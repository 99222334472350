/* eslint max-classes-per-file: 0 */
import { ConnectionType } from 'goodmaps-sdk';
import RoutePoint from '../routing/RoutePoint';
import { getDistanceBetweenPoints } from './MathHelpers';

class PriorityQueue {
  collection: any[];

  constructor() {
    this.collection = [];
  }

  // We'll start with an empty collection so the first time enqueue is called that element
  // assumes the highest priority. The next element that is queued will be compared to the first based on
  // their weight. If the second's weight is lower (or higher depending on what you're doing) than that
  // element will be inserted before the previous element. If the new element is not weighted lower than
  // the previous elements it will be added at the end.
  enqueue = element => {
    if (this.isEmpty()) this.collection.push(element);
    else {
      let added = false;
      for (let i = 1; i <= this.collection.length; i += 1) {
        if (element[1] < this.collection[i - 1][1]) {
          this.collection.splice(i - 1, 0, element);
          added = true;
          break;
        }
      }
      if (!added) {
        this.collection.push(element);
      }
    }
  };

  // Pulls the top element from the queue
  dequeue = () => this.collection.shift();

  isEmpty = () => this.collection.length === 0;
}

export type DjikstraSolution = {
  path: RoutePoint[];
  distance: number;
};

// In order to create a path we first have to construct a weighted graph that we can trace.
// This is done by adding nodes to the graph and determining what others nodes are neighbors
// and weighting them in some way. We weight based on distance from the neighbor.
export class DjikstraGraph {
  nodes: RoutePoint[];

  neighbors: { [id: string]: { node: RoutePoint; weight: number }[] };

  constructor() {
    this.nodes = [];
    this.neighbors = {};
  }

  /**
   * Simply checking to see if a node has already been added to our graph.
   * If it has we'll replace it and remove any neighbors it had. This is a precaution
   * to ensure we don't add the same node twice. This is not used internally.
   *
   * @param node a route point that is to be added to our weighted graph
   */
  addNode(node: RoutePoint) {
    const index = this.nodes.findIndex(point => point.id === node.id);
    if (index > -1) {
      // Replace node
      this.nodes[index] = node;
      this.removeNeighborsByID(node.id);
    } else this.nodes.push(node);
  }

  /**
   * Here we take two nodes and add them as neighbors. If that node already has the other
   * node as a neighbor we ignore it. If that node is being added for the first time we initialize
   * a new array to store that neighbor and all future neighbors. If no weight is passed in we'll
   * determine one by calculating the distance between the nodes
   *
   * @param node1
   * @param node2
   * @param weight number that determines the distance between the two nodes
   */
  addNeighbors(node1: RoutePoint, node2: RoutePoint, weight?: number) {
    const tempWeight: number = !weight ? getWeight(node1, node2) : weight;

    if (!this.neighbors[node1.id]) this.neighbors[node1.id] = [];
    if (!this.neighbors[node2.id]) this.neighbors[node2.id] = [];

    if (!this.neighbors[node1.id].find(n => n.node === node2)) {
      this.neighbors[node1.id].push({ node: node2, weight: tempWeight });
    }
    if (!node1.oneWay && !this.neighbors[node2.id].find(n => n.node === node1)) {
      this.neighbors[node2.id].push({ node: node1, weight: tempWeight });
    }
  }

  /**
   * Returns all nodes currenlty loaded into graph
   */
  getNodes(): RoutePoint[] {
    return [...this.nodes];
  }

  /**
   * Used to pull out node
   * @param id string value used to pull out node
   */
  getNodeByID(id: string): RoutePoint {
    return (this.nodes as any).find(point => point.id === id);
  }

  /**
   * Used to retrieve neighbors of a node
   * @param id string value used to pull out all neighbors of node
   */
  getNeighborsByID(id: string): { node: RoutePoint; weight: number }[] {
    return this.neighbors[id];
  }

  /**
   * Used to remove all neighbors of a node
   * @param id string value used to removes neighbors
   */
  removeNeighborsByID(id: string) {
    this.neighbors[id] = [];
  }

  /**
   * Resets the graph to an empty state
   */
  resetNodes() {
    this.nodes = [];
    this.neighbors = {};
  }

  /**
   * Take loaded route data and figure out which path is the shortest by tracing weighted paths
   *
   * @param startNode node the user is starting from, should be the user position
   * @param endNode node the user is trying to navigate to
   * @returns an object that contains the total distance and the path a user should take
   */
  calcDjikstraAlgorithm(
    startNode: RoutePoint,
    endNode: RoutePoint,
    stepFree: boolean = false
  ): DjikstraSolution {
    const weights = {};
    const backtrace = {};
    const pq = new PriorityQueue();

    // Starting weight is 0 as that's your position
    weights[startNode.id] = 0;

    // Set the weight for every node to infinity
    this.nodes.forEach(node => {
      if (node.id !== startNode.id) {
        weights[node.id] = Infinity;
      }
    });

    // add starting node to our priority queue
    pq.enqueue([startNode, 0]);

    // while the pq has a node inside of it keep checking that node's neighbors
    // to figure out the next shortest path
    while (!pq.isEmpty()) {
      // take out the node with the lowest weight so we can begin to examine
      // its neighbors
      const shortestStep = pq.dequeue();
      const currentNode = shortestStep[0];

      if (!this.neighbors[currentNode.id]) this.neighbors[currentNode.id] = [];

      this.neighbors[currentNode.id].forEach(neighbor => {
        let currentWeight = weights[currentNode.id] + neighbor.weight;
        if (
          stepFree &&
          (neighbor.node.connectionType === ConnectionType.Escalator ||
            neighbor.node.connectionType === ConnectionType.Stairs)
        ) {
          currentWeight = Infinity;
        }
        // TODO: write a test with two neighbors being the same distance from
        // the user. Should still work but I'm curious

        // If the current node weight is less than the saved node weight
        // the current node weight will be saved, and the node will be added to
        // to a backtrace array that will allow us to quickly retrace our steps.
        // That node is then added to the priority queue so its neighbors can be
        // evaulated.
        if (currentWeight < weights[neighbor.node.id]) {
          weights[neighbor.node.id] = currentWeight;
          backtrace[neighbor.node.id] = currentNode;
          pq.enqueue([neighbor.node, currentWeight]);
        }
      });
    }

    const path: RoutePoint[] = [endNode];
    let lastStep = endNode;

    // Now we go through the backtrace array backwards to get the completed solution.
    while (lastStep && lastStep.id !== startNode.id) {
      path.unshift(backtrace[lastStep.id]);
      lastStep = backtrace[lastStep.id];
    }

    return {
      path,
      distance: weights[endNode.id],
    };
  }
}

const getWeight = (node1: RoutePoint, node2: RoutePoint): number => {
  if (node1.level !== node2.level)
    return Math.abs(node1.level - node2.level) * 100 * Math.min(node1.weight, node2.weight);
  return (
    getDistanceBetweenPoints(node1.position, node2.position) * Math.min(node1.weight, node2.weight)
  );
};
