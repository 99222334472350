export const MAP: MAP_TRANSLATION = {
  headsUp: {
    turnLeft: 'headsUp.turnLeft',
    slightLeft: 'headsUp.slightLeft',
    leftToEscalator: 'headsUp.leftToEscalator',
    leftToStairs: 'headsUp.leftToStairs',
    leftToElevator: 'headsUp.leftToElevator',
    leftToDestination: 'headsUp.leftToDestination',
    leftToDestinationShelf: 'headsUp.leftToDestinationShelf',
    turnRight: 'headsUp.turnRight',
    slightRight: 'headsUp.slightRight',
    rightToEscalator: 'headsUp.rightToEscalator',
    rightToStairs: 'headsUp.rightToStairs',
    rightToElevator: 'headsUp.rightToElevator',
    rightToDestination: 'headsUp.rightToDestination',
    rightToDestinationShelf: 'headsUp.rightToDestinationShelf',
    goThroughDoor: {
      named: 'headsUp.goThroughDoor.named',
      default: 'headsUp.goThroughDoor.default',
      opening: 'headsUp.goThroughDoor.opening',
    },
    enterStairwell: 'headsUp.enterStairwell',
    enterElevator: 'headsUp.enterElevator',
    enterEscalator: 'headsUp.enterEscalator',
    destination: 'headsUp.destination',
    destinationShelf: 'headsUp.destinationShelf',
  },
  action: {
    goThroughDoor: {
      named: 'action.goThroughDoor.named',
      default: 'action.goThroughDoor.default',
      opening: 'action.goThroughDoor.opening',
    },
    goThroughDoorImmediate: {
      named: {
        left: 'action.goThroughDoorImmediate.named.left',
        right: 'action.goThroughDoorImmediate.named.right',
      },
      default: {
        left: 'action.goThroughDoorImmediate.default.left',
        right: 'action.goThroughDoorImmediate.default.right',
      },
      opening: {
        left: 'action.goThroughDoorImmediate.opening.left',
        right: 'action.goThroughDoorImmediate.opening.right',
      },
    },
    turnLeft: 'action.turnLeft',
    slightLeft: 'action.slightLeft',
    turnLeftImmediate: {
      left: 'action.turnLeftImmediate.left',
      right: 'action.turnLeftImmediate.right',
      door: {
        named: 'action.turnLeftImmediate.door.named',
        default: 'action.turnLeftImmediate.door.default',
        opening: 'action.turnLeftImmediate.door.opening',
      },
      destination: 'action.turnLeftImmediate.destination',
      destinationShelf: 'action.turnLeftImmediate.destinationShelf',
      stairwell: 'action.turnLeftImmediate.stairwell',
      elevator: 'action.turnLeftImmediate.elevator',
      escalator: 'action.turnLeftImmediate.escalator',
    },
    turnRight: 'action.turnRight',
    slightRight: 'action.slightRight',
    turnRightImmediate: {
      left: 'action.turnRightImmediate.left',
      right: 'action.turnRightImmediate.right',
      door: {
        named: 'action.turnRightImmediate.door.named',
        default: 'action.turnRightImmediate.door.default',
        opening: 'action.turnRightImmediate.door.opening',
      },
      destination: 'action.turnRightImmediate.destination',
      destinationShelf: 'action.turnRightImmediate.destinationShelf',
      stairwell: 'action.turnRightImmediate.stairwell',
      elevator: 'action.turnRightImmediate.elevator',
      escalator: 'action.turnRightImmediate.escalator',
    },
    enterStairwell: 'action.enterStairwell',
    enterElevator: 'action.enterElevator',
    enterEscalator: 'action.enterEscalator',
    continueOnStairwell: 'action.continueOnStairwell',
    continueOnElevator: 'action.continueOnElevator',
    continueOnEscalator: 'action.continueOnEscalator',
    exitConnection: 'action.exitConnection',
    exitConnectionImmediate: {
      left: 'action.exitConnectionImmediate.left',
      right: 'action.exitConnectionImmediate.right',
    },
  },
  continue: 'continue',
  continueAnd: {
    destination: 'continueAnd.destination',
    destinationShelf: 'continueAnd.destinationShelf',
    left: 'continueAnd.left',
    slightLeft: 'continueAnd.slightLeft',
    leftToEscalator: 'continueAnd.leftToEscalator',
    leftToStairs: 'continueAnd.leftToStairs',
    leftToElevator: 'continueAnd.leftToElevator',
    leftToDestination: 'continueAnd.leftToDestination',
    leftToDestinationShelf: 'continueAnd.leftToDestinationShelf',
    right: 'continueAnd.right',
    slightRight: 'continueAnd.slightRight',
    rightToEscalator: 'continueAnd.rightToEscalator',
    rightToStairs: 'continueAnd.rightToStairs',
    rightToElevator: 'continueAnd.rightToElevator',
    rightToDestination: 'continueAnd.rightToDestination',
    rightToDestinationShelf: 'continueAnd.rightToDestinationShelf',
    stairwell: 'continueAnd.stairwell',
    elevator: 'continueAnd.elevator',
    escalator: 'continueAnd.escalator',
  },
  other: {
    continueToFloor: 'other.continueToFloor',
    turnAround: 'other.turnAround',
    lastTurn: 'other.lastTurn',
  },
};

export type MAP_TRANSLATION = {
  headsUp: {
    turnLeft: string;
    slightLeft: string;
    leftToEscalator: string;
    leftToStairs: string;
    leftToElevator: string;
    leftToDestination: string;
    leftToDestinationShelf: string;
    turnRight: string;
    slightRight: string;
    rightToEscalator: string;
    rightToStairs: string;
    rightToElevator: string;
    rightToDestination: string;
    rightToDestinationShelf: string;
    goThroughDoor: {
      named: string;
      default: string;
      opening: string;
    };
    enterStairwell: string;
    enterElevator: string;
    enterEscalator: string;
    destination: string;
    destinationShelf: string;
  };
  action: {
    goThroughDoor: {
      named: string;
      default: string;
      opening: string;
    };
    goThroughDoorImmediate: {
      named: {
        left: string;
        right: string;
      };
      default: {
        left: string;
        right: string;
      };
      opening: {
        left: string;
        right: string;
      };
    };
    turnLeft: string;
    slightLeft: string;
    turnLeftImmediate: {
      left: string;
      right: string;
      door: {
        named: string;
        default: string;
        opening: string;
      };
      destination: string;
      destinationShelf: string;
      stairwell: string;
      elevator: string;
      escalator: string;
    };
    turnRight: string;
    slightRight: string;
    turnRightImmediate: {
      left: string;
      right: string;
      door: {
        named: string;
        default: string;
        opening: string;
      };
      destination: string;
      destinationShelf: string;
      stairwell: string;
      elevator: string;
      escalator: string;
    };
    enterStairwell: string;
    enterElevator: string;
    enterEscalator: string;
    continueOnStairwell: string;
    continueOnElevator: string;
    continueOnEscalator: string;
    exitConnection: string;
    exitConnectionImmediate: {
      left: string;
      right: string;
    };
  };
  continue: string;
  continueAnd: {
    destination: string;
    destinationShelf: string;
    left: string;
    slightLeft: string;
    leftToEscalator: string;
    leftToStairs: string;
    leftToElevator: string;
    leftToDestination: string;
    leftToDestinationShelf: string;
    right: string;
    slightRight: string;
    rightToEscalator: string;
    rightToStairs: string;
    rightToElevator: string;
    rightToDestination: string;
    rightToDestinationShelf: string;
    stairwell: string;
    elevator: string;
    escalator: string;
  };
  other: {
    continueToFloor: string;
    turnAround: string;
    lastTurn: string;
  };
};
