import { Raycaster, Vector3, Vector2 } from 'three';
import Room from '../entities/Room';
import { RoomVector, ExplorePosition } from '../types';
import { getDistanceBetweenPoints, getAngleBetweenPoints } from '../helpers/MathHelpers';
import ExploreEntity from '../ExploreEntity';

export default class RoomQueries {
  building: ExploreEntity;

  constructor(building: ExploreEntity) {
    this.building = building;
  }

  getAllRooms(): Room[] {
    return this.building.rooms;
  }

  getRoomVector(position: ExplorePosition, id: string): RoomVector {
    const room = this.building.getRoom(id);

    const positionVector = new Vector2(position.x, position.y);

    if (!room) {
      return;
    }
    let nearestDoor;

    room.doors.forEach(door => {
      const distance = getDistanceBetweenPoints(positionVector, door.position);
      const angle = getAngleBetweenPoints(positionVector, door.position);
      const angleRelativeToOrientation = (position.orientation - angle) % 360;

      if (!nearestDoor || nearestDoor.distance > distance) {
        nearestDoor = { distance, angle, angleRelativeToOrientation, door };
      }
    });

    if (!nearestDoor) {
      const distance = getDistanceBetweenPoints(positionVector, room.center);
      const angle = getAngleBetweenPoints(positionVector, room.center);
      const angleRelativeToOrientation = (position.orientation - angle) % 360;
      return { room, distance, angle, angleRelativeToOrientation };
    }

    return { room, ...nearestDoor };
  }

  getAllRoomVectors(position: ExplorePosition): RoomVector[] {
    return this.getAllRooms()
      .map(({ id }) => this.getRoomVector(position, id))
      .filter(r => !!r);
  }

  getNearestRoomVectors(position: ExplorePosition, range = 30, excludeCurrentRoom = true) {
    const floor = this.building.getFloor(position.level);
    const currentRoom = this.getRoomAtPosition(position);
    const results = [];

    floor.rooms.forEach(room => {
      if (currentRoom?.id !== room.id || !excludeCurrentRoom) {
        const poiVector = this.getRoomVector(position, room.id);

        if (poiVector.distance <= range) {
          results.push(poiVector);
        }
      }
    });

    results.sort((a, b) => a.distance - b.distance);
    return results;
  }

  getRoomVectorsWithinAngleToUser(
    position: ExplorePosition,
    range = 30,
    startingAngle = 0,
    endingAngle = 30,
    excludeCurrentRoom = true
  ): RoomVector[] {
    const { orientation, x, y, level } = position;
    const userPosition = new Vector2(x, y);
    const currentFloor = this.building.getFloor(level);
    const currentRoom = this.getRoomAtPosition(position);
    const results: RoomVector[] = [];

    if (!currentFloor) return [];

    const fixAngle = angle => {
      if (angle < 0) return angle + 360;
      if (angle > 360) return angle - 360;
      return angle;
    };

    const starting = fixAngle(startingAngle);
    const ending = fixAngle(endingAngle);

    // First find potential rooms by looking at the nearest doors
    const { doors } = currentFloor;
    doors.forEach(door => {
      const distance = getDistanceBetweenPoints(door.position, userPosition);
      if (distance < range) {
        const angle = getAngleBetweenPoints(userPosition, door.position);
        let withinAngle;
        if (starting < ending) {
          // i.e. 30deg to 60deg
          withinAngle = angle > starting && angle < ending;
        } else {
          withinAngle = angle > starting || angle < ending;
        }

        if (withinAngle) {
          door.attachedRooms.forEach(room => {
            if (room.id !== this.getRoomAtPosition(position)?.id || !excludeCurrentRoom) {
              let angleRelativeToOrientation = (orientation - angle) % 360;
              if (angleRelativeToOrientation < 0) angleRelativeToOrientation += 360;
              results.push({
                room,
                distance,
                angle,
                angleRelativeToOrientation,
              });
            }
          });
        }
      }
    });

    // Now find potential areas by looking at their centers
    const areas = currentFloor.rooms.filter(room => room.type === 'area');
    areas.forEach(area => {
      const distance = getDistanceBetweenPoints(area.center, userPosition);
      if (distance < range && (area !== currentRoom || !excludeCurrentRoom)) {
        const angle = getAngleBetweenPoints(userPosition, area.center);
        let withinAngle;
        if (starting < ending) {
          // i.e. 30deg to 60deg
          withinAngle = angle > starting && angle < ending;
        } else {
          withinAngle = angle > starting || angle < ending;
        }

        if (withinAngle) {
          let angleRelativeToOrientation = (orientation - angle) % 360;
          if (angleRelativeToOrientation < 0) angleRelativeToOrientation += 360;
          results.push({
            room: area,
            distance,
            angle,
            angleRelativeToOrientation,
          });
        }
      }
    });

    // Now sort the results by distance
    results.sort((a, b) => a.distance - b.distance);

    // And filter out any rooms without names, or duplicate rooms
    const filteredResults = results.filter(
      (result, i) =>
        !!result.room?.name && results.findIndex(r => r.room.id === result.room.id) === i
    );
    return filteredResults;
  }

  getRoomVectorsRelativeToUserOrientation(
    position: ExplorePosition,
    range = 30,
    arcRange = 30,
    excludeCurrentRoom = true
  ): RoomVector[] {
    const halfArcRange = arcRange / 2;
    return this.getRoomVectorsWithinAngleToUser(
      position,
      range,
      position.orientation - halfArcRange,
      position.orientation + halfArcRange,
      excludeCurrentRoom
    );
  }

  getRoomAtPosition(position: ExplorePosition): Room {
    try {
      const floor = this.building.getFloor(position.level);
      const positionVector = new Vector3(position.x, position.y, 0);

      const roomShapes = floor.rooms.map(r => r.shape);
      const rayPosition = new Vector3(position.x, position.y, 100);
      const direction = positionVector.clone().sub(rayPosition).normalize();

      const raycaster = new Raycaster(rayPosition, direction);
      raycaster.linePrecision = 0.05;
      raycaster.params.Line.threshold = 0.05;

      const intersections = raycaster
        .intersectObjects(roomShapes)
        .sort((a, b) => a.distance - b.distance);

      if (!intersections.length) {
        return undefined;
      }

      const roomName = intersections[0].object.name;
      const room = floor.rooms.find(r => r.id === roomName);
      return room;
    } catch (e) {
      // console.warn("Couldn't get room for building");
      return undefined;
    }
  }
}
