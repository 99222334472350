import { DataLoggerDatasetList, DataLoggerMetadata } from 'goodmaps-utils';
import {
  DATALOGGER_FILE_SELECTED,
  DATALOGGER_FILE_CLEARED,
  DATALOGGER_FILES_LOADED,
  BUILDING_SELECTED_DATALOGGER,
  CPS_TEST_MESSAGE_UPDATE,
} from '../actions/types';

interface DataLoggerState {
  selectedDataLoggerFile: DataLoggerDatasetList;
  cpsLoading: boolean;
  buildingSelected: boolean;
  dataLoggerFiles: DataLoggerMetadata[];
  cpsTestMessage: string;
}

const initialState: DataLoggerState = {
  selectedDataLoggerFile: undefined,
  cpsLoading: false,
  buildingSelected: false,
  dataLoggerFiles: [],
  cpsTestMessage: '',
};

export default (state = initialState, action): DataLoggerState => {
  switch (action.type) {
    case DATALOGGER_FILES_LOADED:
      return { ...state, dataLoggerFiles: action.payload };

    case DATALOGGER_FILE_CLEARED:
      return { ...state, selectedDataLoggerFile: undefined };

    case DATALOGGER_FILE_SELECTED:
      return { ...state, selectedDataLoggerFile: action.payload };

    case BUILDING_SELECTED_DATALOGGER:
      return { ...state, buildingSelected: true };

    case CPS_TEST_MESSAGE_UPDATE:
      return { ...state, cpsTestMessage: action.payload };

    default:
      return state;
  }
};
