import { Vector2 } from 'three';
import { POI } from '../entities/POI';
import { ExplorePosition, POIVector } from '../types';
import { getDistanceBetweenPoints, getAngleBetweenPoints } from '../helpers/MathHelpers';
import ExploreEntity from '../ExploreEntity';

export default class POIQueries {
  building: ExploreEntity;

  constructor(building: ExploreEntity) {
    this.building = building;
  }

  getPOIByName(name: string): POI {
    return this.getAllPOIS().find(poi => poi.name === name);
  }

  getAllPOIS(includeDoors: boolean = false): POI[] {
    const doors = includeDoors ? this.building.doors : [];
    return [...doors, ...this.building.pois];
  }

  getPOIVectorFromPointAndOrientation(id: string, position: ExplorePosition): POIVector {
    const poi = this.building.getPOI(id);

    const positionVector = new Vector2(position.x, position.y);
    const distance = getDistanceBetweenPoints(positionVector, poi.position);
    const angle = getAngleBetweenPoints(positionVector, poi.position);
    let angleRelativeToOrientation = (position.orientation - angle) % 360;
    if (angleRelativeToOrientation < 0) angleRelativeToOrientation += 360;
    return { poi, angle, distance, angleRelativeToOrientation };
  }

  getNearestTestPointVector(position: ExplorePosition): POIVector[] {
    const { testPoints } = this.building.getFloor(position.level);

    return testPoints
      .map(tp => this.getPOIVector(tp.id, position))
      .sort((a, b) => a.distance - b.distance);
  }

  getPOIVector(id: string, position: ExplorePosition): POIVector {
    return this.getPOIVectorFromPointAndOrientation(id, position);
  }

  getAllPOIVectors(position: ExplorePosition): POIVector[] {
    return this.building.pois.map(({ id }) => this.getPOIVector(id, position));
  }

  getNearestPOIVectors = (position: ExplorePosition, range = 30): POIVector[] => {
    const currentFloor = this.building.getFloor(position.level);
    const results = [];

    if (!currentFloor) return [];

    const { pois } = currentFloor;

    pois.forEach(poi => {
      const poiVector = this.getPOIVector(poi.id, position);

      if (poiVector.distance <= range && poiVector.poi?.name) {
        results.push(poiVector);
      }
    });

    results.sort((a, b) => a.distance - b.distance);
    return results;
  };

  getPOIVectorsWithinAngleToUser = (
    position: ExplorePosition,
    range = 30,
    startingAngle = 0,
    endingAngle = 30
  ): POIVector[] => {
    const currentFloor = this.building.getFloor(position.level);
    const results = [];

    if (!currentFloor) return [];

    const fixAngle = angle => {
      if (angle < 0) return angle + 360;
      if (angle > 360) return angle - 360;
      return angle;
    };

    const starting = fixAngle(startingAngle);
    const ending = fixAngle(endingAngle);

    const { pois } = currentFloor;

    pois.forEach(poi => {
      const poiVector = this.getPOIVector(poi.id, position);
      const { angle, distance } = poiVector;

      const withinRange = distance < range;
      let withinAngle;
      if (starting < ending) {
        // i.e. 30deg to 60deg
        withinAngle = angle > starting && angle < ending;
      } else {
        withinAngle = angle > starting || angle < ending;
      }

      if (withinAngle && withinRange) {
        results.push(poiVector);
      }
    });

    results.sort((a, b) => a.distance - b.distance);
    return results;
  };

  getPOISVectorsRelativeToUserOrientation = (
    position: ExplorePosition,
    range = 30,
    arcRange = 30
  ): POIVector[] => {
    const halfArcRange = arcRange / 2;
    return this.getPOIVectorsWithinAngleToUser(
      position,
      range,
      position.orientation - halfArcRange,
      position.orientation + halfArcRange
    );
  };
}
