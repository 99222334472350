import { Vector2, Vector3, Quaternion, Euler } from 'three';
import { Position } from 'goodmaps-utils';
import {
  calculateDegDifference,
  convertDegToRad,
  convertRadToDeg,
  getAngleBetweenVectors,
  getAngleFromUnit,
  getRadsFromUnit,
  getUnitFromAngle,
  getUnitFromRads,
} from '../helpers/MathHelpers';
import ExploreBuilding from '../ExploreBuilding';
import ExploreIMDFBuilding from '../ExploreIMDFBuilding';
import { ExplorePosition } from '../types';
import Positioning from './Positioning';
import { Floor } from '..';

const USER_BLUEDOT_Z_POSITION = 1.3;
const CONFIRMATION_COUNT_REQUIREMENT = 2;
const CONFIRMATION_POSITION_THRESHOLD = 2; // Confirmed position should be within x m of position
const CONFIRMATION_THETA_THRESHOLD = 10; // Confirmed orientation should be within x deg of orientation

interface TransformedCpsPosition {
  position: ExplorePosition;
  adjustedPosition: ExplorePosition;
  thetaUnit: Vector2;
}

export default class ARPositioning extends Positioning {
  building: ExploreBuilding | ExploreIMDFBuilding;

  buildingType: 'osm' | 'imdf';

  private ORIGIN = new Vector2(0, 0);

  private TELEPORTATION_DISTANCE_SAME_LEVEL = 5;

  private TELEPORTATION_DISTANCE_DIFFERENT_LEVEL = 7;

  // the straight up position from the device. resets to 0 when ARKit resets
  private rawDeviceARPosition = new Vector2(0, 0);

  // dark gray, "final" ar position without drift correction
  private uncorrectedARposition = new Vector2(0, 0);

  // the final position calculated
  private finalARposition = new Vector2(0, 0);

  private position: ExplorePosition = {
    x: 0,
    y: 0,
    level: 0,
    orientation: 0,
    heading: 0,
    speed: 0,
  };

  prevWorldPositionTimestamp: number = 0;

  // where we make the CPS request
  rawARPositionMarkers: { [timestamp: number]: Vector2 } = {};

  worldPositionMarkersActual: { [timestamp: number]: Vector2 } = {};

  // the orientation of ARKit at the time of the CPS request
  rawAROrientationMarkers: { [timestamp: number]: Vector2 } = {};

  // where the previous CPS request succeeded
  private prevSnapPoint = new Vector2(0, 0);

  recentMetersPerMillisecond = [];

  speed = 0;

  recentHeadings: Vector2[] = [];

  heading = 0;

  rawOrientationAngle = 0;

  correctedOrientationAngle = 0;

  recentDriftCorrections: Vector2[] = [];

  driftCorrectionTheta = 0;

  allowARTracking = true;

  teleportationCounter = 0;

  prevARUpdate = 0;

  confirmationCounter = 0;

  // drift statistics
  drift = {
    previousDrifts: [],
    currentDrift: 0,
    averageDrift: 0,
    medianDrift: 0,
  };

  private needsConfirmation = false;

  private positionToConfirm: TransformedCpsPosition;

  private rawARAtPositionToConfirm: Vector2 = new Vector2(0, 0);

  constructor(building: ExploreBuilding | ExploreIMDFBuilding) {
    super();
    this.building = building;
    (building as ExploreBuilding).gmBuilding
      ? (this.buildingType = 'osm')
      : (this.buildingType = 'imdf');
  }

  getStatus() {
    const {
      rawOrientationAngle,
      rawDeviceARPosition,
      finalARposition,
      recentMetersPerMillisecond,
      rawARPositionMarkers: worldPositionMarkers,
      driftCorrectionTheta,
      allowARTracking,
      speed,
      heading,
      drift,
    } = this;
    return {
      rawOrientationAngle,
      deviceARposition: rawDeviceARPosition,
      finalARposition,
      recentMetersPerMillisecond,
      worldPositionMarkers,
      driftCorrectionTheta,
      allowARTracking,
      speed,
      heading,
      drift,
    };
  }

  // export const setAllowARTracking = allowed => {
  //   allowARTracking = allowed;
  //   if (!allowed) {
  //     resetARPositioning();
  //   }
  // };

  getPosition() {
    return this.position;
  }

  getARPosition() {
    return this.finalARposition;
  }

  setPrevARUpdateForTesting(update: number) {
    this.prevARUpdate = update;
  }

  setDriftCorrectionTheta(theta: number) {
    this.driftCorrectionTheta = theta;
  }

  /**
   * Sets the relative position based on ARKit coordinates
   *
   * @param x
   * @param y
   */
  updateDeviceARPosition(x: number, y: number, orientationAngle: number) {
    const now = Date.now();
    orientationAngle = (360 + orientationAngle) % 360;

    const prevRawDeviceARPosition = this.rawDeviceARPosition.clone();
    const diff = new Vector2(x, y).sub(this.rawDeviceARPosition);
    this.rawDeviceARPosition.set(x, y);

    let metersPerSecond = 0;
    // Update the AR speed
    if (!(x === 0 && y === 0)) {
      // Calculate the mps and add it to the recent vectors
      const millisecondsElapsed = now - this.prevARUpdate;
      const metersTraveled = diff.length();
      const metersPerMillisecond = metersTraveled / millisecondsElapsed;
      if (metersPerMillisecond * 1000 <= 2) {
        this.recentMetersPerMillisecond = [
          metersPerMillisecond,
          ...this.recentMetersPerMillisecond.slice(0, 150),
        ];

        metersPerSecond = metersPerMillisecond * 1000;
        // Calculate the average speed
        const avgSpeedMpms = this.recentMetersPerMillisecond.length
          ? this.recentMetersPerMillisecond.reduce((a, b) => a + b) /
            this.recentMetersPerMillisecond.length
          : 0;
        const avgSpeedMps = avgSpeedMpms * 1000;
        this.speed = avgSpeedMps;
      } else {
        // ARKit value is likely bad; toss it out
        this.prevARUpdate = now;
        this.position = {
          x: this.finalARposition.x,
          y: this.finalARposition.y,
          level: this.position.level,
          orientation: convertRadToDeg(this.correctedOrientationAngle),
          speed: this.speed,
          heading: this.heading,
        };
        return;
      }
    }

    // Update the new position
    if (
      x === 0 &&
      y === 0 &&
      this.recentMetersPerMillisecond.length &&
      prevRawDeviceARPosition.length() !== 0
    ) {
      // If x === 0 and y === 0, AR is resetting and we need to apply
      // the average speed to the orientation
      const unit = getUnitFromRads(this.heading);
      unit.multiplyScalar(this.speed * (now - this.prevARUpdate));
      this.recentDriftCorrections = [];
      // correctedCalculatedARPosition.add(unit);
    } else if (!(x === 0 && y === 0) && this.driftCorrectionTheta) {
      // Adjust the change in position using the rotation vector... this is a standard update
      this.uncorrectedARposition.add(diff);
      diff.rotateAround(this.ORIGIN, this.driftCorrectionTheta);
      this.finalARposition.add(diff);

      if (metersPerSecond > 0.3) {
        // Calculate the heading
        this.recentHeadings = [diff.clone().normalize(), ...this.recentHeadings.slice(0, 20)];
        const headingUnit = this.recentHeadings
          .reduce((a, b) => new Vector2(a.x + b.x, a.y + b.y))
          .normalize();

        // Convert heading unit to an angle
        const headingRads = getRadsFromUnit(headingUnit);
        this.heading = convertRadToDeg(headingRads);

        if (this.building.debug) {
          this.building.renderer?.renderArrowMarker(
            'heading',
            new Vector3(
              this.finalARposition.x,
              this.finalARposition.y,
              USER_BLUEDOT_Z_POSITION - 0.05
            ),
            new Vector3(headingUnit.x, headingUnit.y, 0).normalize(),
            0xff0000,
            5
          );
        }
      }
    } else {
      // this is only while a drift correction has not yet been established
      this.uncorrectedARposition.add(diff);
      this.finalARposition.add(diff);
    }

    // Calculate the corrected orientation angle
    this.rawOrientationAngle = convertDegToRad(orientationAngle);
    this.correctedOrientationAngle = convertDegToRad(orientationAngle) + this.driftCorrectionTheta;

    if (this.building.debug) {
      this.building.renderer?.renderCircleMarker(
        'user',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        0x0000ff,
        0.4
      );
      this.building.renderer?.renderArrowMarker(
        'userOrientation',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        getUnitFromAngle(this.position.orientation),
        0x0000ff,
        3
      );

      // Renderer.renderArrowMarker(
      //   'rawUserOrientation',
      //   new Vector3(finalARposition.x, finalARposition.y, 1),
      //   getUnitFromRads(rawOrientationAngle),
      //   0xffaa00,
      //   3
      // );

      // Renderer.renderCircleMarker(
      //   'rawPositionMarker',
      //   new Vector3(uncorrectedARposition.x, uncorrectedARposition.y, 0.5),
      //   0x555555
      // );
    }

    this.prevARUpdate = now;
    this.position = {
      x: this.finalARposition.x,
      y: this.finalARposition.y,
      level: this.position.level,
      orientation: convertRadToDeg(this.correctedOrientationAngle),
      speed: this.speed,
      heading: this.heading,
    };
  }

  worldPositionMarkerExists = (timestamp: number) => !!this.rawARPositionMarkers[`${timestamp}`];

  removeWorldPositionMaker = (timestamp: number) => {
    this.building.renderer?.clearMarker(`worldPositionMarker-${timestamp}-circle`);
    this.building.renderer?.clearMarker(`rawUserOrientationMarker-${timestamp}-arrow`);
    delete this.rawARPositionMarkers[`${timestamp}`];
    delete this.rawAROrientationMarkers[`${timestamp}`];
    delete this.worldPositionMarkersActual[`${timestamp}`];
  };

  // setWorldPositionMarker = (timestamp: number) => {
  //   this.worldPositionMarkers[`${timestamp}`] = new Vector2(
  //     this.rawDeviceARPosition.x,
  //     this.rawDeviceARPosition.y
  //   );

  //   const orientationVector = getUnitFromRads(this.rawOrientationAngle);
  //   this.worldPositionOrientations[`${timestamp}`] = new Vector2(
  //     orientationVector.x,
  //     orientationVector.y
  //   );

  //   if (this.building.debug) {
  //     // console.log('RERENDER WORLD POSITION MARKER');
  //     // this.building.renderer?.renderCircleMarker(
  //     //   `worldPositionMarker-${timestamp}`,
  //     //   new Vector3(this.finalARposition.x, this.finalARposition.y, 1.1),
  //     //   0xffcccb,
  //     //   0.2
  //     // );
  //     // this.building.renderer?.renderArrowMarker(
  //     //   `rawUserOrientationMarker-${timestamp}`,
  //     //   new Vector3(this.finalARposition.x, this.finalARposition.y, 1),
  //     //   this.worldPositionOrientations[`${timestamp}`],
  //     //   0xffcccb,
  //     //   2
  //     // );
  //   }
  // };

  /**
   * Sets the absolute position in the world and calibrates AR if there has already
   * been a position established
   *
   * @param x
   * @param y
   */
  applyWorldPosition = (
    timestamp: number,
    x: number,
    y: number,
    level: number,
    orientation?: Vector3
  ) => {
    const isPositionAtRoom = !!this.building.roomQueries.getRoomAtPosition({ x, y, level });

    if (timestamp < this.prevWorldPositionTimestamp || !isPositionAtRoom) {
      this.removeWorldPositionMaker(timestamp);
      return;
    }

    if (this.prevWorldPositionTimestamp) {
      this.removeWorldPositionMaker(this.prevWorldPositionTimestamp);
    }

    Object.keys(this.rawARPositionMarkers).forEach(k => {
      const i = parseInt(k, 10);
      if (i < timestamp) {
        this.removeWorldPositionMaker(i);
      }
    });

    this.prevWorldPositionTimestamp = timestamp;
    const worldPositionMarker = this.rawARPositionMarkers[`${timestamp}`];
    const worldPositionOrientation = this.rawAROrientationMarkers[`${timestamp}`];
    if (!worldPositionMarker || !worldPositionOrientation) {
      this.removeWorldPositionMaker(timestamp);
      return;
    }
    if (this.building.debug) {
      this.building.renderer?.renderCircleMarker(
        `worldPositionMarker-${timestamp}`,
        undefined,
        0x00ff00,
        0.4
      );
    }

    // if (!allowARTracking) {
    //   console.warn('Tried to call applyWorldPosition with AR disabled');
    // }

    // establish an initial correction

    // const setAngleFromOrientation = false;
    if (orientation) {
      // if (debug) {
      //   Renderer.renderArrowMarker(
      //     'newOrientationMarker',
      //     new Vector3(finalARposition.x, finalARposition.y, 1),
      //     orientation,
      //     0x00ff00,
      //     4
      //   );
      // }
      if (worldPositionOrientation.length() !== 0) {
        const diffUnit = getAngleBetweenVectors(worldPositionOrientation, orientation);
        this.recentDriftCorrections.unshift(diffUnit);
        if (this.recentDriftCorrections.length) {
          const currentAvgUnit = this.recentDriftCorrections
            .slice(0, 3)
            .reduce((a, b) => new Vector2(a.x + b.x, a.y + b.y))
            .divideScalar(this.recentDriftCorrections.length);

          const difference = Math.abs(
            getRadsFromUnit(getAngleBetweenVectors(diffUnit, currentAvgUnit))
          );

          if (difference > 0.18) {
            this.recentDriftCorrections = [diffUnit];
            this.driftCorrectionTheta = getRadsFromUnit(diffUnit);
            // setAngleFromOrientation = true;
          } else {
            this.driftCorrectionTheta = getRadsFromUnit(currentAvgUnit);
          }
        } else {
          this.recentDriftCorrections = [diffUnit];
          this.driftCorrectionTheta = getRadsFromUnit(diffUnit);
          // setAngleFromOrientation = true;
        }
      }
    }

    let drift = 0;
    if (this.prevSnapPoint.x !== 0 && this.prevSnapPoint.y !== 0) {
      drift = worldPositionMarker.clone().sub(new Vector2(x, y)).length();
      this.calculateDrift(drift);
    }

    // Add the distance the user moved during the request
    const roomBeforeAdjustment = this.building.roomQueries.getRoomAtPosition({ x, y, level });
    const changeInPositionDuringRequest = this.finalARposition.clone().sub(worldPositionMarker);
    const newWorldPosition = new Vector2(
      x + changeInPositionDuringRequest.x,
      y + changeInPositionDuringRequest.y
    );
    const roomAfterAdjustment = this.building.roomQueries.getRoomAtPosition({
      x: newWorldPosition.x,
      y: newWorldPosition.y,
      level,
    });

    if (roomBeforeAdjustment !== roomAfterAdjustment) {
      if (Date.now() - timestamp > 5000) return;
      newWorldPosition.set(x, y);
    }

    // TODO: revisit this and figure out why it wasn't even working
    // if (!getCurrentRoom(new Vector3(newWorldPosition.x, newWorldPosition.y, 0))) {
    //   newWorldPosition.set(x, y);
    // }

    this.finalARposition.set(newWorldPosition.x, newWorldPosition.y);

    // calculate the drift correction theta
    // if (this.prevSnapPoint.x !== 0 && this.prevSnapPoint.y !== 0 && drift > 1.3 && !setAngleFromOrientation) {
    //   const prevToActual = newWorldPosition.clone().sub(this.prevSnapPoint);
    //   const prevToComputed = uncorrectedARposition.clone().sub(this.prevSnapPoint);
    //   const diffUnit = getAngleBetweenVectors(prevToComputed, prevToActual);
    //   this.recentDriftCorrections.unshift(diffUnit);
    //   const avgUnit = this.recentDriftCorrections
    //     .slice(0, 3)
    //     .reduce((a, b) => new Vector2(a.x + b.x, a.y + b.y))
    //     .divideScalar(this.recentDriftCorrections.length);
    //   this.driftCorrectionTheta = getRadsFromUnit(avgUnit);
    // }

    this.teleportationCounter = 0;
    this.prevSnapPoint.set(newWorldPosition.x, newWorldPosition.y);
    this.uncorrectedARposition.set(newWorldPosition.x, newWorldPosition.y);

    if (this.building.debug) {
      // Renderer.renderCircleMarker(
      //   'rawPositionMarker',
      //   new Vector3(uncorrectedARposition.x, uncorrectedARposition.y, 1.2),
      //   0x555555
      // );
    }
    return this.finalARposition;
  };

  // TODO: Merge this with setWorldPositionMarker
  // prepareCPS(timestamp: number) {
  //   this.setWorldPositionMarker(timestamp);
  // }

  setNeedsConfirmCps() {
    this.needsConfirmation = true;
    this.positionToConfirm = undefined;
    this.confirmationCounter = 0;
  }

  prepareWorldPositionMarkerV2 = (timestamp: number) => {
    this.rawARPositionMarkers[`${timestamp}`] = new Vector2(
      this.rawDeviceARPosition.x,
      this.rawDeviceARPosition.y
    );

    this.worldPositionMarkersActual[`${timestamp}`] = new Vector2(
      this.finalARposition.x,
      this.finalARposition.y
    );

    const orientationVector = getUnitFromRads(this.rawOrientationAngle);
    this.rawAROrientationMarkers[`${timestamp}`] = orientationVector;

    if (this.building.debug) {
      // console.log('RERENDER WORLD POSITION MARKER');
      this.building.renderer?.renderCircleMarker(
        `worldPositionMarker-${timestamp}`,
        new Vector3(this.finalARposition.x, this.finalARposition.y, 1.1),
        0xbbbefe,
        0.05
      );
      this.building.renderer?.renderArrowMarker(
        `rawUserOrientationMarker-${timestamp}`,
        new Vector3(this.finalARposition.x, this.finalARposition.y, 1),
        this.rawAROrientationMarkers[`${timestamp}`],
        0xbbbefe,
        0.7,
        0.3,
        0.4
      );
    }
  };

  getAdjustedCPSPosition(
    timestamp: number,
    position: { x: number; y: number; z: number },
    orientation?: { x: number; y: number; z: number; w: number },
    mapId?: string
  ): TransformedCpsPosition {
    // Transform the CPS position to building coordinates
    let convertedCoordinates;
    let floor: Floor;
    if (this.buildingType === 'osm') {
      if (mapId) {
        // Immersal
        floor = (this.building as ExploreBuilding).floors.find(f =>
          f.cpsMapIds.includes(`${mapId}`)
        );
        convertedCoordinates = floor.gmFloor.originTransform(position, mapId);
      } else {
        convertedCoordinates = (this.building as ExploreBuilding).gmBuilding.originTransform(
          position
        );
      }
    } else if (this.buildingType === 'imdf') {
      convertedCoordinates = (this.building as ExploreIMDFBuilding).getOriginTransform(
        position,
        true,
        mapId
      );
    } else {
      throw Error('Unsupported building type');
    }

    if (!floor) {
      floor = this.building.getFloor(convertedCoordinates.level);
      if (!floor) return;
    }

    const worldPositionMarker = this.rawARPositionMarkers[`${timestamp}`];
    const worldPositionOrientation = this.rawAROrientationMarkers[`${timestamp}`];
    if (!worldPositionMarker || !worldPositionOrientation) {
      this.removeWorldPositionMaker(timestamp);
      return;
    }

    // Convert the orientation to the building's orientation
    // First, take an arbitrary unit vector (<1,0>) and convert it to building units
    // Then, find the difference between CPS <1,0> and converted <1,0>
    // That becomes the offset of the orientation
    let buildingUnit;
    if (this.buildingType === 'osm') {
      if (mapId) {
        // Immersal
        buildingUnit = floor.gmFloor.originTransform({ x: 1, y: 0 }, mapId, false);
      } else {
        buildingUnit = (this.building as ExploreBuilding).gmBuilding.originTransform(
          { x: 1, y: 0 },
          false
        );
      }
    } else if (this.buildingType === 'imdf') {
      buildingUnit = (this.building as ExploreIMDFBuilding).getOriginTransform(
        { x: 1, y: 0 },
        false,
        mapId
      );
    }
    const cpsTan = Math.atan2(0, 1);
    const buildingTan = Math.atan2(buildingUnit.y, buildingUnit.x);
    const diff = buildingTan - cpsTan;

    // Convert the CPS quaternion to Euler angles
    const quaternion = new Quaternion(orientation.x, orientation.y, orientation.z, orientation.w);

    // Adjust the Euler angle from CPS coordinates to Building coordinates
    const eul = new Euler();
    let angle;
    if (mapId) {
      // Immersal
      eul.setFromQuaternion(quaternion, 'ZYX');
      angle = 2 * Math.PI - (eul.z - diff) + Math.PI / 2;
    } else {
      // Fantasmo
      eul.setFromQuaternion(quaternion, 'YZX');
      angle = Math.PI / 2 - eul.y + diff;
    }
    const unit = new Vector3(Math.cos(angle), Math.sin(angle), 0);

    const orientationInDeg = convertRadToDeg(angle);

    const originalPosition = {
      x: convertedCoordinates.x,
      y: convertedCoordinates.y,
      level: floor.level,
      orientation: orientationInDeg,
    };

    // Get the difference in the AR orientation and the CPS orientation
    const diffUnit = getAngleBetweenVectors(worldPositionOrientation, unit);

    // Figure out the distance traveled via AR while the request was going on
    const changeInPositionDuringRequest = this.rawDeviceARPosition.clone().sub(worldPositionMarker);

    // Transform the change in position to the updated world orientation
    changeInPositionDuringRequest.rotateAround(new Vector2(0, 0), getRadsFromUnit(diffUnit));

    const adjustedPosition = {
      x: originalPosition.x + changeInPositionDuringRequest.x,
      y: originalPosition.y + changeInPositionDuringRequest.y,
      level: floor.level,
      orientation: orientationInDeg,
    };

    this.building.renderer?.renderCircleMarker(
      `cpsPosition`,
      new Vector3(originalPosition.x, originalPosition.y, 1.1),
      0xafe1af,
      0.2
    );
    this.building.renderer?.renderArrowMarker(
      `cpsPosition`,
      new Vector3(originalPosition.x, originalPosition.y, 1),
      unit,
      0xafe1af,
      2
    );
    this.building.renderer?.renderCircleMarker(
      `adjustedCpsPosition`,
      new Vector3(adjustedPosition.x, adjustedPosition.y, 1.1),
      0x097969,
      0.2
    );
    this.building.renderer?.renderArrowMarker(
      `adjustedCpsPosition`,
      new Vector3(adjustedPosition.x, adjustedPosition.y, 1),
      unit,
      0x097969,
      2
    );

    return { adjustedPosition, position: originalPosition, thetaUnit: diffUnit };
  }

  confirmCps(
    timestamp: number,
    position: { x: number; y: number; z: number },
    orientation?: { x: number; y: number; z: number; w: number },
    mapId?: string
  ): boolean {
    // Convert the CPS position to building coordinates
    const transformedCpsCoordinates = this.getAdjustedCPSPosition(
      timestamp,
      position,
      orientation,
      mapId
    );
    const rawArPosition = this.rawDeviceARPosition.clone();

    if (this.needsConfirmation && this.positionToConfirm) {
      // It is confirmed if the distance is within CONFIRMATION_DISTANCE_THRESHOLD and the angle is within CONFIRMATION_ANGLE_THRESHOLD
      const thetaDifference: number = calculateDegDifference(
        getAngleFromUnit(transformedCpsCoordinates.thetaUnit),
        getAngleFromUnit(this.positionToConfirm.thetaUnit)
      );

      // Get the distance traveled from the previous CPS position to this CPS position, rotate that around the drift correction theta,
      // add it to the previous adjusted CPS position, and get the distance to the new adjusted CPS position
      const positionChange = rawArPosition.clone().sub(this.rawARAtPositionToConfirm);
      positionChange.rotateAround(
        new Vector2(0, 0),
        getRadsFromUnit(transformedCpsCoordinates.thetaUnit)
      );
      const prevPositionAdjusted = new Vector2(
        this.positionToConfirm.adjustedPosition.x + positionChange.x,
        this.positionToConfirm.adjustedPosition.y + positionChange.y
      );
      this.building.renderer?.renderCircleMarker(
        'prevPositionAdjusted',
        new Vector3(
          transformedCpsCoordinates.adjustedPosition.x,
          transformedCpsCoordinates.adjustedPosition.y,
          2
        ),
        0xae4d4d,
        0.2
      );
      const distance = prevPositionAdjusted.distanceTo(
        new Vector2(
          transformedCpsCoordinates.adjustedPosition.x,
          transformedCpsCoordinates.adjustedPosition.y
        )
      );

      // If the distance or theta are outside of the thresholds, or we are on a different level, we still need to confirm
      if (
        transformedCpsCoordinates.adjustedPosition.level !==
          this.positionToConfirm.adjustedPosition.level ||
        distance > CONFIRMATION_POSITION_THRESHOLD ||
        thetaDifference > CONFIRMATION_THETA_THRESHOLD
      ) {
        this.needsConfirmation = true;
        this.positionToConfirm = transformedCpsCoordinates;
        this.rawARAtPositionToConfirm = rawArPosition;
        this.building.renderer?.renderCircleMarker(
          'positionToConfirm',
          new Vector3(
            this.positionToConfirm.adjustedPosition.x,
            this.positionToConfirm.adjustedPosition.y,
            2
          ),
          0xff0000,
          0.2
        );
        return false;
      }
    } else if (this.needsConfirmation) {
      this.needsConfirmation = true;
      this.positionToConfirm = transformedCpsCoordinates;
      this.rawARAtPositionToConfirm = rawArPosition;
      this.building.renderer?.renderCircleMarker(
        'positionToConfirm',
        new Vector3(
          this.positionToConfirm.adjustedPosition.x,
          this.positionToConfirm.adjustedPosition.y,
          2
        ),
        0xff0000,
        0.2
      );
      return false;
    } else if (
      this.checkForCPSTeleportation(
        timestamp,
        transformedCpsCoordinates.position.x,
        transformedCpsCoordinates.position.y,
        transformedCpsCoordinates.position.level !== this.getPosition().level
      )
    ) {
      return false;
    }

    const { position: cpsPosition, adjustedPosition, thetaUnit } = transformedCpsCoordinates;

    const isPositionAtRoom = !!this.building.roomQueries.getRoomAtPosition(cpsPosition);
    if (timestamp < this.prevWorldPositionTimestamp || !isPositionAtRoom) {
      this.removeWorldPositionMaker(timestamp);
      return false;
    }
    if (this.prevWorldPositionTimestamp) {
      this.removeWorldPositionMaker(this.prevWorldPositionTimestamp);
    }

    // Loop through all the old markers and remove them
    Object.keys(this.rawARPositionMarkers).forEach(k => {
      const i = parseInt(k, 10);
      if (i < timestamp) {
        this.removeWorldPositionMaker(i);
      }
    });
    this.prevWorldPositionTimestamp = timestamp;

    const worldPositionMarker = this.rawARPositionMarkers[`${timestamp}`];
    const worldPositionMarkerActual = this.worldPositionMarkersActual[`${timestamp}`];
    const worldPositionOrientation = this.rawAROrientationMarkers[`${timestamp}`];
    if (!worldPositionMarker || !worldPositionOrientation) {
      this.removeWorldPositionMaker(timestamp);
      return false;
    }

    // Set the drift theta by averaging the last 3
    if (worldPositionOrientation.length() !== 0) {
      this.recentDriftCorrections.unshift(thetaUnit);
      if (this.recentDriftCorrections.length) {
        const currentAvgUnit = this.recentDriftCorrections
          .slice(0, 3)
          .reduce((a, b) => new Vector2(a.x + b.x, a.y + b.y))
          .divideScalar(this.recentDriftCorrections.length);

        const difference = Math.abs(
          getRadsFromUnit(getAngleBetweenVectors(thetaUnit, currentAvgUnit))
        );

        if (difference > 0.18) {
          this.recentDriftCorrections = [thetaUnit];
          this.driftCorrectionTheta = getRadsFromUnit(thetaUnit);
        } else {
          this.driftCorrectionTheta = getRadsFromUnit(currentAvgUnit);
        }
      } else {
        this.recentDriftCorrections = [thetaUnit];
        this.driftCorrectionTheta = getRadsFromUnit(thetaUnit);
      }
    }

    // Calculate the drift from the marker to the CPS position
    let drift = 0;
    if (this.prevSnapPoint.x !== 0 && this.prevSnapPoint.y !== 0) {
      drift = worldPositionMarkerActual
        .clone()
        .sub(new Vector2(cpsPosition.x, cpsPosition.y))
        .length();
      this.calculateDrift(drift);
    }

    // Make sure the room before and after the adjustment is the same
    const newWorldPosition = new Vector2(adjustedPosition.x, adjustedPosition.y);
    const roomBeforeAdjustment = this.building.roomQueries.getRoomAtPosition({
      x: cpsPosition.x,
      y: cpsPosition.y,
      level: cpsPosition.level,
    });
    const roomAfterAdjustment = this.building.roomQueries.getRoomAtPosition({
      x: adjustedPosition.x,
      y: adjustedPosition.y,
      level: adjustedPosition.level,
    });

    // If it is not, fall back to the position before the adjustment
    if (roomBeforeAdjustment !== roomAfterAdjustment) {
      if (Date.now() - timestamp > 5000) {
        return false;
      }
      newWorldPosition.set(cpsPosition.x, cpsPosition.y);
    }

    // Reset the teleportation counter
    this.teleportationCounter = 0;

    // Update to the new position
    this.finalARposition.set(newWorldPosition.x, newWorldPosition.y);
    this.prevSnapPoint.set(newWorldPosition.x, newWorldPosition.y);
    this.uncorrectedARposition.set(newWorldPosition.x, newWorldPosition.y);
    this.position.x = newWorldPosition.x;
    this.position.y = newWorldPosition.y;
    this.position.orientation = transformedCpsCoordinates.position.orientation;
    this.position.heading = transformedCpsCoordinates.position.orientation;

    const { level } = transformedCpsCoordinates.position;
    this.building.renderer?.renderFloor(this.building.getFloor(level));
    if (level !== this.position.level) {
      this.position.level = level;
    }
    // If we made it this far, use the position as the new world position
    this.needsConfirmation = false;
    return true;
  }

  newConfirmCps(
    timestamp: number,
    position: { x: number; y: number; z: number },
    orientation?: { x: number; y: number; z: number; w: number },
    mapId?: any
  ): boolean {
    const worldPositionMarker = this.rawARPositionMarkers[`${timestamp}`];
    const worldPositionMarkerActual = this.worldPositionMarkersActual[`${timestamp}`];
    const worldPositionOrientation = this.rawAROrientationMarkers[`${timestamp}`];
    if (!worldPositionMarker || !worldPositionOrientation) {
      this.removeWorldPositionMaker(timestamp);
      return false;
    }
    // 1. when a localization comes in, stash the localization as the "Hypothesis"
    // 2. track AR relative to the "Hypothesis" -- this becomes the "Estimated Position"
    // 3. when the new localization comes in, compare it to the "Estimated Position"
    // 4. if the new localization is within the threshold of the Estimated Position, it becomes the Established Position
    // 5. repeat steps 4 and 5 indefinitely
    // 6. if a new localization comes in that is not within threshold, go back to step 2 while maintaining the current established position

    // Convert the CPS position to building coordinates
    const transformedCpsCoordinates = this.getAdjustedCPSPosition(
      timestamp,
      position,
      orientation,
      mapId
    );
    const { position: cpsPosition, adjustedPosition, thetaUnit } = transformedCpsCoordinates;
    const rawArPosition = this.rawDeviceARPosition.clone();

    console.log('--');
    const isPositionAtRoom = !!this.building.roomQueries.getRoomAtPosition(cpsPosition);
    if (timestamp < this.prevWorldPositionTimestamp || !isPositionAtRoom) {
      console.log('Position is off the map; throw it out');
      this.removeWorldPositionMaker(timestamp);
      return false;
    }

    // Make sure the room before and after the adjustment is the same
    const newWorldPosition = new Vector2(adjustedPosition.x, adjustedPosition.y);
    const roomBeforeAdjustment = this.building.roomQueries.getRoomAtPosition({
      x: cpsPosition.x,
      y: cpsPosition.y,
      level: cpsPosition.level,
    });
    const roomAfterAdjustment = this.building.roomQueries.getRoomAtPosition({
      x: adjustedPosition.x,
      y: adjustedPosition.y,
      level: adjustedPosition.level,
    });

    // If it is not, fall back to the position before the adjustment
    if (roomBeforeAdjustment !== roomAfterAdjustment) {
      if (Date.now() - timestamp > 5000) {
        console.log('Position changed rooms; throw it out');
        return false;
      }
      newWorldPosition.set(cpsPosition.x, cpsPosition.y);
    }

    console.log('needsConfirmation:\t', this.needsConfirmation);

    if (this.positionToConfirm) {
      // It is confirmed if the distance is within CONFIRMATION_DISTANCE_THRESHOLD and the angle is within CONFIRMATION_ANGLE_THRESHOLD
      const thetaDifference: number = calculateDegDifference(
        getAngleFromUnit(transformedCpsCoordinates.thetaUnit),
        getAngleFromUnit(this.positionToConfirm.thetaUnit)
      );

      // Get the distance traveled from the previous CPS position to this CPS position, rotate that around the drift correction theta,
      // add it to the previous adjusted CPS position, and get the distance to the new adjusted CPS position
      const positionChange = rawArPosition.clone().sub(this.rawARAtPositionToConfirm);
      positionChange.rotateAround(
        new Vector2(0, 0),
        getRadsFromUnit(transformedCpsCoordinates.thetaUnit)
      );
      const prevPositionAdjusted = new Vector2(
        this.positionToConfirm.adjustedPosition.x + positionChange.x,
        this.positionToConfirm.adjustedPosition.y + positionChange.y
      );
      this.building.renderer?.renderCircleMarker(
        'prevPositionAdjusted',
        new Vector3(
          transformedCpsCoordinates.adjustedPosition.x,
          transformedCpsCoordinates.adjustedPosition.y,
          2
        ),
        0xae4d4d,
        0.2
      );
      const distance = prevPositionAdjusted.distanceTo(
        new Vector2(
          transformedCpsCoordinates.adjustedPosition.x,
          transformedCpsCoordinates.adjustedPosition.y
        )
      );

      console.log('distance:\t\t', distance);
      console.log('theta distance:\t', thetaDifference);

      // If the distance or theta are outside of the thresholds, or we are on a different level, we still need to confirm
      if (
        transformedCpsCoordinates.adjustedPosition.level !==
          this.positionToConfirm.adjustedPosition.level ||
        distance > CONFIRMATION_POSITION_THRESHOLD ||
        thetaDifference > CONFIRMATION_THETA_THRESHOLD
      ) {
        console.log('unexpected cps on path');
        this.confirmationCounter = 0;
        this.needsConfirmation = true;
        this.positionToConfirm = transformedCpsCoordinates;
        this.rawARAtPositionToConfirm = rawArPosition;
        this.building.renderer?.renderCircleMarker(
          'positionToConfirm',
          new Vector3(
            this.positionToConfirm.adjustedPosition.x,
            this.positionToConfirm.adjustedPosition.y,
            2.5
          ),
          0xff0000,
          0.5
        );
        return false;
      }

      console.log('good cps on estimated path');
      this.confirmationCounter++;
      this.building.renderer?.renderCircleMarker(
        'confirmedPosition',
        new Vector3(
          this.positionToConfirm.adjustedPosition.x,
          this.positionToConfirm.adjustedPosition.y,
          2.5
        ),
        0x00ff00,
        0.5
      );
      this.needsConfirmation = true;
      this.positionToConfirm = transformedCpsCoordinates;
      this.rawARAtPositionToConfirm = rawArPosition;
      this.building.renderer?.renderCircleMarker(
        'positionToConfirm',
        new Vector3(
          this.positionToConfirm.adjustedPosition.x,
          this.positionToConfirm.adjustedPosition.y,
          2.5
        ),
        0xff0000,
        0.5
      );
      if (this.confirmationCounter < CONFIRMATION_COUNT_REQUIREMENT) {
        console.log('not yet');
        return false;
      }
    } else {
      console.log('initial cps');
      this.confirmationCounter = 0;
      this.needsConfirmation = true;
      this.positionToConfirm = transformedCpsCoordinates;
      this.rawARAtPositionToConfirm = rawArPosition;
      this.building.renderer?.renderCircleMarker(
        'positionToConfirm',
        new Vector3(
          this.positionToConfirm.adjustedPosition.x,
          this.positionToConfirm.adjustedPosition.y,
          2.5
        ),
        0xff0000,
        0.5
      );
      return false;
    }

    // We've succeeded -- let's move on and clean up the old stuff
    if (this.prevWorldPositionTimestamp) {
      this.removeWorldPositionMaker(this.prevWorldPositionTimestamp);
    }

    // Loop through all the old markers and remove them
    Object.keys(this.rawARPositionMarkers).forEach(k => {
      const i = parseInt(k, 10);
      if (i < timestamp) {
        this.removeWorldPositionMaker(i);
      }
    });
    this.prevWorldPositionTimestamp = timestamp;

    // Set the drift theta by averaging the last 3
    if (worldPositionOrientation.length() !== 0) {
      this.recentDriftCorrections.unshift(thetaUnit);
      if (this.recentDriftCorrections.length) {
        const currentAvgUnit = this.recentDriftCorrections
          .slice(0, 3)
          .reduce((a, b) => new Vector2(a.x + b.x, a.y + b.y))
          .divideScalar(this.recentDriftCorrections.length);

        const difference = Math.abs(
          getRadsFromUnit(getAngleBetweenVectors(thetaUnit, currentAvgUnit))
        );

        if (difference > 0.18) {
          this.recentDriftCorrections = [thetaUnit];
          this.driftCorrectionTheta = getRadsFromUnit(thetaUnit);
        } else {
          this.driftCorrectionTheta = getRadsFromUnit(currentAvgUnit);
        }
      } else {
        this.recentDriftCorrections = [thetaUnit];
        this.driftCorrectionTheta = getRadsFromUnit(thetaUnit);
      }
    }

    // Calculate the drift from the marker to the CPS position
    let drift = 0;
    if (this.prevSnapPoint.x !== 0 && this.prevSnapPoint.y !== 0) {
      drift = worldPositionMarkerActual
        .clone()
        .sub(new Vector2(cpsPosition.x, cpsPosition.y))
        .length();
      this.calculateDrift(drift);
    }

    // Reset the teleportation counter
    this.teleportationCounter = 0;

    // Update to the new position
    this.finalARposition.set(newWorldPosition.x, newWorldPosition.y);
    this.prevSnapPoint.set(newWorldPosition.x, newWorldPosition.y);
    this.uncorrectedARposition.set(newWorldPosition.x, newWorldPosition.y);
    this.position.x = newWorldPosition.x;
    this.position.y = newWorldPosition.y;
    this.position.orientation = transformedCpsCoordinates.position.orientation;
    this.position.heading = transformedCpsCoordinates.position.orientation;

    const { level } = transformedCpsCoordinates.position;
    this.building.renderer?.renderFloor(this.building.getFloor(level));
    if (level !== this.position.level) {
      this.position.level = level;
    }

    // If we hit the confirmation count, we've established a new position, and can reroute.
    // Otherwise, proceed as normal.
    return this.confirmationCounter === CONFIRMATION_COUNT_REQUIREMENT;
  }

  isCPSInCorrectLevel(position: { x: number; y: number; z: number }, mapId): boolean {
    try {
      if (!mapId) return true;
      const { x, y, z } = position;
      let floor: Floor;
      if (mapId) {
        floor = this.building.floors.find(f => f.cpsMapIds?.includes(`${mapId}`));
      }
      const convertedCoordinates = floor?.gmFloor?.originTransform(
        {
          x,
          y,
          z,
        },
        mapId
      );
      if (convertedCoordinates?.x === 0 && convertedCoordinates?.y === 0) return false;
      return true;
    } catch (e) {
      console.log(e);
      return true;
    }
  }

  // TODO: Merge this with applyWorldPosition
  setCPS(
    position: { x: number; y: number; z: number },
    orientation?: { x: number; y: number; z: number; w: number },
    mapId?: string
  ): { position: Vector2; orientation: number, floor: Floor } {
    const { x, y, z } = position;
    let convertedCoordinates;
    // let { originTransform } = (this.building as ExploreBuilding).gmBuilding;
    let floor: Floor;
    if (mapId) {
      floor = this.building.floors.find(f => f.cpsMapIds?.includes(`${mapId}`));
    }

    if (this.buildingType === 'osm') {
      if (mapId) {
        convertedCoordinates = floor.gmFloor.originTransform({ x, y, z }, mapId);
      } else {
        convertedCoordinates = (this.building as ExploreBuilding).gmBuilding.originTransform({
          x,
          y,
          z,
        });
      }
    } else if (this.buildingType === 'imdf') {
      convertedCoordinates = (this.building as ExploreIMDFBuilding).getOriginTransform(
        { x, y, z },
        true,
        mapId
      );
    }
    const { level } = convertedCoordinates;
    // Render the new floor if it changed
    if (!floor) {
      floor = this.building.getFloor(level);
      if (!floor) return;
    }

    this.building.renderer?.renderFloor(floor);
    let buildingUnit;
    if (this.buildingType === 'osm') {
      if (mapId) {
        buildingUnit = floor.gmFloor.originTransform({ x: 1, y: 0 }, mapId, false);
      } else {
        buildingUnit = (this.building as ExploreBuilding).gmBuilding.originTransform(
          { x: 1, y: 0 },
          false
        );
      }
    } else if (this.buildingType === 'imdf') {
      buildingUnit = (this.building as ExploreIMDFBuilding).getOriginTransform(
        { x: 1, y: 0 },
        false,
        mapId
      );
    }
    const beaconTan = Math.atan2(0, 1);
    const ARTan = Math.atan2(buildingUnit.y, buildingUnit.x);
    const diff = ARTan - beaconTan;

    const quaternion = new Quaternion(orientation.x, orientation.y, orientation.z, orientation.w);

    const eul = new Euler();
    let angle = 0;
    if (mapId) {
      // Immersal
      eul.setFromQuaternion(quaternion, 'ZYX');
      angle = 2 * Math.PI - (eul.z - diff) + Math.PI / 2;
    } else {
      // Fantasmo
      eul.setFromQuaternion(quaternion, 'YZX');
      angle = Math.PI / 2 - eul.y + diff;
    }

    const unit = new Vector3(Math.cos(angle), Math.sin(angle), 0);

    const newPosition = new Vector2(convertedCoordinates.x, convertedCoordinates.y);

    if (this.building.debug) {
      this.building.renderer?.renderCircleMarker(
        'user',
        new Vector3(newPosition.x, newPosition.y, USER_BLUEDOT_Z_POSITION),
        0x0000ff,
        0.4
      );
      this.building.renderer?.renderArrowMarker(
        'userOrientation',
        new Vector3(newPosition.x, newPosition.y, USER_BLUEDOT_Z_POSITION),
        unit,
        0x0000ff,
        3
      );
    }

    return { position: newPosition, orientation: angle, floor };
  }

  // TODO: Merge this with applyWorldPosition
  updateCPS(
    timestamp: number,
    position: { x: number; y: number; z: number },
    orientation?: { x: number; y: number; z: number; w: number }
  ) {
    const { x, y, z } = position;
    let convertedCoordinates: Position;
    if (this.buildingType === 'osm') {
      convertedCoordinates = (this.building as ExploreBuilding).gmBuilding.originTransform(
        { x, y, z },
        false
      );
    } else if (this.buildingType === 'imdf') {
      convertedCoordinates = (this.building as ExploreIMDFBuilding).getOriginTransform(
        { x, y, z },
        false
      );
    }
    const { level } = convertedCoordinates;
    // Render the new floor if it changed
    const floor = this.building.getFloor(level);
    if (!floor) {
      return;
    }

    let buildingUnit: Position;
    if (this.buildingType === 'osm') {
      buildingUnit = (this.building as ExploreBuilding).gmBuilding.originTransform(
        { x: 1, y: 0 },
        false
      );
    } else if (this.buildingType === 'imdf') {
      buildingUnit = (this.building as ExploreIMDFBuilding).getOriginTransform(
        { x: 1, y: 0 },
        false
      );
    }
    const beaconTan = Math.atan2(0, 1);
    const ARTan = Math.atan2(buildingUnit.y, buildingUnit.x);
    const diff = ARTan - beaconTan;

    const quaternion = new Quaternion(orientation.x, orientation.y, orientation.z, orientation.w);

    const eul = new Euler();
    eul.setFromQuaternion(quaternion, 'YZX');

    const angle = Math.PI / 2 - eul.y + diff;
    const unit = new Vector3(Math.cos(angle), Math.sin(angle), 0);

    if (
      !this.checkForCPSTeleportation(
        timestamp,
        convertedCoordinates.x,
        convertedCoordinates.y,
        level !== this.getPosition().level
      )
    ) {
      this.applyWorldPosition(
        timestamp,
        convertedCoordinates.x,
        convertedCoordinates.y,
        level,
        unit
      );

      const { x: newx, y: newy } = this.getPosition();

      this.position.x = newx;
      this.position.y = newy;
      this.position.orientation = convertRadToDeg(angle);

      this.building.renderer?.renderFloor(this.building.getFloor(level));
      if (level !== this.position.level) {
        this.position.level = level;
      }
    } else {
      this.removeWorldPositionMaker(timestamp);
    }

    if (this.building.debug) {
      this.building.renderer?.renderCircleMarker(
        'user',
        new Vector3(convertedCoordinates.x, convertedCoordinates.y, USER_BLUEDOT_Z_POSITION),
        0x0000ff,
        0.4
      );
      this.building.renderer?.renderArrowMarker(
        'userOrientation',
        new Vector3(convertedCoordinates.x, convertedCoordinates.y, USER_BLUEDOT_Z_POSITION),
        getUnitFromAngle(this.position.orientation),
        0x0000ff,
        3
      );
      // this.building.renderer?.renderCircleMarker(
      //   'cps',
      //   new Vector3(convertedCoordinates.x, convertedCoordinates.y, 1.1),
      //   0xff69b4,
      //   0.2
      // );
      // this.building.renderer?.renderArrowMarker(
      //   'cps',
      //   new Vector3(convertedCoordinates.x, convertedCoordinates.y, 1.1),
      //   unit,
      //   0xff69b4,
      //   2
      // );
    }
  }

  allowTeleportation = () => {
    this.teleportationCounter = 1;
  };

  checkForCPSTeleportation = (timestamp: number, cpsX: number, cpsY: number, newLevel: boolean) => {
    // Always let the initial position fly
    if (this.prevSnapPoint.x === 0 && this.prevSnapPoint.y === 0) {
      return false;
    }

    const driftFromEstimatedPosition = new Vector2(cpsX, cpsY)
      .sub(this.worldPositionMarkersActual[timestamp])
      .length();
    console.log('~~ Drift from estimated position: ', driftFromEstimatedPosition);

    if (this.teleportationCounter === 1) {
      return false;
    }
    if (!newLevel && driftFromEstimatedPosition > this.TELEPORTATION_DISTANCE_SAME_LEVEL) {
      console.log('~~ Throwing out CPS - same level');
      this.teleportationCounter++;
      return true;
    }
    if (newLevel && driftFromEstimatedPosition > this.TELEPORTATION_DISTANCE_DIFFERENT_LEVEL) {
      console.log('~~ Throwing out CPS - different level');
      this.teleportationCounter++;
      return true;
    }
    return false;
  };

  getDriftCorrection = () => this.driftCorrectionTheta;

  calculateDrift = (d: number) => {
    this.drift.currentDrift = d;
    this.drift.previousDrifts.push(d);
    this.drift.averageDrift =
      this.drift.previousDrifts.reduce((a, b) => a + b) / this.drift.previousDrifts.length;
    this.drift.medianDrift = this.drift.previousDrifts[
      Math.floor(this.drift.previousDrifts.length / 2)
    ];

    // console.log('-- DRIFT --');
    // console.log('Current:\t', drift.currentDrift);
    // console.log('Average:\t', drift.averageDrift);
    // console.log('Median:\t', drift.medianDrift);
  };

  reset() {
    Object.keys(this.rawARPositionMarkers).forEach(k => {
      this.removeWorldPositionMaker(parseInt(k, 10));
    });
    this.prevWorldPositionTimestamp = 0;
    this.rawDeviceARPosition.set(0, 0);
    this.finalARposition.set(0, 0);
    this.uncorrectedARposition.set(0, 0);
    this.rawARPositionMarkers = {};
    this.rawAROrientationMarkers = {};
    this.prevSnapPoint.set(0, 0);
    this.driftCorrectionTheta = 0;
    this.recentMetersPerMillisecond = [];
    this.speed = 0;
    this.recentHeadings = [];
    this.heading = 0;
    this.recentDriftCorrections = [];
    this.teleportationCounter = 0;
    this.drift.currentDrift = 0;
    this.drift.previousDrifts = [];
    this.drift.averageDrift = 0;
    this.drift.medianDrift = 0;
  }
}
