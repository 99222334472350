import React, { useEffect, useState } from 'react';
import * as Three from 'three';
import { useSelector } from 'react-redux';
import Map from 'screens/Map';
import './Directions.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import { RoutePoint } from '_sdks/cavu-sdk/src';
import * as CPSActions from 'actions/RouteLogsActions';
import { RootState } from 'App';
import { getCurrentExploreEntity } from 'helpers/BuildingManagerHelper';

const Directions = () => {
  const { rawInstructions } = useSelector((state: RootState) => state.directions);
  const [tableEnabled] = useState(true);

  useEffect(() => {
    // Renderer.setSnapToUser(true);
    // ARPositioning.resetARPositioning();
    // Routing.clearCurrentRoute();
    CPSActions.stop();
  }, []);

  const expandRow = {
    onExpand: data => {
      const point = data.point as RoutePoint;
      getCurrentExploreEntity().renderer.renderCircleMarker(
        'highlightedNode',
        new Three.Vector3(point.position.x, point.position.y, 0.3),
        0x00ff00,
        1
      );
    },
    renderer: data => {
      const { point, angleToNextPoint, distanceToNextPoint } = data;
      const { id, level, connectionType, poi, neighbors } = point;
      return (
        <div>
          <p>
            <b>ID: </b>
            {id}
          </p>
          <p>
            <b>Level: </b>
            {level}
          </p>
          <p>
            <b>Angle to Next Point: </b>
            {angleToNextPoint}
          </p>
          <p>
            <b>Distance to Next Point: </b>
            {distanceToNextPoint}
          </p>
          <p>
            <b>Connection Type: </b>
            {connectionType || '-'}
          </p>

          {poi && (
            <div className="bg-light-green">
              <p>
                <b>POI</b>
              </p>
              <p>{poi.id}</p>
              <p>{poi.name}</p>
            </div>
          )}

          <div className="bg-light-blue">
            <b>Neighbors</b>
            {Object.keys(neighbors).map(neighborID => {
              const neighbor = neighbors[neighborID];
              return (
                <div key={neighbor.point.id}>
                  <p>
                    {`${neighbor.point.id} ${
                      neighbor.point.poi ? `, ${neighbor.point.poi.name}` : ''
                    }`}
                    <br />
                    {neighbor.distance}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      );
    },
  };

  return (
    <div className="flex flex-auto flex-column">
      <div className="flex flex-auto flex-row h-100">
        <Map />

        {tableEnabled ? (
          <div className="h-100 bg-white shadow-2 relative" style={{ flex: 0.5, zIndex: 1 }}>
            <div className="overflow-auto instructions-table">
              <BootstrapTable
                className="instructions-table"
                keyField="point.id"
                data={rawInstructions}
                columns={[
                  {
                    text: 'ID',
                    dataField: 'point.id',
                    style: {
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word',
                    },
                  },
                  { text: 'Direction', dataField: 'directionToNextPoint' },
                  { text: 'Level', dataField: 'point.level' },
                  { text: 'Conn', dataField: 'point.connectionType' },
                ]}
                expandRow={expandRow}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Directions;
