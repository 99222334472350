import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { authenticateUser } from 'actions/AuthenticationActions';
import ESDKVersion from '_sdks/cavu-sdk/package.json';
import GMSDKVersion from 'goodmaps-sdk/package.json';
import CONFIG from '../../global/config';
import SimVersion from '../../../package.json';
import './LoginScreen.scss';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    dispatch(authenticateUser(username, password));
  };

  return (
    <div className="screen-container">
      <img src={require('../../assets/logo.png')} />
      <div className="form-container">
        <Form>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={username}
              onChange={e => {
                setUsername(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
          <Button
            disabled={!username || !password}
            variant="primary"
            type="submit"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Form>
      </div>
      <div className="environment">
        {CONFIG.NAME}
        <br />v{SimVersion.version}
      </div>
      <div className="versions">
        ExploreSDK v{ESDKVersion.version}
        <br />
        GoodMapsSDK v{GMSDKVersion.version}
      </div>
    </div>
  );
};

export default LoginScreen;
