import { RootState } from 'App';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  dataSetId: string;
};

const useDataLoggerFileFetchHook = ({ dataSetId }: Props) => {
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { dataLoggerFiles } = useSelector((state: RootState) => state.datalogger);
  useEffect(() => {
    const nameOfFile = dataLoggerFiles.find(d => d.dataSetId === dataSetId).name.split(' - ')[1];
    if (nameOfFile) {
      setFileName(nameOfFile);
    }
    setIsLoading(false);
  }, [dataSetId]);

  return { isFetchLoading: isLoading, fileName };
};

export default useDataLoggerFileFetchHook;
