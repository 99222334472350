import * as gm from 'goodmaps-sdk';
import { WebGLRenderer } from 'three';
import { POI } from './entities/POI';
import Room from './entities/Room';
import { Floor } from './entities/Floor';
import { RouteNetwork } from './routing/RouteNetwork';
import RoomQueries from './queries/RoomQueries';
import Renderer from './renderer/Renderer';
import { ExplorePosition } from './types';
import POIQueries from './queries/POIQueries';
import VirtualPositioning from './positioning/VirtualPositioning';

export default abstract class ExploreEntity {
  name: string;

  testPoints: POI[] = [];

  pois: POI[] = [];

  doors: POI[] = [];

  rooms: Room[] = [];

  poiQueries: POIQueries;

  roomQueries: RoomQueries;

  floors: Floor[] = [];

  entityTable: { [id: string]: any } = {};

  routeNetwork: RouteNetwork;

  renderer: Renderer | undefined;

  debug: Boolean = false;

  cpsMapId: string = null;

  virtualPositioning: VirtualPositioning;

  cpsType: 'f' | 'fi' | 'li' = 'fi';

  cpsRegion: 'us' | 'uk' | 'az' | '' = '';

  source: 'az' | 'aws';

  id: string;

  constructor() {
    this.poiQueries = new POIQueries(this);
    this.roomQueries = new RoomQueries(this);
    this.virtualPositioning = new VirtualPositioning(this);
  }

  createRenderer(
    renderer: WebGLRenderer,
    width: number,
    height: number,
    onInit?: (r: Renderer) => void,
    onRender?: (r: Renderer) => void
  ) {
    this.renderer = new Renderer(this, renderer, width, height, onInit, onRender);
  }

  abstract getPosition(): ExplorePosition;

  abstract insideOf(position: { lat: number; lon: number }, range: number): boolean;

  abstract getCpsMapIds(): string[];

  getFloor(level: number) {
    return this.floors.find(f => f.level === level);
  }

  getRoom(id: string) {
    return this.entityTable[id];
  }

  getPOI(id: string) {
    return this.entityTable[id];
  }
}
