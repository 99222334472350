import {
  BUILDING_SELECTED,
  DIRECTIONS_CREATED,
  DIRECTIONS_UPDATED,
  TEST_PERCENTAGE_UPDATED,
} from '../actions/types';

const initialState = {
  pois: [],
  selectedBuilding: null,
  rawInstructions: [],
  tripInstructions: [],
  currentInstruction: '',
  selectedCampus: {},
  percentage: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BUILDING_SELECTED: {
      const { selectedBuilding, pois } = action.payload;
      return { ...state, pois, selectedBuilding };
    }

    case DIRECTIONS_CREATED: {
      const { rawInstructions, tripInstructions } = action.payload;
      return { ...state, rawInstructions, tripInstructions };
    }

    case DIRECTIONS_UPDATED:
      // eslint-disable-next-line no-case-declarations
      const { rawInstructions, tripInstructions, currentInstruction } = action.payload;
      return { ...state, tripInstructions, rawInstructions, currentInstruction };

    // case CAMPUS_SELECTED: {
    //   const { worldController, selectedCampus, pois } = action.payload;
    //   return { ...state, selectedCampus };
    // }
    case TEST_PERCENTAGE_UPDATED:
      return { ...state, percentage: action.payload };

    default:
      return state;
  }
};
