/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Spinner, Button, Modal } from 'react-bootstrap';
import SearchAndFilter from '../../components/SearchAndFilter';
import DataLoggerEditModal from './DataLoggerEditModal';
import '../../components/DataList.scss';
import { DataLoggerMetadataExt } from 'actions/DataLoggerActions';

type Props = {
  datasets: DataLoggerMetadataExt[];
  onDatasetSelect: any;
  onDeleteButtonClick?: any;
};

const DatasetViewer = ({ datasets, onDatasetSelect, onDeleteButtonClick }: Props) => {
  const [filteredSets, setFilteredSets] = useState<DataLoggerMetadataExt[]>(datasets);
  const [selectedDataSetId, setSelectedDataSetId] = useState('');
  const [selectedDataSet, setSelectedDataSet] = useState<DataLoggerMetadataExt>();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('none');

  useEffect(() => {
    setFilteredSets(datasets);
  }, [datasets]);

  const onSearchFilterChange = (searchText, dateFilterType, rateFilterType) => {
    let filteredDatasets = datasets;

    searchText = searchText.toLowerCase();
    if (searchText) {
      filteredDatasets = datasets.filter(d =>
        `${d.name} ${d.description}`.toLowerCase().includes(searchText)
      );
    }

    if (rateFilterType !== '0') {
      filteredDatasets = filteredDatasets.filter(
        ds => ds.description !== undefined && ds.description[0] === rateFilterType
      );
    }

    if (dateFilterType !== '') {
      // setSort(dateFilterType);
    }
    setFilteredSets(filteredDatasets);
  };

  const closeModal = () => {
    setMode('none');
  };

  return (
    <div>
      <SearchAndFilter onSearchFilterChange={onSearchFilterChange} shouldHideRatingFilter />
      <div className="data-list">
        {filteredSets.map((d, i) => {
          const info = d.name.split(' - ');
          const deviceName = info[0];
          const fileName = info[1];
          const date = new Date(d.createdAtISO);
          return (
            <div
              key={i}
              className={`list-item flex-column ${
                d.dataSetId === selectedDataSetId ? 'selected' : ''
              }`}
            >
              {d.dataSetId === selectedDataSetId && isLoading ? (
                <div className="spinner-container">
                  <Spinner animation="border" role="status" />
                </div>
              ) : (
                <div
                  className="tile-interior"
                  onClick={() => {
                    setSelectedDataSetId(d.dataSetId);
                    setSelectedDataSet(d);
                  }}
                >
                  <div
                    className="flex flex-column"
                    onClick={() => {
                      setIsLoading(true);
                      onDatasetSelect(d);
                    }}
                  >
                    <div className="item-name" id="image-name">
                      <div className="flex flex-row ">
                        <div className="flex flex-column flex-auto">
                          <b>{fileName}</b>
                          {deviceName}
                          <br />
                          {date.toLocaleString()}
                          <br />
                          <div className="flex flex-row">
                            {d.source === 'aws' ? <b>AWS</b> : <b>Azure</b>}
                            <b style={{ marginLeft: '5px' }}>{d.type}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="datalogger-action-buttons">
                    {d.type === '2.0' ? (
                      <Button variant="primary" onClick={() => setMode('edit')}>
                        Edit
                      </Button>
                    ) : null}
                  </div>
                  {/* TODO: Add delete back in when we have the ability to delete from 2.0 */}
                  {/* <div className="action-buttons">
                    <Button
                      className="delete-btn"
                      variant="danger"
                      // onClick={() => onDeleteClick(d.metaData.id)}
                    >
                      Delete
                    </Button>
                  </div> */}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Modal size="lg" centered show={mode === 'edit'} onHide={closeModal}>
        <DataLoggerEditModal onClose={closeModal} dataSet={selectedDataSet} />
      </Modal>
    </div>
  );
};

export default DatasetViewer;
