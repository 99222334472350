export const AVERAGE_WALKING_SPEED = 3;

// Distance away from a beacon you can be to be pinned
export const PINNING_DISTANCE = 2.0;

// How often you can pin to the same beacon
export const PINNING_DELAY = 30000;

export const DEFAULT_AR_WEIGHT = 100;
export const DEFAULT_BEACON_WEIGHT = 1;
export const DEFAULT_ROUTE_WEIGHT = 4;

export const SCENE_COLOR = 0x091262;
export const OUTLINE_COLOR = 0xffffff;
export const CORRIDOR_COLOR = 0xffffff;
export const PUBLIC_ROOM_COLOR = 0xdddddd;
export const NON_PUBLIC_ROOM_COLOR = 0xffffff;
export const SHOP_COLOR = 0xcaedf6;
export const RESTAURANT_COLOR = 0xffe1b8;
export const KITCHEN_COLOR = 0xffd4b7;
export const CONFERENCE_COLOR = 0xdbe7ff;
export const LOBBY_COLOR = 0xcdf2e8;
export const RESTROOM_COLOR = 0xeee5f8;
export const CONNECTOR_COLOR = 0xffedce;
export const BEACON_COLOR = 0xffe658;
export const ENTITY_SELECTED_COLOR = 0x414143;
export const BEDROOM_COLOR = 0xfae3f7;

// Z Positions
export const USER_BLUEDOT_Z_POSITION = 1.3;

export const APH_COORDS = {
  lat: 38.257848511500164,
  lon: -85.71402195190463,
};
