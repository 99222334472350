import { Vector2, Vector3, Math as TMath } from 'three';
import { ExplorePosition } from '../types';
import Positioning from './Positioning';
import {
  getDistanceBetweenPoints,
  getUnitFromAngle,
  getVectorBetweenPoints,
} from '../helpers/MathHelpers';
import ExploreEntity from '../ExploreEntity';

const USER_BLUEDOT_Z_POSITION = 1.3;

export default class VirtualPositioning extends Positioning {
  building: ExploreEntity;

  private position: ExplorePosition = {
    x: 0,
    y: 0,
    level: 0,
    orientation: 0,
    speed: 0,
    heading: 0,
  };

  constructor(building: ExploreEntity) {
    super();
    this.building = building;
  }

  getPosition() {
    return this.position;
  }

  setPosition(position: ExplorePosition) {
    this.building.renderer?.renderFloor(this.building.getFloor(position.level));

    if (position.level !== this.position.level) {
      this.position.level = position.level;
    }

    this.position = position;

    if (this.building.debug) {
      this.building.renderer.renderCircleMarker(
        'user',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        0x0000ff,
        0.4
      );
      this.building.renderer.renderArrowMarker(
        'userOrientation',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        getUnitFromAngle(this.position.orientation),
        0x0000ff,
        3
      );
    }
  }

  updateOrientation(angle: number) {
    this.position.orientation = angle;

    if (this.building.debug) {
      this.building.renderer.renderArrowMarker(
        'userOrientation',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        getUnitFromAngle(this.position.orientation),
        0x0000ff,
        3
      );
    }
  }

  walkTowardPoint(
    from: ExplorePosition,
    to: ExplorePosition,
    speed: number,
    orientationUponArrival: number = 0
  ) {
    const fromVector = new Vector2(from.x, from.y);
    const toVector = new Vector2(to.x, to.y);
    const distanceToNextPoint = getDistanceBetweenPoints(fromVector, toVector);

    const unit = getVectorBetweenPoints(fromVector, toVector).normalize();
    if (distanceToNextPoint < speed) {
      // Always put the point a little bit before it
      this.setPosition({
        x: to.x - unit.x * 0.05,
        y: to.y - unit.y * 0.05,
        level: to.level,
        orientation: orientationUponArrival || TMath.radToDeg(Math.atan2(unit.y, unit.x)),
        heading: TMath.radToDeg(Math.atan2(unit.y, unit.x)),
      });
    } else {
      this.setPosition({
        x: from.x + unit.x * speed,
        y: from.y + unit.y * speed,
        level: from.level,
        orientation: TMath.radToDeg(Math.atan2(unit.y, unit.x)),
        heading: TMath.radToDeg(Math.atan2(unit.y, unit.x)),
      });
    }
  }

  moveThroughConnection(connectionExit: ExplorePosition, pointAfterConnection: ExplorePosition) {
    const connectionExitVector = new Vector2(connectionExit.x, connectionExit.y);
    const pointAfterConnectionVector = new Vector2(pointAfterConnection.x, pointAfterConnection.y);

    let justOutsideConnection = pointAfterConnectionVector
      .clone()
      .sub(connectionExitVector)
      .normalize();
    justOutsideConnection = justOutsideConnection.multiplyScalar(0.25);
    this.setPosition({
      x: pointAfterConnectionVector.x + justOutsideConnection.x,
      y: pointAfterConnectionVector.y + justOutsideConnection.y,
      level: pointAfterConnection.level,
      orientation: TMath.radToDeg(Math.atan2(justOutsideConnection.y, justOutsideConnection.x)),
      heading: TMath.radToDeg(Math.atan2(justOutsideConnection.y, justOutsideConnection.x)),
    });
  }
}
