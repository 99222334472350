import * as THREE from 'three';

export const BEACON_MATERIAL = new THREE.MeshBasicMaterial({ color: 0x41bcff });
export const BEACON_HIGHLIGHTED_MATERIAL = new THREE.MeshBasicMaterial({ color: 0xffff00 });
export const BEACON_RADIUS_MATERIAL = new THREE.MeshBasicMaterial({
  color: 0x41bcff,
  opacity: 0.15,
  transparent: true,
});
export const POI_MATERIAL = new THREE.MeshBasicMaterial({ color: 0xff803b });
export const MARKER_MATERIAL = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
export const TEST_POINT_MATERIAL = new THREE.MeshBasicMaterial({ color: 0x8f0000 });
export const USER_MATERIAL = new THREE.MeshBasicMaterial({ color: 0xff0000 });

export const ROOM_MATERIAL = new THREE.MeshBasicMaterial({ color: 0xefefef, opacity: 1 });
export const CORRIDOR_MATERIAL = new THREE.MeshBasicMaterial({ color: 0xd3d3d3, opacity: 1 });
export const ROOM_EDGES_MATERIAL = new THREE.LineBasicMaterial({ color: 0x333333, linewidth: 2 });

export const ROUTE_POINT_MATERIAL = new THREE.MeshBasicMaterial({ color: 0x0075fe });
export const ROUTE_EDGES_MATERIAL = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 1 });

export const PERSPECTIVE_CONE_MATERIAL = new THREE.MeshBasicMaterial({
  color: 0x0075fe,
  opacity: 0.4,
  transparent: true,
});
export const CIRCLE_MATERIAL = new THREE.MeshBasicMaterial({
  color: 0x0075fe,
  opacity: 0.4,
  transparent: true,
});
