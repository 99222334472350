import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import {
  initializeBuildingManager,
  updateBuildingManagerWithJWT,
} from 'helpers/BuildingManagerHelper';
import config from '../global/config';
import {
  USER_AUTHENTICATED,
  AUTHENTICATION_ERROR,
  LOAD_USER,
  SIGN_OUT_USER,
  AUTHENTICATED_USER,
} from './types';

let refreshInterval;
const REFRESH_TOKEN_INTERVAL = 5 * 60 * 1000;
let token: string = '';

export const getToken = () => token;

export const authenticateUser = (username: string, password: string) => async dispatch =>
  new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    const userPool = new CognitoUserPool({
      UserPoolId: config.userPoolId,
      ClientId: config.userPoolWebClientId,
    });
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        dispatch(getAuthenticatedUser());
        dispatch({ type: USER_AUTHENTICATED });
        return resolve(true);
      },
      onFailure: err => {
        console.log(err || JSON.stringify(err));
        dispatch({ type: AUTHENTICATION_ERROR });
        return reject(err);
      },
      newPasswordRequired(userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        delete userAttributes.email_verified;
      },
    });
  });

export const getAuthenticatedUser = () => dispatch =>
  new Promise((resolve, reject) => {
    const userPool: any = new CognitoUserPool({
      UserPoolId: config.userPoolId,
      ClientId: config.userPoolWebClientId,
    });
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log(err.message || JSON.stringify(err));
          return reject(err);
        }
        token = session.getIdToken().getJwtToken();
        initializeBuildingManager(session.getIdToken().getJwtToken());
        dispatch({
          type: AUTHENTICATED_USER,
          payload: session.getIdToken().getJwtToken(),
        });
        dispatch({ type: USER_AUTHENTICATED });
        dispatch({ type: LOAD_USER, payload: session.getIdToken().payload.email });
        return resolve(true);
      });
      dispatch(refreshToken());

      if (refreshInterval) clearInterval(refreshInterval);
      refreshInterval = setInterval(() => {
        dispatch(refreshToken());
      }, REFRESH_TOKEN_INTERVAL);
    }
  });

export const signOutUser = () => dispatch =>
  new Promise(resolve => {
    setTimeout(() => {
      console.log('signed out');
    }, 200);
    const userPool = new CognitoUserPool({
      UserPoolId: config.userPoolId,
      ClientId: config.userPoolWebClientId,
    });
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.signOut();
    dispatch({ type: SIGN_OUT_USER });
    resolve(true);
  });

export const refreshToken = () => dispatch => {
  console.log('refresh token');
  const userPool = new CognitoUserPool({
    UserPoolId: config.userPoolId,
    ClientId: config.userPoolWebClientId,
  });
  const cognitoUser = userPool.getCurrentUser();
  try {
    cognitoUser.getSession((err, session: CognitoUserSession) => {
      try {
        const token = session.getRefreshToken(); // receive session from calling cognitoUser.getSession()
        if (err) {
          alert('Your session has expired.');
          dispatch(signOutUser());
          return;
        }
        cognitoUser.refreshSession(token, (e, s) => {
          if (e) {
            alert('Your session has expired.');
            dispatch(signOutUser());
            return;
          }
          updateBuildingManagerWithJWT((s as CognitoUserSession).getIdToken().getJwtToken());
        });
      } catch (e) {
        console.log(e);
        alert('Your session has expired.');
        try {
          dispatch(signOutUser());
        } catch (e) {
          console.log(e);
        }
      }
    });
  } catch (e) {
    console.log(e);
  }
};

// export const signUpUser = (name: string, email: string, password: string) => {
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       const poolData = {
//         UserPoolId: config.userPoolId,
//         ClientId: config.userPoolWebClientId,
//       };
//       const userPool = new CognitoUserPool(poolData);
//       const attributeList = [];
//       const dataEmail = {
//         Name: 'email',
//         Value: email,
//       };
//       const dataName = {
//         Name: 'name',
//         Value: name,
//       };
//       const attributeEmail = new CognitoUserAttribute(dataEmail);
//       const attributeName = new CognitoUserAttribute(dataName);
//       attributeList.push(attributeEmail, attributeName);

//       userPool.signUp(email, password, attributeList, null, (error, result) => {
//         if (error) {
//           console.log(error.message);
//           dispatch({ type: SIGN_UP_ERROR, payload: error.message });
//           return reject(error);
//         }
//         console.log(`CREATED: ${result.user.getUsername()}`);
//         return resolve(true);
//       });
//     });
//   };
// };

// export const submitVerificationCode = (verificationCode: string, username: string) => {
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       const poolData = {
//         UserPoolId: config.userPoolId,
//         ClientId: config.userPoolWebClientId,
//       };
//       const userPool = new CognitoUserPool(poolData);
//       const userData = {
//         Username: username,
//         Pool: userPool,
//       };

//       const cognitoUser = new CognitoUser(userData);
//       cognitoUser.confirmRegistration(verificationCode, true, (error, result) => {
//         if (error) {
//           console.log(error.message);
//           dispatch({ type: SIGN_UP_ERROR, payload: error.message });
//           return reject(error);
//         }
//         return resolve(true);
//       });
//     });
//   };
// };

// export const resendConfirmationCode = (username: string) => {
//   const poolData = {
//     UserPoolId: config.userPoolId,
//     ClientId: config.userPoolWebClientId,
//   };
//   const userPool = new CognitoUserPool(poolData);
//   const userData = {
//     Username: username,
//     Pool: userPool,
//   };

//   const cognitoUser = new CognitoUser(userData);
//   cognitoUser.resendConfirmationCode((error, result) => {
//     if (error) {
//       console.log(error.message);
//       return;
//     }
//     console.log(result);
//   });
// };

// export const forgotPassword = (username: string) => {
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       const poolData = {
//         UserPoolId: config.userPoolId,
//         ClientId: config.userPoolWebClientId,
//       };
//       const userPool = new CognitoUserPool(poolData);
//       const userData = {
//         Username: username,
//         Pool: userPool,
//       };

//       const cognitoUser = new CognitoUser(userData);
//       cognitoUser.forgotPassword({
//         onSuccess: () => {
//           return resolve(true);
//         },
//         onFailure: error => {
//           dispatch({ type: SIGN_UP_ERROR, payload: error.message });
//           return reject(error);
//         },
//       });
//     });
//   };
// };

// export const submitForgotPassword = (
//   verificationCode: string,
//   newPassword: string,
//   username: string
// ) => {
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       const poolData = {
//         UserPoolId: config.userPoolId,
//         ClientId: config.userPoolWebClientId,
//       };
//       const userPool = new CognitoUserPool(poolData);
//       const userData = {
//         Username: username,
//         Pool: userPool,
//       };
//       const cognitoUser = new CognitoUser(userData);

//       cognitoUser.confirmPassword(verificationCode, newPassword, {
//         onSuccess() {
//           return resolve(true);
//         },
//         onFailure(error) {
//           dispatch({ type: SIGN_UP_ERROR, payload: error.message });
//           return reject(error);
//         },
//       });
//     });
//   };
// };
