import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Directions from 'screens/directions/Directions';
import DataLogger from 'screens/datalogger/DataLogger';
import CPS from 'screens/route-logs/RouteLogs';
import LoginScreen from 'screens/login/LoginScreen';
import { RootState } from 'App';
import { stop as cpsStop } from 'actions/RouteLogsActions';
import { stop as dlStop } from 'actions/DataLoggerActions';
import { resetMapOverlay } from 'actions/MapOverlayActions';
import { getAuthenticatedUser } from 'actions/AuthenticationActions';
import DataLoggerOptions from './datalogger/DataLoggerOptions';
import CPSOptions from './route-logs/RouteLogsOptions';
import DirectionsOptions from './directions/DirectionsOptions';
import './Main.scss';

process.hrtime = require('browser-process-hrtime');

const Main = () => {
  const dispatch = useDispatch();
  const { userAuthenticated } = useSelector((state: RootState) => state.authenticator);
  const [currentScreen, setCurrentScreen] = useState('directions');

  useEffect(() => {
    dispatch(getAuthenticatedUser());
  }, []);

  const renderCurrentScreen = () => {
    switch (currentScreen) {
      case 'directions':
        return <Directions />;
      case 'cps':
        return <CPS />;
      case 'datalogger':
        return <DataLogger />;
      default:
        break;
    }
  };

  const renderCurrentTab = () => {
    switch (currentScreen) {
      case 'directions':
        return <DirectionsOptions />;
      case 'cps':
        return <CPSOptions />;
      case 'datalogger':
        return <DataLoggerOptions />;
      default:
        break;
    }
  };

  const switchTab = tab => {
    if (tab === currentScreen) return;
    dispatch(cpsStop());
    dispatch(dlStop());
    dispatch(resetMapOverlay());
    setCurrentScreen(tab);
  };

  return !userAuthenticated ? (
    <div className="root">
      <LoginScreen />
    </div>
  ) : (
    <div className="root">
      {/* <TopBanner /> */}
      <div className="vh-100 vw-100 sidebar flex flex-row">
        <div
          className="vh-100 pa2 shadow-3 relative overflow-auto"
          style={{ minWidth: 400, maxWidth: 400, zIndex: 2 }}
        >
          <div className="top-tabs">
            <div
              onClick={() => switchTab('cps')}
              className={`tab ${currentScreen === 'cps' ? ' selected' : ''}`}
            >
              Routes
            </div>
            <div
              onClick={() => switchTab('datalogger')}
              className={`tab ${currentScreen === 'datalogger' ? ' selected' : ''}`}
            >
              DataLogger
            </div>
            <div
              onClick={() => switchTab('directions')}
              className={`tab ${currentScreen === 'directions' ? ' selected' : ''}`}
            >
              Directions
            </div>
          </div>

          {renderCurrentTab()}

          {/* // <Tabs variant="pills" activeKey={currentScreen} onSelect={tab => setCurrentScreen(tab)}>
        //   <Tab disabled={activeSimulation} title="CPS" eventKey="cps">
        //     <CPSOptions isCurrentScreen={currentScreen === 'cps'} />
        //   </Tab>
        //   {config.ENV_ID === gm.Environment.Dev ? (
        //     <Tab disabled={activeSimulation} title="DataLogger" eventKey="datalogger">
        //       <DataLoggerOptions isCurrentScreen={currentScreen === 'datalogger'} />
        //     </Tab>
        //   ) : null}
        //   <Tab disabled={activeSimulation} title="Directions" eventKey="directions">
        //     <DirectionsOptions isCurrentScreen={currentScreen === 'directions'} />
        //   </Tab>
        // </Tabs> */}
        </div>
        <div className="flex flex-auto vh-100">{renderCurrentScreen()}</div>
      </div>
    </div>
  );
};

export default Main;
