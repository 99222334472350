import { stop } from 'actions/RouteLogsActions';
// import { RootState } from 'App';
import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import Map from 'screens/Map';
import './DataLogger.scss';

const DataLogger = () => {
  useEffect(() => {
    // Renderer.setSnapToUser(true);
    // ARPositioning.resetARPositioning();
    // Routing.clearCurrentRoute();
    stop();
  }, []);

  return (
    <div className="flex flex-auto flex-row">
      <Map />
    </div>
  );
};

export default DataLogger;
