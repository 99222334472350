import { Vector2, Vector3 } from 'three';
import {
  convertDegToRad,
  convertRadToDeg,
  getAngleBetweenVectors,
  getRadsFromUnit,
  getUnitFromAngle,
  getUnitFromRads,
} from '../helpers/MathHelpers';
import ExploreBuilding from '../ExploreBuilding';
import ExploreIMDFBuilding from '../ExploreIMDFBuilding';
import { ExplorePosition } from '../types';
import Positioning from './Positioning';

const USER_BLUEDOT_Z_POSITION = 1.3;

export default class AtriusPositioning extends Positioning {
  building: ExploreBuilding | ExploreIMDFBuilding;

  buildingType: 'osm' | 'imdf';

  private ORIGIN = new Vector2(0, 0);

  // the straight up position from the device. resets to 0 when ARKit resets
  private rawDeviceAtriusPosition = new Vector2(0, 0);

  // dark gray, "final" ar position without drift correction
  private uncorrectedAtriusposition = new Vector2(0, 0);

  // the final position calculated
  private finalARposition = new Vector2(0, 0);

  private position: ExplorePosition = {
    x: 0,
    y: 0,
    level: 0,
    orientation: 0,
    heading: 0,
    speed: 0,
  };

  prevWorldPositionTimestamp: number = 0;

  // where we make the CPS request
  worldPositionMarkers: { [timestamp: number]: Vector2 } = {};

  worldPositionMarkersActual: { [timestamp: number]: Vector2 } = {};

  // the orientation of ARKit at the time of the CPS request
  worldPositionOrientations: { [timestamp: number]: Vector2 } = {};

  // where the previous CPS request succeeded
  private prevSnapPoint = new Vector2(0, 0);

  recentMetersPerMillisecond = [];

  speed = 0;

  recentHeadings: Vector2[] = [];

  heading = 0;

  rawOrientationAngle = 0;

  correctedOrientationAngle = 0;

  recentDriftCorrections: Vector2[] = [];

  driftCorrectionTheta = 0;

  allowARTracking = true;

  teleportationCounter = 0;

  prevAtriusUpdate = 0;

  // drift statistics
  drift = {
    previousDrifts: [],
    currentDrift: 0,
    averageDrift: 0,
    medianDrift: 0,
  };

  constructor(building: ExploreBuilding | ExploreIMDFBuilding) {
    super();
    this.building = building;
    (building as ExploreBuilding).gmBuilding
      ? (this.buildingType = 'osm')
      : (this.buildingType = 'imdf');
  }

  getStatus() {
    const {
      rawOrientationAngle,
      rawDeviceAtriusPosition,
      finalARposition,
      recentMetersPerMillisecond,
      worldPositionMarkers,
      driftCorrectionTheta,
      allowARTracking,
      speed,
      heading,
      drift,
    } = this;
    return {
      rawOrientationAngle,
      deviceARposition: rawDeviceAtriusPosition,
      finalARposition,
      recentMetersPerMillisecond,
      worldPositionMarkers,
      driftCorrectionTheta,
      allowARTracking,
      speed,
      heading,
      drift,
    };
  }

  // export const setAllowARTracking = allowed => {
  //   allowARTracking = allowed;
  //   if (!allowed) {
  //     resetARPositioning();
  //   }
  // };

  getPosition() {
    return this.position;
  }

  getAtriusPosition() {
    return this.finalARposition;
  }

  setPrevAtriusUpdateForTesting(update: number) {
    this.prevAtriusUpdate = update;
  }

  setDriftCorrectionTheta(theta: number) {
    this.driftCorrectionTheta = theta;
  }

  /**
   * Sets the relative position based on ARKit coordinates
   *
   * @param x
   * @param y
   */
  updateDeviceAtriusPosition(x: number, y: number, orientationAngle: number) {
    const now = Date.now();
    orientationAngle = (360 + orientationAngle) % 360;

    const prevRawDeviceARPosition = this.rawDeviceAtriusPosition.clone();
    const diff = new Vector2(x, y).sub(this.rawDeviceAtriusPosition);
    this.rawDeviceAtriusPosition.set(x, y);

    let metersPerSecond = 0;
    // Update the AR speed
    if (!(x === 0 && y === 0)) {
      // Calculate the mps and add it to the recent vectors
      const millisecondsElapsed = now - this.prevAtriusUpdate;
      const metersTraveled = diff.length();
      const metersPerMillisecond = metersTraveled / millisecondsElapsed;
      if (metersPerMillisecond * 1000 <= 2) {
        this.recentMetersPerMillisecond = [
          metersPerMillisecond,
          ...this.recentMetersPerMillisecond.slice(0, 150),
        ];

        metersPerSecond = metersPerMillisecond * 1000;
        // Calculate the average speed
        const avgSpeedMpms = this.recentMetersPerMillisecond.length
          ? this.recentMetersPerMillisecond.reduce((a, b) => a + b) /
            this.recentMetersPerMillisecond.length
          : 0;
        const avgSpeedMps = avgSpeedMpms * 1000;
        this.speed = avgSpeedMps;
      } else {
        // ARKit value is likely bad; toss it out
        this.prevAtriusUpdate = now;
        this.position = {
          x: this.finalARposition.x,
          y: this.finalARposition.y,
          level: this.position.level,
          orientation: convertRadToDeg(this.correctedOrientationAngle),
          speed: this.speed,
          heading: this.heading,
        };
        return;
      }
    }

    // Update the new position
    if (
      x === 0 &&
      y === 0 &&
      this.recentMetersPerMillisecond.length &&
      prevRawDeviceARPosition.length() !== 0
    ) {
      // If x === 0 and y === 0, AR is resetting and we need to apply
      // the average speed to the orientation
      const unit = getUnitFromRads(this.heading);
      unit.multiplyScalar(this.speed * (now - this.prevAtriusUpdate));
      this.recentDriftCorrections = [];
      // correctedCalculatedARPosition.add(unit);
    } else if (!(x === 0 && y === 0) && this.driftCorrectionTheta) {
      // Adjust the change in position using the rotation vector... this is a standard update
      this.uncorrectedAtriusposition.add(diff);
      diff.rotateAround(this.ORIGIN, this.driftCorrectionTheta);
      this.finalARposition.add(diff);

      if (metersPerSecond > 0.3) {
        // Calculate the heading
        this.recentHeadings = [diff.clone().normalize(), ...this.recentHeadings.slice(0, 20)];
        const headingUnit = this.recentHeadings
          .reduce((a, b) => new Vector2(a.x + b.x, a.y + b.y))
          .normalize();

        // Convert heading unit to an angle
        const headingRads = getRadsFromUnit(headingUnit);
        this.heading = convertRadToDeg(headingRads);

        if (this.building.debug) {
          this.building.renderer?.renderArrowMarker(
            'heading',
            new Vector3(
              this.finalARposition.x,
              this.finalARposition.y,
              USER_BLUEDOT_Z_POSITION - 0.05
            ),
            new Vector3(headingUnit.x, headingUnit.y, 0).normalize(),
            0xff0000,
            5
          );
        }
      }
    } else {
      // this is only while a drift correction has not yet been established
      this.uncorrectedAtriusposition.add(diff);
      this.finalARposition.add(diff);
    }

    // Calculate the corrected orientation angle
    this.rawOrientationAngle = convertDegToRad(orientationAngle);
    this.correctedOrientationAngle = convertDegToRad(orientationAngle) + this.driftCorrectionTheta;

    if (this.building.debug) {
      this.building.renderer?.renderCircleMarker(
        'user',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        0x0000ff,
        0.4
      );
      this.building.renderer?.renderArrowMarker(
        'userOrientation',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        getUnitFromAngle(this.position.orientation),
        0x0000ff,
        3
      );

      // Renderer.renderArrowMarker(
      //   'rawUserOrientation',
      //   new Vector3(finalARposition.x, finalARposition.y, 1),
      //   getUnitFromRads(rawOrientationAngle),
      //   0xffaa00,
      //   3
      // );

      // Renderer.renderCircleMarker(
      //   'rawPositionMarker',
      //   new Vector3(uncorrectedARposition.x, uncorrectedARposition.y, 0.5),
      //   0x555555
      // );
    }

    this.prevAtriusUpdate = now;
    this.position = {
      x: this.finalARposition.x,
      y: this.finalARposition.y,
      level: this.position.level,
      orientation: convertRadToDeg(this.correctedOrientationAngle),
      speed: this.speed,
      heading: this.heading,
    };
  }

  worldPositionMarkerExists = (timestamp: number) => !!this.worldPositionMarkers[`${timestamp}`];

  removeWorldPositionMaker = (timestamp: number) => {
    this.building.renderer?.clearMarker(`worldPositionMarker-${timestamp}-circle`);
    this.building.renderer?.clearMarker(`rawUserOrientationMarker-${timestamp}-arrow`);
    delete this.worldPositionMarkers[`${timestamp}`];
    delete this.worldPositionOrientations[`${timestamp}`];
    delete this.worldPositionMarkersActual[`${timestamp}`];
  };

  setWorldPositionMarker = (timestamp: number) => {
    this.worldPositionMarkers[`${timestamp}`] = new Vector2(
      this.finalARposition.x,
      this.finalARposition.y
    );

    const orientationVector = getUnitFromRads(this.rawOrientationAngle);
    this.worldPositionOrientations[`${timestamp}`] = new Vector2(
      orientationVector.x,
      orientationVector.y
    );

    if (this.building.debug) {
      // console.log('RERENDER WORLD POSITION MARKER');
      // this.building.renderer?.renderCircleMarker(
      //   `worldPositionMarker-${timestamp}`,
      //   new Vector3(this.finalARposition.x, this.finalARposition.y, 1.1),
      //   0xffcccb,
      //   0.2
      // );
      // this.building.renderer?.renderArrowMarker(
      //   `rawUserOrientationMarker-${timestamp}`,
      //   new Vector3(this.finalARposition.x, this.finalARposition.y, 1),
      //   this.worldPositionOrientations[`${timestamp}`],
      //   0xffcccb,
      //   2
      // );
    }
  };

  /**
   * Sets the absolute position in the world and calibrates AR if there has already
   * been a position established
   *
   * @param x
   * @param y
   */
  applyWorldPosition = (
    timestamp: number,
    x: number,
    y: number,
    level: number,
    orientation?: Vector3
  ) => {
    const isPositionAtRoom = !!this.building.roomQueries.getRoomAtPosition({ x, y, level });

    if (timestamp < this.prevWorldPositionTimestamp || !isPositionAtRoom) {
      this.removeWorldPositionMaker(timestamp);
      return;
    }

    if (this.prevWorldPositionTimestamp) {
      this.removeWorldPositionMaker(this.prevWorldPositionTimestamp);
    }

    Object.keys(this.worldPositionMarkers).forEach(k => {
      const i = parseInt(k, 10);
      if (i < timestamp) {
        this.removeWorldPositionMaker(i);
      }
    });

    this.prevWorldPositionTimestamp = timestamp;
    const worldPositionMarker = this.worldPositionMarkers[`${timestamp}`];
    const worldPositionOrientation = this.worldPositionOrientations[`${timestamp}`];
    if (!worldPositionMarker || !worldPositionOrientation) {
      this.removeWorldPositionMaker(timestamp);
      return;
    }
    if (this.building.debug) {
      this.building.renderer?.renderCircleMarker(
        `worldPositionMarker-${timestamp}`,
        undefined,
        0x00ff00,
        0.4
      );
    }

    // if (!allowARTracking) {
    //   console.warn('Tried to call applyWorldPosition with AR disabled');
    // }

    // establish an initial correction

    // const setAngleFromOrientation = false;
    if (orientation) {
      // if (debug) {
      //   Renderer.renderArrowMarker(
      //     'newOrientationMarker',
      //     new Vector3(finalARposition.x, finalARposition.y, 1),
      //     orientation,
      //     0x00ff00,
      //     4
      //   );
      // }
      if (worldPositionOrientation.length() !== 0) {
        const diffUnit = getAngleBetweenVectors(worldPositionOrientation, orientation);
        this.recentDriftCorrections.unshift(diffUnit);
        if (this.recentDriftCorrections.length) {
          const currentAvgUnit = this.recentDriftCorrections
            .slice(0, 3)
            .reduce((a, b) => new Vector2(a.x + b.x, a.y + b.y))
            .divideScalar(this.recentDriftCorrections.length);

          const difference = Math.abs(
            getRadsFromUnit(getAngleBetweenVectors(diffUnit, currentAvgUnit))
          );

          if (difference > 0.18) {
            this.recentDriftCorrections = [diffUnit];
            this.driftCorrectionTheta = getRadsFromUnit(diffUnit);
            // setAngleFromOrientation = true;
          } else {
            this.driftCorrectionTheta = getRadsFromUnit(currentAvgUnit);
          }
        } else {
          this.recentDriftCorrections = [diffUnit];
          this.driftCorrectionTheta = getRadsFromUnit(diffUnit);
          // setAngleFromOrientation = true;
        }
      }
    }

    let drift = 0;
    if (this.prevSnapPoint.x !== 0 && this.prevSnapPoint.y !== 0) {
      drift = worldPositionMarker.clone().sub(new Vector2(x, y)).length();
      this.calculateDrift(drift);
    }

    // Add the distance the user moved during the request
    const roomBeforeAdjustment = this.building.roomQueries.getRoomAtPosition({ x, y, level });
    const changeInPositionDuringRequest = this.finalARposition.clone().sub(worldPositionMarker);
    const newWorldPosition = new Vector2(
      x + changeInPositionDuringRequest.x,
      y + changeInPositionDuringRequest.y
    );
    const roomAfterAdjustment = this.building.roomQueries.getRoomAtPosition({
      x: newWorldPosition.x,
      y: newWorldPosition.y,
      level,
    });

    if (roomBeforeAdjustment !== roomAfterAdjustment) {
      if (Date.now() - timestamp > 5000) return;
      newWorldPosition.set(x, y);
    }

    // TODO: revisit this and figure out why it wasn't even working
    // if (!getCurrentRoom(new Vector3(newWorldPosition.x, newWorldPosition.y, 0))) {
    //   newWorldPosition.set(x, y);
    // }

    this.finalARposition.set(newWorldPosition.x, newWorldPosition.y);

    // calculate the drift correction theta
    // if (this.prevSnapPoint.x !== 0 && this.prevSnapPoint.y !== 0 && drift > 1.3 && !setAngleFromOrientation) {
    //   const prevToActual = newWorldPosition.clone().sub(this.prevSnapPoint);
    //   const prevToComputed = uncorrectedARposition.clone().sub(this.prevSnapPoint);
    //   const diffUnit = getAngleBetweenVectors(prevToComputed, prevToActual);
    //   this.recentDriftCorrections.unshift(diffUnit);
    //   const avgUnit = this.recentDriftCorrections
    //     .slice(0, 3)
    //     .reduce((a, b) => new Vector2(a.x + b.x, a.y + b.y))
    //     .divideScalar(this.recentDriftCorrections.length);
    //   this.driftCorrectionTheta = getRadsFromUnit(avgUnit);
    // }

    this.teleportationCounter = 0;
    this.prevSnapPoint.set(newWorldPosition.x, newWorldPosition.y);
    this.uncorrectedAtriusposition.set(newWorldPosition.x, newWorldPosition.y);

    if (this.building.debug) {
      // Renderer.renderCircleMarker(
      //   'rawPositionMarker',
      //   new Vector3(uncorrectedARposition.x, uncorrectedARposition.y, 1.2),
      //   0x555555
      // );
    }
    return this.finalARposition;
  };

  prepareWorldPositionMarkerV2 = (timestamp: number) => {
    this.worldPositionMarkers[`${timestamp}`] = new Vector2(
      this.rawDeviceAtriusPosition.x,
      this.rawDeviceAtriusPosition.y
    );

    this.worldPositionMarkersActual[`${timestamp}`] = new Vector2(
      this.finalARposition.x,
      this.finalARposition.y
    );

    const orientationVector = getUnitFromRads(this.rawOrientationAngle);
    this.worldPositionOrientations[`${timestamp}`] = orientationVector;

    if (this.building.debug) {
      // console.log('RERENDER WORLD POSITION MARKER');
      this.building.renderer?.renderCircleMarker(
        `worldPositionMarker-${timestamp}`,
        new Vector3(this.finalARposition.x, this.finalARposition.y, 1.1),
        0xbbbefe,
        0.05
      );
      this.building.renderer?.renderArrowMarker(
        `rawUserOrientationMarker-${timestamp}`,
        new Vector3(this.finalARposition.x, this.finalARposition.y, 1),
        this.worldPositionOrientations[`${timestamp}`],
        0xbbbefe,
        0.7,
        0.3,
        0.4
      );
    }
  };

  allowTeleportation = () => {
    this.teleportationCounter = 1;
  };

  getDriftCorrection = () => this.driftCorrectionTheta;

  calculateDrift = (d: number) => {
    this.drift.currentDrift = d;
    this.drift.previousDrifts.push(d);
    this.drift.averageDrift =
      this.drift.previousDrifts.reduce((a, b) => a + b) / this.drift.previousDrifts.length;
    this.drift.medianDrift = this.drift.previousDrifts[
      Math.floor(this.drift.previousDrifts.length / 2)
    ];

    // console.log('-- DRIFT --');
    // console.log('Current:\t', drift.currentDrift);
    // console.log('Average:\t', drift.averageDrift);
    // console.log('Median:\t', drift.medianDrift);
  };

  reset() {
    Object.keys(this.worldPositionMarkers).forEach(k => {
      this.removeWorldPositionMaker(parseInt(k, 10));
    });
    this.prevWorldPositionTimestamp = 0;
    this.rawDeviceAtriusPosition.set(0, 0);
    this.finalARposition.set(0, 0);
    this.uncorrectedAtriusposition.set(0, 0);
    this.worldPositionMarkers = {};
    this.worldPositionOrientations = {};
    this.prevSnapPoint.set(0, 0);
    this.driftCorrectionTheta = 0;
    this.recentMetersPerMillisecond = [];
    this.speed = 0;
    this.recentHeadings = [];
    this.heading = 0;
    this.recentDriftCorrections = [];
    this.teleportationCounter = 0;
    this.drift.currentDrift = 0;
    this.drift.previousDrifts = [];
    this.drift.averageDrift = 0;
    this.drift.medianDrift = 0;
  }
}
