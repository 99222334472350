import * as THREE from 'three';
import { getDistanceBetweenPoints } from '../helpers/MathHelpers';
import { POI } from '../entities/POI';

export default class RoutePoint {
  id: string;

  level: number;

  position: THREE.Vector2;

  routeType: number;

  connectionType: number;

  weight: number;

  oneWay: boolean = false;

  nodeConnectionType: number;

  neighbors: { [id: string]: { distance: number; point: RoutePoint } } = {};

  poi: POI = null;

  constructor(
    id: string,
    level: number,
    routeType: number,
    connectionType: number,
    nodeConnectionType: number,
    x: number,
    y: number,
    weight: number,
    poi?: POI,
    oneWay: boolean = false
  ) {
    this.id = id;
    this.level = level;
    this.position = new THREE.Vector2(x, y);
    this.weight = weight;
    this.routeType = routeType;
    this.connectionType = connectionType;
    this.nodeConnectionType = nodeConnectionType;
    this.oneWay = oneWay;
    if (poi) {
      this.poi = poi;
    }
  }

  addNeighbor(neighbor: RoutePoint) {
    let distance;
    if (this.level !== neighbor.level) {
      distance = Math.abs(this.level - neighbor.level) * 10;
    } else {
      distance = getDistanceBetweenPoints(this.position, neighbor.position);
    }
    this.neighbors[neighbor.id] = { distance, point: neighbor };
  }
}
