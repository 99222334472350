import NanoTimer from 'nanotimer';
import * as THREE from 'three';
import * as gm from 'goodmaps-sdk';
import * as analytics from 'goodmaps-sdk/dist/analytics';
import {
  ExploreBuilding,
  ARPositioning,
  MathHelpers,
  POIQueries,
  Renderer,
  RoomQueries,
  RoutePoint,
  ExploreCampus,
} from '_sdks/cavu-sdk/src';
import { trackPromise } from 'react-promise-tracker';
import { getInstruction } from '_sdks/explore-directions/src/ActionPoint';
import { Vector3, Vector2 } from 'three';
import { Route } from '_sdks/cavu-sdk/src/routing/Route';
import * as BuildingManager from 'helpers/BuildingManagerHelper';
import { getAzureRouteLogFull, getAzureRouteLogs } from 'helpers/ApolloHelper';
import { calcProjection } from '_sdks/cavu-sdk/src/helpers/Imdf.helper';
import ExploreIMDFBuilding from '_sdks/cavu-sdk/dist/ExploreIMDFBuilding';
import { getDataLoggerData } from './DataLoggerActions';
import {
  CPS_DATA_IMPORTED,
  CPS_LOADING_CHANGED,
  CPS_ERROR_CHANGED,
  AR_POSITION_UPDATED,
  BUILDING_DATASETS_LOADED,
  IMPORTED_CPS_DATASET_CLEARED,
  REMOTE_DATASET_CLEARED,
  BUILDINGS_LOADED,
  CAMPUSES_LOADED,
  BUILDING_SELECTED_DATALOGGER,
  MAP_OVERLAY_LIVE_DIRECTION_UPDATED,
  MAP_OVERLAY_ACTUAL_DIRECTION_UPDATED,
  MAP_OVERLAY_CPS_IMAGE_UPDATED,
  MAP_OVERLAY_EXTRA_INFO_UPDATED,
  BUILDING_SELECTED,
  ROUTE_LOGS_LOADED,
} from './types';
import CONFIG from '../global/config';
import {
  ARDataType,
  CompassDataType,
  CPSFantasmoReturnedDataType,
  CPSImmersalReturnedDataType,
  CPSRequestedDataType,
  DataSet,
  DirectionTextDataType,
  GPSDataType,
  PhonePositionDataType,
  RealPositionDataType,
  RouteGeneratedDataType,
  RouteLog,
  RouteStartedDataType,
} from '../constants/DataInterfaces';

const dataNanoTimer = new NanoTimer();

let nextUpdateStep = 0;
let cpsTimestamp;
const initialDelay = 0;
let route: Route;
let firstRouteStarted: RouteStartedDataType;

export const loadBuildings = () => async dispatch => {
  const { buildings, campuses } = await BuildingManager.refreshBuildingsAndCampusMetaData();

  dispatch({
    type: BUILDINGS_LOADED,
    payload: buildings.sort((a, b) => a.name.localeCompare(b.name)),
  });

  dispatch({
    type: CAMPUSES_LOADED,
    payload: campuses.sort((a, b) => a.name.localeCompare(b.name)),
  });

  dispatch(getAllRouteLogs());
};

export const setExploreBuilding = (buildingId, fromDataLogger = false) => async dispatch => {
  const building = await BuildingManager.setCurrentInsideBuilding(buildingId);

  if (fromDataLogger) dispatch({ type: BUILDING_SELECTED_DATALOGGER });
  dispatch({ type: BUILDING_SELECTED, payload: { selectedBuilding: building, pois: [] } });
};

export const clearImportedCPSDataList = () => dispatch => {
  dispatch({ type: IMPORTED_CPS_DATASET_CLEARED });
};

export const clearRemoteDataList = () => dispatch => {
  dispatch({ type: REMOTE_DATASET_CLEARED });
};

export const getAllRouteLogs = () => {
  dataNanoTimer.clearTimeout();
  dataNanoTimer.clearInterval();
  // BuildingManager.getCurrentInsideBuilding().arPositioning.reset();
  route = undefined;
  return async (dispatch, getState) => {
    dispatch({ type: MAP_OVERLAY_LIVE_DIRECTION_UPDATED, payload: '' });
    dispatch({ type: MAP_OVERLAY_ACTUAL_DIRECTION_UPDATED, payload: '' });

    let awsLogs: RouteLog[] = [];
    let azureLogs: RouteLog[] = [];

    // Load AWS logs
    const config: gm.BuildingManagerConfig = {
      userType: 'cavu',
      userId: 'cavu',
      jwt: getState().authenticator.jwt,
      environment: CONFIG.ENV_ID,
      language: 'en',
    };

    const aStore = analytics.createAnalyticsStore(config);
    const status = await aStore.getList({ env: CONFIG.ENV_ID });
    console.log('received all route logs');
    if (status.length > 0) {
      const list = status
        .sort((a, b) => new Date(b.createdAtISO).getTime() - new Date(a.createdAtISO).getTime())
        .filter(dataset => dataset.buildingId !== 'DataLogger');

      awsLogs = list.map(l => {
        const [pn, bn, ts] = l.name?.split(' - ');
        const phoneName = pn?.trim();
        const buildingName = bn?.trim();

        const [r, n, de] = l.description?.split(' - ');
        let rating = r?.trim();
        rating = rating.length === 1 ? rating : '-';
        const description = `${de?.trim()}`;

        return {
          buildingId: l.buildingId,
          name: buildingName,
          description,
          data: [],
          userId: l.userId,
          rating: rating ? parseInt(rating, 10) : 0,
          createdAt: l.createdAtISO,
          fingerprint: phoneName,
          id: l.dataSetId,
          source: 'aws',
        };
      });
    }

    // Load Azure logs
    azureLogs = await getAzureRouteLogs();

    const allLogs = [...awsLogs, ...azureLogs]
      .filter(dataset => dataset.buildingId !== 'DataLogger')
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    dispatch({ type: ROUTE_LOGS_LOADED, payload: allLogs });
  };
};

export const fetchDataSet = (file: RouteLog, type: string = 'building') => async (
  dispatch,
  getState
) => {
  let dataset: DataSet;
  if (file.source === 'aws') {
    const config: gm.BuildingManagerConfig = {
      userType: 'cavu',
      userId: 'cavu',
      jwt: getState().authenticator.jwt,
      environment: CONFIG.ENV_ID,
      language: 'en',
    };

    const aStore = analytics.createAnalyticsStore(config);

    const datasetMetaData = await aStore.getDataSet(file.id);
    const datasetURL = (datasetMetaData as any).url;

    const response = await fetch(datasetURL, {
      method: 'GET',
      headers: {
        ContentType: 'application/json',
      },
    });

    const json = await response.json();
    dataset = json.dataset;
  } else if (file.source === 'az') {
    const fullRouteLog: RouteLog = await getAzureRouteLogFull(file);
    dataset = fullRouteLog.data;
  }

  if (type === 'building') {
    const building = await BuildingManager.setCurrentInsideBuilding(file.buildingId);

    dispatch({
      type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
      payload: { buildingName: building.name },
    });

    dispatch(loadCPSVideo(dataset));

    // if (shouldPlay) {
    dispatch(playCPSVideo(dataset));
    // }
  } else {
    const campus = await BuildingManager.setCurrentInsideCampus(file.buildingId);
    dispatch({
      type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
      payload: { buildingName: campus.gmCampus.name },
    });

    dispatch(loadCPSVideo(dataset));
    // if (shouldPlay) {
    dispatch(playCPSVideo(dataset));
    // }
  }
};

export const deleteDataSetFromList = (datasetID: string) => async (dispatch, getState) => {
  const config: gm.BuildingManagerConfig = {
    userType: 'cavu',
    userId: 'cavu',
    jwt: getState().authenticator.jwt,
    environment: CONFIG.ENV_ID,
    language: 'en',
  };

  const aStore = analytics.createAnalyticsStore(config);
  const deleteStatus = await aStore.deleteDataSet(datasetID);
  dispatch(getAllRouteLogs());
  return deleteStatus;
};

export const setCPSData = data => {
  const dataList = data.map(d => {
    if (d.isRecording) {
      return {
        dataType: 'video',
        data: d.imageData,
        metaData: { name: d.name, desc: d.description },
      };
    }
    return {
      dataType: 'image',
      data: d.imageData,
      metaData: { name: d.name, desc: d.description },
    };
  });
  return { type: CPS_DATA_IMPORTED, payload: dataList };
};

export const selectCPSData = selectedData => {
  const { dataType, data } = selectedData;

  const building = BuildingManager.getCurrentExploreEntity();

  return async dispatch => {
    dispatch(stop());
    dispatch({ type: CPS_LOADING_CHANGED, payload: true });

    if (dataType === 'image') {
      try {
        (building as ExploreBuilding).arPositioning.allowTeleportation();
        dispatch({
          type: MAP_OVERLAY_CPS_IMAGE_UPDATED,
          payload: `data:image/jpeg;base64,${data.data.data.image}`,
        });

        const formData = new FormData();
        const mapId = building.cpsMapId;
        formData.append('intrinsics', JSON.stringify(data.data.data.intrinsics));
        formData.append('capturedAt', '1583510835.267616');
        formData.append('uuid', 'E7E3060A-5184-4D12-96F3-D4F332C8A0CD');
        formData.append('mapId', mapId);
        formData.append('image', b64toBlob(data.data.data.image), 'image.jpg');
        cpsTimestamp = Date.now();
        // (building as ExploreBuilding).arPositioning.prepareCPS(cpsTimestamp);
        building.renderer?.clearMarker('worldPositionMarker-circle');
        building.renderer?.clearMarker('rawUserOrientationMarker-arrow');

        trackPromise(
          fetch('https://api.goodmaps.io/dev/cps', {
            method: 'POST',
            body: formData,
          })
            .then(response => response.json())
            .then(responseData => {
              dispatch({ type: CPS_LOADING_CHANGED, payload: false });
              if (!responseData.pose) {
                dispatch({ type: CPS_ERROR_CHANGED, payload: true });
                return;
              }

              dispatch({ type: CPS_ERROR_CHANGED, payload: false });
              const { x, y: z, z: y } = responseData.pose.position;
              (building as ExploreBuilding).arPositioning.updateCPS(
                cpsTimestamp,
                { x, y, z },
                responseData.pose.orientation
              );
            })
            .catch(error => {
              dispatch({ type: CPS_LOADING_CHANGED, payload: false });

              dispatch({ type: CPS_ERROR_CHANGED, payload: true });
              alert('Could not load CPS position...');
              console.log(error);
            })
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      dispatch(playCPSVideo(data.data));
    }
  };
};

const b64toBlob = (dataURI: string) => {
  const byteString = atob(dataURI);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
};

let updateInterval: any;
let meta: any;
const loadCPSVideo = (data: DataSet) => dispatch => {
  meta = data.find(d => d.dataType === 'meta').data;
  console.log('Route metadata:');
  console.log(meta);

  const building = BuildingManager.getCurrentExploreEntity();
  dispatch(stop());
  nextUpdateStep = 0;
  (building as ExploreBuilding).arPositioning?.reset();
  if (meta.developerOptions?.enableConfirmCps) {
    (building as ExploreBuilding).arPositioning?.setNeedsConfirmCps();
  }
  route = undefined;

  // const images = data.filter(d => d.dataType === 'rawCPS' || d.dataType === 'cpsImage');
  // dispatch({ type: 'DATASET_IMAGES_LOADED', payload: images });
  const firstRequest = data.findIndex(d => d.dataType === 'cpsRequested');
  const firstReturn = data.findIndex(d => d.dataType === 'cpsReturned');

  if (firstRequest == null || firstReturn == null) {
    cpsTimestamp = Date.now();
    // (building as ExploreBuilding).arPositioning?.prepareCPS(cpsTimestamp);
  } else {
    cpsTimestamp = Date.now();
    // if (firstRequest > firstReturn)
    // (building as ExploreBuilding).arPositioning?.prepareCPS(cpsTimestamp);
  }
};

export const playCPSVideo = (data: DataSet) => async dispatch => {
  route = null;
  firstRouteStarted = null;
  dataNanoTimer.setTimeout(
    () => {
      const building = BuildingManager.getCurrentExploreEntity();
      dispatch(updateCPSVideo(data, 0));

      dispatch({
        type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
        payload: {
          cpsType: (building as ExploreBuilding).cpsType.toUpperCase(),
          queryMaps: (building as ExploreBuilding).getCpsMapIds().join(', '),
          mapId: '',
          region: (building as ExploreBuilding).cpsRegion,
        },
      });

      firstRouteStarted = data.find(d => d.dataType === 'routeStarted')
        .data as RouteStartedDataType;
      if (firstRouteStarted && firstRouteStarted.isRoom) {
        const room = building.roomQueries.getRoomVector(
          building.getPosition(),
          firstRouteStarted.id
        );
        dispatch({
          type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
          payload: { destination: room.room.name },
        });
      } else {
        const poi = building.poiQueries.getPOIVector(firstRouteStarted.id, building.getPosition());
        dispatch({
          type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
          payload: { destination: poi.poi.name },
        });
      }
    },
    '',
    `${initialDelay}ms`
  );
};

export const selectDatasetImage = d => dispatch => {
  dispatch(stop());
  const building = BuildingManager.getCurrentExploreEntity();
  (building as ExploreBuilding).arPositioning.allowTeleportation();
  dispatch(loadCps(d.data));
};

const currentRealPosition = new Vector2(0, 0);
const updateCPSVideo = (data: DataSet, step) => async dispatch => {
  const building = BuildingManager.getCurrentExploreEntity();
  const { dataType, timestamp } = data[step];
  let d = data[step].data;

  nextUpdateStep = step + 1;
  if (nextUpdateStep !== data.length) {
    const { timestamp: nextTimestamp } = data[nextUpdateStep];
    dataNanoTimer.setTimeout(
      () => {
        dispatch(updateCPSVideo(data, nextUpdateStep));
      },
      '',
      `${nextTimestamp - timestamp}m`
    );
  }
  switch (dataType) {
    case 'ar': {
      d = d as ARDataType;
      const [x, z, y, o] = d;
      (building as ExploreBuilding).arPositioning.updateDeviceARPosition(x, -y, o);
      dispatch({ type: AR_POSITION_UPDATED, payload: { x, y: -y, z } });
      const { speed } = building.getPosition();
      dispatch({ type: MAP_OVERLAY_EXTRA_INFO_UPDATED, payload: { speed: speed.toFixed(2) } });
      break;
    }
    case 'routeGenerated': {
      d = d as RouteGeneratedDataType;
      let points: any[];
      if (typeof d.nodes[0] === 'object') {
        points = d.nodes.map(n => ({
          position: { x: n.point.position.x, y: n.point.position.y },
          level: n.point.level,
        }));
        dispatch({
          type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
          payload: { realTarget: d.nodes[1].point.id },
        });
      }
      building.renderer?.renderRoute(
        'generatedRoute',
        points,
        building.getPosition().level,
        0.45,
        0x333,
        0.25
      );
      break;
    }
    case 'directionText': {
      d = d as DirectionTextDataType;
      dispatch({ type: MAP_OVERLAY_ACTUAL_DIRECTION_UPDATED, payload: d.replace('\n', ' ') });
      break;
    }
    case 'cpsRequested': {
      d = d as CPSRequestedDataType;
      if (!d.timestamp) {
        break;
      }
      const requestTime = d?.timestamp || Date.now();
      // (building as ExploreBuilding).arPositioning.prepareCPS(cpsTimestamp);
      (building as ExploreBuilding).arPositioning.prepareWorldPositionMarkerV2(requestTime);
      break;
    }
    case 'cpsReturned': {
      const immersalData = d as CPSImmersalReturnedDataType;
      const fantasmoData = d as CPSFantasmoReturnedDataType;
      if (!immersalData.timestamp) {
        break;
      }
      if (immersalData.timestamp - timestamp > 8000) {
        console.warn('Long time for ', immersalData.timestamp);
      }
      if (!fantasmoData.pose && !immersalData.map) {
        (building as ExploreBuilding).arPositioning?.removeWorldPositionMaker(
          immersalData.timestamp
        );
        break;
      }
      const requestTime = immersalData?.timestamp || Date.now();
      if (fantasmoData.pose) {
        const { x, y: z, z: y } = fantasmoData.pose.position;
        if (
          (building as ExploreBuilding).arPositioning.newConfirmCps(
            requestTime,
            { x, y, z },
            fantasmoData.pose.orientation
          )
        ) {
          building.routeNetwork.allowReroute();

          if (!route) {
            const newRoute = firstRouteStarted.isRoom
              ? building.routeNetwork.generateRouteToRoom(
                  building.getPosition(),
                  building.getRoom(firstRouteStarted.id)
                )
              : building.routeNetwork.generateRouteToPOI(
                  building.getPosition(),
                  building.getPOI(firstRouteStarted.id)
                );
            if (newRoute) route = newRoute;
            // Routing.getRouteToPOI(d.id, d.isRoom);
            clearInterval(updateInterval);
            updateInterval = setInterval(() => {
              try {
                if (route) {
                  const update = route.update(building.getPosition());
                  const ins = getInstruction(update[0], v => `${Math.round(v)}m`);

                  dispatch({
                    type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
                    payload: { liveTarget: update[1].point.id },
                  });
                  dispatch({
                    type: MAP_OVERLAY_LIVE_DIRECTION_UPDATED,
                    payload: ins.instruction.replace('\n', ' '),
                  });
                }
              } catch (e) {
                console.log(e);
                // Routing.getRouteToPOI(d.id, d.isRoom);
              }
            }, 1000);
          }
        }

        dispatch({
          type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
          payload: {
            cpsConfirmationCount: (building as ExploreBuilding).arPositioning.confirmationCounter,
          },
        });
      } else if (immersalData.map) {
        dispatch({
          type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
          payload: { mapId: immersalData.map },
        });
        const m = new THREE.Matrix4();
        m.set(
          immersalData.r00,
          immersalData.r01,
          immersalData.r02,
          0,
          immersalData.r10,
          immersalData.r11,
          immersalData.r12,
          0,
          immersalData.r20,
          immersalData.r21,
          immersalData.r22,
          0,
          0,
          0,
          0,
          0
        );
        const q = new THREE.Quaternion();
        q.setFromRotationMatrix(m);

        const isFi = (building as ExploreBuilding).cpsType === 'fi';

        if (
          (building as ExploreBuilding).arPositioning.newConfirmCps(
            requestTime,
            isFi
              ? { x: immersalData.px, y: immersalData.pz, z: immersalData.py }
              : {
                  x: immersalData.px,
                  y: -1 * immersalData.pz,
                  z: immersalData.py,
                },
            isFi ? { x: q.x, y: q.y, z: q.z, w: q.w } : { x: q.x, y: q.z, z: q.y, w: q.w },
            isFi ? undefined : immersalData.map
          )
        ) {
          building.routeNetwork.allowReroute();

          if (!route) {
            const newRoute = firstRouteStarted.isRoom
              ? building.routeNetwork.generateRouteToRoom(
                  building.getPosition(),
                  building.getRoom(firstRouteStarted.id)
                )
              : building.routeNetwork.generateRouteToPOI(
                  building.getPosition(),
                  building.getPOI(firstRouteStarted.id)
                );
            if (newRoute) route = newRoute;
            // Routing.getRouteToPOI(d.id, d.isRoom);
            clearInterval(updateInterval);
            updateInterval = setInterval(() => {
              try {
                if (route) {
                  const update = route.update(building.getPosition());
                  const ins = getInstruction(update[0], v => `${Math.round(v)}m`);

                  dispatch({
                    type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
                    payload: { liveTarget: update[1].point.id },
                  });
                  dispatch({
                    type: MAP_OVERLAY_LIVE_DIRECTION_UPDATED,
                    payload: ins.instruction.replace('\n', ' '),
                  });
                }
              } catch (e) {
                console.log(e);
                // Routing.getRouteToPOI(d.id, d.isRoom);
              }
            }, 1000);
          }
        }

        dispatch({
          type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
          payload: {
            cpsConfirmationCount: (building as ExploreBuilding).arPositioning.confirmationCounter,
          },
        });
      }
      break;
    }
    case 'gps': {
      d = d as GPSDataType;
      if ((building as ExploreCampus).gpsPositioning) {
        (building as ExploreCampus).gpsPositioning.updateGPS(d.latitude, d.longitude);
        const { x, y } = (building as ExploreCampus).gmCampus.calcProjection({
          lat: d.latitude,
          lon: d.longitude,
        });
        building.renderer?.renderCircleMarker('gpsPosition', new Vector3(x, y, 0.3), 0x888, 0.2);
        // dispatch({ type: MAP_OVERLAY_EXTRA_INFO_UPDATED, payload: { floor: d.floor } });
        building.routeNetwork.allowReroute();
      } else if ((building as ExploreBuilding).gmBuilding) {
        const { x, y } = (building as ExploreBuilding).gmBuilding.calcProjection({
          lat: d.latitude,
          lon: d.longitude,
        });
        building.renderer?.renderCircleMarker('wff', new Vector3(x, y, 1), 0x00008b, 0.2);
      } else {
        const { x, y } = calcProjection(
          [d.longitude, d.latitude],
          ((building as unknown) as ExploreIMDFBuilding).imdfBuilding.features.venue
        );
        building.renderer?.renderCircleMarker('wff', new Vector3(x, y, 1), 0x00008b, 0.2);
      }
      break;
    }
    case 'compass': {
      d = d as CompassDataType;
      (building as ExploreCampus).gpsPositioning?.updateCompass(d);
      break;
    }
    case 'realPosition': {
      d = d as RealPositionDataType;
      const [x, y, level, orientation] = d;
      currentRealPosition.set(x, y);
      building.renderer?.renderCircleMarker('realPosition', new Vector3(x, y, 0.3), 0x333);
      building.renderer?.renderArrowMarker(
        'realPositionOrientation',
        new Vector3(x, y, 0.3),
        MathHelpers.getUnitFromAngle(orientation),
        0x333
      );
      break;
    }
    case 'phonePosition': {
      d = d as PhonePositionDataType;
      if (d === 'down') {
        clearInterval(updateInterval);
        if (meta.developerOptions?.enableConfirmCps) {
          (building as ExploreBuilding).arPositioning?.setNeedsConfirmCps();
        }
        route = undefined;
        (building as ExploreBuilding).renderer?.renderRoute('generatedRoute', [], 0);
        (building as ExploreBuilding).renderer?.renderRoute('currentRoute', [], 0);
        dispatch({ type: MAP_OVERLAY_EXTRA_INFO_UPDATED, payload: { phonePosition: 'DOWN' } });
      } else if (d === 'up') {
        (building as ExploreBuilding).arPositioning?.reset();
        (building as ExploreBuilding).arPositioning?.allowTeleportation();
        dispatch({ type: MAP_OVERLAY_EXTRA_INFO_UPDATED, payload: { phonePosition: 'UP' } });
      }
      break;
    }
    default:
      break;
  }
};

const loadCps = (d: { image: any; intrinsics: any }) => dispatch => {
  const building = BuildingManager.getCurrentExploreEntity();
  dispatch({ type: CPS_LOADING_CHANGED, payload: true });
  dispatch({ type: MAP_OVERLAY_CPS_IMAGE_UPDATED, payload: `data:image/jpeg;base64,${d.image}` });
  const formData = new FormData();
  formData.append('intrinsics', JSON.stringify(d.intrinsics));
  formData.append('capturedAt', '1583510835.267616');
  formData.append('uuid', 'E7E3060A-5184-4D12-96F3-D4F332C8A0CD');
  const mapId = building.cpsMapId;
  formData.append('mapId', mapId);
  formData.append('image', b64toBlob(d.image), 'image.jpg');
  cpsTimestamp = Date.now();
  (building as ExploreBuilding).arPositioning.prepareWorldPositionMarkerV2(cpsTimestamp);
  fetch('https://api.goodmaps.io/dev/cps', {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json())
    .then(responseData => {
      dispatch({ type: CPS_LOADING_CHANGED, payload: false });

      if (!responseData.pose) {
        dispatch({ type: CPS_ERROR_CHANGED, payload: true });
        return;
      }

      dispatch({ type: CPS_ERROR_CHANGED, payload: false });
      const { x, y: z, z: y } = responseData.pose.position;
      console.log(responseData);
      (building as ExploreBuilding).arPositioning.newConfirmCps(
        cpsTimestamp,
        { x, y, z },
        responseData.pose.orientation
      );

      const { x: ox, y: oy, level } = building.getPosition();
      const pos = (building as ExploreBuilding).gmBuilding.reverseOriginTransform({
        x: ox,
        y: oy,
        level,
      });
      // document.getElementById('directionMeta').innerText =
      //   JSON.stringify({ ox, oy, level }, null, 2) + '\n' + JSON.stringify(pos, null, 2);
    })
    .catch(error => {
      dispatch({ type: CPS_LOADING_CHANGED, payload: false });

      dispatch({ type: CPS_ERROR_CHANGED, payload: true });
      alert('Could not load CPS position...');
      console.log(error);
    });
};

export const stop = () => async dispatch => {
  clearInterval(updateInterval);
  dataNanoTimer.clearTimeout();
  dataNanoTimer.clearInterval();
  dispatch({ type: MAP_OVERLAY_LIVE_DIRECTION_UPDATED, payload: '' });
  dispatch({ type: MAP_OVERLAY_ACTUAL_DIRECTION_UPDATED, payload: '' });
};

export const setExploreCampus = campusId => async (dispatch, getState) => {
  await BuildingManager.setCurrentInsideCampus(campusId);
};

// export const initCampus = campusId => async (dispatch, getState) => {
//   const config: gm.BuildingManagerConfig = {
//     userType: 'cavu',
//     userId: 'cavu',
//     jwt: getState().authenticator.jwt,
//     environment: CONFIG.ENV_ID,
//     language: 'en',
//   };
//   const bm = gm.createBuildingManager(config);
//   // This needs to switch back to cps for videos
//   Explore.init(bm, 'cps');
//   if (campusId) {
//     await Explore.setCampus(campusId);
//     if (campusId === 'DataLogger') {
//       await dispatch(getDataLoggerData());
//     }
//   }
// };
