import { Environment } from 'goodmaps-sdk';

const config = {
  NAME: 'Prod',
  TRACKING_ID: '',
  ENV_ID: Environment.Prod,
  userPoolId: 'us-east-2_5VoCCcrYb',
  userPoolWebClientId: '29b66risvrh27tdkqg4gd40p49',
  immersalCpsEndpoint: 'https://prod.api.goodmaps.io/servicesv2-services/cpsi',
  immersalAzureEndpoint: 'https://services.goodmapsaz.io/cpsi',
  region: 'us-east-2',
  V1_API: 'https://prod.api.goodmaps.io/servicesv2-services',
  AZURE_API: 'https://api.prod.goodmapsaz.io',
  AWS_API: 'https://api.prod.goodmaps.io',
  schemaVersion: '1',
};

export default config;
