import * as THREE from 'three';
import { Vector3, Vector2 } from 'three';

export const standardizeDegs = (theta: number) => {
  while (theta < 0) {
    theta += 360;
  }
  return theta % 360;
};

export const standardizeRads = (theta: number) => {
  while (theta < 0) {
    theta += 2 * Math.PI;
  }
  return theta % (2 * Math.PI);
};

export const calculateDegDifference = (a: number, b: number) => {
  const diff = standardizeDegs(standardizeDegs(a) - standardizeDegs(b));
  return Math.min(360 - diff, diff);
};

export const calculateRadsDifference = (a: number, b: number) => {
  const diff = standardizeRads(standardizeRads(a) - standardizeRads(b));
  return Math.min(2 * Math.PI - diff, diff);
};

/**
 * Converts from degrees to radians and then stores that value
 * @param thetaAngle angle in degrees
 */
export const convertDegToRad = (thetaAngle: number): number => {
  const convertedAngle = THREE.Math.degToRad(thetaAngle);
  return standardizeRads(convertedAngle);
};

export const convertRadToDeg = (radians: number): number => {
  const convertedAngle = THREE.Math.radToDeg(radians);
  return standardizeDegs(convertedAngle);
};

export const getDistanceBetweenPoints = (
  from: Vector2 | Vector3,
  to: Vector2 | Vector3
): number => {
  const difference = getVectorBetweenPoints(from, to);
  return difference.length();
};

export const getAngleBetweenPoints = (from: Vector2 | Vector3, to: Vector2 | Vector3): number => {
  const difference = getVectorBetweenPoints(from, to);
  let angle = convertRadToDeg(Math.atan2(difference.y, difference.x));
  if (angle < 0) {
    angle += 360;
  }
  return angle;
};

export const getVectorBetweenPoints = (
  from: THREE.Vector3 | THREE.Vector2,
  to: THREE.Vector3 | THREE.Vector2
): THREE.Vector2 => {
  const a2D = new THREE.Vector2(from.x, from.y);
  const b2D = new THREE.Vector2(to.x, to.y);
  const difference = b2D.sub(a2D);
  return difference;
};

export const getAngleBetweenVectors = (a: Vector2 | Vector3, b: Vector2 | Vector3): Vector2 => {
  const atan = Math.atan2(a.x, a.y);
  const btan = Math.atan2(b.x, b.y);
  const diff = atan - btan;
  return new THREE.Vector2(Math.cos(diff), Math.sin(diff));
};

export const getAngleFromUnit = (unit: Vector2): number => convertRadToDeg(getRadsFromUnit(unit));

export const getUnitFromAngle = (angle: number): THREE.Vector2 => {
  const rads = convertDegToRad(angle);
  return getUnitFromRads(rads);
};

export const getUnitFromRads = (rads: number): THREE.Vector2 => {
  const unit = new THREE.Vector2(Math.cos(rads), Math.sin(rads)).normalize();
  return unit;
};

export const getRadsFromUnit = (unit: THREE.Vector2): number => Math.atan2(unit.y, unit.x);

export const getEnglishDirectionFromTwoAngles = (
  prev: number,
  current: number,
  useAbsolute?: boolean
): string => {
  const RELATIVE_DIRECTIONS: Array<[number, number, string]> = [
    [322.5, 37.5, 'Straight'],
    [37.5, 135, 'Left'],
    [135, 225, 'Back'],
    [225, 322.5, 'Right'],
  ];

  const ABSOLUTE_DIRECTIONS: Array<[number, number, string]> = [
    [337.5, 22.5, 'East'],
    [22.5, 67.5, 'Northeast'],
    [67.5, 112.5, 'North'],
    [112.5, 157.5, 'Northwest'],
    [157.5, 202.5, 'West'],
    [202.5, 247.5, 'Southwest'],
    [247.5, 292.5, 'South'],
    [292.5, 337.5, 'Southeast'],
  ];

  const getDirFromAngle = (directions: Array<[number, number, string]>, angle: number) => {
    if (angle > directions[0][0] || angle <= directions[0][1]) {
      return directions[0][2];
    }

    const dir = directions.find(a => angle > a[0] && angle <= a[1]);
    return dir[2];
  };

  const direction = current - prev;
  if (useAbsolute) {
    return getDirFromAngle(
      ABSOLUTE_DIRECTIONS,
      (direction < 0 ? direction + 360 : direction) % 360
    );
  }
  return getDirFromAngle(RELATIVE_DIRECTIONS, (direction < 0 ? direction + 360 : direction) % 360);
};

export const projAontoB = (a: THREE.Vector2, b: THREE.Vector2) => {
  const bMagSquared = b.length() ** 2;
  const aDotB = a.dot(b);
  // const bUnit = b.normalize();
  const projection = b.multiplyScalar(aDotB / bMagSquared);
  return projection;
};

/**
 * finds mininum x coordinate from list coordinates (returns coordinate with mininum x coordinate )
 * @param points array of three 2d vectors
 */

export const minPointX = (points: THREE.Vector2[]) => {
  const minX = Math.min(...points.map(({ x }) => x));
  const p = points.find(({ x }) => x === minX);
  return new THREE.Vector3(p.x, p.y, 0.5);
};

/**
 * finds maximum x coordinate from list coordinates (returns coordinate with maximum x coordinate )
 * @param points array of three 2d vectors
 */
export const maxPointX = (points: THREE.Vector2[]) => {
  const maxX = Math.max(...points.map(({ x }) => x));
  const p = points.find(({ x }) => x === maxX);
  return new THREE.Vector3(p.x, p.y, 0.5);
};

/**
 * finds mininum y coordinate from list coordinates (returns coordinate with mininum y coordinate )
 * @param points array of three 2d vectors
 */
export const minPointY = (points: THREE.Vector2[]) => {
  const minY = Math.min(...points.map(({ y }) => y));
  const p = points.find(({ y }) => y === minY);
  return new THREE.Vector3(p.x, p.y, 0.5);
};

/**
 * finds mininum y maximum from list coordinates (returns coordinate with maximum y coordinate )
 * @param points array of three 2d vectors
 */
export const maxPointY = (points: THREE.Vector2[]) => {
  const maxY = Math.max(...points.map(({ y }) => y));
  const p = points.find(({ y }) => y === maxY);
  return new THREE.Vector3(p.x, p.y, 0.5);
};
