import { Vector3 } from 'three';
import ExploreCampus from '../ExploreCampus';
import { getUnitFromAngle } from '../helpers/MathHelpers';
import { ExplorePosition } from '../types';
import Positioning from './Positioning';

const USER_BLUEDOT_Z_POSITION = 1.3;

export default class GPSPositioning extends Positioning {
  campus: ExploreCampus;

  private position: ExplorePosition = {
    x: 0,
    y: 0,
    level: 0,
    orientation: 0,
    speed: 0,
    heading: 0,
  };

  constructor(campus: ExploreCampus) {
    super();
    this.campus = campus;
  }

  getPosition() {
    return this.position;
  }

  updateGPS(latitude: number, longitude: number, heading: number = 0) {
    const { x, y } = this.campus.gmCampus.calcProjection({ lat: latitude, lon: longitude });
    this.position.x = x;
    this.position.y = y;
    this.position.heading = heading;

    if (this.campus.debug) {
      this.campus.renderer?.renderCircleMarker(
        'user',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        0x0000ff,
        0.4
      );
      this.campus.renderer?.renderArrowMarker(
        'userOrientation',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        getUnitFromAngle(this.position.orientation),
        0x0000ff,
        3
      );
      const headingUnit = getUnitFromAngle(heading);
      this.campus.renderer?.renderArrowMarker(
        'heading',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION - 0.05),
        new Vector3(headingUnit.x, headingUnit.y, 0).normalize(),
        0xff0000,
        5
      );
    }
  }

  updateCompass(angle: number) {
    this.position.orientation = angle;

    if (this.campus.debug) {
      this.campus.renderer?.renderArrowMarker(
        'userOrientation',
        new Vector3(this.position.x, this.position.y, USER_BLUEDOT_Z_POSITION),
        getUnitFromAngle(this.position.orientation),
        0x0000ff,
        3
      );
    }
  }
}
