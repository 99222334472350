/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { RouteLog } from 'constants/DataInterfaces';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import './RouteLogs.scss';

type Props = {
  routeLogs: RouteLog[];
  loading: boolean;
  onSelect: (data: RouteLog) => void;
};

export default ({ routeLogs, onSelect, loading }: Props) => {
  const [selectedItem, setSelectedItem] = useState(undefined);

  // const dataItemClick = async (d, i) => {
  //   setIsLoading(true);
  //   await onSelect(d, i);
  //   setIsLoading(false);
  // };

  const renderDataList = () => {
    const dataListItems = routeLogs.map((d, i) => {
      let backgroundColor = '#888';

      const { rating, name, description, fingerprint, createdAt } = d;
      // const { name, desc, date } = d.metaData;
      // const [pn, bn, ts] = name.split(' - ');
      // phoneName = pn.trim();
      // buildingName = bn.trim();
      const time = new Date(createdAt).toLocaleString();

      // if (desc.length) {
      // const [r, n, de] = desc.split(' - ');
      // rating = r.trim();
      // rating = rating.length === 1 ? rating : '-';
      // description = `${de.trim()}`;

      switch (rating) {
        case 1:
          backgroundColor = '#ED5314';
          break;
        case 2:
          backgroundColor = '#FFB92A';
          break;
        case 3:
          backgroundColor = '#FEEB51';
          break;
        case 4:
          backgroundColor = '#9BCA3E';
          break;
        case 5:
          backgroundColor = '#3ABBC9';
          break;
        default:
          backgroundColor = '#888';
          break;
      }

      return (
        <div key={i} className={`list-item flex-row ${selectedItem === i ? 'selected' : ''}`}>
          {i === selectedItem && loading ? (
            <div className="spinner-container">
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <>
              <div
                className="flex flex-column"
                onClick={() => {
                  setSelectedItem(i);
                  onSelect(d);
                }}
              >
                <div className="item-name" id="image-name">
                  <div className="flex flex-row ">
                    <div className="flex flex-column flex-auto">
                      <b>{name}</b>
                      {fingerprint}
                      <br />
                      {time}
                    </div>
                    {rating ? (
                      <div
                        className="flex justify-center align-center rating"
                        style={{ backgroundColor }}
                      >
                        {rating}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="item-desc">{description}</div>
              </div>
              <div className="action-buttons">
                {/* {onPlayClick ? (
                  <Button
                    style={{ marginRight: 5 }}
                    className="delete-btn"
                    variant="primary"
                    onClick={async () => {
                      setIsLoading(true);
                      await onPlayClick(d, i);
                      setIsLoading(false);
                    }}
                  >
                    Play
                  </Button>
                ) : null} */}
                {/* <Button
                  className="delete-btn"
                  variant="danger"
                  onClick={() => onDeleteClick(d.metaData.id)}
                >
                  Delete
                </Button> */}
              </div>
            </>
          )}
        </div>
      );
    });
    return dataListItems;
  };

  return <div className="data-list">{renderDataList()}</div>;
};
