import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import './SearchAndFilter.scss';

type Props = {
  onSearchFilterChange: any;
  shouldHideRatingFilter: boolean;
};

const SearchAndFilter = ({ onSearchFilterChange, shouldHideRatingFilter }: Props) => {
  const [search, setSearch] = useState('');
  const [filterToggled, setToggle] = useState(false);
  const [dateRadioValue, setDateRadioValue] = useState('1');
  const [ratingRadioValue, setRateRadioValue] = useState('0');

  const dateRadios = [
    { name: 'Latest', value: '1' },
    { name: 'Earliest', value: '2' },
  ];

  const ratingRadios = [
    { name: 'All', value: '0' },
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
  ];

  const FilterToggleButton = ({ eventKey }) => {
    const onClick = useAccordionToggle(eventKey, () => setToggle(!filterToggled));
    return (
      <div className={`filter-button ${filterToggled ? 'toggled' : ''}`} onClick={onClick}>
        <img
          className="filter-icon"
          alt="button icon for filter"
          src={require('../assets/icons/filter-icon.png')}
        />
      </div>
    );
  };

  useEffect(() => {
    onSearchFilterChange(search, dateRadioValue, ratingRadioValue);
  }, [search, dateRadioValue, ratingRadioValue]);

  return (
    <div className="search-filter-container">
      <Accordion>
        <Card className="search-card">
          <Card.Header className="card-header">
            <Form.Control
              className="entityInputField search-input"
              placeholder="Search"
              as="input"
              value={search}
              onChange={event => {
                const searchText = event.target.value || '';
                setSearch(searchText);
              }}
            />
            <FilterToggleButton eventKey="0" />
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div>
                <div>Filter by date:</div>
                <ButtonGroup toggle>
                  {dateRadios.map((radio, id) => (
                    <ToggleButton
                      key={id}
                      type="radio"
                      variant="secondary"
                      name="radio"
                      value={radio.value}
                      checked={dateRadioValue === radio.value}
                      onChange={e => {
                        setDateRadioValue(e.currentTarget.value);
                      }}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                {shouldHideRatingFilter ? (
                  <>
                    <div>Filter by rating:</div>
                    <ButtonGroup toggle>
                      {ratingRadios.map((radio, id) => (
                        <ToggleButton
                          key={id}
                          type="radio"
                          variant="secondary"
                          name="radio"
                          value={radio.value}
                          checked={ratingRadioValue === radio.value}
                          onChange={e => {
                            setRateRadioValue(e.currentTarget.value);
                          }}
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </>
                ) : null}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default SearchAndFilter;
