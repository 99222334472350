import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from 'actions/AuthenticationActions';
import { DataLoggerMetadataExt } from 'actions/DataLoggerActions';
import { RouteLog } from 'constants/DataInterfaces';
import { IMap } from 'goodmaps-sdk';
import { BuildingMetaData, Address, DataLoggerDatasetList } from 'goodmaps-utils';
import config from '../global/config';

// let awsApolloClient: ApolloClient<NormalizedCacheObject> | undefined;
let azureApolloClient: ApolloClient<NormalizedCacheObject> | undefined;
let awsApolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const getHttpLink = (uri: string) =>
  createHttpLink({
    uri: `${uri}`, // your URI here...
  });

const authLink = setContext(async () => {
  const response = await fetch(`${config.V1_API}/getV2Token`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
  const token = await response.json();
  return {
    headers: {
      // Authorization: `Bearer ${config.EXPLORE_APP_KEY}`,
      Authorization: `Bearer ${token.message.access_token}`,
    },
  };
});

export const getAzureApolloClient = () => {
  if (azureApolloClient) return azureApolloClient;

  azureApolloClient = new ApolloClient({
    link: authLink.concat(getHttpLink(`${config.AZURE_API}/graphql`)),
    cache: new InMemoryCache(),
  });

  return azureApolloClient;
};
export const getAWSApolloClient = () => {
  if (awsApolloClient) return awsApolloClient;

  awsApolloClient = new ApolloClient({
    link: authLink.concat(getHttpLink(`${config.AWS_API}/graphql`)),
    cache: new InMemoryCache(),
  });

  return awsApolloClient;
};

export const getAllAzureBuildings = async (): Promise<BuildingInfo[]> => {
  try {
    const apolloClient = getAzureApolloClient();
    const {
      data: { getAllBuildings },
    } = await apolloClient.query<IAllAzureBuildingsResult>({
      query: GET_ALL_BUILDINGS,
    });

    console.log('Buildings Fetched');

    const result = convertIAzureBuildingInfoToBuildingInfo(
      getAllBuildings.filter(b => b.features.venue !== null)
    );

    return result;
  } catch (error) {
    console.warn('Issue getting all buildings using Apollo');
    console.warn(error);
    return [];
  }
};

export const getAzureBuilding = async (buildingId): Promise<IMap> => {
  try {
    const apolloClient = getAzureApolloClient();
    const data = await apolloClient.query<any>({
      query: config.schemaVersion === '2' ? GET_BUILDING_V2 : GET_BUILDING,
      variables: {
        buildingId,
      },
    });

    const buildingData = JSON.parse(JSON.stringify(data.data.getBuildingById));
    // Handle old schema
    if (buildingData.features.cpsPoints) {
      buildingData.features.cps_points = buildingData.features.cpsPoints;
    }

    console.log('Buildings Fetched');

    return buildingData as IMap;
  } catch (error) {
    console.warn('Issue getting all buildings using Apollo');
    console.warn(error);
  }
};

export const getAllAWSBuildings = async (): Promise<BuildingInfo[]> => {
  try {
    const apolloClient = getAWSApolloClient();
    const {
      data: { getAllBuildings },
    } = await apolloClient.query<IAllAzureBuildingsResult>({
      query: GET_ALL_BUILDINGS,
    });

    console.log('Buildings Fetched');

    const result = convertIAWSBuildingInfoToBuildingInfo(
      getAllBuildings.filter(b => b.features.venue !== null)
    );

    return result;
  } catch (error) {
    console.warn('Issue getting all buildings using Apollo');
    console.warn(error);
    return [];
  }
};

export const getAWSBuilding = async (buildingId): Promise<IMap> => {
  try {
    const apolloClient = getAWSApolloClient();
    const data = await apolloClient.query<any>({
      query: config.schemaVersion === '2' ? GET_BUILDING_V2 : GET_BUILDING,
      variables: {
        buildingId,
      },
    });

    const buildingData = JSON.parse(JSON.stringify(data.data.getBuildingById));
    // Handle old schema
    if (buildingData.features.cpsPoints) {
      buildingData.features.cps_points = buildingData.features.cpsPoints;
    }

    console.log('Buildings Fetched');

    return buildingData as IMap;
  } catch (error) {
    console.warn('Issue getting all buildings using Apollo');
    console.warn(error);
  }
};

export const getAzureDataLoggerFiles = async (): Promise<DataLoggerMetadataExt[]> => {
  try {
    const apolloClient = getAzureApolloClient();
    const data = await apolloClient.query<GetDataLoggerFilesResponse>({
      query: GET_DATALOGGER_FILES,
      fetchPolicy: 'network-only',
    });

    console.log('Datalogger Files Fetched');

    return data.data.getAllDataLoggerMetaData.map(d => ({ ...d, source: 'az', type: '2.0' }));
  } catch (error) {
    console.warn('Issue getting all datalogger files using Apollo');
    console.warn(error);
    return [];
  }
};

export const getAzureDataLoggerFile = async (datasetId: string): Promise<DataLoggerDatasetList> => {
  try {
    const apolloClient = getAzureApolloClient();
    const data = await apolloClient.query<GetDataLoggerFileResponse>({
      query: GET_DATALOGGER_FILE,
      variables: {
        datasetId,
      },
    });

    console.log('Datalogger File Fetched');

    return data.data.getDataLoggerDatasetList;
  } catch (error) {
    console.warn('Issue getting datalogger file using Apollo');
    console.warn(error);
  }
};

export const getAWSDataLoggerFiles = async (): Promise<DataLoggerMetadataExt[]> => {
  try {
    const apolloClient = getAWSApolloClient();
    const data = await apolloClient.query<GetDataLoggerFilesResponse>({
      query: GET_DATALOGGER_FILES,
      fetchPolicy: 'network-only',
    });

    console.log('Datalogger Files Fetched');

    return data.data.getAllDataLoggerMetaData.map(d => ({ ...d, source: 'aws', type: '2.0' }));
  } catch (error) {
    console.warn('Issue getting all datalogger files using Apollo');
    console.warn(error);
    return [];
  }
};

export const getAWSDataLoggerFile = async (datasetId: string): Promise<DataLoggerDatasetList> => {
  try {
    const apolloClient = getAWSApolloClient();
    const data = await apolloClient.query<GetDataLoggerFileResponse>({
      query: GET_DATALOGGER_FILE,
      variables: {
        datasetId,
      },
    });

    console.log('Datalogger File Fetched');

    return data.data.getDataLoggerDatasetList;
  } catch (error) {
    console.warn('Issue getting datalogger file using Apollo');
    console.warn(error);
  }
};

export const getAzureRouteLogs = async (): Promise<RouteLog[]> => {
  try {
    const apolloClient = getAzureApolloClient();
    const data = await apolloClient.query<GetRouteLogsResponse>({
      query: GET_ROUTE_LOGS,
    });

    console.log('Route Log Files Fetched');

    return data.data.getAllRouteLogs.map(d => {
      const [pn, bn] = d.name?.split(' - ');
      const phoneName = pn?.trim();
      const buildingName = bn?.trim();

      return { ...d, name: buildingName, fingerprint: phoneName, source: 'az' };
    });
  } catch (error) {
    console.warn('Issue getting all Route Log files using Apollo');
    console.warn(error);
    return [];
  }
};

export const getAzureRouteLogFull = async (routeLog: RouteLog): Promise<RouteLog> => {
  try {
    const apolloClient = getAzureApolloClient();
    const data = await apolloClient.query<GetFullRouteLogResponse>({
      query: GET_FULL_ROUTE_LOG,
      variables: {
        routeLogId: routeLog.id,
      },
    });

    const innerData = JSON.parse(data.data.getRouteLogById.data);

    console.log('Route Log Full File Fetched');
    const file = { ...routeLog, data: innerData.data ? innerData.data.dataset : innerData };
    console.log(file);

    return file;
  } catch (error) {
    console.warn('Issue getting specific Route Log file using Apollo');
    console.warn(error);
  }
};

export const isBuildingMetaData = (
  entity: BuildingMetaData | IMDFBuildingInfo
): entity is BuildingMetaData => (entity as BuildingMetaData).ancestors !== undefined;

export const convertToBuildingInfo = (
  buildings: (BuildingMetaData | IMDFBuildingInfo)[]
): BuildingInfo[] =>
  buildings.map(b => ({
    name: b.name,
    id: b.id,
    distance: b.distance,
    angle: b.angle || 0,
    lat: b.lat,
    lon: b.lon,
    address: b.address,
    ancestors: isBuildingMetaData(b) ? b.ancestors : undefined,
    buildingMetaData: isBuildingMetaData(b) ? b : undefined,
    source: 'aws',
    version: '1.0',
  }));

const convertIAzureBuildingInfoToBuildingInfo = (buildings: IAzureBuildingInfo[]): BuildingInfo[] =>
  buildings.map(b => ({
    name: b.name,
    id: b.id,
    distance: b?.distance || 0,
    angle: 0,
    lat: b.features.venue.properties.display_point.coordinates[1],
    lon: b.features.venue.properties.display_point.coordinates[0],
    address: convertIIMDFAddresstoAddress(b.features.address.properties),
    ancestors: [],
    buildingMetaData: undefined,
    source: 'az',
    version: '2.0',
  }));

const convertIAWSBuildingInfoToBuildingInfo = (buildings: IAzureBuildingInfo[]): BuildingInfo[] =>
  buildings.map(b => ({
    name: b.name,
    id: b.id,
    distance: b?.distance || 0,
    angle: 0,
    lat: b.features.venue.properties.display_point.coordinates[1],
    lon: b.features.venue.properties.display_point.coordinates[0],
    address: convertIIMDFAddresstoAddress(b.features.address.properties),
    ancestors: [],
    buildingMetaData: undefined,
    source: 'aws',
    version: '2.0',
  }));

const convertIIMDFAddresstoAddress = (a: IIMDFAddress) => ({
  streetAddress: a.address,
  locality: a.locality,
  administrativeArea: a.province,
  postalCode: a.postal_code,
  country: a.country,
});

export type IMDFBuildingInfo = {
  name: string;
  id: string;
  distance: number;
  angle: number;
  lat: number;
  lon: number;
  address: Address;
};

export interface BuildingInfo extends IMDFBuildingInfo {
  ancestors: string[] | undefined;
  buildingMetaData: BuildingMetaData | undefined;
  source: 'aws' | 'az';
  version: '1.0' | '2.0';
}

const GET_ALL_BUILDINGS = gql`
  query GetBuildings {
    getAllBuildings {
      name
      id
      features {
        address {
          properties {
            address
            locality
            country
            postal_code
            province
          }
        }
        venue {
          properties {
            display_point {
              coordinates
            }
          }
        }
      }
    }
  }
`;

const GET_BUILDING_V2 = gql`
  query($buildingId: String!) {
    getBuildingById(buildingId: $buildingId) {
      id
      name
      orgId
      published
      private
      cpsMapId
      features {
        address {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            address
            unit
            locality
            province
            country
            postal_code
            postal_code_ext
            postal_code_vanity
          }
        }
        amenity {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            shelf
            category
            accessibility
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            hours
            phone
            website
            unit_ids
            address_id
            correlation_id
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
        anchor {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            address_id
            unit_id
          }
        }
        building {
          id
          buildingId
          type
          feature_type
          geometry {
            coordinates
            type
          }
          properties {
            name {
              frCA
              en
            }
            alt_name {
              frCA
              en
            }
            category
            restriction
            display_point {
              type
              coordinates
            }
            address_id
          }
        }
        detail {
          id
          buildingId
          type
          feature_type
          geometry {
            coordinates
            type
          }
          properties {
            level_id
          }
        }
        fixture {
          id
          buildingId
          type
          geometry {
            type
            coordinates
          }
          feature_type
          properties {
            category
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            anchor_id
            level_id
            display_point {
              type
              coordinates
            }
            restriction
            accessibility
            parents
          }
        }
        footprint {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            name {
              en
              frCA
            }
            building_ids
          }
        }
        geofence {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            correlation_id
            display_point {
              coordinates
              type
            }
            building_ids
            level_ids
            parents
            accessibility
            fixture_ids
            opening_ids
          }
        }
        kiosk {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            anchor_id
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
        level {
          id
          buildingId
          type
          geometry {
            type
            coordinates
          }
          feature_type
          properties {
            category
            restriction
            outdoor
            ordinal
            name {
              en
              frCA
            }
            short_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            address_id
            building_ids
            elevation
          }
        }
        occupant {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            name {
              en
              frCA
            }
            category
            anchor_id
            hours
            phone
            website
            validity
            correlation_id
          }
        }
        opening {
          id
          buildingId
          type
          geometry {
            type
          }
          feature_type
          properties {
            category
            accessibility
            access_control
            door {
              type
              automatic
              material
            }
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            level_id
          }
        }
        relationship {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          properties {
            category
            direction
            origin {
              id
              feature_type
            }
            intermediary {
              id
              feature_type
            }
            destination {
              id
              feature_type
            }
            hours
          }
          feature_type
        }
        section {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            accessibility
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            level_id
            address_id
            correlation_id
            parents
          }
        }
        unit {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            accessibility
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
        venue {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            hours
            phone
            website
            address_id
            display_point {
              coordinates
              type
            }
          }
        }
        cps_points {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            indoorMark
            cps_map_id
            x_pointcloud
            y_pointcloud
            z_pointcloud
            level
            level_id
            cps
          }
        }
        route {
          id
          properties {
            category
            restriction
            accessibility
            level_id
          }
          geometry {
            coordinates
            type
          }
        }
      }
    }
  }
`;

const GET_BUILDING = gql`
  query($buildingId: String!) {
    getBuildingById(buildingId: $buildingId) {
      id
      name
      orgId
      published
      private
      cpsMapId
      features {
        address {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            address
            unit
            locality
            province
            country
            postal_code
            postal_code_ext
            postal_code_vanity
          }
        }
        amenity {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            shelf
            category
            accessibility
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            hours
            phone
            website
            unit_ids
            address_id
            correlation_id
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
        anchor {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            address_id
            unit_id
          }
        }
        building {
          id
          buildingId
          type
          feature_type
          geometry {
            coordinates
            type
          }
          properties {
            name {
              frCA
              en
            }
            alt_name {
              frCA
              en
            }
            category
            restriction
            display_point {
              type
              coordinates
            }
            address_id
          }
        }
        detail {
          id
          buildingId
          type
          feature_type
          geometry {
            coordinates
            type
          }
          properties {
            level_id
          }
        }
        fixture {
          id
          buildingId
          type
          geometry {
            type
            coordinates
          }
          feature_type
          properties {
            category
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            anchor_id
            level_id
            display_point {
              type
              coordinates
            }
            restriction
            accessibility
            parents
          }
        }
        footprint {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            name {
              en
              frCA
            }
            building_ids
          }
        }
        geofence {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            correlation_id
            display_point {
              coordinates
              type
            }
            building_ids
            level_ids
            parents
            accessibility
            fixture_ids
            opening_ids
          }
        }
        kiosk {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            anchor_id
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
        level {
          id
          buildingId
          type
          geometry {
            type
            coordinates
          }
          feature_type
          properties {
            category
            restriction
            outdoor
            ordinal
            name {
              en
              frCA
            }
            short_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            address_id
            building_ids
            elevation
          }
        }
        occupant {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            name {
              en
              frCA
            }
            category
            anchor_id
            hours
            phone
            website
            validity
            correlation_id
          }
        }
        opening {
          id
          buildingId
          type
          geometry {
            type
          }
          feature_type
          properties {
            category
            accessibility
            access_control
            door {
              type
              automatic
              material
            }
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            level_id
          }
        }
        relationship {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          properties {
            category
            direction
            origin {
              id
              feature_type
            }
            intermediary {
              id
              feature_type
            }
            destination {
              id
              feature_type
            }
            hours
          }
          feature_type
        }
        section {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            accessibility
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            level_id
            address_id
            correlation_id
            parents
          }
        }
        unit {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            accessibility
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
        venue {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            hours
            phone
            website
            address_id
            display_point {
              coordinates
              type
            }
          }
        }
        cpsPoints {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            indoorMark
            cps_map_id
            x_pointcloud
            y_pointcloud
            z_pointcloud
            level
            level_id
            cps
          }
        }
        route {
          id
          properties {
            category
            restriction
            accessibility
            level_id
          }
          geometry {
            coordinates
            type
          }
        }
      }
    }
  }
`;

const GET_DATALOGGER_FILES = gql`
  query {
    getAllDataLoggerMetaData {
      dataSetId
      userId
      createdAtISO
      name
      description
    }
  }
`;

const GET_DATALOGGER_FILE = gql`
  query($datasetId: String!) {
    getDataLoggerDatasetList(datasetId: $datasetId) {
      dataSetId
      name
      description
      dataset {
        dataType
        metaData {
          batteryLevel
          build
          deviceName
          fingerprint
          model
          os
          version
        }
        imageData {
          description
          isRecording
          name
          thumbnail
          data {
            image
            intrinsics {
              cx
              cy
              fx
              fy
            }
          }
        }
        videoData {
          name
          description
          isRecording
          thumbnail
          data {
            dataType
            timestamp
            gpsData {
              lat
              lon
            }
            arData
            imageData {
              image
              intrinsics {
                cx
                cy
                fx
                fy
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_DATALOGGER_FILE = gql`
  mutation($dataset: DataLoggerMetadataDTO!) {
    editDataLoggerFile(dataset: $dataset) {
      dataSetId
      userId
      createdAtISO
      description
      name
    }
  }
`;

const GET_ROUTE_LOGS = gql`
  query {
    getAllRouteLogs {
      id
      name
      rating
      description
      buildingId
      createdAt
    }
  }
`;

const GET_FULL_ROUTE_LOG = gql`
  query($routeLogId: String!) {
    getRouteLogById(routeLogId: $routeLogId) {
      id
      data
    }
  }
`;

interface IIMDFAddress {
  address: string;
  country: string;
  locality: string;
  postal_code: string;
  province: string;
}
interface IAzureBuildingInfo {
  distance: number;
  name: string;
  id: string;
  features: {
    address: {
      properties: IIMDFAddress;
    };
    venue: {
      properties: {
        display_point: {
          coordinates: number[];
        };
      };
    };
  };
}

interface IAllAzureBuildingsResult {
  getAllBuildings: IAzureBuildingInfo[];
}

export interface GetBuildingResponse {
  getBuildingById: IMap;
}

export interface GetDataLoggerFilesResponse {
  getAllDataLoggerMetaData: DataLoggerMetadataExt[];
}

export interface GetDataLoggerFileResponse {
  getDataLoggerDatasetList: DataLoggerDatasetList;
}

export interface GetRouteLogsResponse {
  getAllRouteLogs: RouteLog[];
}

export interface GetFullRouteLogResponse {
  getRouteLogById: {
    id: string;
    data: string;
  };
}
