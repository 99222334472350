import { ConnectionType, DoorType } from 'goodmaps-sdk';
import I18n from 'i18n-js';
import { RouteInstruction } from '../../cavu-sdk/src';
import { MAP, MAP_TRANSLATION } from './TranslationMap';
// import en from '../translations/en.json';

const ACTION_POINT_DISTANCE = 1.5;
const DOOR_ACTION_POINT_DISTANCE = 3;
const VERY_CLOSE_DISTANCE = 5;
const REDUCE_VERBOSITY_DISTANCE = 10;
const HEADS_UP_DISTANCE = 9;
const SLIGHT_TURN_THRESHOLD = 20;

export type InstructionType = 'headsup' | 'action' | 'continue';
export type InstructionSubType =
  | 'left'
  | 'right'
  | 'door'
  | 'connectionExit'
  | 'destination'
  | 'stairs'
  | 'elevator'
  | 'escalator';
export type Instruction = {
  instruction: string;
  type: InstructionType;
  subType?: InstructionSubType;
  priority?: number;
};
export enum Priority {
  Low = 0,
  Medium,
  High,
  VeryHigh,
}

let prevPointID = '';
let reduceVerbosity = true;

const englishMap: MAP_TRANSLATION = {
  headsUp: {
    turnLeft: 'Approaching left turn',
    slightLeft: 'Approaching slight left',
    leftToEscalator: 'Approaching escalator on left',
    leftToStairs: 'Approaching stairs on left',
    leftToElevator: 'Approaching elevator on left',
    leftToDestination: 'Approaching destination on left',
    leftToDestinationShelf: 'Approaching shelf on left',
    turnRight: 'Approaching right turn',
    slightRight: 'Approaching slight right',
    rightToEscalator: 'Approaching escalator on right',
    rightToStairs: 'Approaching stairs on right',
    rightToElevator: 'Approaching elevator on right',
    rightToDestination: 'Approaching destination on right',
    rightToDestinationShelf: 'Approaching shelf on right',
    goThroughDoor: {
      named: 'In %{d} go through %{p}',
      default: 'In %{d} go through door',
      opening: 'In %{d} go through opening',
    },
    enterStairwell: 'Approaching stairs',
    enterElevator: 'Approaching elevator',
    enterEscalator: 'Approaching escalator',
    destination: 'Approaching destination',
    destinationShelf: 'Approaching shelf',
  },
  action: {
    goThroughDoor: {
      named: 'Go through %{p} ahead',
      default: 'Go through door ahead',
      opening: 'Go through opening ahead',
    },
    goThroughDoorImmediate: {
      named: {
        left: 'Go through %{p} ahead and turn left',
        right: 'Go through %{p} ahead and turn right',
      },
      default: {
        left: 'Go through door ahead and turn left',
        right: 'Go through door ahead and turn right',
      },
      opening: {
        left: 'Go through opening ahead and turn left',
        right: 'Go through opening ahead and turn right',
      },
    },
    turnLeft: 'Turn left and continue %{p}',
    slightLeft: 'Slight left and continue %{p}',
    turnLeftImmediate: {
      left: 'Turn left, then take another left',
      right: 'Turn left, then take immediate right',
      door: {
        named: 'Turn left and go through %{p}',
        default: 'Turn left and go through door',
        opening: 'Turn left and go through opening',
      },
      destination: 'Turn left and arrive at destination',
      destinationShelf: 'Turn left and arrive at shelf',
      stairwell: 'Turn left and enter the stairs',
      elevator: 'Turn left and enter the elevator',
      escalator: 'Turn left and get on the escalator',
    },
    turnRight: 'Turn right and continue %{p}',
    slightRight: 'Slight right and continue %{p}',
    turnRightImmediate: {
      left: 'Turn right, then take immediate left',
      right: 'Turn right, then take another right',
      door: {
        named: 'Turn right and go through %{p}',
        default: 'Turn right and go through door',
        opening: 'Turn right and go through opening',
      },
      destination: 'Turn right and arrive at destination',
      destinationShelf: 'Turn right and arrive at shelf',
      stairwell: 'Turn right and enter the stairs',
      elevator: 'Turn right and enter the elevator',
      escalator: 'Turn right and get on the escalator',
    },
    enterStairwell: 'Enter stairs and go to %{p}',
    enterElevator: 'Enter elevator and go to %{p}',
    enterEscalator: 'Enter escalator and go to %{p}',
    continueOnStairwell:
      'Enter stairs and go to %{p}.\n Use caution in stairs. Hold phone vertically to confirm you have exited stairs.',
    continueOnElevator:
      'Enter elevator and go to %{p}.\n Use caution in elevator. Hold phone vertically to confirm you have exited elevator.',
    continueOnEscalator: 'Get on escalator\n and go to %{p}',
    exitConnection:
      'After exiting to %{level}, continue %{p}. Hold the phone up so the camera can see where you are.',
    exitConnectionImmediate: {
      left:
        'After exiting to %{level}, turn left. Hold the phone up so the camera can see where you are.',
      right:
        'After exiting to %{level}, turn right. Hold the phone up so the camera can see where you are.',
    },
  },
  continue: 'Continue %{p}',
  continueAnd: {
    destination: 'Continue %{p}\nto destination',
    destinationShelf: 'Continue %{p}\nto shelf',
    left: 'Continue %{p}\nand turn left',
    slightLeft: 'Continue %{p} and slight left',
    leftToEscalator: 'Continue %{p}\nand turn left to escalator',
    leftToStairs: 'Continue %{p}\nand turn left to stairs',
    leftToElevator: 'Continue %{p}\nand turn left to elevator',
    leftToDestination: 'Continue %{p}\nand destination on left',
    leftToDestinationShelf: 'Continue %{p}\nand shelf on left',
    right: 'Continue %{p}\nand turn right',
    slightRight: 'Continue %{p} and slight right',
    rightToEscalator: 'Continue %{p}\nand turn right to escalator',
    rightToStairs: 'Continue %{p}\nand turn right to stairs',
    rightToElevator: 'Continue %{p}\nand turn right to elevator',
    rightToDestination: 'Continue %{p}\nand destination on right',
    rightToDestinationShelf: 'Continue %{p}\nand shelf on right',
    escalator: 'Continue %{p}\nand enter escalator',
    stairwell: 'Continue %{p}\nand enter stairs',
    elevator: 'Continue %{p}\nand enter elevator',
  },
  other: {
    continueToFloor: 'Continue to %{p}',
    turnAround: 'Turn around and continue %{p}',
    lastTurn: 'Last turn',
  },
};

I18n.translations.en = englishMap;
const { t } = I18n;

export type Action = {
  text: string;
};

type ConversionFunction = (
  value: number,
  useLongName?: boolean,
  unitConfig?: string,
  numDecimals?: number
) => string;

let headsUpAllowed = true;
export const setHeadsUpAllowed = allowed => (headsUpAllowed = allowed);

// prettier-ignore
export const getInstruction = (instruction: RouteInstruction, convertMetersTo: ConversionFunction): Instruction => {
  try {
    if (instruction.nextInstruction.point.id !== prevPointID) {
      prevPointID = instruction.nextInstruction.point.id;
      reduceVerbosity = instruction.distanceToNextPoint < REDUCE_VERBOSITY_DISTANCE;
    }
    const { nextInstruction } = instruction;
    const distance = convertMetersTo(getRemainingDistance(instruction));
    // Are we approaching a shelf?
    const isShelf = nextInstruction.nextInstruction?.point?.poi?.shelf
    const nextTargetDistance = nextInstruction ? convertMetersTo(getRemainingDistance(nextInstruction)) : '0';

    // Most important if user is off course
    if (instruction.directionToNextPoint === 'Back') {
      return ins(t(MAP.other.turnAround, { p: distance }), 'action', null, Priority.Low);
    }

    //
    //
    //
    // ~~~~~ Action point
    //
    //
    //
    // console.log(`Action point? ${instruction.distanceToNextPoint < ACTION_POINT_DISTANCE}`)
    if (instruction.distanceToNextPoint < ACTION_POINT_DISTANCE) {
      //
      // Destination
      //

      if (!nextInstruction.nextInstruction) {
        return  isShelf ? ins(t(MAP.headsUp.destinationShelf), 'action', 'destination', Priority.VeryHigh) :
         ins(t(MAP.headsUp.destination), 'action', 'destination', Priority.VeryHigh);
      }

      //
      // Connection entrance
      //
      if (nextInstruction.point.connectionType && nextInstruction.nextInstruction.point.connectionType) {
        switch (nextInstruction.point.connectionType) {
          case ConnectionType.Stairs:
            return ins(t(MAP.headsUp.enterStairwell), 'action', 'stairs', Priority.Medium);
          case ConnectionType.Elevator:
            return ins(t(MAP.headsUp.enterElevator), 'action', 'elevator', Priority.Medium);
          case ConnectionType.Escalator:
            return ins(t(MAP.headsUp.enterEscalator), 'action', 'escalator', Priority.Medium);
          default:
            break;
        }
      }
      // Node Connection entrance
      //
      if (nextInstruction.point.nodeConnectionType && nextInstruction.point.level === nextInstruction.nextInstruction.point.level) {
        switch (nextInstruction.point.nodeConnectionType) {
          case ConnectionType.Stairs:
            return ins(t(MAP.headsUp.enterStairwell), 'action', 'stairs', Priority.Medium);
          // case ConnectionType.MovingWalkway:
          //   return ins(t(MAP.headsUp.enterMovingWalkway), 'action', 'connection', Priority.Medium);

          default:
            break;
        }
      }

      //
      // Connection exit
      //
      if (nextInstruction.point.connectionType && !nextInstruction.nextInstruction.point.connectionType) {
        return ins(t(MAP.other.continueToFloor, { p: nextInstruction.point.level }), 'action', null, Priority.Medium);
      }

      //
      // If the next instruction is very short
      //
      if (nextInstruction.distanceToNextPoint < VERY_CLOSE_DISTANCE && (nextInstruction.directionToNextPoint === 'Left' || nextInstruction.directionToNextPoint === 'Right')) {
        // If we are going to turn and then immediately arrive at the destination
        if (!nextInstruction.nextInstruction.nextInstruction) {
          if(nextInstruction.directionToNextPoint === 'Left'){
            return isShelf ? ins(t(MAP.action.turnLeftImmediate.destinationShelf), 'action', 'left') : ins(t(MAP.action.turnLeftImmediate.destination), 'action', 'left') 
          }
         
          return isShelf ? ins(t(MAP.action.turnRightImmediate.destinationShelf), 'action', 'right', Priority.Medium) : ins(t(MAP.action.turnRightImmediate.destination), 'action', 'right', Priority.Medium);
         
        }

        // If we are going to turn and then immediately get on a connection
        if (nextInstruction.nextInstruction.point.connectionType) {
          switch (nextInstruction.nextInstruction.point.connectionType) {
            case ConnectionType.Stairs:
              if (!nextInstruction.nextInstruction.nextInstruction.nextInstruction) {
                return nextInstruction.directionToNextPoint === 'Left' ? ins(`${t(MAP.action.turnLeftImmediate.stairwell)}. ${t(MAP.other.lastTurn)}`, 'action', 'left') : ins(`${t(MAP.action.turnRightImmediate.stairwell)}. ${t(MAP.other.lastTurn)}`, 'action', 'right', Priority.Medium);
              }
              return nextInstruction.directionToNextPoint === 'Left' ? ins(t(MAP.action.turnLeftImmediate.stairwell), 'action', 'left') : ins(t(MAP.action.turnRightImmediate.stairwell), 'action', 'right', Priority.Medium);
            case ConnectionType.Elevator:
              if (!nextInstruction.nextInstruction.nextInstruction.nextInstruction) {
                return nextInstruction.directionToNextPoint === 'Left' ? ins(`${t(MAP.action.turnLeftImmediate.elevator)}. ${t(MAP.other.lastTurn)}`, 'action', 'left') : ins(`${t(MAP.action.turnRightImmediate.elevator)}. ${t(MAP.other.lastTurn)}`, 'action', 'right', Priority.Medium);
              }
              return nextInstruction.directionToNextPoint === 'Left' ? ins(t(MAP.action.turnLeftImmediate.elevator), 'action', 'left') : ins(t(MAP.action.turnRightImmediate.elevator), 'action', 'right', Priority.Medium);
            case ConnectionType.Escalator:
              if (!nextInstruction.nextInstruction.nextInstruction.nextInstruction) {
                return nextInstruction.directionToNextPoint === 'Left' ? ins(`${t(MAP.action.turnLeftImmediate.escalator)}. ${t(MAP.other.lastTurn)}`, 'action', 'left') : ins(`${t(MAP.action.turnRightImmediate.escalator)}. ${t(MAP.other.lastTurn)}`, 'action', 'right', Priority.Medium);
              }
              return nextInstruction.directionToNextPoint === 'Left' ? ins(t(MAP.action.turnLeftImmediate.escalator), 'action', 'left') : ins(t(MAP.action.turnRightImmediate.escalator), 'action', 'right', Priority.Medium);
            default:
              break;
          }
        }

        // If we are going to turn and immediately go through a door
        if (nextInstruction.nextInstruction.point.poi && nextInstruction.nextInstruction.point.poi.type === 'door') {
          const poiName = nextInstruction.nextInstruction.point.poi?.name?.trim();
          const isOpening = nextInstruction.nextInstruction.point.poi?.type === 'door' && (nextInstruction.nextInstruction.point.poi?.poiType as DoorType) === DoorType.No;
          const name = poiName || (isOpening ? 'opening' : 'door');

          const translatedInstructionAndDoorName = (direction: 'right' | 'left') => {
            if (direction === 'left') {
              if (name === 'opening') return t(MAP.action.turnLeftImmediate.door.opening)
              if (name === 'door') return t(MAP.action.turnLeftImmediate.door.default)
              
              return t(MAP.action.turnLeftImmediate.door.named, { p: name })
            } 
              if (name === 'opening') return t(MAP.action.turnRightImmediate.door.opening)
              if (name === 'door') return t(MAP.action.turnRightImmediate.door.default)
              
              return t(MAP.action.turnRightImmediate.door.named, { p: name })
            
          }

          if (!isOpening) {
            if (!nextInstruction.nextInstruction.nextInstruction.nextInstruction) {
              return nextInstruction.directionToNextPoint === 'Left' ? ins(`${translatedInstructionAndDoorName('left')}. ${t(MAP.other.lastTurn)}`, 'action', 'left', Priority.Medium) : ins(`${translatedInstructionAndDoorName('right')}. ${t(MAP.other.lastTurn)}`, 'action', 'right', Priority.Medium);
            }
            return nextInstruction.directionToNextPoint === 'Left' ? ins(translatedInstructionAndDoorName('left'), 'action', 'left', Priority.Medium) : ins(translatedInstructionAndDoorName('right'), 'action', 'right', Priority.Medium);
          }
        }

        // If we are going to turn and then turn again
        if (nextInstruction.directionToNextPoint === 'Left') {
          if (nextInstruction.nextInstruction.directionToNextPoint === 'Left') return ins(nextInstruction.nextInstruction.nextInstruction.nextInstruction ? t(MAP.action.turnLeftImmediate.left) : `${t(MAP.action.turnLeftImmediate.left)}. ${t(MAP.other.lastTurn)}`, 'action', 'left', Priority.Medium);
          if (nextInstruction.nextInstruction.directionToNextPoint === 'Right') return ins(nextInstruction.nextInstruction.nextInstruction.nextInstruction ? t(MAP.action.turnLeftImmediate.right) : `${t(MAP.action.turnLeftImmediate.right)}. ${t(MAP.other.lastTurn)}`, 'action', 'left', Priority.Medium);
        }
        if (nextInstruction.directionToNextPoint === 'Right') {
          if (nextInstruction.nextInstruction.directionToNextPoint === 'Left') return ins(nextInstruction.nextInstruction.nextInstruction.nextInstruction ? t(MAP.action.turnRightImmediate.left) : `${t(MAP.action.turnRightImmediate.left)}. ${t(MAP.other.lastTurn)}`, 'action', 'right', Priority.Medium);
          if (nextInstruction.nextInstruction.directionToNextPoint === 'Right') return ins(nextInstruction.nextInstruction.nextInstruction.nextInstruction ? t(MAP.action.turnRightImmediate.right) : `${t(MAP.action.turnRightImmediate.right)}. ${t(MAP.other.lastTurn)}`, 'action', 'right', Priority.Medium);
        }
      }
        

      //
      // Otherwise just a normal turn
      //
      if (nextInstruction.directionToNextPoint === 'Left') {
        if (Math.abs(nextInstruction.relativeAngleToNextPoint - 45) < SLIGHT_TURN_THRESHOLD) return ins(nextInstruction.nextInstruction.nextInstruction.nextInstruction ? t(MAP.action.slightLeft, { p: nextTargetDistance }) : `${t(MAP.action.slightLeft, { p: nextTargetDistance })}. ${t(MAP.other.lastTurn)}`, 'action', 'left', Priority.Medium);
        return ins(nextInstruction.nextInstruction?.nextInstruction?.nextInstruction ? t(MAP.action.turnLeft, { p: nextTargetDistance }) : `${t(MAP.action.turnLeft, { p: nextTargetDistance })}. ${t(MAP.other.lastTurn)}`, 'action', 'left', Priority.Medium);
      }
      if (nextInstruction.directionToNextPoint === 'Right') {
        if (Math.abs(nextInstruction.relativeAngleToNextPoint - 45) < SLIGHT_TURN_THRESHOLD) return ins(nextInstruction.nextInstruction.nextInstruction.nextInstruction ? t(MAP.action.slightRight, { p: nextTargetDistance }) : `${t(MAP.action.slightRight, { p: nextTargetDistance })}. ${t(MAP.other.lastTurn)}`, 'action', 'right', Priority.Medium);
        return ins(nextInstruction.nextInstruction?.nextInstruction?.nextInstruction ? t(MAP.action.turnRight, { p: nextTargetDistance }) : `${t(MAP.action.turnRight, { p: nextTargetDistance })}. ${t(MAP.other.lastTurn)}`, 'action', 'right', Priority.Medium); // ins(t(MAP.action.turnRight, { p: nextTargetDistance }), 'action', 'right', Priority.Medium);
      }
    }

    // Door action -- a little more generous for distance
    // console.log(`door action? ${instruction.distanceToNextPoint < DOOR_ACTION_POINT_DISTANCE && nextInstruction.point.poi  && !nextInstruction.point.connectionType}`)
    if (instruction.distanceToNextPoint < DOOR_ACTION_POINT_DISTANCE && nextInstruction.point.poi && nextInstruction.point.poi.type === 'door' && !nextInstruction.point.connectionType) {
      const poiName = nextInstruction.point.poi?.name?.trim();
      const isOpening = nextInstruction.point.poi?.type === 'door' && (nextInstruction.point.poi?.poiType as DoorType) === DoorType.No;
      const name = poiName || (isOpening ? 'opening' : 'door');

      const translatedInstructionAndDoorName = (direction: 'right' | 'left' | 'none') => {
        if (direction === 'left') {
          if (name === 'opening') return t(MAP.action.goThroughDoorImmediate.opening.left)
          if (name === 'door') return t(MAP.action.goThroughDoorImmediate.default.left)
          
          return t(MAP.action.goThroughDoorImmediate.named.left, { p: name })
        } if (direction === 'right') {
          if (name === 'opening') return t(MAP.action.goThroughDoorImmediate.opening.right)
          if (name === 'door') return t(MAP.action.goThroughDoorImmediate.default.right)
          
          return t(MAP.action.goThroughDoorImmediate.named.right, { p : name })
        } 
          if (name === 'opening') return t(MAP.action.goThroughDoor.opening)
          if (name === 'door') return t(MAP.action.goThroughDoor.default)
          
          return t(MAP.action.goThroughDoor.named, { p : name })
        
      }

      if (!isOpening) {
        // If there is an immediate left/right outside of the door
        if (nextInstruction.distanceToNextPoint < VERY_CLOSE_DISTANCE && (nextInstruction.nextInstruction.directionToNextPoint === 'Left' || nextInstruction.nextInstruction.directionToNextPoint === 'Right')) {
          return nextInstruction.nextInstruction.directionToNextPoint === 'Left' ? ins(translatedInstructionAndDoorName('left'), 'action', 'door', Priority.Medium) : ins(translatedInstructionAndDoorName('right'), 'action', 'door', Priority.Medium);
        }

        return ins(translatedInstructionAndDoorName('none'), 'action', 'door', Priority.Medium);
      }
    }

    //
    //
    //
    // ~~~~~ Off Course
    //
    //
    //
    // console.log(`Off Course? ${instruction.directionToNextPoint !== 'Straight'}`)
    if (instruction.directionToNextPoint !== 'Straight') {
      if (instruction.directionToNextPoint === 'Left') return ins(t(MAP.action.turnLeft, { p: distance}), 'action', 'left', Priority.Low);
      if (instruction.directionToNextPoint === 'Right') return ins(t(MAP.action.turnRight, { p: distance}), 'action', 'right', Priority.Low);
      if (instruction.directionToNextPoint === 'Back') return ins(t(MAP.other.turnAround, { p: distance}), 'action', null, Priority.Low);
    }

    //
    //
    //
    // ~~~~~ Heads up
    //
    //
    //
    // console.log(`Heads up? ${instruction.distanceToNextPoint < HEADS_UP_DISTANCE && headsUpAllowed && !reduceVerbosity}`)
    if (instruction.distanceToNextPoint < HEADS_UP_DISTANCE && headsUpAllowed && !reduceVerbosity) {
      //
      // Destination
      //
      if (!nextInstruction.nextInstruction) {
        return isShelf?  ins(t(MAP.headsUp.destinationShelf), 'headsup', 'destination') : ins(t(MAP.headsUp.destination), 'headsup', 'destination');
      }

      //
      // Heads up left/right
      //
      if (nextInstruction.directionToNextPoint === 'Left') {
        if (nextInstruction.distanceToNextPoint < VERY_CLOSE_DISTANCE) {
          if (nextInstruction.nextInstruction.point.connectionType) {
            switch (nextInstruction.nextInstruction.point.connectionType) {
              case ConnectionType.Stairs:
                return ins(t(MAP.headsUp.leftToStairs), 'headsup', 'stairs', Priority.Medium);
              case ConnectionType.Elevator:
                return ins(t(MAP.headsUp.leftToElevator), 'headsup', 'elevator', Priority.Medium);
              case ConnectionType.Escalator:
                return ins(t(MAP.headsUp.leftToEscalator), 'headsup', 'escalator', Priority.Medium);
              default:
                break;
            }
          }
          if (!nextInstruction.nextInstruction.nextInstruction) {
            const nextIsShelf = nextInstruction.nextInstruction.point.poi?.shelf
            return nextIsShelf ? ins(t(MAP.headsUp.leftToDestinationShelf), 'headsup', 'destination', Priority.Medium) : ins(t(MAP.headsUp.leftToDestination), 'headsup', 'destination', Priority.Medium);
          }
        }
        if (Math.abs(nextInstruction.relativeAngleToNextPoint - 45) < SLIGHT_TURN_THRESHOLD) return ins(t(MAP.headsUp.slightLeft), 'headsup', 'left', Priority.Medium);
        return ins(t(MAP.headsUp.turnLeft), 'headsup', 'left', Priority.Medium);
      }
      if (nextInstruction.directionToNextPoint === 'Right') {
        if (nextInstruction.distanceToNextPoint < VERY_CLOSE_DISTANCE) {
          if (nextInstruction.nextInstruction.point.connectionType) {
            switch (nextInstruction.nextInstruction.point.connectionType) {
              case ConnectionType.Stairs:
                return ins(t(MAP.headsUp.rightToStairs), 'headsup', 'stairs', Priority.Medium);
              case ConnectionType.Elevator:
                return ins(t(MAP.headsUp.rightToElevator), 'headsup', 'elevator', Priority.Medium);
              case ConnectionType.Escalator:
                return ins(t(MAP.headsUp.rightToEscalator), 'headsup', 'escalator', Priority.Medium);
              default:
                break;
            }
          }
          if (!nextInstruction.nextInstruction.nextInstruction) {
            const nextIsShelf = nextInstruction.nextInstruction.point.poi?.shelf
            return nextIsShelf ? ins(t(MAP.headsUp.rightToDestinationShelf), 'headsup', 'destination', Priority.Medium) : ins(t(MAP.headsUp.rightToDestination), 'headsup', 'destination', Priority.Medium);
          }
        }
        if (Math.abs(nextInstruction.relativeAngleToNextPoint - 45) < SLIGHT_TURN_THRESHOLD) return ins(t(MAP.headsUp.slightRight), 'headsup', 'right', Priority.Medium);
        return ins(t(MAP.headsUp.turnRight), 'headsup', 'right', Priority.Medium);
      }
    }

    // Heads up for things that are always allowed
    // console.log(`Heads up allways allowed? ${instruction.distanceToNextPoint < HEADS_UP_DISTANCE}`)
    if (instruction.distanceToNextPoint < HEADS_UP_DISTANCE) {
      //
      // Connection
      //
      if (nextInstruction.point.connectionType && nextInstruction.nextInstruction.point.connectionType) {
        switch (nextInstruction.point.connectionType) {
          case ConnectionType.Stairs:
            return ins(t(MAP.headsUp.enterStairwell), 'headsup', 'stairs', Priority.Medium);
          case ConnectionType.Elevator:
            return ins(t(MAP.headsUp.enterElevator), 'headsup', 'elevator', Priority.Medium);
          case ConnectionType.Escalator:
            return ins(t(MAP.headsUp.enterEscalator), 'headsup', 'escalator', Priority.Medium);
          default:
            break;
        }
      }

      //
      // Door
      //
      if (nextInstruction.point.poi && nextInstruction.point.poi.type === 'door') {
        // Named door
        const poiName = nextInstruction.point.poi?.name?.trim();
        const isOpening = nextInstruction.point.poi?.type === 'door' && (nextInstruction.point.poi?.poiType as DoorType) === DoorType.No;
        const name = poiName || (isOpening ? 'opening' : 'door');

        const translatedInstructionDistanceAndDoorName = (distanceToDoor: number) => {
          if (name === 'opening') return t(MAP.headsUp.goThroughDoor.opening, { d: convertMetersTo(distanceToDoor) })
          if (name === 'door') return t(MAP.headsUp.goThroughDoor.default, { d: convertMetersTo(distanceToDoor) })

          return t(MAP.headsUp.goThroughDoor.named, { d: convertMetersTo(distanceToDoor), p: name })
        }

        if (!isOpening) return ins( translatedInstructionDistanceAndDoorName(instruction.distanceToNextPoint), 'headsup', 'door', Priority.Medium);
      }
    }

    //
    //
    // ~~~~~ Other
    //
    //

    //
    // If next point is the destination
    //
    if (!nextInstruction.nextInstruction) {
      return isShelf ? ins(t(MAP.continueAnd.destinationShelf, { p: distance }), 'continue', 'destination', Priority.Medium) : ins(t(MAP.continueAnd.destination, { p: distance }), 'continue', 'destination', Priority.Medium);
    }

    //
    // Otherwise fall back
    //
    if (instruction.directionToNextPoint === 'Left') {
      return ins(t(MAP.action.turnLeft, { p: distance }), 'action', 'left', Priority.Low);
    }
    if (instruction.directionToNextPoint === 'Right') {
      return ins(t(MAP.action.turnRight, { p: distance }), 'action', 'right', Priority.Low);
    }
    if (instruction.directionToNextPoint === 'Straight' && !reduceVerbosity) {
      // Try to find what the user will do at the very end of the straight
      let {nextInstruction} = instruction;
      while (nextInstruction && nextInstruction.directionToNextPoint === 'Straight' && !nextInstruction.point.connectionType) {
        nextInstruction = nextInstruction.nextInstruction;
      }
        if (nextInstruction.point.connectionType === ConnectionType.Elevator) {
        return ins(t(MAP.continueAnd.elevator, { p : distance }), 'continue', 'elevator', Priority.Medium);
      }
      if (nextInstruction.point.connectionType === ConnectionType.Stairs) {
        return ins(t(MAP.continueAnd.stairwell, { p : distance }), 'continue', 'stairs', Priority.Medium);
      }
      if (nextInstruction.point.connectionType === ConnectionType.Escalator) {
        return ins(t(MAP.continueAnd.escalator, { p : distance }), 'continue', 'escalator', Priority.Medium);
      }
      // Continue and left
      if (nextInstruction.directionToNextPoint === 'Left') {
        if (nextInstruction.distanceToNextPoint < VERY_CLOSE_DISTANCE) {
          if (nextInstruction.nextInstruction.point.connectionType) {
            switch (nextInstruction.nextInstruction.point.connectionType) {
              case ConnectionType.Stairs:
                return ins(t(MAP.continueAnd.leftToStairs, { p: distance }), 'continue', 'stairs', Priority.Medium);
              case ConnectionType.Elevator:
                return ins(t(MAP.continueAnd.leftToElevator, { p: distance }), 'continue', 'elevator', Priority.Medium);
              case ConnectionType.Escalator:
                return ins(t(MAP.continueAnd.leftToEscalator, { p: distance }), 'continue', 'escalator', Priority.Medium);
              default:
                break;
            }
          }
          if (!nextInstruction.nextInstruction.nextInstruction) {
            const nextIsShelf = nextInstruction.nextInstruction.point.poi?.shelf
            return nextIsShelf ? ins(t(MAP.continueAnd.leftToDestinationShelf, { p: distance }), 'continue', 'destination', Priority.Medium) : ins(t(MAP.continueAnd.leftToDestination, { p: distance }), 'continue', 'destination', Priority.Medium);
          }
        }
        if (Math.abs(nextInstruction.relativeAngleToNextPoint - 45) < SLIGHT_TURN_THRESHOLD) return ins(t(MAP.continueAnd.slightLeft, { p : distance }), 'continue', 'left', Priority.Medium);
        return ins(t(MAP.continueAnd.left, { p : distance }), 'continue', 'left', Priority.Medium);
      }
      // Continue and right
      if (nextInstruction.directionToNextPoint === 'Right') {
        if (nextInstruction.distanceToNextPoint < VERY_CLOSE_DISTANCE) {
          if (nextInstruction.nextInstruction.point.connectionType) {
            switch (nextInstruction.nextInstruction.point.connectionType) {
              case ConnectionType.Stairs:
                return ins(t(MAP.continueAnd.rightToStairs, { p: distance }), 'continue', 'stairs', Priority.Medium);
              case ConnectionType.Elevator:
                return ins(t(MAP.continueAnd.rightToElevator, { p: distance }), 'continue', 'elevator', Priority.Medium);
              case ConnectionType.Escalator:
                return ins(t(MAP.continueAnd.rightToEscalator, { p: distance }), 'continue', 'escalator', Priority.Medium);
              default:
                break;
            }
          }
          if (!nextInstruction.nextInstruction.nextInstruction) {
            const nextIsShelf = nextInstruction.nextInstruction.point.poi?.shelf
            return nextIsShelf ? ins(t(MAP.continueAnd.rightToDestinationShelf, { p: distance }), 'continue', 'destination', Priority.Medium) : ins(t(MAP.continueAnd.rightToDestination, { p: distance }), 'continue', 'destination', Priority.Medium);
          }
        }
        if (Math.abs(nextInstruction.relativeAngleToNextPoint - 45) < SLIGHT_TURN_THRESHOLD) return ins(t(MAP.continueAnd.slightRight, { p : distance }), 'continue', 'right', Priority.Medium);
        return ins(t(MAP.continueAnd.right, { p : distance }), 'continue', 'right', Priority.Medium);
      }
    }
    // Fall back to just the regular ole continue instruction
    return ins(t(MAP.continue, { p: distance }), 'continue', null, Priority.Medium);
  } catch (e) {
    const distance = convertMetersTo(getRemainingDistance(instruction));
    return ins(t(MAP.continue, { p: distance }), 'continue', null, Priority.Medium);
  }
}

const ins = (
  instruction: string,
  type: InstructionType,
  subType: InstructionSubType,
  priority: number = 0
): Instruction => ({ instruction, type, subType, priority });

export const getConnectionInstruction = (
  instruction: RouteInstruction,
  targetFloorName: string,
  convertMetersTo: ConversionFunction
): Instruction => {
  // If entrance
  if (instruction.point.connectionType && instruction.nextInstruction.point.connectionType) {
    switch (instruction.point.connectionType) {
      case ConnectionType.Stairs:
        return ins(t(MAP.action.continueOnStairwell, { p: targetFloorName }), 'action', 'stairs');
      case ConnectionType.Elevator:
        return ins(t(MAP.action.continueOnElevator, { p: targetFloorName }), 'action', 'elevator');
      case ConnectionType.Escalator:
        return ins(
          t(MAP.action.continueOnEscalator, { p: targetFloorName }),
          'action',
          'escalator'
        );
      default:
        break;
    }
  } else if (
    instruction.point.connectionType &&
    !instruction.nextInstruction.point.connectionType
  ) {
    let direction = '';
    if (instruction.distanceToNextPoint < VERY_CLOSE_DISTANCE)
      direction = instruction.nextInstruction.directionToNextPoint;
    switch (direction) {
      case 'Left':
        return ins(
          t(MAP.action.exitConnectionImmediate.left, { level: targetFloorName }),
          'action',
          'connectionExit'
        );
      case 'Right':
        return ins(
          t(MAP.action.exitConnectionImmediate.right, { level: targetFloorName }),
          'action',
          'connectionExit'
        );
      default:
        return ins(
          t(MAP.action.exitConnection, {
            level: targetFloorName,
            p: convertMetersTo(instruction.distanceToNextPoint),
          }),
          'action',
          'connectionExit'
        );
    }
  }
};

const getRemainingDistance = (instruction: RouteInstruction) => {
  let distance = instruction.distanceToNextPoint;

  let { nextInstruction } = instruction;
  while (nextInstruction && nextInstruction.directionToNextPoint === 'Straight') {
    distance += nextInstruction.distanceToNextPoint;
    nextInstruction = nextInstruction.nextInstruction;
  }

  return distance;
};
