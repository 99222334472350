import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import Main from 'screens/Main';
import reducers from './reducers';

const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

export type RootState = ReturnType<typeof store.getState>;

const App = () => (
  <Provider store={store}>
    <Main />
  </Provider>
);
export default App;
