import { stop } from 'actions/RouteLogsActions';
import React, { useEffect } from 'react';
import Map from 'screens/Map';
import { Renderer, ARPositioning } from '_sdks/cavu-sdk/src';
import './RouteLogs.scss';

export const CPS = () => {
  useEffect(() => {
    // Renderer.setSnapToUser(true);
    // ARPositioning.resetARPositioning();
    // Routing.clearCurrentRoute();
    stop();
  }, []);

  return (
    <div className="flex flex-auto flex-row">
      <Map />
    </div>
  );
};

export default CPS;
