import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'App';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getCoordinates } from 'screens/Map';
import { typeaheadFuzzySearchHelper } from 'helpers/searchHelper';
import {
  SelectableRouteDestination,
  selectBuilding,
  selectCampus,
  selectStartingPOI,
  startDirections,
  updateDirections,
  testRoutes,
  cancelTest,
  selectStartingPosition,
  selectIMDFBuilding,
} from '../../actions/DirectionsActions';
import { loadBuildings, stop } from '../../actions/RouteLogsActions';
import BuildingCampusSelect from '../../components/BuildingCampusSelect';

const DirectionsOptions = () => {
  const dispatch = useDispatch();
  const { selectedBuilding, pois, selectedCampus, percentage } = useSelector(
    (state: RootState) => state.directions
  );
  const { listOfBuildings, listOfCampuses } = useSelector((state: RootState) => state.global);
  const [dropdownSelection, setSelection] = useState([]);
  const [dropdownStartPOISelection, setStartPOISelection] = useState([]);
  const [dropdownEndPOISelection, setEndPOISelection] = useState([]);
  const [campusDropdownSelection, setCampusDropdownSelection] = useState([]);
  const [startingPOI, setStartingPOI] = useState<SelectableRouteDestination>();
  const [endingPOI, setEndingPOI] = useState<SelectableRouteDestination>();
  const [stepFree, setStepFree] = useState<boolean>();

  useEffect(() => {
    stop();
    dispatch(loadBuildings());
    stop();
    setSelection([]);
    setCampusDropdownSelection([]);
  }, []);

  useEffect(() => {
    setStartingPOI(undefined);
    setEndingPOI(undefined);
  }, [selectedBuilding, selectedCampus]);

  const fillBuildingsDropdown = () =>
    listOfBuildings.map(building => ({ id: building.id, name: building.name }));

  const fillPOIsDropdown = () =>
    pois.map((poi, i) => ({ id: poi.entity.id, name: poi.name, value: poi }));

  const onBuildingSelect = building => {
    setSelection(building);
    setCampusDropdownSelection([]);
    if (building[0]) {
      const { id } = building[0];
      dispatch(selectBuilding(id));
    }
  };

  const onCampusSelect = e => {
    setCampusDropdownSelection(e);
    setSelection([]);
    if (e[0]) {
      const { id } = e[0];
      dispatch(selectCampus(id));
    }
  };

  const fillCampusDropdown = () =>
    listOfCampuses.map(campus => ({ id: campus.id, name: campus.name }));

  const selectMapCoordinates = () => {
    const listener = () => {
      window.removeEventListener('mouseup', listener);
      const coordinates = getCoordinates();
      setStartingPOI(undefined);
      selectStartingPosition(coordinates.x, coordinates.y);

      dispatch(startDirections(undefined, endingPOI, true, stepFree));
    };
    setTimeout(() => {
      window.addEventListener('mouseup', listener);
    }, 10);
  };

  const renderBuildingOptions = () => {
    if (selectedBuilding || selectedCampus)
      return (
        <>
          <div className="extra-options" style={{ marginBottom: 10 }}>
            <div className="flex w-100 flex-direction-row" style={{ marginBottom: 5 }}>
              <Typeahead
                id="dropdown-menu"
                className="flex-auto"
                labelKey="name"
                filterBy={['name']}
                onChange={pois => {
                  setStartPOISelection(pois);

                  if (pois[0]) {
                    const { value: poi } = pois[0];
                    setStartingPOI(poi);
                    selectStartingPOI(poi);
                    dispatch(startDirections(poi, endingPOI, true, stepFree));
                  }
                }}
                options={fillPOIsDropdown()}
                placeholder="Select Starting POI"
                selected={dropdownStartPOISelection}
              />
              <Button style={{ marginLeft: 5 }} onClick={selectMapCoordinates}>
                ⌖
              </Button>
            </div>
            <div>
              <Typeahead
                id="dropdown-menu"
                labelKey="name"
                onChange={pois => {
                  setEndPOISelection(pois);

                  if (pois[0]) {
                    const { value: poi } = pois[0];
                    setEndingPOI(poi);
                    dispatch(startDirections(startingPOI, poi, true, stepFree));
                  }
                }}
                filterBy={['name']}
                options={fillPOIsDropdown()}
                placeholder="Select Ending POI"
                selected={dropdownEndPOISelection}
              />
            </div>
          </div>
          <div className="extra-options" style={{ marginBottom: 10 }}>
            <div className="flex flex-row justify-between align-items-center">
              <Form>
                <Form.Check
                  id="use-image-switch"
                  type="switch"
                  label=""
                  checked={stepFree}
                  onChange={() => {
                    setStepFree(!stepFree);
                    dispatch(startDirections(startingPOI, endingPOI, true, !stepFree));
                  }}
                />
              </Form>
              <span>Step Free</span>
            </div>
          </div>
          <div className="extra-options">
            {endingPOI && (
              <Button
                variant="primary"
                className="w-100 mb2"
                onClick={() => dispatch(updateDirections())}
              >
                Move Forward
              </Button>
            )}
            <Button variant="primary" className="w-100 mb2" onClick={() => dispatch(testRoutes())}>
              Test Routes
            </Button>
            <Button variant="primary" className="w-100 mb2" onClick={() => cancelTest()}>
              Cancel Test
            </Button>
            {percentage}%
          </div>
          <div className="extra-options" style={{ marginTop: 10 }}>
            <Button
              variant="primary"
              className="w-100 mb2"
              onClick={() => dispatch(selectIMDFBuilding())}
            >
              Load Test IMDF APH
            </Button>
          </div>
        </>
      );

    return null;
  };
  return (
    <div className="relative flex flex-column">
      <BuildingCampusSelect
        buildings={fillBuildingsDropdown()}
        onBuildingChange={onBuildingSelect}
        selectedBuilding={dropdownSelection}
        campuses={fillCampusDropdown()}
        onCampusChange={onCampusSelect}
        selectedCampus={campusDropdownSelection}
      />

      {renderBuildingOptions()}
    </div>
  );
};
export default DirectionsOptions;
