import * as gm from 'goodmaps-sdk';
import { Vector2 } from 'three';
import { POI } from './entities/POI';
import Room from './entities/Room';
import { Floor } from './entities/Floor';
import GPSPositioning from './positioning/GPSPositioning';
import ExploreEntity from './ExploreEntity';
import { ExplorePosition } from './types';
import { RouteNetwork } from './routing/RouteNetwork';

export default class ExploreCampus extends ExploreEntity {
  gmCampus: gm.Campus;

  gpsPositioning: GPSPositioning;

  constructor(gmCampus: gm.Campus, buildingMetaData?: gm.BuildingMetaData) {
    super();

    this.cpsMapId = buildingMetaData?.localization?.cpsMapId || null;
    this.gmCampus = gmCampus;
    this.gpsPositioning = new GPSPositioning(this);

    this.id = gmCampus.uuid;

    const getPOI = (
      node: gm.POI | gm.TestPoint | gm.Door,
      type: 'door' | 'poi' | 'testpoint',
      bmBuilding: gm.Campus
    ): POI => {
      const { x, y } = bmBuilding.calcProjection(node);
      const p: POI = {
        id: node.id.toLowerCase(),
        routePointID: node.id.toLowerCase(),
        name: node.name,
        shortName: node.shortName,
        type,
        position: new Vector2(x, y),
        level: node.level,
        startingPoint: !!node.virtualStart,
        attachedRooms: [],
        extraInfo: node.extraInfo ? node.extraInfo : [],
        // @ts-ignore
        poiType: node.poiType || node.doorType || null,
        shelf: false,
      };
      return p;
    };

    let outline = [];
    outline = gmCampus.boundary.getNodes().map(node => {
      const proj = gmCampus.calcProjection(node);
      return { x: proj.x, y: proj.y };
    });

    // Generate beacons and pois, putting them in the table

    const campusPOIS: POI[] = [];
    gmCampus.getPOIs().forEach(poi => {
      const p = getPOI(poi, 'poi', gmCampus);
      p.level = 0;
      this.entityTable[poi.id.toLowerCase()] = p;
      this.pois.push(p);
      campusPOIS.push(p);
    });

    const campusTestPoints: POI[] = [];
    gmCampus.getTestPoints().forEach(poi => {
      const p = getPOI(poi, 'testpoint', gmCampus);
      p.level = 0;
      this.entityTable[poi.id.toLowerCase()] = p;
      this.testPoints.push(p);
      campusTestPoints.push(p);
    });

    const campusDoors: POI[] = [];
    gmCampus.getDoors().forEach(door => {
      const p = getPOI(door, 'door', gmCampus);
      p.level = 0;
      this.entityTable[door.id.toLowerCase()] = p;
      this.doors.push(p);
      campusDoors.push(p);
      if (p.name) {
        this.pois.push(p);
        campusPOIS.push(p);
      }
    });

    const campusRooms: Room[] = [];
    gmCampus.getElements().forEach(room => {
      let r: Room = this.entityTable[room.id.toLowerCase()];
      if (!r) {
        let type: 'room' | 'area' | 'corridor' = 'room';
        switch (room.elementType) {
          case gm.ElementType.Area:
            type = 'area';
            break;
          case gm.ElementType.Corridor:
            type = 'corridor';
            break;
          default:
            type = 'room';
            break;
        }
        r = new Room(room.id.toLowerCase(), room.name, type, '', room.getNodes(), [], 0);

        // @ts-ignore
        r.extraInfo = room.extraInfo ? room.extraInfo : [];
        // @ts-ignore
        r.roomType = room.roomType;

        this.entityTable[room.id.toLowerCase()] = r;
        this.rooms.push(r);
      }

      // Attach doors
      room.getDoors().forEach(d => {
        const door: POI = this.entityTable[d.id.toLowerCase()];
        if (door) {
          door.attachedRooms.push(r);
          r.doors.push(door);
        }
        door.level = 0;
      });
      campusRooms.push(r);
    });

    const floor: Floor = {
      level: 0,
      name: gmCampus.name,
      pois: campusPOIS,
      doors: campusDoors,
      rooms: campusRooms,
      testPoints: campusTestPoints,
      outline: outline.map(point => new Vector2(point.x, point.y)),
      // beacons: [],
    };

    this.floors.push(floor);

    const routes: {
      id: string;
      level: number;
      routeType: number;
      connectionType: number;
      nodeConnectionType: number;
      x: number;
      y: number;
      weight: number;
    }[][] = this.gmCampus.getRoutes().map(route => {
      if (route.name !== 'microRoute' && route.levelList && route.levelList.length > 1) {
        return [];
      }

      const nodes = (route as any).getNodes();
      const routePointsArray = [];

      for (let i = 0; i < nodes.length; i += 1) {
        const node = nodes[i];
        if (!node) {
          return undefined;
        }
        const { x, y } = this.gmCampus.calcProjection(node);
        routePointsArray.push({
          id: `${node.id}`.toLowerCase(),
          routeType: route.routeType,
          connectionType: route.connectionType ? route.connectionType : null,
          nodeConnectionType:
            typeof node.getConnections === 'function' &&
            node.getConnections().length > 0 &&
            node.getConnections()[0].connectionType !== undefined
              ? node.getConnections()[0].connectionType
              : null,
          level: 0,
          x,
          y,
          weight: route.weight,
        });
      }

      return routePointsArray;
    });

    const poisTable: { [routePointID: string]: POI } = {};
    this.pois.forEach(p => {
      poisTable[p.id] = p;
    });

    this.routeNetwork = new RouteNetwork(this, routes, poisTable, { IS_CAMPUS: true });
  }

  getPosition(): ExplorePosition {
    return this.gpsPositioning.getPosition();
  }

  getBuilding(name: string) {
    return this.gmCampus.getBuildingOutlinesForCampus().find(building => building.name === name);
  }

  getAllBuildings() {
    return this.gmCampus.getBuildingOutlinesForCampus();
  }
  // getMappedBuilding(id: string): gm.BuildingMetaData {
  //   return Explore.getStatus().nearestBuildings.find(building => building.id === id);
  // },
  // getAllMappedBuildings: (): gm.BuildingMetaData[] => {
  //   return Explore.getStatus().nearestBuildings.filter(building =>
  //     building.parentId.includes(campus.id)
  //   );
  // },

  insideOf(position: { lat: number; lon: number }, range: number): boolean {
    return this.gmCampus.insideCampus(position, range);
  }

  getCpsMapIds(): string[] {
    return [];
  }
}
