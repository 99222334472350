import {
  AUTHENTICATION_ERROR,
  USER_AUTHENTICATED,
  LOAD_USER,
  AUTHENTICATED_USER,
  SIGN_OUT_USER,
} from '../actions/types';

const initialState = {
  userAuthenticated: false,
  signInError: false,
  signedInUser: '',
  jwt: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATED_USER: {
      return { ...state, jwt: action.payload };
    }
    case LOAD_USER: {
      return { ...state, signedInUser: action.payload };
    }
    case AUTHENTICATION_ERROR: {
      return { ...state, signInError: true };
    }
    case USER_AUTHENTICATED: {
      return { ...state, userAuthenticated: true, signInError: false };
    }
    case SIGN_OUT_USER: {
      return { initialState };
    }
    default:
      return state;
  }
};
