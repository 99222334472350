import { RoomType, Node, Position, GoodMapsLocalStorage } from 'goodmaps-sdk';
import { unitCategory } from 'goodmaps-utils/index';
import * as THREE from 'three';
import { Vector2 } from 'three';
import * as gm from 'goodmaps-sdk';
import { RoutePoint } from '..';
import { ROOM_EDGES_MATERIAL } from '../_3d/materials';
import { POI } from './POI';

export default class Room {
  id: string;

  name: string;

  level: number;

  levelList: number[];

  type: 'room' | 'corridor' | 'area' | 'fixture' | 'connection' | unitCategory;

  connectionType: '' | 'stairs' | 'elevator' | 'escalator';

  points: THREE.Vector2[];

  center: THREE.Vector2;

  doors: POI[] = [];

  routePoints: RoutePoint[] = [];

  pointIds: string[] = [];

  walls: THREE.Line[];

  innerAreas: Room[] = [];

  innerFixtures: Room[] = [];

  // Deprecated
  innerWalls: THREE.Line[] = [];

  holes: THREE.Vector2[][];

  parent: Room = undefined;

  shape: THREE.Mesh;

  roomType: RoomType | unitCategory;

  extraInfo?: {
    markup: string;
    mlMarkup: any;
    mlName: any;
    name: string;
    type: number;
  }[];

  missingTranslation?: boolean;

  constructor(
    id: string,
    name: string,
    type: 'room' | 'corridor' | 'area' | 'fixture' | 'connection' | unitCategory,
    connectionType: '' | 'stairs' | 'elevator' | 'escalator',
    points: Node[],
    holes: Vector2[][],
    level: number,
    levelList: number[] = [],
    missingTranslation?: boolean
  ) {
    this.id = id;
    this.name = name || '';
    this.type = type;
    this.connectionType = connectionType;
    this.points = points.map(point => new THREE.Vector2(point.x, point.y));
    this.pointIds = (points as Node[]).map(point => point.id);
    // this.routePoints = points.map(point => new RoutePoint(point.id, point.level, point.));
    this.level = level;
    this.levelList = levelList;

    this.holes = holes;
    this.walls = this.getWalls(this.points);
    this.shape = this.getShape();

    // Get the center of the room
    const sortedX = this.points.map(p => p.x).sort((a, b) => a - b);
    const sortedY = this.points.map(p => p.y).sort((a, b) => a - b);

    const minX = sortedX[0];
    const maxX = sortedX[sortedX.length - 1];
    const minY = sortedY[0];
    const maxY = sortedY[sortedX.length - 1];

    const x = minX + (maxX - minX) / 2;
    const y = minY + (maxY - minY) / 2;
    this.center = new Vector2(x, y);

    this.missingTranslation = missingTranslation;
  }

  addInnerArea(area: Room) {
    this.innerAreas.push(area);
  }

  addInnerFixture(fixture: Room) {
    this.innerFixtures.push(fixture);
    this.innerWalls = [...this.innerWalls, ...fixture.walls];
  }

  addParent(parent: Room) {
    this.parent = parent;
  }

  private getWalls(points: THREE.Vector2[]) {
    const walls: THREE.Line[] = [];
    const geometry = new THREE.Geometry();
    points.forEach(point => geometry.vertices.push(new THREE.Vector3(point.x, point.y, 0)));
    const line = new THREE.Line(geometry, ROOM_EDGES_MATERIAL);
    walls.push(line);

    this.holes.forEach(hole => {
      const holeGeometry = new THREE.Geometry();
      hole.forEach(point => holeGeometry.vertices.push(new THREE.Vector3(point.x, point.y, 0)));
      const holeLine = new THREE.Line(holeGeometry, ROOM_EDGES_MATERIAL);
      walls.push(holeLine);
    });

    return walls;
  }

  private getShape() {
    const floorShape = new THREE.Shape(this.points);
    floorShape.holes = this.holes.map(p => new THREE.Path(p));
    const floorGeometry = new THREE.ShapeGeometry(floorShape);
    const floorMesh = new THREE.Mesh(floorGeometry);
    switch (this.type) {
      case 'fixture':
        floorGeometry.translate(0, 0, 0.2);
        break;
      case 'area':
        floorGeometry.translate(0, 0, 0.1);
        break;
      default:
        break;
    }
    floorMesh.name = this.id;
    return floorMesh;
  }
}
