/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DataLoggerDataset, DataLoggerDatasetList } from 'goodmaps-utils';
import React, { useState } from 'react';

type Props = {
  dataLoggerFile: DataLoggerDatasetList;
  onDatasetSelect: any;
};

const DatasetViewer = ({ dataLoggerFile, onDatasetSelect }: Props) => {
  const [selectedItem, setSelectedItem] = useState(undefined);

  const renderDataLoggerFile = (d: DataLoggerDataset, i: number) => {
    if (d.dataType === 'image' && d.imageData) {
      return (
        <div key={i} className={`list-item flex-row ${selectedItem === i ? 'selected' : ''}`}>
          <>
            <div
              className="flex flex-column"
              onClick={() => {
                setSelectedItem(i);
                onDatasetSelect(d);
              }}
            >
              <div className="item-name" id="image-name">
                <div className="flex flex-row ">
                  <div className="flex flex-column flex-auto">
                    <b>{d.imageData.name}</b>
                    {d.imageData.description}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      );
    }
    if (d.dataType === 'video' && d.videoData) {
      return (
        <div
          key={i}
          className={`list-item flex-row ${selectedItem === i ? 'selected' : ''}`}
          style={{ backgroundColor: '#203354', color: 'white' }}
        >
          <>
            <div
              className="flex flex-column"
              onClick={() => {
                setSelectedItem(i);
                onDatasetSelect(d);
              }}
            >
              <div className="item-name" id="image-name">
                <div className="flex flex-row ">
                  <div className="flex flex-column flex-auto">
                    <b>{d.videoData.name}</b>
                    {d.videoData.description}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="data-list">
        {dataLoggerFile.dataset.map((d, i) => renderDataLoggerFile(d, i))}
      </div>
    </div>
  );
};

export default DatasetViewer;
