import React from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useDataLoggerFileFetchHook from './useDataLoggerFileFetchHook';
import useDataLoggerEditMutation from './useDataLoggerEditMutation';
import './DataLoggerEditModal.scss';
import { DataLoggerMetadataExt } from 'actions/DataLoggerActions';

type Props = {
  dataSet: DataLoggerMetadataExt;
  onClose: () => void;
};

export const editDataLoggerMetaData = Yup.object({
  name: Yup.string().required('Please provide a valid name.'),
});

export type editDataLoggerFormProperties = Yup.InferType<typeof editDataLoggerMetaData>;

const DataLoggerEditModal = ({ onClose, dataSet }: Props) => {
  const { isFetchLoading, fileName } = useDataLoggerFileFetchHook({ dataSetId: dataSet.dataSetId });

  const { onConfirmFileEdit, isMutationLoading, editData, editError } = useDataLoggerEditMutation({
    dataSet,
  });

  const initialValues: editDataLoggerFormProperties = {
    name: fileName.split('.json')[0],
  };

  const editSuccessMessage = () =>
    editData ? (
      <Alert variant="success">{`Successfully Edited ${editData.name.split(' - ')[1]}`}</Alert>
    ) : null;

  const editErrors = () =>
    editError ? <Alert variant="danger">{`Unsuccessful Edit: ${editError}`}</Alert> : null;

  return (
    <div className="datalogger-edit-modal">
      <Modal.Header closeButton>
        <Modal.Title>{`Edit File: ${fileName}`}</Modal.Title>
      </Modal.Header>
      {isFetchLoading ? (
        <div className="loader-container">
          <Spinner animation="border" />
        </div>
      ) : (
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={editDataLoggerMetaData}
            onSubmit={values => {
              onConfirmFileEdit(values);
            }}
          >
            {formik => (
              <Form id="dataLoggerEditForm" onSubmit={formik.handleSubmit}>
                <Form.Group>
                  <Form.Label>Name*</Form.Label>
                  <Form.Control as="input" id="name" {...formik.getFieldProps('name')} />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="form-feedback-error">{formik.errors.name}</div>
                  ) : null}
                </Form.Group>

                {editSuccessMessage()}
                {editErrors()}
                <hr />
                <div className="footer">
                  <Button
                    style={{ marginRight: '0.25rem' }}
                    onClick={() => onClose()}
                    variant="secondary"
                  >
                    Close
                  </Button>
                  <Button
                    form="dataLoggerEditForm"
                    type="submit"
                    variant="primary"
                    disabled={isFetchLoading || isMutationLoading || !formik.dirty}
                  >
                    {isMutationLoading ? <Spinner animation="border" /> : 'Confirm'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      )}
    </div>
  );
};

export default DataLoggerEditModal;
