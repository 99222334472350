import {
  MAP_OVERLAY_ACTUAL_DIRECTION_UPDATED,
  MAP_OVERLAY_CPS_IMAGE_UPDATED,
  MAP_OVERLAY_EXTRA_INFO_UPDATED,
  MAP_OVERLAY_LIVE_DIRECTION_UPDATED,
  MAP_OVERLAY_RESET,
} from '../actions/types';

const initialState = {
  actualDirection: '',
  liveDirection: '',
  cpsImage: '',
  extra: {
    buildingName: '',
    destination: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MAP_OVERLAY_RESET:
      return { ...initialState };

    case MAP_OVERLAY_CPS_IMAGE_UPDATED:
      return { ...state, cpsImage: action.payload };

    case MAP_OVERLAY_ACTUAL_DIRECTION_UPDATED:
      return { ...state, actualDirection: action.payload };

    case MAP_OVERLAY_LIVE_DIRECTION_UPDATED:
      return { ...state, liveDirection: action.payload };

    case MAP_OVERLAY_EXTRA_INFO_UPDATED: {
      const extra = { ...state.extra, ...action.payload };
      return { ...state, extra };
    }

    default:
      return state;
  }
};
