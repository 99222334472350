import { ApolloClient, NormalizedCacheObject, useMutation } from '@apollo/client';
import { DataLoggerMetadataExt, getDataLoggerData } from 'actions/DataLoggerActions';
import { RootState } from 'App';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAWSApolloClient,
  getAzureApolloClient,
  UPDATE_DATALOGGER_FILE,
} from '../../helpers/ApolloHelper';
import { editDataLoggerFormProperties } from './DataLoggerEditModal';

type Props = {
  dataSet: DataLoggerMetadataExt;
};

const useDataLoggerEditMutation = ({ dataSet }: Props) => {
  const dispatch = useDispatch();
  const { dataLoggerFiles } = useSelector((state: RootState) => state.datalogger);

  const client: ApolloClient<NormalizedCacheObject> =
    dataSet.source === 'aws' ? getAWSApolloClient() : getAzureApolloClient();
  const [editDataLoggerFile, { data, loading, error }] = useMutation(UPDATE_DATALOGGER_FILE, {
    client,
    onCompleted: () => {
      dispatch(getDataLoggerData());
    },
  });

  const onConfirmFileEdit = (values: editDataLoggerFormProperties) => {
    const dataLoggerFile: DataLoggerMetadataExt = dataLoggerFiles.find(
      d => d.dataSetId === dataSet.dataSetId
    );
    if (dataLoggerFile) {
      console.log('dataLoggerFile', dataLoggerFile);
      const updatedDataLoggerFile: DataLoggerMetadataExt = {
        ...dataLoggerFile,
        name: dataLoggerFile.name.replace(dataLoggerFile.name.split(' - ')[1], values.name),
      };
      if (
        updatedDataLoggerFile.type &&
        updatedDataLoggerFile.source &&
        (updatedDataLoggerFile as any).__typename
      ) {
        delete updatedDataLoggerFile.type;
        delete updatedDataLoggerFile.source;
        delete (updatedDataLoggerFile as any).__typename;
      }
      dataLoggerFile.name = `${dataLoggerFile.name}.json`;
      console.log('updatedDataLoggerFile', updatedDataLoggerFile);
      editDataLoggerFile({ variables: { dataset: updatedDataLoggerFile } });
    }
  };

  return {
    onConfirmFileEdit,
    isMutationLoading: loading,
    editData: data?.editDataLoggerFile,
    editError: error,
  };
};

export default useDataLoggerEditMutation;
