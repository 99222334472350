import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'App';
import Form from 'react-bootstrap/Form';
import ModalComponent from 'components/ModalComponent';
import { selectBuilding, selectCampus } from 'actions/DirectionsActions';
import { RouteLog } from 'constants/DataInterfaces';
import {
  fetchDataSet,
  clearRemoteDataList,
  stop,
  loadBuildings,
  selectDatasetImage,
} from '../../actions/RouteLogsActions';
import RouteList from './RouteLogList';
import DeleteModal from './DeleteModal';

type Mode = 'Delete' | 'Edit' | 'Default';

const CPSOptions = () => {
  const dispatch = useDispatch();
  const { listOfBuildings, listOfCampuses } = useSelector((state: RootState) => state.global);
  const { routeLogs } = useSelector((state: RootState) => state.routeLogs);
  const [useCPSImages, setUseCPSImages] = useState(false);
  const [followUser, setFollowUser] = useState(true);
  const [checked, setChecked] = useState(true);
  const [selectedForDelete, setSelectedForDelete] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<Mode>('Default');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadBuildings());
    dispatch(clearRemoteDataList());
  }, [dispatch]);

  const openModal = (datasetID: any, mode: Mode) => {
    setModalMode(mode);
    switch (mode) {
      case 'Delete':
        setSelectedForDelete(datasetID);
        break;
      default:
        break;
    }
    setIsModalOpen(true);
  };

  const renderModal = () => {
    switch (modalMode) {
      case 'Delete':
        return <DeleteModal datasetID={selectedForDelete} onClose={() => setIsModalOpen(false)} />;
    }
  };

  const onDatasetSelect = async (data: RouteLog) => {
    dispatch(stop());
    setLoading(true);

    const selectedBuilding = listOfBuildings.find(building => building.id === data.buildingId);

    if (selectedBuilding) {
      await dispatch(selectBuilding(data.buildingId));
      await dispatch(fetchDataSet(data, 'building'));
      setLoading(false);
      return;
    }

    const selectedCampus = listOfCampuses.find(building => building.id === data.buildingId);

    if (selectedCampus) {
      await dispatch(selectCampus(data.buildingId));
      await dispatch(fetchDataSet(data, 'campus'));
      setLoading(false);
    }
  };

  // const onDeleteButtonClick = (id: string) => {
  //   openModal(id, 'Delete');
  // };

  return (
    <div className="flex flex-column">
      {routeLogs?.length ? (
        <RouteList loading={loading} routeLogs={routeLogs} onSelect={onDatasetSelect} />
      ) : null}
      <div className="extra-options" style={{ marginBottom: 10 }}>
        <div className="flex flex-row justify-between align-items-center">
          <Form>
            <Form.Check
              id="use-image-switch"
              type="switch"
              label=""
              onChange={() => setUseCPSImages(!useCPSImages)}
            />
          </Form>
          <span>Use CPS Images</span>
        </div>

        <div className="flex flex-row justify-between align-items-center">
          <Form>
            <Form.Check
              id="follower-user-switch"
              type="switch"
              label=""
              checked={checked}
              onChange={() => {
                setChecked(!checked);
                // setSnapToUser(!followUser);
                setFollowUser(!followUser);
              }}
            />
          </Form>
          <span>Follow User</span>
        </div>
      </div>

      <ModalComponent
        show={isModalOpen}
        size="sm"
        title={modalMode !== 'Edit' ? `${modalMode} Dataset` : `${modalMode} Dataset`}
        onClose={() => setIsModalOpen(false)}
      >
        {renderModal()}
      </ModalComponent>
    </div>
  );
};
export default CPSOptions;
