import * as gm from 'goodmaps-sdk';
import { Vector2 } from 'three';
import { BuildingMetaData } from 'goodmaps-utils';
import { POI } from './entities/POI';
import Room from './entities/Room';
import { Floor } from './entities/Floor';
import ARPositioning from './positioning/ARPositioning';
import ExploreEntity from './ExploreEntity';
import { RouteNetwork } from './routing/RouteNetwork';
import AtriusPositioning from './positioning/AtriusPositioning';

export default class ExploreBuilding extends ExploreEntity {
  gmBuilding: gm.Building;

  arPositioning: ARPositioning;

  atriusPositioning: AtriusPositioning;

  constructor(gmBuilding: gm.Building, buildingMetaData?: BuildingMetaData) {
    super();

    this.name = gmBuilding.name;
    this.gmBuilding = gmBuilding as gm.Building;
    this.cpsRegion = buildingMetaData?.cpsRegion || '';
    this.cpsType = buildingMetaData?.localization?.buildingType || 'f';
    this.arPositioning = new ARPositioning(this);
    this.atriusPositioning = new AtriusPositioning(this);
    this.id = gmBuilding.uuid;

    const getPOI = (
      node: gm.POI | gm.TestPoint | gm.Door,
      type: 'door' | 'poi' | 'testpoint',
      bmBuilding: gm.Building | gm.Campus
    ): POI => {
      const { x, y } = bmBuilding.calcProjection(node);
      const p: POI = {
        id: node.id.toLowerCase(),
        routePointID: node.id.toLowerCase(),
        name: node.name,
        shortName: node.shortName,
        type,
        position: new Vector2(x, y),
        level: node.level,
        startingPoint: !!node.virtualStart,
        attachedRooms: [],
        extraInfo: node.extraInfo ? node.extraInfo : [],
        poiType: (node as gm.POI).poiType || (node as gm.Door).doorType || undefined,
        shelf: (node as gm.POI).shelf,
      };
      return p;
    };

    gmBuilding.getDetailLevels().forEach(gmLevel => {
      const existingFloor = this.floors.find(f => f.level === gmLevel.level);
      if (existingFloor) {
        return;
      }
      let outline = [];
      outline = gmLevel.getNodes().map(node => {
        const proj = gmBuilding.calcProjection(node);
        return { x: proj.x, y: proj.y };
      });

      // Generate beacons and pois, putting them in the table
      // const beacons = gmBuilding.getBeacons(gmLevel.level).map(beacon => {
      //   const b = getBeacon(beacon, gmBuilding);
      //   entityTable[b.id.toLowerCase()] = b;
      //   allBeacons.push(b);
      //   if (beaconUUIDs.indexOf(b.region) < 0) {
      //     beaconUUIDs.push(b.region);
      //   }
      //   return b;
      // });

      const floorPOIS: POI[] = [];
      gmBuilding.getPOIs(gmLevel.level).forEach(poi => {
        const p = getPOI(poi, 'poi', gmBuilding);
        this.entityTable[poi.id.toLowerCase()] = p;
        this.pois.push(p);
        floorPOIS.push(p);
      });

      const floorTestPoints: POI[] = [];
      gmBuilding.getTestPoints(gmLevel.level).forEach(poi => {
        const p = getPOI(poi, 'testpoint', gmBuilding);
        this.entityTable[poi.id.toLowerCase()] = p;
        this.testPoints.push(p);
        floorTestPoints.push(p);
      });

      const floorDoors: POI[] = [];
      gmBuilding.getDoors(gmLevel.level).forEach(door => {
        const p = getPOI(door, 'door', gmBuilding);
        this.entityTable[door.id.toLowerCase()] = p;
        this.doors.push(p);
        floorDoors.push(p);
        if (p.name) {
          this.pois.push(p);
          floorPOIS.push(p);
        }
      });

      const floorRooms: Room[] = [];
      gmBuilding.getElements(gmLevel.level).forEach(room => {
        let r: Room = this.entityTable[room.id.toLowerCase()];
        if (!r) {
          let type: 'room' | 'area' | 'fixture' | 'corridor' = 'room';
          switch (room.elementType) {
            case gm.ElementType.Fixture:
              type = 'fixture';
              break;
            case gm.ElementType.Area:
              type = 'area';
              break;
            case gm.ElementType.Corridor:
              type = 'corridor';
              break;
            default:
              type = 'room';
              break;
          }

          const holes: Vector2[][] = room.getInnerWays
            ? room.getInnerWays().map(w =>
                w.getNodes().map(n => {
                  const { x, y } = gmBuilding.calcProjection(n);
                  return new Vector2(x, y);
                })
              )
            : [];

          r = new Room(
            room.id.toLowerCase(),
            room.name,
            type,
            '',
            room.getNodes(),
            holes,
            gmLevel.level,
            [],
            false
          );

          // @ts-ignore
          r.extraInfo = room.extraInfo ? room.extraInfo : [];
          // @ts-ignore
          r.roomType = room.roomType;

          this.entityTable[room.id.toLowerCase()] = r;
          this.rooms.push(r);
        }

        // Attach doors
        room.getDoors().forEach(d => {
          const door: POI = this.entityTable[d.id.toLowerCase()];
          if (door) {
            door.attachedRooms.push(r);
            r.doors.push(door);
          }
        });
        floorRooms.push(r);
      });

      // Go back through the rooms, connecting areas/fixtures with their parent
      gmBuilding.getElements(gmLevel.level).forEach(element => {
        const room: Room = this.entityTable[element.id.toLowerCase()];
        if (!room) return;
        if (element.getInteriorWays) {
          const ways = element.getInteriorWays();
          ways.forEach(w => {
            const area: Room = this.entityTable[w.id.toLowerCase()];
            if (!area) return;
            if (area.type === 'fixture') {
              room.addInnerFixture(area);
            } else {
              room.addInnerArea(area);
            }
            area.addParent(room);
          });
        }
      });

      gmBuilding.getConnections(gmLevel.level).forEach(room => {
        // See if we've already created the room
        let r: Room = this.entityTable[room.id.toLowerCase()];
        if (!r) {
          let type: 'stairs' | 'elevator' | 'escalator' = 'stairs';
          let { name: connectionName } = room;
          switch (room.connectionType) {
            case gm.ConnectionType.Elevator:
              type = 'elevator';
              connectionName = connectionName || 'Elevator';
              break;
            case gm.ConnectionType.Escalator:
              type = 'escalator';
              connectionName = connectionName || 'Escalator';
              break;
            default:
              type = 'stairs';
              connectionName = connectionName || 'Stairs';
              break;
          }
          r = new Room(
            room.id.toLowerCase(),
            connectionName,
            'connection',
            type,
            room.getNodes(),
            [],
            gmLevel.level,
            room.levelList
          );
          this.entityTable[room.id.toLowerCase()] = r;
          this.rooms.push(r);
        }

        // Attach doors
        room.getDoors().forEach(d => {
          const door: POI = this.entityTable[d.id.toLowerCase()];
          if (door) {
            door.attachedRooms.push(r);
            r.doors.push(door);
          }
        });

        floorRooms.push(r);
      });

      const floor: Floor = {
        level: gmLevel.level,
        name: gmLevel.name,
        pois: floorPOIS,
        doors: floorDoors,
        rooms: floorRooms,
        testPoints: floorTestPoints,
        outline: outline.map(point => new Vector2(point.x, point.y)),
        cpsMapIds: gmLevel.cpsMapIds,
        gmFloor: gmLevel,
      };

      this.floors.push(floor);
    });

    const routes: {
      id: string;
      level: number;
      routeType: number;
      connectionType: number;
      nodeConnectionType: number;
      oneWay: boolean;
      x: number;
      y: number;
      weight: number;
    }[][] = gmBuilding.getRoutes().map(route => {
      if (route.name !== 'microRoute' && route.levelList && route.levelList.length > 1) {
        return [];
      }
      const nodes = (route as any).getNodes();
      const routePointsArray = [];

      for (let i = 0; i < nodes.length; i += 1) {
        const node = nodes[i];
        if (!node) {
          return undefined;
        }
        routePointsArray.push({
          id: `${node.id}`.toLowerCase(),
          routeType: route.routeType,
          connectionType: route.connectionType ? route.connectionType : null,
          oneWay: route.oneWay,
          nodeConnectionType:
            typeof node.getConnections === 'function' &&
            node.getConnections().length > 0 &&
            node.getConnections()[0].connectionType !== undefined
              ? node.getConnections()[0].connectionType
              : null,
          level:
            typeof node.level !== 'undefined' && !Number.isNaN(node.level)
              ? node.level
              : route.level,
          ...gmBuilding.calcProjection(node),
          weight: route.weight,
        });
      }

      return routePointsArray;
    });

    const doorsTable: { [id: string]: POI } = {};
    this.doors.forEach(d => {
      doorsTable[d.id] = d;
    });

    this.routeNetwork = new RouteNetwork(this, routes, doorsTable);

    if (this.cpsType === 'f') {
      this.cpsMapId = buildingMetaData?.localization?.cpsMapId || null;
    } else {
      this.cpsMapId = `${buildingMetaData?.localization?.immersalMapId}` || null;
    }
    this.gmBuilding.bmd.address = buildingMetaData?.address;

    // compare room points to route network and add matching ones
    this.rooms.forEach(room => {
      room.pointIds.forEach(id => {
        if (this.routeNetwork.getRoutePoint(id)) {
          room.routePoints.push(this.routeNetwork.getRoutePoint(id));
        }
      });
    });
    // this.routeNetwork = new RouteNetwork(
    //   routes.filter(r => r && r.length),
    //   this.pois
    // );
  }

  getPosition() {
    return this.arPositioning.getPosition();
  }

  getAtriusPosition() {
    return this.atriusPositioning.getPosition();
  }

  insideOf(position: { lat: number; lon: number }, range: number): boolean {
    return this.gmBuilding.insideBuilding(position, range);
  }

  getCpsMapIds() {
    return this.cpsType === 'f' || this.cpsType === 'fi'
      ? [this.cpsMapId]
      : this.floors.map(f => f.cpsMapIds).reduce((a, b) => [...a, ...b]);
  }

  getDoors(level?: number) {
    return this.gmBuilding.getDoors(level);
  }
}
