import { BUILDINGS_LOADED, CAMPUSES_LOADED } from '../actions/types';

const initialState = {
  listOfBuildings: [],
  listOfCampuses: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case BUILDINGS_LOADED:
      return { ...state, listOfBuildings: action.payload };
    case CAMPUSES_LOADED: {
      return { ...state, listOfCampuses: action.payload };
    }
    default:
      return state;
  }
};
