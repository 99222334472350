import { RouteLog } from 'constants/DataInterfaces';
import {
  BUILDING_SELECTED_CPS,
  CPS_LOADING_CHANGED,
  CPS_ERROR_CHANGED,
  ROUTE_LOGS_LOADED,
  REMOTE_DATASET_CLEARED,
} from '../actions/types';

interface RouteLogsState {
  buildingSelected: boolean;
  cpsLoading: boolean;
  cpsError: boolean;
  routeLogs: RouteLog[];
}
const initialState = {
  buildingSelected: false,
  cpsLoading: false,
  cpsError: false,
  routeLogs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BUILDING_SELECTED_CPS:
      return {
        ...state,
        buildingSelected: true,
        routeLogs: action.payload,
      };

    case CPS_LOADING_CHANGED:
      return { ...state, cpsLoading: action.payload };

    case CPS_ERROR_CHANGED:
      return { ...state, cpsError: action.payload };

    case ROUTE_LOGS_LOADED:
      return { ...state, routeLogs: action.payload };

    case REMOTE_DATASET_CLEARED:
      return { ...state, buildingSelected: false, remoteDataSets: [], datasetImages: [] };

    case 'DATASET_IMAGES_LOADED':
      return { ...state, datasetImages: action.payload };

    default:
      return state;
  }
};
