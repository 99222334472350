import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Typeahead } from 'react-bootstrap-typeahead';
import { typeaheadFuzzySearchHelper } from 'helpers/searchHelper';

export default ({
  buildings,
  onBuildingChange,
  selectedBuilding,
  campuses = undefined,
  onCampusChange = undefined,
  selectedCampus = undefined,
}) => (
  <div className="extra-options" style={{ marginBottom: 10 }}>
    <Typeahead
      style={{ marginBottom: campuses ? 5 : 0 }}
      id="dropdown-menu"
      labelKey="name"
      filterBy={['name']}
      onChange={onBuildingChange}
      options={buildings}
      placeholder="Select Building"
      selected={selectedBuilding}
    />
    {campuses ? (
      <Typeahead
        id="dropdown-menu"
        labelKey="name"
        onChange={onCampusChange}
        options={campuses}
        filterBy={['name']}
        placeholder="Select Campus"
        selected={selectedCampus}
      />
    ) : null}
  </div>
);
