import { Vector2, Vector3, Line3 } from 'three';
import { DoorType } from 'goodmaps-sdk';
import RoutePoint from './RoutePoint';
import { POI } from '../entities/POI';
import Room from '../entities/Room';
import {
  getDistanceBetweenPoints,
  getAngleBetweenPoints,
  getEnglishDirectionFromTwoAngles,
  convertDegToRad,
  convertRadToDeg,
  calculateRadsDifference,
  calculateDegDifference,
} from '../helpers/MathHelpers';
import { AVERAGE_WALKING_SPEED } from '../globals/constants';
import { RouteNetwork } from './RouteNetwork';
import { RouteInstruction } from './RouteInstruction';
import { ExplorePosition } from '../types';

export type SlowSpeedInstruction = {
  interpolatedDirection: number;
  rotateDirection: 'left' | 'right';
};
export class Route {
  destination: Room | POI;

  snap: Vector2 = new Vector2(0, 0);

  clamp: Vector2;

  inConnection: boolean = false;

  routeNetwork: RouteNetwork;

  route: RoutePoint[];

  routeInstructions: RouteInstruction[];

  indoor: boolean = true;

  needsReroute: boolean = false;

  constructor(
    routeNetwork: RouteNetwork,
    destination: Room | POI,
    route: RoutePoint[],
    orientation: number
  ) {
    this.routeNetwork = routeNetwork;
    this.destination = destination;
    if (this.routeNetwork.configuration.IS_CAMPUS) this.indoor = false;
    this.setRoute(route, orientation);
  }

  setRoute(route: RoutePoint[], orientation: number = 0) {
    this.route = route;
    this.snap.set(route[0]?.position.x, route[0]?.position.y);
    this.clamp = new Vector2(route[0]?.position.x, route[0]?.position.y);
    this.generateRouteInstructions(orientation);
  }

  getRouteLength(): { length: number; timeInSeconds: number } {
    let length = 0;
    this.routeInstructions.forEach(r => {
      length += r.distanceToNextPoint;
    });

    const timeInSeconds = length / AVERAGE_WALKING_SPEED;

    return { length, timeInSeconds };
  }

  destinationIsRoom = () => !!(this.destination as Room).walls;

  update(position: ExplorePosition, reroute: boolean = false): RouteInstruction[] {
    const positionVector = new Vector2(position.x, position.y);
    if (this.inConnection) {
      return this.routeInstructions;
    }
    try {
      if (this.destination) {
        const targetPoint = this.routeInstructions[1];
        // if we are more than 5m away from the target point, recalculate
        const distanceFromSnap = positionVector.distanceTo(this.snap);
        if (distanceFromSnap > this.routeNetwork.configuration.REROUTE_THRESHOLD) {
          this.needsReroute = true;
        }

        if (this.needsReroute && this.routeNetwork.rerouteAllowed) {
          reroute = true;
        }

        // Don't reroute if we are off the map
        if (!this.routeNetwork.building.roomQueries.getRoomAtPosition(position)) {
          reroute = false;
        }

        if (reroute) {
          console.log('~~ REROUTING');
          try {
            this.snap.set(position.x, position.y);
            let route;
            if (this.destinationIsRoom()) {
              route = this.routeNetwork.generateRouteToRoom(position, this.destination as Room);
            } else {
              route = this.routeNetwork.generateRouteToPOI(position, this.destination as POI);
            }
            this.needsReroute = false;
            this.setRoute(route.route, position.orientation);
          } catch (e) {
            console.log(e);
            return [];
          }
        } else {
          try {
            const target = targetPoint.point.position;
            const difference = target.clone().sub(this.snap);
            const dir = difference.normalize();
            const targetIsPOI = !!targetPoint.point.poi;
            const targetIsConnection =
              !!targetPoint.point.connectionType && targetPoint.nextInstruction;

            const { newSnap, newDistanceToTarget } = this.getSnapForNewPosition(position);

            const isOrientedForNewTarget = this.checkIfAlignedToAngle(
              position,
              convertDegToRad(targetPoint.angleToNextPoint)
            );

            // If we're close enough to the point, go ahead and set the position to the point,
            // and update to the next target. For normal points, we consider orientation. For connections, it is a simple distance check
            // TODO: Rework this, I have no idea what this is checking
            const newTarget =
              newDistanceToTarget < this.routeNetwork.configuration.POINT_UPDATE_THRESHOLD_METERS &&
              isOrientedForNewTarget &&
              !targetIsConnection &&
              (targetPoint.nextInstruction ||
                newDistanceToTarget <
                  this.routeNetwork.configuration.POINT_UPDATE_THRESHOLD_POI_METERS) &&
              (!targetIsPOI ||
                (targetIsPOI &&
                  newDistanceToTarget <
                    this.routeNetwork.configuration.POINT_UPDATE_THRESHOLD_POI_METERS)) &&
              this.routeInstructions[0].point.level === this.routeInstructions[1].point.level;

            this.checkIfUserSkippedPoint(position);

            if (newTarget) {
              newSnap.set(target.x, target.y, 0);
            }

            this.bumpSnapTowardMap(newSnap, dir, position.level);

            // Update the user routepoint position to the new, snapped position
            this.snap.set(newSnap.x, newSnap.y);
            this.route[0].position.set(this.snap.x, this.snap.y);

            // If we are at a new target, remove the first point
            if (newTarget && this.route.length > 2) {
              this.clamp.set(this.route[1].position.x, this.route[1].position.y);
              this.route.splice(1, 1);
            }

            if (this.routeNetwork.building.debug) {
              const point = new Vector3(newSnap.x, newSnap.y, 0.3);
              this.routeNetwork.building.renderer?.renderCircleMarker('snap', point, 0xff0000, 0.3);
            }
          } catch (e) {
            console.log('Route update error');
            console.log(e);
          }
        }

        let orientation;
        if (this.indoor) {
          orientation =
            position.speed > 0.5 && position.heading ? position.heading : position.orientation;
        } else {
          orientation = position.orientation;
        }
        return this.generateRouteInstructions(orientation, position.level);
      }
      return [];
    } catch (e) {
      console.log('Route update error 2');
      console.log(e);
      return [];
    }
  }

  checkIfUserSkippedPoint(position: ExplorePosition) {
    // Check to see if we have somehow already passed the point. If so, update to the new point
    let newTarget = false;

    const a = this.routeInstructions[1];
    const b = this.routeInstructions[2];
    if (a && b && a.point.level === position.level && b.point.level === position.level) {
      const aPoint = new Vector3(a.point.position.x, a.point.position.y, 0);
      const bPoint = new Vector3(b.point.position.x, b.point.position.y, 0);
      const snapLine = new Line3(aPoint, bPoint);
      const snapForLine = new Vector3();
      snapLine.closestPointToPoint(new Vector3(position.x, position.y, 0), true, snapForLine);
      let color = 0x00ff00;
      const isOrientedForNewTarget = this.checkIfAlignedToAngle(
        position,
        convertDegToRad(a.angleToNextPoint)
      );

      if (
        a.distanceToNextPoint - bPoint.clone().sub(snapForLine).length() > 0.5 &&
        isOrientedForNewTarget &&
        this.indoor
      ) {
        color = 0xff0000;
        newTarget = true;
      }
      if (this.routeNetwork.building.debug) {
        this.routeNetwork.building.renderer?.renderCircleMarker(
          `skip${newTarget}` ? 'True' : '',
          snapForLine,
          color
        );
      }
    }

    return newTarget;
  }

  bumpSnapTowardMap(snap: Vector3, dir: Vector2 | Vector3, level: number) {
    // If the point is off the map, try to move it back
    let attempts = 3;
    while (
      this.indoor &&
      !this.routeNetwork.building.roomQueries.getRoomAtPosition({ x: snap.x, y: snap.y, level }) &&
      attempts > 0
    ) {
      snap.addScaledVector(new Vector3(dir.x, dir.y, 0), 1);
      attempts--;
    }

    // If we are still off the map just stay at last position
    if (
      !this.routeNetwork.building.roomQueries.getRoomAtPosition({ x: snap.x, y: snap.y, level }) &&
      this.indoor
    ) {
      snap.set(this.snap.x, this.snap.y, 0.3);
    }
    return snap;
  }

  getSnapForNewPosition(
    position: ExplorePosition
  ): {
    newSnap: Vector3;
    newDistanceToTarget: number;
  } {
    const targetPoint = this.routeInstructions[1];
    const target = targetPoint.point.position;
    const difference = target.clone().sub(this.snap);
    const dir = difference.normalize();
    const targetIsPOI = !!targetPoint.point.poi;
    const targetIsConnection = !!targetPoint.point.connectionType && targetPoint.nextInstruction;

    // Find the nearest point on a line. Clamp to the target point, but allow the user to get further
    // away from the current point
    const line = new Line3(
      new Vector3(this.clamp.x, this.clamp.y, 0),
      new Vector3(target.x, target.y, 0)
    );
    const newSnap = new Vector3();
    line.closestPointToPoint(new Vector3(position.x, position.y, 0), true, newSnap);

    // Find the new distance, and then bump forward a little bit
    let newDistanceToTarget = newSnap.clone().sub(new Vector3(target.x, target.y, 0)).length();

    // Bump the point a little forward
    if (newDistanceToTarget > 1.5) {
      newSnap.addScaledVector(
        new Vector3(dir.x, dir.y, 0),
        this.routeNetwork.configuration.BUMP_AHEAD_AMOUNT
      );
      newDistanceToTarget -= 1.5;
    } else if (!targetIsPOI && !targetIsConnection) {
      // Set the snap to just before the target point so that the angles are maintained
      newSnap.set(target.x + dir.x * -0.25, target.y + dir.y * -0.25, 0);
      newDistanceToTarget = 0.25;
    }
    return { newSnap, newDistanceToTarget };
  }

  checkIfAlignedToAngle(position: ExplorePosition, targetRadians: number) {
    const heading = convertDegToRad(position.heading);
    const orientation = convertDegToRad(position.orientation);
    const targetOrientation = targetRadians;
    const diffHeading = calculateRadsDifference(heading, targetOrientation);
    const diffOrientation = calculateRadsDifference(orientation, targetOrientation);
    return (
      diffHeading < this.routeNetwork.configuration.POINT_UPDATE_THRESHOLD_RADIANS ||
      diffOrientation < this.routeNetwork.configuration.POINT_UPDATE_THRESHOLD_RADIANS
    );
  }

  generateRouteInstructions(initialOrientation: number, renderLevel = 0): RouteInstruction[] {
    const instructions: RouteInstruction[] = [];
    const orientation = initialOrientation || 0;

    this.route.forEach((r, i) => {
      // TODO: Sometimes when you're initializing a route the user's orientation
      // hasn't been updated. This will cause an error to be generated but we're
      // currently catching it. Directions seem to generate correctly afterwards.
      // For the meantime I'm going to set orientation to 0 if it comes back as
      // undefined
      try {
        let angleToNextPoint = 0;
        let relativeAngleToNextPoint = 0;
        let distanceToNextPoint = 0;
        let directionToNextPoint = '';

        if (i === 0 && this.route[i + 1]) {
          // Initial point needs to take user's orientation into consideration
          angleToNextPoint = getAngleBetweenPoints(r.position, this.route[i + 1].position);
          distanceToNextPoint = getDistanceBetweenPoints(r.position, this.route[i + 1].position);
          const angleWithOrientation = (angleToNextPoint - orientation) % 360;
          if (r.level !== this.route[i + 1].level) {
            const goingUp = r.level < this.route[i + 1].level;
            directionToNextPoint = goingUp ? 'Up' : 'Down';
          } else {
            directionToNextPoint = getEnglishDirectionFromTwoAngles(null, angleWithOrientation);
            relativeAngleToNextPoint = calculateDegDifference(null, angleWithOrientation);
          }
        } else if (this.route[i + 1]) {
          // Otherwise, use the next point to determine my direction
          angleToNextPoint = getAngleBetweenPoints(r.position, this.route[i + 1].position);
          distanceToNextPoint = getDistanceBetweenPoints(r.position, this.route[i + 1].position);
          if (r.level !== this.route[i + 1].level) {
            const goingUp = r.level < this.route[i + 1].level;
            directionToNextPoint = goingUp ? 'Up' : 'Down';
          } else {
            directionToNextPoint = getEnglishDirectionFromTwoAngles(
              !instructions[i - 1] ? 0 : instructions[i - 1].angleToNextPoint,
              angleToNextPoint
            );
            relativeAngleToNextPoint = calculateDegDifference(
              !instructions[i - 1] ? 0 : instructions[i - 1].angleToNextPoint,
              angleToNextPoint
            );
          }
        }
        instructions.push({
          point: r,
          angleToNextPoint,
          relativeAngleToNextPoint,
          distanceToNextPoint,
          directionToNextPoint,
          nextInstruction: null,
        });
      } catch (e) {
        console.log('Djikstra', e);
      }
    });

    // Prune the instructions by removing nodes along a straight line that don't have a POI
    const prunedInstructions: RouteInstruction[] = [];
    instructions.forEach((instruction, i) => {
      if (!prunedInstructions.length || instruction === instructions[instructions.length - 1]) {
        prunedInstructions.push(instruction);
      } else {
        // If the point the same angle as the last point (within 2 degrees), and it has no POI, "merge"
        // it with the last point by adding its distance to the last point
        const lastInstruction = prunedInstructions[prunedInstructions.length - 1];
        if (
          !instruction.point.connectionType &&
          !instruction.point.nodeConnectionType &&
          !lastInstruction.point.connectionType &&
          !lastInstruction.point.nodeConnectionType &&
          instruction.point.level === lastInstruction.point.level &&
          Math.abs(instruction.angleToNextPoint - lastInstruction.angleToNextPoint) < 10 &&
          (!instruction.point.poi || instruction.point.poi?.poiType === DoorType.No) &&
          instruction.point.id !== 'lastPoint' &&
          instruction.point.id !== 'destination'
        ) {
          lastInstruction.distanceToNextPoint += instruction.distanceToNextPoint;
          lastInstruction.angleToNextPoint = instruction.angleToNextPoint;
        } else if (instruction.point.connectionType) {
          // Merge levels
          const nextInstruction = instructions[i + 1];
          if (!lastInstruction?.point?.connectionType || !nextInstruction?.point?.connectionType) {
            prunedInstructions.push(instruction);
            lastInstruction.nextInstruction = instruction;
            if (lastInstruction.point.connectionType) lastInstruction.distanceToNextPoint = 1;
          }
        } else {
          prunedInstructions.push(instruction);
          lastInstruction.nextInstruction = instruction;
        }
      }
    });

    this.route = prunedInstructions.map(p => p.point);
    this.routeInstructions = prunedInstructions;

    if (this.routeNetwork.building.debug) {
      this.routeNetwork.building.renderer?.renderRoute(
        'currentRoute',
        this.routeInstructions.map(r => r.point),
        renderLevel
      );
    }

    return prunedInstructions;
  }

  getSlowSpeedInterpolatedDirection(position: ExplorePosition): SlowSpeedInstruction {
    const target = this.routeInstructions[1];
    const angle = getAngleBetweenPoints(new Vector2(position.x, position.y), target.point.position);
    let angleRelativeToOrientation = (position.orientation - angle) % 360;
    let rotateDirection: 'left' | 'right';
    // If the angle is positive, it is rotating right.
    if (
      Math.sign(angleRelativeToOrientation) === 1 ||
      Math.sign(angleRelativeToOrientation) === 0
    ) {
      // if greater than 180 we are past the halfway point and should rotate left
      rotateDirection = angleRelativeToOrientation > 180 ? 'left' : 'right';
      // if the angle is negative than it is rotating left
    } else if (Math.sign(angleRelativeToOrientation) === -1) {
      // if less than -180 we are past the halfway point and should rotate right
      rotateDirection = angleRelativeToOrientation < -180 ? 'right' : 'left';
    }
    angleRelativeToOrientation =
      angleRelativeToOrientation < 0
        ? angleRelativeToOrientation + 360
        : angleRelativeToOrientation;
    angleRelativeToOrientation =
      angleRelativeToOrientation > 180
        ? 180 - (angleRelativeToOrientation - 180)
        : angleRelativeToOrientation;
    // console.log(`second check: ${angleRelativeToOrientation}`);
    const interpolatedDirection = angleRelativeToOrientation / 180;
    return { interpolatedDirection, rotateDirection };
  }
}
