export const SIMULATION_REQUESTED = 'simulation_requested';
export const WC_LOADED = 'wc_loaded';
export const SIMULATION_STARTED = 'simulation_started';
export const SIMULATION_CANCELED = 'simulation_canceled';
export const SIMULATION_PAUSED = 'simulation_paused';
export const SIMULATION_RESUMED = 'simulation_resumed';
export const METRICS_UPDATED = 'metrics_updated';
export const AR_POSITION_UPDATED = 'ar_position_updated';
export const CPS_POSITION_UPDATED = 'cps_position_updated';
export const SIMULATION_INSTRUCTION_UPDATED = 'simulation_instruction_updated';
export const SET_DATA_SETS = 'simulation_set_data_sets';

export const BUILDINGS_LOADED = 'buildings_loaded';
export const BUILDING_SELECTED = 'building_selected';
export const BUILDING_SELECTED_DATALOGGER = 'building_selected_datalogger';
export const BUILDING_SELECTED_SIMULATION = 'building_selected_simulation';
export const DIRECTIONS_CREATED = 'directions_created';
export const DIRECTIONS_UPDATED = 'directions_updated';
export const CURRENT_DIRECTION = 'current_direction';
export const CAMPUSES_LOADED = 'CAMPUSES_LOADED';
export const CAMPUS_SELECTED = 'CAMPUS_SELECTED';
export const TEST_PERCENTAGE_UPDATED = 'TEST_PERCENTAGE_UPDATED';
export const CPS_TEST_MESSAGE_UPDATE = 'CPS_TEST_MESSAGE_UPDATE';

export const CPS_DATA_IMPORTED = 'cps_data_imported';
export const ROUTE_LOGS_LOADED = 'ROUTE_LOGS_LOADED';
export const BUILDING_SELECTED_CPS = 'building_selected_cps';
export const CPS_LOADING_CHANGED = 'cps_loading_changed';
export const CPS_ERROR_CHANGED = 'cps_error_changed';
export const BUILDING_DATASETS_LOADED = 'building_datasets_loaded';
export const DATALOGGER_FILES_LOADED = 'DATALOGGER_FILES_LOADED';
export const DATALOGGER_FILE_SELECTED = 'DATALOGGER_FILE_SELECTED';
export const DATALOGGER_FILE_CLEARED = 'DATALOGGER_FILE_CLEARED';
export const IMPORTED_CPS_DATASET_CLEARED = 'imported_cps_dataset_cleared';
export const REMOTE_DATASET_CLEARED = 'remote_dataset_cleared';

export const USER_AUTHENTICATED = 'user_authenticated';
export const LOAD_USER = 'load-user';
export const AUTHENTICATED_USER = 'AUTHENTICATED_USER';
export const AUTHENTICATION_ERROR = 'authentication_error';
export const SIGN_OUT_USER = 'sign_out_user';
export const SIGN_UP_ERROR = 'sign_up_error';

export const BEST_FIT_IMAGE_LOADED = 'best_fit_image_loaded';
export const ADD_BEST_FIT_DOOR = 'add_best_fit_door';
export const BEST_FIT_CALCS = 'best_fit_calcs';
export const RESET_BEST_FIT_DOORS = 'reset_best_fit_doors';
export const SET_BEST_FIT_DOOR_POSSIBILITIES = 'set_best_fit_door_possibilities';

export const MAP_OVERLAY_RESET = 'MAP_OVERLAY_RESET';
export const MAP_OVERLAY_CPS_IMAGE_UPDATED = 'MAP_OVERLAY_CPS_IMAGE_UPDATED';
export const MAP_OVERLAY_ACTUAL_DIRECTION_UPDATED = 'MAP_OVERLAY_ACTUAL_DIRECTION_UPDATED';
export const MAP_OVERLAY_LIVE_DIRECTION_UPDATED = 'MAP_OVERLAY_LIVE_DIRECTION_UPDATED';
export const MAP_OVERLAY_EXTRA_INFO_UPDATED = 'MAP_OVERLAY_EXTRA_INFO_UPDATED';
