import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'App';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ExploreBuilding, Renderer } from '_sdks/cavu-sdk/src';
import Button from 'react-bootstrap/esm/Button';
import { stop as cpsStop } from 'actions/RouteLogsActions';
import { stop as dlStop } from 'actions/DataLoggerActions';
import './Map.scss';
import * as BuildingManagerHelper from 'helpers/BuildingManagerHelper';

const currentCoords: { x: number; y: number } = {
  x: 0,
  y: 0,
};
export const getCoordinates = () => currentCoords;

const Map = () => {
  const dispatch = useDispatch();
  const selectedBuilding: ExploreBuilding = useSelector(
    (state: RootState) => state.directions.selectedBuilding
  );
  // const { selectedBuilding, pois, selectedCampus, percentage } = useSelector(
  //   (state: RootState) => state.directions
  // );
  const { actualDirection, liveDirection, cpsImage, extra } = useSelector(
    (state: any) => state.mapOverlay
  );
  const { cpsLoading, cpsError } = useSelector((state: any) => state.routeLogs);
  const container = useRef<any>();
  const [mousePosition, setMousePosition] = useState([0, 0]);

  let imageClass = '';
  if (cpsLoading) imageClass = 'loading';
  else if (cpsError) imageClass = 'error';
  else if (!cpsLoading) imageClass = 'success';

  // useEffect(() => {
  //   console.log('hi');
  //   console.log((selectedBuilding as ExploreBuilding)?.gmBuilding?.name);
  // }, [selectedBuilding]);

  useEffect(() => {
    container.current.innerHTML = '';
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    container.current.appendChild(renderer.domElement);
    const { clientWidth, clientHeight } = container.current;
    let controls: OrbitControls;

    let x: number;
    let y: number;
    const onMouseMove = e => {
      x = ((e.clientX - 400) / clientWidth) * 2 - 1;
      y = -(e.clientY / clientHeight) * 2 + 1;
    };
    window.addEventListener('mousemove', onMouseMove, false);

    console.log('init renderer');
    const building = BuildingManagerHelper.getCurrentExploreEntity();
    if (!building) return;
    building.createRenderer(
      renderer as any,
      clientWidth,
      clientHeight,
      r => {
        controls = new OrbitControls(r.getCamera(), renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.enableRotate = true;
        controls.enablePan = true;
        controls.minDistance = 10;
        controls.maxDistance = 500;
        controls.maxPolarAngle = Math.PI / 5;
        // controls.minPolarAngle = -Math.PI / 2;
        controls.target.set(0, 0, 0);
        r.getCamera().position.set(0, 0, 50);
        r.getCamera().lookAt(0, 0, 0);
        controls.update();
        // controls.screenSpacePanning = true;
        controls.mouseButtons = {
          RIGHT: THREE.MOUSE.ROTATE,
          MIDDLE: THREE.MOUSE.DOLLY,
          LEFT: THREE.MOUSE.PAN,
        };
      },
      r => {
        const v = r.getCoordinateFromRaycaster(x, y);
        currentCoords.x = v.x;
        currentCoords.y = v.y;
        setMousePosition([v.x, v.y]);
        if (controls && r.snapToUser) controls.target = r.currentCameraTarget;
        if (controls) controls.update();
      }
    );

    const onResize = e => {
      const { clientWidth, clientHeight } = container.current;
      renderer.setSize(clientWidth, clientHeight);
      building.renderer.getCamera().aspect = clientWidth / clientHeight;
      building.renderer.getCamera().updateProjectionMatrix();
      controls.update();
    };
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', onResize);
      controls.dispose();
    };
  }, [selectedBuilding]);

  return (
    <div className="map-container">
      <div className="map-viewer relative" ref={container} />
      <div className="coordinates absolute">
        x: {mousePosition[0].toFixed(3)}
        <br />
        y: {mousePosition[1].toFixed(3)}
      </div>

      <div className="directions-text-container">
        {liveDirection ? (
          <div className="direction-text" id="directionText">
            {liveDirection}
          </div>
        ) : null}
        {actualDirection ? (
          <div className="direction-text" id="directionTextActual">
            {actualDirection}
          </div>
        ) : null}
        <div className="direction-stop" id="directionStopButton">
          <Button
            className="delete-btn"
            variant="danger"
            onClick={() => {
              dispatch(cpsStop());
              dispatch(dlStop());
            }}
          >
            Stop Route
          </Button>
        </div>
      </div>

      {cpsImage ? (
        <div className={`image-box absolute ${imageClass}`}>
          <img
            className="image"
            alt="preview of cps call"
            style={{ width: 250, backgroundColor: '#333' }}
            id="cps-image1"
            src={cpsImage}
          />
        </div>
      ) : null}

      <div className="extra-options extra-map-info">
        <b>{extra.buildingName ? extra.buildingName : 'No Building Selected'}</b>
        {extra.cpsType ? (
          <>
            <br />
            <b>CPS Type:</b> {extra.cpsType}
          </>
        ) : null}
        {extra.region ? (
          <>
            <br />
            <b>Region:</b> {extra.region}
          </>
        ) : null}
        {extra.queryMaps ? (
          <>
            <br />
            <b>Query Maps:</b> {extra.queryMaps}
          </>
        ) : null}
        {extra.mapId ? (
          <>
            <br />
            <b>Localized Map:</b> {extra.mapId}
          </>
        ) : null}
        {extra.phonePosition ? (
          <>
            <br />
            <b>Phone Position:</b> {extra.phonePosition}
          </>
        ) : null}
        {extra.cpsConfirmationCount ? (
          <>
            <br />
            <b>Confirmations:</b> {extra.cpsConfirmationCount}
          </>
        ) : null}
        {extra.destination ? (
          <>
            <br />
            <b>Destination:</b> {extra.destination}
          </>
        ) : null}
        {extra.speed ? (
          <>
            <br />
            <b>Speed:</b> {extra.speed}
          </>
        ) : null}
        {extra.floor != undefined ? (
          <>
            <br />
            <b>Floor:</b> {extra.floor}
          </>
        ) : null}
        {extra.realTarget != undefined ? (
          <>
            <br />
            <b>Real Target:</b> {extra.realTarget}
          </>
        ) : null}
        {extra.liveTarget != undefined ? (
          <>
            <br />
            <b>Live Target:</b> {extra.liveTarget}
          </>
        ) : null}
      </div>

      <div className="map-buttons">
        <div
          className="map-button"
          onClick={() => {
            const building = BuildingManagerHelper.getCurrentExploreEntity();
            const floor = building.getFloor(building.virtualPositioning.getPosition().level - 1);
            if (floor) {
              const { x, y } = building.getPosition();
              building.virtualPositioning.setPosition({ x, y, level: floor.level });
            }
          }}
        >
          ▼
        </div>
        <div
          className="map-button"
          onClick={() => {
            const building = BuildingManagerHelper.getCurrentExploreEntity();
            const floor = building.getFloor(building.virtualPositioning.getPosition().level + 1);
            if (floor) {
              const { x, y } = building.getPosition();
              building.virtualPositioning.setPosition({ x, y, level: floor.level });
            }
          }}
        >
          ▲
        </div>
      </div>
    </div>
  );
};

export default Map;
