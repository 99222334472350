import React from 'react';
import { Modal } from 'react-bootstrap';

type props = {
  size: 'sm' | 'lg' | 'xl';
  title: string;
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const ModalComponent = ({ size, title, show, onClose, children }: props) => (
  <Modal
    centered
    size={size}
    show={show}
    onHide={() => {
      onClose();
    }}
  >
    <>
      {/* Potentially move this into individual modal code, making this component only concerned
      with displaying children, sizing/styling of container, and onClose */}
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {children}
    </>
  </Modal>
);

export default ModalComponent;
