import { IMap } from 'goodmaps-sdk';

export const aphIMDF: IMap = {
  parentId: '',
  id: '3dd050d8-3528-4587-9414-c0b895ec54f7',
  name: 'American Printing House for the Blind',
  orgId: '',
  published: false,
  private: false,
  cpsMapId: '',
  isInsideOf: true,
  distance: 0,
  angle: 0,
  features: {
    address: {
      id: 'edc2af67-1074-4715-a287-9b94dbdc0335',
      buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
      type: 'Feature',
      geometry: null,
      feature_type: 'address',
      properties: {
        address: '1839 Frankfort Ave',
        unit: null,
        locality: 'Louisville',
        province: 'US-KY',
        country: 'US',
        postal_code: '40206',
        postal_code_ext: null,
        postal_code_vanity: null,
      },
    },
    amenity: [
      {
        id: '03df2ccc-dba6-423c-ab27-90cd5c23721c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71384124524, 38.25813664969],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['2fe220ea-1999-4508-ab2b-35387b3a2d86'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '055695d8-f233-4d85-80d4-928fbfa029c0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71463236003, 38.256874007389996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['e7d597ae-7064-4340-b91d-b91bbbae2ec0'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '06242260-d44d-4c28-9f47-6763c3569699',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714703250675, 38.256832768795],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'storage',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['f75b6577-1ee9-423f-bb9f-5d57b172018e'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '075236cf-7cc9-46f4-9961-0d7c2626f08f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71438410703, 38.25759881112],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'storage',
          accessibility: null,
          name: {
            en: 'Storage',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['c85d6f0a-f16c-4bd6-b57c-1ead7fb8cebb'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '07a5b348-269d-4c77-ae58-4f0dfedee189',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71463236003, 38.256874007389996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['5178993a-e485-4c11-856f-ce64e356c8e6'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '07c431fd-22c5-4ce4-8e6d-02009ac4fa3f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71412789949, 38.258116854935],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'storage',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['328be258-8aab-45b7-be29-8537b393c67a'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '09ed579c-4f4c-47f9-a290-ce4b8a01ae45',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71412176986999, 38.2579400177],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['1d7d887a-4d9a-42eb-b03c-9523041370fa'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '13922824-50d5-495f-b532-e1a56e524fa0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71458531187, 38.256974322125004],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a3ebf07e-097c-48f2-ba87-ae0a4a15f201'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '142be952-4a9a-47cf-ac5e-8fddb052ce1f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71480824259, 38.256971829815],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['abb93d9e-da6c-43f5-a23f-fc360b5c19e2'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '1503e7c7-5176-4079-9cf9-b7df75f1a410',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71433012404296, 38.25766014778711],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'information',
          accessibility: null,
          name: {
            en: 'click here',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['93f65377-853f-4e9f-9a06-c58b84ec749e'],
          address_id: null,
          correlation_id: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71433012404296, 38.25766014778711],
            type: 'Point',
          },
        },
      },
      {
        id: '174ae1c8-ee49-4154-9dfc-5d2833141f41',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71479174483, 38.256996298715],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['869b56d1-5b96-4845-9e5e-ef37e83c436b'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '1a698e9f-5c0c-4933-a437-b4aab406a07f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71482115334, 38.256939990584996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['f7b5b6a2-5bb1-429d-af40-9433e6e17bbf'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '1dd367af-eb18-4c56-8c17-a427f5d7a376',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71447999002571, 38.257344929604194],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'unspecified',
          accessibility: null,
          name: {
            en: 'testPOI',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['50ac65dc-a842-4d2d-83c8-0e63236633aa'],
          address_id: null,
          correlation_id: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71447999002571, 38.257344929604194],
            type: 'Point',
          },
        },
      },
      {
        id: '1eb2eccf-b2a9-4b8b-90ce-b5377334ce22',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71452791843501, 38.25746962746],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['3d936666-16f6-48c7-9e3b-314b1e744729'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '2163556c-e78e-4143-aa16-1226c7e40f1b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713869609795, 38.258097868785],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'storage',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['35624642-a46d-45f8-b9ce-f2da32e4f3be'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '247b74f6-9628-4e5f-a784-e2b7f56a4546',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71386901845109, 38.258083997621526],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'information',
          accessibility: null,
          name: {
            en: 'Test favorite delete sam',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['cc3fc769-3257-4d12-bf7c-606d13838aa2'],
          address_id: null,
          correlation_id: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71386901845109, 38.258083997621526],
            type: 'Point',
          },
        },
      },
      {
        id: '269650ec-f30e-48bd-a263-ff5a2c5e2b75',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71452791843501, 38.25746962746],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['560871b5-0b71-42f1-aeeb-34dc79f0c7dc'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '278caca1-c5a2-4645-925b-8ba0b9041f9a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714804649685, 38.25697098373],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['941a3fb4-6bd8-4e05-b1df-0ead7928836d'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '2ab97a64-a4c9-4387-b9b2-6b8601a747a3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714313298635, 38.257525300764996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Do not use Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a552838f-14e9-408a-a42a-dbd18ecf7ed3'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '2c2b01e7-a6dc-4720-90bf-e7aae595bcd5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71459004978, 38.256976128074996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a68d6c23-0d15-48a9-9a52-16d805324145'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '2c5a3a77-4328-43fd-8929-3725ec28900a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71452497651, 38.25686511866],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.family',
          accessibility: null,
          name: {
            en: 'Family Restroom',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['74fad0c1-3daf-4c4b-a6bc-a1631dcd84b6'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '2fbc4ded-39c5-4a48-b80a-2ef42ca5f6f9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713561447145, 38.258188551145],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'East Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['bf11f39f-0093-49ff-bb0a-b63e26b94e04'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '32a08b4f-1d46-44f1-bc15-20f7184db881',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71475005796, 38.25696807912],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['be370945-2736-4c90-a89e-dd3bd83ffbc5'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '34210221-6e60-4009-8e95-ab7559744fcb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71444895148, 38.256914336150004],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['4a20c995-8663-4805-beb0-69da66f428e9'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '3653e21e-a36a-426f-8f2f-a91810fafd6f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714824553405, 38.25694070722],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['2af0462e-f17d-402e-9b84-7ee33fd9f735'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '3d89d568-fa9c-4995-9951-df3dd8586cef',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714317170005, 38.257501769155],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Stairs',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['6b8ff04f-1dbe-4afa-bece-19bb22f0eb55'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '3e142f58-e6e8-4ac1-acb9-e6ac4b854524',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71389896095, 38.25833464438],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['2c55d857-482f-4585-ab7e-7f04a282a1e5'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '3f874b63-afe8-4b75-9570-208ae92a590a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713797241825, 38.25819792415],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Center Stairwell 6',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['4cfb11a5-0c71-4765-adb8-fedc220312b6'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '40ce7b65-7e55-4861-92f2-856d0091c53a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71397773238499, 38.258296712255],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['57f40a74-df0a-4a81-ada0-7dff6d9525c9'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '41619afb-072b-4cb9-9392-47ad750d934a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71405677037001, 38.257402119935],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['00e45e17-9c20-46c2-886f-27c977e4adbc'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '445df98a-8a48-4a4e-b988-ac7fb38da172',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71472734995, 38.256963344940004],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['f32e7b5f-d54b-49a5-bb82-0949ebaaf30e'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '45e83cbe-dbab-492a-a956-85fb74158b55',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71410550531999, 38.258204565735],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['63d7e688-6eb0-4ccf-a1c7-2a908cae7251'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '49289f48-58d3-48a1-8bc1-4475cec876bb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71394269986, 38.258489849460005],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 4',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['6441bbb5-a4ff-443d-8953-82d3eba05ba5'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '4b56df9e-7e34-44ca-a7da-86b6730f1426',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71463236003, 38.256874007389996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['85f86feb-0d01-4855-b1d2-88275502e593'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '4b6b4397-621d-406d-93cb-ae7d9d72c1c9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714192356755, 38.258000247415],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'storage',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['f3ea4f73-f1eb-456c-af5a-4372b2076a8a'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '4c90173b-e5d2-4e19-9d9a-395a7740ce60',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71370432511, 38.258360271375],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'storage',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['b84aa587-2127-437c-b1a1-c51e8e02f33c'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '4c98ed5c-9d32-45ec-a753-4a7f9601d671',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71410550531999, 38.258204565735],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['5165e67d-cfa0-48af-9deb-c5de6e472c82'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '4cce0743-d756-4ef1-a590-54a4ebcdebfb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71397773238499, 38.258296712255],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['02b15a0a-9726-48a6-954f-accd965e4a75'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '4d0e692a-c9cb-4153-b22f-556da7bb5d9e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714579696145, 38.25697238774001],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['b8f68e36-e122-4679-8634-1e7ec5f57f73'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '5305ef1a-a950-4169-b387-9300ea146e36',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71394269986, 38.258489849460005],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 4',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['aa6ca436-f3e9-4538-81fb-8bacd7591194'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '540feb23-eaaa-4952-a093-12952198574f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714726776625, 38.256951441025],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['804d8e52-7c9a-4c6d-922e-dfd737a5a113'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '541ce314-3463-4aa0-b570-db284a40831d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71468707349999, 38.257022547700004],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'library',
          accessibility: null,
          name: {
            en: 'Barr Library and Hall of Fame',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['157bf662-5ced-4ccf-8bb4-f0c5e44c7721'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '55598151-ee82-48be-9f4c-6751c5c770c1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713561447145, 38.258188551145],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'East Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['1f9ef7bc-2f5c-4e2d-a91a-edb89cc3cfbc'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '59e9778e-3474-4728-9ba9-d001eb6a2257',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714131393925, 38.257427678119996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom Vestibule",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['f1009b8c-499c-488e-978f-cb89455df024'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '5c5a1a16-149e-4639-ac2b-547333944310',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714535531625, 38.256900754975],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['6a125fc8-da1b-403f-89be-c615f9424b8f'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '67514946-733b-4c31-a246-d049cf7aff17',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71449932075872, 38.25730619539442],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'unspecified',
          accessibility: null,
          name: {
            en: 'sdf',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['50ac65dc-a842-4d2d-83c8-0e63236633aa'],
          address_id: null,
          correlation_id: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71449932075872, 38.25730619539442],
            type: 'Point',
          },
        },
      },
      {
        id: '69101652-2972-4897-8a26-fe2dbe4a126b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71453259673098, 38.2573701563749],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'unspecified',
          accessibility: null,
          name: {
            en: 'test2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['50ac65dc-a842-4d2d-83c8-0e63236633aa'],
          address_id: null,
          correlation_id: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71453259673098, 38.2573701563749],
            type: 'Point',
          },
        },
      },
      {
        id: '6adba9be-31f0-47e2-b27b-e95e48f1f550',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71410549979501, 38.257769061625],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['74972292-13f2-4d00-abb4-6a5abe135186'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '6cf78291-9c0a-4bd6-8259-1590d60b85db',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714581141655, 38.25685125423],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['029e455f-a9c3-4eb5-974f-badff5e174ee'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '70cbadfb-a293-45b2-b929-1dfa9380bc4d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71432509729499, 38.25719661943],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Stair',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['644841c5-fce4-4f29-98d1-2b1e03162065'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '72680f7f-5653-47d3-8f6f-b32096a2c3d3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71479174483, 38.256996298715],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['694bee31-b3f7-4b2c-a04e-a105ba0b0ad5'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '741de9d3-93a3-4968-a506-d819d8851a57',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71421700861372, 38.25723836600283],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'exhibit',
          accessibility: null,
          name: {
            en: "Jonathan's Desk",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a714af0c-82a5-48b6-a62c-b0a6fe2e8de5'],
          address_id: null,
          correlation_id: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71421700861372, 38.25723836600283],
            type: 'Point',
          },
        },
      },
      {
        id: '75bcb9c9-2be8-425b-a8b6-d0ea0eccaf69',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71449829664878, 38.25736151853267],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'unspecified',
          accessibility: null,
          name: {
            en: 'test',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['50ac65dc-a842-4d2d-83c8-0e63236633aa'],
          address_id: null,
          correlation_id: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71449829664878, 38.25736151853267],
            type: 'Point',
          },
        },
      },
      {
        id: '76d26880-8ce0-44dd-af00-99e6b8a52790',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71410550531999, 38.258204565735],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['7a6f018d-ebe5-4c0a-baeb-06701d5db1ea'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '7716be5b-1a1c-4ba5-9b5d-1d8d8c6cdbc9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71431985479, 38.257501769155],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['f0c7cf48-99c1-43c3-b0ff-6aa2dce61522'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '77783fe2-1ce6-4122-8298-adbc19d0d104',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71389896095, 38.25833464438],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['829f7d6b-c25b-4866-8507-0d5f14bc69c6'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '7781a1d2-616f-4ec0-8b6a-fbedc10dcf63',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713993669865, 38.258165018955],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'ramp',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['6e3cb470-702d-44c3-9490-9bdf96806cad'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '7adaa7e9-8a8c-4928-a5a0-e51a5effa42c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714581141655, 38.25685125423],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['83961642-0ffb-48b2-8426-772032b4a0e9'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '7c7fe219-254f-42b9-a3e1-0f6f5f31f163',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713814060045, 38.25816216955],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['e963e028-ce56-411d-af9c-e5b7a77583ac'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '7dc0ef8c-553e-48e8-aaee-9ba5760f1e66',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714582821405, 38.256950449434996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['af0c6b22-1ef1-49ce-b87e-30ba19f41565'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '831c7c0b-fea3-49ce-ab35-d6d5449b41dd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714026595455, 38.2581100354],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'storage',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['1d2e055d-190a-48d5-94c7-a3fbc5bc0d12'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '85d528cf-55cf-49da-89f6-ed6ca63b50e7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714581141655, 38.25685125423],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['c67c8db0-2bec-4b92-a6c5-39141eb5b291'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '8e142545-3aa2-4209-9062-2e7a18866f07',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71409262943999, 38.25790140135],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['ddd944a0-e87a-4e4e-932d-89e635fa7565'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '8f46c7e3-8e1f-425e-90dc-87105fca360d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713797241825, 38.25819792415],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Center Stairwell 6',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['d1ed8f24-7ad9-405e-816b-f69ce3840bfa'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '91d63e3e-24a1-4ba0-bb99-00269de06d58',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714008133585, 38.257435508564996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['8d82d311-3070-4219-a9df-d1fc2f0269f8'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '971638a6-5b2c-41c2-8288-dca0fcc9bc42',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71452791843501, 38.25746962746],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['027b31e3-0ec6-4ee4-a5ff-e873b2750396'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '98058980-4fc7-424b-85eb-1fb4969fbc7a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71389896095, 38.25833464438],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['2ce5ccac-44b6-44f7-a426-e85c0c61a112'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '9945f692-3571-4245-b0d8-bd8973487c34',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714582821405, 38.256950449434996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['3bbc1d6d-90f2-4960-af9f-9095f66b3198'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '99d3ec7b-7d29-453f-8457-113d1a3a58d1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.7142528704027, 38.25725353531775],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'unspecified',
          accessibility: null,
          name: {
            en: "Charlie's Desk",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a714af0c-82a5-48b6-a62c-b0a6fe2e8de5'],
          address_id: null,
          correlation_id: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7142528704027, 38.25725353531775],
            type: 'Point',
          },
        },
      },
      {
        id: '9b3e5ea6-bfc7-4992-bd49-fcc3d884ed81',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71479174483, 38.256996298715],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['5bd61309-4342-497b-8927-caf6bcca8d06'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '9ba11305-f738-4fc5-844e-6e71efba0af6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71462927626499, 38.256975451719995],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a050a15a-cfef-4b39-9467-c06646694000'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: '9ee91901-39d9-43e6-baaf-470a446cf888',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714081790135, 38.258006679225005],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['36c9b327-cc9b-4d18-b551-fe6a90a0a877'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'a278242a-d1ef-4e8d-a0fa-5d6d064e6dfa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71463236003, 38.256874007389996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['6bec132d-6dd4-4a18-be9f-c25d13736d17'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'a770eb30-ed45-48cc-8ebe-30be6e7f66e8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713574120985, 38.258357240695005],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'storage',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['896211df-b69f-4686-bd72-e983757230b7'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'ad9ceb5a-0025-491a-b1f9-21204e4ebd1d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71404703725, 38.257390758785],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['454392d1-4734-46c5-ad26-5e29b945e4b3'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'ada03fe8-e407-4758-9b42-0f53e2fbcc33',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71394269986, 38.258489849460005],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 4',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['56af869a-34e2-4772-82d4-4be19ca01e92'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'b2773b9e-ce74-4dca-b729-793b33598e63',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714465998165, 38.25734750981],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'library',
          accessibility: null,
          name: {
            en: 'Miguel Library',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['268d2566-5cbb-478e-ba36-7a1b7a1320cc'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'b7010878-8de1-48d4-a4b0-cb080429e959',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71410549979501, 38.257769061625],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['4579722b-14b5-44ad-b243-940f03ae16a2'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'ba14b8ec-77e3-44ce-bf3d-0afb0067e6c9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713561447145, 38.258188551145],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'East Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['879ee864-ae73-42ec-9da1-fe3314445ef0'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'ba4552a7-c4f7-4082-b3bc-425c0382635e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714694710635, 38.256947225755],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.female',
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['ecea6bc2-68e9-48d3-9959-26228656e75c'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'bc4f043f-8258-4ce4-b4a0-66ad838dc4bc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714161611935, 38.257873042815],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'storage',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['3ec00ca8-20a3-4931-a753-853f0a0c53eb'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'bd6b34fd-4895-4bdc-8c1d-d4a09e66a076',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71479174483, 38.256996298715],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['81d4553d-a1ec-4b05-811c-15603b41022c'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'bd823655-2329-4f49-9dc1-df3d721ef767',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71379096034917, 38.25835576126055],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'information',
          accessibility: null,
          name: {
            en: 'Test',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['b84aa587-2127-437c-b1a1-c51e8e02f33c'],
          address_id: null,
          correlation_id: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71379096034917, 38.25835576126055],
            type: 'Point',
          },
        },
      },
      {
        id: 'befaafe4-3e7a-421b-9c07-00fb753604be',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714313298635, 38.257525300764996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Do not use Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['61781969-f149-4990-b6db-5f74979f75d2'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'bf3c0e40-f506-4154-b6c4-2cc142a642a9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71446296099501, 38.256916147545],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'test 2',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['8eecce7c-51d0-40a5-a505-8eed7d236912'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'c55e3a4b-9fc8-49ef-9fe3-8ed04f262747',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71445271278, 38.256911536775],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['4b76871d-436b-4fbe-8050-debc94f1449b'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'c72c3ada-bba0-414a-aee3-da4e67951fd2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71463236003, 38.256874007389996],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['87db6de4-c145-46ef-a2b7-aa5c6a1630b9'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'c95a92d3-c374-4af1-b9cd-e1124780b84c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714603294385, 38.256920223475],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.family',
          accessibility: null,
          name: {
            en: 'Family Restroom',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a154a228-9953-4d65-9f3d-4dae30333dc1'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'c9f13089-a534-4379-929e-4f04ecff7308',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71425807069133, 38.25725554952147],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'unspecified',
          accessibility: null,
          name: {
            en: "Sam's Desk",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a714af0c-82a5-48b6-a62c-b0a6fe2e8de5'],
          address_id: null,
          correlation_id: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71425807069133, 38.25725554952147],
            type: 'Point',
          },
        },
      },
      {
        id: 'd0d71024-2d1d-45cd-8edb-271db9161de5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714498243, 38.25692127796],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'unspecified',
          accessibility: null,
          name: {
            en: 'American Printing House Gift Shop',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['4680fed8-3e15-4cbe-80af-21ea90753c0a'],
          address_id: null,
          correlation_id: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714498243, 38.25692127796],
            type: 'Point',
          },
        },
      },
      {
        id: 'd593dd3d-9a5c-4baf-8b9a-b62d462b051f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714581141655, 38.25685125423],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['38fe3bb8-1512-43c6-9224-cb045c4968d5'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'daab78d9-d11c-489f-8e82-86942b9e680b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71432509729499, 38.25719661943],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Stair',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['75feb0e4-824f-435c-b53d-4f777ec54260'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'df59f199-94f1-473f-823e-a2f34bb22ad5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71403695889279, 38.257631392902404],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'unspecified',
          accessibility: null,
          name: {
            en: 'test poi',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['fbf1aabe-023e-4e92-a6b6-81712e66602f'],
          address_id: null,
          correlation_id: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71403695889279, 38.257631392902404],
            type: 'Point',
          },
        },
      },
      {
        id: 'e3ed95a6-a696-4b4a-8e0d-c4371a80fc99',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71461917785, 38.25687256628],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: null,
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['64410b31-67a6-4196-a089-12058f140aba'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'e4b080e8-089b-481a-93ca-e4d2eb5e8bef',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71405640887, 38.257452469855],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom Vestibule",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['af6f9b00-7118-481d-a52b-63ac0681f9b7'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'e5ebb316-60d4-409d-a51a-57e34faa8a59',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714581141655, 38.25685125423],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['df508559-586a-4535-9201-58e46a132fff'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'ebc07024-7350-48d7-92a8-2551dd882f39',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71479174483, 38.256996298715],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['40182329-fac5-456a-8bba-12d73775064a'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'ed52a244-d2d0-4498-a1a2-26ee1259fb62',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.713797241825, 38.25819792415],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'Center Stairwell 6',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a7de4fcb-0b37-4e07-9742-3a2c6d834966'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'f4280f00-01bf-41fa-b423-f9fb3f21485f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71402557999, 38.25742778069],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['a4e8f81e-c257-4df1-9fc0-bbe14989c5f5'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'f685f444-f317-4cb5-8a0a-9670faea200d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71397773238499, 38.258296712255],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'elevator',
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['72cc4217-e156-4ab8-a52c-66ae91ee9f9f'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'fb4bcd76-0074-4af5-8e2a-d09a6f4c8a41',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714617654805, 38.25689837945],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'restroom.male',
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['0ffa72ec-782e-4523-9bfc-4c3c6147960a'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
      {
        id: 'fc50083c-eeff-4a3f-b2b4-3b2e89266851',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71479174483, 38.256996298715],
          type: 'Point',
        },
        feature_type: 'amenity',
        properties: {
          category: 'stairs',
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          hours: null,
          phone: null,
          website: null,
          unit_ids: ['97eb85fd-6673-4edf-bb2b-429b7b7fd638'],
          address_id: null,
          correlation_id: null,
          level_id: null,
          display_point: null,
        },
      },
    ],
    anchor: null,
    building: {
      id: 'eff2f396-abf1-45bd-afdd-af12c6ce5fe5',
      buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
      type: 'Feature',
      feature_type: 'building',
      geometry: null,
      properties: {
        name: {
          frCA: null,
          en: 'American Printing House for the Blind',
        },
        alt_name: null,
        category: 'unspecified',
        restriction: null,
        display_point: null,
        address_id: 'edc2af67-1074-4715-a287-9b94dbdc0335',
      },
    },
    detail: {
      id: 'bc9a343a-c0e4-4111-aebd-caa2ff12bba0',
      buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
      type: 'Feature',
      feature_type: 'detail',
      geometry: {
        coordinates: [
          [-85.71465732982, 38.25706664694],
          [-85.71467013413, 38.25704497484],
          [-85.71467789069, 38.25703184635],
          [-85.71467095547, 38.25702931984],
        ],
        type: 'LineString',
      },
      properties: {
        level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
      },
    },
    fixture: [],
    footprint: {
      id: 'a9af4e3f-aa3a-412f-b299-f5950fd7350b',
      buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
      type: 'Feature',
      geometry: {
        coordinates: [
          [
            [-85.71415978854, 38.2571203895],
            [-85.71425812216, 38.2571553143],
            [-85.71433494606, 38.25702194088],
            [-85.71428344883, 38.25700365076],
            [-85.71436466451, 38.25686265249],
            [-85.71436808729, 38.2568638888],
            [-85.71443148819, 38.2568867892],
            [-85.71443596202, 38.25688835627],
            [-85.7144400739, 38.25688979655],
            [-85.71447081434, 38.25690035348],
            [-85.71448655074, 38.25687281437],
            [-85.71446984099, 38.2568669919],
            [-85.71441732657, 38.25684834047],
            [-85.71439743158, 38.25684127441],
            [-85.71438271471, 38.25683617047],
            [-85.71437802734, 38.25684459276],
            [-85.71430698503, 38.25681964226],
            [-85.71438381297, 38.25668626121],
            [-85.71453067969, 38.25673842365],
            [-85.7145431027, 38.25674283591],
            [-85.71457936758, 38.25675571605],
            [-85.71459574086, 38.25676153131],
            [-85.7146162768, 38.25676882503],
            [-85.71462211325, 38.25677077411],
            [-85.71464160421, 38.25677782052],
            [-85.71466742826, 38.2567869924],
            [-85.71468388601, 38.25679283767],
            [-85.71489722096, 38.25686860741],
            [-85.71482077905, 38.25700096518],
            [-85.71481777986, 38.25700624383],
            [-85.71481473211, 38.25701160793],
            [-85.71480003033, 38.25703715194],
            [-85.71479397303, 38.25704799075],
            [-85.71479229297, 38.25705077247],
            [-85.71478931531, 38.25705594197],
            [-85.71478663173, 38.25706060092],
            [-85.71478307092, 38.25706678282],
            [-85.71477983952, 38.25707239282],
            [-85.71477728263, 38.25707683184],
            [-85.71477216611, 38.25708571459],
            [-85.7147692543, 38.25709076977],
            [-85.71476673397, 38.25709514529],
            [-85.71476336116, 38.2571010008],
            [-85.7147604122, 38.25710612046],
            [-85.71475773111, 38.25711077509],
            [-85.71475071169, 38.25712296144],
            [-85.71476493042, 38.25712801145],
            [-85.71475834392, 38.25713942607],
            [-85.71474581763, 38.25716122809],
            [-85.71473327868, 38.25718305214],
            [-85.71472692527, 38.25719399186],
            [-85.71471260366, 38.25718890531],
            [-85.71454489709, 38.25748202186],
            [-85.71454257882, 38.25748602749],
            [-85.71454013777, 38.25749024527],
            [-85.71442513537, 38.25768797457],
            [-85.71411708345, 38.25822195901],
            [-85.71411534294, 38.2582250959],
            [-85.71411321309, 38.2582289345],
            [-85.71391097842, 38.25858058597],
            [-85.71390657418, 38.2585789672],
            [-85.71381672652, 38.25854652913],
            [-85.71350232501, 38.2584353333],
            [-85.71342506114, 38.25840801328],
            [-85.71342353165, 38.25840746446],
            [-85.71354643958, 38.25819498046],
            [-85.71369889148, 38.25792812343],
            [-85.71396678832, 38.2574590501],
            [-85.71396926393, 38.25745480235],
            [-85.71397370073, 38.2574471895],
            [-85.71397451282, 38.25744579607],
            [-85.7139748437, 38.25744522834],
            [-85.71398013623, 38.25743604865],
            [-85.71399547882, 38.25740943754],
            [-85.71399913925, 38.25740309483],
            [-85.71401898346, 38.25736793699],
            [-85.71402049277, 38.2573651696],
            [-85.71404187223, 38.25732759581],
            [-85.71404298098, 38.25732593275],
            [-85.71406571881, 38.25728555291],
            [-85.71413725713, 38.25715949433],
            [-85.71414011254, 38.25715461163],
            [-85.7141413218, 38.25715254381],
            [-85.71414213304, 38.25715115661],
            [-85.71414505066, 38.25714616752],
            [-85.71415978854, 38.2571203895],
          ],
        ],
        type: 'Polygon',
      },
      feature_type: 'footprint',
      properties: {
        category: 'ground',
        name: null,
        building_ids: ['eff2f396-abf1-45bd-afdd-af12c6ce5fe5'],
      },
    },
    geofence: [
      {
        id: '1ba3dc9e-2a7c-4cd0-b30b-cfe2eeaf690e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461281481, 38.25703103899],
              [-85.71458983842, 38.25700145026],
              [-85.71455778933, 38.25699109015],
              [-85.71453600441, 38.25702970183],
              [-85.71454759095, 38.25703373268],
              [-85.71454147662, 38.25704440923],
              [-85.71459527053, 38.2570627566],
              [-85.71461281481, 38.25703103899],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: {
            en: 'Hall of Fame',
            frCA: null,
          },
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71457440961, 38.257026923375],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['44536ed5-4347-4682-9b89-07ad91856b6c'],
          parents: null,
          accessibility: null,
          fixture_ids: [
            'df2b6813-3ff9-4648-b6e7-30768d6b376f',
            '878bf2c8-843e-433f-9e9f-2c594d33b350',
            '2e19c8c8-20ee-4773-b748-d527031cd6f1',
            'f097393f-1a96-4d56-9ac6-d3d4d048ce68',
          ],
          opening_ids: [],
        },
      },
      {
        id: '363aaa02-1ca3-4bde-98e0-4cc385d4e453',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71362952477, 38.25821099127],
              [-85.71362678059, 38.25821582908],
              [-85.71362176575, 38.25821409533],
              [-85.71360946039, 38.25823442045],
              [-85.71363115128, 38.25824767079],
              [-85.71364243894, 38.25825165812],
              [-85.71363093648, 38.25827323246],
              [-85.71368042577, 38.25829193945],
              [-85.7137096356, 38.25823768936],
              [-85.71362952477, 38.25821099127],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71366916151, 38.25825146536],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['1b9019de-8d83-4d45-9863-39b93f3d60b3'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: '58931ab0-953e-45e5-b3ae-a80f07aff282',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71399191619, 38.25832537262],
              [-85.71401034834, 38.25829246239],
              [-85.71406014264, 38.25830979807],
              [-85.71397298462, 38.2584626766],
              [-85.71388153217, 38.25843006552],
              [-85.7138852698, 38.25840407581],
              [-85.71389625896, 38.25838586561],
              [-85.7139265865, 38.2583961965],
              [-85.71396559644, 38.25833455],
              [-85.71399191619, 38.25832537262],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.713975035535, 38.258377569494996],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['1b9019de-8d83-4d45-9863-39b93f3d60b3'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: '67bf1ce4-d889-4dd5-bed0-fe0e94f76029',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71383056989, 38.25776597708],
              [-85.7139422084, 38.25780575847],
              [-85.71393400117, 38.25781995976],
              [-85.71396801826, 38.25783208144],
              [-85.7139876369, 38.25779813458],
              [-85.7138419813, 38.25774623149],
              [-85.71383056989, 38.25776597708],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.713944711925, 38.257789156465],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['20be8f6d-915d-4837-af52-afb5f5b8f7cd'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: '79724081-a185-429f-a76e-a9650d2b693c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71407261531, 38.25827636439],
              [-85.71409805628, 38.25823323769],
              [-85.71405988686, 38.25822007727],
              [-85.71407021913, 38.25819923108],
              [-85.71400397143, 38.25817638957],
              [-85.71396819818, 38.25824036248],
              [-85.71407261531, 38.25827636439],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71403312723, 38.258226376980005],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['20be8f6d-915d-4837-af52-afb5f5b8f7cd'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: '7df81e41-cd13-4663-8453-fd52ff943969',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71406706643, 38.25750490606],
              [-85.7141709846, 38.25754175497],
              [-85.71411198424, 38.25764434879],
              [-85.71399511098, 38.25760290614],
              [-85.71397614738, 38.25763588132],
              [-85.71392428742, 38.25761749204],
              [-85.71396629702, 38.25754444289],
              [-85.71403111207, 38.257567426],
              [-85.71406706643, 38.25750490606],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.714101263235, 38.257574627425],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['20be8f6d-915d-4837-af52-afb5f5b8f7cd'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: '86a14441-7b8c-45ad-a531-ef2ccf8b70a3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71411955452, 38.25798798058],
              [-85.71401972377, 38.25816372904],
              [-85.71409080674, 38.25818862513],
              [-85.71410649091, 38.25816101375],
              [-85.7141380763, 38.25817207621],
              [-85.71422222289, 38.25802393922],
              [-85.71411955452, 38.25798798058],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.714121900615, 38.258088302855],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['20be8f6d-915d-4837-af52-afb5f5b8f7cd'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: '8994d212-d450-4081-ae5f-3adb4624921a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71422294127, 38.25768717188],
              [-85.71414088887, 38.25765698872],
              [-85.71420748311, 38.25754536356],
              [-85.71428953551, 38.25757554675],
              [-85.71422294127, 38.25768717188],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71421521219, 38.257616267719996],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['20be8f6d-915d-4837-af52-afb5f5b8f7cd'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: 'b421598e-1d16-468b-b43f-c3758df23991',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7142621689, 38.25740622392],
              [-85.71420241898, 38.25750851231],
              [-85.71424985168, 38.2575255963],
              [-85.71427642619, 38.25748010231],
              [-85.71426904919, 38.25747744531],
              [-85.7143022246, 38.25742065093],
              [-85.7142621689, 38.25740622392],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71425232179, 38.25746591011],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['20be8f6d-915d-4837-af52-afb5f5b8f7cd'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: 'b95f3d9d-4a8d-4de5-81d0-3ce2b5f0f090',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71375032862, 38.25815144479],
              [-85.71379106961, 38.25808523317],
              [-85.71386526494, 38.25806006326],
              [-85.7139240022, 38.25796094742],
              [-85.71383667319, 38.25793080346],
              [-85.71390967625, 38.25780464467],
              [-85.71380276958, 38.25776735761],
              [-85.71360923799, 38.25810312855],
              [-85.71375032862, 38.25815144479],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.713766620095, 38.2579594012],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['20be8f6d-915d-4837-af52-afb5f5b8f7cd'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: 'd936cd58-a53e-4694-9974-f280e11243b8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71392042961, 38.25826287235],
              [-85.71361357731, 38.25815248837],
              [-85.71359903007, 38.2581818398],
              [-85.71375787752, 38.25823981039],
              [-85.71373706554, 38.2582789212],
              [-85.71372974061, 38.25827589413],
              [-85.71371117982, 38.25831039192],
              [-85.71375722616, 38.25833680005],
              [-85.71383538892, 38.25836548692],
              [-85.71387401656, 38.25829624282],
              [-85.71388531765, 38.25827598494],
              [-85.71392481064, 38.25827664641],
              [-85.71392903489, 38.25826735471],
              [-85.71392042961, 38.25826287235],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71382253561501, 38.258258987645],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['1b9019de-8d83-4d45-9863-39b93f3d60b3'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: 'db6fc6fd-ed41-4107-9a85-4f287f2514b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71454606729, 38.25740605857],
              [-85.7146039914, 38.25730941352],
              [-85.71453558528, 38.25728413324],
              [-85.7145491019, 38.25726153115],
              [-85.71455383987, 38.25726327824],
              [-85.7145540726, 38.25726288907],
              [-85.71464685801, 38.25729710294],
              [-85.71464431845, 38.2573013495],
              [-85.71464526876, 38.25730169992],
              [-85.71462978342, 38.25732759401],
              [-85.71462874318, 38.25732721043],
              [-85.71462680187, 38.25733045661],
              [-85.7146193951, 38.25734284199],
              [-85.71462112212, 38.25734347881],
              [-85.71461839737, 38.25734803507],
              [-85.71460762779, 38.2573660436],
              [-85.71460538588, 38.25736979246],
              [-85.71460355214, 38.25736911628],
              [-85.71459756691, 38.25737912458],
              [-85.7145989464, 38.25737963325],
              [-85.71459712001, 38.25738268728],
              [-85.71458677782, 38.25739998115],
              [-85.71458318676, 38.257405986],
              [-85.71458171013, 38.25740544151],
              [-85.71457496801, 38.25741671546],
              [-85.71454606729, 38.25740605857],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: {
            en: 'Cubicles 2R21 through 2R29',
            frCA: null,
          },
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71459122164501, 38.257339123305],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['1b9019de-8d83-4d45-9863-39b93f3d60b3'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: 'de0e283e-ccbd-4da4-821f-52c3b05e3965',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71406698108, 38.25801010057],
              [-85.714014313, 38.25810107573],
              [-85.71389605342, 38.25805886193],
              [-85.7139487215, 38.25796788672],
              [-85.71406698108, 38.25801010057],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71398151725, 38.258034481225],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['20be8f6d-915d-4837-af52-afb5f5b8f7cd'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: 'deca7db2-7629-49da-bdf5-a9e637462e34',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71373504654, 38.25844671811],
              [-85.71381068925, 38.25847376912],
              [-85.71385086621, 38.25840449844],
              [-85.7137752235, 38.2583774474],
              [-85.71373504654, 38.25844671811],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71379295637499, 38.25842560826],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['1b9019de-8d83-4d45-9863-39b93f3d60b3'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
      {
        id: 'f286b624-ce8f-4ace-b68b-12e4ec4353bb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71402278229, 38.25747114794],
              [-85.71397892044, 38.25745537916],
              [-85.71371485668, 38.257913168],
              [-85.71401722185, 38.25802234699],
              [-85.71405729036, 38.25795477906],
              [-85.71394481042, 38.25791365155],
              [-85.71413837206, 38.25758724557],
              [-85.7139868008, 38.2575318243],
              [-85.71402278229, 38.25747114794],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'geofence',
        properties: {
          category: 'geofence',
          restriction: null,
          name: null,
          alt_name: null,
          correlation_id: null,
          display_point: {
            coordinates: [-85.71392661437, 38.257738863075005],
            type: 'Point',
          },
          building_ids: null,
          level_ids: ['c7549865-d626-4ff6-965f-fd313605a7e6'],
          parents: null,
          accessibility: null,
          fixture_ids: [],
          opening_ids: [],
        },
      },
    ],
    kiosk: [],
    level: [
      {
        id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-85.71390976183, 38.25857487426],
                [-85.71394152392, 38.258518846],
                [-85.71394370029, 38.25851776662],
                [-85.71395103313, 38.25850481931],
                [-85.71396219166, 38.25848461995],
                [-85.71396970365, 38.25847170502],
                [-85.71397373916, 38.25846375557],
                [-85.71406107456, 38.25830969576],
                [-85.71406364423, 38.25830570388],
                [-85.7141054838, 38.25823273498],
                [-85.71410830427, 38.25823139394],
                [-85.71411023119, 38.25822788667],
                [-85.71411321309, 38.2582289345],
                [-85.71411534294, 38.2582250959],
                [-85.71411708345, 38.25822195901],
                [-85.71411406652, 38.25822090584],
                [-85.71411638707, 38.25821668212],
                [-85.71412677597, 38.25819795149],
                [-85.71413237271, 38.25818786086],
                [-85.71413225565, 38.25818528922],
                [-85.71419543245, 38.25807316431],
                [-85.71419686543, 38.25807145794],
                [-85.71426221359, 38.25795469384],
                [-85.71426296678, 38.25795395168],
                [-85.71426397774, 38.25795216403],
                [-85.71426675059, 38.25795313091],
                [-85.71430841408, 38.25787945875],
                [-85.71430911074, 38.25787893852],
                [-85.71442439261, 38.25767906793],
                [-85.71442175169, 38.25767654864],
                [-85.71442402757, 38.25767260748],
                [-85.71442729584, 38.25767377118],
                [-85.71444226261, 38.257647853],
                [-85.71443937762, 38.25764682578],
                [-85.71444184225, 38.25764255774],
                [-85.7144420572, 38.25764168609],
                [-85.71444506791, 38.25763651394],
                [-85.71444816503, 38.25763762555],
                [-85.71446446919, 38.25760961633],
                [-85.71446142384, 38.25760852329],
                [-85.71446354957, 38.25760487147],
                [-85.714467382, 38.2576040078],
                [-85.7144911617, 38.25756317104],
                [-85.71449268896, 38.2575637194],
                [-85.71451267356, 38.25752939995],
                [-85.71451037066, 38.2575285731],
                [-85.71452263868, 38.25750750529],
                [-85.71452301721, 38.2575063475],
                [-85.71453145843, 38.25749194834],
                [-85.71453423359, 38.25749097686],
                [-85.71453555141, 38.25748864326],
                [-85.71454013777, 38.25749024527],
                [-85.71454257882, 38.25748602749],
                [-85.71454489709, 38.25748202186],
                [-85.71454019009, 38.25748042904],
                [-85.71454792703, 38.25746677154],
                [-85.71455232709, 38.2574590044],
                [-85.71455515564, 38.25744963839],
                [-85.71457440399, 38.2574176236],
                [-85.71457496801, 38.25741671546],
                [-85.71458171013, 38.25740544151],
                [-85.71458318676, 38.257405986],
                [-85.71458677782, 38.25739998115],
                [-85.71459712001, 38.25738268728],
                [-85.7145989464, 38.25737963325],
                [-85.71459756691, 38.25737912458],
                [-85.71460355214, 38.25736911628],
                [-85.71460538588, 38.25736979246],
                [-85.71460762779, 38.2573660436],
                [-85.71461839737, 38.25734803507],
                [-85.71462112212, 38.25734347881],
                [-85.7146193951, 38.25734284199],
                [-85.71462680187, 38.25733045661],
                [-85.71462874318, 38.25732721043],
                [-85.71462978342, 38.25732759401],
                [-85.71464526876, 38.25730169992],
                [-85.71464431845, 38.2573013495],
                [-85.71464685801, 38.25729710294],
                [-85.71462133828, 38.25728769275],
                [-85.71459924425, 38.25727954575],
                [-85.71457789333, 38.25727167277],
                [-85.7145540726, 38.25726288907],
                [-85.71455383987, 38.25726327824],
                [-85.7145491019, 38.25726153115],
                [-85.7145462287, 38.25725988945],
                [-85.71449921746, 38.25724248082],
                [-85.71438576763, 38.25743607906],
                [-85.71438454666, 38.25743810583],
                [-85.71437801073, 38.25744937246],
                [-85.7143754803, 38.25744846894],
                [-85.71436698796, 38.25746338812],
                [-85.7143692334, 38.25746419061],
                [-85.71436477489, 38.25747187618],
                [-85.71435577213, 38.25748739515],
                [-85.71435373831, 38.25748666489],
                [-85.71434771487, 38.25749724675],
                [-85.71434946843, 38.2574978774],
                [-85.71434191531, 38.25751089749],
                [-85.71433921429, 38.25751555351],
                [-85.71433761798, 38.25751498478],
                [-85.71433146072, 38.25752580174],
                [-85.71433293655, 38.25752632803],
                [-85.71432985233, 38.2575316446],
                [-85.71432858165, 38.25753366646],
                [-85.71432551738, 38.25753889206],
                [-85.71432413662, 38.25753839283],
                [-85.71431843691, 38.25754811275],
                [-85.71431982295, 38.2575486139],
                [-85.71431498326, 38.25755686718],
                [-85.71431374858, 38.257558966],
                [-85.71430902585, 38.25756704694],
                [-85.71430789683, 38.25756664009],
                [-85.71430175088, 38.25757715627],
                [-85.71430285329, 38.25757755353],
                [-85.71429603546, 38.25758921934],
                [-85.71429562936, 38.25758989656],
                [-85.71427521798, 38.25762472774],
                [-85.71427384794, 38.25762552832],
                [-85.71415997109, 38.25782296311],
                [-85.71416507534, 38.25782477837],
                [-85.71416367032, 38.25782721433],
                [-85.71416232076, 38.25782856554],
                [-85.71411916901, 38.25790526853],
                [-85.71411756503, 38.25790643636],
                [-85.71410129636, 38.25793583195],
                [-85.71408497328, 38.25796532582],
                [-85.71408186976, 38.25796997577],
                [-85.71406528041, 38.25799948183],
                [-85.71404893625, 38.25802855179],
                [-85.71404818516, 38.25802974307],
                [-85.7140337692, 38.25805519742],
                [-85.71403626007, 38.25805606724],
                [-85.71403242059, 38.25806284664],
                [-85.71402870618, 38.25806401538],
                [-85.71402340108, 38.25806122902],
                [-85.71369256294, 38.2579440775],
                [-85.71358292722, 38.25813498095],
                [-85.71358418316, 38.25813549496],
                [-85.71358359195, 38.2581365417],
                [-85.71358141352, 38.25813578306],
                [-85.71354424436, 38.25820144905],
                [-85.7135306879, 38.25822518046],
                [-85.71352611333, 38.25823313169],
                [-85.71352545578, 38.25823390223],
                [-85.71342776209, 38.25840603332],
                [-85.71364078505, 38.25848057916],
                [-85.7136449279, 38.25848230572],
                [-85.71390976183, 38.25857487426],
              ],
            ],
          ],
        },
        feature_type: 'level',
        properties: {
          category: 'unspecified',
          restriction: null,
          outdoor: false,
          ordinal: 2,
          name: {
            en: '2 Plant',
            frCA: null,
          },
          short_name: {
            en: '2 Plant',
            frCA: null,
          },
          display_point: null,
          address_id: null,
          building_ids: ['eff2f396-abf1-45bd-afdd-af12c6ce5fe5'],
          elevation: -3.761,
          minZ: -2.42,
        },
      },
      {
        id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-85.71475217682, 38.25711860631],
                [-85.71476184619, 38.25710160461],
                [-85.71475765898, 38.25710013624],
                [-85.71478511557, 38.25705185914],
                [-85.71478972771, 38.25705347653],
                [-85.71480997918, 38.2570178682],
                [-85.71480800182, 38.25701717478],
                [-85.7148083749, 38.25701651878],
                [-85.71480458581, 38.25701519002],
                [-85.71480495042, 38.25701452873],
                [-85.71480863568, 38.25701581471],
                [-85.71481167288, 38.25701042357],
                [-85.71481473211, 38.25701160793],
                [-85.71481777986, 38.25700624383],
                [-85.71482077905, 38.25700096518],
                [-85.71481763779, 38.25699983568],
                [-85.71482147042, 38.25699303263],
                [-85.71478528314, 38.25698040503],
                [-85.71478118323, 38.25697897436],
                [-85.71477707635, 38.25697754126],
                [-85.71477490258, 38.25697678272],
                [-85.71477324981, 38.25697967175],
                [-85.71477173059, 38.25698238639],
                [-85.7147702568, 38.2569849908],
                [-85.71476201924, 38.25699954785],
                [-85.71475970775, 38.25699987769],
                [-85.71460963342, 38.25694724958],
                [-85.71461268186, 38.25694027736],
                [-85.71461460807, 38.25693689292],
                [-85.71461668193, 38.25693762276],
                [-85.71462780128, 38.25691769098],
                [-85.714630965, 38.25691201991],
                [-85.71464340123, 38.25688972758],
                [-85.71464186438, 38.25688919338],
                [-85.71464243218, 38.25688802175],
                [-85.71465180118, 38.25689118409],
                [-85.71466148827, 38.2568734877],
                [-85.71461229054, 38.25685683069],
                [-85.71461109673, 38.25685905853],
                [-85.71460947834, 38.25686195039],
                [-85.71460779129, 38.25686496492],
                [-85.71460323179, 38.25687331996],
                [-85.71460478478, 38.25687384603],
                [-85.71460412665, 38.256875044],
                [-85.71463496873, 38.25688549163],
                [-85.71463415738, 38.25688708872],
                [-85.71458016214, 38.25686825616],
                [-85.71458330267, 38.25686270407],
                [-85.71458038597, 38.25686168677],
                [-85.71458200234, 38.25685882798],
                [-85.71458862645, 38.25686108926],
                [-85.71459540594, 38.25684884371],
                [-85.71458854975, 38.2568465178],
                [-85.71458442866, 38.25684509571],
                [-85.71458091825, 38.25684388434],
                [-85.71457365686, 38.2568414192],
                [-85.71456687737, 38.25685366475],
                [-85.7145733223, 38.25685586487],
                [-85.71457162599, 38.25685886405],
                [-85.71456750666, 38.25685742731],
                [-85.71456484347, 38.25686213551],
                [-85.71455922593, 38.25686233021],
                [-85.71450915759, 38.25684518731],
                [-85.71449966157, 38.25686228858],
                [-85.71450068663, 38.25686263955],
                [-85.7144917753, 38.25687868787],
                [-85.71449042185, 38.25687822447],
                [-85.71448044965, 38.25689618328],
                [-85.71449964849, 38.25690275677],
                [-85.71449871731, 38.25690443373],
                [-85.7145299152, 38.25691511556],
                [-85.71453212868, 38.25691112932],
                [-85.71453573691, 38.25691227477],
                [-85.71453184033, 38.25691930553],
                [-85.71450652116, 38.25691065311],
                [-85.71450412542, 38.25691497585],
                [-85.71449177494, 38.25691075527],
                [-85.71448942491, 38.25691499554],
                [-85.71448619133, 38.25691393066],
                [-85.71448774894, 38.25691113718],
                [-85.71447008143, 38.2569050629],
                [-85.7144302244, 38.2568913596],
                [-85.71441854156, 38.25691216647],
                [-85.71445854062, 38.25692592327],
                [-85.71445354235, 38.25693488737],
                [-85.71445644229, 38.2569358844],
                [-85.71445535754, 38.25693787176],
                [-85.71445314574, 38.25693712801],
                [-85.7144347526, 38.25697085525],
                [-85.71443289324, 38.25697030028],
                [-85.71438997034, 38.25695606482],
                [-85.71437823726, 38.25697815569],
                [-85.71437927125, 38.25697852367],
                [-85.7143781282, 38.25698052989],
                [-85.71438409811, 38.25698262719],
                [-85.71438358109, 38.25698353463],
                [-85.71439614187, 38.25698794737],
                [-85.71439671682, 38.25698693824],
                [-85.71441587903, 38.25699367013],
                [-85.71441732258, 38.25699113649],
                [-85.71442100441, 38.25699238033],
                [-85.71442309582, 38.2569930836],
                [-85.71442163075, 38.25699577008],
                [-85.714434213, 38.25700000105],
                [-85.71443470306, 38.25699910245],
                [-85.71443845694, 38.25700036523],
                [-85.71445240233, 38.25697506433],
                [-85.71445673814, 38.2569765076],
                [-85.71443916909, 38.25700821523],
                [-85.71451567348, 38.25703525748],
                [-85.71453675951, 38.25699750557],
                [-85.7145422549, 38.25699937315],
                [-85.71453009772, 38.25702074921],
                [-85.7145824594, 38.25703933325],
                [-85.71458031633, 38.25704304448],
                [-85.71458151348, 38.25704346429],
                [-85.71457426173, 38.25705621509],
                [-85.71475217682, 38.25711860631],
              ],
            ],
          ],
        },
        feature_type: 'level',
        properties: {
          category: 'unspecified',
          restriction: null,
          outdoor: false,
          ordinal: 3,
          name: {
            en: '2R',
            frCA: null,
          },
          short_name: {
            en: '2R',
            frCA: null,
          },
          display_point: null,
          address_id: null,
          building_ids: ['eff2f396-abf1-45bd-afdd-af12c6ce5fe5'],
          elevation: -4.618,
          minZ: -3.277,
        },
      },
      {
        id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-85.71390657418, 38.2585789672],
                [-85.71393870558, 38.25852297668],
                [-85.71394370029, 38.25851776662],
                [-85.71395103313, 38.25850481931],
                [-85.71396219166, 38.25848461995],
                [-85.71396970365, 38.25847170502],
                [-85.7139699762, 38.258469748],
                [-85.71406333411, 38.2583069849],
                [-85.7140657326, 38.25830271985],
                [-85.71410589787, 38.25823264993],
                [-85.71410830427, 38.25823139394],
                [-85.71411023119, 38.25822788667],
                [-85.71411321309, 38.2582289345],
                [-85.71411534294, 38.2582250959],
                [-85.71411708345, 38.25822195901],
                [-85.71411406652, 38.25822090584],
                [-85.71411638707, 38.25821668212],
                [-85.71412677597, 38.25819795149],
                [-85.71413237271, 38.25818786086],
                [-85.71413364305, 38.2581857863],
                [-85.71422933481, 38.25801884783],
                [-85.71408006102, 38.25796608897],
                [-85.7140713139, 38.25798134872],
                [-85.71406515357, 38.25797917143],
                [-85.71409733538, 38.25792578929],
                [-85.71406534466, 38.25791402154],
                [-85.7140860303, 38.25787907869],
                [-85.7141173772, 38.25789061298],
                [-85.71412321496, 38.25787928549],
                [-85.71422442649, 38.25791598824],
                [-85.71429171656, 38.25780157455],
                [-85.71429413376, 38.25779732179],
                [-85.71429612139, 38.25779818149],
                [-85.71434647467, 38.25781661827],
                [-85.71437903011, 38.25776179523],
                [-85.71437931591, 38.25776008623],
                [-85.71439318672, 38.25773667227],
                [-85.71439359801, 38.25773527141],
                [-85.71441645762, 38.25769623752],
                [-85.71441816938, 38.25769259161],
                [-85.71444006415, 38.25765521403],
                [-85.71444128626, 38.25765257379],
                [-85.71448522517, 38.25757660454],
                [-85.71448728104, 38.25757299022],
                [-85.71452765571, 38.257502336],
                [-85.71453423359, 38.25749097686],
                [-85.71453555141, 38.25748864326],
                [-85.71454013777, 38.25749024527],
                [-85.71454257882, 38.25748602749],
                [-85.71454489709, 38.25748202186],
                [-85.71454019009, 38.25748042904],
                [-85.71454792703, 38.25746677154],
                [-85.71455232709, 38.2574590044],
                [-85.71455599643, 38.25745402749],
                [-85.71464619025, 38.25729619122],
                [-85.71464877114, 38.25729270935],
                [-85.71468816364, 38.25722339694],
                [-85.71469090621, 38.2572191907],
                [-85.71471532407, 38.25717669143],
                [-85.71473327868, 38.25718305214],
                [-85.71474581763, 38.25716122809],
                [-85.71475834392, 38.25713942607],
                [-85.71474034497, 38.25713304965],
                [-85.71474741055, 38.257120752],
                [-85.71476192656, 38.25709700857],
                [-85.71477013311, 38.25708248809],
                [-85.71477109275, 38.25708134393],
                [-85.71479029642, 38.25704820001],
                [-85.71479397303, 38.25704799075],
                [-85.71480003033, 38.25703715194],
                [-85.71480616888, 38.25702622086],
                [-85.71476228016, 38.25701053631],
                [-85.71476705328, 38.25700211726],
                [-85.71476784545, 38.25700158091],
                [-85.71479695578, 38.257011739],
                [-85.71480101288, 38.25701315473],
                [-85.71480495042, 38.25701452873],
                [-85.71480863568, 38.25701581471],
                [-85.71481167288, 38.25701042357],
                [-85.71481473211, 38.25701160793],
                [-85.71481777986, 38.25700624383],
                [-85.71482077905, 38.25700096518],
                [-85.71481763779, 38.25699983568],
                [-85.71482147042, 38.25699303263],
                [-85.71482612433, 38.25698956228],
                [-85.71483880249, 38.25696621204],
                [-85.71484146228, 38.25696239789],
                [-85.71485633884, 38.2569357647],
                [-85.71485721435, 38.25693438988],
                [-85.71487830016, 38.2568974038],
                [-85.71488061333, 38.25689604553],
                [-85.71488472596, 38.25688838603],
                [-85.714880877, 38.25688711173],
                [-85.71489097689, 38.25686830131],
                [-85.71487247497, 38.25686217579],
                [-85.71487343175, 38.25686039384],
                [-85.71484446548, 38.25685080384],
                [-85.71465721697, 38.25678353576],
                [-85.71461513445, 38.2567689877],
                [-85.71461210657, 38.2567675277],
                [-85.71457009231, 38.25675313645],
                [-85.71456704948, 38.2567518658],
                [-85.71444183948, 38.25670860434],
                [-85.71442705556, 38.25673498812],
                [-85.71442388251, 38.25673385709],
                [-85.71439666805, 38.25672395525],
                [-85.71439571357, 38.25672557282],
                [-85.71439484435, 38.25672525609],
                [-85.71439601709, 38.25672323029],
                [-85.71438080512, 38.25671780033],
                [-85.71437103304, 38.25673468083],
                [-85.71437472217, 38.25673599768],
                [-85.71436321881, 38.25675586885],
                [-85.71435737363, 38.25675378239],
                [-85.71435127803, 38.25676431206],
                [-85.71435805577, 38.25676673139],
                [-85.71434707514, 38.25678569956],
                [-85.71434222718, 38.25678396906],
                [-85.71432193072, 38.25681902958],
                [-85.71437920745, 38.25683947465],
                [-85.71438318729, 38.25683256253],
                [-85.71439289856, 38.25683588557],
                [-85.71444333294, 38.25685375476],
                [-85.71444641148, 38.25685483296],
                [-85.71449078945, 38.25687049913],
                [-85.71447036723, 38.25690587966],
                [-85.71442831245, 38.25689157976],
                [-85.71441711849, 38.25691109382],
                [-85.71441575245, 38.25691416936],
                [-85.71437505798, 38.25698704326],
                [-85.7142742991, 38.25716415401],
                [-85.71426108129, 38.25715936378],
                [-85.71425851246, 38.25715843282],
                [-85.71416287999, 38.25712377492],
                [-85.71414971548, 38.25714617307],
                [-85.71414505066, 38.25714616752],
                [-85.71414213304, 38.25715115661],
                [-85.7141413218, 38.25715254381],
                [-85.71414011254, 38.25715461163],
                [-85.71413725713, 38.25715949433],
                [-85.71414089016, 38.25716230951],
                [-85.71410634437, 38.25722223722],
                [-85.7141052604, 38.25722431614],
                [-85.71409247616, 38.25724641314],
                [-85.71409148673, 38.25724820339],
                [-85.71407328973, 38.25727958483],
                [-85.71404343196, 38.25733119036],
                [-85.71402643755, 38.25736122301],
                [-85.7140252431, 38.25736297062],
                [-85.71400609907, 38.25739638725],
                [-85.71400526122, 38.25739777626],
                [-85.71398346908, 38.25743561657],
                [-85.71398013623, 38.25743604865],
                [-85.7139748437, 38.25744522834],
                [-85.71397451282, 38.25744579607],
                [-85.71397370073, 38.2574471895],
                [-85.71396926393, 38.25745480235],
                [-85.71396678832, 38.2574590501],
                [-85.71393183263, 38.25752662632],
                [-85.7138772956, 38.25762037568],
                [-85.71387621542, 38.2576228486],
                [-85.71384252626, 38.25768099642],
                [-85.71384119014, 38.25768337574],
                [-85.71379844725, 38.25775653128],
                [-85.7137976479, 38.25775939423],
                [-85.71358243344, 38.25813484608],
                [-85.71358418316, 38.25813549496],
                [-85.71358359195, 38.2581365417],
                [-85.71358141352, 38.25813578306],
                [-85.71354424436, 38.25820144905],
                [-85.7135306879, 38.25822518046],
                [-85.71352611333, 38.25823313169],
                [-85.71352418766, 38.25823499216],
                [-85.71347797478, 38.25831509856],
                [-85.71347670302, 38.25831707371],
                [-85.71346304308, 38.25834094008],
                [-85.71346449405, 38.25834145213],
                [-85.71346226653, 38.25834534401],
                [-85.71345977541, 38.25834749132],
                [-85.71342506114, 38.25840801328],
                [-85.71350232501, 38.2584353333],
                [-85.71381672652, 38.25854652913],
                [-85.71390657418, 38.2585789672],
              ],
            ],
          ],
        },
        feature_type: 'level',
        properties: {
          category: 'unspecified',
          restriction: null,
          outdoor: false,
          ordinal: -1,
          name: {
            en: 'Ground Floor',
            frCA: null,
          },
          short_name: {
            en: 'Ground Floor',
            frCA: null,
          },
          display_point: null,
          address_id: null,
          building_ids: ['eff2f396-abf1-45bd-afdd-af12c6ce5fe5'],
          elevation: 3.066,
          minZ: 4.407,
        },
      },
      {
        id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-85.7147548415, 38.25711561899],
                [-85.71479477838, 38.25704406572],
                [-85.71480863568, 38.25701581471],
                [-85.71481167288, 38.25701042357],
                [-85.71481473211, 38.25701160793],
                [-85.71481777986, 38.25700624383],
                [-85.71482077905, 38.25700096518],
                [-85.71481763779, 38.25699983568],
                [-85.71482147042, 38.25699303263],
                [-85.7148220598, 38.25699016515],
                [-85.714831998, 38.25697294126],
                [-85.71483223091, 38.25697253761],
                [-85.71483508251, 38.25697226748],
                [-85.71489262285, 38.25687021537],
                [-85.71473283071, 38.25681313288],
                [-85.71473090538, 38.25681304786],
                [-85.71470371708, 38.2568034376],
                [-85.71470136922, 38.25680322475],
                [-85.71465237858, 38.25678611096],
                [-85.71465042685, 38.25678564978],
                [-85.71460742402, 38.25677040985],
                [-85.71460116333, 38.25676820182],
                [-85.71456289123, 38.25675455972],
                [-85.71455491492, 38.25675163961],
                [-85.71450986532, 38.25673524582],
                [-85.71450926925, 38.25673502505],
                [-85.71445717782, 38.25671580229],
                [-85.71444033337, 38.25674393597],
                [-85.71443895366, 38.25674470898],
                [-85.71441689156, 38.2567820914],
                [-85.71441561809, 38.25678369009],
                [-85.71440593662, 38.25680000496],
                [-85.71440948627, 38.25680130379],
                [-85.71440252845, 38.25681302886],
                [-85.71439949313, 38.25681191822],
                [-85.71438958698, 38.25682861171],
                [-85.71439761847, 38.25683155045],
                [-85.71439437533, 38.25683701568],
                [-85.71449067715, 38.2568722528],
                [-85.71449510383, 38.25688257733],
                [-85.7144846321, 38.25690104863],
                [-85.71454613753, 38.2569225488],
                [-85.71454492697, 38.25692468412],
                [-85.71455511651, 38.25692824603],
                [-85.71456391961, 38.25693057877],
                [-85.71457456388, 38.25693429964],
                [-85.71457541035, 38.25693280653],
                [-85.71460761935, 38.25694406567],
                [-85.7146053245, 38.2569482393],
                [-85.7146037976, 38.25694772177],
                [-85.71459323825, 38.25696693173],
                [-85.71458331207, 38.25696356739],
                [-85.7145726436, 38.25695995146],
                [-85.71453388445, 38.25703046344],
                [-85.7145450236, 38.2570342389],
                [-85.71453934736, 38.25704456531],
                [-85.7145536783, 38.25704942258],
                [-85.71455246827, 38.25705162391],
                [-85.7145648571, 38.25705582293],
                [-85.714577576, 38.25705942594],
                [-85.71459827477, 38.25706706414],
                [-85.71460924631, 38.25707067256],
                [-85.71462301535, 38.25707578041],
                [-85.71463507025, 38.25707974513],
                [-85.71463589871, 38.25707819192],
                [-85.71463864382, 38.25707909475],
                [-85.71464533434, 38.2570810568],
                [-85.71466283941, 38.25708693214],
                [-85.71466976738, 38.25708933803],
                [-85.71466935208, 38.25709009356],
                [-85.7146751152, 38.25709204689],
                [-85.71468184919, 38.25709432928],
                [-85.71469102918, 38.25709724745],
                [-85.71469689983, 38.25709923722],
                [-85.71470218846, 38.25710102973],
                [-85.71470266539, 38.2571001621],
                [-85.71470321517, 38.25710035592],
                [-85.71471009114, 38.25710105028],
                [-85.71471653633, 38.2571032684],
                [-85.7147159113, 38.25710438825],
                [-85.71472219504, 38.25710655081],
                [-85.71472789914, 38.25710851388],
                [-85.71472878966, 38.25710691837],
                [-85.71473794896, 38.25711007056],
                [-85.71473723286, 38.25711135357],
                [-85.71474351707, 38.25711351629],
                [-85.7147501249, 38.25711579038],
                [-85.71475096515, 38.25711428493],
                [-85.7147548415, 38.25711561899],
              ],
            ],
          ],
        },
        feature_type: 'level',
        properties: {
          category: 'unspecified',
          restriction: null,
          outdoor: false,
          ordinal: 5,
          name: {
            en: '5',
            frCA: null,
          },
          short_name: {
            en: '5',
            frCA: null,
          },
          display_point: null,
          address_id: null,
          building_ids: ['eff2f396-abf1-45bd-afdd-af12c6ce5fe5'],
          elevation: -8.526,
          minZ: -7.185,
        },
      },
      {
        id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-85.71480495042, 38.25701452873],
                [-85.71480863568, 38.25701581471],
                [-85.71481167288, 38.25701042357],
                [-85.71481473211, 38.25701160793],
                [-85.71481777986, 38.25700624383],
                [-85.71482077905, 38.25700096518],
                [-85.71481763779, 38.25699983568],
                [-85.71482147042, 38.25699303263],
                [-85.71482338593, 38.25699152153],
                [-85.7148267854, 38.25698539418],
                [-85.71482770556, 38.25698570896],
                [-85.71483469784, 38.25697310575],
                [-85.7148332814, 38.2569726212],
                [-85.71483791976, 38.25696426081],
                [-85.71483866172, 38.2569636142],
                [-85.71484058125, 38.25696010393],
                [-85.71484167359, 38.25696047224],
                [-85.71484638335, 38.25695185943],
                [-85.7148448828, 38.25695135348],
                [-85.71485364123, 38.25693533679],
                [-85.71485351152, 38.25693461368],
                [-85.71485713921, 38.25692798696],
                [-85.71485861055, 38.25692848361],
                [-85.7148627864, 38.25692085557],
                [-85.71486077724, 38.25692017738],
                [-85.71486835956, 38.25690632672],
                [-85.71487003819, 38.25690594704],
                [-85.71487499196, 38.25689695686],
                [-85.71487558706, 38.25689715905],
                [-85.71487965022, 38.25688978518],
                [-85.71487881974, 38.25688950301],
                [-85.71488463627, 38.25687894708],
                [-85.71487860878, 38.25687689917],
                [-85.71488265603, 38.25686955417],
                [-85.71486628764, 38.25686399281],
                [-85.71486644889, 38.25686370017],
                [-85.71485636185, 38.25686027297],
                [-85.71485425733, 38.25685967488],
                [-85.71482738461, 38.25685040223],
                [-85.71482502744, 38.25684988033],
                [-85.71481832479, 38.25684755659],
                [-85.71481887596, 38.25684657631],
                [-85.71480884423, 38.25684309841],
                [-85.71480813942, 38.25684435196],
                [-85.71479606498, 38.25684016587],
                [-85.71479668107, 38.25683907012],
                [-85.71478709067, 38.25683574523],
                [-85.71478665941, 38.25683651224],
                [-85.7147862572, 38.2568363728],
                [-85.71478369682, 38.25683505549],
                [-85.71474973399, 38.25682318064],
                [-85.71474825231, 38.25682252544],
                [-85.71474796444, 38.25682239814],
                [-85.71470904681, 38.2568086652],
                [-85.71470752249, 38.25680767078],
                [-85.71468574765, 38.25679992378],
                [-85.71468634414, 38.25679888998],
                [-85.71467601172, 38.25679521394],
                [-85.71467515803, 38.25679669349],
                [-85.71466561762, 38.25679329923],
                [-85.71466404423, 38.2567927179],
                [-85.71465203751, 38.2567884391],
                [-85.71465273187, 38.25678723768],
                [-85.71463519924, 38.25678098962],
                [-85.71463408562, 38.25678291647],
                [-85.71462073763, 38.25677815967],
                [-85.71461931046, 38.25677707041],
                [-85.71460656165, 38.25677256206],
                [-85.71460738712, 38.25677112273],
                [-85.7145922494, 38.25676576959],
                [-85.71459138354, 38.25676727937],
                [-85.71457843647, 38.2567627009],
                [-85.71457290889, 38.25676112361],
                [-85.71455508972, 38.25675488682],
                [-85.7145558715, 38.25675350953],
                [-85.71453856366, 38.25674745171],
                [-85.71453769786, 38.25674897701],
                [-85.71452698314, 38.25674522681],
                [-85.71452324393, 38.25674358743],
                [-85.71450316147, 38.25673668512],
                [-85.71450377943, 38.25673557647],
                [-85.71448595748, 38.25672945109],
                [-85.71448526825, 38.25673068761],
                [-85.71447056758, 38.25672563501],
                [-85.71446665778, 38.25673264935],
                [-85.71446518578, 38.25673214343],
                [-85.71446143852, 38.25673886615],
                [-85.71445740828, 38.25674609656],
                [-85.71445895408, 38.25674662785],
                [-85.71444836431, 38.25676562629],
                [-85.7144480433, 38.25676619312],
                [-85.71443380278, 38.25679159555],
                [-85.71443324667, 38.25679212483],
                [-85.71441993927, 38.25681589584],
                [-85.71441885494, 38.25681713848],
                [-85.71441772602, 38.25681913782],
                [-85.71441608159, 38.25681856529],
                [-85.7144120731, 38.25682566439],
                [-85.71440824994, 38.25683243528],
                [-85.71440987273, 38.25683300028],
                [-85.71440576326, 38.25684027823],
                [-85.71442050398, 38.25684541043],
                [-85.71441969916, 38.25684683578],
                [-85.71442870675, 38.25684997191],
                [-85.71443725699, 38.2568529488],
                [-85.71443828319, 38.25685113138],
                [-85.71445857762, 38.25685819719],
                [-85.71446041985, 38.25685865637],
                [-85.71446582919, 38.25686053175],
                [-85.71446538187, 38.25686132734],
                [-85.71447230413, 38.25686372723],
                [-85.71447138713, 38.25686535817],
                [-85.71448022528, 38.25686842228],
                [-85.71448934749, 38.25687158488],
                [-85.71449029728, 38.25686989564],
                [-85.71450688589, 38.25687564679],
                [-85.71453459197, 38.2568263704],
                [-85.7145386269, 38.25682745513],
                [-85.71457480898, 38.25684002395],
                [-85.71457365686, 38.2568414192],
                [-85.71456687737, 38.25685366475],
                [-85.7145733223, 38.25685586487],
                [-85.71457777332, 38.25685738432],
                [-85.71458200234, 38.25685882798],
                [-85.71458862645, 38.25686108926],
                [-85.71459540594, 38.25684884371],
                [-85.71460248061, 38.25685133729],
                [-85.71459233286, 38.2568693499],
                [-85.71460213224, 38.25687275398],
                [-85.71460323179, 38.25687331996],
                [-85.71460478478, 38.25687384603],
                [-85.71460412665, 38.256875044],
                [-85.71463496873, 38.25688549163],
                [-85.71464243218, 38.25688802175],
                [-85.71465180118, 38.25689118409],
                [-85.71466148827, 38.2568734877],
                [-85.71466758628, 38.25687210239],
                [-85.71466836137, 38.25687072657],
                [-85.71467225279, 38.25687207836],
                [-85.71467312201, 38.25687053545],
                [-85.71467926942, 38.25687267092],
                [-85.71467817983, 38.25687410449],
                [-85.71466303492, 38.25690077498],
                [-85.71466026436, 38.25690132691],
                [-85.71463472797, 38.25694813719],
                [-85.71468811039, 38.25696609377],
                [-85.71469006269, 38.25696644426],
                [-85.71470840987, 38.25697265125],
                [-85.71471553225, 38.25697521908],
                [-85.71474119864, 38.25698380816],
                [-85.71474241525, 38.25698156646],
                [-85.71475187712, 38.25698473281],
                [-85.71475138231, 38.25698729883],
                [-85.71476302738, 38.2569913456],
                [-85.71476201924, 38.25699954785],
                [-85.71479695578, 38.257011739],
                [-85.71480101288, 38.25701315473],
                [-85.71480495042, 38.25701452873],
              ],
            ],
          ],
        },
        feature_type: 'level',
        properties: {
          category: 'unspecified',
          restriction: null,
          outdoor: false,
          ordinal: 4,
          name: {
            en: '4',
            frCA: null,
          },
          short_name: {
            en: '4',
            frCA: null,
          },
          display_point: null,
          address_id: null,
          building_ids: ['eff2f396-abf1-45bd-afdd-af12c6ce5fe5'],
          elevation: -5.557,
          minZ: -4.216,
        },
      },
      {
        id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-85.71390949173, 38.2585788471],
                [-85.71394274788, 38.25852108066],
                [-85.71394370029, 38.25851776662],
                [-85.71395103313, 38.25850481931],
                [-85.71396219166, 38.25848461995],
                [-85.71396970365, 38.25847170502],
                [-85.71394173332, 38.2584619323],
                [-85.71394026392, 38.25846457268],
                [-85.71393727864, 38.25846352763],
                [-85.7139432006, 38.25845308129],
                [-85.71401194264, 38.25833073226],
                [-85.71397418617, 38.2583176476],
                [-85.71397580706, 38.25831476176],
                [-85.71398594479, 38.25831818015],
                [-85.71400321239, 38.25828660527],
                [-85.71399278704, 38.25828308989],
                [-85.71399502025, 38.25827915346],
                [-85.7140634805, 38.25830353009],
                [-85.71410492231, 38.25823215183],
                [-85.71410830427, 38.25823139394],
                [-85.71411023119, 38.25822788667],
                [-85.71411321309, 38.2582289345],
                [-85.71411534294, 38.2582250959],
                [-85.71411708345, 38.25822195901],
                [-85.71411406652, 38.25822090584],
                [-85.71411638707, 38.25821668212],
                [-85.71412677597, 38.25819795149],
                [-85.71413237271, 38.25818786086],
                [-85.71413227912, 38.25818495352],
                [-85.71420219061, 38.25806378803],
                [-85.71420359344, 38.2580612681],
                [-85.71422571316, 38.2580238679],
                [-85.71422670926, 38.25802162749],
                [-85.71424866348, 38.25798403949],
                [-85.71425056894, 38.25798116809],
                [-85.71438854277, 38.25774204108],
                [-85.71439030757, 38.25773961311],
                [-85.71450520722, 38.25754128327],
                [-85.71450689108, 38.25753703703],
                [-85.714527065, 38.25750220475],
                [-85.71453423359, 38.25749097686],
                [-85.71453555141, 38.25748864326],
                [-85.71454013777, 38.25749024527],
                [-85.71454257882, 38.25748602749],
                [-85.71454489709, 38.25748202186],
                [-85.71454019009, 38.25748042904],
                [-85.71454792703, 38.25746677154],
                [-85.71455232709, 38.2574590044],
                [-85.71455083395, 38.25745777008],
                [-85.71457050719, 38.25742375763],
                [-85.71456991429, 38.25742088168],
                [-85.71457298603, 38.2574154959],
                [-85.71457472575, 38.25741610771],
                [-85.71459374547, 38.25738275986],
                [-85.71459402224, 38.2573813305],
                [-85.71460929193, 38.25735418796],
                [-85.71460958465, 38.25735279809],
                [-85.71461549449, 38.25734252179],
                [-85.71461749384, 38.25734201788],
                [-85.71464092177, 38.25730113233],
                [-85.7145838118, 38.25728099707],
                [-85.71458261343, 38.25728057688],
                [-85.71457575824, 38.25727815532],
                [-85.71457401282, 38.25727765613],
                [-85.7145479074, 38.25726822418],
                [-85.71454241132, 38.25726645263],
                [-85.71453248944, 38.25726284998],
                [-85.71452832996, 38.25726991339],
                [-85.71449282946, 38.2572570231],
                [-85.71449376931, 38.25724939172],
                [-85.71443975395, 38.25723006507],
                [-85.71443686033, 38.25722923659],
                [-85.71437572292, 38.25720747927],
                [-85.71437441462, 38.25720703087],
                [-85.71441329578, 38.25714009325],
                [-85.71443027827, 38.25714617564],
                [-85.71449552943, 38.25703383942],
                [-85.71447408348, 38.25702615839],
                [-85.71447587222, 38.25702302478],
                [-85.7145170829, 38.25703752729],
                [-85.7145341739, 38.25704370295],
                [-85.71461364362, 38.25707111533],
                [-85.71461574092, 38.25707150308],
                [-85.71465918317, 38.25708688485],
                [-85.71466202684, 38.25708763714],
                [-85.71470546909, 38.25710301891],
                [-85.71470810254, 38.25710386406],
                [-85.71474739607, 38.25711791067],
                [-85.7147868734, 38.25704981765],
                [-85.71478742915, 38.25704792249],
                [-85.71480396137, 38.25701894443],
                [-85.71480863568, 38.25701581471],
                [-85.71481167288, 38.25701042357],
                [-85.71481473211, 38.25701160793],
                [-85.71481777986, 38.25700624383],
                [-85.71482077905, 38.25700096518],
                [-85.71481763779, 38.25699983568],
                [-85.71482147042, 38.25699303263],
                [-85.71482007437, 38.25699159864],
                [-85.71483203466, 38.25696994543],
                [-85.71483180617, 38.25696660822],
                [-85.71483389372, 38.25696299485],
                [-85.71483513142, 38.25696343576],
                [-85.71484798049, 38.25694119521],
                [-85.71484373189, 38.25693968172],
                [-85.71484513493, 38.2569372532],
                [-85.71484904401, 38.25693655084],
                [-85.71486856132, 38.25690337005],
                [-85.71486957805, 38.2569024166],
                [-85.71488728465, 38.25687226125],
                [-85.71483988142, 38.25685509851],
                [-85.71483826321, 38.25685466905],
                [-85.71477116204, 38.25683078846],
                [-85.71476984008, 38.2568303359],
                [-85.71474061001, 38.2568198974],
                [-85.71473757932, 38.25681879961],
                [-85.71470353107, 38.25680675638],
                [-85.71469692783, 38.25680431921],
                [-85.71468260671, 38.25679932243],
                [-85.71468420387, 38.25679649986],
                [-85.71466530699, 38.25678990655],
                [-85.71466381755, 38.25679253875],
                [-85.71465032363, 38.25678783058],
                [-85.71464964775, 38.25678902502],
                [-85.71464682567, 38.25678804036],
                [-85.71464752164, 38.25678666925],
                [-85.71463462525, 38.25678231177],
                [-85.71462491123, 38.25680003896],
                [-85.71462437234, 38.25680145248],
                [-85.71462315051, 38.25680363668],
                [-85.71461667583, 38.2568014034],
                [-85.71461740246, 38.25680010444],
                [-85.71462119904, 38.25680135731],
                [-85.71462811639, 38.25678883072],
                [-85.71461519277, 38.25678443025],
                [-85.71460213093, 38.25677998271],
                [-85.71459521359, 38.25679250931],
                [-85.71459923622, 38.25679383846],
                [-85.71459848175, 38.25679518721],
                [-85.71459181405, 38.25679288735],
                [-85.7146039398, 38.25677121066],
                [-85.71459208113, 38.25676712031],
                [-85.71459344285, 38.25676468601],
                [-85.71457445146, 38.25675813541],
                [-85.71457324793, 38.25676028691],
                [-85.71456123262, 38.25675614253],
                [-85.71455579643, 38.25675391745],
                [-85.71450986803, 38.25673802052],
                [-85.71450264903, 38.25673506231],
                [-85.71444974549, 38.25671656417],
                [-85.71443139276, 38.25674892852],
                [-85.71443643572, 38.25675069965],
                [-85.71443583046, 38.25675177201],
                [-85.71443127244, 38.25675018569],
                [-85.71439730508, 38.25681036642],
                [-85.71440169327, 38.25681189363],
                [-85.71440092276, 38.25681325875],
                [-85.71439696441, 38.25681189413],
                [-85.71438366031, 38.25683556768],
                [-85.71448655074, 38.25687281437],
                [-85.71447081434, 38.25690035348],
                [-85.7144400739, 38.25688979655],
                [-85.71443596202, 38.25688835627],
                [-85.71443148819, 38.2568867892],
                [-85.71436808729, 38.2568638888],
                [-85.71436600311, 38.25686768979],
                [-85.71434860957, 38.25689778218],
                [-85.71434712629, 38.25689947818],
                [-85.71428896391, 38.25700012663],
                [-85.71434870569, 38.25702141388],
                [-85.71435541136, 38.25700980991],
                [-85.71438028118, 38.25701867155],
                [-85.71437326805, 38.25703080757],
                [-85.71443276261, 38.25705200673],
                [-85.71443820832, 38.2570426604],
                [-85.71444242966, 38.25704417231],
                [-85.71434817568, 38.25720643961],
                [-85.71434553075, 38.25720549231],
                [-85.71435183447, 38.25719460369],
                [-85.71435292272, 38.25719262593],
                [-85.71434394045, 38.25718944398],
                [-85.7143193758, 38.25718067702],
                [-85.7143105379, 38.25717754284],
                [-85.71430715822, 38.25718344467],
                [-85.71430619338, 38.25718313491],
                [-85.71425902346, 38.2571664685],
                [-85.71425126577, 38.25716363754],
                [-85.7142129371, 38.25715035094],
                [-85.71421229891, 38.25714998861],
                [-85.71420232036, 38.25714652917],
                [-85.71419928711, 38.25714527828],
                [-85.71415574745, 38.2571302211],
                [-85.71415423534, 38.25713291716],
                [-85.71415318639, 38.2571325544],
                [-85.71413344038, 38.25716776109],
                [-85.71413172873, 38.25717216543],
                [-85.71411090593, 38.25720748409],
                [-85.71411000181, 38.257209502],
                [-85.7140885978, 38.2572463554],
                [-85.71408765576, 38.25724853613],
                [-85.7140671195, 38.25728377718],
                [-85.71406571881, 38.25728555291],
                [-85.71404298098, 38.25732593275],
                [-85.71404187223, 38.25732759581],
                [-85.71402049277, 38.2573651696],
                [-85.71401898346, 38.25736793699],
                [-85.71399913925, 38.25740309483],
                [-85.71399547882, 38.25740943754],
                [-85.71398013623, 38.25743604865],
                [-85.7139748437, 38.25744522834],
                [-85.7139751882, 38.25744779085],
                [-85.71369889148, 38.25792812343],
                [-85.71369990385, 38.25793171062],
                [-85.71358243344, 38.25813484608],
                [-85.71358418316, 38.25813549496],
                [-85.71358359195, 38.2581365417],
                [-85.71358141352, 38.25813578306],
                [-85.71354424436, 38.25820144905],
                [-85.7135306879, 38.25822518046],
                [-85.71352611333, 38.25823313169],
                [-85.71352400686, 38.25823477884],
                [-85.71342494545, 38.25840685056],
                [-85.71390949173, 38.2585788471],
              ],
            ],
          ],
        },
        feature_type: 'level',
        properties: {
          category: 'unspecified',
          restriction: null,
          outdoor: false,
          ordinal: 0,
          name: {
            en: '0',
            frCA: null,
          },
          short_name: {
            en: '0',
            frCA: null,
          },
          display_point: null,
          address_id: null,
          building_ids: ['eff2f396-abf1-45bd-afdd-af12c6ce5fe5'],
          elevation: 0,
          minZ: 1.341,
        },
      },
      {
        id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-85.71480863568, 38.25701581471],
                [-85.71481167288, 38.25701042357],
                [-85.71481473211, 38.25701160793],
                [-85.71481777986, 38.25700624383],
                [-85.71482077905, 38.25700096518],
                [-85.71481763779, 38.25699983568],
                [-85.71482147042, 38.25699303263],
                [-85.71482221762, 38.25699027083],
                [-85.71482567971, 38.25698415656],
                [-85.71482719957, 38.25698468795],
                [-85.71483197417, 38.25697634281],
                [-85.7148303745, 38.2569757834],
                [-85.71483660717, 38.25696477611],
                [-85.71483861234, 38.2569610667],
                [-85.71484122334, 38.25695651943],
                [-85.71484296352, 38.25695713542],
                [-85.71484696726, 38.2569501376],
                [-85.71484526471, 38.25694953492],
                [-85.71485284878, 38.25693632664],
                [-85.7148538481, 38.25693430987],
                [-85.71485729172, 38.25692835005],
                [-85.71485907026, 38.25692898373],
                [-85.71486332851, 38.25692154107],
                [-85.71486161565, 38.25692093067],
                [-85.71486646452, 38.25691253881],
                [-85.7148670206, 38.2569111],
                [-85.71487584689, 38.25689576304],
                [-85.71487770055, 38.25689642133],
                [-85.71488223432, 38.25688849711],
                [-85.71488039051, 38.25688784227],
                [-85.71489112673, 38.2568691865],
                [-85.71486956972, 38.25686153698],
                [-85.71487020601, 38.25686043243],
                [-85.71485962822, 38.25685670074],
                [-85.71485898276, 38.25685782113],
                [-85.71484218928, 38.25685186195],
                [-85.71483892973, 38.25685092978],
                [-85.71483243488, 38.25684879349],
                [-85.71483318454, 38.25684749048],
                [-85.71482248251, 38.25684371496],
                [-85.71482175305, 38.25684498276],
                [-85.71481246198, 38.25684168825],
                [-85.71481318331, 38.25684043433],
                [-85.7148030349, 38.25683685412],
                [-85.71480233597, 38.25683806902],
                [-85.71478989037, 38.25683365595],
                [-85.7147905783, 38.25683245961],
                [-85.71478048054, 38.25682889727],
                [-85.71477981375, 38.25683005676],
                [-85.71477414006, 38.25682804493],
                [-85.71477202662, 38.25682746842],
                [-85.7147676849, 38.25682594813],
                [-85.71476842589, 38.25682464456],
                [-85.71475851684, 38.25682114879],
                [-85.71475776674, 38.25682246823],
                [-85.71473594231, 38.25681482625],
                [-85.71473671973, 38.25681345908],
                [-85.71472686312, 38.25680998181],
                [-85.71472607765, 38.25681136301],
                [-85.71470828815, 38.25680513389],
                [-85.71469778601, 38.25680139779],
                [-85.71468357386, 38.25679652232],
                [-85.71468521739, 38.25679356874],
                [-85.71466685203, 38.25678725136],
                [-85.71466530876, 38.25679002469],
                [-85.71465350856, 38.25678597663],
                [-85.71465195492, 38.25678537891],
                [-85.71463872812, 38.25678076257],
                [-85.71464024021, 38.25677809731],
                [-85.71462187598, 38.25677178031],
                [-85.71462042853, 38.25677433026],
                [-85.71460742703, 38.25676979255],
                [-85.7146057948, 38.25676922755],
                [-85.71459390242, 38.25676502678],
                [-85.71459526998, 38.25676262827],
                [-85.71457684072, 38.2567562889],
                [-85.71457550999, 38.25675861938],
                [-85.71455963377, 38.25675301138],
                [-85.71455782548, 38.25675236842],
                [-85.71454012936, 38.25674622898],
                [-85.71454133987, 38.25674407717],
                [-85.7145237508, 38.25673802681],
                [-85.71452262103, 38.25674003476],
                [-85.71450729756, 38.25673471848],
                [-85.71450490371, 38.25673383866],
                [-85.71448642659, 38.25672739088],
                [-85.71448747003, 38.25672554679],
                [-85.714469346, 38.25671931241],
                [-85.71446836907, 38.25672103792],
                [-85.71445425426, 38.25671611242],
                [-85.71445043422, 38.25672286239],
                [-85.71444754685, 38.25672185675],
                [-85.71443952925, 38.25673622868],
                [-85.71444226283, 38.25673718113],
                [-85.71443075747, 38.25675751095],
                [-85.71443021802, 38.2567584374],
                [-85.714424413, 38.25676860913],
                [-85.71442195064, 38.25676773919],
                [-85.71441637928, 38.25677772613],
                [-85.71441878767, 38.25677857793],
                [-85.71440467035, 38.25680331474],
                [-85.71440124569, 38.25680939077],
                [-85.71439933919, 38.25681274509],
                [-85.71439720501, 38.2568120969],
                [-85.71438908482, 38.2568266527],
                [-85.71439106508, 38.25682730264],
                [-85.71438738718, 38.25683377358],
                [-85.71440137303, 38.25683867509],
                [-85.71440029623, 38.25684081748],
                [-85.71441882197, 38.25684727254],
                [-85.71442002724, 38.25684521269],
                [-85.71443791184, 38.25685148057],
                [-85.7144400756, 38.2568522619],
                [-85.71445474852, 38.25685735777],
                [-85.71445324087, 38.25686003452],
                [-85.71447096548, 38.25686619023],
                [-85.71447242288, 38.25686360271],
                [-85.71448970236, 38.25686960383],
                [-85.7144914353, 38.2568665271],
                [-85.71449266043, 38.25686695258],
                [-85.71451852857, 38.25682102525],
                [-85.71451968789, 38.2568193062],
                [-85.71458233457, 38.2568414052],
                [-85.71458091825, 38.25684388434],
                [-85.71457365686, 38.2568414192],
                [-85.71456687737, 38.25685366475],
                [-85.7145733223, 38.25685586487],
                [-85.71457777332, 38.25685738432],
                [-85.71458200234, 38.25685882798],
                [-85.71458862645, 38.25686108926],
                [-85.71459540594, 38.25684884371],
                [-85.71458854975, 38.2568465178],
                [-85.71458992496, 38.25684413556],
                [-85.71459912744, 38.25684738179],
                [-85.71458788463, 38.25686703387],
                [-85.7146009464, 38.25687164148],
                [-85.71460527387, 38.25686407721],
                [-85.71460779129, 38.25686496492],
                [-85.71460323179, 38.25687331996],
                [-85.71460478478, 38.25687384603],
                [-85.71460412665, 38.256875044],
                [-85.71463496873, 38.25688549163],
                [-85.71464243218, 38.25688802175],
                [-85.71465180118, 38.25689118409],
                [-85.71465291233, 38.25689470269],
                [-85.71465476088, 38.25689535849],
                [-85.714653036, 38.25689835771],
                [-85.71465918324, 38.25690053762],
                [-85.71465832524, 38.25690202951],
                [-85.71465031728, 38.25689919789],
                [-85.71462271721, 38.25694700732],
                [-85.71463741327, 38.25695244579],
                [-85.71463942208, 38.25694880033],
                [-85.71467114378, 38.25695974674],
                [-85.71466912502, 38.2569633237],
                [-85.71468879855, 38.25697000819],
                [-85.71469094689, 38.25697040053],
                [-85.71470440096, 38.25697528127],
                [-85.71470546698, 38.25697346935],
                [-85.71472202479, 38.25697947604],
                [-85.71472084557, 38.25698148037],
                [-85.71472853717, 38.25698427066],
                [-85.71473062833, 38.25698071629],
                [-85.71473934114, 38.25698387704],
                [-85.7147458937, 38.2569727396],
                [-85.71474810167, 38.25697354059],
                [-85.71475011588, 38.2569701646],
                [-85.71475232076, 38.25697094238],
                [-85.71474195443, 38.25698906236],
                [-85.7147592486, 38.25699516297],
                [-85.71476572163, 38.25698394723],
                [-85.7147702568, 38.2569849908],
                [-85.71476201924, 38.25699954785],
                [-85.71476784545, 38.25700158091],
                [-85.71479695578, 38.257011739],
                [-85.71480101288, 38.25701315473],
                [-85.71480495042, 38.25701452873],
                [-85.71480863568, 38.25701581471],
              ],
            ],
          ],
        },
        feature_type: 'level',
        properties: {
          category: 'unspecified',
          restriction: null,
          outdoor: false,
          ordinal: 1,
          name: {
            en: '1',
            frCA: null,
          },
          short_name: {
            en: '1',
            frCA: null,
          },
          display_point: null,
          address_id: null,
          building_ids: ['eff2f396-abf1-45bd-afdd-af12c6ce5fe5'],
          elevation: -2.927,
          minZ: -1.586,
        },
      },
    ],
    occupant: null,
    opening: [
      {
        id: '00fa66e5-26af-4b04-8416-bafdfa294a6e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71361404244, 38.25814601784],
            [-85.71360881949, 38.25814417186],
            [-85.7136033949, 38.25814225461],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71360881949, 38.25814417186],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '01c3d1b1-474e-40b5-aa72-fb1c9690a479',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71459144343, 38.25679892605],
            [-85.71458801709, 38.25679775242],
            [-85.71458420511, 38.25679644669],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71458801709, 38.25679775242],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '029f02ef-f923-4838-8373-55e98625a03a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71444129221, 38.25746695343],
            [-85.71443786971, 38.25746572764],
            [-85.7144344589, 38.25746450604],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71443786971, 38.25746572764],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '02aa8a26-70c3-46b4-9e38-b7b8791c6a9a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71407761951, 38.25776737924],
            [-85.7140794335, 38.2577642415],
            [-85.71408128868, 38.25776092862],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7140794335, 38.2577642415],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '02d94a3c-c6b5-4fc1-90a6-42a167c5b8aa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71410344525, 38.25802249984],
            [-85.71410529202, 38.258019239],
            [-85.71410716794, 38.25801592667],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71410529202, 38.258019239],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '03506c29-dccf-49a0-9f7c-bf3acf26b8ee',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71426762606, 38.25718891281],
            [-85.71427209538, 38.2571905244],
            [-85.71427651613, 38.25719211847],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71427209538, 38.2571905244],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '0356bc24-97c5-45a9-b4dd-f377891f335a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71392878321, 38.2582552089],
            [-85.71394031455, 38.25825931134],
            [-85.71395237912, 38.25826360347],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71394031455, 38.25825931134],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '0364dc50-2739-469b-9790-81f7100994b4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71475133025, 38.25685957122],
            [-85.71474653421, 38.25685785849],
            [-85.71474172149, 38.25685613979],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71474653421, 38.25685785849],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '039ac101-380b-4a05-8604-c4faf44b6b64',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71470271861, 38.25686678254],
            [-85.7147002839, 38.25687113305],
            [-85.71469760347, 38.2568759226],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7147002839, 38.25687113305],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '04608312-36aa-4000-a7c2-946c5f134cd9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71474123695, 38.25685017085],
            [-85.71473873507, 38.2568546118],
            [-85.71473615807, 38.25685918606],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71473873507, 38.2568546118],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '05976d23-b267-451c-bcf4-004ec7611b2d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71423354513, 38.2574007038],
            [-85.71423168389, 38.25740390809],
            [-85.71422976156, 38.25740721755],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71423168389, 38.25740390809],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '0617b6fe-f141-4798-808a-d9063e7f27b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71383519435, 38.25811709523],
            [-85.71383863597, 38.25811830035],
            [-85.71384203868, 38.25811949184],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71383863597, 38.25811830035],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '07381540-434c-43eb-9bc9-3c9700429414',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71448391663, 38.25683601765],
            [-85.71447940135, 38.25683444621],
            [-85.71447508923, 38.25683294548],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71447940135, 38.25683444621],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '076360e7-6dae-40f9-8760-d82c619b92b5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71457777978, 38.25697696086],
            [-85.71457640742, 38.25697957974],
            [-85.71457507738, 38.25698211785],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71457640742, 38.25697957974],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '0837601e-b1ac-4573-8cfc-e488953a2a27',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71381672652, 38.25854652913],
            [-85.71350232501, 38.2584353333],
            [-85.71342506114, 38.25840801328],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Loading dock 6',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71350232501, 38.2584353333],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '09395372-4437-45ab-8d5f-58c54026865f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71424913675, 38.25746627235],
            [-85.7142279952, 38.25745870213],
            [-85.71420666548, 38.25745106452],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7142279952, 38.25745870213],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '0a2fef74-d8bd-4baa-bbb6-32af2ffda356',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71436682948, 38.25761271154],
            [-85.71436902909, 38.25760895942],
            [-85.71437134529, 38.25760500842],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71436902909, 38.25760895942],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '0a40ccee-4080-4894-b6bc-a50fa3fdbba7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71457227693, 38.25700579412],
            [-85.71456806414, 38.25700435401],
            [-85.71456389971, 38.25700293074],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71456806414, 38.25700435401],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '0af90c0d-4049-4dda-89d1-8a1014eb8fa4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71404331336, 38.25824034877],
            [-85.71404533649, 38.2582368204],
            [-85.7140473361, 38.25823333304],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71404533649, 38.2582368204],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '0b1a9900-88fa-48f7-b9f6-d5ce767c995c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71475626464, 38.2569789967],
            [-85.71475807216, 38.25697571301],
            [-85.71475992686, 38.25697234361],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71475807216, 38.25697571301],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '0bb2db94-d6f8-4f17-9a4f-0dff841e7c38',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71439571357, 38.25672557282],
            [-85.71439376271, 38.25672889672],
            [-85.71439195563, 38.25673194141],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71439376271, 38.25672889672],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '0bcbbb76-6e0d-4c23-b3e6-98dea3fc8ac8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7144400739, 38.25688979655],
            [-85.71443596202, 38.25688835627],
            [-85.71443148819, 38.2568867892],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Cafeteria Entrance',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71443596202, 38.25688835627],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '0c6ed23d-9666-4c6f-ae2b-f90fe14e21d5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71444058739, 38.25743563754],
            [-85.71444453554, 38.25742903558],
            [-85.7144484443, 38.25742249948],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71444453554, 38.25742903558],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '0d37a3b9-f87e-4c66-ab31-53eafc4fa859',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71436983181, 38.25727263413],
            [-85.71437395057, 38.2572741283],
            [-85.7143778869, 38.25727555629],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71437395057, 38.2572741283],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '0d622bad-ad21-4edc-9f4a-a442de79b145',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71453212868, 38.25691112932],
            [-85.71453385077, 38.25690802804],
            [-85.71453577156, 38.2569045689],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71453385077, 38.25690802804],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: '0e575642-6165-4b15-a42e-a6b576943264',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71360242367, 38.25831246434],
            [-85.71361112896, 38.25831550714],
            [-85.71362074803, 38.25831886935],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71361112896, 38.25831550714],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '104bd81f-3b27-47b6-ae82-bb814d6e9b25',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71438164568, 38.25753576991],
            [-85.71436202832, 38.25752885779],
            [-85.7143421835, 38.25752186552],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71436202832, 38.25752885779],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '105e3e34-5bd0-4400-b9af-7fd414c85482',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71447764566, 38.25729086042],
            [-85.71448207126, 38.25729246737],
            [-85.7144864179, 38.25729404564],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71448207126, 38.25729246737],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '1060845b-3309-4400-837f-20a08f254dad',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71462211325, 38.25677077411],
            [-85.71462211325, 38.25677077411],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71462211325, 38.25677077411],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '1063fb87-93d9-4a6b-9d1e-65f4eebfe3fe',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7135155763, 38.25833079212],
            [-85.71351312318, 38.25833507816],
            [-85.71351076195, 38.25833920365],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71351312318, 38.25833507816],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '110b50a6-3eb2-4934-8ba6-c9f79fb24566',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71394092484, 38.25809894929],
            [-85.71393870735, 38.25810269587],
            [-85.71393643979, 38.25810652705],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71393870735, 38.25810269587],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '12aeac2b-8c91-411c-b105-7730244aa9c2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71480616888, 38.25702622086],
            [-85.71480003033, 38.25703715194],
            [-85.71479397303, 38.25704799075],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Dock 1 Employee Entrance',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71480003033, 38.25703715194],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '135cd47c-7d40-4cf7-a692-6c3b2dde68a3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71469626319, 38.25687955176],
            [-85.7146919043, 38.25687797552],
            [-85.71468739269, 38.25687634405],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7146919043, 38.25687797552],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '13873d75-772f-4442-a52c-b54a502804fa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71436167693, 38.25756369554],
            [-85.71435971011, 38.25756705057],
            [-85.71435779104, 38.25757032414],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71435971011, 38.25756705057],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '1401317b-f259-4dfd-99a3-80af0c936c8b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71404730473, 38.25782006147],
            [-85.71404313253, 38.25782731469],
            [-85.71403891078, 38.25783465405],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71404313253, 38.25782731469],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '149f23bb-30d6-49ef-bbb1-8cc4e6d0b65e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71459600702, 38.25697950158],
            [-85.71459981152, 38.25698082142],
            [-85.71460337976, 38.2569820593],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459981152, 38.25698082142],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: '155fe23d-9abb-4559-ae42-928a0aae7f2e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71441848071, 38.25731611812],
            [-85.71441446493, 38.25731466407],
            [-85.71441079173, 38.2573133318],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71441446493, 38.25731466407],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '16b670a6-3528-4d3e-963d-9895931e9cac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7145298941, 38.25748946108],
            [-85.71452606668, 38.25748812416],
            [-85.71452276249, 38.25748697001],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71452606668, 38.25748812416],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '16c07e5b-c31e-41e0-b2d5-078536235b19',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71441926168, 38.25740733857],
            [-85.7144156098, 38.25740604595],
            [-85.7144118298, 38.25740467545],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7144156098, 38.25740604595],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '17cf2c9c-e59b-4272-9069-f162db937dfe',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71354643958, 38.25819498046],
            [-85.71354643958, 38.25819498046],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Emergency Exit 14',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71354643958, 38.25819498046],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '1823c069-c9ef-423d-94c0-9aac103dc874',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7147095057, 38.25692687444],
            [-85.71471113299, 38.25692389149],
            [-85.71471247154, 38.25692143781],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71471113299, 38.25692389149],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '1bbf31ae-73eb-4649-b82c-e4e5d67d753d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71477351242, 38.25697923917],
            [-85.71477173059, 38.25698238639],
            [-85.7147702568, 38.2569849908],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '1de646de-e4f9-443c-91d9-7a1b94e5c6ee',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71468282164, 38.25682898868],
            [-85.71468700054, 38.25683047544],
            [-85.71469105685, 38.25683191859],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71468700054, 38.25683047544],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '1e01848a-da84-49d4-9f5e-c71036723b32',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419453559, 38.25722263105],
            [-85.71419641855, 38.2572194823],
            [-85.71419880838, 38.25721545965],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71419641855, 38.2572194823],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '1e5e642b-ec6b-4ae6-a48e-27e42b62b30a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71436657306, 38.25717036538],
            [-85.71436398827, 38.25717478532],
            [-85.71436140486, 38.2571792029],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71436398827, 38.25717478532],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '1f80c71e-f268-46cd-bb61-03ed72cded3d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71397498012, 38.25827708549],
            [-85.71398585094, 38.25828075108],
            [-85.71399278704, 38.25828308989],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '1f8d832c-100d-4d85-8757-18d6100e1db3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71477324981, 38.25697967175],
            [-85.71477173059, 38.25698238639],
            [-85.7147702568, 38.2569849908],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '208855f9-b405-49c2-aca8-bb20998e6405',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7141909191, 38.25723469544],
            [-85.7141948581, 38.257236057],
            [-85.714199565, 38.2572376888],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7141948581, 38.257236057],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '20d4367b-9167-4bb9-8783-727c816b7821',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71454341765, 38.25699825975],
            [-85.71454726071, 38.25699173928],
            [-85.7145508994, 38.25698515055],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71454726071, 38.25699173928],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '2168e38e-1fd8-4abf-8166-5468e63e1b13',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71473679423, 38.25684738004],
            [-85.71473904386, 38.25684816151],
            [-85.71474140516, 38.25684898177],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71473904386, 38.25684816151],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '21c3d5a6-80f2-4ab7-819d-e53c1a1455c5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71421503873, 38.2572292618],
            [-85.71421309332, 38.25723277568],
            [-85.714211074, 38.25723642305],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71421309332, 38.25723277568],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '227699c4-5b5f-42d1-96ee-ed0aeb4c5018',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458789806, 38.25682720038],
            [-85.71459250116, 38.25682876273],
            [-85.7145970458, 38.25683030525],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459250116, 38.25682876273],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '24d0dcc9-67bb-4977-adc5-811128dbe4e2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71447745927, 38.25693094394],
            [-85.7144756525, 38.25693404546],
            [-85.71447364055, 38.25693749919],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7144756525, 38.25693404546],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '24e7d277-5d44-46d5-a9af-f25248070ec9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71402398697, 38.25745598552],
            [-85.7140259689, 38.2574525602],
            [-85.71402795248, 38.25744913201],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7140259689, 38.2574525602],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '250c49d0-1363-4a30-94d6-e8e5955d44ea',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71450263199, 38.25679077283],
            [-85.71450601539, 38.25679196635],
            [-85.71451007418, 38.25679339811],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71450601539, 38.25679196635],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '257e28fd-c1d6-447c-8f31-23ab5a65d5d4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71478061777, 38.25695808003],
            [-85.71477878309, 38.25696135835],
            [-85.71477737826, 38.25696386859],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71477878309, 38.25696135835],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '25d530de-78a9-41e4-a3ee-a3bfe59689b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.714412451, 38.25714135616],
            [-85.71440385138, 38.25713825523],
            [-85.71439533261, 38.25713518346],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71440385138, 38.25713825523],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '2711a474-c030-4e27-b58d-4d2e5e8c0a2d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7146081709, 38.25699664363],
            [-85.71460480227, 38.25700281178],
            [-85.71460112586, 38.25700914371],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460480227, 38.25700281178],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '27295d8e-6a07-42e4-ab92-191b5f948861',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71415399754, 38.25724209695],
            [-85.71415217689, 38.25724521023],
            [-85.71415036998, 38.25724830001],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71415217689, 38.25724521023],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '28a0325c-b27a-49f6-9db5-4b54d8752f12',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71390109557, 38.25831288001],
            [-85.71391008018, 38.25831601215],
            [-85.71391884016, 38.25831906598],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '299fb919-97ea-4b14-bb62-aa1825279402',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71420949242, 38.25788951582],
            [-85.71421091845, 38.25788699785],
            [-85.71421247634, 38.25788424707],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71421091845, 38.25788699785],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '29aace65-4ee9-4c57-afd7-0e139be248c5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7141305869, 38.2573511405],
            [-85.71413445615, 38.25734452417],
            [-85.71413817282, 38.25733816874],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71413445615, 38.25734452417],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '2a0c016b-c878-4ce2-8bba-20aec1e8652b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71358418316, 38.25813549496],
            [-85.71359025065, 38.25813760798],
            [-85.71359574666, 38.25813952198],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '2a6dce12-44d8-4320-821b-5aa1a4b63f1b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71476232307, 38.25696536149],
            [-85.71476428969, 38.25696187067],
            [-85.71476586108, 38.25695908139],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71476428969, 38.25696187067],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '2ac4fde4-d03c-42fb-b847-9c76346f7dd9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71407036501, 38.25747828534],
            [-85.71406682796, 38.2574843336],
            [-85.71406335166, 38.25749027798],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71406682796, 38.2574843336],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '2ac95f21-9784-41cb-84b5-6c1cf512f253',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71453163211, 38.25742203594],
            [-85.71452888218, 38.25742663427],
            [-85.7145265327, 38.257430563],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71452888218, 38.25742663427],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '2b1540d3-1afa-4e4c-ae83-4545953b2230',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71442276896, 38.2567921284],
            [-85.71442729618, 38.256793665],
            [-85.71443155183, 38.25679510943],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71442729618, 38.256793665],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '2b55c2dc-15f7-4c09-99ce-db01f66cbce4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71460976322, 38.25704211038],
            [-85.71464379624, 38.25705330347],
            [-85.71465111645, 38.25705571101],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71464379624, 38.25705330347],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '2b9ae91f-af30-4365-a877-f8e8f0ec1def',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71440092276, 38.25681325875],
            [-85.71440556479, 38.2568148743],
            [-85.71441031368, 38.25681652704],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71440556479, 38.2568148743],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '2b9beaac-aeff-41ad-9889-a4deeec5198a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7145276797, 38.25692725281],
            [-85.7145260641, 38.25693032099],
            [-85.71452440894, 38.25693346429],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7145260641, 38.25693032099],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '2c1adb80-ca45-4e20-a897-a12f865df22e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458449908, 38.2568098735],
            [-85.71458618257, 38.25680692782],
            [-85.71459127014, 38.25679802582],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71458618257, 38.25680692782],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '2c9e1088-0ae9-4bc4-9bff-a76f823671c4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7147142723, 38.25688673339],
            [-85.71471126667, 38.2568856893],
            [-85.71470798611, 38.25688454971],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71471126667, 38.2568856893],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '2ccf9a6d-c4cf-48bb-97a0-a37856ad8dd3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458854975, 38.2568465178],
            [-85.71458442866, 38.25684509571],
            [-85.71458091825, 38.25684388434],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '2df6b49a-e8f7-47a9-859e-0a8e9991f96b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419416928, 38.25745352005],
            [-85.71418961089, 38.25745192128],
            [-85.71418592511, 38.25745063042],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71418961089, 38.25745192128],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '2f2a24df-b569-461e-8696-03c9ba73f543',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71390657418, 38.2585789672],
            [-85.71381672652, 38.25854652913],
            [-85.71350232501, 38.2584353333],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Rear Employee Entrance 12',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71381672652, 38.25854652913],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '2f80d279-d1ab-4036-a767-b2caed217431',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71463496873, 38.25688549163],
            [-85.71463852805, 38.25688669734],
            [-85.71464243218, 38.25688802175],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71463852805, 38.25688669734],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: '30610db8-096f-4a07-a366-6b53468da59a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71430332029, 38.25750850065],
            [-85.71429813199, 38.25750665261],
            [-85.71429274001, 38.25750470558],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71429813199, 38.25750665261],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '30b01226-2965-47f3-a8e2-7979d4faf1e2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7146204223, 38.25697011991],
            [-85.71462184703, 38.25696762516],
            [-85.71462323531, 38.25696519424],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71462184703, 38.25696762516],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '30df8e83-461a-48e4-a1c1-59f67f7dadfb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71476199732, 38.25696133195],
            [-85.71476574589, 38.25696264388],
            [-85.71476943112, 38.25696393365],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71476574589, 38.25696264388],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '30e52956-87cd-492f-8b50-d8e16c7eccf0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71455730442, 38.25730852877],
            [-85.71455311955, 38.25730698563],
            [-85.71454926416, 38.25730556399],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71455311955, 38.25730698563],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '31ece040-e502-4b0d-8022-79dbf2e7fa6f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7146946161, 38.25683304563],
            [-85.71473155725, 38.25684583236],
            [-85.71473457124, 38.25684688533],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71473155725, 38.25684583236],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '3239f3a4-afef-4349-8685-de1f16bfd378',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71447483682, 38.25752201236],
            [-85.71447702385, 38.25751823624],
            [-85.71447922593, 38.25751443413],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71447702385, 38.25751823624],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '323eb15b-de26-4d9b-865d-0e8e9c3bb44d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71482686702, 38.25690620626],
            [-85.71482300663, 38.25690486525],
            [-85.71481748381, 38.25690294675],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71482300663, 38.25690486525],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '32584253-c1b1-48fe-8232-f839149c10d4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7148087555, 38.25690814418],
            [-85.71480701735, 38.25691110778],
            [-85.7148049003, 38.25691469829],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71480701735, 38.25691110778],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '32720240-eeae-4d7b-8ca9-1dd636f04a22',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71471492925, 38.25686567509],
            [-85.71471009797, 38.25686397083],
            [-85.71470574134, 38.256862434],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71471009797, 38.25686397083],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '33f35c5b-1086-41fa-8e83-bead17dafc83',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71482866684, 38.25687419759],
            [-85.71482653615, 38.25687781863],
            [-85.71482468409, 38.25688098045],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71482653615, 38.25687781863],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '34252ece-20c4-43b8-8399-ffa5d5312ef3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71466821229, 38.25686772109],
            [-85.71467153402, 38.25686187754],
            [-85.71467489775, 38.25685596012],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71467153402, 38.25686187754],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '344a43fc-edbc-42e1-81b8-eccb8f5d4fbe',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7139187801, 38.25803815298],
            [-85.71392075995, 38.25803472165],
            [-85.71392262787, 38.25803148431],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71392075995, 38.25803472165],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '3472e2ae-f8c2-4e5a-8528-5c829bb1749c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71427648663, 38.25726541948],
            [-85.71428075732, 38.25726692322],
            [-85.7142849505, 38.25726839966],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71428075732, 38.25726692322],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '3492e86b-383c-4acd-942c-fdef441baaca',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71412857642, 38.25735516454],
            [-85.71412696346, 38.25735792266],
            [-85.71412510124, 38.257361107],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71412696346, 38.25735792266],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '35144039-ee24-48ff-8234-143439df779b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71408285873, 38.25821363858],
            [-85.71408423021, 38.25821116589],
            [-85.71408588548, 38.25820818152],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71408423021, 38.25821116589],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '3714dec5-eb1c-4d59-aab9-3f6fa9ba5183',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7135701148, 38.25820709329],
            [-85.71357207259, 38.25820362701],
            [-85.71357397735, 38.2582002546],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '3726a7fa-3ad5-4db2-bdd1-d8fc75e8f352',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71451217982, 38.25681148148],
            [-85.71451446373, 38.25680739864],
            [-85.71451608575, 38.25680449902],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71451446373, 38.25680739864],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '3812aa74-11a2-4eeb-80d7-96f39bbe6de7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71475575463, 38.25699913347],
            [-85.71475066329, 38.25699737657],
            [-85.71474582911, 38.25699570841],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71475066329, 38.25699737657],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '3b1fca02-7e83-4358-aac1-9412d50477ae',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71476017777, 38.25684833356],
            [-85.7147583359, 38.25685157699],
            [-85.71475642873, 38.25685493542],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7147583359, 38.25685157699],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '3ba826e5-60b6-466d-9c05-958bbda51579',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71476117208, 38.25703871278],
            [-85.71475650882, 38.25703704577],
            [-85.71475166599, 38.25703531455],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71475650882, 38.25703704577],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '3bd0fddd-57c8-4bfd-902c-68d015b51dcf',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71463933271, 38.2569715223],
            [-85.7146330772, 38.25696949166],
            [-85.71462568885, 38.25696709329],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7146330772, 38.25696949166],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '3c3cbdaa-6714-4757-bf9a-ae12395ae055',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458513722, 38.25687195237],
            [-85.71459138986, 38.25687410906],
            [-85.71459747544, 38.25687620812],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459138986, 38.25687410906],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '3c676dca-9796-4320-a676-87d4ca2ac915',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71415145189, 38.25799399878],
            [-85.71415546565, 38.25798691165],
            [-85.7141591601, 38.25798038833],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71415546565, 38.25798691165],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '3d0eafe5-374f-4351-a624-ac40ef885686',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71404453155, 38.25812039422],
            [-85.7140478194, 38.25811458885],
            [-85.7140513182, 38.258108411],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7140478194, 38.25811458885],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '3d2ca520-9645-4b3a-b972-a267df8b2bf6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71408484645, 38.2577039499],
            [-85.71408066241, 38.25771106406],
            [-85.71407647285, 38.2577181876],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71408066241, 38.25771106406],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '3dab787a-e93c-43de-a377-9de623de75d7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71416910963, 38.25725610598],
            [-85.71417071162, 38.25725325289],
            [-85.71417234463, 38.25725034455],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71417071162, 38.25725325289],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '3e8e09bc-bf95-4f33-b541-61457b84c0da',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458854975, 38.2568465178],
            [-85.71458442866, 38.25684509571],
            [-85.71458091825, 38.25684388434],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '3ecbfa38-c2d6-4432-bbf9-7035cf4a6472',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71473400236, 38.25705820952],
            [-85.71473824948, 38.25705060013],
            [-85.71474211126, 38.25704368114],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71473824948, 38.25705060013],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '3f94dcfc-e4ec-4cde-aac8-cabf95e718f7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71439481898, 38.25730727189],
            [-85.71438922906, 38.25730526239],
            [-85.71438423105, 38.25730346394],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71438922906, 38.25730526239],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '40488c0b-e9d8-4785-8c3e-09ccbe79bcc9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71439003899, 38.25767623014],
            [-85.7143921786, 38.25767253021],
            [-85.71439411825, 38.25766917606],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7143921786, 38.25767253021],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '4129d82c-4361-4402-a98e-2e191107515e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71358418316, 38.25813549496],
            [-85.71359025065, 38.25813760798],
            [-85.71359574666, 38.25813952198],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '4192d42b-5976-4269-824c-065b68a0578c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71391884016, 38.25831906598],
            [-85.71391008018, 38.25831601215],
            [-85.71390109557, 38.25831288001],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '42c3c74a-c8d7-44d7-8c93-b5b1a472d1e7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71434553075, 38.25720549231],
            [-85.71434317604, 38.2572095909],
            [-85.71434066724, 38.25721395769],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71434317604, 38.2572095909],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '43352d5b-81a3-4f63-89b6-d14d199dd6b2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7144974086, 38.25681371072],
            [-85.71449335191, 38.2568122797],
            [-85.71448981365, 38.25681103155],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71449335191, 38.2568122797],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '45fd1dbf-6c42-4e0c-b6fb-3bd45b151a8c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419515134, 38.25740024698],
            [-85.71419045561, 38.25739862507],
            [-85.71418565287, 38.25739696621],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71419045561, 38.25739862507],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '4631a172-2a93-47b9-9e1a-4a10978bfd69',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71433258308, 38.25761624326],
            [-85.71433087885, 38.25761915035],
            [-85.71432901952, 38.25762232202],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71433087885, 38.25761915035],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '468ea26f-5549-4665-9295-f32a9fbcae08',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7144595099, 38.25702093875],
            [-85.71446682595, 38.25702355905],
            [-85.71447408348, 38.25702615839],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71446682595, 38.25702355905],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '477edaa9-e49f-4e31-bbd6-a38dc7fda903',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71445598617, 38.25676631476],
            [-85.71446032727, 38.25676783274],
            [-85.71446413617, 38.25676915878],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71446032727, 38.25676783274],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '47deae30-a534-48c9-9fa4-cc3e37eec033',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71477357246, 38.25688636409],
            [-85.7147779837, 38.25688792018],
            [-85.71478200149, 38.25688933748],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7147779837, 38.25688792018],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '47e2718b-076e-4be5-a430-a9c1de4cb411',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71441444484, 38.25691081838],
            [-85.7144129428, 38.25691339681],
            [-85.71441052939, 38.25691753968],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7144129428, 38.25691339681],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '483db33d-b7f4-4236-b442-711798484f9a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7138025097, 38.2581663505],
            [-85.71380418517, 38.25816344016],
            [-85.71380606008, 38.2581601834],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71380418517, 38.25816344016],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '487acbca-5db7-4549-bdf3-843f23f2c174',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71426311373, 38.25769416629],
            [-85.71425543814, 38.25769143558],
            [-85.7142472306, 38.25768851561],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71425543814, 38.25769143558],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '491239aa-cf38-4fde-b568-5e5d8262646f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71460900323, 38.2568046424],
            [-85.71460481866, 38.25680324998],
            [-85.71460052187, 38.25680182022],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460481866, 38.25680324998],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '4951a6b1-e67a-4b08-96fe-f8910a201683',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71392758069, 38.25826013252],
            [-85.71394029994, 38.25826463081],
            [-85.71395132253, 38.25826852905],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71394029994, 38.25826463081],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '49980d33-38f6-4065-aa35-915dba7a6e20',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458364839, 38.25703244014],
            [-85.7145852575, 38.25702953718],
            [-85.71458690608, 38.25702656341],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7145852575, 38.25702953718],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '4a1b3b56-0c24-4679-9cae-70ac02943892',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71444897968, 38.25746180507],
            [-85.71444066904, 38.25745880105],
            [-85.71443221625, 38.25745574564],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71444066904, 38.25745880105],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '4df15197-876a-4cf0-9115-e8d6aad507ad',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71457426975, 38.25732790195],
            [-85.71457837941, 38.25732935537],
            [-85.71458229201, 38.25733073638],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71457837941, 38.25732935537],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '4e0d1cb2-ff21-4699-8efb-e2ea98eb2b69',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7141093081, 38.25771385882],
            [-85.71411129309, 38.2577104508],
            [-85.71411328934, 38.25770702344],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71411129309, 38.2577104508],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '4e463870-4937-4c89-beb2-56aed4605343',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71431793653, 38.25753906709],
            [-85.71430819255, 38.25753557804],
            [-85.71429788541, 38.25753188733],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71430819255, 38.25753557804],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '4e806092-a862-4683-bbac-517023bd92ee',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71434736617, 38.25757205718],
            [-85.71434543665, 38.25757534857],
            [-85.71434353634, 38.25757859015],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71434543665, 38.25757534857],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '4ebc65e3-8d1c-46f6-a1ca-78352f98b102',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71446223679, 38.25682509684],
            [-85.71446646366, 38.25682656849],
            [-85.71447048208, 38.25682796756],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71446646366, 38.25682656849],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '4ede021e-3743-4eda-8cd7-711aa9ad4017',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71430896093, 38.25727091416],
            [-85.71431290339, 38.25726412685],
            [-85.71431680914, 38.25725740273],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71431290339, 38.25726412685],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '50050184-12c7-40f6-959d-9288d37f6cba',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71454013777, 38.25749024527],
            [-85.71454257882, 38.25748602749],
            [-85.71454489709, 38.25748202186],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Emergency Exit 26',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71454257882, 38.25748602749],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '508d705d-ea0c-4dec-a2d3-41ec88a9c839',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71468711646, 38.25686524158],
            [-85.71469167049, 38.25686678728],
            [-85.71469610686, 38.25686829304],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71469167049, 38.25686678728],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '51fe2e8e-7738-4c20-9df5-3f3d9a8fa1f2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71399663815, 38.25809620042],
            [-85.7139946376, 38.25809966762],
            [-85.71399260366, 38.2581031927],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7139946376, 38.25809966762],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '52133df4-ab10-4200-a147-87e516c77439',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71397498012, 38.25827708549],
            [-85.71398585094, 38.25828075108],
            [-85.71399278704, 38.25828308989],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '522e61c1-3a97-44ec-ab12-a91f66091c20',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71450831848, 38.2567756164],
            [-85.7145129057, 38.25677721288],
            [-85.71451763699, 38.25677885949],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7145129057, 38.25677721288],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '53197747-f26f-47bd-a99f-c7e0d4ff6d6f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71394026392, 38.25846457268],
            [-85.71393805796, 38.25846841862],
            [-85.71393579416, 38.25847236539],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71393805796, 38.25846841862],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '534becc8-d46d-49b3-8410-b0f92b12749c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71480495042, 38.25701452873],
            [-85.71480101288, 38.25701315473],
            [-85.71479695578, 38.257011739],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71480101288, 38.25701315473],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: '534ce926-25b8-43f7-8fd1-7656d6fe04a1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71461155408, 38.25682920067],
            [-85.71461598625, 38.25683076415],
            [-85.71462067444, 38.25683241794],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71461598625, 38.25683076415],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '53b9458e-9d76-4c52-90e5-a7f41ba91d08',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71464106975, 38.25680193015],
            [-85.71463972346, 38.25680427255],
            [-85.71463702798, 38.2568089624],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71463972346, 38.25680427255],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '53d90438-b9cc-4103-a680-e847c3337b29',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71449389916, 38.25731753612],
            [-85.71448934883, 38.25731587551],
            [-85.71448479084, 38.2573142121],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71448934883, 38.25731587551],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '542f7265-e59b-4e04-9043-a087e0e23a2b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71414480804, 38.25728561196],
            [-85.71414335285, 38.2572882105],
            [-85.71414185092, 38.25729089252],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71414335285, 38.2572882105],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '54d60f92-e210-4c03-bd28-34805d86f07d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71438219534, 38.25758662191],
            [-85.71438475988, 38.25758224729],
            [-85.71438677473, 38.25757881034],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71438475988, 38.25758224729],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '557c513c-cbc5-4075-8d2e-4db1db635b15',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71393060253, 38.25826128473],
            [-85.71394172443, 38.25826517223],
            [-85.71395516678, 38.25826987081],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71394172443, 38.25826517223],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '56667df2-f8a7-4821-a67a-aaa53824efae',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71467262733, 38.25692620299],
            [-85.71467469097, 38.25692264818],
            [-85.71467694145, 38.2569187715],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71467469097, 38.25692264818],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '568a54a7-7deb-421b-bfe3-9552879254ea',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71428304151, 38.25724443975],
            [-85.71428712997, 38.2572459331],
            [-85.71429105056, 38.25724736514],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71428712997, 38.2572459331],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '56ad01d1-7e18-4638-b8ca-f947bf1a843a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71413187395, 38.25767682156],
            [-85.71413390156, 38.25767329663],
            [-85.71413593983, 38.25766975317],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71413390156, 38.25767329663],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '58c76164-5d86-444c-891e-53569c6adae0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71475235021, 38.25696625906],
            [-85.71475114935, 38.25696835813],
            [-85.71475011588, 38.2569701646],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71475114935, 38.25696835813],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '58e4dc4d-7554-4e55-b434-1a3f51759508',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71482273443, 38.25688567006],
            [-85.71482463977, 38.25688235872],
            [-85.71482649275, 38.25687913459],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71482463977, 38.25688235872],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '58f0f941-f020-46eb-a83f-f719ac8cfed2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71445991754, 38.25743314582],
            [-85.71445807251, 38.25743639071],
            [-85.7144560261, 38.25743998978],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71445807251, 38.25743639071],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '59c5b0fd-6652-4ee9-bc8a-354177cf58f5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71408196528, 38.25775986216],
            [-85.71408376341, 38.25775675188],
            [-85.71408565773, 38.25775347518],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71408376341, 38.25775675188],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '5adc5a97-635c-4d69-814e-b4555ca6eda5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71429688424, 38.25758753992],
            [-85.71430672735, 38.25757059028],
            [-85.71431646868, 38.2575538159],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71430672735, 38.25757059028],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '5c11d2e0-049d-4b51-8e4e-d75f57ea69e7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71459277643, 38.25699230612],
            [-85.71459553881, 38.25699329502],
            [-85.71459821208, 38.25699425202],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459553881, 38.25699329502],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '5c8d7f3a-2e0a-4717-863a-5da48a68a477',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71452319695, 38.25681874736],
            [-85.71452727842, 38.25681145432],
            [-85.71452982934, 38.25680689617],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71452727842, 38.25681145432],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '5d315d0b-fc4e-426d-9bcb-f8219fdd484a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71457305833, 38.25691491251],
            [-85.714574521, 38.25691213475],
            [-85.71457617111, 38.25690900103],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.714574521, 38.25691213475],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '5d9ac18a-587c-4bec-ab00-e9b0d7c69efa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71473111264, 38.25689572879],
            [-85.71472716745, 38.25689438974],
            [-85.71472315265, 38.25689302707],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71472716745, 38.25689438974],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '5e128703-8625-49e3-8146-c540ee72049b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71455294811, 38.25698057118],
            [-85.71455040586, 38.25698504124],
            [-85.71454228525, 38.25699931979],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71455040586, 38.25698504124],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: '5e594a73-5d8d-4931-8307-b726e01d786f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71425015559, 38.25720794662],
            [-85.71425453003, 38.25720948689],
            [-85.71425868497, 38.25721094986],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71425453003, 38.25720948689],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '5e6d7cfb-756f-4acc-8488-4a87b93e6cbc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71407297453, 38.25735796783],
            [-85.71407759774, 38.25735955355],
            [-85.71408234885, 38.25736118505],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71407759774, 38.25735955355],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '5ec00921-dda8-46ca-9e3e-c08346acf98a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419900829, 38.25715241984],
            [-85.71419701037, 38.25715597323],
            [-85.71419490497, 38.25715971778],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71419701037, 38.25715597323],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '5fa58af6-04dd-4ded-876c-20933e1e9c03',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71408285873, 38.25821363858],
            [-85.71408423021, 38.25821116589],
            [-85.71408588548, 38.25820818152],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71408423021, 38.25821116589],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '5fff2542-6006-41a4-b2a8-75614faac3b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71461109673, 38.25685905853],
            [-85.71460947834, 38.25686195039],
            [-85.71460779129, 38.25686496492],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '6011877f-3e3f-483f-99fa-90c38a3f2046',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71478891236, 38.25693690231],
            [-85.71478704232, 38.25694013911],
            [-85.71478510403, 38.25694349418],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71478704232, 38.25694013911],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '60157040-7043-45c5-9ec8-71e9b00f73d4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71476399385, 38.25704223576],
            [-85.71476221319, 38.25704537657],
            [-85.71476046497, 38.25704846016],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71476221319, 38.25704537657],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '609dbe8a-daba-4c7c-97e8-20af073a4846',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71475834392, 38.25713942607],
            [-85.71474581763, 38.25716122809],
            [-85.71473327868, 38.25718305214],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Dock 2 Emergency Exit 25',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71474581763, 38.25716122809],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '6335ca9e-ddf1-43f9-9584-11156c2cadd7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7141675761, 38.2573906607],
            [-85.71416273696, 38.25738900663],
            [-85.7141578894, 38.25738734968],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71416273696, 38.25738900663],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '637edcc3-8a8e-4edf-ade6-6a16d3607206',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7145378324, 38.25734433339],
            [-85.71453981844, 38.25734101241],
            [-85.71454188174, 38.25733756222],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71453981844, 38.25734101241],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '63aed861-b252-43a9-af41-7e56558de656',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71459430037, 38.25678007374],
            [-85.71459241569, 38.25678333397],
            [-85.71459040834, 38.25678680641],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459241569, 38.25678333397],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '63ff3d44-9eb7-4965-b73a-d89481d4b9fa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71438887094, 38.25766176261],
            [-85.71438359132, 38.25765988009],
            [-85.71437829824, 38.25765799276],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71438359132, 38.25765988009],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '640db70a-21d3-434a-a868-c7f729ae8c8c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71409057325, 38.2579401676],
            [-85.71408646795, 38.2579473045],
            [-85.7140824227, 38.25795433701],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71408646795, 38.2579473045],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '6410b5fe-fbce-463b-bed1-83a57241dae5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71452674738, 38.25730785657],
            [-85.71453070235, 38.25730929538],
            [-85.71453477691, 38.25731078873],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71453070235, 38.25730929538],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '650cd3ed-bfd7-4bd3-b4fe-e380614b14e1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71420461419, 38.25727193167],
            [-85.71420182441, 38.25727690246],
            [-85.71419881781, 38.25728225958],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71420182441, 38.25727690246],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '67a8c65f-f16d-42ec-a845-8d9820d2ab1a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71442902017, 38.25749657542],
            [-85.7144261291, 38.25750150706],
            [-85.71442355598, 38.25750589631],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7144261291, 38.25750150706],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '68295be4-fc67-4753-b42e-a62593000f26',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71466581401, 38.25684835476],
            [-85.71466179231, 38.25684695155],
            [-85.71465684639, 38.25684522587],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71466179231, 38.25684695155],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '6860a359-c24a-42ca-9eef-eccedef98cf0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71388877552, 38.25809027605],
            [-85.71389086129, 38.25808666113],
            [-85.71389302815, 38.2580829057],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71389086129, 38.25808666113],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '68b5e3bc-c50e-4d58-b31f-f0d1ef0d146f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71446974578, 38.25694045829],
            [-85.71446310011, 38.25693817343],
            [-85.71445644229, 38.2569358844],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71446310011, 38.25693817343],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: '68d26820-3365-4fd9-a112-b66ac647513b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71422913321, 38.25722458062],
            [-85.71423320448, 38.25722606769],
            [-85.71423722052, 38.25722753459],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71423320448, 38.25722606769],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '6a631a05-bd30-4a58-a817-a3d1d1ff1335',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71411015669, 38.25740859609],
            [-85.71411220774, 38.25740503153],
            [-85.71411427511, 38.25740143859],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71411220774, 38.25740503153],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '6a691ff2-d6d1-4496-b0f5-21894927a7ca',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71462811639, 38.25678883072],
            [-85.71461519277, 38.25678443025],
            [-85.71460213093, 38.25677998271],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'American Printing House for the Blind (main entrance)',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71461519277, 38.25678443025],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '6a982055-fe23-454e-a9c0-ec6ca46269b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71402014472, 38.25776448242],
            [-85.71399422548, 38.25780855311],
            [-85.71396817182, 38.25785285231],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71399422548, 38.25780855311],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '6ae156b2-1666-4324-a068-e426efead8b3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419193837, 38.25786462615],
            [-85.71418821832, 38.25787119468],
            [-85.71418383969, 38.25787892606],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71418821832, 38.25787119468],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '6b78c33c-c44b-407c-b7c3-dc93fa2374c1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71442513537, 38.25768797457],
            [-85.71442513537, 38.25768797457],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Dock 3 Entrance',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71442513537, 38.25768797457],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '6b85de1a-12c5-4b21-8b4b-8a4e15de7a3f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7138800326, 38.25835050065],
            [-85.71388890142, 38.25835364877],
            [-85.71389656422, 38.25835636879],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '6bb7a84a-ab12-4e5b-96da-79587d4b371d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71455790332, 38.2569333375],
            [-85.71455125323, 38.25693099663],
            [-85.71454456572, 38.25692864259],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71455125323, 38.25693099663],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '6c713bb2-f397-4a50-bcf5-7fa089473013',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71431454189, 38.25741737662],
            [-85.71425314291, 38.25739536427],
            [-85.71424535817, 38.25739255719],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71425314291, 38.25739536427],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '6cef7247-93d6-4650-bbf5-76fb0ce66d5d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71473901346, 38.25695364386],
            [-85.71473618549, 38.256952668],
            [-85.7147330354, 38.25695158097],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71473618549, 38.256952668],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '6d3ffcc2-84e3-4820-81b8-b706fbbfa762',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71461894684, 38.25680808018],
            [-85.71461386203, 38.25680626812],
            [-85.71460898536, 38.25680453023],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71461386203, 38.25680626812],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '6e45acce-3263-4726-9087-858c816ca6a3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71398879992, 38.2580871795],
            [-85.71399299704, 38.25808871119],
            [-85.71399722037, 38.25809025244],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71399299704, 38.25808871119],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '6e87733e-b2e2-4523-a303-52f26e4271c4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71391704441, 38.25800894426],
            [-85.71390077551, 38.25800317409],
            [-85.71388545152, 38.25799773907],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71390077551, 38.25800317409],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '6eb01642-b7f5-49f1-9d06-7fed05c47e51',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71408285873, 38.25821363858],
            [-85.71408423021, 38.25821116589],
            [-85.71408588548, 38.25820818152],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71408423021, 38.25821116589],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '70499d80-619a-4d59-91fe-4802ebea4491',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71424662269, 38.25737825707],
            [-85.7142480941, 38.25737572391],
            [-85.71425026877, 38.25737198002],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7142480941, 38.25737572391],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '7056b879-636e-4638-b01d-111239e1e20a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71462136485, 38.25685960932],
            [-85.71461975077, 38.25686254159],
            [-85.71461809555, 38.25686554859],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71461975077, 38.25686254159],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '70eda0c7-970f-4118-af9f-89114ac0da3f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71396228526, 38.2581678053],
            [-85.713968559, 38.25815672774],
            [-85.71397543587, 38.25814458523],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.713968559, 38.25815672774],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '70eea6df-893a-42e8-a46a-c7c74d07c67a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71444536915, 38.25713425076],
            [-85.71444893643, 38.25712804191],
            [-85.71445245109, 38.25712192464],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71444893643, 38.25712804191],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '7104e5dd-3e5a-4bb8-b6ef-c3eb3065cfa5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71415883059, 38.25728294658],
            [-85.7141612692, 38.25727883104],
            [-85.71416336052, 38.25727529955],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7141612692, 38.25727883104],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '71c04918-0190-4859-b257-82d2fc7f94b2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71460419037, 38.25680791089],
            [-85.71460177215, 38.25681220333],
            [-85.71459950483, 38.2568162279],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460177215, 38.25681220333],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '748160b7-1131-4612-8106-058411dd26e5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7139568187, 38.25788641517],
            [-85.71395114859, 38.25789630695],
            [-85.71394555919, 38.25790605793],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71395114859, 38.25789630695],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '74d02b69-390b-4984-89c4-4ee33eb84147',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71397451282, 38.25744579607],
            [-85.71397370073, 38.2574471895],
            [-85.71396926393, 38.25745480235],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'East Corridor Fire Exit 15',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71397370073, 38.2574471895],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '75566b6f-a67a-45b5-a4fa-b51f6199197e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71457007426, 38.25694987461],
            [-85.71457158474, 38.25694710319],
            [-85.71457320298, 38.25694413405],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71457158474, 38.25694710319],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '75a30851-dcdd-4fc9-90eb-2893ddc2297b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71411391367, 38.25734098101],
            [-85.71411597108, 38.25733731685],
            [-85.71411795245, 38.25733378811],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71411597108, 38.25733731685],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '75ffcc07-0c29-45b1-93dd-9f781fa1c089',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71431403826, 38.25756027374],
            [-85.714309792, 38.25756757307],
            [-85.71430315768, 38.25757897749],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.714309792, 38.25756757307],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '7710dc2b-4d43-40d4-96c0-4cee95fef884',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71480652513, 38.25692109128],
            [-85.71480467112, 38.25692445943],
            [-85.71480279064, 38.25692787565],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71480467112, 38.25692445943],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '77d242d0-7d0c-4883-9f3a-847783f9d27d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71481619316, 38.25693151773],
            [-85.7148177944, 38.25692867548],
            [-85.71481964835, 38.25692538466],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7148177944, 38.25692867548],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '792171bd-9066-4806-8fa6-cd333a71c364',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71450343598, 38.25743849685],
            [-85.71450539997, 38.25743504272],
            [-85.71450736288, 38.25743159051],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71450539997, 38.25743504272],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '7964e20a-167b-48f5-8e98-6dad1fb89460',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71442726668, 38.25714550365],
            [-85.7144232129, 38.25714403588],
            [-85.71441907521, 38.25714253772],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7144232129, 38.25714403588],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '79c0e444-7f59-407c-9aaf-9e76a7382b30',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71409208847, 38.25791957286],
            [-85.71408366713, 38.25791658602],
            [-85.71407484759, 38.25791345795],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71408366713, 38.25791658602],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '7a72193c-6e40-4ed3-a4ec-002424906aeb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7147570841, 38.25693719488],
            [-85.71476155007, 38.25693879544],
            [-85.714764835, 38.25693994347],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71476155007, 38.25693879544],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '7bb726a8-d17c-4063-b9ae-55e4ddc4c258',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71484276218, 38.25688338196],
            [-85.71484017634, 38.25688800277],
            [-85.71483837237, 38.2568912264],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71484017634, 38.25688800277],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '7bc1af06-9e5d-480f-91ac-980672c6c61f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71464949453, 38.25681585845],
            [-85.71464457116, 38.25681416949],
            [-85.71464030045, 38.25681270442],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71464457116, 38.25681416949],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '7caff4a4-6ac3-419f-8642-0d713a38b512',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71457320298, 38.25694413405],
            [-85.71457158474, 38.25694710319],
            [-85.71457007426, 38.25694987461],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71457158474, 38.25694710319],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: '7cc8d8b0-d78f-4669-9e16-bfe8bd6ea1b5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71431793653, 38.25753906709],
            [-85.71430819255, 38.25753557804],
            [-85.71429788541, 38.25753188733],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71430819255, 38.25753557804],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '7f930d16-cb4f-4127-afed-75fcef865343',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71461921982, 38.25697938114],
            [-85.71462658116, 38.25698177074],
            [-85.71463286368, 38.25698381015],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71462658116, 38.25698177074],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '80ed6018-9285-4685-8d63-4c126272aea4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71422316842, 38.25767955027],
            [-85.71421847337, 38.25767787993],
            [-85.71421349949, 38.25767611038],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71421847337, 38.25767787993],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '81427734-90e7-43ca-a80d-85a5a09039b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71448187027, 38.25692168023],
            [-85.71448417665, 38.25691754386],
            [-85.71448619133, 38.25691393066],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71448417665, 38.25691754386],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: '8157ebf6-d91b-44fc-8b8a-184bab9b58ab',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71473008871, 38.25681802219],
            [-85.71472823306, 38.2568213289],
            [-85.71472627649, 38.25682481545],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71472823306, 38.2568213289],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '815c70d6-4305-40f9-a498-1ff419572a10',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71453387658, 38.25732385315],
            [-85.71453000859, 38.25732670105],
            [-85.71452615269, 38.25732944151],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71453000859, 38.25732670105],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '825beaf3-98fb-461d-963d-474c6fc87ebb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71475292775, 38.25696996945],
            [-85.71474948534, 38.25696878155],
            [-85.71474569785, 38.25696747458],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71474948534, 38.25696878155],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '82cadfcb-115c-4a60-8c31-b833fac9f1de',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71457095307, 38.25682113334],
            [-85.7145749735, 38.25682249793],
            [-85.71457881563, 38.256823802],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7145749735, 38.25682249793],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '83de7593-21b2-493f-8020-455b33206988',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71414741786, 38.25794064626],
            [-85.71414569166, 38.25794369422],
            [-85.71414374251, 38.25794713584],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71414569166, 38.25794369422],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '850f8acc-f4a3-475a-bdac-0ee993de153e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71413114653, 38.25737810599],
            [-85.71413587954, 38.25737971312],
            [-85.71414055586, 38.25738130101],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71413587954, 38.25737971312],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '8626f0ba-d955-47cc-94a9-67ca1bc7c783',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71461109673, 38.25685905853],
            [-85.71460947834, 38.25686195039],
            [-85.71460779129, 38.25686496492],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '872bacf3-56d1-4e07-82ae-a036d1119190',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71468321935, 38.25693703168],
            [-85.71468798893, 38.25693873569],
            [-85.71469296632, 38.25694051395],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71468798893, 38.25693873569],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '87e78e4c-c942-4a3c-adba-dd63e60bf054',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71455629934, 38.25698057954],
            [-85.71456205989, 38.2569826167],
            [-85.71456786469, 38.25698466951],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71456205989, 38.2569826167],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '882be691-931b-4c92-8559-8bf949576caa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71401129331, 38.25818545268],
            [-85.71401799716, 38.25817371057],
            [-85.71402505447, 38.25816113049],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71401799716, 38.25817371057],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '88a3129b-018c-4ea0-8aa9-ba0f98519d56',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71422381982, 38.25736033678],
            [-85.71422848649, 38.25736198333],
            [-85.71423299674, 38.2573635747],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71422848649, 38.25736198333],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '89249292-7c69-4d6a-8d39-3d078ab7e040',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71452218996, 38.2567779922],
            [-85.71452608657, 38.25677932829],
            [-85.71452992308, 38.25678064378],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71452608657, 38.25677932829],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '8a83e104-bb1d-49fb-9a71-6b9338b817cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71464252561, 38.25684141037],
            [-85.7146471079, 38.25684299162],
            [-85.71465145246, 38.25684449082],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7146471079, 38.25684299162],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '8a95349d-baef-4cdf-93bf-0cb5d6d2c614',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71447635586, 38.25678454067],
            [-85.71447415624, 38.25678838555],
            [-85.71447271103, 38.25679091173],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71447415624, 38.25678838555],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: '8a9bb778-b2e3-4c44-bc7c-1461b5c910ab',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71447515077, 38.25679541826],
            [-85.71447327643, 38.25679878887],
            [-85.71447152707, 38.25680193473],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71447327643, 38.25679878887],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '8ad74980-eb8f-45d0-853c-41da34d110cf',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71467147849, 38.25685258483],
            [-85.7146746951, 38.25685370221],
            [-85.71467834851, 38.25685497132],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7146746951, 38.25685370221],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '8b2a0356-eabd-4843-95c9-7d06d2750af5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71462954177, 38.25684061296],
            [-85.71462569986, 38.25684748097],
            [-85.71462190491, 38.25685426505],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71462569986, 38.25684748097],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '8bc9c7ab-d296-4a22-a8ff-a687de306a8c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71459923622, 38.25679383846],
            [-85.71460855331, 38.25679705215],
            [-85.71461740246, 38.25680010444],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460855331, 38.25679705215],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '8c348e23-4217-45e5-b9e4-76ce7348240d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71447052949, 38.25675505391],
            [-85.71446493091, 38.25675301585],
            [-85.71445964326, 38.25675109235],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71446493091, 38.25675301585],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '8ca12ca6-5ce2-420d-be64-68fc77905eea',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71469764496, 38.25684012205],
            [-85.71469286233, 38.25683843039],
            [-85.71468792185, 38.25683668289],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71469286233, 38.25683843039],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '8cecf63f-126f-4932-af9f-571e9160b7ee',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71413760084, 38.25745158773],
            [-85.71413354408, 38.25745013205],
            [-85.71412935506, 38.25744862891],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71413354408, 38.25745013205],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '8d3f1c89-dc02-4517-b287-3de644df677c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71389656422, 38.25835636879],
            [-85.71388890142, 38.25835364877],
            [-85.7138800326, 38.25835050065],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '8e21d766-a3f2-43fe-a64a-ee5739cf4ba1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71477916974, 38.25697056346],
            [-85.71478086891, 38.25696747662],
            [-85.71478253135, 38.25696445649],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71478086891, 38.25696747662],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '8e649304-2a31-4ecf-bdbb-c228cf055f2b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71477351242, 38.25697923917],
            [-85.71477173059, 38.25698238639],
            [-85.7147702568, 38.2569849908],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '8f484ccf-6bec-4cfd-9d77-f23192f05011',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71446792746, 38.25694549211],
            [-85.71446972424, 38.2569422745],
            [-85.71447160933, 38.25693889878],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71446972424, 38.2569422745],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '9056b4a8-7b82-4712-8dce-db3cb0960c4a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71450915196, 38.25681792278],
            [-85.71451378166, 38.25681960755],
            [-85.71451704951, 38.25682079674],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71451378166, 38.25681960755],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '916566bf-ad96-4a0e-8445-eea2fa544116',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71432011019, 38.25742378125],
            [-85.71432458014, 38.25742538219],
            [-85.71432895287, 38.25742694831],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71432458014, 38.25742538219],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '92772389-7bbd-4e9e-a4fb-bbbdd3584e95',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71448349877, 38.25741314469],
            [-85.71448789774, 38.25741476677],
            [-85.71449208816, 38.25741631195],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71448789774, 38.25741476677],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: '928aa1fa-87aa-4a71-a6c5-ee541dad119b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71456923992, 38.25710613246],
            [-85.71457112138, 38.25710285777],
            [-85.71457289746, 38.25709976651],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71457112138, 38.25710285777],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '92adc58c-7a17-4078-a4ef-945418141fc5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71455938423, 38.25688303551],
            [-85.71456136252, 38.25687957017],
            [-85.71456294965, 38.25687678999],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71456136252, 38.25687957017],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '92c82f54-9d19-4602-8090-0e9f0f898408',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71459362071, 38.25679768972],
            [-85.71459848611, 38.25679937985],
            [-85.7146033354, 38.25680106438],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459848611, 38.25679937985],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: '939106df-7d09-4cde-9438-840331f79916',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71450568109, 38.25689522053],
            [-85.71450568109, 38.25689522053],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71450568109, 38.25689522053],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '9608a558-c267-43a1-9897-b0f3e84b0db1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71418674876, 38.25717306364],
            [-85.71418484978, 38.25717649365],
            [-85.7141829339, 38.25717995419],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71418484978, 38.25717649365],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '967c1e35-73d1-4fcf-bdc7-3185fc6d946e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458854975, 38.2568465178],
            [-85.71458442866, 38.25684509571],
            [-85.71458091825, 38.25684388434],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: '96809068-d9cd-44b2-bae8-e31d696b0dc5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419061396, 38.25747870496],
            [-85.71418696896, 38.25748498159],
            [-85.71418322304, 38.25749143199],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71418696896, 38.25748498159],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '97ce4719-94d2-4b74-9db7-515ce584fba5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71444389813, 38.25678743729],
            [-85.71444389813, 38.25678743729],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71444389813, 38.25678743729],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '98996143-11f5-480c-8f1c-0bea88067076',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71413790376, 38.25735877843],
            [-85.71413340723, 38.25735722166],
            [-85.71412871465, 38.25735559701],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71413340723, 38.25735722166],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '98c3f1d6-fd98-44c4-982e-4b7067a938d1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71456894812, 38.25689478149],
            [-85.71456398549, 38.25689307918],
            [-85.71455869893, 38.25689127447],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71456398549, 38.25689307918],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: '9a5c5dc4-8017-4316-904d-6653125763b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71395804726, 38.25830877324],
            [-85.71396692524, 38.25831176685],
            [-85.71397580706, 38.25831476176],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '9ad4d3ca-e306-4c9a-a0e2-72343f88d9b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71430564223, 38.25778209854],
            [-85.71430359919, 38.25778557027],
            [-85.7143014209, 38.25778930667],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71430359919, 38.25778557027],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '9b06551d-8035-4b4d-a764-b93b7da8c221',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71463722113, 38.25695576427],
            [-85.71464142815, 38.25695709],
            [-85.71464531652, 38.25695847941],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71464142815, 38.25695709],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '9b413732-dfb4-4ef8-b33a-07079a7f5118',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71449449222, 38.25702600606],
            [-85.71448549047, 38.25702266068],
            [-85.71447687655, 38.25701945944],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71448549047, 38.25702266068],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: '9c769eae-cd49-4e8c-bc1a-0c11c03b1417',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71442171271, 38.25725548175],
            [-85.71442368789, 38.25725205951],
            [-85.71442566333, 38.25724863681],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71442368789, 38.25725205951],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '9db4abe8-e867-4747-b507-1fbea3f23859',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71454177631, 38.25702426539],
            [-85.7145397446, 38.25702785413],
            [-85.71453761087, 38.25703147062],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7145397446, 38.25702785413],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '9f7591ed-b72e-436c-8d2a-9b610d8e340f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71447719847, 38.25740933709],
            [-85.71447318263, 38.25741645042],
            [-85.71446912067, 38.25742354373],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71447318263, 38.25741645042],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '9fc5f8f1-23f0-4808-8780-d3f06a4820d6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71453549829, 38.25687422522],
            [-85.71453757323, 38.25687063908],
            [-85.71453943605, 38.25686736299],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71453757323, 38.25687063908],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: '9fece2e6-2bb1-4f29-a044-29c71ec37f9d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71358418316, 38.25813549496],
            [-85.71359025065, 38.25813760798],
            [-85.71359574666, 38.25813952198],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'a09f4c0a-c176-41df-b9ff-e9395c8ea124',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71395804726, 38.25830877324],
            [-85.71396692524, 38.25831176685],
            [-85.71397580706, 38.25831476176],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'a0a009ed-c9f5-4e1a-9637-dd011d5b66e7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.714611326, 38.25686482668],
            [-85.71460714887, 38.25686340891],
            [-85.71460295725, 38.25686198622],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460714887, 38.25686340891],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'a205ae3f-31bf-4c28-8de8-e4a3c4ff821e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71451717331, 38.25682768235],
            [-85.71451526769, 38.25683105859],
            [-85.71451324543, 38.25683464146],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71451526769, 38.25683105859],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'a2311953-7ac5-40aa-81c0-fb7452393d3f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458854975, 38.2568465178],
            [-85.71458442866, 38.25684509571],
            [-85.71458091825, 38.25684388434],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'a29082bf-0ede-42a1-9b5f-db42e56c31f4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71408862413, 38.2574452865],
            [-85.7140864823, 38.25744896695],
            [-85.7140842846, 38.2574527434],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7140864823, 38.25744896695],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'a38ed8d6-27f0-434b-99cf-0c39820883b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71465556906, 38.25705089979],
            [-85.71466047316, 38.2570526529],
            [-85.71466504789, 38.25705428827],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71466047316, 38.2570526529],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'a3d4f31c-e6b5-4a9f-b119-c9ca9a3262dc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71424955067, 38.25731774337],
            [-85.71424583091, 38.25731638469],
            [-85.71424189748, 38.25731494796],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71424583091, 38.25731638469],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'a4e6dc17-4dd3-4aec-ae56-e8a40b4d3251',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71372465569, 38.25818510819],
            [-85.71374533952, 38.25819242323],
            [-85.71376228651, 38.25819841671],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71374533952, 38.25819242323],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'a6142d11-3da0-4153-b097-4e2f8c3d6a2a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71394026392, 38.25846457268],
            [-85.71393805796, 38.25846841862],
            [-85.71393579416, 38.25847236539],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71393805796, 38.25846841862],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'a6333a66-f72e-4074-9d14-1e5ece7027f7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7135471435, 38.25837838985],
            [-85.71355641586, 38.25838166911],
            [-85.7135656652, 38.25838494022],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71355641586, 38.25838166911],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'a69a458f-9831-4465-8b43-7b83a2aab95d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71477016878, 38.25697535497],
            [-85.71476596815, 38.25697389577],
            [-85.71476182021, 38.25697245488],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71476596815, 38.25697389577],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'a7714da0-c7a4-4368-b66c-09a675ccfa6a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71464547573, 38.25697092506],
            [-85.71464152225, 38.25697789839],
            [-85.71463751277, 38.2569849705],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71464152225, 38.25697789839],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'a78eeeaa-6bff-41c9-9dec-9dcc359bef33',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71401218597, 38.25789330318],
            [-85.71402186658, 38.25789673665],
            [-85.71403132831, 38.25790009249],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71402186658, 38.25789673665],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'a817ca94-d1b2-414f-835e-ac9395038908',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71376786094, 38.25820060072],
            [-85.7137720683, 38.25820203345],
            [-85.7137765621, 38.25820356372],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7137720683, 38.25820203345],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'a82443f5-7b76-4e18-a596-dabc64126fc6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71440728968, 38.25680206613],
            [-85.71440986001, 38.25679767035],
            [-85.71441254271, 38.25679252302],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71440986001, 38.25679767035],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'a83026a6-dfd3-4ce9-a86b-8312780b20db',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71482543218, 38.25691555097],
            [-85.71482396101, 38.25691816236],
            [-85.71482222601, 38.25692124202],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71482396101, 38.25691816236],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'a9334259-f1c4-4760-9b08-33b176ed6ee8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71443643572, 38.25675069965],
            [-85.71444148028, 38.25675245609],
            [-85.71444598186, 38.25675402197],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71444148028, 38.25675245609],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'abca0475-d5e1-44dc-bb10-10aeabf62d70',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7143663552, 38.25728559027],
            [-85.71437210341, 38.25728761658],
            [-85.7143778397, 38.25728963869],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71437210341, 38.25728761658],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'ac577058-e49f-471e-bb26-5caa6b7f8de8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71445849007, 38.25680544667],
            [-85.71445365566, 38.25680376416],
            [-85.71444893009, 38.25680211954],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71445365566, 38.25680376416],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'ac6eabea-37ea-41cc-9a74-6c53006224e2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71439788295, 38.2574514437],
            [-85.71440195737, 38.25745290298],
            [-85.71440595155, 38.25745433351],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71440195737, 38.25745290298],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'ad5909c1-52af-49d8-8ba5-5223020a2572',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71475414692, 38.25696216399],
            [-85.71474930512, 38.25696044616],
            [-85.71474458693, 38.2569587722],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71474930512, 38.25696044616],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'ad6a8d22-d573-4b15-94d2-7882b9288c72',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71479438471, 38.2569546895],
            [-85.71479012861, 38.25695323993],
            [-85.71478575291, 38.25695174963],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71479012861, 38.25695323993],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'aeab48eb-1ae5-4879-af16-258ad693ca61',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71467347705, 38.25690577135],
            [-85.71466915215, 38.25690431656],
            [-85.71466538035, 38.25690304781],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71466915215, 38.25690431656],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'afb38ea7-e75d-44a4-bd0d-30c4d5da0743',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71448855319, 38.25677943914],
            [-85.71449187927, 38.25678058231],
            [-85.71449658175, 38.25678219854],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71449187927, 38.25678058231],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'b0190664-8145-40bb-97c9-9d31d783d907',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71459279289, 38.25689298532],
            [-85.71459111055, 38.25689593227],
            [-85.7145892797, 38.25689913935],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459111055, 38.25689593227],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'b032ec90-732f-4e6b-aa68-feee1dbba40a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71466636236, 38.2569048796],
            [-85.71466220302, 38.25690340692],
            [-85.71465832524, 38.25690202951],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71466220302, 38.25690340692],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'b0a6fff1-9496-42ae-9244-ff6e944707a0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419300346, 38.25725507446],
            [-85.714195183, 38.25725119278],
            [-85.71419758579, 38.25724691349],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.714195183, 38.25725119278],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'b10b68ba-40f4-40af-a960-57172c3c4cf5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71474973671, 38.25699900969],
            [-85.71474557744, 38.25699755402],
            [-85.71474122496, 38.25699603074],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71474557744, 38.25699755402],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'b10c43ad-8f1b-49dc-a66b-57bd264546c2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7140938085, 38.25737524922],
            [-85.7140922845, 38.25737785522],
            [-85.71409069387, 38.25738057517],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7140922845, 38.25737785522],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'b1374119-5343-4ca0-98cc-3abb1be413bd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71453339047, 38.25729736972],
            [-85.71453772212, 38.25729893475],
            [-85.71454156896, 38.25730032462],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71453772212, 38.25729893475],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'b1af0084-c376-43fa-bc15-9d6aa20b8477',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7147702568, 38.2569849908],
            [-85.71477173059, 38.25698238639],
            [-85.71477324981, 38.25697967175],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'b1d5194e-f81b-48e4-bc5b-31ec1f18d177',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71478528314, 38.25698040503],
            [-85.71478118323, 38.25697897436],
            [-85.71477707635, 38.25697754126],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71478118323, 38.25697897436],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'b1eab451-666e-4367-8215-3723c0a35915',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71377871878, 38.25817883889],
            [-85.71378289563, 38.25818026124],
            [-85.71378707767, 38.25818168535],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71378289563, 38.25818026124],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'b1f2cf46-9ea2-491e-aa84-34487d3dea95',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.714823072, 38.25691868758],
            [-85.71482478424, 38.25691557699],
            [-85.71482645399, 38.25691254357],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71482478424, 38.25691557699],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'b3795065-daa0-4242-aedc-0e9661dd092f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71406037326, 38.25743272186],
            [-85.7140576627, 38.25743735686],
            [-85.71405507868, 38.25744177547],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7140576627, 38.25743735686],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'b37d170b-f2c7-4cd1-b06d-54812c04c374',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71434810001, 38.25761100863],
            [-85.7143509865, 38.25760601714],
            [-85.71435377591, 38.25760119353],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7143509865, 38.25760601714],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'b3d007ce-8110-41d0-b0f4-6342ccef435c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71405771367, 38.25799215069],
            [-85.71406145719, 38.25798561994],
            [-85.71406515357, 38.25797917143],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71406145719, 38.25798561994],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'b46e6f2d-617c-401a-910d-f05edd81c50d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71454129951, 38.2568177807],
            [-85.71454291975, 38.25681498016],
            [-85.71454463458, 38.25681178328],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71454291975, 38.25681498016],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'b5c36082-eb21-46c3-9a6b-9212f5bf231f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71439669642, 38.25731699883],
            [-85.71439475641, 38.25732032484],
            [-85.71439283257, 38.25732362319],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71439475641, 38.25732032484],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'b71202ad-b064-4046-82b3-6f209f4d4631',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71473241409, 38.25693783679],
            [-85.71472849798, 38.25693651194],
            [-85.7147244806, 38.25693515283],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71472849798, 38.25693651194],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'b7164e22-ddbf-4d37-aa4d-8ce40f5a7ef3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71436625739, 38.25676969347],
            [-85.71436941849, 38.25676406328],
            [-85.71437232423, 38.2567590085],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71436941849, 38.25676406328],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'b7344aea-51b1-4bfa-a867-327fa04c3a14',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71480408862, 38.25695404225],
            [-85.71480281132, 38.25695630949],
            [-85.71480178528, 38.25695813075],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71480281132, 38.25695630949],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'b735907a-a8e9-461e-be74-7cb7bb3479a7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71481473211, 38.25701160793],
            [-85.71481777986, 38.25700624383],
            [-85.71482077905, 38.25700096518],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'West Stairwell 1 Emergency Exit 23',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71481777986, 38.25700624383],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'b75f3387-757b-4d73-a459-6f11a9c578bd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71479470802, 38.25693448382],
            [-85.7147930389, 38.25693740137],
            [-85.71479136625, 38.25694032512],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7147930389, 38.25693740137],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'b7e7dceb-a2d4-40e6-a2cf-d33e1ae78bcc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71437444598, 38.25759958684],
            [-85.71437677815, 38.2575956086],
            [-85.71437886414, 38.25759205029],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71437677815, 38.2575956086],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'b80269c8-8a92-4d4d-9136-629926e843c8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71456220712, 38.25731869865],
            [-85.71456416025, 38.25731533368],
            [-85.71456606709, 38.25731196239],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71456416025, 38.25731533368],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'b874e7a3-5435-4ea6-8dc1-ee57df1b4770',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71469014349, 38.25706277469],
            [-85.71469488953, 38.2570644713],
            [-85.71469970041, 38.25706619109],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71469488953, 38.2570644713],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'b8e39bc2-ef7a-4417-8ff6-9a0d91f7e094',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7144005466, 38.25754547149],
            [-85.71439771375, 38.25755030381],
            [-85.71439532519, 38.25755437824],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71439771375, 38.25755030381],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'b952d19d-62d6-4ff9-ba4f-5c849abd7f0f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7138800326, 38.25835050065],
            [-85.71388890142, 38.25835364877],
            [-85.71389656422, 38.25835636879],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'b97aebf6-8b48-4f82-abb4-5b4c81cb057a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71450766302, 38.2574729136],
            [-85.7145057533, 38.25747628469],
            [-85.71450400751, 38.25747936642],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'ba53e4a8-5a6e-40d5-a51b-969b088ba327',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71357397735, 38.2582002546],
            [-85.71357207259, 38.25820362701],
            [-85.7135701148, 38.25820709329],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'ba8cae59-84d0-4da0-8202-8114c68d7bf4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71454300277, 38.25736928682],
            [-85.71454300277, 38.25736928682],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71454300277, 38.25736928682],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'baf61c37-ad43-4da3-9fd1-b9786bab7590',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71377871878, 38.25817883889],
            [-85.71378289563, 38.25818026124],
            [-85.71378707767, 38.25818168535],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71378289563, 38.25818026124],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'bb4f9823-a76c-4acd-b357-95e92b402d74',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71446525407, 38.25681301513],
            [-85.71446718099, 38.25680954998],
            [-85.71446891643, 38.25680642916],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71446718099, 38.25680954998],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'bbb6d57f-5564-41af-9ec8-1e615fdbcf6c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71420931173, 38.25719470096],
            [-85.71420492024, 38.25719320182],
            [-85.71420048501, 38.25719168775],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71420492024, 38.25719320182],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'bbbcbe60-521d-43b7-87be-a0cde8d9d43f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71468467857, 38.25693572509],
            [-85.71468151445, 38.25693463322],
            [-85.71467840073, 38.25693355875],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71468151445, 38.25693463322],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: 'bc4348f7-f14e-4740-9214-85af7ba934a3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71417378235, 38.25734556494],
            [-85.71417191437, 38.25734882948],
            [-85.71416977903, 38.25735256098],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71417191437, 38.25734882948],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'bcb46ae0-c0c5-499c-81b9-64ef45a1f222',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71448083934, 38.25692499798],
            [-85.7144828981, 38.25692140901],
            [-85.71448469351, 38.25691827914],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7144828981, 38.25692140901],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'be6e35c3-2cdd-4403-9ad3-0deb67d46af4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71464348337, 38.25679394699],
            [-85.71464515363, 38.25679099524],
            [-85.71464682567, 38.25678804036],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71464515363, 38.25679099524],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'bfdd4743-c583-45c9-8084-320465c88363',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71449273951, 38.25679393114],
            [-85.71449064211, 38.25679764716],
            [-85.71448873001, 38.25680103486],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71449064211, 38.25679764716],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'c0587cc4-9a96-4d56-8cd4-fdb0ac3c0880',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71480767792, 38.25691193003],
            [-85.71480936975, 38.25690897276],
            [-85.71481114415, 38.25690587117],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71480936975, 38.25690897276],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'c06ee343-17e5-434b-a98d-d79bff39fb89',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419339529, 38.25780855759],
            [-85.71419791485, 38.25781019654],
            [-85.71420241195, 38.25781182734],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71419791485, 38.25781019654],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'c1484b86-6601-45d4-bad4-65ce46ccc1fc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71442630532, 38.25675831054],
            [-85.71442188529, 38.25675678337],
            [-85.71441691604, 38.25675506644],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71442188529, 38.25675678337],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'c191ac4e-bd7e-400e-a088-1ba7dfd8e5d4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71427809327, 38.25761745772],
            [-85.71428005569, 38.25761407037],
            [-85.71428198839, 38.25761073431],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71428005569, 38.25761407037],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'c21130a6-4bd4-4f83-b007-c51fbaf22792',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71472980658, 38.25687981659],
            [-85.71473326769, 38.25688099134],
            [-85.71473674495, 38.25688217156],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71473326769, 38.25688099134],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'c2775955-e343-44a7-bd27-cdc001d9edc7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71447936248, 38.25738597315],
            [-85.7144835953, 38.2573874905],
            [-85.71448785535, 38.2573890176],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7144835953, 38.2573874905],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'c3496e20-cc11-4002-85a8-bd00fd72b48f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71477691525, 38.25696554605],
            [-85.71477553525, 38.25696795823],
            [-85.71477419805, 38.25697029561],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71477553525, 38.25696795823],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'c36fff3b-2fb8-4e30-863f-7925dc97d314',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71462474123, 38.25680741974],
            [-85.71462826611, 38.25680865376],
            [-85.71463310206, 38.25681033778],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71462826611, 38.25680865376],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'c3db0e90-878f-40cb-b615-09c1ec798811',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71417589547, 38.25719478979],
            [-85.71417787247, 38.2571912633],
            [-85.71417978258, 38.25718785613],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71417787247, 38.2571912633],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'c40f7f1d-63cc-4e20-86fa-1112f5f958fb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71460083232, 38.25687290858],
            [-85.71459674359, 38.25687149765],
            [-85.71459238418, 38.25686999332],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459674359, 38.25687149765],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: 'c534aa16-f6eb-4b0b-9497-92f26afc4e4b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71479376029, 38.25689524494],
            [-85.71478993839, 38.25689391729],
            [-85.7147860025, 38.25689255006],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71478993839, 38.25689391729],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'c63843d4-9a8c-47e0-8f84-203325ca5cca',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71474362181, 38.25707829349],
            [-85.7147419272, 38.25708128251],
            [-85.71474029264, 38.25708416563],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7147419272, 38.25708128251],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'c652b020-077d-4f5d-99cb-947f1fa8ec1b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7141402079, 38.25726579744],
            [-85.71413849896, 38.25726871969],
            [-85.71413683156, 38.25727157091],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71413849896, 38.25726871969],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'c65a0de9-c441-40ab-af1a-cef161867f6d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71476670275, 38.25694861077],
            [-85.71477159842, 38.25695030015],
            [-85.71477570172, 38.2569517161],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71477159842, 38.25695030015],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: 'c6a68570-5c1d-4279-a7ed-8c0b7ce9bb2e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71440471626, 38.25710825944],
            [-85.71440873494, 38.25710141577],
            [-85.71441251164, 38.25709498418],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71440873494, 38.25710141577],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'c72eb747-c314-41ad-9c8a-33c271e0ded9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458082948, 38.25691363171],
            [-85.71457772897, 38.25691906285],
            [-85.71457398548, 38.25692562028],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71457772897, 38.25691906285],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'c7d89269-b083-4cdf-a8bc-155ec4c42e17',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71459708125, 38.2568944433],
            [-85.71459478448, 38.25689851525],
            [-85.71459258514, 38.25690241445],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459478448, 38.25689851525],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: 'c8181969-c7cf-4cf6-b414-af245e7b7c64',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71468290593, 38.2568551164],
            [-85.71468739458, 38.25685666533],
            [-85.71469251046, 38.25685843071],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71468739458, 38.25685666533],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: 'c946e704-c2eb-4c3f-9098-f5f7c96860f1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458091825, 38.25684388434],
            [-85.71458442866, 38.25684509571],
            [-85.71458854975, 38.2568465178],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'cbb9153e-e6f4-4381-815e-9663e6c8ff1f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419816974, 38.25775612989],
            [-85.71420066034, 38.25775186774],
            [-85.71420318745, 38.25774754312],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71420066034, 38.25775186774],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'cc8de2e0-1a36-4dd4-aade-f76e72034df3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71463971438, 38.25703040879],
            [-85.71463744437, 38.25703432426],
            [-85.71463523121, 38.25703814165],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71463744437, 38.25703432426],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'cd2448cb-e25e-4fb6-849c-95c77adab724',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71377871878, 38.25817883889],
            [-85.71378289563, 38.25818026124],
            [-85.71378707767, 38.25818168535],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71378289563, 38.25818026124],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'cd5015a8-5485-431a-ac8a-879c9850408c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.714752927, 38.25699319792],
            [-85.71475634345, 38.25699439224],
            [-85.7147597961, 38.25699559921],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71475634345, 38.25699439224],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'ce0dda59-21d2-4e86-9934-f4d8a8075d61',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71391884016, 38.25831906598],
            [-85.71391008018, 38.25831601215],
            [-85.71390109557, 38.25831288001],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'ce2d0825-4fe6-4092-a7f8-7614a18927b9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71445304008, 38.25727762668],
            [-85.71445778135, 38.2572793231],
            [-85.71446224512, 38.25728092023],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71445778135, 38.2572793231],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'cf0a1cb8-720e-408b-b94b-1782e746f00b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71454788852, 38.2569018468],
            [-85.71454581823, 38.25690538164],
            [-85.71454351448, 38.25690931511],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71454581823, 38.25690538164],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'cf1047f6-c3ca-423c-b176-d1717b1638a6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71433500428, 38.25728038406],
            [-85.71433894691, 38.25727359644],
            [-85.71434286343, 38.2572668538],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71433894691, 38.25727359644],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'cf9b81bc-80df-4b22-ab63-b88826747bbb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71411293046, 38.25731010749],
            [-85.7141148077, 38.25730691018],
            [-85.71411667574, 38.25730372853],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7141148077, 38.25730691018],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'cfb18aab-86c9-4c51-a761-3992ba29ca42',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71419896631, 38.25716401432],
            [-85.7141947825, 38.25716250568],
            [-85.71419058697, 38.25716099282],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7141947825, 38.25716250568],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'd04ef867-a4ec-4650-8553-db173d368158',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71460779129, 38.25686496492],
            [-85.71460947834, 38.25686195039],
            [-85.71461109673, 38.25685905853],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'd0c5ddb2-5d1f-49dd-bb5f-a3802add7142',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71429479076, 38.25749375304],
            [-85.71429728743, 38.25748940644],
            [-85.71429985655, 38.25748494535],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71429728743, 38.25748940644],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'd0f32754-3655-4f4f-822d-c73492db6dc6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71461109673, 38.25685905853],
            [-85.71460947834, 38.25686195039],
            [-85.71460779129, 38.25686496492],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'd1192969-bdbc-48ab-be58-31b4e3ef6552',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71468470434, 38.25687497284],
            [-85.7146801894, 38.25687338016],
            [-85.71467610942, 38.25687194093],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7146801894, 38.25687338016],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'd2520038-7ead-4d5b-89f4-33a6b964f610',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7144560266, 38.25737745118],
            [-85.71445136889, 38.25737577753],
            [-85.71444670238, 38.25737410073],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71445136889, 38.25737577753],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'd269e1c8-4e74-48ac-9d38-646c0bdf67d9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71479516861, 38.25692591769],
            [-85.71479323523, 38.25692923805],
            [-85.71479129408, 38.25693257175],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71479323523, 38.25692923805],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'd2f66159-f461-42e2-8942-4fb9bfce244f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7145733223, 38.25685586487],
            [-85.71457777332, 38.25685738432],
            [-85.71458200234, 38.25685882798],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71457777332, 38.25685738432],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: 'd6011af1-3a16-46d1-ac40-c68060d2af52',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71445240233, 38.25697506433],
            [-85.71445473607, 38.25697083024],
            [-85.7144571609, 38.25696643092],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71445473607, 38.25697083024],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: 'd6963dd1-7929-46e1-8834-829d7271d123',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71434182219, 38.25772052152],
            [-85.71433946417, 38.25772454794],
            [-85.71433707959, 38.25772861973],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71433946417, 38.25772454794],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'd6aefd80-14bd-459c-9d76-9118712b8a30',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71424227026, 38.25725265057],
            [-85.71423813486, 38.25725119448],
            [-85.71423375982, 38.257249654],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71423813486, 38.25725119448],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'd6b07410-0149-4fc1-a072-058cddf39c4b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71455907899, 38.25681068774],
            [-85.71455444595, 38.2568090534],
            [-85.7145499819, 38.25680747868],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71455444595, 38.2568090534],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'd8733ff6-2220-4fac-9828-037f5b456f13',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71449092597, 38.25743832418],
            [-85.71449388899, 38.25743936299],
            [-85.7144967873, 38.25744037912],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71449388899, 38.25743936299],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'da9f0bd6-c2a9-471e-9782-e0b03022a5b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71418286421, 38.25743452616],
            [-85.71418096326, 38.25743795789],
            [-85.71417901187, 38.25744148067],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71418096326, 38.25743795789],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'dac04bf9-0ca3-45d3-94c3-aa28b8a1e519',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71410148134, 38.25736784704],
            [-85.71410615002, 38.25736944508],
            [-85.71411076591, 38.25737102505],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71410615002, 38.25736944508],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'dc39398e-5b0b-40f4-a135-d45e118da2a3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71417718046, 38.25720285029],
            [-85.71417543472, 38.25720583548],
            [-85.71417354962, 38.25720905896],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71417543472, 38.25720583548],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'dc66b2fb-efe0-4c5b-ba56-31903b640941',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7143966896, 38.25743035277],
            [-85.71440048752, 38.25743171301],
            [-85.71440444237, 38.25743312947],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71440048752, 38.25743171301],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'dc718824-40a9-4391-8edc-cddb0d55f1ac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71449112694, 38.25683503204],
            [-85.7144950063, 38.25683634874],
            [-85.71449847006, 38.25683752439],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7144950063, 38.25683634874],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'dd99041e-665e-470c-9a22-401b1524a2d4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71445316584, 38.25745222249],
            [-85.71446037402, 38.25745480414],
            [-85.71446903857, 38.25745790739],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71446037402, 38.25745480414],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'ded43d1b-61dd-4145-bbf9-c6b097e6e965',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71428002097, 38.25727009696],
            [-85.7142781208, 38.25727330465],
            [-85.71427610842, 38.25727670178],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7142781208, 38.25727330465],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'df3c3c10-b592-4f2a-9fbf-bc92d2ac417e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71450428436, 38.25691711647],
            [-85.71451529785, 38.25692085459],
            [-85.71452527251, 38.25692424011],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71451529785, 38.25692085459],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'df4aa628-edb9-4c11-a8f6-6d5a8ac589d2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71395804726, 38.25830877324],
            [-85.71396692524, 38.25831176685],
            [-85.71397580706, 38.25831476176],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'df593d35-de08-4ba1-90ea-11efd5282f45',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7145708081, 38.25701270432],
            [-85.71457768324, 38.25700680654],
            [-85.71458383826, 38.25700152653],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71457768324, 38.25700680654],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'e02cc05b-66cd-429f-9948-94c16c2d4696',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71408196528, 38.25775986216],
            [-85.71408376341, 38.25775675188],
            [-85.71408565773, 38.25775347518],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71408376341, 38.25775675188],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'e05f3e9c-cb0c-4145-962e-81c6cd68ea40',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71418823632, 38.25757670968],
            [-85.71418623003, 38.25758019756],
            [-85.7141840862, 38.25758392454],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71418623003, 38.25758019756],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'e363d49d-d2d0-4b88-bbba-4f6fe8875ffb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71400880299, 38.2580757035],
            [-85.7140065776, 38.25807956039],
            [-85.71400438918, 38.25808335319],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7140065776, 38.25807956039],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'e3ffc303-d795-488d-a59b-ea235b6f6355',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71450400751, 38.25747936642],
            [-85.7145057533, 38.25747628469],
            [-85.71450766302, 38.2574729136],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'e4456aa6-5e9c-4ed9-9598-27203c5bfca9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71397498012, 38.25827708549],
            [-85.71398585094, 38.25828075108],
            [-85.71399278704, 38.25828308989],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'e491bde3-bec9-48da-990e-7cf1c2f9acab',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71442478017, 38.25698545677],
            [-85.71442281425, 38.25698906166],
            [-85.71442100441, 38.25699238033],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71442281425, 38.25698906166],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: 'e5dd5797-30ba-4df8-a247-c809530feea6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71458004524, 38.256908642],
            [-85.71458141019, 38.25690624372],
            [-85.71458310171, 38.25690327163],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71458141019, 38.25690624372],
            type: 'Point',
          },
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
        },
      },
      {
        id: 'e62b566d-c1ae-4f7b-8fb4-93af889b43ac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71452316272, 38.25681470815],
            [-85.71451493747, 38.25681188783],
            [-85.71450525834, 38.256808569],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71451493747, 38.25681188783],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'e72739c7-9786-4a65-af94-7df6c3c492ae',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7141952128, 38.25729813039],
            [-85.71419118101, 38.25729665774],
            [-85.7141875392, 38.25729532754],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71419118101, 38.25729665774],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'e7ab98eb-9c7c-48a5-9651-c62f5cb86be3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71414634428, 38.25755459763],
            [-85.71416753023, 38.25756218374],
            [-85.71418860538, 38.25756973018],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71416753023, 38.25756218374],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'e809308e-e85f-4330-89f6-bd1d84cd75dc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71452238982, 38.25740106222],
            [-85.71451759416, 38.2573993809],
            [-85.71451278459, 38.2573976947],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71451759416, 38.2573993809],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'e944c3b4-55b4-472b-bdc9-af3a02de2555',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71446387073, 38.25693929112],
            [-85.7144605476, 38.25693809745],
            [-85.71445567434, 38.25693634698],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7144605476, 38.25693809745],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'e97907a5-4292-4c6b-9dbc-91ac41e6ed18',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71465231436, 38.25682350181],
            [-85.71465721994, 38.25682521547],
            [-85.71466225413, 38.25682697405],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71465721994, 38.25682521547],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: 'eab2a412-7d09-482f-abd3-1bc819a0bd75',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71439223424, 38.25742181612],
            [-85.71438679389, 38.257431119],
            [-85.71438179811, 38.25743966166],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71438679389, 38.257431119],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'eace54e5-dfd7-4f1c-ba40-ec3c26c3e64f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71415644856, 38.25763265741],
            [-85.71415845489, 38.25762916947],
            [-85.71416045396, 38.25762569414],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71415845489, 38.25762916947],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'eb760192-04f3-4941-a047-0c47530b1641',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71471381581, 38.2568675122],
            [-85.714723884, 38.25684943125],
            [-85.71472616651, 38.2568453797],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.714723884, 38.25684943125],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'ec9663b7-7a8c-43a3-8fb0-c8a023b04dac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71457020016, 38.25728779271],
            [-85.71457185056, 38.25728497612],
            [-85.71457351412, 38.2572821371],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71457185056, 38.25728497612],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'ed297ca7-895a-440b-8365-1a3de75ec25e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71407366338, 38.25741017937],
            [-85.71407189634, 38.25741320098],
            [-85.71406979126, 38.25741680061],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71407189634, 38.25741320098],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'ed866407-616d-42c3-874c-70a620b22abe',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7139287974, 38.25795689977],
            [-85.71393827655, 38.25796025005],
            [-85.71394764527, 38.25796356131],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71393827655, 38.25796025005],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'edbe0565-9f7d-4673-bcc7-a7fa68a6738d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71438057398, 38.25691139664],
            [-85.71437680216, 38.25691004902],
            [-85.71437249937, 38.25690851884],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71437680216, 38.25691004902],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'ee9be357-fa08-4541-94e9-4af8b8483dcd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71444112587, 38.25681814055],
            [-85.71443670322, 38.25681658422],
            [-85.71443269266, 38.25681517291],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71443670322, 38.25681658422],
            type: 'Point',
          },
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
        },
      },
      {
        id: 'eecefa36-74c3-4c29-8650-7311e2dabbad',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71424008196, 38.25722836186],
            [-85.71424368733, 38.25722967876],
            [-85.71424728326, 38.25723099221],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71424368733, 38.25722967876],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'f0b8971e-3ca3-474b-a839-9801597a1813',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71415968396, 38.25752817229],
            [-85.71415602013, 38.25753448134],
            [-85.71415228204, 38.25754091826],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71415602013, 38.25753448134],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'f0c22821-c0c5-4cd2-91a2-a007196c9fd8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71436220323, 38.25723907295],
            [-85.71436589666, 38.25723275725],
            [-85.7143696092, 38.25722640889],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71436589666, 38.25723275725],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'f147cbf0-fc76-4573-9668-701a66f224f8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71461181079, 38.25694961215],
            [-85.71460849038, 38.25694844291],
            [-85.71460497627, 38.25694720546],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71460849038, 38.25694844291],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'f179afaa-ae49-4e48-9744-f234af9e245c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71414484924, 38.25799440498],
            [-85.71415055837, 38.25799646102],
            [-85.71415613852, 38.25799847062],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71415055837, 38.25799646102],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'f27d66fd-973b-4607-8521-1a4863fc441f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71414213304, 38.25715115661],
            [-85.7141413218, 38.25715254381],
            [-85.71414011254, 38.25715461163],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Technology Product Research Employee Entrance',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.7141413218, 38.25715254381],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'f2ece0a3-c74c-4c84-8f8b-628a99736d50',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71394026392, 38.25846457268],
            [-85.71393805796, 38.25846841862],
            [-85.71393579416, 38.25847236539],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71393805796, 38.25846841862],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'f32567ef-eda9-47d1-8676-c5af25b314dc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71428495742, 38.25726251173],
            [-85.71428322641, 38.25726543387],
            [-85.71428139989, 38.25726851723],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71428322641, 38.25726543387],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'f37153b8-0140-405e-a28e-81df38b300f1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71451112174, 38.25738840354],
            [-85.71451301999, 38.25738522934],
            [-85.71451514656, 38.25738167337],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71451301999, 38.25738522934],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'f547d4d5-e32c-4ad0-af02-045648457dcd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71475525808, 38.25690591536],
            [-85.71475365233, 38.25690876562],
            [-85.71475199076, 38.25691171496],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71475365233, 38.25690876562],
            type: 'Point',
          },
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
        },
      },
      {
        id: 'f56b01ff-9739-4517-a76b-f80e8af0ddf3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71483210536, 38.25687995568],
            [-85.71483569177, 38.25688117295],
            [-85.71483934612, 38.25688241329],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71483569177, 38.25688117295],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'f6fc52bd-1619-4f7f-99a8-1c924c75a208',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71420977375, 38.25764331517],
            [-85.71420764237, 38.25764699857],
            [-85.71420552716, 38.25765065403],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71420764237, 38.25764699857],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'f7048a3a-7d5f-4b93-a5f3-1ddae926d92c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.7135701148, 38.25820709329],
            [-85.71357207259, 38.25820362701],
            [-85.71357397735, 38.2582002546],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'f7596371-9dd6-4ddd-b947-bc1f2a7621ac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71450400751, 38.25747936642],
            [-85.7145057533, 38.25747628469],
            [-85.71450766302, 38.2574729136],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'f7a07bb0-2c1e-4c2c-9b7b-30fbe8c0c162',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71413483522, 38.25797406367],
            [-85.71413900714, 38.2579668332],
            [-85.71414317432, 38.25795961093],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71413900714, 38.2579668332],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'f829b38e-92ad-42ea-ad60-e3acf000d45a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71459013739, 38.25682407577],
            [-85.71459418052, 38.25682547097],
            [-85.7145985962, 38.25682699472],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71459418052, 38.25682547097],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
        },
      },
      {
        id: 'f8413aac-7b94-4c89-8c65-59c37b12ee83',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71434635218, 38.2568184145],
            [-85.71434798462, 38.25681558556],
            [-85.71434952244, 38.25681293423],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71434798462, 38.25681558556],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'f87a9ef4-2ac2-4506-a65a-aa3becc1f728',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71444409538, 38.2568183655],
            [-85.71444036115, 38.25681709806],
            [-85.71443625568, 38.25681570461],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71444036115, 38.25681709806],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'fa4e6ec0-4139-4c0f-82cd-b1341575775f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71426481881, 38.25729643034],
            [-85.71426302038, 38.25729946629],
            [-85.71426112921, 38.25730265878],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71426302038, 38.25729946629],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'fab3e946-83a3-414e-926a-878b236ad22f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71434623648, 38.25764103579],
            [-85.71434999722, 38.25763462068],
            [-85.71435416586, 38.25762750977],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71434999722, 38.25763462068],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
        },
      },
      {
        id: 'fbce9bd5-60af-4363-86fa-276fa7d900ed',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71420572608, 38.25730220956],
            [-85.7142026029, 38.25730106879],
            [-85.71419771276, 38.25729928262],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.7142026029, 38.25730106879],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'fc5712c6-a61a-4187-a5e3-883b638d834b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71435183447, 38.25719460369],
            [-85.71434907416, 38.25719932376],
            [-85.71434640463, 38.25720388862],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71434907416, 38.25719932376],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'fcfcca1c-4979-4d2e-8ac2-3fdc33e3c0dd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71443820832, 38.2570426604],
            [-85.71444065939, 38.25703844065],
            [-85.71444293807, 38.25703451766],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71444065939, 38.25703844065],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
        },
      },
      {
        id: 'fd548d7c-80f0-47fa-820b-ffc6ef86a3d3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71421698661, 38.25735887081],
            [-85.71422562932, 38.25736198727],
            [-85.71423459316, 38.25736521952],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71422562932, 38.25736198727],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'fec29b60-d1f4-4124-8fe0-6024134d87df',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71479695856, 38.25690187674],
            [-85.71480090692, 38.25690321687],
            [-85.71480505679, 38.25690462539],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71480090692, 38.25690321687],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
      {
        id: 'fefb2975-d6a6-4136-9b6c-b5301afd609a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [-85.71411708345, 38.25822195901],
            [-85.71411534294, 38.2582250959],
            [-85.71411321309, 38.2582289345],
          ],
        },
        feature_type: 'opening',
        properties: {
          category: 'pedestrian',
          accessibility: null,
          access_control: null,
          door: null,
          name: {
            en: 'Emergency Exit 8',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71411534294, 38.2582250959],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
        },
      },
    ],
    relationship: [
      {
        id: '0067eb2f-a2db-424c-bc7a-4d0321551319',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '68f93540-51e2-4706-bd3c-f261bc96e375',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '16c07e5b-c31e-41e0-b2d5-078536235b19',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6cc945f1-9d6f-414a-93c2-885640b5a3ce',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '00d4ea46-121e-4c4f-989c-b8ee6eb1f4c1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'af6f9b00-7118-481d-a52b-63ac0681f9b7',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '24e7d277-5d44-46d5-a9af-f25248070ec9',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '8d82d311-3070-4219-a9df-d1fc2f0269f8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '010fd791-39a3-40e3-99b2-9571b19a6167',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '7b4f925d-6346-4dbe-814b-ecb8b0210c62',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'dac04bf9-0ca3-45d3-94c3-aa28b8a1e519',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '01367c3c-6964-4a87-a611-e25c7c7c7aa6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '46908382-db94-4264-9e2a-1afac5167f5c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2711a474-c030-4e27-b58d-4d2e5e8c0a2d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '2f847a91-bea3-4ae4-b170-aa41f6f49d23',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0155b648-64dd-45e9-b614-2f7d0d97f51f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c79e749b-905f-4145-a328-9885fb6e26b7',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '75a30851-dcdd-4fc9-90eb-2893ddc2297b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e1d355d0-3ece-4ac8-8a66-79ac38cbee87',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '018732c6-369b-4d4a-9644-dca8eb1c31a9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b8f68e36-e122-4679-8634-1e7ec5f57f73',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '149f23bb-30d6-49ef-bbb1-8cc4e6d0b65e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1be6f9dc-f784-4de2-a7af-2f70b285889d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '029eb8d0-cf2d-4f3c-b9ff-76d04393c818',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3748db0b-19db-4865-b5ce-b814270a72ae',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'cc8de2e0-1a36-4dd4-aade-f76e72034df3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd72c5c36-a673-43ab-9dbb-0df85732c930',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0311b8a5-02a2-4628-a27a-2cb58cde8b30',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '9cf1f0d6-1896-4d27-84e5-7c30c85cd0b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c0587cc4-9a96-4d56-8cd4-fdb0ac3c0880',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '04b37887-4689-4d58-91d8-bf0dd72b88fc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a654bcf7-5121-4909-beac-46cab5098bb8',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '208855f9-b405-49c2-aca8-bb20998e6405',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e1d355d0-3ece-4ac8-8a66-79ac38cbee87',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '054f0d5e-a25c-4d8e-a8c5-743b3dded1cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f0006594-2259-4a5f-bfdb-1280512dd51d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '25d530de-78a9-41e4-a3ee-a3bfe59689b6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0590a55d-fd1d-4cb1-bfdf-a21f0b5dc8d8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6eb01642-b7f5-49f1-9d06-7fed05c47e51',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '7a6f018d-ebe5-4c0a-baeb-06701d5db1ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '05a90407-cbe4-4a8a-a4b9-31bde60c70c8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6cef7247-93d6-4650-bbf5-76fb0ce66d5d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f32e7b5f-d54b-49a5-bb82-0949ebaaf30e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '05bf495d-9d49-4451-963f-2bd8cc6a3d8b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '51fe2e8e-7738-4c20-9df5-3f3d9a8fa1f2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'b34ec29f-91d2-45e2-b95f-aaef9a308f58',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '060781d8-c7c7-4445-856a-991ad4e51ab7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '89425ca3-97dd-4b0d-9cf0-39b35617f197',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0d622bad-ad21-4edc-9f4a-a442de79b145',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1532027c-1934-450c-8aef-8427bc89c42d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '07082ede-17db-4df0-bd61-d9ee48983c98',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4951a6b1-e67a-4b08-96fe-f8910a201683',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0755a978-29e4-4cdc-b28f-9a5be499c710',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '68f93540-51e2-4706-bd3c-f261bc96e375',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'dc66b2fb-efe0-4c5b-ba56-31903b640941',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0786fd84-220c-4682-8e44-7a7c6db99bb0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '80ed6018-9285-4685-8d63-4c126272aea4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6108e94f-aa27-4393-a11c-47d3653c303b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '07ab3895-0525-4dd7-9356-ee17fa08e221',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '34048ca9-f7f6-49e5-9761-b52af1f538c8',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '872bacf3-56d1-4e07-82ae-a036d1119190',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '07af1a92-0283-441e-836c-000cd9efde5c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1ea95200-a2af-4b0f-824a-40f1ee423521',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4ede021e-3743-4eda-8cd7-711aa9ad4017',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '07b77bb9-cdf8-4964-b366-d8360fb351ac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'directed',
          origin: null,
          intermediary: [
            {
              id: '0bcbbb76-6e0d-4c23-b3e6-98dea3fc8ac8',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4a20c995-8663-4805-beb0-69da66f428e9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '07cafce7-5c3b-4743-b61c-5eab1fb3c090',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '1401317b-f259-4dfd-99a3-80af0c936c8b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '08c4a21e-f494-49c9-9fd4-9906ac40f4f1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'ecd301ed-0f81-44ac-8596-d0ef6b2f4682',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '00fa66e5-26af-4b04-8416-bafdfa294a6e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '08ea9dfc-a7e5-4594-8693-531318cbade6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '62c9167e-1e88-4a5f-8813-d48465a4c2c0',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b1f2cf46-9ea2-491e-aa84-34487d3dea95',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '09d26a57-1eab-4b12-8580-5cd3030247c1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ac6eabea-37ea-41cc-9a74-6c53006224e2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'bed852f9-9a8f-45fb-b828-30b779eac9ec',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0a6611f3-3b8a-4137-9c49-a67c44461967',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6a125fc8-da1b-403f-89be-c615f9424b8f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'cf0a1cb8-720e-408b-b94b-1782e746f00b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'bb271af3-0a0b-46a5-b586-0a1a80fb4b7d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0aa973bb-9b08-4111-ba87-ab7d15944aa1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2b1540d3-1afa-4e4c-ae83-4545953b2230',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '90f3e35b-f742-4460-ac5a-076350528210',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0af22009-6020-429c-bb74-11c1f28fad9a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '7104e5dd-3e5a-4bb8-b6ef-c3eb3065cfa5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0b3b1c99-d014-4ae4-a76e-095da494dc25',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd548a622-526a-4fd2-aeab-a54a1ae5e070',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'cf9b81bc-80df-4b22-ab63-b88826747bbb',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0cf3159e-f9ab-4f39-83af-d08b5401a102',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '7d778c79-ac63-45ac-84ee-8fdd7c488a70',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '1de646de-e4f9-443c-91d9-7a1b94e5c6ee',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '269935c7-cd96-4127-bb56-6902ea5ada7e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0d158389-1ad3-4235-b880-0af5e679eb3f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '483db33d-b7f4-4236-b442-711798484f9a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e963e028-ce56-411d-af9c-e5b7a77583ac',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0e223251-b9f6-4d3c-a61c-a97fcc03d45e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6786fbe0-ea91-4b42-929c-6c0ff930ad71',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'bb4f9823-a76c-4acd-b357-95e92b402d74',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1cca5204-7fb2-4b39-aa6e-19f9555fa32c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0e86d774-1179-44a3-8e66-c33faa4bb1af',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b1d5194e-f81b-48e4-bc5b-31ec1f18d177',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '81d4553d-a1ec-4b05-811c-15603b41022c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0fa5490a-f806-4b8e-a6c1-bd2a5154ab54',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2ccf9a6d-c4cf-48bb-97a0-a37856ad8dd3',
              feature_type: 'opening',
            },
            {
              id: 'c67c8db0-2bec-4b92-a6c5-39141eb5b291',
              feature_type: 'unit',
            },
            {
              id: '029e455f-a9c3-4eb5-974f-badff5e174ee',
              feature_type: 'unit',
            },
            {
              id: 'a2311953-7ac5-40aa-81c0-fb7452393d3f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '526bb2f5-25f8-4d13-b4f3-faa18a0e5e09',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0fc4e184-09e4-42f2-95ac-0f4e923577df',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6860a359-c24a-42ca-9eef-eccedef98cf0',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '160218be-551a-4959-8077-908e4a87eb91',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '0fd4ad5b-f8b3-4e3b-9627-34e0c46e03cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4b5df6ca-60ad-4c30-9fce-e49ac72031e8',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c72eb747-c314-41ad-9c8a-33c271e0ded9',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'bb271af3-0a0b-46a5-b586-0a1a80fb4b7d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1002e17b-c36a-43da-b00a-d6c67b5f688c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '9de5605f-e010-4d24-b06b-ece61bf4b400',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9ad4d3ca-e306-4c9a-a0e2-72343f88d9b6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ccbc084c-7217-4d8f-88a0-13a84d3ddb8b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1011ebcc-3377-439e-878f-c94380d31e92',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c40f7f1d-63cc-4e20-86fa-1112f5f958fb',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '98c3bf35-61a6-4de6-98eb-176e47df9a39',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '113d33e7-2325-44f5-8627-4479bddcc6ef',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3748db0b-19db-4865-b5ce-b814270a72ae',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a38ed8d6-27f0-434b-99cf-0c39820883b6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'de85e1f5-dddf-4f4a-98c4-189446a8c9b0',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '11fb0a8c-c526-44fa-8fae-eac404351c76',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '526bb2f5-25f8-4d13-b4f3-faa18a0e5e09',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a2311953-7ac5-40aa-81c0-fb7452393d3f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '029e455f-a9c3-4eb5-974f-badff5e174ee',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '125a46da-f646-4a98-b2c6-ca97a2212d96',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'ecd301ed-0f81-44ac-8596-d0ef6b2f4682',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a6333a66-f72e-4074-9d14-1e5ece7027f7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1376369b-6564-4f2b-a83d-8267f5a59679',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'eecefa36-74c3-4c29-8650-7311e2dabbad',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'dabebd92-cbad-4896-97ba-6c90214436d6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '142935d8-c138-4ecf-b06b-380d5ffe246b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5d5873c4-44cf-46e5-aa17-812b01ff68ef',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9608a558-c267-43a1-9897-b0f3e84b0db1',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a654bcf7-5121-4909-beac-46cab5098bb8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1520b32a-4185-4e80-a354-34597a9c3356',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'ecd301ed-0f81-44ac-8596-d0ef6b2f4682',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ba53e4a8-5a6e-40d5-a51b-969b088ba327',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '879ee864-ae73-42ec-9da1-fe3314445ef0',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '16240dbb-e383-45a7-87e3-7da1e5bfe05d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a3d4f31c-e6b5-4a9f-b119-c9ca9a3262dc',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '88d39f01-37f9-454a-bc48-8562b533affd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '16a8fdb2-8f9b-48b8-8597-221357610a54',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a2f2db51-a011-476f-a34e-6a37dcb8db3d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b37d170b-f2c7-4cd1-b06d-54812c04c374',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '2c3b3b14-6166-488a-8f74-43efa50af1c2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '16f42908-ca7b-4fcd-8c81-c95c58fe6adb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '7f930d16-cb4f-4127-afed-75fcef865343',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a050a15a-cfef-4b39-9467-c06646694000',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '175237cb-2a10-4528-937e-1fec5df349b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2c9e1088-0ae9-4bc4-9bff-a76f823671c4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '5d5a6114-9f3e-4125-befb-a3dab0092dde',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '17715d5f-f431-4a66-9cd3-21e5e27c901a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '05976d23-b267-451c-bcf4-004ec7611b2d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '17efde72-3f04-4b6a-ae51-e916da1a3245',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a38f1727-a39a-44d0-a1bc-0e850157061a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '029f02ef-f923-4838-8373-55e98625a03a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '18093687-b0fa-4c6c-ad60-f41fdceab404',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '7bb3ea74-3966-47a9-8e7f-0c85d9daa3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ce0dda59-21d2-4e86-9934-f4d8a8075d61',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '2c55d857-482f-4585-ab7e-7f04a282a1e5',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '18120f53-b1bb-44a6-8c88-571bf74546e4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e4456aa6-5e9c-4ed9-9598-27203c5bfca9',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '72cc4217-e156-4ab8-a52c-66ae91ee9f9f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '18450478-7b69-48c9-9e25-749a17b0e72c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '829f7d6b-c25b-4866-8507-0d5f14bc69c6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8d3f1c89-dc02-4517-b287-3de644df677c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '188d7042-6764-4500-ba0e-1a60051cbbad',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbd13c71-37ae-40fd-8540-20e1c3832867',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8cecf63f-126f-4932-af9f-571e9160b7ee',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f1009b8c-499c-488e-978f-cb89455df024',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '18bc74e9-2f1a-462b-bfe6-2391bc0d6d60',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b2fe075e-a509-4656-b4dc-1bbce64ab179',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '30b01226-2965-47f3-a8e2-7979d4faf1e2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd72c5c36-a673-43ab-9dbb-0df85732c930',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '18d0a90c-4159-4ce4-81a9-85d1ed409088',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '9eed3702-98dc-48f2-9318-0268c812faa5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2b9ae91f-af30-4365-a877-f8e8f0ec1def',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'abccbae5-fd37-4b92-a70e-b82bdc309489',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '197ceca4-f100-4455-8725-7a48396196c5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '510c2077-ec97-4ab7-8695-f70672a2a93c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '53b9458e-9d76-4c52-90e5-a7f41ba91d08',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4423aaf0-a9c2-475a-84c0-0789d98de29c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '19b20ba5-4194-45fc-b379-513f57947bef',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e8a12e2f-76d6-4bdd-98cb-72caee307b23',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8b2a0356-eabd-4843-95c9-7d06d2750af5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '526bb2f5-25f8-4d13-b4f3-faa18a0e5e09',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1a2dd196-8e4b-4e93-bd05-379d21eb6b54',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '16b670a6-3528-4d3e-963d-9895931e9cac',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '027b31e3-0ec6-4ee4-a5ff-e873b2750396',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1b1b8033-87ab-4d29-b46d-67d770e1a0ae',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'directed',
          origin: null,
          intermediary: [
            {
              id: 'f27d66fd-973b-4607-8521-1a4863fc441f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1bd61824-bc50-43c2-944a-97e55a5466ad',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c4283b06-924b-4719-b8a4-9ec9fa2b341b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'dd99041e-665e-470c-9a22-401b1524a2d4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1bf116d9-135d-4c42-9a87-cb26e9f4240d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a4e6dc17-4dd3-4aec-ae56-e8a40b4d3251',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1d3cadfe-4874-45b3-a942-146a31ba1a9a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f87a9ef4-2ac2-4506-a65a-aa3becc1f728',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '9e72e37c-1f71-4b48-8b80-2ed2ed79361e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1dae5243-4ed8-402d-a5fa-0516678dd54f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '79c0e444-7f59-407c-9aaf-9e76a7382b30',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ddd944a0-e87a-4e4e-932d-89e635fa7565',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1dbe1644-2f10-4eae-938b-9eedf292b3a7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fa991bfd-1db3-4589-8725-178300eb5647',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '40488c0b-e9d8-4785-8c3e-09ccbe79bcc9',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a2f2db51-a011-476f-a34e-6a37dcb8db3d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '1fd8a7fa-feb5-4a60-9ef7-67b3316cedd9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'dee8766a-88c0-49e1-b7a1-d2e46ca21211',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '323eb15b-de26-4d9b-865d-0e8e9c3bb44d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '203b75b9-c0d4-44ba-926a-558a800c77a0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '59c5b0fd-6652-4ee9-bc8a-354177cf58f5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4579722b-14b5-44ad-b243-940f03ae16a2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '208b20c6-b9c3-4c1b-8d00-e5c9bf2fad2d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'fab3e946-83a3-414e-926a-878b236ad22f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1b9e042e-1343-46d2-8cb6-7816002a2d5e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '21e79028-f2dc-4f33-b1df-aa8d87f3fcd9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '7bb3ea74-3966-47a9-8e7f-0c85d9daa3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a6142d11-3da0-4153-b097-4e2f8c3d6a2a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'aa6ca436-f3e9-4538-81fb-8bacd7591194',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '23785965-fc68-4b66-959a-89ef29cb217d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '52ed0fef-5aa6-4f76-860e-e7b8e5c5b1af',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4df15197-876a-4cf0-9115-e8d6aad507ad',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6aa495d5-e7e4-4415-a0e2-ba40632d319b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2547d2d1-c523-4f66-a861-c796b88bd8a1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '8eecce7c-51d0-40a5-a505-8eed7d236912',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '81427734-90e7-43ca-a80d-85a5a09039b6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '89425ca3-97dd-4b0d-9cf0-39b35617f197',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '258dca3f-3ca8-4ee1-82c1-5c70d281c31c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3f94dcfc-e4ec-4cde-aac8-cabf95e718f7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e01f0f5e-c905-4afc-b0f4-dd4cadef52cc',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '26bbb30f-9008-493b-9223-4b8fc3936568',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0a2fef74-d8bd-4baa-bbb6-32af2ffda356',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '88f60a06-70d4-471a-b4e8-ed427eec135d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '26e6bb98-f331-4ae7-a2f2-71744d978b9e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a552838f-14e9-408a-a42a-dbd18ecf7ed3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4e463870-4937-4c89-beb2-56aed4605343',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '20ddff7d-1518-4b5d-b198-75de64ad5900',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '283ade87-918b-4a03-ab9b-e45952b8e7e4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '385e839d-4a00-43c9-b531-eaffede33632',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '250c49d0-1363-4a30-94d6-e8e5955d44ea',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2852f0ba-a6fc-4a84-a25c-273c7b075b53',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd6b07410-0149-4fc1-a072-058cddf39c4b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'b34205df-ac72-4db4-9f43-c8b1010838fd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '294905b5-67db-48b9-97b4-eb942cd811e5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '02b15a0a-9726-48a6-954f-accd965e4a75',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9a5c5dc4-8017-4316-904d-6653125763b7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2b049a8e-87c4-4d53-b865-7504972aad74',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4fd82952-2ec8-47e1-add4-d4edc27df3e1',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '82cadfcb-115c-4a60-8c31-b833fac9f1de',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2cc02f11-c40e-4d24-b84f-99457987f7a8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '03bbe2f5-7dfd-4236-8fd8-a38c7578da8a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ed866407-616d-42c3-874c-70a620b22abe',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2cf0483c-d769-4150-9b92-e01d556aa505',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '008ee6df-b92e-422f-8208-426dd7968e19',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e7ab98eb-9c7c-48a5-9651-c62f5cb86be3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2d3d759f-b72c-4517-a45a-4a7e02abc74b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2ce5ccac-44b6-44f7-a426-e85c0c61a112',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4192d42b-5976-4269-824c-065b68a0578c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '86762cac-e648-470a-b0e7-8e763288fc0c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2de18130-f3df-45fe-9ec0-f969d64fa0aa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '0837601e-b1ac-4573-8cfc-e488953a2a27',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2e0031b2-11be-4324-9200-46cbb9474b45',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5fff2542-6006-41a4-b2a8-75614faac3b6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '87db6de4-c145-46ef-a2b7-aa5c6a1630b9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2e62ea8a-5ee3-4aeb-9237-c4af8bf2ec88',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9fece2e6-2bb1-4f29-a044-29c71ec37f9d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1f9ef7bc-2f5c-4e2d-a91a-edb89cc3cfbc',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2f40c282-4044-4c68-8ee6-58016a9fa2dc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bf11f39f-0093-49ff-bb0a-b63e26b94e04',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3714dec5-eb1c-4d59-aab9-3f6fa9ba5183',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '86762cac-e648-470a-b0e7-8e763288fc0c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2f419738-580b-4074-88f5-a9b1edbbd3e4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b6cb1535-d0a0-4edd-903c-e6e96ed53061',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'fcfcca1c-4979-4d2e-8ac2-3fdc33e3c0dd',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '2f953f2b-a8cf-43f9-a6cb-076123e27ad3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c946e704-c2eb-4c3f-9098-f5f7c96860f1',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'df508559-586a-4535-9201-58e46a132fff',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '309444a9-9584-454d-bb80-e00f0e3d87ea',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbe4e96d-db1d-49ff-9ae8-233e26f6713e',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a205ae3f-31bf-4c28-8de8-e4a3c4ff821e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'abccbae5-fd37-4b92-a70e-b82bdc309489',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '30bf6375-3f87-4588-844e-964021e9638b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f75b6577-1ee9-423f-bb9f-5d57b172018e',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8157ebf6-d91b-44fc-8b8a-184bab9b58ab',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1569cae1-ae6e-468c-b5ff-51dd7a3c82c2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '30ed693f-98eb-48d8-8ced-f9cc61ba1901',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5e6d7cfb-756f-4acc-8488-4a87b93e6cbc',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '644389f3-2236-453e-b858-eddc29126689',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3266e849-5b17-4521-bbc4-fe8ac0291c85',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e72739c7-9786-4a65-af94-7df6c3c492ae',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '7006d00d-ee18-46df-8993-b467b4ee3d72',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3286eb61-ffb6-47fe-8e3b-af56784d657c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '03bbe2f5-7dfd-4236-8fd8-a38c7578da8a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '02aa8a26-70c3-46b4-9e38-b7b8791c6a9a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4579722b-14b5-44ad-b243-940f03ae16a2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3356ec67-5edc-4b49-8b53-d9ee8c540461',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '64ef1854-9dde-451f-9d43-d65d9775cce4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'be6e35c3-2cdd-4403-9ad3-0deb67d46af4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd93f7bfb-53a2-48f4-9b98-64cafade5fd2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '338a02ac-94a3-4acb-ba5f-e14e33d35397',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4680fed8-3e15-4cbe-80af-21ea90753c0a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '75566b6f-a67a-45b5-a4fa-b51f6199197e',
              feature_type: 'opening',
            },
            {
              id: 'af0c6b22-1ef1-49ce-b87e-30ba19f41565',
              feature_type: 'unit',
            },
            {
              id: '3bbc1d6d-90f2-4960-af9f-9095f66b3198',
              feature_type: 'unit',
            },
            {
              id: '7caff4a4-6ac3-419f-8642-0d713a38b512',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '89425ca3-97dd-4b0d-9cf0-39b35617f197',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '33ba22fa-3904-45c8-8fdc-811564182747',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '650e5f46-f1e8-4013-af09-fa8b1e98e998',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b874e7a3-5435-4ea6-8dc1-ee57df1b4770',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3748db0b-19db-4865-b5ce-b814270a72ae',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '33c6c156-64d5-4ded-a5cd-b4a0960a8a27',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '1f80c71e-f268-46cd-bb61-03ed72cded3d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '57f40a74-df0a-4a81-ada0-7dff6d9525c9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '33d89bda-d338-4aa3-8c09-3cb6ac51835b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '160218be-551a-4959-8077-908e4a87eb91',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '110b50a6-3eb2-4934-8ba6-c9f79fb24566',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'b34ec29f-91d2-45e2-b95f-aaef9a308f58',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '34bc2367-8fda-4ae2-bf32-b2c4d8807f78',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4680fed8-3e15-4cbe-80af-21ea90753c0a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '468ea26f-5549-4665-9295-f32a9fbcae08',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3711a83c-441a-4a64-9fd6-0392ac73d037',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8a95349d-baef-4cdf-93bf-0cb5d6d2c614',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4f3eb8dc-67c4-451c-85ea-909d70290095',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '387ca6d4-4c57-4196-95cf-a7efd49999e1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '941a3fb4-6bd8-4e05-b1df-0ead7928836d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c3496e20-cc11-4002-85a8-bd00fd72b48f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '38956af8-6e5e-494f-95df-5ead866b7979',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '21938c0f-3071-4f41-a822-ff24a6d1d572',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2ac95f21-9784-41cb-84b5-6c1cf512f253',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '25d22dce-852f-4e21-8335-d743c28ed2df',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '399845b2-29e0-472c-b933-03baad5e5d7a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c4283b06-924b-4719-b8a4-9ec9fa2b341b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e809308e-e85f-4330-89f6-bd1d84cd75dc',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '39c384f7-5f50-45b7-8d2c-e1116cd951d1',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3a9fd1b2-361c-43fa-a19b-7b1fdd306111',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6d44801b-5831-4a93-9ffc-8ed34ae55d07',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '7bb726a8-d17c-4063-b9ae-55e4ddc4c258',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'dee8766a-88c0-49e1-b7a1-d2e46ca21211',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3aa2807e-4592-4faa-ae4a-77e558e33593',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '012cc231-5afd-4bce-84cd-b6130df91cf8',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '850f8acc-f4a3-475a-bdac-0ee993de153e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3ab6dacc-1fc3-48fa-8629-63e499f5b512',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'abb93d9e-da6c-43f5-a23f-fc360b5c19e2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8e21d766-a3f2-43fe-a64a-ee5739cf4ba1',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3b1ec136-5301-4934-9fb0-e3e98f5d8e92',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6feeee39-f05c-4146-949c-ab057920e5cd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2b55c2dc-15f7-4c09-99ce-db01f66cbce4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '157bf662-5ced-4ccf-8bb4-f0c5e44c7721',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3b25df51-5e8b-4abd-b9a3-eed37cacf98b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '68d26820-3365-4fd9-a112-b66ac647513b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '95dd916c-e902-4938-a944-d9e4dda224db',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3d276d8f-2fe7-4def-8018-a185bd2db88c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '49e51b4f-274c-469b-b07d-d4a280f95cdb',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'abca0475-d5e1-44dc-bb10-10aeabf62d70',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '59cf144d-0a52-4809-8a2b-e3610fba1664',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3d850c12-d235-4d7e-9630-0761c6b7f2d2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4e0d1cb2-ff21-4699-8efb-e2ea98eb2b69',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd3f6fffd-c44f-472e-b2b9-ca9e4064d191',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3ee0ee9f-2296-4789-a5d0-e2a3c7f1f95b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '20ddff7d-1518-4b5d-b198-75de64ad5900',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2ac4fde4-d03c-42fb-b847-9c76346f7dd9',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '3f48e157-83c3-4321-88e7-c5b3f0b756a2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '17cf2c9c-e59b-4272-9069-f162db937dfe',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: null,
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4133693f-396d-4456-a8f7-549dceec22e1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e38ec9ed-0ad5-4d0e-8f74-11ec36acc454',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c191ac4e-bd7e-400e-a088-1ba7dfd8e5d4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '93f65377-853f-4e9f-9a06-c58b84ec749e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '415b59fa-0331-4b7d-8108-91fba2ad5d9f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '454392d1-4734-46c5-ad26-5e29b945e4b3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ed297ca7-895a-440b-8365-1a3de75ec25e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '42447d47-85b0-4c11-8aa0-bc2ceb06386b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '53d90438-b9cc-4103-a680-e847c3337b29',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'c0b0cce0-585a-4133-a2a7-5c952cf5942a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '425e172e-9d98-40fa-9dbb-1858c0068bbb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0617b6fe-f141-4798-808a-d9063e7f27b7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '2fe220ea-1999-4508-ab2b-35387b3a2d86',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '433e1e87-5e89-4883-894b-6f45741462b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd72c5c36-a673-43ab-9dbb-0df85732c930',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'df593d35-de08-4ba1-90ea-11efd5282f45',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f2602946-9666-4195-9ae1-2e1b5bceca2e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4380febd-e134-4c05-aff8-51a097c784e1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '155fe23d-9abb-4559-ae42-928a0aae7f2e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f1598ae5-83e0-411c-b62c-6d1b003d8e63',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '443d9135-beee-4eae-91d4-e0044475e416',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd407c6e5-ce76-485a-be6f-20d185505e1b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3ba826e5-60b6-466d-9c05-958bbda51579',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3748db0b-19db-4865-b5ce-b814270a72ae',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '44e47ee1-185b-4179-8b00-0aa77f106076',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6283443d-6964-48e7-a91b-ed8b499c2c54',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5ec00921-dda8-46ca-9e3e-c08346acf98a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '5d5873c4-44cf-46e5-aa17-812b01ff68ef',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '45abcd5d-3051-455f-a567-8d124b484de1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3e8e09bc-bf95-4f33-b541-61457b84c0da',
              feature_type: 'opening',
            },
            {
              id: '83961642-0ffb-48b2-8426-772032b4a0e9',
              feature_type: 'unit',
            },
            {
              id: '38fe3bb8-1512-43c6-9224-cb045c4968d5',
              feature_type: 'unit',
            },
            {
              id: '967c1e35-73d1-4fcf-bdc7-3185fc6d946e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '46aebce3-95db-4ba8-914c-f8792feb7885',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2df4b8e7-8693-497b-8214-a7285c3c1d82',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3239f3a4-afef-4349-8685-de1f16bfd378',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a38f1727-a39a-44d0-a1bc-0e850157061a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '476f9240-0eef-436d-9f8b-5d6bf1372a88',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '21938c0f-3071-4f41-a822-ff24a6d1d572',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f37153b8-0140-405e-a28e-81df38b300f1',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '84204b50-8437-4059-8bde-fa59342f7342',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '498bd65c-35fd-413d-986e-703bf4e81123',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2ccf9a6d-c4cf-48bb-97a0-a37856ad8dd3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'c67c8db0-2bec-4b92-a6c5-39141eb5b291',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4b0149fa-41d6-41a1-82be-1cdda0b6539e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e8a12e2f-76d6-4bdd-98cb-72caee307b23',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '34252ece-20c4-43b8-8399-ffa5d5312ef3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4b797854-a1ac-42bf-b01a-48ea7fe4ff45',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '83de7593-21b2-493f-8020-455b33206988',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1d7d887a-4d9a-42eb-b03c-9523041370fa',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4bd98735-d86a-402e-b765-17c4736d2525',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6680ffe0-4546-4c27-ab92-529eaf70c94a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'dc718824-40a9-4391-8edc-cddb0d55f1ac',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4c65c578-4609-4174-bc83-f6c50debe3fb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f3ea4f73-f1eb-456c-af5a-4372b2076a8a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3c676dca-9796-4320-a676-87d4ca2ac915',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4d08cd2b-4d09-436f-ae5b-d961a8eb35e6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a2f2db51-a011-476f-a34e-6a37dcb8db3d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '104bd81f-3b27-47b6-ae82-bb814d6e9b25',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '50ac65dc-a842-4d2d-83c8-0e63236633aa',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4d74fc0d-92a7-4cc6-831e-6a535cd32722',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '80fc0a17-8bd2-43cf-85ac-d0995c6f82a5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5e594a73-5d8d-4931-8307-b726e01d786f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1ea95200-a2af-4b0f-824a-40f1ee423521',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4d8ee215-1886-47d5-986a-898e159a852b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c946e704-c2eb-4c3f-9098-f5f7c96860f1',
              feature_type: 'opening',
            },
            {
              id: 'df508559-586a-4535-9201-58e46a132fff',
              feature_type: 'unit',
            },
            {
              id: '83961642-0ffb-48b2-8426-772032b4a0e9',
              feature_type: 'unit',
            },
            {
              id: '3e8e09bc-bf95-4f33-b541-61457b84c0da',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4f0ac49e-3ca7-4401-87a7-a014add4aae7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '526bb2f5-25f8-4d13-b4f3-faa18a0e5e09',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8bc9c7ab-d296-4a22-a8ff-a687de306a8c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a838a9cc-e2c8-4319-83b2-88163bfeafcb',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4fa6f524-0135-4865-b334-6833fa52d6f4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '50ac65dc-a842-4d2d-83c8-0e63236633aa',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f7596371-9dd6-4ddd-b947-bc1f2a7621ac',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3d936666-16f6-48c7-9e3b-314b1e744729',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '4fe072e4-0c13-46f6-ae1d-280dbd788693',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c4283b06-924b-4719-b8a4-9ec9fa2b341b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9f7591ed-b72e-436c-8d2a-9b610d8e340f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6cc945f1-9d6f-414a-93c2-885640b5a3ce',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '50737b10-449b-4f3b-9b0b-da03e06ff3d0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1ea95200-a2af-4b0f-824a-40f1ee423521',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3472e2ae-f8c2-4e5a-8528-5c829bb1749c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'db3a98b9-8433-4f7d-8aa7-a5120391f1d9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5082b831-28cb-4157-9f2b-9e3c00d66b29',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '21938c0f-3071-4f41-a822-ff24a6d1d572',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '92772389-7bbd-4e9e-a4fb-bbbdd3584e95',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '332efe06-8072-4a3e-9c63-8c82c1cc9533',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '51b7c921-aeb5-49c7-aef0-34919891bbab',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '568a54a7-7deb-421b-bfe3-9552879254ea',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'c90ccf70-7dc1-4f60-90a2-f6fbd8a7774d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '52078ffb-f49a-41ac-a3fc-e68649c8e8ed',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1be6f9dc-f784-4de2-a7af-2f70b285889d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '534becc8-d46d-49b3-8410-b0f92b12749c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '869b56d1-5b96-4845-9e5e-ef37e83c436b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '521b1f89-4a1b-4afc-a39a-3fb5986c0737',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0b1a9900-88fa-48f7-b9f6-d5ce767c995c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67f08e69-9079-49dc-8f40-244b5d2c04f1',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '526ffc9e-e232-48c7-a52f-38420c84121f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd72c5c36-a673-43ab-9dbb-0df85732c930',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5c11d2e0-049d-4b51-8e4e-d75f57ea69e7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '0620bad9-8d47-49c2-9cd2-6ab08412efcf',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '53906b44-e181-4059-ae29-5d3f14048772',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '526bb2f5-25f8-4d13-b4f3-faa18a0e5e09',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3726a7fa-3ad5-4db2-bdd1-d8fc75e8f352',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'abccbae5-fd37-4b92-a70e-b82bdc309489',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '53d30490-0212-44d4-a1da-d7add4edfd9b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6b9fbb15-7fd6-4fd8-9136-22fd724a6459',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '47e2718b-076e-4be5-a430-a9c1de4cb411',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4a20c995-8663-4805-beb0-69da66f428e9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '53ea96c2-74c3-40bd-872f-c19d49c68604',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e6ff4062-4c67-43ca-879b-394f38090ef0',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '92c82f54-9d19-4602-8090-0e9f0f898408',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '54e22123-0c96-46ca-a295-1b498ba70fb9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '508d705d-ea0c-4dec-a2d3-41ec88a9c839',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6b68a78a-2ad5-4fcf-845f-6dfac072d4fb',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '554629eb-9de2-4993-bf87-163845ed1cc5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '557c513c-cbc5-4075-8d2e-4db1db635b15',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '86762cac-e648-470a-b0e7-8e763288fc0c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '55a511f3-20cd-4823-9109-1ca7d3d34182',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '1e01848a-da84-49d4-9f5e-c71036723b32',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '55e72564-db3f-423a-9e99-4da1bdc47e26',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '03bbe2f5-7dfd-4236-8fd8-a38c7578da8a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '748160b7-1131-4612-8106-058411dd26e5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5671b12b-ca4b-4c69-81b1-1c874f08724d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '98996143-11f5-480c-8f1c-0bea88067076',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e1d355d0-3ece-4ac8-8a66-79ac38cbee87',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '577de330-153b-4e3d-b6a5-b450eded4a37',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '916566bf-ad96-4a0e-8445-eea2fa544116',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '518b505f-40f0-4685-ae46-451aa2b85846',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '57848fab-5b0e-4d15-b7f8-8459a2c7aa8b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6441bbb5-a4ff-443d-8953-82d3eba05ba5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '53197747-f26f-47bd-a99f-c7e0d4ff6d6f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '578d3921-4cd1-42be-8846-691001ef3e28',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3b13b11a-adef-47d1-8783-a389197808cc',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3b1fca02-7e83-4358-aac1-9412d50477ae',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '75f35c6c-3546-4142-be71-0b01b0de7008',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '57e143a1-1e10-4c34-aa8d-96bee3e30c7f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '815c70d6-4305-40f9-a498-1ff419572a10',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd258693d-2ce5-4bc8-915f-e46a2af41f10',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '59126f3c-d2e9-4f4a-8531-0c372ca33620',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1911dd16-d826-42b8-b8d6-794b8aefe325',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '88a3129b-018c-4ea0-8aa9-ba0f98519d56',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4c304b21-113b-49ab-80b8-90c5dfc33557',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5918a520-3254-4ed6-a730-725041245e41',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'directed',
          origin: null,
          intermediary: [
            {
              id: '609dbe8a-daba-4c7c-97e8-20af073a4846',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '23be744d-79b5-405b-9edb-edf916d35407',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5922546f-a3cb-48f1-96bc-2bc2502a3d55',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4cfb11a5-0c71-4765-adb8-fedc220312b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a817ca94-d1b2-414f-835e-ac9395038908',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '594ef709-98f9-42e5-9da7-aa9e32972c25',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ad5909c1-52af-49d8-8ba5-5223020a2572',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '0dfa543e-193d-4b1d-8d4e-31d3616da61c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '596a5b9e-3b50-4a5a-8eed-6b041550028c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '916baba0-493d-445b-bfc3-c2e7f0ff9622',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '89249292-7c69-4d6a-8d39-3d078ab7e040',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1cca5204-7fb2-4b39-aa6e-19f9555fa32c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5a35af54-f22a-4dc1-a3ef-ff14433c7a79',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5fa58af6-04dd-4ded-876c-20933e1e9c03',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '63d7e688-6eb0-4ccf-a1c7-2a908cae7251',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5a36792c-8f57-486d-a3f4-18f81aff2364',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'ba1ee02b-8c17-4240-a1fe-fc7bc0889dc2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b80269c8-8a92-4d4d-9136-629926e843c8',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6aa495d5-e7e4-4415-a0e2-ba40632d319b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5a62485b-b30e-4b40-b3be-e48d74fc9060',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3b13b11a-adef-47d1-8783-a389197808cc',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '58e4dc4d-7554-4e55-b434-1a3f51759508',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'aaa376ef-b16e-4325-92ed-c916646e7555',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5aa6b0f3-53fd-4752-abb9-c5c8cb5c958e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '510c2077-ec97-4ab7-8695-f70672a2a93c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e97907a5-4292-4c6b-9dbc-91ac41e6ed18',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '62942654-bcca-41de-9265-6e8cf4b99f50',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5aace476-5952-4aa7-85bb-fff7700d772a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '6b78c33c-c44b-407c-b7c3-dc93fa2374c1',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: null,
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5be4a987-ea55-4796-9c82-178017085582',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '9826d113-8a8d-4c32-bb11-881dc8a43033',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '67a8c65f-f16d-42ec-a845-8d9820d2ab1a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5cd16521-9def-4073-8c90-9f5a71a44db0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c4283b06-924b-4719-b8a4-9ec9fa2b341b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '792171bd-9066-4806-8fa6-cd333a71c364',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd034d562-cf29-42cd-863f-8ab674180a4f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5d197ec7-8580-4eb7-a641-7c02502f5dbf',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a2f2db51-a011-476f-a34e-6a37dcb8db3d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '63ff3d44-9eb7-4965-b73a-d89481d4b9fa',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '8d6efb88-345f-488a-9ad8-04eac52df115',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5d246a39-0972-48a5-a371-6e4a0ad6446d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cf792762-540e-48ee-a419-371abbe030c8',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '29aace65-4ee9-4c57-afd7-0e139be248c5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5d745f5e-2854-433e-be8b-bf3a0f0033bd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4c304b21-113b-49ab-80b8-90c5dfc33557',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'bc4348f7-f14e-4740-9214-85af7ba934a3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e1d355d0-3ece-4ac8-8a66-79ac38cbee87',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5fbe9391-670d-474e-83b4-087f5cf5fc68',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '9b170552-de82-412e-a40f-aa572ea2866b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9056b4a8-7b82-4712-8dce-db3cb0960c4a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '70062c59-611a-4bd2-86d3-6963b664933b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5fe1e91d-71a6-4e15-af33-e0e6f6c74134',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '952d98a3-ef9b-4d64-8279-5eef1ad3404d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'fbce9bd5-60af-4363-86fa-276fa7d900ed',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '5fee8b05-31c0-4175-a433-107385a76bc4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '75feb0e4-824f-435c-b53d-4f777ec54260',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'fc5712c6-a61a-4187-a5e3-883b638d834b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '603fd818-8880-4552-845d-d277ef727d6a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4680fed8-3e15-4cbe-80af-21ea90753c0a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '75566b6f-a67a-45b5-a4fa-b51f6199197e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'af0c6b22-1ef1-49ce-b87e-30ba19f41565',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '6042409e-857a-48be-ab24-7f8fbc24aa50',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'db0cb8d6-caff-4b72-9098-2482c581be31',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '45fd1dbf-6c42-4e0c-b6fb-3bd45b151a8c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '61bdb3ef-18ce-47e3-bb0e-67156ac68813',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '74fad0c1-3daf-4c4b-a6bc-a1631dcd84b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9fc5f8f1-23f0-4808-8780-d3f06a4820d6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '0c016dd6-fff0-4c16-97a7-e72ea7cad585',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '62402a28-10f8-4c28-b9e0-36f316938c2d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '8eecce7c-51d0-40a5-a505-8eed7d236912',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '68b5e3bc-c50e-4d58-b31f-f0d1ef0d146f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '94111f33-1d90-4516-b0fa-54c2d5cc3143',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '658883be-54d2-4c18-8124-1850f5b36832',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c8181969-c7cf-4cf6-b414-af245e7b7c64',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f75b6577-1ee9-423f-bb9f-5d57b172018e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '66d31add-b179-44ad-9ba7-89ecaf9be22d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3cfbfcc0-75c4-4eb8-a202-2d63e59cca27',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b032ec90-732f-4e6b-aa68-feee1dbba40a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'c5b93454-61a4-4fe6-b8a8-2056f4fa1651',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '6702fd8c-e442-4aa5-a997-445f82f7ab4d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0af90c0d-4049-4dda-89d1-8a1014eb8fa4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '2594512a-8c2b-4744-92e3-00e3fa8a0289',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '67a87bac-c43e-4a35-9393-fb4f1746d82f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '11c7a7d1-151f-482a-a3ab-1dca0756ca57',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2df6b49a-e8f7-47a9-859e-0a8e9991f96b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '67d8a99f-364b-4e9a-9f89-21f45c7a7921',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cd4fc747-7a86-433d-873f-8b6c4645ed4c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6e45acce-3263-4726-9087-858c816ca6a3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'b34ec29f-91d2-45e2-b95f-aaef9a308f58',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '683a2d09-241c-4834-87d7-ac000f8749f2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b5f48aa2-7a75-4102-b624-4b665dbd4738',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2f80d279-d1ab-4036-a767-b2caed217431',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '5178993a-e485-4c11-856f-ce64e356c8e6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '6870e8cb-7ef0-4af4-85fc-608f552a8f50',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e7b2cc13-2580-4266-abc6-01b2bad09a08',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f8413aac-7b94-4c89-8c65-59c37b12ee83',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'c6008caa-1c6a-4366-8e0e-d51f54955fd5',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '68985cc5-cefc-4075-9442-800d3c190593',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '679d58b4-96b9-4978-978f-4c0532b3b607',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '01c3d1b1-474e-40b5-aa72-fb1c9690a479',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4fd82952-2ec8-47e1-add4-d4edc27df3e1',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '68c98216-59c1-413b-a411-bd8ed4d1d981',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'ca1d116c-293b-49d3-9513-0bb29810c7ee',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '1063fb87-93d9-4a6b-9d1e-65f4eebfe3fe',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ecd301ed-0f81-44ac-8596-d0ef6b2f4682',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '6ad94d59-7722-4a8c-a062-f49a3806c13b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4680fed8-3e15-4cbe-80af-21ea90753c0a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '20d4367b-9167-4bb9-8783-727c816b7821',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f2602946-9666-4195-9ae1-2e1b5bceca2e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '6aec19c2-9bc7-42ef-b44f-d2ed8320dc32',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1f111abf-330c-457e-9173-e4ce620113fa',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '33f35c5b-1086-41fa-8e83-bead17dafc83',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '6b65816a-096d-4e9d-b46f-59c168ac17aa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: 'b97aebf6-8b48-4f82-abb4-5b4c81cb057a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '027b31e3-0ec6-4ee4-a5ff-e873b2750396',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '6c6ef2d8-67e0-4cd6-b1a3-219ae99dacad',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '910f1954-c17a-47ef-932e-953f611321e1',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9c769eae-cd49-4e8c-bc1a-0c11c03b1417',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '38261cf4-cd44-4a1b-957c-4a7423547f1b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '6ea63d88-c698-489d-9b31-9164d17c05dc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '64357b2c-7022-4c94-83a9-eba0d1bb8983',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '7bc1af06-9e5d-480f-91ac-980672c6c61f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '050455df-a87a-4b1e-987b-243c34fc6940',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '6eb09309-c1c1-48be-a587-4294098f2669',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4e806092-a862-4683-bbac-517023bd92ee',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '48137477-4f17-48c8-88b9-9549cb166273',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '70098359-90b7-4c11-9fb8-68955bb16e23',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e8a12e2f-76d6-4bdd-98cb-72caee307b23',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '68295be4-fc67-4753-b42e-a62593000f26',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd93f7bfb-53a2-48f4-9b98-64cafade5fd2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '7094e8bd-1828-475d-9784-7ba2d5949191',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '008ee6df-b92e-422f-8208-426dd7968e19',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f0b8971e-3ca3-474b-a839-9801597a1813',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6576f6-0e56-4359-a0e1-a9427519d967',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '70f73677-5346-4f7f-8302-ee2cc5ed8599',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'cd2448cb-e25e-4fb6-849c-95c77adab724',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd1ed8f24-7ad9-405e-816b-f69ce3840bfa',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '72e33b4f-8116-4dd4-b3c1-8026a2795004',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4c304b21-113b-49ab-80b8-90c5dfc33557',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '70499d80-619a-4d59-91fe-4802ebea4491',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '73796495-6ff2-4998-9ffd-61fc886fefac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '46908382-db94-4264-9e2a-1afac5167f5c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'bcb46ae0-c0c5-499c-81b9-64ef45a1f222',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4b76871d-436b-4fbe-8050-debc94f1449b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '74d9ab6a-0eb2-4e38-abf4-eb53fba71a7c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'directed',
          origin: null,
          intermediary: [
            {
              id: 'fefb2975-d6a6-4136-9b6c-b5301afd609a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '5165e67d-cfa0-48af-9deb-c5de6e472c82',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '7524b1d7-7e8a-45f6-85af-7945fae79918',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '54d60f92-e210-4c03-bd28-34805d86f07d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '183734de-3c16-4450-a27c-793942e12962',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '75c50e60-b9ce-46b3-8c65-e63481cf84c8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cb64d2a9-c158-40a6-a0dd-e73047b44b88',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '30df8e83-461a-48e4-a1c1-59f67f7dadfb',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '75f70eca-08bb-475d-858a-505671bc192a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '7bb3ea74-3966-47a9-8e7f-0c85d9daa3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'df4aa628-edb9-4c11-a8f6-6d5a8ac589d2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '57f40a74-df0a-4a81-ada0-7dff6d9525c9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '77077cff-ffef-4420-8937-862ebde3f580',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: 'd2f66159-f461-42e2-8942-4fb9bfce244f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'b5f48aa2-7a75-4102-b624-4b665dbd4738',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '77399d81-ed97-45b8-971d-e2febe8e90e5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '32438f2c-93b2-467a-b1c9-6952cb5ebac2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '7a72193c-6e40-4ed3-a4ec-002424906aeb',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'c5b93454-61a4-4fe6-b8a8-2056f4fa1651',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '78230b57-4eab-41a6-aa58-e450282a45b5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: 'c6a68570-5c1d-4279-a7ed-8c0b7ce9bb2e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f0006594-2259-4a5f-bfdb-1280512dd51d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '79249fbd-9e4c-4719-99ba-4664caf798ac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '28454355-cd19-4d3a-8460-bbee43835511',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'eb760192-04f3-4941-a047-0c47530b1641',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '7933f735-98de-42bf-8ba7-7d330894cf4d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd42c9050-8c82-4d52-a5e2-4fe9f89bfd7d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '650cd3ed-bfd7-4bd3-b4fe-e380614b14e1',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f416a52c-ce76-4cb7-8742-e570190297b7',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '7b191279-db5f-4dcf-83f3-c3dacac586d1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a2f2db51-a011-476f-a34e-6a37dcb8db3d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '75ffcc07-0c29-45b1-93dd-9f781fa1c089',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '20ddff7d-1518-4b5d-b198-75de64ad5900',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '7f57344a-64e4-4032-878c-37df8ab4452d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '3bd0fddd-57c8-4bfd-902c-68d015b51dcf',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a050a15a-cfef-4b39-9467-c06646694000',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '7f9cc335-ac0d-4612-bbf6-56ef95ea7664',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '56ad01d1-7e18-4638-b8ca-f947bf1a843a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'bb9742c5-f906-470f-850c-6652f15694d8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '800ecc5a-8c14-4282-ab1d-d1d010d8511d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1ea95200-a2af-4b0f-824a-40f1ee423521',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '21c3d5a6-80f2-4ab7-819d-e53c1a1455c5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a654bcf7-5121-4909-beac-46cab5098bb8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8048059f-a6a6-4188-a49d-b83b119aca86',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bb271af3-0a0b-46a5-b586-0a1a80fb4b7d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '92adc58c-7a17-4078-a4ef-945418141fc5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '0c016dd6-fff0-4c16-97a7-e72ea7cad585',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '80e992f0-3338-4d61-a6b4-fadcd1c2973e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c91637e1-8d47-454e-aca4-f57acaac6133',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '04608312-36aa-4000-a7c2-946c5f134cd9',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '82eddda8-898c-4c66-8969-269de7f4b017',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1be6f9dc-f784-4de2-a7af-2f70b285889d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5e128703-8625-49e3-8146-c540ee72049b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '89425ca3-97dd-4b0d-9cf0-39b35617f197',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '832c86fb-b37f-44c5-8802-273e0bad2a0d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4f71d12a-4b65-48dd-b6ea-89b06385bbaa',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3dab787a-e93c-43de-a377-9de623de75d7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e1d355d0-3ece-4ac8-8a66-79ac38cbee87',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '83836e12-9428-429f-bb80-02e163736a41',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '56844de3-db48-465b-bc05-51ecf586f2b7',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3492e86b-383c-4acd-942c-fdef441baaca',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '83b9798c-8d6c-4baf-82f0-bb97ae7c477d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'directed',
          origin: null,
          intermediary: [
            {
              id: '12aeac2b-8c91-411c-b105-7730244aa9c2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '2176f2c0-9661-44b2-b7ab-9dbe71ee2896',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8411f26b-5c5d-4059-8a3d-92e88d3042cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '008ee6df-b92e-422f-8208-426dd7968e19',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd0c5ddb2-5d1f-49dd-bb5f-a3802add7142',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f0c7cf48-99c1-43c3-b0ff-6aa2dce61522',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '84508a42-2dfb-4587-8301-4f82f696c2f9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a531d837-70db-4c04-8813-4bcf2427a1f2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f179afaa-ae49-4e48-9744-f234af9e245c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '5fa5de83-9aaf-4bc1-af93-2d04b12c3859',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8768390f-e58d-4ac1-bb1f-fe85c3a1bdb0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e3ffc303-d795-488d-a59b-ea235b6f6355',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '560871b5-0b71-42f1-aeeb-34dc79f0c7dc',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '876a4399-13e6-4359-a662-4d70b3d77b23',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '1e5e642b-ec6b-4ae6-a48e-27e42b62b30a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '87cacc9a-2183-41a7-9596-2c356c2a905b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3d4cf40f-7ad1-441e-ae00-ce0331608728',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8c348e23-4217-45e5-b9e4-76ce7348240d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '9b170552-de82-412e-a40f-aa572ea2866b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '891ad784-8a9e-4238-8653-858eb2bd87d8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4129d82c-4361-4402-a98e-2e191107515e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '879ee864-ae73-42ec-9da1-fe3314445ef0',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '89f26937-5134-493e-b49a-ce3a654a11cf',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '21938c0f-3071-4f41-a822-ff24a6d1d572',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '637edcc3-8a8e-4edf-ade6-6a16d3607206',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'b4e14876-301b-44c5-8ead-f070b3279d8c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '89fd0914-6c92-4fdc-87ba-1495c80d53c8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a6e7debd-3505-47ec-b5bd-63c01e38e7df',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c652b020-077d-4f5d-99cb-947f1fa8ec1b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8a30897a-cdca-406d-a17f-cc7d07082a1d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '52133df4-ab10-4200-a147-87e516c77439',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '02b15a0a-9726-48a6-954f-accd965e4a75',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8ac7f0a7-f84c-4008-957e-6a645d548882',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '257e28fd-c1d6-447c-8f31-23ab5a65d5d4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '9f6f4d4d-4740-4f19-94cf-d236787b02dc',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8ba23047-2802-48fb-b613-cc8647db97d8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c4283b06-924b-4719-b8a4-9ec9fa2b341b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '58f0f941-f020-46eb-a83f-f719ac8cfed2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '01db2d2a-4add-4253-a292-b25d774ca483',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8c4bf9e1-7ade-41da-b985-245c12cae08b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3e8e09bc-bf95-4f33-b541-61457b84c0da',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '83961642-0ffb-48b2-8426-772032b4a0e9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8cc03219-ad43-415f-a8ab-0233cf668f65',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'ade736d4-64e0-404f-98db-cbdf76984152',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a83026a6-dfd3-4ce9-a86b-8312780b20db',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8d07cc26-099a-4041-a4f6-2818945547c2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e02cc05b-66cd-429f-9948-94c16c2d4696',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '74972292-13f2-4d00-abb4-6a5abe135186',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8db28a82-a983-4c6e-9a19-fe717f6c9f88',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'directed',
          origin: null,
          intermediary: [
            {
              id: 'b735907a-a8e9-461e-be74-7cb7bb3479a7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '40182329-fac5-456a-8bba-12d73775064a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8dd85325-01e1-4b1d-bc30-5a37ce10a3a4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '271a3191-dc2f-4229-a4d1-48ac466ac983',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'dc39398e-5b0b-40f4-a135-d45e118da2a3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '8f73952b-e4f4-447a-8929-8e2869416871',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '9b06551d-8035-4b4d-a764-b93b7da8c221',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '46908382-db94-4264-9e2a-1afac5167f5c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '91021274-ea5b-437f-afb7-672c6844318d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a154a228-9953-4d65-9f3d-4dae30333dc1',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e5dd5797-30ba-4df8-a247-c809530feea6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '89425ca3-97dd-4b0d-9cf0-39b35617f197',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9111c25b-a3ef-4d3d-bba2-64fd303db704',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '7314be54-e528-4e5d-954b-f34bf98611fc',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '43352d5b-81a3-4f63-89b6-d14d199dd6b2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '91270b0d-ee08-4a5f-bac9-652ee2189e48',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: 'fd548d7c-80f0-47fa-820b-ffc6ef86a3d3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '929ac73d-0d98-4c30-9925-4b3ce5354ae5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbd13c71-37ae-40fd-8540-20e1c3832867',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a29082bf-0ede-42a1-9b5f-db42e56c31f4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'af6f9b00-7118-481d-a52b-63ac0681f9b7',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '930f1666-d5d4-4797-9e7e-57e8e24a0126',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '9b5ae2fe-eb79-4a8a-ac76-e5e9e590a8be',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'da9f0bd6-c2a9-471e-9782-e0b03022a5b7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '93de4218-6b74-4fdc-ab3f-ef173b1679d5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3ec00ca8-20a3-4931-a753-853f0a0c53eb',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6ae156b2-1666-4324-a068-e426efead8b3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9501d108-e6af-4e9b-975c-761b7af73a75',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '7ec982ae-ded4-416a-907b-5d93fb01ab19',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c534aa16-f6eb-4b0b-9497-92f26afc4e4b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '95cf8bd4-0d18-4601-95a2-a8fe9f78da30',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ce2d0825-4fe6-4092-a7f8-7614a18927b9',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '38261cf4-cd44-4a1b-957c-4a7423547f1b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '95e8bdae-4f1d-402e-983d-1ce40f35a41c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fb9f5ee0-d519-4c67-ad5c-d216a32d7425',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b1374119-5343-4ca0-98cc-3abb1be413bd',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ba1ee02b-8c17-4240-a1fe-fc7bc0889dc2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '95f6d985-6002-4e04-a73e-47f0b997a1d0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a654bcf7-5121-4909-beac-46cab5098bb8',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'bbb6d57f-5564-41af-9ec8-1e615fdbcf6c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ce798d77-9749-4b44-a96e-dd05d17151e3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '96687373-301e-4067-88bf-62a12c484914',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f0006594-2259-4a5f-bfdb-1280512dd51d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '7964e20a-167b-48f5-8e98-6dad1fb89460',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6c16127e-409f-46c1-96d1-b422f7cce020',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '96f121b7-cfff-4345-8366-0c019e116cde',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '21938c0f-3071-4f41-a822-ff24a6d1d572',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4a1b3b56-0c24-4679-9cae-70ac02943892',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '96ff071f-ad93-4f97-9698-62aabf4b0dac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1c45dfcf-a7e9-4d26-8847-22c77d814f33',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b0a6fff1-9496-42ae-9244-ff6e944707a0',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e1d355d0-3ece-4ac8-8a66-79ac38cbee87',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '970176ed-d778-4c00-8c78-1cdee1cc6247',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2f847a91-bea3-4ae4-b170-aa41f6f49d23',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '49980d33-38f6-4065-aa35-915dba7a6e20',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'b79ce21c-dc48-4373-ac25-af21477f4977',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '972dbd6c-0cbf-49d4-be19-31def53be8a8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '21938c0f-3071-4f41-a822-ff24a6d1d572',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0c6ed23d-9666-4c6f-ae2b-f90fe14e21d5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '268d2566-5cbb-478e-ba36-7a1b7a1320cc',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9777abe5-f140-4a70-bc08-7fe57d30ca1e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '96ef62e3-2b1b-49f3-a784-65efae23e182',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e491bde3-bec9-48da-990e-7cf1c2f9acab',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '94111f33-1d90-4516-b0fa-54c2d5cc3143',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9798ac1c-b025-4c25-8a1a-aff9636cad00',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'eace54e5-dfd7-4f1c-ba40-ec3c26c3e64f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '88c11693-4001-4d05-90cb-169a66472a65',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9920fc77-8a2e-46d9-884e-d9a16d60da3d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4423aaf0-a9c2-475a-84c0-0789d98de29c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '63aed861-b252-43a9-af41-7e56558de656',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '2f93b94c-ede2-410d-89de-0ff46a800b48',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '99656732-1788-4486-90af-21d4d240e155',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '299fb919-97ea-4b14-bb62-aa1825279402',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '357af0e5-5be5-437f-8077-159c8606476a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '998b4c11-e1d4-45fd-b904-92f799453a25',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '35144039-ee24-48ff-8234-143439df779b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '5165e67d-cfa0-48af-9deb-c5de6e472c82',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9a883f6a-0d7b-411c-b6f9-0c999375e37a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2af0462e-f17d-402e-9b84-7ee33fd9f735',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '7710dc2b-4d43-40d4-96c0-4cee95fef884',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9a965886-17a6-4b3c-a674-0631d7a2177c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '50ac65dc-a842-4d2d-83c8-0e63236633aa',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'eab2a412-7d09-482f-abd3-1bc819a0bd75',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9b3180a8-7d51-4e81-84a1-fe7ae95f651e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '440eef5a-8fba-478e-b4c7-ae27402443dd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8a9bb778-b2e3-4c44-bc7c-1461b5c910ab',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1cca5204-7fb2-4b39-aa6e-19f9555fa32c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9b8bdfbf-7f33-43a7-951b-3d0b04a99b73',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '32720240-eeae-4d7b-8ca9-1dd636f04a22',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '75f35c6c-3546-4142-be71-0b01b0de7008',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9ba8eb0a-4963-4cb8-a28e-d3c6b1371daa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '825beaf3-98fb-461d-963d-474c6fc87ebb',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '341c7017-b59a-482f-a3a3-9c0082d7e09d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9e375184-67df-4450-8ed9-a8710b13036c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b84aa587-2127-437c-b1a1-c51e8e02f33c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6b85de1a-12c5-4b21-8b4b-8a4e15de7a3f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '2c55d857-482f-4585-ab7e-7f04a282a1e5',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9e7e4748-c3c8-4375-9e4c-3c674f809b22',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3b13b11a-adef-47d1-8783-a389197808cc',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '47deae30-a534-48c9-9fa4-cc3e37eec033',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9eccaea6-94a4-4fc0-9a7e-d244f06190de',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '23be744d-79b5-405b-9edb-edf916d35407',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '928aa1fa-87aa-4a71-a6c5-ee541dad119b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '7e3aa381-42c2-4df4-9301-b94378c6c8c8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: '9fe0f555-2d67-4f4a-852f-96ee549c4066',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '344a43fc-edbc-42e1-81b8-eccb8f5d4fbe',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '32ff3a08-a533-4198-9aec-e3ac064ffaf1',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a01d6c9e-08e3-411e-a2fd-26e5928a49fe',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'be370945-2736-4c90-a89e-dd3bd83ffbc5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2a6dce12-44d8-4320-821b-5aa1a4b63f1b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a0e5364e-eeac-4ad1-9c9f-f291ea89487b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '227699c4-5b5f-42d1-96ee-ed0aeb4c5018',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4db76740-aae6-4eb3-8e3c-e6c240e82f3d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a0fdcb3c-8813-4a2f-b695-8ce738bfd024',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '36c9b327-cc9b-4d18-b551-fe6a90a0a877',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '02d94a3c-c6b5-4fc1-90a6-42a167c5b8aa',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a16804d9-9c75-4770-9926-7421a20aabbc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '0b09e3e1-3ab1-4bfb-89ac-96248e9d0a45',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2168e38e-1fd8-4abf-8166-5468e63e1b13',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a173b75c-6421-4acc-b23c-5210b1167168',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '896211df-b69f-4686-bd72-e983757230b7',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0e575642-6165-4b15-a42e-a6b576943264',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '86762cac-e648-470a-b0e7-8e763288fc0c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a27bea31-3e04-430a-ba00-cc84ac660c38',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5d5a6114-9f3e-4125-befb-a3dab0092dde',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'aeab48eb-1ae5-4879-af16-258ad693ca61',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6dfb3e19-e82a-4b99-82e5-096dc24c399e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a2875583-3106-45d9-8366-f78461f652f9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fb9f5ee0-d519-4c67-ad5c-d216a32d7425',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ec9663b7-7a8c-43a3-8fb0-c8a023b04dac',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '17a405d3-9c47-468d-9744-bffdd1dcdee8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a32b0f9d-2b43-4938-b13f-edac73c0664f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '46908382-db94-4264-9e2a-1afac5167f5c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a7714da0-c7a4-4368-b66c-09a675ccfa6a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '2176f2c0-9661-44b2-b7ab-9dbe71ee2896',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a42e6214-c298-4022-a0fd-42a6cd828600',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f1598ae5-83e0-411c-b62c-6d1b003d8e63',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b5c36082-eb21-46c3-9a6b-9212f5bf231f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e01f0f5e-c905-4afc-b0f4-dd4cadef52cc',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a44519de-088b-42c5-9ca1-210473f8a9d4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b84aa587-2127-437c-b1a1-c51e8e02f33c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f7048a3a-7d5f-4b93-a5f3-1ddae926d92c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1f9ef7bc-2f5c-4e2d-a91a-edb89cc3cfbc',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a489ab7f-658c-4d15-ad2a-f23463fc31cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4631a172-2a93-47b9-9e1a-4a10978bfd69',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '78d4ada4-a327-41b6-bafa-d0181903e36a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a58cf1c9-b729-4cac-b51c-7503859a55a1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f7a07bb0-2c1e-4c2c-9b7b-30fbe8c0c162',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a531d837-70db-4c04-8813-4bcf2427a1f2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a596fb4a-8300-46e5-b079-8fe0d07923a3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c65a0de9-c441-40ab-af1a-cef161867f6d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1569cae1-ae6e-468c-b5ff-51dd7a3c82c2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a5b28f27-28f5-44b5-af47-6fdb676b4239',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '66df0df3-ae93-4f4a-84fa-ade7ef25e15b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'fec29b60-d1f4-4124-8fe0-6024134d87df',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a6402fd6-bbac-4cf5-8abf-c135b276eede',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b1eab451-666e-4367-8215-3723c0a35915',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4cfb11a5-0c71-4765-adb8-fedc220312b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a641411b-a129-4660-b16f-274d928aa1e0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd42c9050-8c82-4d52-a5e2-4fe9f89bfd7d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd6aefd80-14bd-459c-9d76-9118712b8a30',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1ea95200-a2af-4b0f-824a-40f1ee423521',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a7256eac-9c42-4655-af3c-441090a042d8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '64357b2c-7022-4c94-83a9-eba0d1bb8983',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '534ce926-25b8-43f7-8fd1-7656d6fe04a1',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a82e4049-a282-4ef2-9d05-f8665d858dd6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '20ddff7d-1518-4b5d-b198-75de64ad5900',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '30610db8-096f-4a07-a366-6b53468da59a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6b8ff04f-1dbe-4afa-bece-19bb22f0eb55',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a8580a9b-c9e1-4592-b6ee-706fecec4e06',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '7bb3ea74-3966-47a9-8e7f-0c85d9daa3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0356bc24-97c5-45a9-b4dd-f377891f335a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a89dc211-eede-45d1-a3fd-c60cf1bdbc8a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '526bb2f5-25f8-4d13-b4f3-faa18a0e5e09',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a2311953-7ac5-40aa-81c0-fb7452393d3f',
              feature_type: 'opening',
            },
            {
              id: '029e455f-a9c3-4eb5-974f-badff5e174ee',
              feature_type: 'unit',
            },
            {
              id: 'df508559-586a-4535-9201-58e46a132fff',
              feature_type: 'unit',
            },
            {
              id: 'c946e704-c2eb-4c3f-9098-f5f7c96860f1',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'a984305c-b105-4593-afd1-0ba08e0558c7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'directed',
          origin: null,
          intermediary: [
            {
              id: '74d02b69-390b-4984-89c4-4ee33eb84147',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'aa7b18b4-6beb-433a-9110-e904d1c1acf3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '568b94b1-566e-49b5-8647-d6bc159b38f3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '03506c29-dccf-49a0-9f7c-bf3acf26b8ee',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ab12c655-72d7-403c-b483-1880d6a72a7b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cb64d2a9-c158-40a6-a0dd-e73047b44b88',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b10b68ba-40f4-40af-a960-57172c3c4cf5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3748db0b-19db-4865-b5ce-b814270a72ae',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'abd2687a-35df-4034-b11c-2e1373456a29',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a0a009ed-c9f5-4e1a-9637-dd011d5b66e7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '64410b31-67a6-4196-a089-12058f140aba',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'acd1694f-3a66-499b-b47b-170e5f7d3396',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '008ee6df-b92e-422f-8208-426dd7968e19',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '09395372-4437-45ab-8d5f-58c54026865f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ad1cc6b3-4052-4ccb-a92b-e2a7c05962da',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '56af869a-34e2-4772-82d4-4be19ca01e92',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f2ece0a3-c74c-4c84-8f8b-628a99736d50',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '86762cac-e648-470a-b0e7-8e763288fc0c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ad8b53ae-e1e0-43bb-9249-7eb43a601e96',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '6a691ff2-d6d1-4496-b0f5-21894927a7ca',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a838a9cc-e2c8-4319-83b2-88163bfeafcb',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ad9286cf-791b-4468-a0cd-dc527fc1d471',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '02e1bc1c-f29a-443c-b159-840d9b8a5f21',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ac577058-e49f-471e-bb26-5caa6b7f8de8',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'abccbae5-fd37-4b92-a70e-b82bdc309489',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ae30444d-bdbe-4752-923a-9498622e40a6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a69a458f-9831-4465-8b43-7b83a2aab95d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '05d05ce3-5a8e-456b-ba1f-649315094fbe',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ae6608fe-d3cd-433f-bb0c-64447e84ac5d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3ad772e3-ddf7-4d3b-9a07-49adab42d29f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f32567ef-eda9-47d1-8676-c5af25b314dc',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'aee15144-7b6b-4b9c-aa11-ea1a88907963',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd04ef867-a4ec-4650-8553-db173d368158',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6bec132d-6dd4-4a18-be9f-c25d13736d17',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'af21f8a7-a216-4c94-8483-9555055c246a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1d2e055d-190a-48d5-94c7-a3fbc5bc0d12',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3d0eafe5-374f-4351-a624-ac40ef885686',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'afb7df97-5498-4cfa-b9c6-715833335d8a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6127ce7b-ae08-4ce5-b85a-824b350feb4a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c2775955-e343-44a7-bd27-cdc001d9edc7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6cc945f1-9d6f-414a-93c2-885640b5a3ce',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'aff21543-f656-4009-a610-ead5a61372d5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '59cf144d-0a52-4809-8a2b-e3610fba1664',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f0c22821-c0c5-4cd2-91a2-a007196c9fd8',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b1044339-1053-4223-a00a-672785574e3c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '46908382-db94-4264-9e2a-1afac5167f5c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2b9beaac-aeff-41ad-9889-a4deeec5198a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '0a2dc1d6-b611-4f14-9bcb-65a4d1238d40',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b136a328-08e7-4c40-a983-1bcdcb7352fa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4680fed8-3e15-4cbe-80af-21ea90753c0a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6bb7a84a-ab12-4e5b-96da-79587d4b371d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'bb271af3-0a0b-46a5-b586-0a1a80fb4b7d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b14e478d-6dff-4bf4-9267-f2f971e54d77',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5d9ac18a-587c-4bec-ab00-e9b0d7c69efa',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67f08e69-9079-49dc-8f40-244b5d2c04f1',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b1d88ec9-873e-4d15-8c62-6d1906903b72',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3b348ea9-2bc2-4222-b771-fa94e63733bc',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f547d4d5-e32c-4ad0-af02-045648457dcd',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b226fc37-4b11-4f9b-831d-6d649237d9ce',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2176f2c0-9661-44b2-b7ab-9dbe71ee2896',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '60157040-7043-45c5-9ec8-71e9b00f73d4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ce9fe56c-c483-4a83-a8e9-ca22e4f79f79',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b2d5b5d8-0448-44ea-81c8-f56e5a7c335f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '20ddff7d-1518-4b5d-b198-75de64ad5900',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6c713bb2-f397-4a50-bcf5-7fa089473013',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b343e62d-4d44-479f-84cb-f4f293fb46e2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3a2bc212-7ae5-4329-aba5-5ff4d2e9c7fc',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c1484b86-6601-45d4-bad4-65ce46ccc1fc',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '989205e8-ae30-4963-acfe-7d6246f8ba68',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b3c8ac2c-9794-4f85-93a3-2664af967536',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f3f1e630-2276-4313-8d6e-26c6f588d5a1',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '542f7265-e59b-4e04-9043-a087e0e23a2b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e1d355d0-3ece-4ac8-8a66-79ac38cbee87',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b4871cf4-b045-4d2a-ab26-8bd7f2913267',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '20ddff7d-1518-4b5d-b198-75de64ad5900',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4e463870-4937-4c89-beb2-56aed4605343',
              feature_type: 'opening',
            },
            {
              id: 'a552838f-14e9-408a-a42a-dbd18ecf7ed3',
              feature_type: 'unit',
            },
            {
              id: '61781969-f149-4990-b6db-5f74979f75d2',
              feature_type: 'unit',
            },
            {
              id: '7cc8d8b0-d78f-4669-9e16-bfe8bd6ea1b5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '008ee6df-b92e-422f-8208-426dd7968e19',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b4a18b39-0ab2-432a-942b-98e40e775df0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '72cc4217-e156-4ab8-a52c-66ae91ee9f9f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a09f4c0a-c176-41df-b9ff-e9395c8ea124',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '86762cac-e648-470a-b0e7-8e763288fc0c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b52fafff-30d0-4418-bce1-168f4d1236e5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4e431a16-58e1-4107-80a1-fa07e8c8fac9',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd2520038-7ead-4d5b-89f4-33a6b964f610',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6cc945f1-9d6f-414a-93c2-885640b5a3ce',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b5b9fb66-71f1-4738-ba6f-8c636e7c5fa2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2ce5ccac-44b6-44f7-a426-e85c0c61a112',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b952d19d-62d6-4ff9-ba4f-5c849abd7f0f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '86762cac-e648-470a-b0e7-8e763288fc0c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b5cff7d6-bbbd-407e-85b3-bb7111a3c233',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6011877f-3e3f-483f-99fa-90c38a3f2046',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f45c6edc-9cf4-45bb-ae09-0bc57d1c8213',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b907d52c-c680-4fe2-9086-87526b5f3bed',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f2602946-9666-4195-9ae1-2e1b5bceca2e',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '87e78e4c-c942-4a3c-adba-dd63e60bf054',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a3ebf07e-097c-48f2-ba87-ae0a4a15f201',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'b9bf1da7-3b1e-49ee-b1f0-000cd3e6f65c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '32438f2c-93b2-467a-b1c9-6952cb5ebac2',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '58c76164-5d86-444c-891e-53569c6adae0',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ba26959c-939f-4746-bd9a-d69c23ada83c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe584745-4fa0-4347-b511-fa063ea80e2f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd6963dd1-7929-46e1-8834-829d7271d123',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ccbc084c-7217-4d8f-88a0-13a84d3ddb8b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'bacc2d92-f849-4ced-b950-da63cda3463f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a9fe523e-aa51-4fe8-8763-e88e48701541',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'cfb18aab-86c9-4c51-a761-3992ba29ca42',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'bbaa4a82-227f-4496-b0c3-9c742d1e688c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '487acbca-5db7-4549-bdf3-843f23f2c174',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '93f65377-853f-4e9f-9a06-c58b84ec749e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'bc806382-b632-491f-b5d6-68c43fb91569',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ded43d1b-61dd-4145-bbf9-c6b097e6e965',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e6f2b03e-fd54-46a9-b08a-9a9f19db492e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'bd4178cb-c658-46c8-87ce-3276e10b47f6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '882be691-931b-4c92-8559-8bf949576caa',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6e3cb470-702d-44c3-9490-9bdf96806cad',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'bdbb3c50-b4e2-47e0-b193-a35804da23f6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '46908382-db94-4264-9e2a-1afac5167f5c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0a40ccee-4080-4894-b6bc-a50fa3fdbba7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'b79ce21c-dc48-4373-ac25-af21477f4977',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'be181b8c-eaf4-451c-8331-8fd8afbbd9c0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'fa4e6ec0-4139-4c0f-82cd-b1341575775f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '98462083-e38b-4aa0-8e50-28f8c98f7b2b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'be4111ec-efc9-48d2-8124-f7f2b19f1ad1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b1af0084-c376-43fa-bc15-9d6aa20b8477',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '97eb85fd-6673-4edf-bb2b-429b7b7fd638',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'beaef44e-485b-431a-9b78-219956c9136b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b7e7dceb-a2d4-40e6-a2cf-d33e1ae78bcc',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'c85d6f0a-f16c-4bd6-b57c-1ead7fb8cebb',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'beb94ec9-05d4-4cc6-ba25-699fbf533a1d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'ccbc084c-7217-4d8f-88a0-13a84d3ddb8b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c06ee343-17e5-434b-a98d-d79bff39fb89',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '03bbe2f5-7dfd-4236-8fd8-a38c7578da8a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'bf45e531-708a-4e5f-94b2-9eb249cf84e9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '0ffa72ec-782e-4523-9bfc-4c3c6147960a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b0190664-8145-40bb-97c9-9d31d783d907',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'bb271af3-0a0b-46a5-b586-0a1a80fb4b7d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c0958862-cda6-432e-83d8-f54e4585d9e8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bcab30aa-49d1-4370-be60-4f4a16114e1e',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c3db0e90-878f-40cb-b615-09c1ec798811',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a654bcf7-5121-4909-beac-46cab5098bb8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c0efec69-df89-433d-98a3-b8973bfa99fc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '1bbf31ae-73eb-4649-b82c-e4e5d67d753d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '40182329-fac5-456a-8bba-12d73775064a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c0f07017-25a7-4484-bf20-44c04dd2684c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '00e45e17-9c20-46c2-886f-27c977e4adbc',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6a631a05-bd30-4a58-a817-a3d1d1ff1335',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f1009b8c-499c-488e-978f-cb89455df024',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c0fd6052-a238-410b-8ca1-7b394ee36958',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '41e8e075-f2e8-4916-8d48-d152f32eaad6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2c1adb80-ca45-4e20-a897-a12f865df22e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c1e87169-294e-4807-96af-de09b14eff97',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '6108e94f-aa27-4393-a11c-47d3653c303b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f6fc52bd-1619-4f7f-99a8-1c924c75a208',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '88c11693-4001-4d05-90cb-169a66472a65',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c34d9837-e309-47f8-b1ef-8eaaa796d2b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '89425ca3-97dd-4b0d-9cf0-39b35617f197',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '7caff4a4-6ac3-419f-8642-0d713a38b512',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3bbc1d6d-90f2-4960-af9f-9095f66b3198',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c4864da0-4d66-43c2-9dfe-6222a0fb81ad',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c52b7f4b-8e47-4450-bd8a-a639a6bc9b2d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '4ebc65e3-8d1c-46f6-a1ca-78352f98b102',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1cca5204-7fb2-4b39-aa6e-19f9555fa32c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c5924b0c-8aa4-47f6-b128-b1f5b4e67138',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2ee91936-5d2b-4916-801c-59aa5fb63a33',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3ecbfa38-c2d6-4432-bbf9-7035cf4a6472',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '157bf662-5ced-4ccf-8bb4-f0c5e44c7721',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c60acf58-0c82-4844-a2a9-ee766bdca7ac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '35624642-a46d-45f8-b9ce-f2da32e4f3be',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'baf61c37-ad43-4da3-9fd1-b9786bab7590',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a7de4fcb-0b37-4e07-9742-3a2c6d834966',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c96e6d8c-4e87-45fe-8120-3cf027368c1f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e78c5480-cb5c-40a1-a0a3-8a48813e41b0',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9db4abe8-e867-4747-b507-1fbea3f23859',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd72c5c36-a673-43ab-9dbb-0df85732c930',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'c9c558d6-90a9-4379-aaba-87ca92f459b2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c8aa43d2-5c28-47ce-98ba-c8db14e63144',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'cbb9153e-e6f4-4381-815e-9663e6c8ff1f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ccbc084c-7217-4d8f-88a0-13a84d3ddb8b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'cae984d0-99cc-4c7a-8f4f-194648779de6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd60ef518-f40d-4c9b-8f4e-9c0029819e11',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '135cd47c-7d40-4cf7-a692-6c3b2dde68a3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'cb491be3-47a3-4ee3-804d-7e5f1cac345b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'directed',
          origin: null,
          intermediary: [
            {
              id: '50050184-12c7-40f6-959d-9288d37f6cba',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3d936666-16f6-48c7-9e3b-314b1e744729',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'cba5025b-d1dd-498c-9cff-d45e96612b3c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f56b01ff-9739-4517-a76b-f80e8af0ddf3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fdad949f-f1a9-4df7-8cfa-c747d58c7c9b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'cc2cfc82-4b7a-43b4-ad7f-8e14522e6c52',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '1f8d832c-100d-4d85-8757-18d6100e1db3',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '5bd61309-4342-497b-8927-caf6bcca8d06',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'cc9b4355-5ab9-457c-b1eb-fd539bdfbcda',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '46908382-db94-4264-9e2a-1afac5167f5c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'df3c3c10-b592-4f2a-9fbf-bc92d2ac417e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'cd8a510f-630b-446a-8162-fc57c62543cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '0835c7fc-9280-4bcf-ac4e-1a5457e303a6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '27295d8e-6a07-42e4-ab92-191b5f948861',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'cdadae74-809e-49e3-952b-a7c1402737b4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b7344aea-51b1-4bfa-a867-327fa04c3a14',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f9312a48-d66f-4048-8f95-e9a05fa0f31f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ce3af363-d7eb-4229-8723-2f07cea66500',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'cf1047f6-c3ca-423c-b176-d1717b1638a6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'cf24bfb0-56b3-4453-94bd-ca2bf5df0bd7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '23be744d-79b5-405b-9edb-edf916d35407',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '70eea6df-893a-42e8-a46a-c7c74d07c67a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f0006594-2259-4a5f-bfdb-1280512dd51d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd075b4f5-3b2e-4647-8230-b4b851d1abc2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b6cb1535-d0a0-4edd-903c-e6e96ed53061',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'edbe0565-9f7d-4673-bcc7-a7fa68a6738d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6b9fbb15-7fd6-4fd8-9136-22fd724a6459',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd08c5288-da14-489d-a263-6a55b1ede94b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a90e210f-7ef3-43ab-bfa3-b732ac9a47b3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8ca12ca6-5ce2-420d-be64-68fc77905eea',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd12e8dba-6d25-4712-8b3b-df10ce25229c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'bbbcbe60-521d-43b7-87be-a0cde8d9d43f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ecea6bc2-68e9-48d3-9959-26228656e75c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd18a86c5-ade3-4cec-b26f-b3f815876730',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '03bbe2f5-7dfd-4236-8fd8-a38c7578da8a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6a982055-fe23-454e-a9c0-ec6ca46269b6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '829b64d2-6923-49de-afec-790144e9c33a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd1a568f8-a345-461c-b476-5bb45f9fbd23',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '94111f33-1d90-4516-b0fa-54c2d5cc3143',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd6011af1-3a16-46d1-ac40-c68060d2af52',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '89425ca3-97dd-4b0d-9cf0-39b35617f197',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd1c79857-73ae-43b3-9e67-8dc3b08a14fa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '0a2dc1d6-b611-4f14-9bcb-65a4d1238d40',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5d315d0b-fc4e-426d-9bcb-f8219fdd484a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '8eb9f88e-cfda-41be-b511-85a5b69fbcfe',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd2aac26f-1c16-4847-ab0e-0dbbac55267e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '7056b879-636e-4638-b01d-111239e1e20a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '8fd21940-f458-47c1-ab55-f0efef67438a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd44c70e2-03c9-4cfc-80b8-31d90644fdec',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '967c1e35-73d1-4fcf-bdc7-3185fc6d946e',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '38fe3bb8-1512-43c6-9224-cb045c4968d5',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd4779871-f4a7-450d-8f3a-26e452737bd0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'abde762c-42a4-4106-b005-7d46c8670d1a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'bfdd4743-c583-45c9-8084-320465c88363',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'abccbae5-fd37-4b92-a70e-b82bdc309489',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd4ba91ce-f56d-4f5a-8066-3aaab091e07b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '42c3c74a-c8d7-44d7-8c93-b5b1a472d1e7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '644841c5-fce4-4f29-98d1-2b1e03162065',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd4e1635b-f5d2-42f2-a742-140b82b9dd14',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '829f7d6b-c25b-4866-8507-0d5f14bc69c6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '28a0325c-b27a-49f6-9db5-4b54d8752f12',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd5129969-eb71-4698-9d84-94a52fe1e556',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6e87733e-b2e2-4523-a303-52f26e4271c4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd52a60a5-e5e8-4091-ab97-c18e418ef7a5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '526bb2f5-25f8-4d13-b4f3-faa18a0e5e09',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd0f32754-3655-4f4f-822d-c73492db6dc6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '85f86feb-0d01-4855-b1d2-88275502e593',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd56cce8a-836c-41e7-8d26-c6dcd15eabc7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2176f2c0-9661-44b2-b7ab-9dbe71ee2896',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c63843d4-9a8c-47e0-8f84-203325ca5cca',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e6d77cb2-bdd8-4a68-9a4d-7f8d800522d9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd6e24671-9d7f-4075-bd78-b9b545f00590',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '640db70a-21d3-434a-a868-c7f729ae8c8c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd7677cbe-833f-45e5-b789-f754ba4b6c3b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '03bbe2f5-7dfd-4236-8fd8-a38c7578da8a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b3d007ce-8110-41d0-b0f4-6342ccef435c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd7ea38d9-ea93-48e0-9968-7087483e1466',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6410b5fe-fbce-463b-bed1-83a57241dae5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'ba1ee02b-8c17-4240-a1fe-fc7bc0889dc2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd7f48d9b-7546-45f2-a567-9551d12294f1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cf544ca1-ebaf-4a8a-819a-d1fb08fc3ad9',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c21130a6-4bd4-4f83-b007-c51fbaf22792',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'd8656887-e7cf-4cc3-a644-6f2e35d0dfac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'cb64d2a9-c158-40a6-a0dd-e73047b44b88',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8e649304-2a31-4ecf-bdbb-c228cf055f2b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '694bee31-b3f7-4b2c-a04e-a105ba0b0ad5',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'dade0916-1d6d-48ae-badc-563560a81142',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8a83e104-bb1d-49fb-9a71-6b9338b817cc',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '62942654-bcca-41de-9265-6e8cf4b99f50',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'db4ed7c5-d9c9-401c-8144-3f3dee865c7f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '03bbe2f5-7dfd-4236-8fd8-a38c7578da8a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3d2ca520-9645-4b3a-b972-a267df8b2bf6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '938a9a9c-366e-4bd3-b926-8142bf03cad0',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'dc3127d1-c50c-4b63-9e13-33d592bfd941',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a3a63f5a-7168-4067-bc0d-eda856c8f14d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '477edaa9-e49f-4e31-bbd6-a38dc7fda903',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4f3eb8dc-67c4-451c-85ea-909d70290095',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'dc38a156-e1eb-487d-b3cb-f6f47f7ce5d2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '89598fb4-97f8-48ff-af43-80d9ee7e1ae5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '56667df2-f8a7-4821-a67a-aaa53824efae',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'dc53f294-030f-4d13-92ef-d9ec0e39eea8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'abccbae5-fd37-4b92-a70e-b82bdc309489',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a9334259-f1c4-4760-9b08-33b176ed6ee8',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '14ca1f98-ce4b-4a58-a158-1a3a4630626d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'df2017e5-1d4c-4279-add5-bbbcd485c097',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'af37a854-eccd-4c8a-a346-36e11dbab775',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0364dc50-2739-469b-9790-81f7100994b4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'df754651-c615-4638-81a6-cf537f68067d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b46e6f2d-617c-401a-910d-f05edd81c50d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1cca5204-7fb2-4b39-aa6e-19f9555fa32c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e02639f9-747e-45df-a078-b37e90fa3298',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '53b0ff28-42df-47e1-b87d-82ceb9f83b05',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '522e61c1-3a97-44ec-ab12-a91f66091c20',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'abccbae5-fd37-4b92-a70e-b82bdc309489',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e0b5f9e8-4bd9-4a27-9cc8-d5f7db43bdb8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '105e3e34-5bd0-4400-b9af-7fd414c85482',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1c4cbdef-c39c-46c7-aa1a-9be720fc9c2f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e1250a9f-2173-4c09-8be1-78c043645ab2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'e38ec9ed-0ad5-4d0e-8f74-11ec36acc454',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e05f3e9c-cb0c-4145-962e-81c6cd68ea40',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e17b13dc-5e6c-463d-8bf8-d4a06ba8f467',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2450c828-8260-4947-b567-a37e131b06a6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'afb38ea7-e75d-44a4-bd0d-30c4d5da0743',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1cca5204-7fb2-4b39-aa6e-19f9555fa32c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e1ce878d-f36e-4835-ad59-d2886b5265ca',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '039ac101-380b-4a05-8604-c4faf44b6b64',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1569cae1-ae6e-468c-b5ff-51dd7a3c82c2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e37709d6-d5d5-485a-bb97-aa512f8e42c2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '21938c0f-3071-4f41-a822-ff24a6d1d572',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '30e52956-87cd-492f-8b50-d8e16c7eccf0',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6d1d14c0-7542-4503-adf1-d47a9f823026',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e38d85ed-39c5-4adb-b0bc-0b0d74371fbb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd2c2146a-ccec-405f-9012-170d90d0bf5d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '71c04918-0190-4859-b257-82d2fc7f94b2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e40ec965-1fd7-4ea9-8042-5895dc556230',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a4e8f81e-c257-4df1-9fc0-bbe14989c5f5',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b3795065-daa0-4242-aedc-0e9661dd092f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e4b1819c-2e0f-481d-9a3b-90df3a736187',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '1823c069-c9ef-423d-94c0-9aac103dc874',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '6dfb3e19-e82a-4b99-82e5-096dc24c399e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e4fd58c5-a7e6-413a-91d2-cc4480955604',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f7af3645-c6b0-45e9-893c-f97fdbdd5f92',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '32584253-c1b1-48fe-8232-f839149c10d4',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e560df9e-9b63-47db-9a9e-d706438fceaa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c5b93454-61a4-4fe6-b8a8-2056f4fa1651',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd1192969-bdbc-48ab-be58-31b4e3ef6552',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e69a43f7-5221-4da1-a8e6-6e4f919dc18b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '13873d75-772f-4442-a52c-b54a502804fa',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '14a46101-2659-4a49-b2f9-a8dfbc44d2bd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e6c646c9-4045-4304-b66e-71f9ae441bdd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b2fe075e-a509-4656-b4dc-1bbce64ab179',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f147cbf0-fc76-4573-9668-701a66f224f8',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4b5df6ca-60ad-4c30-9fce-e49ac72031e8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e9594d97-e2f0-41c6-907c-02868ac1c0cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '157bf662-5ced-4ccf-8bb4-f0c5e44c7721',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '076360e7-6dae-40f9-8760-d82c619b92b5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'a68d6c23-0d15-48a9-9a52-16d805324145',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'e9d7b2d0-635e-4fe2-860f-67385e6f2d56',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'a745029f-2e5f-4a3d-8b71-980c154caab9',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd269e1c8-4e74-48ac-9d38-646c0bdf67d9',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ea105f49-cb02-4662-b075-00ed80dc47a7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'ddbc72ae-7239-41f5-8704-c5e7fd64208f',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '31ece040-e502-4b0d-8022-79dbf2e7fa6f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'eace0f53-58d9-4208-b1d5-93744acc3508',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8626f0ba-d955-47cc-94a9-67ca1bc7c783',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e7d597ae-7064-4340-b91d-b91bbbae2ec0',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'eaef616f-9cb4-4a94-a96f-fba9e74a20ab',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '46908382-db94-4264-9e2a-1afac5167f5c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8f484ccf-6bec-4cfd-9d77-f23192f05011',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '8d1fac6f-8793-4eec-b578-53d92fc48de1',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'eb24bf3d-3336-4a4d-9f03-36a79218ef20',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '9826d113-8a8d-4c32-bb11-881dc8a43033',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b8e39bc2-ef7a-4417-8ff6-9a0d91f7e094',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'eb48d02b-30c8-46ba-ac1c-dec1717598da',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'eeabef0e-1821-487a-b0e4-7795b6a2095e',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c36fff3b-2fb8-4e30-863f-7925dc97d314',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '64357b2c-7022-4c94-83a9-eba0d1bb8983',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'eb903e6a-16c1-48a2-bad5-caec7e4bbafe',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '9acef95c-797d-4b4a-ae66-4c72ee31d0b4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ee9be357-fa08-4541-94e9-4af8b8483dcd',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4f3eb8dc-67c4-451c-85ea-909d70290095',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ebab9bf8-6d76-4be8-8e6e-c9424ea70366',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4680fed8-3e15-4cbe-80af-21ea90753c0a',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '24d0dcc9-67bb-4977-adc5-811128dbe4e2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4a20c995-8663-4805-beb0-69da66f428e9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'edd3372b-5299-42cf-9aea-f07591447bad',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'd2c2146a-ccec-405f-9012-170d90d0bf5d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6d3ffcc2-84e3-4820-81b8-b706fbbfa762',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '0ca8f428-3b5f-4114-b00c-74418216a78d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'edda0b26-43a5-4d9b-9803-e5239d1cfe28',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '008ee6df-b92e-422f-8208-426dd7968e19',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '96809068-d9cd-44b2-bae8-e31d696b0dc5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'bbd13c71-37ae-40fd-8540-20e1c3832867',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ee5acd30-95df-49d7-b9aa-7a69a56dcb00',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3f47c1c0-9ff8-416f-b7d2-eb887eeda728',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e62b566d-c1ae-4f7b-8fb4-93af889b43ac',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '1cca5204-7fb2-4b39-aa6e-19f9555fa32c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'ef1733f4-4c1a-4ff3-a6c7-6e4a893a8dc0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b71202ad-b064-4046-82b3-6f209f4d4631',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '804d8e52-7c9a-4c6d-922e-dfd737a5a113',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'efec0fb6-415d-493f-85a2-56174ef0b609',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '672c4fae-7b33-49d9-bfe8-ffb7866f5ae4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '07381540-434c-43eb-9bc9-3c9700429414',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'abccbae5-fd37-4b92-a70e-b82bdc309489',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f13d095d-b656-4c97-8897-02b4dc72bbb2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'f829b38e-92ad-42ea-ad60-e3acf000d45a',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4423aaf0-a9c2-475a-84c0-0789d98de29c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f16fd9d4-514d-474e-97aa-cabf42551201',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '46908382-db94-4264-9e2a-1afac5167f5c',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '9b413732-dfb4-4ef8-b33a-07079a7f5118',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f0006594-2259-4a5f-bfdb-1280512dd51d',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f18ba2f8-1634-412c-b97b-b6edb49cdbe0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '8ad74980-eb8f-45d0-853c-41da34d110cf',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '7d778c79-ac63-45ac-84ee-8fdd7c488a70',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f31399da-26e5-4c33-b796-deaa7d56550b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '3e88a6f2-ec4e-48ac-ba69-6457a6abeafa',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'c7d89269-b083-4cdf-a8bc-155ec4c42e17',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '98c3bf35-61a6-4de6-98eb-176e47df9a39',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f322a83f-ae62-4a85-b13f-db086ce9f33c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e363d49d-d2d0-4b88-bbba-4f6fe8875ffb',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'cd4fc747-7a86-433d-873f-8b6c4645ed4c',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f3333cc7-d1ad-4737-a5fe-04db844473b8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a78eeeaa-6bff-41c9-9dec-9dcc359bef33',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f3eea2df-e0ff-45bf-84fe-55fb3248e401',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '35624642-a46d-45f8-b9ce-f2da32e4f3be',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '2a0c016b-c878-4ce2-8bba-20aec1e8652b',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'bf11f39f-0093-49ff-bb0a-b63e26b94e04',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f561dc13-ed88-4e9d-8b5e-1fb22078b5c4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '008ee6df-b92e-422f-8208-426dd7968e19',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5adc5a97-635c-4d69-814e-b4555ca6eda5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '93f65377-853f-4e9f-9a06-c58b84ec749e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f610e2e9-3b4b-44df-b32f-cd4f9f922cda',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'bb271af3-0a0b-46a5-b586-0a1a80fb4b7d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '3c3cbdaa-6714-4757-bf9a-ae12395ae055',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '526bb2f5-25f8-4d13-b4f3-faa18a0e5e09',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f6dce35c-8019-44f3-b32e-f8926b431c35',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '1b498153-3e15-4021-8510-ce19b34286cc',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b10c43ad-8f1b-49dc-a66b-57bd264546c2',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '425f1312-03c2-4e10-b058-4032f8a92af8',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f7094b7c-b9a9-4a69-98d4-3ea0ef0156d0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '221d34c9-3ad8-44df-b030-59d15e476f94',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'ad6a8d22-d573-4b15-94d2-7882b9288c72',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f45c6edc-9cf4-45bb-ae09-0bc57d1c8213',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f85710e2-8312-4d70-bbe1-734352027cd3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: null,
          intermediary: [
            {
              id: '2f2a24df-b569-461e-8696-03c9ba73f543',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '3f75644b-4930-459e-9312-2a55444bd17b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f8595dc0-46ac-43be-9107-aa81f12aadb0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '0d37a3b9-f87e-4c66-ab31-53eafc4fa859',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '532fd2ac-2ee4-40a7-97e4-c3d14975e1ff',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f9043aa8-2401-4ab1-9437-fd9787c0cc23',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'c4283b06-924b-4719-b8a4-9ec9fa2b341b',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'd8733ff6-2220-4fac-9828-037f5b456f13',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '084692b3-d542-4053-bcfd-8ed1ac0de31e',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'f9dd4771-20ad-47a0-afde-b99e4d85d2c8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b5f48aa2-7a75-4102-b624-4b665dbd4738',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '98c3f1d6-fd98-44c4-982e-4b7067a938d1',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '89425ca3-97dd-4b0d-9cf0-39b35617f197',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'fa4ae7b5-9218-46ef-849c-07d70044bd41',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '5c8d7f3a-2e0a-4717-863a-5da48a68a477',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '9b170552-de82-412e-a40f-aa572ea2866b',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'fc0124ae-eb97-48fe-a59c-738b90a2f91c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '008ee6df-b92e-422f-8208-426dd7968e19',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '7cc8d8b0-d78f-4669-9e16-bfe8bd6ea1b5',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '61781969-f149-4990-b6db-5f74979f75d2',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'fc2de450-0aa4-43ae-b13c-8548a65cdd44',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'a82443f5-7b76-4e18-a596-dabc64126fc6',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '989205e8-ae30-4963-acfe-7d6246f8ba68',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'fce2edc6-3f1e-46b0-b81a-b662a08d8c80',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '4db76740-aae6-4eb3-8e3c-e6c240e82f3d',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '491239aa-cf38-4fde-b568-5e5d8262646f',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4e728134-30f6-44e7-b6d9-ac9d4bd269ab',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'fd73b279-b602-4748-8456-ebd5eafec874',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'b6cb1535-d0a0-4edd-903c-e6e96ed53061',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'e944c3b4-55b4-472b-bdc9-af3a02de2555',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: '4a20c995-8663-4805-beb0-69da66f428e9',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'fdcbaf3f-ecbb-48b6-ba00-8ba4d875711b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'f7b5b6a2-5bb1-429d-af40-9433e6e17bbf',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'b75f3387-757b-4d73-a459-6f11a9c578bd',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'd6268573-2911-49c0-b8b0-6a121757300f',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'fe2b3da0-adb0-465d-b310-0f3a159eaa66',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '2176f2c0-9661-44b2-b7ab-9dbe71ee2896',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: 'cd5015a8-5485-431a-ac8a-879c9850408c',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'fe4fcb26-ef5b-4687-a258-a48a798719ac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: 'ae78fb7d-f1cb-4388-bf26-35a7bb3712e3',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '77d242d0-7d0c-4883-9f3a-847783f9d27d',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'f05c5432-2165-4222-8807-b23eb40477b6',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
      {
        id: 'fec39aa4-3f22-4726-938c-846dca1ed19b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: null,
        properties: {
          category: 'traversal',
          direction: 'undirected',
          origin: {
            id: '52a5a68f-3e41-45a8-99d1-0cb174ce9860',
            feature_type: 'unit',
          },
          intermediary: [
            {
              id: '6335ca9e-ddf1-43f9-9584-11156c2cadd7',
              feature_type: 'opening',
            },
          ],
          destination: {
            id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
            feature_type: 'unit',
          },
          hours: null,
        },
        feature_type: 'relationship',
      },
    ],
    section: [
      {
        id: '05c8f707-9eea-4d5d-ba1e-15c799725b1b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71402278229, 38.25747114794],
              [-85.71397892044, 38.25745537916],
              [-85.71371485668, 38.257913168],
              [-85.71401722185, 38.25802234699],
              [-85.71405729036, 38.25795477906],
              [-85.71394481042, 38.25791365155],
              [-85.71413837206, 38.25758724557],
              [-85.7139868008, 38.2575318243],
              [-85.71402278229, 38.25747114794],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71392661437, 38.257738863075005],
            type: 'Point',
          },
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          address_id: null,
          correlation_id: null,
          parents: ['fbf1aabe-023e-4e92-a6b6-81712e66602f'],
        },
      },
      {
        id: '0e320b82-7616-4050-8bfb-7cb0780d5e06',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71406706643, 38.25750490606],
              [-85.7141709846, 38.25754175497],
              [-85.71411198424, 38.25764434879],
              [-85.71399511098, 38.25760290614],
              [-85.71397614738, 38.25763588132],
              [-85.71392428742, 38.25761749204],
              [-85.71396629702, 38.25754444289],
              [-85.71403111207, 38.257567426],
              [-85.71406706643, 38.25750490606],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.714101263235, 38.257574627425],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          address_id: null,
          correlation_id: null,
          parents: ['20ddff7d-1518-4b5d-b198-75de64ad5900'],
        },
      },
      {
        id: '214af8a6-b3b8-455b-9d88-4d8e88b27965',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71375032862, 38.25815144479],
              [-85.71379106961, 38.25808523317],
              [-85.71386526494, 38.25806006326],
              [-85.7139240022, 38.25796094742],
              [-85.71383667319, 38.25793080346],
              [-85.71390967625, 38.25780464467],
              [-85.71380276958, 38.25776735761],
              [-85.71360923799, 38.25810312855],
              [-85.71375032862, 38.25815144479],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.713766620095, 38.2579594012],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          address_id: null,
          correlation_id: null,
          parents: ['cc3fc769-3257-4d12-bf7c-606d13838aa2'],
        },
      },
      {
        id: '5c981fd2-f6ae-42d1-8ebe-bb56b7c319f0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71383056989, 38.25776597708],
              [-85.7139422084, 38.25780575847],
              [-85.71393400117, 38.25781995976],
              [-85.71396801826, 38.25783208144],
              [-85.7139876369, 38.25779813458],
              [-85.7138419813, 38.25774623149],
              [-85.71383056989, 38.25776597708],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.713944711925, 38.257789156465],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          address_id: null,
          correlation_id: null,
          parents: ['829b64d2-6923-49de-afec-790144e9c33a'],
        },
      },
      {
        id: '606d4dc4-c5a8-46c8-9adb-df1efa140e0b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71407261531, 38.25827636439],
              [-85.71409805628, 38.25823323769],
              [-85.71405988686, 38.25822007727],
              [-85.71407021913, 38.25819923108],
              [-85.71400397143, 38.25817638957],
              [-85.71396819818, 38.25824036248],
              [-85.71407261531, 38.25827636439],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71403312723, 38.258226376980005],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          address_id: null,
          correlation_id: null,
          parents: ['cc3fc769-3257-4d12-bf7c-606d13838aa2'],
        },
      },
      {
        id: '6324d889-1ecb-408f-a2d9-9c8c5d6e8e5a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71411955452, 38.25798798058],
              [-85.71401972377, 38.25816372904],
              [-85.71409080674, 38.25818862513],
              [-85.71410649091, 38.25816101375],
              [-85.7141380763, 38.25817207621],
              [-85.71422222289, 38.25802393922],
              [-85.71411955452, 38.25798798058],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.714121900615, 38.258088302855],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          address_id: null,
          correlation_id: null,
          parents: ['cc3fc769-3257-4d12-bf7c-606d13838aa2'],
        },
      },
      {
        id: '6c922fbf-dc7c-48c9-a8ac-55084c20058e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71454606729, 38.25740605857],
              [-85.7146039914, 38.25730941352],
              [-85.71453558528, 38.25728413324],
              [-85.7145491019, 38.25726153115],
              [-85.71455383987, 38.25726327824],
              [-85.7145540726, 38.25726288907],
              [-85.71464685801, 38.25729710294],
              [-85.71464431845, 38.2573013495],
              [-85.71464526876, 38.25730169992],
              [-85.71462978342, 38.25732759401],
              [-85.71462874318, 38.25732721043],
              [-85.71462680187, 38.25733045661],
              [-85.7146193951, 38.25734284199],
              [-85.71462112212, 38.25734347881],
              [-85.71461839737, 38.25734803507],
              [-85.71460762779, 38.2573660436],
              [-85.71460538588, 38.25736979246],
              [-85.71460355214, 38.25736911628],
              [-85.71459756691, 38.25737912458],
              [-85.7145989464, 38.25737963325],
              [-85.71459712001, 38.25738268728],
              [-85.71458677782, 38.25739998115],
              [-85.71458318676, 38.257405986],
              [-85.71458171013, 38.25740544151],
              [-85.71457496801, 38.25741671546],
              [-85.71454606729, 38.25740605857],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 2R21 through 2R29',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71459122164501, 38.257339123305],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          address_id: null,
          correlation_id: null,
          parents: ['21938c0f-3071-4f41-a822-ff24a6d1d572'],
        },
      },
      {
        id: '7160db3f-b24b-4e75-9736-aad9cecf228a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71373504654, 38.25844671811],
              [-85.71381068925, 38.25847376912],
              [-85.71385086621, 38.25840449844],
              [-85.7137752235, 38.2583774474],
              [-85.71373504654, 38.25844671811],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71379295637499, 38.25842560826],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          address_id: null,
          correlation_id: null,
          parents: ['86762cac-e648-470a-b0e7-8e763288fc0c'],
        },
      },
      {
        id: 'ad2bd003-fd3b-403c-af57-0415b6b3d0a3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71399191619, 38.25832537262],
              [-85.71401034834, 38.25829246239],
              [-85.71406014264, 38.25830979807],
              [-85.71397298462, 38.2584626766],
              [-85.71388153217, 38.25843006552],
              [-85.7138852698, 38.25840407581],
              [-85.71389625896, 38.25838586561],
              [-85.7139265865, 38.2583961965],
              [-85.71396559644, 38.25833455],
              [-85.71399191619, 38.25832537262],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.713975035535, 38.258377569494996],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          address_id: null,
          correlation_id: null,
          parents: ['86762cac-e648-470a-b0e7-8e763288fc0c'],
        },
      },
      {
        id: 'd4413ba1-1906-4598-bb54-dfa800440926',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7142621689, 38.25740622392],
              [-85.71420241898, 38.25750851231],
              [-85.71424985168, 38.2575255963],
              [-85.71427642619, 38.25748010231],
              [-85.71426904919, 38.25747744531],
              [-85.7143022246, 38.25742065093],
              [-85.7142621689, 38.25740622392],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71425232179, 38.25746591011],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          address_id: null,
          correlation_id: null,
          parents: ['20ddff7d-1518-4b5d-b198-75de64ad5900'],
        },
      },
      {
        id: 'de59d6e8-5b6d-41fe-9083-c78aa74cdd7c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461281481, 38.25703103899],
              [-85.71458983842, 38.25700145026],
              [-85.71455778933, 38.25699109015],
              [-85.71453600441, 38.25702970183],
              [-85.71454759095, 38.25703373268],
              [-85.71454147662, 38.25704440923],
              [-85.71459527053, 38.2570627566],
              [-85.71461281481, 38.25703103899],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Hall of Fame',
            frCA: null,
          },
          alt_name: null,
          display_point: {
            coordinates: [-85.71457440961, 38.257026923375],
            type: 'Point',
          },
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          address_id: null,
          correlation_id: null,
          parents: ['157bf662-5ced-4ccf-8bb4-f0c5e44c7721'],
        },
      },
      {
        id: 'e8ef49c1-a44f-43d1-bb57-a9913d98f83e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71392042961, 38.25826287235],
              [-85.71361357731, 38.25815248837],
              [-85.71359903007, 38.2581818398],
              [-85.71375787752, 38.25823981039],
              [-85.71373706554, 38.2582789212],
              [-85.71372974061, 38.25827589413],
              [-85.71371117982, 38.25831039192],
              [-85.71375722616, 38.25833680005],
              [-85.71383538892, 38.25836548692],
              [-85.71387401656, 38.25829624282],
              [-85.71388531765, 38.25827598494],
              [-85.71392481064, 38.25827664641],
              [-85.71392903489, 38.25826735471],
              [-85.71392042961, 38.25826287235],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71382253561501, 38.258258987645],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          address_id: null,
          correlation_id: null,
          parents: ['86762cac-e648-470a-b0e7-8e763288fc0c'],
        },
      },
      {
        id: 'eaa25f8c-714d-4d18-a5c9-8416333c0c18',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71406698108, 38.25801010057],
              [-85.714014313, 38.25810107573],
              [-85.71389605342, 38.25805886193],
              [-85.7139487215, 38.25796788672],
              [-85.71406698108, 38.25801010057],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71398151725, 38.258034481225],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          address_id: null,
          correlation_id: null,
          parents: ['cc3fc769-3257-4d12-bf7c-606d13838aa2'],
        },
      },
      {
        id: 'ef967a51-a0d4-4b9d-844d-7cedd5301b9b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71362952477, 38.25821099127],
              [-85.71362678059, 38.25821582908],
              [-85.71362176575, 38.25821409533],
              [-85.71360946039, 38.25823442045],
              [-85.71363115128, 38.25824767079],
              [-85.71364243894, 38.25825165812],
              [-85.71363093648, 38.25827323246],
              [-85.71368042577, 38.25829193945],
              [-85.7137096356, 38.25823768936],
              [-85.71362952477, 38.25821099127],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71366916151, 38.25825146536],
            type: 'Point',
          },
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          address_id: null,
          correlation_id: null,
          parents: ['86762cac-e648-470a-b0e7-8e763288fc0c'],
        },
      },
      {
        id: 'fecbf3fc-4cac-4f7a-ab19-9a3346ed39c2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71422294127, 38.25768717188],
              [-85.71414088887, 38.25765698872],
              [-85.71420748311, 38.25754536356],
              [-85.71428953551, 38.25757554675],
              [-85.71422294127, 38.25768717188],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'section',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          display_point: {
            coordinates: [-85.71421521219, 38.257616267719996],
            type: 'Point',
          },
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          address_id: null,
          correlation_id: null,
          parents: ['20ddff7d-1518-4b5d-b198-75de64ad5900'],
        },
      },
    ],
    unit: [
      {
        id: '008ee6df-b92e-422f-8208-426dd7968e19',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71430852443, 38.25749994839],
              [-85.7142930789, 38.25749441776],
              [-85.7142936931, 38.25749336011],
              [-85.71429479076, 38.25749375304],
              [-85.71429728743, 38.25748940644],
              [-85.71429985655, 38.25748494535],
              [-85.71429858809, 38.25748449111],
              [-85.71429883402, 38.25748406761],
              [-85.71424913675, 38.25746627235],
              [-85.7142279952, 38.25745870213],
              [-85.71420666548, 38.25745106452],
              [-85.71419061396, 38.25747870496],
              [-85.71418696896, 38.25748498159],
              [-85.71418322304, 38.25749143199],
              [-85.71416150775, 38.25752882534],
              [-85.71415968396, 38.25752817229],
              [-85.71415602013, 38.25753448134],
              [-85.71415228204, 38.25754091826],
              [-85.71415394289, 38.25754151297],
              [-85.71414634428, 38.25755459763],
              [-85.71416753023, 38.25756218374],
              [-85.71418860538, 38.25756973018],
              [-85.71428556687, 38.25760444944],
              [-85.71429564448, 38.257587096],
              [-85.71429688424, 38.25758753992],
              [-85.71430672735, 38.25757059028],
              [-85.71431646868, 38.2575538159],
              [-85.71431530064, 38.25755339765],
              [-85.71432018718, 38.25754498312],
              [-85.71431566252, 38.25754336297],
              [-85.71431793653, 38.25753906709],
              [-85.71430819255, 38.25753557804],
              [-85.71429788541, 38.25753188733],
              [-85.71429675853, 38.25753438907],
              [-85.71429002739, 38.25753200388],
              [-85.71430852443, 38.25749994839],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71423326573, 38.25752775698],
            type: 'Point',
          },
        },
      },
      {
        id: '00e45e17-9c20-46c2-886f-27c977e4adbc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71409455729, 38.25743630288],
              [-85.71411015669, 38.25740859609],
              [-85.71411220774, 38.25740503153],
              [-85.71411427511, 38.25740143859],
              [-85.71411440149, 38.25740114505],
              [-85.71401898346, 38.25736793699],
              [-85.71399913925, 38.25740309483],
              [-85.71409455729, 38.25743630288],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71405677037001, 38.257402119935],
            type: 'Point',
          },
        },
      },
      {
        id: '012cc231-5afd-4bce-84cd-b6130df91cf8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71414284456, 38.25738207815],
              [-85.71413146223, 38.25740274711],
              [-85.71410328804, 38.25739318033],
              [-85.71411467037, 38.25737251137],
              [-85.71413114653, 38.25737810599],
              [-85.71413587954, 38.25737971312],
              [-85.71414055586, 38.25738130101],
              [-85.71414284456, 38.25738207815],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7141230663, 38.25738762924],
            type: 'Point',
          },
        },
      },
      {
        id: '01bb32af-a025-4149-852f-074f8fe5e3b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71411318307, 38.2577071673],
              [-85.71415189989, 38.25772100781],
              [-85.71416337899, 38.25770120835],
              [-85.71412466217, 38.25768736784],
              [-85.71412034493, 38.25769486906],
              [-85.71411588343, 38.257702455],
              [-85.71411318307, 38.2577071673],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71413828102999, 38.257704187825],
            type: 'Point',
          },
        },
      },
      {
        id: '01db2d2a-4add-4253-a292-b25d774ca483',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71446548895, 38.25742374639],
              [-85.71442310433, 38.25740842193],
              [-85.71440790704, 38.25743433928],
              [-85.71445029165, 38.25744966374],
              [-85.7144560261, 38.25743998978],
              [-85.71445807251, 38.25743639071],
              [-85.71445991754, 38.25743314582],
              [-85.71446548895, 38.25742374639],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714436697995, 38.257429042835],
            type: 'Point',
          },
        },
      },
      {
        id: '01ff79c6-1e2a-49a6-bf79-5a43daffc2bd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71401500612, 38.25828613253],
              [-85.71404132819, 38.25823965564],
              [-85.71404331336, 38.25824034877],
              [-85.71404533649, 38.2582368204],
              [-85.7140473361, 38.25823333304],
              [-85.71404552696, 38.2582327012],
              [-85.71405601415, 38.25821418396],
              [-85.71407624602, 38.25822124894],
              [-85.71408093598, 38.25821296786],
              [-85.71408285873, 38.25821363858],
              [-85.71408423021, 38.25821116589],
              [-85.71408588548, 38.25820818152],
              [-85.71408402837, 38.25820753225],
              [-85.71409682893, 38.25818493028],
              [-85.71405288966, 38.25816958667],
              [-85.7141219582, 38.25804763209],
              [-85.71411890179, 38.25804656478],
              [-85.71414913127, 38.25799318842],
              [-85.71415145189, 38.25799399878],
              [-85.71415546565, 38.25798691165],
              [-85.7141591601, 38.25798038833],
              [-85.71415648994, 38.25797945591],
              [-85.71420776809, 38.25788891368],
              [-85.71420949242, 38.25788951582],
              [-85.71421091845, 38.25788699785],
              [-85.71421247634, 38.25788424707],
              [-85.71421058336, 38.25788358604],
              [-85.71422932822, 38.25785048806],
              [-85.71421716242, 38.25784623974],
              [-85.7142057269, 38.25784224643],
              [-85.71419286835, 38.2578649509],
              [-85.71419193837, 38.25786462615],
              [-85.71418821832, 38.25787119468],
              [-85.71418383969, 38.25787892606],
              [-85.71418512381, 38.25787937448],
              [-85.71414992668, 38.25794152235],
              [-85.71414741786, 38.25794064626],
              [-85.71414569166, 38.25794369422],
              [-85.71414374251, 38.25794713584],
              [-85.71414649311, 38.25794809636],
              [-85.71414470531, 38.25795125308],
              [-85.71413503908, 38.25794787762],
              [-85.71410002869, 38.25800969571],
              [-85.71411012243, 38.25801322045],
              [-85.714108355, 38.2580163412],
              [-85.71410716794, 38.25801592667],
              [-85.71410529202, 38.258019239],
              [-85.71410344525, 38.25802249984],
              [-85.71410475146, 38.25802295597],
              [-85.71409220239, 38.25804511394],
              [-85.71404818516, 38.25802974307],
              [-85.7140337692, 38.25805519742],
              [-85.71403626007, 38.25805606724],
              [-85.71403242059, 38.25806284664],
              [-85.71407253631, 38.25807685509],
              [-85.71405411218, 38.25810938666],
              [-85.7140513182, 38.258108411],
              [-85.7140478194, 38.25811458885],
              [-85.71404453155, 38.25812039422],
              [-85.71404753194, 38.25812144196],
              [-85.71402505447, 38.25816113049],
              [-85.71397779819, 38.25814462858],
              [-85.71397742863, 38.25814528111],
              [-85.71397543587, 38.25814458523],
              [-85.713968559, 38.25815672774],
              [-85.71396228526, 38.2581678053],
              [-85.71396442484, 38.25816855244],
              [-85.71396417247, 38.25816899806],
              [-85.71401129331, 38.25818545268],
              [-85.71400925397, 38.25818905354],
              [-85.7139634888, 38.25817307233],
              [-85.71391799714, 38.25825339701],
              [-85.71393225079, 38.25825837439],
              [-85.71393060253, 38.25826128473],
              [-85.71394172443, 38.25826517223],
              [-85.71395516678, 38.25826987081],
              [-85.71395757848, 38.25826561248],
              [-85.71397753167, 38.25827258013],
              [-85.71397498012, 38.25827708549],
              [-85.71398585094, 38.25828075108],
              [-85.71399278704, 38.25828308989],
              [-85.71399502025, 38.25827915346],
              [-85.71401500612, 38.25828613253],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Hallway',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71407366268, 38.25806418948],
            type: 'Point',
          },
        },
      },
      {
        id: '027b31e3-0ec6-4ee4-a5ff-e873b2750396',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7145057533, 38.25747628469],
              [-85.71450400751, 38.25747936642],
              [-85.71450350978, 38.25748024504],
              [-85.71452276249, 38.25748697001],
              [-85.71452606668, 38.25748812416],
              [-85.7145298941, 38.25748946108],
              [-85.71453423359, 38.25749097686],
              [-85.71453555141, 38.25748864326],
              [-85.71454013777, 38.25749024527],
              [-85.71454257882, 38.25748602749],
              [-85.71454489709, 38.25748202186],
              [-85.71454019009, 38.25748042904],
              [-85.71454792703, 38.25746677154],
              [-85.71455232709, 38.2574590044],
              [-85.71452161898, 38.25744827806],
              [-85.71451701842, 38.25745639914],
              [-85.71450937748, 38.25746988717],
              [-85.71450766302, 38.2574729136],
              [-85.7145057533, 38.25747628469],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71452791843501, 38.25746962746],
            type: 'Point',
          },
        },
      },
      {
        id: '029e455f-a9c3-4eb5-974f-badff5e174ee',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457777332, 38.25685738432],
              [-85.71458200234, 38.25685882798],
              [-85.71458862645, 38.25686108926],
              [-85.71459540594, 38.25684884371],
              [-85.71458854975, 38.2568465178],
              [-85.71458442866, 38.25684509571],
              [-85.71458091825, 38.25684388434],
              [-85.71457365686, 38.2568414192],
              [-85.71456687737, 38.25685366475],
              [-85.7145733223, 38.25685586487],
              [-85.71457777332, 38.25685738432],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714581141655, 38.25685125423],
            type: 'Point',
          },
        },
      },
      {
        id: '02b15a0a-9726-48a6-954f-accd965e4a75',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71397580706, 38.25831476176],
              [-85.71396692524, 38.25831176685],
              [-85.71395804726, 38.25830877324],
              [-85.71395225238, 38.25830681924],
              [-85.71396951999, 38.25827524436],
              [-85.71397498012, 38.25827708549],
              [-85.71398585094, 38.25828075108],
              [-85.71399278704, 38.25828308989],
              [-85.71400321239, 38.25828660527],
              [-85.71398594479, 38.25831818015],
              [-85.71397580706, 38.25831476176],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71397773238499, 38.258296712255],
            type: 'Point',
          },
        },
      },
      {
        id: '02e1bc1c-f29a-443c-b159-840d9b8a5f21',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71444974726, 38.25682854891],
              [-85.7144621985, 38.25680678663],
              [-85.71445849007, 38.25680544667],
              [-85.71445365566, 38.25680376416],
              [-85.71444893009, 38.25680211954],
              [-85.71442910837, 38.25679511275],
              [-85.71441665713, 38.25681687503],
              [-85.71444974726, 38.25682854891],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714439427815, 38.25681183083],
            type: 'Point',
          },
        },
      },
      {
        id: '03bbe2f5-7dfd-4236-8fd8-a38c7578da8a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71392338409, 38.25795046201],
              [-85.7139309656, 38.25795321132],
              [-85.7139287974, 38.25795689977],
              [-85.71393827655, 38.25796025005],
              [-85.71394764527, 38.25796356131],
              [-85.71394942266, 38.25796054101],
              [-85.71405392042, 38.25799843542],
              [-85.71405771367, 38.25799215069],
              [-85.71406145719, 38.25798561994],
              [-85.71406515357, 38.25797917143],
              [-85.71409733538, 38.25792578929],
              [-85.71406534466, 38.25791402154],
              [-85.7140860303, 38.25787907869],
              [-85.7141173772, 38.25789061298],
              [-85.71412321496, 38.25787928549],
              [-85.71422442649, 38.25791598824],
              [-85.71429171656, 38.25780157455],
              [-85.71423625462, 38.25778146212],
              [-85.71421474949, 38.25781802744],
              [-85.71420157524, 38.25781325001],
              [-85.71420241195, 38.25781182734],
              [-85.71419791485, 38.25781019654],
              [-85.71419339529, 38.25780855759],
              [-85.71419215194, 38.25781067167],
              [-85.71418807792, 38.25780919429],
              [-85.714211207, 38.25776986775],
              [-85.71419498612, 38.2577639855],
              [-85.71418583396, 38.257779547],
              [-85.71413944526, 38.25776272484],
              [-85.71412460642, 38.25778795545],
              [-85.71407282973, 38.25776917942],
              [-85.71407451023, 38.2577663038],
              [-85.71407761951, 38.25776737924],
              [-85.7140794335, 38.2577642415],
              [-85.71408128868, 38.25776092862],
              [-85.71407906452, 38.25776017052],
              [-85.71411352285, 38.25770159897],
              [-85.71411588343, 38.257702455],
              [-85.71412034493, 38.25769486906],
              [-85.71411822678, 38.25769410094],
              [-85.71412391325, 38.25768443172],
              [-85.7141106179, 38.25767966278],
              [-85.71409477967, 38.25767398173],
              [-85.71408189536, 38.25769588848],
              [-85.71409060142, 38.2576990456],
              [-85.71408721245, 38.2577048079],
              [-85.71408484645, 38.2577039499],
              [-85.71408066241, 38.25771106406],
              [-85.71407647285, 38.2577181876],
              [-85.71407857178, 38.25771894874],
              [-85.7140523237, 38.25776357858],
              [-85.71403142838, 38.25775600121],
              [-85.71402533366, 38.25776636411],
              [-85.71402014472, 38.25776448242],
              [-85.71399422548, 38.25780855311],
              [-85.71396817182, 38.25785285231],
              [-85.71397300676, 38.25785460562],
              [-85.7139660064, 38.25786650837],
              [-85.71397486189, 38.25786971967],
              [-85.71396359764, 38.25788887232],
              [-85.7139568187, 38.25788641517],
              [-85.71395114859, 38.25789630695],
              [-85.71394555919, 38.25790605793],
              [-85.71394880629, 38.25790723654],
              [-85.71392338409, 38.25795046201],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714107550325, 38.257836208575],
            type: 'Point',
          },
        },
      },
      {
        id: '050455df-a87a-4b1e-987b-243c34fc6940',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71468329065, 38.25682745218],
              [-85.71469778601, 38.25680139779],
              [-85.71468357386, 38.25679652232],
              [-85.71468521739, 38.25679356874],
              [-85.71466685203, 38.25678725136],
              [-85.71466530876, 38.25679002469],
              [-85.71465350856, 38.25678597663],
              [-85.7146389049, 38.25681222568],
              [-85.71464030045, 38.25681270442],
              [-85.71464457116, 38.25681416949],
              [-85.71464949453, 38.25681585845],
              [-85.71468329065, 38.25682745218],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Public Affairs',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71466834545501, 38.256806714405],
            type: 'Point',
          },
        },
      },
      {
        id: '05d05ce3-5a8e-456b-ba1f-649315094fbe',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71476302738, 38.2569913456],
              [-85.71475138231, 38.25698729883],
              [-85.71476008775, 38.25697185228],
              [-85.71476182021, 38.25697245488],
              [-85.71476596815, 38.25697389577],
              [-85.71477016878, 38.25697535497],
              [-85.71477173283, 38.25697589905],
              [-85.71476302738, 38.2569913456],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71476155757, 38.25698159894],
            type: 'Point',
          },
        },
      },
      {
        id: '0620bad9-8d47-49c2-9cd2-6ab08412efcf',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71460825606, 38.25698830448],
              [-85.71460594965, 38.25699227707],
              [-85.71460427041, 38.25699167593],
              [-85.71460198966, 38.25699560434],
              [-85.71459821208, 38.25699425202],
              [-85.71459553881, 38.25699329502],
              [-85.71459277643, 38.25699230612],
              [-85.71456904174, 38.2569838094],
              [-85.7145736289, 38.25697590839],
              [-85.71460825606, 38.25698830448],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145886489, 38.256985756365],
            type: 'Point',
          },
        },
      },
      {
        id: '0835c7fc-9280-4bcf-ac4e-1a5457e303a6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71409247616, 38.25724641314],
              [-85.7141052604, 38.25722431614],
              [-85.71415399754, 38.25724209695],
              [-85.71415217689, 38.25724521023],
              [-85.71415036998, 38.25724830001],
              [-85.71414111047, 38.25726393717],
              [-85.71409247616, 38.25724641314],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Room Gr39B',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71412323685, 38.257244126654996],
            type: 'Point',
          },
        },
      },
      {
        id: '084692b3-d542-4053-bcfd-8ed1ac0de31e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71451939843, 38.25744831938],
              [-85.71450692956, 38.25747021696],
              [-85.71447127817, 38.25745769973],
              [-85.71448374703, 38.25743580215],
              [-85.71449092597, 38.25743832418],
              [-85.71449388899, 38.25743936299],
              [-85.7144967873, 38.25744037912],
              [-85.71451939843, 38.25744831938],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7144953383, 38.257453009555],
            type: 'Point',
          },
        },
      },
      {
        id: '0a2dc1d6-b611-4f14-9bcb-65a4d1238d40',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71452057474, 38.25694074582],
              [-85.71455109941, 38.25695065668],
              [-85.71457037915, 38.25691404262],
              [-85.71457305833, 38.25691491251],
              [-85.714574521, 38.25691213475],
              [-85.71457617111, 38.25690900103],
              [-85.71457352441, 38.25690814169],
              [-85.71457814506, 38.25689936662],
              [-85.71457355634, 38.25689787673],
              [-85.71457415395, 38.25689674181],
              [-85.7145634298, 38.25689325985],
              [-85.71456254265, 38.25689494465],
              [-85.71454784179, 38.25689017152],
              [-85.71453485084, 38.25691484261],
              [-85.7145546123, 38.25692125883],
              [-85.71455068946, 38.25692870869],
              [-85.71454291855, 38.2569261856],
              [-85.71454217463, 38.25692759837],
              [-85.71454020137, 38.25692695768],
              [-85.71453965678, 38.2569279919],
              [-85.71453224591, 38.25692558571],
              [-85.71453308928, 38.25692398406],
              [-85.71452993944, 38.25692296135],
              [-85.7145276797, 38.25692725281],
              [-85.7145260641, 38.25693032099],
              [-85.71452440894, 38.25693346429],
              [-85.71452057474, 38.25694074582],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7145493599, 38.256918956679996],
            type: 'Point',
          },
        },
      },
      {
        id: '0b09e3e1-3ab1-4bfb-89ac-96248e9d0a45',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7147701066, 38.25685902228],
              [-85.71478369682, 38.25683505549],
              [-85.71474973399, 38.25682318064],
              [-85.71473614378, 38.25684714743],
              [-85.71473679423, 38.25684738004],
              [-85.71473904386, 38.25684816151],
              [-85.71474140516, 38.25684898177],
              [-85.7147701066, 38.25685902228],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 312',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.7147599203, 38.25684110146],
            type: 'Point',
          },
        },
      },
      {
        id: '0c016dd6-fff0-4c16-97a7-e72ea7cad585',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71456326366, 38.25687639226],
              [-85.71456294965, 38.25687678999],
              [-85.71456136252, 38.25687957017],
              [-85.71455938423, 38.25688303551],
              [-85.71455915251, 38.25688355667],
              [-85.71453705033, 38.25687573632],
              [-85.71453750916, 38.25687493672],
              [-85.71453549829, 38.25687422522],
              [-85.71453757323, 38.25687063908],
              [-85.71453943605, 38.25686736299],
              [-85.71454144768, 38.25686807476],
              [-85.71454615769, 38.25685986673],
              [-85.7145622805, 38.25686557142],
              [-85.71455728505, 38.25687427686],
              [-85.71456326366, 38.25687639226],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714549380975, 38.2568717117],
            type: 'Point',
          },
        },
      },
      {
        id: '0ca8f428-3b5f-4114-b00c-74418216a78d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71460659096, 38.25680263703],
              [-85.71462073763, 38.25677815967],
              [-85.71463408562, 38.25678291647],
              [-85.71463519924, 38.25678098962],
              [-85.71465273187, 38.25678723768],
              [-85.71465203751, 38.2567884391],
              [-85.71466404423, 38.2567927179],
              [-85.71464959502, 38.25681771872],
              [-85.71461956194, 38.2568070159],
              [-85.71461894684, 38.25680808018],
              [-85.71461386203, 38.25680626812],
              [-85.71460898536, 38.25680453023],
              [-85.71460948373, 38.25680366792],
              [-85.71460659096, 38.25680263703],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 306',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714635317595, 38.256797939194996],
            type: 'Point',
          },
        },
      },
      {
        id: '0dfa543e-193d-4b1d-8d4e-31d3616da61c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71475535637, 38.25696259309],
              [-85.71474048105, 38.25698844553],
              [-85.71473094036, 38.25698506059],
              [-85.71472866699, 38.25698901156],
              [-85.71469033994, 38.2569754135],
              [-85.71470748863, 38.25694561008],
              [-85.71474458693, 38.2569587722],
              [-85.71474930512, 38.25696044616],
              [-85.71475414692, 38.25696216399],
              [-85.71475535637, 38.25696259309],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714722848155, 38.25696731082],
            type: 'Point',
          },
        },
      },
      {
        id: '0ffa72ec-782e-4523-9bfc-4c3c6147960a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71460155995, 38.25687785074],
              [-85.71459279289, 38.25689298532],
              [-85.71459111055, 38.25689593227],
              [-85.7145892797, 38.25689913935],
              [-85.71458738153, 38.25690238632],
              [-85.71463374966, 38.25691890816],
              [-85.71464792808, 38.25689437259],
              [-85.71460155995, 38.25687785074],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714617654805, 38.25689837945],
            type: 'Point',
          },
        },
      },
      {
        id: '11c7a7d1-151f-482a-a3ab-1dca0756ca57',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71415629644, 38.2574402454],
              [-85.71414573927, 38.25745881715],
              [-85.71414859187, 38.257459817],
              [-85.71414749046, 38.25746175456],
              [-85.71418974622, 38.25747656546],
              [-85.7142014048, 38.25745605615],
              [-85.71419416928, 38.25745352005],
              [-85.71418961089, 38.25745192128],
              [-85.71418592511, 38.25745063042],
              [-85.71415629644, 38.2574402454],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714173572035, 38.25745840543],
            type: 'Point',
          },
        },
      },
      {
        id: '14a46101-2659-4a49-b2f9-a8dfbc44d2bd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71437041649, 38.2575487926],
              [-85.71432858165, 38.25753366646],
              [-85.71432551738, 38.25753889206],
              [-85.71432413662, 38.25753839283],
              [-85.71431843691, 38.25754811275],
              [-85.71431982295, 38.2575486139],
              [-85.71431498326, 38.25755686718],
              [-85.71435681281, 38.25757199141],
              [-85.71435779104, 38.25757032414],
              [-85.71435971011, 38.25756705057],
              [-85.71436167693, 38.25756369554],
              [-85.71437041649, 38.2575487926],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Office',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714342699875, 38.257552828935005],
            type: 'Point',
          },
        },
      },
      {
        id: '14ca1f98-ce4b-4a58-a158-1a3a4630626d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71444974549, 38.25671656417],
              [-85.71443139276, 38.25674892852],
              [-85.71443643572, 38.25675069965],
              [-85.71444148028, 38.25675245609],
              [-85.71444598186, 38.25675402197],
              [-85.7144842963, 38.25676742665],
              [-85.71450264903, 38.25673506231],
              [-85.71444974549, 38.25671656417],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714467020895, 38.256741995409996],
            type: 'Point',
          },
        },
      },
      {
        id: '1532027c-1934-450c-8aef-8427bc89c42d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7145299152, 38.25691511556],
              [-85.71453212868, 38.25691112932],
              [-85.71453385077, 38.25690802804],
              [-85.71453577156, 38.2569045689],
              [-85.71455922593, 38.25686233021],
              [-85.71450915759, 38.25684518731],
              [-85.71449966157, 38.25686228858],
              [-85.71450068663, 38.25686263955],
              [-85.7144917753, 38.25687868787],
              [-85.71449042185, 38.25687822447],
              [-85.71448044965, 38.25689618328],
              [-85.71449964849, 38.25690275677],
              [-85.71449871731, 38.25690443373],
              [-85.7145299152, 38.25691511556],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Museum Conference Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71451983779, 38.256880151435],
            type: 'Point',
          },
        },
      },
      {
        id: '1569cae1-ae6e-468c-b5ff-51dd7a3c82c2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71473283071, 38.25681313288],
              [-85.71473008871, 38.25681802219],
              [-85.71472823306, 38.2568213289],
              [-85.71472627649, 38.25682481545],
              [-85.71470271861, 38.25686678254],
              [-85.7147002839, 38.25687113305],
              [-85.71469760347, 38.2568759226],
              [-85.71467467744, 38.25691676333],
              [-85.71476670275, 38.25694861077],
              [-85.71477159842, 38.25695030015],
              [-85.71477570172, 38.2569517161],
              [-85.71483508251, 38.25697226748],
              [-85.71489262285, 38.25687021537],
              [-85.71473283071, 38.25681313288],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: {
            en: '4th Floor Conference Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.714783650145, 38.25689270018],
            type: 'Point',
          },
        },
      },
      {
        id: '157bf662-5ced-4ccf-8bb4-f0c5e44c7721',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71470321517, 38.25710035592],
              [-85.71470266539, 38.2571001621],
              [-85.71470218846, 38.25710102973],
              [-85.71469689983, 38.25709923722],
              [-85.71469102918, 38.25709724745],
              [-85.71469181096, 38.25709582521],
              [-85.71468590847, 38.25709379888],
              [-85.71468272052, 38.25709274414],
              [-85.71468184919, 38.25709432928],
              [-85.7146751152, 38.25709204689],
              [-85.71466935208, 38.25709009356],
              [-85.71466976738, 38.25708933803],
              [-85.71466283941, 38.25708693214],
              [-85.71464533434, 38.2570810568],
              [-85.7146587605, 38.25705663149],
              [-85.71465245915, 38.25705449574],
              [-85.71464736106, 38.25705276781],
              [-85.71464657055, 38.25705420595],
              [-85.71464379624, 38.25705330347],
              [-85.71464122337, 38.25705239361],
              [-85.71464200853, 38.25705096523],
              [-85.7146111037, 38.25704049048],
              [-85.7146088083, 38.25703971249],
              [-85.71459547095, 38.25706397623],
              [-85.71459066579, 38.2570623476],
              [-85.71458996383, 38.25706362462],
              [-85.71458376976, 38.25706152522],
              [-85.714577576, 38.25705942594],
              [-85.71457846636, 38.25705780619],
              [-85.71456607546, 38.25705360646],
              [-85.7145648571, 38.25705582293],
              [-85.71455895831, 38.25705382361],
              [-85.71455246827, 38.25705162391],
              [-85.7145536783, 38.25704942258],
              [-85.71453934736, 38.25704456531],
              [-85.7145450236, 38.2570342389],
              [-85.71453388445, 38.25703046344],
              [-85.7145726436, 38.25695995146],
              [-85.71458331207, 38.25696356739],
              [-85.71457660949, 38.25697666147],
              [-85.71457777978, 38.25697696086],
              [-85.71457640742, 38.25697957974],
              [-85.71457507738, 38.25698211785],
              [-85.71457388469, 38.25698182308],
              [-85.71457308466, 38.25698331133],
              [-85.71459058093, 38.25698839807],
              [-85.71460085788, 38.25698659185],
              [-85.714606426, 38.25697777037],
              [-85.7146002021, 38.25696914522],
              [-85.71459323825, 38.25696693173],
              [-85.7146037976, 38.25694772177],
              [-85.7146053245, 38.2569482393],
              [-85.71460761935, 38.25694406567],
              [-85.7146123757, 38.25694572833],
              [-85.7146171129, 38.25694738429],
              [-85.71461485851, 38.25695138731],
              [-85.71463114584, 38.25695686245],
              [-85.7146384269, 38.25695937498],
              [-85.71464568038, 38.25696187798],
              [-85.71473118872, 38.25699076505],
              [-85.71474582911, 38.25699570841],
              [-85.71475066329, 38.25699737657],
              [-85.71475575463, 38.25699913347],
              [-85.71476555553, 38.25700238598],
              [-85.71476125519, 38.25701022764],
              [-85.71474936843, 38.25703214765],
              [-85.71474593821, 38.25703098502],
              [-85.71473945464, 38.25704278016],
              [-85.71474211126, 38.25704368114],
              [-85.71473824948, 38.25705060013],
              [-85.71473400236, 38.25705820952],
              [-85.71473189783, 38.25705749646],
              [-85.71472517401, 38.25706972865],
              [-85.71472085509, 38.25706826481],
              [-85.71470321517, 38.25710035592],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'library',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Barr Library and Hall of Fame',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71468707349999, 38.257022547700004],
            type: 'Point',
          },
        },
      },
      {
        id: '160218be-551a-4959-8077-908e4a87eb91',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71390781533, 38.2580577323],
              [-85.71395339971, 38.2580742049],
              [-85.71393925198, 38.25809834473],
              [-85.71394092484, 38.25809894929],
              [-85.71393870735, 38.25810269587],
              [-85.71393643979, 38.25810652705],
              [-85.71393476403, 38.25810592157],
              [-85.71393207482, 38.25811051009],
              [-85.71388652951, 38.25809405162],
              [-85.71388877552, 38.25809027605],
              [-85.71389086129, 38.25808666113],
              [-85.71389302815, 38.2580829057],
              [-85.71390781533, 38.2580577323],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71391996461, 38.258084121195],
            type: 'Point',
          },
        },
      },
      {
        id: '17a405d3-9c47-468d-9744-bffdd1dcdee8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71455922759, 38.25730700997],
              [-85.71456608278, 38.25730943154],
              [-85.71458261343, 38.25728057688],
              [-85.71457575824, 38.25727815532],
              [-85.71457351412, 38.2572821371],
              [-85.71457185056, 38.25728497612],
              [-85.71457020016, 38.25728779271],
              [-85.71455922759, 38.25730700997],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71457092051, 38.25729379343],
            type: 'Point',
          },
        },
      },
      {
        id: '1822cc25-6c0e-4a82-8afc-a7eb161d04ac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71452301721, 38.2575063475],
              [-85.71453145843, 38.25749194834],
              [-85.71452896604, 38.25749104742],
              [-85.7145298941, 38.25748946108],
              [-85.71452606668, 38.25748812416],
              [-85.71452276249, 38.25748697001],
              [-85.71452203477, 38.25748821209],
              [-85.71444897968, 38.25746180507],
              [-85.71444066904, 38.25745880105],
              [-85.71443221625, 38.25745574564],
              [-85.71442902363, 38.25745459161],
              [-85.7144168661, 38.25747533009],
              [-85.71441370301, 38.25747418674],
              [-85.71441166077, 38.25747767042],
              [-85.71440942701, 38.25748148081],
              [-85.71441227695, 38.25748251097],
              [-85.71436415825, 38.25756459246],
              [-85.71436167693, 38.25756369554],
              [-85.71435971011, 38.25756705057],
              [-85.71435779104, 38.25757032414],
              [-85.71436001982, 38.25757112977],
              [-85.71435769699, 38.25757509209],
              [-85.7143493943, 38.25757209095],
              [-85.71434905601, 38.257572668],
              [-85.71434736617, 38.25757205718],
              [-85.71434543665, 38.25757534857],
              [-85.71434353634, 38.25757859015],
              [-85.71434513309, 38.25757916732],
              [-85.71433166868, 38.25760213505],
              [-85.714341517, 38.25760569488],
              [-85.71433485234, 38.25761706352],
              [-85.71433258308, 38.25761624326],
              [-85.71433087885, 38.25761915035],
              [-85.71432901952, 38.25762232202],
              [-85.71433119054, 38.25762310677],
              [-85.71432014989, 38.25764194002],
              [-85.71432969589, 38.25764539058],
              [-85.71433898275, 38.25764874747],
              [-85.71434398143, 38.25764022066],
              [-85.71434623648, 38.25764103579],
              [-85.71434999722, 38.25763462068],
              [-85.71435416586, 38.25762750977],
              [-85.71435203465, 38.25762673941],
              [-85.71436044055, 38.25761240052],
              [-85.71436399162, 38.25761368412],
              [-85.71436495829, 38.25761203516],
              [-85.71436682948, 38.25761271154],
              [-85.71436902909, 38.25760895942],
              [-85.71437134529, 38.25760500842],
              [-85.71436948696, 38.2576043367],
              [-85.71437265169, 38.25759893826],
              [-85.71437444598, 38.25759958684],
              [-85.71437677815, 38.2575956086],
              [-85.71437886414, 38.25759205029],
              [-85.7143771227, 38.25759142082],
              [-85.71438033103, 38.25758594802],
              [-85.71438219534, 38.25758662191],
              [-85.71438475988, 38.25758224729],
              [-85.71438677473, 38.25757881034],
              [-85.71438494908, 38.25757815043],
              [-85.71438627922, 38.25757588147],
              [-85.71438107111, 38.25757399891],
              [-85.71439305452, 38.25755355746],
              [-85.71439532519, 38.25755437824],
              [-85.71439771375, 38.25755030381],
              [-85.7144005466, 38.25754547149],
              [-85.71439824807, 38.25754464064],
              [-85.71442141489, 38.25750512238],
              [-85.71442355598, 38.25750589631],
              [-85.7144261291, 38.25750150706],
              [-85.71442902017, 38.25749657542],
              [-85.71442709518, 38.2574958796],
              [-85.71443880368, 38.25747590707],
              [-85.71452301721, 38.2575063475],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71442580416, 38.25755166954],
            type: 'Point',
          },
        },
      },
      {
        id: '183734de-3c16-4450-a27c-793942e12962',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71440313708, 38.25758235563],
              [-85.71439584846, 38.25759455239],
              [-85.71438076886, 38.25758893441],
              [-85.71438219534, 38.25758662191],
              [-85.71438475988, 38.25758224729],
              [-85.71438677473, 38.25757881034],
              [-85.71438802729, 38.25757678816],
              [-85.71440313708, 38.25758235563],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Phone Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71439195297, 38.257585670275006],
            type: 'Point',
          },
        },
      },
      {
        id: '1911dd16-d826-42b8-b8d6-794b8aefe325',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71422729795, 38.25734457953],
              [-85.7142191934, 38.25735869291],
              [-85.71422381982, 38.25736033678],
              [-85.71422848649, 38.25736198333],
              [-85.71423299674, 38.2573635747],
              [-85.71425143547, 38.25737010914],
              [-85.71425879277, 38.25735729704],
              [-85.71425654765, 38.25735650209],
              [-85.7142572949, 38.25735520081],
              [-85.71422729795, 38.25734457953],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71423899308499, 38.257357344335],
            type: 'Point',
          },
        },
      },
      {
        id: '1b498153-3e15-4021-8510-ce19b34286cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71402643755, 38.25736122301],
              [-85.71404343196, 38.25733119036],
              [-85.71407934192, 38.25734371975],
              [-85.71408381779, 38.25733580997],
              [-85.71411075694, 38.25734520934],
              [-85.7140938085, 38.25737524922],
              [-85.7140922845, 38.25737785522],
              [-85.71409069387, 38.25738057517],
              [-85.71408928666, 38.25738315176],
              [-85.71402643755, 38.25736122301],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Office Gr44',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714068597245, 38.25735717106],
            type: 'Point',
          },
        },
      },
      {
        id: '1b9e042e-1343-46d2-8cb6-7816002a2d5e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71444184225, 38.25764255774],
              [-85.71443937762, 38.25764682578],
              [-85.71444226261, 38.257647853],
              [-85.71442729584, 38.25767377118],
              [-85.71442402757, 38.25767260748],
              [-85.71442175169, 38.25767654864],
              [-85.71434215364, 38.25764820704],
              [-85.71434623648, 38.25764103579],
              [-85.71434999722, 38.25763462068],
              [-85.71435416586, 38.25762750977],
              [-85.71436191723, 38.25761398213],
              [-85.71439125977, 38.25762442982],
              [-85.71439172008, 38.25762363269],
              [-85.7144011842, 38.25762700247],
              [-85.71440066757, 38.25762789712],
              [-85.71444184225, 38.25764255774],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Daredevil Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714392208125, 38.257645265385],
            type: 'Point',
          },
        },
      },
      {
        id: '1be6f9dc-f784-4de2-a7af-2f70b285889d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7148083749, 38.25701651878],
              [-85.71480458581, 38.25701519002],
              [-85.71480495042, 38.25701452873],
              [-85.71480101288, 38.25701315473],
              [-85.71479695578, 38.257011739],
              [-85.71479659549, 38.25701239262],
              [-85.7147746176, 38.25700468541],
              [-85.71477180569, 38.25700962962],
              [-85.7147570954, 38.25700447101],
              [-85.71475970775, 38.25699987769],
              [-85.71460963342, 38.25694724958],
              [-85.71460319821, 38.25694499288],
              [-85.71458651583, 38.25697432566],
              [-85.71459688277, 38.25697796114],
              [-85.71459600702, 38.25697950158],
              [-85.71459981152, 38.25698082142],
              [-85.71460337976, 38.2569820593],
              [-85.71460458795, 38.25698017228],
              [-85.71460655842, 38.25698086328],
              [-85.71460011216, 38.2569921978],
              [-85.71455527761, 38.2569764752],
              [-85.71455294811, 38.25698057118],
              [-85.71455040586, 38.25698504124],
              [-85.71454228525, 38.25699931979],
              [-85.7145422549, 38.25699937315],
              [-85.71453009772, 38.25702074921],
              [-85.7145824594, 38.25703933325],
              [-85.71458031633, 38.25704304448],
              [-85.71458151348, 38.25704346429],
              [-85.71457426173, 38.25705621509],
              [-85.71475217682, 38.25711860631],
              [-85.71476184619, 38.25710160461],
              [-85.71475765898, 38.25710013624],
              [-85.71478511557, 38.25705185914],
              [-85.71478972771, 38.25705347653],
              [-85.71480997918, 38.2570178682],
              [-85.71480800182, 38.25701717478],
              [-85.7148083749, 38.25701651878],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'auditorium',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Marie & Eugene Callahan Gallery',
            frCA: null,
          },
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71467003845001, 38.257031799595],
            type: 'Point',
          },
        },
      },
      {
        id: '1c45dfcf-a7e9-4d26-8847-22c77d814f33',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7142063504, 38.25726849505],
              [-85.71418881508, 38.2572623316],
              [-85.71419300346, 38.25725507446],
              [-85.714195183, 38.25725119278],
              [-85.71419758579, 38.25724691349],
              [-85.71420169408, 38.25723973854],
              [-85.7142192294, 38.25724590199],
              [-85.7142063504, 38.25726849505],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71420402224001, 38.257254116794996],
            type: 'Point',
          },
        },
      },
      {
        id: '1c4cbdef-c39c-46c7-aa1a-9be720fc9c2f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71451916115, 38.25730593477],
              [-85.7144864179, 38.25729404564],
              [-85.71448207126, 38.25729246737],
              [-85.71447764566, 38.25729086042],
              [-85.71447537288, 38.25729003517],
              [-85.7144763736, 38.25728833581],
              [-85.7144747395, 38.25728774247],
              [-85.71449282946, 38.2572570231],
              [-85.71452832996, 38.25726991339],
              [-85.71453248944, 38.25726284998],
              [-85.71454241132, 38.25726645263],
              [-85.71451916115, 38.25730593477],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71450857541001, 38.257281478935],
            type: 'Point',
          },
        },
      },
      {
        id: '1cca5204-7fb2-4b39-aa6e-19f9555fa32c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71453966449, 38.25681846555],
              [-85.71452405047, 38.25681311173],
              [-85.71452316272, 38.25681470815],
              [-85.71451493747, 38.25681188783],
              [-85.71450525834, 38.256808569],
              [-85.71450603099, 38.25680717956],
              [-85.71449185381, 38.25680231841],
              [-85.71449335825, 38.25679961298],
              [-85.71448988796, 38.25679842307],
              [-85.71448778005, 38.2568022137],
              [-85.71448937993, 38.25680276228],
              [-85.7144750939, 38.25682845268],
              [-85.71447099416, 38.25682704694],
              [-85.71447048208, 38.25682796756],
              [-85.71446646366, 38.25682656849],
              [-85.71446223679, 38.25682509684],
              [-85.71446286944, 38.25682395881],
              [-85.71446108899, 38.25682334832],
              [-85.71446658193, 38.25681347044],
              [-85.71446525407, 38.25681301513],
              [-85.71446718099, 38.25680954998],
              [-85.71446891643, 38.25680642916],
              [-85.71447057706, 38.25680699857],
              [-85.71447309418, 38.25680247207],
              [-85.71447152707, 38.25680193473],
              [-85.71447327643, 38.25679878887],
              [-85.71447515077, 38.25679541826],
              [-85.71447691801, 38.25679602422],
              [-85.71448564557, 38.25678032956],
              [-85.71448767171, 38.25678102429],
              [-85.71448855319, 38.25677943914],
              [-85.71449187927, 38.25678058231],
              [-85.71449658175, 38.25678219854],
              [-85.71449571684, 38.25678375389],
              [-85.71449706965, 38.25678421775],
              [-85.71449662907, 38.25678501004],
              [-85.71450122375, 38.25678658549],
              [-85.7145078256, 38.25677471347],
              [-85.71452142094, 38.25677937512],
              [-85.71452218996, 38.2567779922],
              [-85.71452608657, 38.25677932829],
              [-85.71452992308, 38.25678064378],
              [-85.71452912817, 38.25678207326],
              [-85.71455602697, 38.25679129647],
              [-85.71454463458, 38.25681178328],
              [-85.71454291975, 38.25681498016],
              [-85.71454129951, 38.2568177807],
              [-85.71454071783, 38.25681882673],
              [-85.71453966449, 38.25681846555],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714529157365, 38.256801583075],
            type: 'Point',
          },
        },
      },
      {
        id: '1d2e055d-190a-48d5-94c7-a3fbc5bc0d12',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71406850787, 38.25807782571],
              [-85.71402870618, 38.25806401538],
              [-85.71398468304, 38.25814224511],
              [-85.71402448474, 38.25815605542],
              [-85.71404453155, 38.25812039422],
              [-85.7140478194, 38.25811458885],
              [-85.7140513182, 38.258108411],
              [-85.71406850787, 38.25807782571],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'storage',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714026595455, 38.2581100354],
            type: 'Point',
          },
        },
      },
      {
        id: '1d7d887a-4d9a-42eb-b03c-9523041370fa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71411756503, 38.25790643636],
              [-85.71410129636, 38.25793583195],
              [-85.71408497328, 38.25796532582],
              [-85.71410921756, 38.25797359904],
              [-85.71411623651, 38.25796091662],
              [-85.71412340355, 38.25796336233],
              [-85.71413305441, 38.25794592439],
              [-85.71414264451, 38.25794919696],
              [-85.71414374251, 38.25794713584],
              [-85.71414569166, 38.25794369422],
              [-85.71414741786, 38.25794064626],
              [-85.71415856646, 38.25792042786],
              [-85.71411756503, 38.25790643636],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71412176986999, 38.2579400177],
            type: 'Point',
          },
        },
      },
      {
        id: '1ea95200-a2af-4b0f-824a-40f1ee423521',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71433204181, 38.25721852237],
              [-85.71430356919, 38.25720849699],
              [-85.71429349717, 38.25722613487],
              [-85.71425729314, 38.2572133872],
              [-85.71425868497, 38.25721094986],
              [-85.71425453003, 38.25720948689],
              [-85.71425015559, 38.25720794662],
              [-85.7142488155, 38.25721029335],
              [-85.71423561212, 38.25720564436],
              [-85.7142209393, 38.25723133906],
              [-85.71421503873, 38.2572292618],
              [-85.71421309332, 38.25723277568],
              [-85.714211074, 38.25723642305],
              [-85.71421683604, 38.25723845265],
              [-85.71421515566, 38.25724139529],
              [-85.71423457192, 38.25724823187],
              [-85.71423375982, 38.257249654],
              [-85.71423813486, 38.25725119448],
              [-85.71424227026, 38.25725265057],
              [-85.71424302173, 38.25725133462],
              [-85.71427758095, 38.25726350313],
              [-85.71427648663, 38.25726541948],
              [-85.71428075732, 38.25726692322],
              [-85.7142849505, 38.25726839966],
              [-85.71428603062, 38.25726650817],
              [-85.71430727231, 38.2572739875],
              [-85.71430896093, 38.25727091416],
              [-85.71431290339, 38.25726412685],
              [-85.71431680914, 38.25725740273],
              [-85.71432287821, 38.25724665882],
              [-85.71431713035, 38.25724463497],
              [-85.71433204181, 38.25721852237],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714271557905, 38.25723981593],
            type: 'Point',
          },
        },
      },
      {
        id: '1f111abf-330c-457e-9173-e4ce620113fa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71488728465, 38.25687226125],
              [-85.71483988142, 38.25685509851],
              [-85.71482866684, 38.25687419759],
              [-85.71482653615, 38.25687781863],
              [-85.71482468409, 38.25688098045],
              [-85.71482217482, 38.25688525387],
              [-85.71486957805, 38.2569024166],
              [-85.71488728465, 38.25687226125],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71485472973501, 38.256878757555],
            type: 'Point',
          },
        },
      },
      {
        id: '1f9ef7bc-2f5c-4e2d-a91a-edb89cc3cfbc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7135701148, 38.25820709329],
              [-85.71356848669, 38.25820993147],
              [-85.7135549448, 38.25823367159],
              [-85.71355043471, 38.25824160733],
              [-85.71352611333, 38.25823313169],
              [-85.7135306879, 38.25822518046],
              [-85.71354424436, 38.25820144905],
              [-85.71358141352, 38.25813578306],
              [-85.71358359195, 38.2581365417],
              [-85.71358418316, 38.25813549496],
              [-85.71359025065, 38.25813760798],
              [-85.71359574666, 38.25813952198],
              [-85.71359516399, 38.25814055361],
              [-85.71359678096, 38.25814111672],
              [-85.71356913952, 38.25819005617],
              [-85.71357799557, 38.25819314031],
              [-85.71357397735, 38.2582002546],
              [-85.71357207259, 38.25820362701],
              [-85.7135701148, 38.25820709329],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'East Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.713561447145, 38.258188551145],
            type: 'Point',
          },
        },
      },
      {
        id: '20ddff7d-1518-4b5d-b198-75de64ad5900',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71393183263, 38.25752662632],
              [-85.7138772956, 38.25762037568],
              [-85.71401296812, 38.25766904038],
              [-85.71402471375, 38.25764884962],
              [-85.71409477967, 38.25767398173],
              [-85.7141106179, 38.25767966278],
              [-85.71412391325, 38.25768443172],
              [-85.71421870414, 38.25771843246],
              [-85.71422474123, 38.2577080547],
              [-85.71422743898, 38.25770902236],
              [-85.71423121723, 38.25770252755],
              [-85.71423536406, 38.25769539914],
              [-85.71423119623, 38.25769390417],
              [-85.71429893415, 38.25757746254],
              [-85.71430315768, 38.25757897749],
              [-85.714309792, 38.25756757307],
              [-85.71431403826, 38.25756027374],
              [-85.71430921511, 38.25755854371],
              [-85.71431760925, 38.25754411413],
              [-85.71431562755, 38.2575434033],
              [-85.71431793653, 38.25753906709],
              [-85.71430819255, 38.25753557804],
              [-85.71429788541, 38.25753188733],
              [-85.71429675853, 38.25753438907],
              [-85.71429002739, 38.25753200388],
              [-85.71430332029, 38.25750850065],
              [-85.71429813199, 38.25750665261],
              [-85.71429274001, 38.25750470558],
              [-85.71429204059, 38.25750590187],
              [-85.71428705851, 38.2575040799],
              [-85.71429967975, 38.25748244069],
              [-85.71428066067, 38.25747561868],
              [-85.71431454189, 38.25741737662],
              [-85.71425314291, 38.25739536427],
              [-85.71424535817, 38.25739255719],
              [-85.71424353951, 38.25739190852],
              [-85.71417306002, 38.25751306323],
              [-85.71407134029, 38.25747657706],
              [-85.71407036501, 38.25747828534],
              [-85.71406682796, 38.2574843336],
              [-85.71406335166, 38.25749027798],
              [-85.71402316694, 38.25755938729],
              [-85.71393183263, 38.25752662632],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714097616065, 38.25755517049],
            type: 'Point',
          },
        },
      },
      {
        id: '2176f2c0-9661-44b2-b7ab-9dbe71ee2896',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71463468349, 38.25699438745],
              [-85.71463960725, 38.25698570269],
              [-85.71463751277, 38.2569849705],
              [-85.71464152225, 38.25697789839],
              [-85.71464547573, 38.25697092506],
              [-85.71464674491, 38.25697136874],
              [-85.71465188117, 38.25696230917],
              [-85.71475082862, 38.25699689915],
              [-85.714752927, 38.25699319792],
              [-85.71475634345, 38.25699439224],
              [-85.7147597961, 38.25699559921],
              [-85.71475791238, 38.25699892179],
              [-85.71476705328, 38.25700211726],
              [-85.71476228016, 38.25701053631],
              [-85.71480616888, 38.25702622086],
              [-85.71480003033, 38.25703715194],
              [-85.71479397303, 38.25704799075],
              [-85.71476302607, 38.25703688157],
              [-85.71476065273, 38.25704106778],
              [-85.71476399385, 38.25704223576],
              [-85.71476221319, 38.25704537657],
              [-85.71476046497, 38.25704846016],
              [-85.7147579346, 38.2570475756],
              [-85.71474103248, 38.25707738832],
              [-85.71474362181, 38.25707829349],
              [-85.7147419272, 38.25708128251],
              [-85.71474029264, 38.25708416563],
              [-85.71473827719, 38.25708346108],
              [-85.71473583541, 38.25708776799],
              [-85.71472089124, 38.25708254383],
              [-85.7147483443, 38.25703412089],
              [-85.71463468349, 38.25699438745],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71474343947, 38.25702503858],
            type: 'Point',
          },
        },
      },
      {
        id: '21938c0f-3071-4f41-a822-ff24a6d1d572',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71443261156, 38.25745344912],
              [-85.71444277988, 38.257436446],
              [-85.71444058739, 38.25743563754],
              [-85.71444453554, 38.25742903558],
              [-85.7144484443, 38.25742249948],
              [-85.71445072298, 38.25742333973],
              [-85.71446092359, 38.25740628262],
              [-85.71448278225, 38.25741434282],
              [-85.71448349877, 38.25741314469],
              [-85.71448789774, 38.25741476677],
              [-85.71449208816, 38.25741631195],
              [-85.71449134904, 38.25741754788],
              [-85.71449523115, 38.25741897937],
              [-85.71451308367, 38.25738912698],
              [-85.71451112174, 38.25738840354],
              [-85.71451301999, 38.25738522934],
              [-85.71451514656, 38.25738167337],
              [-85.71451722408, 38.25738243944],
              [-85.7145396186, 38.25734499204],
              [-85.7145378324, 38.25734433339],
              [-85.71453981844, 38.25734101241],
              [-85.71454188174, 38.25733756222],
              [-85.7145437652, 38.25733825673],
              [-85.71456189825, 38.25730793521],
              [-85.71455842524, 38.25730665456],
              [-85.71455730442, 38.25730852877],
              [-85.71455311955, 38.25730698563],
              [-85.71454926416, 38.25730556399],
              [-85.71455043223, 38.25730361077],
              [-85.71452872421, 38.25729560611],
              [-85.71453558528, 38.25728413324],
              [-85.7145491019, 38.25726153115],
              [-85.71455383987, 38.25726327824],
              [-85.7145540726, 38.25726288907],
              [-85.71457789333, 38.25727167277],
              [-85.71459924425, 38.25727954575],
              [-85.71462133828, 38.25728769275],
              [-85.71464685801, 38.25729710294],
              [-85.71464431845, 38.2573013495],
              [-85.71464526876, 38.25730169992],
              [-85.71462978342, 38.25732759401],
              [-85.71462874318, 38.25732721043],
              [-85.71462680187, 38.25733045661],
              [-85.7146193951, 38.25734284199],
              [-85.71462112212, 38.25734347881],
              [-85.71461839737, 38.25734803507],
              [-85.71460762779, 38.2573660436],
              [-85.71460538588, 38.25736979246],
              [-85.71460355214, 38.25736911628],
              [-85.71459756691, 38.25737912458],
              [-85.7145989464, 38.25737963325],
              [-85.71459712001, 38.25738268728],
              [-85.71458677782, 38.25739998115],
              [-85.71458318676, 38.257405986],
              [-85.71458171013, 38.25740544151],
              [-85.71457496801, 38.25741671546],
              [-85.71454606729, 38.25740605857],
              [-85.71454065702, 38.25740406357],
              [-85.71453022038, 38.25742151538],
              [-85.71453163211, 38.25742203594],
              [-85.71452888218, 38.25742663427],
              [-85.7145265327, 38.257430563],
              [-85.71452505953, 38.25743001978],
              [-85.71452009707, 38.25743831783],
              [-85.71452287027, 38.25743934042],
              [-85.71449981919, 38.25747788565],
              [-85.7144502863, 38.25745962084],
              [-85.71444897968, 38.25746180507],
              [-85.71444066904, 38.25745880105],
              [-85.71443221625, 38.25745574564],
              [-85.71443341226, 38.25745374437],
              [-85.71443261156, 38.25745344912],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71453953713001, 38.2573697084],
            type: 'Point',
          },
        },
      },
      {
        id: '221d34c9-3ad8-44df-b030-59d15e476f94',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71478206485, 38.25695049353],
              [-85.71476897351, 38.25697419443],
              [-85.71482007437, 38.25699159864],
              [-85.71483203466, 38.25696994543],
              [-85.71479412577, 38.25695703421],
              [-85.71479525682, 38.25695498653],
              [-85.71479438471, 38.2569546895],
              [-85.71479012861, 38.25695323993],
              [-85.71478575291, 38.25695174963],
              [-85.71478206485, 38.25695049353],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714800504085, 38.256971046085],
            type: 'Point',
          },
        },
      },
      {
        id: '23be744d-79b5-405b-9edb-edf916d35407',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71443817935, 38.25714676462],
              [-85.71444536915, 38.25713425076],
              [-85.71444893643, 38.25712804191],
              [-85.71445245109, 38.25712192464],
              [-85.71445357746, 38.25711996419],
              [-85.71452076502, 38.2571437665],
              [-85.71453172567, 38.25712468947],
              [-85.71455627594, 38.25713338682],
              [-85.71457147908, 38.25710692572],
              [-85.71456923992, 38.25710613246],
              [-85.71457112138, 38.25710285777],
              [-85.71457289746, 38.25709976651],
              [-85.71457554753, 38.25710070534],
              [-85.71457735384, 38.25709756146],
              [-85.71455335083, 38.25708905798],
              [-85.71457104009, 38.25705826978],
              [-85.71474741055, 38.257120752],
              [-85.71474034497, 38.25713304965],
              [-85.71475834392, 38.25713942607],
              [-85.71474581763, 38.25716122809],
              [-85.71473327868, 38.25718305214],
              [-85.71471532407, 38.25717669143],
              [-85.71469090621, 38.2572191907],
              [-85.71447287178, 38.25714194841],
              [-85.71446470544, 38.25715616192],
              [-85.71443817935, 38.25714676462],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71467788346, 38.25713873024],
            type: 'Point',
          },
        },
      },
      {
        id: '2450c828-8260-4947-b567-a37e131b06a6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7144981571, 38.25678273999],
              [-85.71451449653, 38.25675342643],
              [-85.71451878786, 38.25675490136],
              [-85.71452384476, 38.2567458291],
              [-85.71452229192, 38.25674529539],
              [-85.71452324393, 38.25674358743],
              [-85.71450316147, 38.25673668512],
              [-85.71450377943, 38.25673557647],
              [-85.71448595748, 38.25672945109],
              [-85.71448526825, 38.25673068761],
              [-85.71447056758, 38.25672563501],
              [-85.71446665778, 38.25673264935],
              [-85.71446518578, 38.25673214343],
              [-85.71446143852, 38.25673886615],
              [-85.71445740828, 38.25674609656],
              [-85.71445895408, 38.25674662785],
              [-85.71444836431, 38.25676562629],
              [-85.71448855319, 38.25677943914],
              [-85.71449187927, 38.25678058231],
              [-85.71449658175, 38.25678219854],
              [-85.7144981571, 38.25678273999],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: "President's Office",
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714486104535, 38.2567541875],
            type: 'Point',
          },
        },
      },
      {
        id: '2594512a-8c2b-4744-92e3-00e3fa8a0289',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71405742197, 38.25821574311],
              [-85.7140473361, 38.25823333304],
              [-85.71404533649, 38.2582368204],
              [-85.71404331336, 38.25824034877],
              [-85.7140155824, 38.25828871204],
              [-85.71406364423, 38.25830570388],
              [-85.7141054838, 38.25823273498],
              [-85.71405742197, 38.25821574311],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Room 2R01',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.7140605331, 38.258260723495],
            type: 'Point',
          },
        },
      },
      {
        id: '25d22dce-852f-4e21-8335-d743c28ed2df',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457440399, 38.2574176236],
              [-85.71455515564, 38.25744963839],
              [-85.71452235048, 38.25743782981],
              [-85.7145265327, 38.257430563],
              [-85.71452888218, 38.25742663427],
              [-85.71453163211, 38.25742203594],
              [-85.7145411416, 38.25740564645],
              [-85.71457440399, 38.2574176236],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'HVAC/Electrical',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71454837723499, 38.25742764242],
            type: 'Point',
          },
        },
      },
      {
        id: '268d2566-5cbb-478e-ba36-7a1b7a1320cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71443015809, 38.2574525388],
              [-85.71438576763, 38.25743607906],
              [-85.71449921746, 38.25724248082],
              [-85.7145462287, 38.25725988945],
              [-85.7144484443, 38.25742249948],
              [-85.71444453554, 38.25742903558],
              [-85.71444058739, 38.25743563754],
              [-85.71443015809, 38.2574525388],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'library',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Miguel Library',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714465998165, 38.25734750981],
            type: 'Point',
          },
        },
      },
      {
        id: '269935c7-cd96-4127-bb56-6902ea5ada7e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7146514625, 38.2568178318],
              [-85.71466561762, 38.25679329923],
              [-85.71467515803, 38.25679669349],
              [-85.71467601172, 38.25679521394],
              [-85.71468634414, 38.25679888998],
              [-85.71468574765, 38.25679992378],
              [-85.71470752249, 38.25680767078],
              [-85.71469311017, 38.25683264911],
              [-85.71469105685, 38.25683191859],
              [-85.71468700054, 38.25683047544],
              [-85.71468282164, 38.25682898868],
              [-85.7146514625, 38.2568178318],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 308',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71467949249501, 38.25681297417],
            type: 'Point',
          },
        },
      },
      {
        id: '271a3191-dc2f-4229-a4d1-48ac466ac983',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71410634437, 38.25722223722],
              [-85.71414089016, 38.25716230951],
              [-85.71419040257, 38.2571799915],
              [-85.71417718046, 38.25720285029],
              [-85.71417543472, 38.25720583548],
              [-85.71417354962, 38.25720905896],
              [-85.71415575611, 38.2572398114],
              [-85.71410634437, 38.25722223722],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Technology Conference Room Gr39A',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71414837347, 38.257201060455],
            type: 'Point',
          },
        },
      },
      {
        id: '28454355-cd19-4d3a-8460-bbee43835511',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71471381581, 38.2568675122],
              [-85.714723884, 38.25684943125],
              [-85.71472616651, 38.2568453797],
              [-85.71469402394, 38.25683430917],
              [-85.71468167574, 38.25685646916],
              [-85.71471381581, 38.2568675122],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 309',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714703921125, 38.256850910685],
            type: 'Point',
          },
        },
      },
      {
        id: '2976d767-e378-4769-a27a-17c32f2b1ecc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71430911074, 38.25787893852],
              [-85.71442439261, 38.25767906793],
              [-85.71433898275, 38.25764874747],
              [-85.71432969589, 38.25764539058],
              [-85.71432014989, 38.25764194002],
              [-85.71427384794, 38.25762552832],
              [-85.71415997109, 38.25782296311],
              [-85.71416507534, 38.25782477837],
              [-85.71416367032, 38.25782721433],
              [-85.7142057269, 38.25784224643],
              [-85.71421716242, 38.25784623974],
              [-85.71422932822, 38.25785048806],
              [-85.71430911074, 38.25787893852],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71429218185, 38.25775223342],
            type: 'Point',
          },
        },
      },
      {
        id: '2af0462e-f17d-402e-9b84-7ee33fd9f735',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7148077115, 38.25691901655],
              [-85.71480652513, 38.25692109128],
              [-85.71480467112, 38.25692445943],
              [-85.71480279064, 38.25692787565],
              [-85.71479276797, 38.25694625427],
              [-85.71484146228, 38.25696239789],
              [-85.71485633884, 38.2569357647],
              [-85.7148077115, 38.25691901655],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714824553405, 38.25694070722],
            type: 'Point',
          },
        },
      },
      {
        id: '2c3b3b14-6166-488a-8f74-43efa50af1c2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71438804316, 38.2575419471],
              [-85.71448522517, 38.25757660454],
              [-85.71444128626, 38.25765257379],
              [-85.71434410425, 38.25761791639],
              [-85.71434810001, 38.25761100863],
              [-85.7143509865, 38.25760601714],
              [-85.71435377591, 38.25760119353],
              [-85.71438804316, 38.2575419471],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71441466471, 38.257597260444996],
            type: 'Point',
          },
        },
      },
      {
        id: '2c55d857-482f-4585-ab7e-7f04a282a1e5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71391884016, 38.25831906598],
              [-85.71391008018, 38.25831601215],
              [-85.71390109557, 38.25831288001],
              [-85.71389161814, 38.25830957607],
              [-85.71387035649, 38.25834718106],
              [-85.7138800326, 38.25835050065],
              [-85.71388890142, 38.25835364877],
              [-85.71389656422, 38.25835636879],
              [-85.71390630376, 38.25835971269],
              [-85.71392756541, 38.2583221077],
              [-85.71391884016, 38.25831906598],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71389896095, 38.25833464438],
            type: 'Point',
          },
        },
      },
      {
        id: '2ce5ccac-44b6-44f7-a426-e85c0c61a112',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71391884016, 38.25831906598],
              [-85.71391008018, 38.25831601215],
              [-85.71390109557, 38.25831288001],
              [-85.71389161814, 38.25830957607],
              [-85.71387035649, 38.25834718106],
              [-85.7138800326, 38.25835050065],
              [-85.71388890142, 38.25835364877],
              [-85.71389656422, 38.25835636879],
              [-85.71390630376, 38.25835971269],
              [-85.71392756541, 38.2583221077],
              [-85.71391884016, 38.25831906598],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71389896095, 38.25833464438],
            type: 'Point',
          },
        },
      },
      {
        id: '2df4b8e7-8693-497b-8214-a7285c3c1d82',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71447311989, 38.25752497683],
              [-85.71447483682, 38.25752201236],
              [-85.71447702385, 38.25751823624],
              [-85.71447922593, 38.25751443413],
              [-85.7144932938, 38.25749014454],
              [-85.714527065, 38.25750220475],
              [-85.71450689108, 38.25753703703],
              [-85.71447311989, 38.25752497683],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71450009244501, 38.257513590784995],
            type: 'Point',
          },
        },
      },
      {
        id: '2ee91936-5d2b-4916-801c-59aa5fb63a33',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7147548415, 38.25711561899],
              [-85.71475096515, 38.25711428493],
              [-85.7147501249, 38.25711579038],
              [-85.71474351707, 38.25711351629],
              [-85.71473723286, 38.25711135357],
              [-85.71473794896, 38.25711007056],
              [-85.71472878966, 38.25710691837],
              [-85.71472789914, 38.25710851388],
              [-85.71472219504, 38.25710655081],
              [-85.7147159113, 38.25710438825],
              [-85.71471653633, 38.2571032684],
              [-85.71471009114, 38.25710105028],
              [-85.71473400236, 38.25705820952],
              [-85.71473824948, 38.25705060013],
              [-85.71474211126, 38.25704368114],
              [-85.71474560622, 38.25703741936],
              [-85.71474962092, 38.25703880103],
              [-85.71475443236, 38.25703018056],
              [-85.71479477838, 38.25704406572],
              [-85.7147548415, 38.25711561899],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Library Conference Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71475243476, 38.257072524179996],
            type: 'Point',
          },
        },
      },
      {
        id: '2f847a91-bea3-4ae4-b170-aa41f6f49d23',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461202408, 38.25698980691],
              [-85.7147449978, 38.25703601767],
              [-85.71470924874, 38.25709944744],
              [-85.7145769364, 38.25705346655],
              [-85.71458794528, 38.25703393344],
              [-85.71458364839, 38.25703244014],
              [-85.7145852575, 38.25702953718],
              [-85.71458690608, 38.25702656341],
              [-85.71459058723, 38.25702784247],
              [-85.71460112586, 38.25700914371],
              [-85.71460480227, 38.25700281178],
              [-85.7146081709, 38.25699664363],
              [-85.71461202408, 38.25698980691],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Computer and Technical Services',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7146609671, 38.257044627175],
            type: 'Point',
          },
        },
      },
      {
        id: '2f93b94c-ede2-410d-89de-0ff46a800b48',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71459040834, 38.25678680641],
              [-85.71459241569, 38.25678333397],
              [-85.71459430037, 38.25678007374],
              [-85.71460116333, 38.25676820182],
              [-85.71456289123, 38.25675455972],
              [-85.71454726152, 38.25678159689],
              [-85.71454978874, 38.25678249772],
              [-85.71454671733, 38.25678781083],
              [-85.71454452437, 38.25678702915],
              [-85.71453525116, 38.25680307048],
              [-85.71453666952, 38.25680357606],
              [-85.7145358103, 38.25680506239],
              [-85.7145769879, 38.25681877196],
              [-85.71458912568, 38.25679831148],
              [-85.71458467463, 38.2567967249],
              [-85.71459040834, 38.25678680641],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Vice President of Development',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71456820724501, 38.256786665839996],
            type: 'Point',
          },
        },
      },
      {
        id: '2fe220ea-1999-4508-ab2b-35387b3a2d86',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71383519435, 38.25811709523],
              [-85.71383174184, 38.25811588629],
              [-85.71381963398, 38.25813720653],
              [-85.71383428837, 38.25814233793],
              [-85.71382883177, 38.25815194623],
              [-85.71384444418, 38.25815741309],
              [-85.71386200864, 38.25812648455],
              [-85.71384203868, 38.25811949184],
              [-85.71383863597, 38.25811830035],
              [-85.71383519435, 38.25811709523],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71384124524, 38.25813664969],
            type: 'Point',
          },
        },
      },
      {
        id: '32438f2c-93b2-467a-b1c9-6952cb5ebac2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71470440096, 38.25697528127],
              [-85.71469094689, 38.25697040053],
              [-85.71471869057, 38.2569232443],
              [-85.7147570841, 38.25693719488],
              [-85.71476155007, 38.25693879544],
              [-85.714764835, 38.25693994347],
              [-85.71476731485, 38.25694088375],
              [-85.71475235021, 38.25696625906],
              [-85.71475114935, 38.25696835813],
              [-85.71475011588, 38.2569701646],
              [-85.71474810167, 38.25697354059],
              [-85.7147458937, 38.2569727396],
              [-85.71473934114, 38.25698387704],
              [-85.71473062833, 38.25698071629],
              [-85.71472853717, 38.25698427066],
              [-85.71472084557, 38.25698148037],
              [-85.71472202479, 38.25697947604],
              [-85.71470546698, 38.25697346935],
              [-85.71470440096, 38.25697528127],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: {
            en: '2nd Floor Conference Room',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71472913087, 38.25695375748],
            type: 'Point',
          },
        },
      },
      {
        id: '328be258-8aab-45b7-be29-8537b393c67a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71419543245, 38.25807316431],
              [-85.71413225565, 38.25818528922],
              [-85.71410352518, 38.25817532401],
              [-85.71410297376, 38.25817630425],
              [-85.71406036653, 38.25816152586],
              [-85.71412409476, 38.25804842065],
              [-85.71419543245, 38.25807316431],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'storage',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71412789949, 38.258116854935],
            type: 'Point',
          },
        },
      },
      {
        id: '32ff3a08-a533-4198-9aec-e3ac064ffaf1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71393260764, 38.25801434451],
              [-85.71399722085, 38.25803750754],
              [-85.71397267124, 38.25807973205],
              [-85.71390805802, 38.25805656904],
              [-85.7139187801, 38.25803815298],
              [-85.71392075995, 38.25803472165],
              [-85.71392262787, 38.25803148431],
              [-85.71393260764, 38.25801434451],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.713952639435, 38.25804703828],
            type: 'Point',
          },
        },
      },
      {
        id: '332efe06-8072-4a3e-9c63-8c82c1cc9533',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71447788583, 38.25737863814],
              [-85.71450972144, 38.25739058394],
              [-85.71449368328, 38.25741693836],
              [-85.71449208816, 38.25741631195],
              [-85.71448789774, 38.25741476677],
              [-85.71448349877, 38.25741314469],
              [-85.71446184768, 38.25740499255],
              [-85.71447788583, 38.25737863814],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Office',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71448578456, 38.25739778825],
            type: 'Point',
          },
        },
      },
      {
        id: '34048ca9-f7f6-49e5-9761-b52af1f538c8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71470573454, 38.25694507561],
              [-85.71468858466, 38.25697467448],
              [-85.71466861381, 38.25696753956],
              [-85.71467059376, 38.25696412237],
              [-85.71465657653, 38.25695911448],
              [-85.71467174646, 38.2569329328],
              [-85.71468321935, 38.25693703168],
              [-85.71468798893, 38.25693873569],
              [-85.71469296632, 38.25694051395],
              [-85.71470573454, 38.25694507561],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714681155535, 38.25695380364],
            type: 'Point',
          },
        },
      },
      {
        id: '341c7017-b59a-482f-a3a3-9c0082d7e09d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71474152687, 38.25699260237],
              [-85.71473065545, 38.25698879047],
              [-85.71474327194, 38.25696660395],
              [-85.71474569785, 38.25696747458],
              [-85.71474948534, 38.25696878155],
              [-85.71475292775, 38.25696996945],
              [-85.71475414335, 38.25697041585],
              [-85.71474152687, 38.25699260237],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.7147423994, 38.25697960316],
            type: 'Point',
          },
        },
      },
      {
        id: '35624642-a46d-45f8-b9ce-f2da32e4f3be',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71391403648, 38.25825166007],
              [-85.71396228526, 38.2581678053],
              [-85.713968559, 38.25815672774],
              [-85.71397543587, 38.25814458523],
              [-85.71402340108, 38.25806122902],
              [-85.71369256294, 38.2579440775],
              [-85.71358292722, 38.25813498095],
              [-85.71358418316, 38.25813549496],
              [-85.71359025065, 38.25813760798],
              [-85.71359574666, 38.25813952198],
              [-85.71376227832, 38.25819848998],
              [-85.71377583646, 38.25817488188],
              [-85.71378004425, 38.25817637188],
              [-85.71377871878, 38.25817883889],
              [-85.71378289563, 38.25818026124],
              [-85.71378707767, 38.25818168535],
              [-85.71378837894, 38.2581795045],
              [-85.7138329863, 38.25819530016],
              [-85.7138197858, 38.25821828551],
              [-85.71391403648, 38.25825166007],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'storage',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.713869609795, 38.258097868785],
            type: 'Point',
          },
        },
      },
      {
        id: '357af0e5-5be5-437f-8077-159c8606476a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71418786437, 38.25792776374],
              [-85.71420949242, 38.25788951582],
              [-85.71421091845, 38.25788699785],
              [-85.71421247634, 38.25788424707],
              [-85.71423053882, 38.2578523039],
              [-85.71430841408, 38.25787945875],
              [-85.71426675059, 38.25795313091],
              [-85.71426397774, 38.25795216403],
              [-85.71426296678, 38.25795395168],
              [-85.71418786437, 38.25792776374],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'GoodMaps',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71424813922499, 38.25790312779],
            type: 'Point',
          },
        },
      },
      {
        id: '36c9b327-cc9b-4d18-b551-fe6a90a0a877',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71409171765, 38.25804338268],
              [-85.71410344525, 38.25802249984],
              [-85.71410529202, 38.258019239],
              [-85.71410716794, 38.25801592667],
              [-85.7141080533, 38.25801432787],
              [-85.71409804616, 38.25801085872],
              [-85.71411464402, 38.25798133753],
              [-85.71408186976, 38.25796997577],
              [-85.71406528041, 38.25799948183],
              [-85.71404893625, 38.25802855179],
              [-85.71409171765, 38.25804338268],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714081790135, 38.258006679225005],
            type: 'Point',
          },
        },
      },
      {
        id: '3748db0b-19db-4865-b5ce-b814270a72ae',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71470810254, 38.25710386406],
              [-85.71474739607, 38.25711791067],
              [-85.7147868734, 38.25704981765],
              [-85.7147602514, 38.25704030084],
              [-85.71476117208, 38.25703871278],
              [-85.71475650882, 38.25703704577],
              [-85.71475166599, 38.25703531455],
              [-85.71475075066, 38.25703689338],
              [-85.71473331967, 38.25703066216],
              [-85.71475132326, 38.2569996084],
              [-85.71474973671, 38.25699900969],
              [-85.71474557744, 38.25699755402],
              [-85.71474122496, 38.25699603074],
              [-85.71467366565, 38.25697184741],
              [-85.71463971438, 38.25703040879],
              [-85.71463744437, 38.25703432426],
              [-85.71463523121, 38.25703814165],
              [-85.71463381141, 38.25704059061],
              [-85.71465678465, 38.25704880306],
              [-85.71465556906, 38.25705089979],
              [-85.71466047316, 38.2570526529],
              [-85.71466504789, 38.25705428827],
              [-85.71466625781, 38.25705220131],
              [-85.71469112063, 38.25706108925],
              [-85.71469014349, 38.25706277469],
              [-85.71469488953, 38.2570644713],
              [-85.71469970041, 38.25706619109],
              [-85.71470067169, 38.25706451575],
              [-85.71472572305, 38.25707347109],
              [-85.71470810254, 38.25710386406],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71471384177, 38.257044879039995],
            type: 'Point',
          },
        },
      },
      {
        id: '38261cf4-cd44-4a1b-957c-4a7423547f1b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71447319961, 38.25728483973],
              [-85.71449376931, 38.25724939172],
              [-85.71443975395, 38.25723006507],
              [-85.71442840743, 38.25724961866],
              [-85.71442566333, 38.25724863681],
              [-85.71442368789, 38.25725205951],
              [-85.71442171271, 38.25725548175],
              [-85.71442443363, 38.25725645534],
              [-85.71441917875, 38.25726551113],
              [-85.71445304008, 38.25727762668],
              [-85.71445778135, 38.2572793231],
              [-85.71446224512, 38.25728092023],
              [-85.71447319961, 38.25728483973],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71445647403, 38.2572574524],
            type: 'Point',
          },
        },
      },
      {
        id: '385e839d-4a00-43c9-b531-eaffede33632',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71453065317, 38.2568006591],
              [-85.71455782548, 38.25675236842],
              [-85.71454012936, 38.25674622898],
              [-85.71454133987, 38.25674407717],
              [-85.7145237508, 38.25673802681],
              [-85.71452262103, 38.25674003476],
              [-85.71450729756, 38.25673471848],
              [-85.7144802102, 38.25678286238],
              [-85.71450263199, 38.25679077283],
              [-85.71450601539, 38.25679196635],
              [-85.71451007418, 38.25679339811],
              [-85.71453065317, 38.2568006591],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Field Services (South)',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71451901783999, 38.25676768879],
            type: 'Point',
          },
        },
      },
      {
        id: '38fe3bb8-1512-43c6-9224-cb045c4968d5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457777332, 38.25685738432],
              [-85.71458200234, 38.25685882798],
              [-85.71458862645, 38.25686108926],
              [-85.71459540594, 38.25684884371],
              [-85.71458854975, 38.2568465178],
              [-85.71458442866, 38.25684509571],
              [-85.71458091825, 38.25684388434],
              [-85.71457365686, 38.2568414192],
              [-85.71456687737, 38.25685366475],
              [-85.7145733223, 38.25685586487],
              [-85.71457777332, 38.25685738432],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.714581141655, 38.25685125423],
            type: 'Point',
          },
        },
      },
      {
        id: '39c384f7-5f50-45b7-8d2c-e1116cd951d1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71456991429, 38.25742088168],
              [-85.71452374491, 38.2574046452],
              [-85.71452685877, 38.25739918557],
              [-85.71452396093, 38.25739816648],
              [-85.71452238982, 38.25740106222],
              [-85.71451759416, 38.2573993809],
              [-85.71451278459, 38.2573976947],
              [-85.71449083654, 38.25738998692],
              [-85.7145118971, 38.25735301888],
              [-85.71454401405, 38.25736431352],
              [-85.71454342639, 38.25736534388],
              [-85.71454494737, 38.25736587877],
              [-85.71454106464, 38.25737268647],
              [-85.71453953516, 38.2573721486],
              [-85.71453810121, 38.25737466279],
              [-85.71456446913, 38.25738393565],
              [-85.71456991876, 38.25737438066],
              [-85.71459374547, 38.25738275986],
              [-85.71457472575, 38.25741610771],
              [-85.71457298603, 38.2574154959],
              [-85.71456991429, 38.25742088168],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 127A-127E',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71454229100499, 38.25738695028],
            type: 'Point',
          },
        },
      },
      {
        id: '3a2bc212-7ae5-4329-aba5-5ff4d2e9c7fc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71441598508, 38.25675474478],
              [-85.71442705556, 38.25673498812],
              [-85.71444183948, 38.25670860434],
              [-85.71456704948, 38.2567518658],
              [-85.71453435832, 38.25681020727],
              [-85.71456168375, 38.2568196485],
              [-85.71455204175, 38.25683685585],
              [-85.71445825871, 38.25680445279],
              [-85.71447096513, 38.2567817766],
              [-85.71442827469, 38.25676702658],
              [-85.71443204703, 38.25676029436],
              [-85.71442630532, 38.25675831054],
              [-85.71442188529, 38.25675678337],
              [-85.71441691604, 38.25675506644],
              [-85.71441598508, 38.25675474478],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714502923725, 38.256772730094994],
            type: 'Point',
          },
        },
      },
      {
        id: '3ad772e3-ddf7-4d3b-9a07-49adab42d29f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71428139989, 38.25726851723],
              [-85.71430498818, 38.25727718344],
              [-85.71431929056, 38.25725318303],
              [-85.71429569404, 38.2572445126],
              [-85.71428495742, 38.25726251173],
              [-85.71428322641, 38.25726543387],
              [-85.71428139989, 38.25726851723],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: "Caitlin's Office",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714300345225, 38.25726084802],
            type: 'Point',
          },
        },
      },
      {
        id: '3b13b11a-adef-47d1-8783-a389197808cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71481216211, 38.25689987269],
              [-85.71482075634, 38.2568849279],
              [-85.71482273443, 38.25688567006],
              [-85.71482463977, 38.25688235872],
              [-85.71482649275, 38.25687913459],
              [-85.71482464193, 38.25687843756],
              [-85.71483938221, 38.25685280519],
              [-85.71483811055, 38.25685235428],
              [-85.71483892973, 38.25685092978],
              [-85.71483243488, 38.25684879349],
              [-85.71483318454, 38.25684749048],
              [-85.71482248251, 38.25684371496],
              [-85.71482175305, 38.25684498276],
              [-85.71481246198, 38.25684168825],
              [-85.71481318331, 38.25684043433],
              [-85.7148030349, 38.25683685412],
              [-85.71480233597, 38.25683806902],
              [-85.71478989037, 38.25683365595],
              [-85.7147905783, 38.25683245961],
              [-85.71478048054, 38.25682889727],
              [-85.71477981375, 38.25683005676],
              [-85.71477414006, 38.25682804493],
              [-85.71476208412, 38.2568490094],
              [-85.71476017777, 38.25684833356],
              [-85.7147583359, 38.25685157699],
              [-85.71475642873, 38.25685493542],
              [-85.7147581658, 38.25685555141],
              [-85.71474606108, 38.25687660072],
              [-85.71477357246, 38.25688636409],
              [-85.7147779837, 38.25688792018],
              [-85.71478200149, 38.25688933748],
              [-85.71481216211, 38.25689987269],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Braille Improvement!',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71479272164501, 38.25686395881],
            type: 'Point',
          },
        },
      },
      {
        id: '3b1b5610-ef1c-4ad2-bb6e-0a00a85cb891',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71476555553, 38.25700238598],
              [-85.71475575463, 38.25699913347],
              [-85.71475066329, 38.25699737657],
              [-85.71474582911, 38.25699570841],
              [-85.71473118872, 38.25699076505],
              [-85.71472688837, 38.2569986067],
              [-85.71476125519, 38.25701022764],
              [-85.71476555553, 38.25700238598],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71474622195, 38.257000496345],
            type: 'Point',
          },
        },
      },
      {
        id: '3b348ea9-2bc2-4222-b771-fa94e63733bc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71478165853, 38.2569613642],
              [-85.71480326278, 38.2569231386],
              [-85.71479696084, 38.25692094243],
              [-85.71479923349, 38.2569169213],
              [-85.71475730182, 38.25690230847],
              [-85.71475525808, 38.25690591536],
              [-85.71475365233, 38.25690876562],
              [-85.71475199076, 38.25691171496],
              [-85.71473342492, 38.25694455521],
              [-85.71478165853, 38.2569613642],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Research Multipurpose Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71476834385, 38.256931836335],
            type: 'Point',
          },
        },
      },
      {
        id: '3bbc1d6d-90f2-4960-af9f-9095f66b3198',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7145674485, 38.25695469233],
              [-85.71457007426, 38.25694987461],
              [-85.71457158474, 38.25694710319],
              [-85.71457320298, 38.25694413405],
              [-85.7145761171, 38.25693878723],
              [-85.71459819431, 38.25694620655],
              [-85.71458952571, 38.25696211164],
              [-85.7145674485, 38.25695469233],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714582821405, 38.256950449434996],
            type: 'Point',
          },
        },
      },
      {
        id: '3cfbfcc0-75c4-4eb8-a202-2d63e59cca27',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71468879855, 38.25697000819],
              [-85.71471682739, 38.25692255535],
              [-85.71466636236, 38.2569048796],
              [-85.71466220302, 38.25690340692],
              [-85.71465832524, 38.25690202951],
              [-85.71465031728, 38.25689919789],
              [-85.71462271721, 38.25694700732],
              [-85.71463741327, 38.25695244579],
              [-85.71463942208, 38.25694880033],
              [-85.71467114378, 38.25695974674],
              [-85.71466912502, 38.2569633237],
              [-85.71468879855, 38.25697000819],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: {
            en: '2nd Floor Conference Room',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.7146697723, 38.25693460304],
            type: 'Point',
          },
        },
      },
      {
        id: '3d4cf40f-7ad1-441e-ae00-ce0331608728',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71445717782, 38.25671580229],
              [-85.71450926925, 38.25673502505],
              [-85.71449241833, 38.25676316326],
              [-85.71447052949, 38.25675505391],
              [-85.71446493091, 38.25675301585],
              [-85.71445964326, 38.25675109235],
              [-85.71444033337, 38.25674393597],
              [-85.71445717782, 38.25671580229],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71447480131, 38.256739482775004],
            type: 'Point',
          },
        },
      },
      {
        id: '3d936666-16f6-48c7-9e3b-314b1e744729',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7145057533, 38.25747628469],
              [-85.71450400751, 38.25747936642],
              [-85.71450350978, 38.25748024504],
              [-85.71452276249, 38.25748697001],
              [-85.71452606668, 38.25748812416],
              [-85.7145298941, 38.25748946108],
              [-85.71453423359, 38.25749097686],
              [-85.71453555141, 38.25748864326],
              [-85.71454013777, 38.25749024527],
              [-85.71454257882, 38.25748602749],
              [-85.71454489709, 38.25748202186],
              [-85.71454019009, 38.25748042904],
              [-85.71454792703, 38.25746677154],
              [-85.71455232709, 38.2574590044],
              [-85.71452161898, 38.25744827806],
              [-85.71451701842, 38.25745639914],
              [-85.71450937748, 38.25746988717],
              [-85.71450766302, 38.2574729136],
              [-85.7145057533, 38.25747628469],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71452791843501, 38.25746962746],
            type: 'Point',
          },
        },
      },
      {
        id: '3e88a6f2-ec4e-48ac-ba69-6457a6abeafa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71459708125, 38.2568944433],
              [-85.71459478448, 38.25689851525],
              [-85.71459258514, 38.25690241445],
              [-85.71461611166, 38.25691054113],
              [-85.71462058212, 38.25690256101],
              [-85.71459708125, 38.2568944433],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71460658363, 38.256902492215],
            type: 'Point',
          },
        },
      },
      {
        id: '3ec00ca8-20a3-4931-a753-853f0a0c53eb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7141636694, 38.2579148282],
              [-85.71415725443, 38.25791260296],
              [-85.71415448814, 38.25791752009],
              [-85.71411916901, 38.25790526853],
              [-85.71416232076, 38.25782856554],
              [-85.71420405486, 38.25784304236],
              [-85.71419193837, 38.25786462615],
              [-85.71418821832, 38.25787119468],
              [-85.71418383969, 38.25787892606],
              [-85.7141636694, 38.2579148282],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'storage',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714161611935, 38.257873042815],
            type: 'Point',
          },
        },
      },
      {
        id: '3f47c1c0-9ff8-416f-b7d2-eb887eeda728',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71449132358, 38.25680369282],
              [-85.71446041985, 38.25685865637],
              [-85.71446582919, 38.25686053175],
              [-85.71446538187, 38.25686132734],
              [-85.71447230413, 38.25686372723],
              [-85.71447138713, 38.25686535817],
              [-85.71448022528, 38.25686842228],
              [-85.71448934749, 38.25687158488],
              [-85.71449029728, 38.25686989564],
              [-85.71450688589, 38.25687564679],
              [-85.71453459197, 38.2568263704],
              [-85.71453820416, 38.25681994597],
              [-85.71452316272, 38.25681470815],
              [-85.71451493747, 38.25681188783],
              [-85.71450525834, 38.256808569],
              [-85.71449132358, 38.25680369282],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Third Floor Conference Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71449931200499, 38.256839669805004],
            type: 'Point',
          },
        },
      },
      {
        id: '3f75644b-4930-459e-9312-2a55444bd17b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71390657418, 38.2585789672],
              [-85.71381672652, 38.25854652913],
              [-85.71350232501, 38.2584353333],
              [-85.71342506114, 38.25840801328],
              [-85.71345977541, 38.25834749132],
              [-85.7135471435, 38.25837838985],
              [-85.71355641586, 38.25838166911],
              [-85.7135656652, 38.25838494022],
              [-85.71360123377, 38.25839751937],
              [-85.71372111211, 38.25818851944],
              [-85.71372243143, 38.25818898604],
              [-85.71372465569, 38.25818510819],
              [-85.71374533952, 38.25819242323],
              [-85.71376228651, 38.25819841671],
              [-85.71376065126, 38.25820126767],
              [-85.71376632626, 38.25820327469],
              [-85.71376786094, 38.25820060072],
              [-85.7137720683, 38.25820203345],
              [-85.7137765621, 38.25820356372],
              [-85.71377505609, 38.2582061907],
              [-85.71392758069, 38.25826013252],
              [-85.71394029994, 38.25826463081],
              [-85.71395132253, 38.25826852905],
              [-85.71396463176, 38.25827323599],
              [-85.71394373164, 38.25830967397],
              [-85.71395520081, 38.25831373015],
              [-85.71395804726, 38.25830877324],
              [-85.71396692524, 38.25831176685],
              [-85.71397580706, 38.25831476176],
              [-85.71397295271, 38.25831974383],
              [-85.71398690771, 38.25832467915],
              [-85.71400823399, 38.25828749822],
              [-85.71406333411, 38.2583069849],
              [-85.7139699762, 38.258469748],
              [-85.71394012709, 38.2584591916],
              [-85.71393758419, 38.25846362497],
              [-85.71394026392, 38.25846457268],
              [-85.71393805796, 38.25846841862],
              [-85.71393579416, 38.25847236539],
              [-85.71393364173, 38.25847160417],
              [-85.71390999864, 38.25851282422],
              [-85.71393870558, 38.25852297668],
              [-85.71390657418, 38.2585789672],
            ],
            [
              [-85.71390888566, 38.25836418552],
              [-85.71389486762, 38.25835932417],
              [-85.71389656422, 38.25835636879],
              [-85.71388890142, 38.25835364877],
              [-85.7138800326, 38.25835050065],
              [-85.71387816449, 38.25835366313],
              [-85.71386709287, 38.25834982358],
              [-85.71389337898, 38.25830308811],
              [-85.71390455117, 38.25830696255],
              [-85.71390109557, 38.25831288001],
              [-85.71391008018, 38.25831601215],
              [-85.71391884016, 38.25831906598],
              [-85.71392210148, 38.2583132721],
              [-85.71393500483, 38.25831774688],
              [-85.71390888566, 38.25836418552],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.713744197625, 38.258382037695],
            type: 'Point',
          },
        },
      },
      {
        id: '40182329-fac5-456a-8bba-12d73775064a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480863568, 38.25701581471],
              [-85.71481167288, 38.25701042357],
              [-85.71481473211, 38.25701160793],
              [-85.71481777986, 38.25700624383],
              [-85.71482077905, 38.25700096518],
              [-85.71481763779, 38.25699983568],
              [-85.71482147042, 38.25699303263],
              [-85.71478528314, 38.25698040503],
              [-85.71478118323, 38.25697897436],
              [-85.71477707635, 38.25697754126],
              [-85.71477490258, 38.25697678272],
              [-85.71477324981, 38.25697967175],
              [-85.71477173059, 38.25698238639],
              [-85.7147702568, 38.2569849908],
              [-85.71476201924, 38.25699954785],
              [-85.71476784545, 38.25700158091],
              [-85.71479695578, 38.257011739],
              [-85.71480101288, 38.25701315473],
              [-85.71480495042, 38.25701452873],
              [-85.71480863568, 38.25701581471],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71479174483, 38.256996298715],
            type: 'Point',
          },
        },
      },
      {
        id: '41e8e075-f2e8-4916-8d48-d152f32eaad6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7145784866, 38.25682039387],
              [-85.71458449908, 38.2568098735],
              [-85.71458618257, 38.25680692782],
              [-85.71459127014, 38.25679802582],
              [-85.71456377025, 38.25678833495],
              [-85.71455098671, 38.25681070301],
              [-85.7145784866, 38.25682039387],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 303',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714571128425, 38.25680436441],
            type: 'Point',
          },
        },
      },
      {
        id: '425f1312-03c2-4e10-b058-4032f8a92af8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.714412451, 38.25714135616],
              [-85.71440385138, 38.25713825523],
              [-85.71439533261, 38.25713518346],
              [-85.71439225713, 38.25713407447],
              [-85.71437025726, 38.25717169386],
              [-85.71436657306, 38.25717036538],
              [-85.71436398827, 38.25717478532],
              [-85.71436140486, 38.2571792029],
              [-85.71436544446, 38.25718065954],
              [-85.71435633984, 38.25719622827],
              [-85.71435183447, 38.25719460369],
              [-85.71434907416, 38.25719932376],
              [-85.71434640463, 38.25720388862],
              [-85.71434840734, 38.25720461077],
              [-85.71434065022, 38.25721787531],
              [-85.71427524556, 38.25719429111],
              [-85.71427651613, 38.25719211847],
              [-85.71427209538, 38.2571905244],
              [-85.71426762606, 38.25718891281],
              [-85.71426655957, 38.25719073649],
              [-85.71419783194, 38.25716595405],
              [-85.71419896631, 38.25716401432],
              [-85.7141947825, 38.25716250568],
              [-85.71419058697, 38.25716099282],
              [-85.71418981685, 38.25716230972],
              [-85.71414505066, 38.25714616752],
              [-85.71414213304, 38.25715115661],
              [-85.7141413218, 38.25715254381],
              [-85.71414011254, 38.25715461163],
              [-85.71413725713, 38.25715949433],
              [-85.71419360037, 38.25717981109],
              [-85.71417961387, 38.25720372775],
              [-85.71417718046, 38.25720285029],
              [-85.71417543472, 38.25720583548],
              [-85.71417354962, 38.25720905896],
              [-85.71417674385, 38.25721021076],
              [-85.71415738287, 38.25724331766],
              [-85.71415399754, 38.25724209695],
              [-85.71415217689, 38.25724521023],
              [-85.71415036998, 38.25724830001],
              [-85.71415362326, 38.25724947311],
              [-85.714143403, 38.25726694955],
              [-85.7141402079, 38.25726579744],
              [-85.71413849896, 38.25726871969],
              [-85.71413683156, 38.25727157091],
              [-85.71413915826, 38.25727240989],
              [-85.71412011732, 38.25730496951],
              [-85.71411667574, 38.25730372853],
              [-85.7141148077, 38.25730691018],
              [-85.71411293046, 38.25731010749],
              [-85.71411544379, 38.25731101378],
              [-85.71410761682, 38.25732439774],
              [-85.71408380795, 38.25731581254],
              [-85.71407499365, 38.25733088481],
              [-85.71409219256, 38.25733708654],
              [-85.71409252906, 38.25733651113],
              [-85.71409883795, 38.25733878605],
              [-85.71409842056, 38.25733949977],
              [-85.71411338726, 38.25734489659],
              [-85.71409531853, 38.25737579372],
              [-85.7140938085, 38.25737524922],
              [-85.7140922845, 38.25737785522],
              [-85.71409069387, 38.25738057517],
              [-85.71409251435, 38.25738123161],
              [-85.71407525086, 38.25741075179],
              [-85.71407366338, 38.25741017937],
              [-85.71407189634, 38.25741320098],
              [-85.71406979126, 38.25741680061],
              [-85.71407164087, 38.25741746756],
              [-85.7140623114, 38.25743342073],
              [-85.71406037326, 38.25743272186],
              [-85.7140576627, 38.25743735686],
              [-85.71405507868, 38.25744177547],
              [-85.71405705912, 38.2574424896],
              [-85.71404675649, 38.25746010683],
              [-85.71398013623, 38.25743604865],
              [-85.7139748437, 38.25744522834],
              [-85.71397451282, 38.25744579607],
              [-85.71397370073, 38.2574471895],
              [-85.71396926393, 38.25745480235],
              [-85.71396678832, 38.2574590501],
              [-85.71405809187, 38.25749197303],
              [-85.71405982651, 38.25748900685],
              [-85.71406335166, 38.25749027798],
              [-85.71406682796, 38.2574843336],
              [-85.71407036501, 38.25747828534],
              [-85.71406648368, 38.25747688578],
              [-85.71406805509, 38.25747419871],
              [-85.71405915793, 38.2574709905],
              [-85.71412371128, 38.2573606058],
              [-85.71412510124, 38.257361107],
              [-85.71412696346, 38.25735792266],
              [-85.71412857642, 38.25735516454],
              [-85.71412710709, 38.25735463471],
              [-85.71412940066, 38.25735071276],
              [-85.7141305869, 38.2573511405],
              [-85.71413445615, 38.25734452417],
              [-85.71413817282, 38.25733816874],
              [-85.71413692614, 38.2573377192],
              [-85.71414156597, 38.2573297852],
              [-85.71421789931, 38.25735731011],
              [-85.71421698661, 38.25735887081],
              [-85.71422562932, 38.25736198727],
              [-85.71423459316, 38.25736521952],
              [-85.71423595018, 38.25736289904],
              [-85.71425635715, 38.25737025755],
              [-85.71424457348, 38.25739040735],
              [-85.7142462598, 38.25739101542],
              [-85.71424535817, 38.25739255719],
              [-85.71425314291, 38.25739536427],
              [-85.71426073828, 38.25739810307],
              [-85.71426174966, 38.25739637364],
              [-85.71438179811, 38.25743966166],
              [-85.71438679389, 38.257431119],
              [-85.71439223424, 38.25742181612],
              [-85.71427539665, 38.25737968589],
              [-85.71431154925, 38.25731786575],
              [-85.71432438248, 38.25732249326],
              [-85.71433051686, 38.25731200361],
              [-85.71431819012, 38.25730755873],
              [-85.71435893082, 38.25723789296],
              [-85.71436220323, 38.25723907295],
              [-85.71436589666, 38.25723275725],
              [-85.7143696092, 38.25722640889],
              [-85.71436627054, 38.25722520501],
              [-85.71441143076, 38.25714798185],
              [-85.7144090734, 38.25714713181],
              [-85.714412451, 38.25714135616],
            ],
            [
              [-85.7141429821, 38.25730806518],
              [-85.71415767325, 38.25728237829],
              [-85.71415883059, 38.25728294658],
              [-85.7141612692, 38.25727883104],
              [-85.71416336052, 38.25727529955],
              [-85.71419641855, 38.2572194823],
              [-85.71419880838, 38.25721545965],
              [-85.71419644599, 38.2572145857],
              [-85.71421318161, 38.2571853241],
              [-85.71433579656, 38.25723052522],
              [-85.71426419318, 38.2573519801],
              [-85.7141429821, 38.25730806518],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71418961966, 38.25731302375],
            type: 'Point',
          },
        },
      },
      {
        id: '440eef5a-8fba-478e-b4c7-ae27402443dd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71448448711, 38.25677879058],
              [-85.71447515077, 38.25679541826],
              [-85.71447327643, 38.25679878887],
              [-85.71447152707, 38.25680193473],
              [-85.71447024659, 38.25680419301],
              [-85.71443380278, 38.25679159555],
              [-85.7144480433, 38.25676619312],
              [-85.71448448711, 38.25677879058],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714459144945, 38.256785193065],
            type: 'Point',
          },
        },
      },
      {
        id: '4423aaf0-a9c2-475a-84c0-0789d98de29c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71464106975, 38.25680193015],
              [-85.71465042685, 38.25678564978],
              [-85.71460742402, 38.25677040985],
              [-85.71460590213, 38.25677305778],
              [-85.71460148058, 38.25677149081],
              [-85.7145961673, 38.25678073535],
              [-85.71459430037, 38.25678007374],
              [-85.71459241569, 38.25678333397],
              [-85.71459040834, 38.25678680641],
              [-85.71459210049, 38.25678740612],
              [-85.71458672239, 38.25679676343],
              [-85.71459037318, 38.25679805725],
              [-85.71457792825, 38.25681971011],
              [-85.71459013739, 38.25682407577],
              [-85.71459418052, 38.25682547097],
              [-85.7145985962, 38.25682699472],
              [-85.7146024486, 38.25682839997],
              [-85.71460500971, 38.25682394391],
              [-85.71462445636, 38.25683083568],
              [-85.71463702798, 38.2568089624],
              [-85.71463972346, 38.25680427255],
              [-85.71464097854, 38.25680208884],
              [-85.71464106975, 38.25680193015],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Development Coordinator',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71461417755, 38.256800622765],
            type: 'Point',
          },
        },
      },
      {
        id: '454392d1-4734-46c5-ad26-5e29b945e4b3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7140252431, 38.25736297062],
              [-85.71408797543, 38.25738513033],
              [-85.71407366338, 38.25741017937],
              [-85.71407189634, 38.25741320098],
              [-85.71406979126, 38.25741680061],
              [-85.71406883139, 38.25741854695],
              [-85.71400609907, 38.25739638725],
              [-85.7140252431, 38.25736297062],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71404703725, 38.257390758785],
            type: 'Point',
          },
        },
      },
      {
        id: '4579722b-14b5-44ad-b243-940f03ae16a2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71407632679, 38.25776961531],
              [-85.71407761951, 38.25776737924],
              [-85.7140794335, 38.2577642415],
              [-85.71408196528, 38.25775986216],
              [-85.71408376341, 38.25775675188],
              [-85.71408565773, 38.25775347518],
              [-85.71408682699, 38.25775145265],
              [-85.71412266192, 38.25776422651],
              [-85.7141346728, 38.25776850795],
              [-85.7141241726, 38.2577866706],
              [-85.71411213424, 38.25778237937],
              [-85.71407632679, 38.25776961531],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71410549979501, 38.257769061625],
            type: 'Point',
          },
        },
      },
      {
        id: '4680fed8-3e15-4cbe-80af-21ea90753c0a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457345161, 38.25693880923],
              [-85.71455790332, 38.2569333375],
              [-85.71455125323, 38.25693099663],
              [-85.71454456572, 38.25692864259],
              [-85.71449445033, 38.25691100773],
              [-85.71448213353, 38.2569325887],
              [-85.71447745927, 38.25693094394],
              [-85.7144756525, 38.25693404546],
              [-85.71447364055, 38.25693749919],
              [-85.71447831972, 38.25693914608],
              [-85.7144380226, 38.2570097529],
              [-85.714461232, 38.25701792056],
              [-85.7144595099, 38.25702093875],
              [-85.71446682595, 38.25702355905],
              [-85.71447408348, 38.25702615839],
              [-85.71447587222, 38.25702302478],
              [-85.7145170829, 38.25703752729],
              [-85.71454015016, 38.25699710988],
              [-85.71454341765, 38.25699825975],
              [-85.71454726071, 38.25699173928],
              [-85.7145508994, 38.25698515055],
              [-85.71454765908, 38.25698401024],
              [-85.71456782177, 38.25694908211],
              [-85.71457007426, 38.25694987461],
              [-85.71457158474, 38.25694710319],
              [-85.71457320298, 38.25694413405],
              [-85.71457107005, 38.25694338215],
              [-85.71457345161, 38.25693880923],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Product Display Area',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714505737105, 38.25697426751],
            type: 'Point',
          },
        },
      },
      {
        id: '46908382-db94-4264-9e2a-1afac5167f5c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71465011723, 38.25696222965],
              [-85.71464547573, 38.25697092506],
              [-85.71464152225, 38.25697789839],
              [-85.71463751277, 38.2569849705],
              [-85.71463292, 38.25699362804],
              [-85.71460988502, 38.2569859044],
              [-85.714604592, 38.25699547832],
              [-85.7146081709, 38.25699664363],
              [-85.71460480227, 38.25700281178],
              [-85.71460112586, 38.25700914371],
              [-85.71459768907, 38.25700796422],
              [-85.71459635859, 38.25701037076],
              [-85.714573923, 38.25700272259],
              [-85.71457227693, 38.25700579412],
              [-85.71456806414, 38.25700435401],
              [-85.71456389971, 38.25700293074],
              [-85.71456567049, 38.25699990935],
              [-85.71455655524, 38.25699680202],
              [-85.71455752965, 38.25699465394],
              [-85.71454845889, 38.25699136333],
              [-85.71454657692, 38.25699322084],
              [-85.71451395081, 38.25699252179],
              [-85.71449449222, 38.25702600606],
              [-85.71448549047, 38.25702266068],
              [-85.71447687655, 38.25701945944],
              [-85.7144472973, 38.2570088272],
              [-85.71448141931, 38.25695057312],
              [-85.71446792746, 38.25694549211],
              [-85.71446972424, 38.2569422745],
              [-85.71447160933, 38.25693889878],
              [-85.71448537717, 38.25694397059],
              [-85.71449354322, 38.25692963609],
              [-85.71448083934, 38.25692499798],
              [-85.7144828981, 38.25692140901],
              [-85.71448469351, 38.25691827914],
              [-85.71449712519, 38.25692239386],
              [-85.71449974332, 38.25691758314],
              [-85.71450334465, 38.25691885588],
              [-85.71450428436, 38.25691711647],
              [-85.71451529785, 38.25692085459],
              [-85.71452527251, 38.25692424011],
              [-85.71452420831, 38.25692605743],
              [-85.7145276797, 38.25692725281],
              [-85.7145260641, 38.25693032099],
              [-85.71452440894, 38.25693346429],
              [-85.71452069122, 38.25693225093],
              [-85.71449852267, 38.25697128918],
              [-85.71451406779, 38.25697722892],
              [-85.7145535219, 38.25697805409],
              [-85.71455530284, 38.25697973062],
              [-85.7145643736, 38.25698302123],
              [-85.71457008167, 38.25697233565],
              [-85.71459685645, 38.25698146303],
              [-85.71460331233, 38.25697003156],
              [-85.71460093282, 38.2569692504],
              [-85.71460515258, 38.25696154603],
              [-85.71459676227, 38.25695860919],
              [-85.71459998066, 38.25695273731],
              [-85.71460283267, 38.25695353369],
              [-85.71460494293, 38.25694993826],
              [-85.71461042561, 38.25695191862],
              [-85.71461185554, 38.25694930788],
              [-85.71463625582, 38.25695754836],
              [-85.71463722113, 38.25695576427],
              [-85.71464142815, 38.25695709],
              [-85.71464531652, 38.25695847941],
              [-85.71464451681, 38.25696033827],
              [-85.71465011723, 38.25696222965],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71448678284499, 38.256971561265004],
            type: 'Point',
          },
        },
      },
      {
        id: '48137477-4f17-48c8-88b9-9549cb166273',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71434783291, 38.25757124845],
              [-85.71431374858, 38.257558966],
              [-85.71430902585, 38.25756704694],
              [-85.71430789683, 38.25756664009],
              [-85.71430175088, 38.25757715627],
              [-85.71430285329, 38.25757755353],
              [-85.71429603546, 38.25758921934],
              [-85.71433014641, 38.25760151138],
              [-85.71434353634, 38.25757859015],
              [-85.71434543665, 38.25757534857],
              [-85.71434736617, 38.25757205718],
              [-85.71434783291, 38.25757124845],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Break Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714321934185, 38.25758023869],
            type: 'Point',
          },
        },
      },
      {
        id: '49e51b4f-274c-469b-b07d-d4a280f95cdb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7143224218, 38.2573064558],
              [-85.71434033426, 38.25727606927],
              [-85.7143663552, 38.25728559027],
              [-85.71437210341, 38.25728761658],
              [-85.7143778397, 38.25728963869],
              [-85.71437855246, 38.2572899607],
              [-85.71435046358, 38.25733761041],
              [-85.71442055074, 38.25736308546],
              [-85.7143886578, 38.25741718827],
              [-85.71428003886, 38.25737770785],
              [-85.71431282083, 38.25732209684],
              [-85.71432476217, 38.25732643724],
              [-85.71433404955, 38.25731068221],
              [-85.7143224218, 38.2573064558],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7143502948, 38.257346628769994],
            type: 'Point',
          },
        },
      },
      {
        id: '4a20c995-8663-4805-beb0-69da66f428e9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71442682575, 38.25688956521],
              [-85.71441444484, 38.25691081838],
              [-85.7144129428, 38.25691339681],
              [-85.71441052939, 38.25691753968],
              [-85.71440928108, 38.25691968254],
              [-85.71445567434, 38.25693634698],
              [-85.7144605476, 38.25693809745],
              [-85.71446387073, 38.25693929112],
              [-85.71447108672, 38.2569418831],
              [-85.71447364055, 38.25693749919],
              [-85.7144756525, 38.25693404546],
              [-85.71447745927, 38.25693094394],
              [-85.71448862188, 38.2569117821],
              [-85.71446813371, 38.25690442275],
              [-85.71444002846, 38.25689432735],
              [-85.71443788635, 38.25689355791],
              [-85.7144400739, 38.25688979655],
              [-85.71443596202, 38.25688835627],
              [-85.71443148819, 38.2568867892],
              [-85.7144293463, 38.25689047059],
              [-85.71442682575, 38.25688956521],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71444895148, 38.256914336150004],
            type: 'Point',
          },
        },
      },
      {
        id: '4b5df6ca-60ad-4c30-9fce-e49ac72031e8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461573935, 38.25695096536],
              [-85.71463257042, 38.25692114426],
              [-85.7145857485, 38.25690484964],
              [-85.71458082948, 38.25691363171],
              [-85.71457772897, 38.25691906285],
              [-85.71457398548, 38.25692562028],
              [-85.71456891743, 38.25693467073],
              [-85.71460497627, 38.25694720546],
              [-85.71460849038, 38.25694844291],
              [-85.71461181079, 38.25694961215],
              [-85.71461573935, 38.25695096536],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71460074392499, 38.2569279075],
            type: 'Point',
          },
        },
      },
      {
        id: '4b76871d-436b-4fbe-8050-debc94f1449b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71447711311, 38.25693149379],
              [-85.71448083934, 38.25692499798],
              [-85.7144828981, 38.25692140901],
              [-85.71448469351, 38.25691827914],
              [-85.71448830707, 38.25691197974],
              [-85.71447036723, 38.25690587966],
              [-85.71442831245, 38.25689157976],
              [-85.71441711849, 38.25691109382],
              [-85.71447711311, 38.25693149379],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71445271278, 38.256911536775],
            type: 'Point',
          },
        },
      },
      {
        id: '4c304b21-113b-49ab-80b8-90c5dfc33557',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71422455823, 38.25734604931],
              [-85.71418425587, 38.25733182927],
              [-85.71417595691, 38.2573463322],
              [-85.71417378235, 38.25734556494],
              [-85.71417191437, 38.25734882948],
              [-85.71416977903, 38.25735256098],
              [-85.71417545019, 38.25735456196],
              [-85.71416706782, 38.25736921065],
              [-85.71423758687, 38.25739409213],
              [-85.71424662269, 38.25737825707],
              [-85.7142480941, 38.25737572391],
              [-85.71425026877, 38.25737198002],
              [-85.71425071838, 38.25737114403],
              [-85.71423236993, 38.25736467008],
              [-85.71423299674, 38.2573635747],
              [-85.71422848649, 38.25736198333],
              [-85.71422381982, 38.25736033678],
              [-85.71422318758, 38.25736144166],
              [-85.71421699966, 38.25735925835],
              [-85.71422455823, 38.25734604931],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 136A-136B',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7142088931, 38.2573629607],
            type: 'Point',
          },
        },
      },
      {
        id: '4ce46be5-1957-451e-8c35-0e1363fa9ff1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71456381348, 38.25738204302],
              [-85.71454046446, 38.25737394366],
              [-85.71454106464, 38.25737268647],
              [-85.71454494737, 38.25736587877],
              [-85.71456100212, 38.25733743708],
              [-85.71460929193, 38.25735418796],
              [-85.71459402224, 38.2573813305],
              [-85.71456908145, 38.25737267899],
              [-85.71456381348, 38.25738204302],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71457487819501, 38.25735974005],
            type: 'Point',
          },
        },
      },
      {
        id: '4cfb11a5-0c71-4765-adb8-fedc220312b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71377871878, 38.25817883889],
              [-85.71378289563, 38.25818026124],
              [-85.71378707767, 38.25818168535],
              [-85.71379146861, 38.25818318059],
              [-85.71381610379, 38.25819165565],
              [-85.71383049141, 38.25819659267],
              [-85.71381858101, 38.25821799408],
              [-85.71380437561, 38.25821311959],
              [-85.71377963365, 38.25820460967],
              [-85.7137765621, 38.25820356372],
              [-85.7137720683, 38.25820203345],
              [-85.71376786094, 38.25820060072],
              [-85.71376399224, 38.25819928331],
              [-85.7137758272, 38.25817785422],
              [-85.71377871878, 38.25817883889],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Center Stairwell 6',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.713797241825, 38.25819792415],
            type: 'Point',
          },
        },
      },
      {
        id: '4db76740-aae6-4eb3-8e3c-e6c240e82f3d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71458354162, 38.2568257961],
              [-85.71458789806, 38.25682720038],
              [-85.71459250116, 38.25682876273],
              [-85.7145970458, 38.25683030525],
              [-85.71462705983, 38.25684020488],
              [-85.71463920146, 38.25681759339],
              [-85.71460765749, 38.25680714925],
              [-85.71460900323, 38.2568046424],
              [-85.71460481866, 38.25680324998],
              [-85.71460052187, 38.25680182022],
              [-85.71459922635, 38.25680423192],
              [-85.7145957406, 38.25680307779],
              [-85.71458354162, 38.2568257961],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71461137154, 38.25682101255],
            type: 'Point',
          },
        },
      },
      {
        id: '4e431a16-58e1-4107-80a1-fa07e8c8fac9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71445689272, 38.2573777624],
              [-85.7144560266, 38.25737745118],
              [-85.71445136889, 38.25737577753],
              [-85.71444670238, 38.25737410073],
              [-85.71442972337, 38.2573679997],
              [-85.71443155852, 38.25736485063],
              [-85.71442761809, 38.25736343472],
              [-85.71444734763, 38.25732957929],
              [-85.71445266871, 38.25733149131],
              [-85.7144550273, 38.25732744402],
              [-85.714480816, 38.25733671061],
              [-85.71445689272, 38.2573777624],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714454217045, 38.257352603209995],
            type: 'Point',
          },
        },
      },
      {
        id: '4e728134-30f6-44e7-b6d9-ac9d4bd269ab',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461513445, 38.2567689877],
              [-85.71465721697, 38.25678353576],
              [-85.71464727574, 38.25680192161],
              [-85.71464406603, 38.25680085357],
              [-85.71464236711, 38.25680400174],
              [-85.71463904088, 38.25680289493],
              [-85.7146336687, 38.25681284988],
              [-85.71460900323, 38.2568046424],
              [-85.71460481866, 38.25680324998],
              [-85.71460052187, 38.25680182022],
              [-85.71459788916, 38.25680094418],
              [-85.71461513445, 38.2567689877],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714627553065, 38.25679091879],
            type: 'Point',
          },
        },
      },
      {
        id: '4f3eb8dc-67c4-451c-85ea-909d70290095',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7144810612, 38.25677628976],
              [-85.71447635586, 38.25678454067],
              [-85.71447415624, 38.25678838555],
              [-85.71447271103, 38.25679091173],
              [-85.71445546636, 38.25682113783],
              [-85.71444209972, 38.25681643413],
              [-85.71444112587, 38.25681814055],
              [-85.71443670322, 38.25681658422],
              [-85.71443269266, 38.25681517291],
              [-85.71443364183, 38.25681350976],
              [-85.71440467035, 38.25680331474],
              [-85.71441878767, 38.25677857793],
              [-85.71441637928, 38.25677772613],
              [-85.71442195064, 38.25676773919],
              [-85.714424413, 38.25676860913],
              [-85.71443021802, 38.2567584374],
              [-85.71445542037, 38.25676730607],
              [-85.71445598617, 38.25676631476],
              [-85.71446032727, 38.25676783274],
              [-85.71446413617, 38.25676915878],
              [-85.71446357774, 38.25677013736],
              [-85.7144810612, 38.25677628976],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Field Services (East)',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.714442865775, 38.256789787615006],
            type: 'Point',
          },
        },
      },
      {
        id: '4f71d12a-4b65-48dd-b6ea-89b06385bbaa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71418135034, 38.25723412067],
              [-85.71411000181, 38.257209502],
              [-85.7140885978, 38.2572463554],
              [-85.71416078452, 38.2572711057],
              [-85.71416910963, 38.25725610598],
              [-85.71417071162, 38.25725325289],
              [-85.71417234463, 38.25725034455],
              [-85.71418135034, 38.25723412067],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 136P-136U',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71413497406999, 38.25724030385],
            type: 'Point',
          },
        },
      },
      {
        id: '4fd82952-2ec8-47e1-add4-d4edc27df3e1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71455108427, 38.25678650801],
              [-85.71453814874, 38.25680998927],
              [-85.71457095307, 38.25682113334],
              [-85.7145749735, 38.25682249793],
              [-85.71457881563, 38.256823802],
              [-85.71458129769, 38.25682464615],
              [-85.71459421266, 38.25680120221],
              [-85.71459082388, 38.2568000511],
              [-85.71459144343, 38.25679892605],
              [-85.71458801709, 38.25679775242],
              [-85.71458420511, 38.25679644669],
              [-85.71458359646, 38.25679755179],
              [-85.71455108427, 38.25678650801],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7145661807, 38.25680557708],
            type: 'Point',
          },
        },
      },
      {
        id: '50ac65dc-a842-4d2d-83c8-0e63236633aa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71433838452, 38.25752052696],
              [-85.7143421835, 38.25752186552],
              [-85.71436202832, 38.25752885779],
              [-85.71438164568, 38.25753576991],
              [-85.71448728104, 38.25757299022],
              [-85.71452765571, 38.257502336],
              [-85.71449483497, 38.2574907717],
              [-85.71450172536, 38.25747857953],
              [-85.71450400751, 38.25747936642],
              [-85.7145057533, 38.25747628469],
              [-85.71450766302, 38.2574729136],
              [-85.71450557996, 38.25747215616],
              [-85.71452261752, 38.25744226651],
              [-85.71455599643, 38.25745402749],
              [-85.71464619025, 38.25729619122],
              [-85.71449445193, 38.25724272646],
              [-85.71439223424, 38.25742181612],
              [-85.71438679389, 38.257431119],
              [-85.71438179811, 38.25743966166],
              [-85.71440510295, 38.25744789246],
              [-85.71437983997, 38.25749210179],
              [-85.71435885336, 38.25748470722],
              [-85.71433838452, 38.25752052696],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714492287385, 38.257396629324994],
            type: 'Point',
          },
        },
      },
      {
        id: '510c2077-ec97-4ab7-8695-f70672a2a93c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71463702798, 38.2568089624],
              [-85.714638951, 38.2568096343],
              [-85.71463460031, 38.25681731381],
              [-85.71465231436, 38.25682350181],
              [-85.71465721994, 38.25682521547],
              [-85.71466225413, 38.25682697405],
              [-85.71468204196, 38.25683388649],
              [-85.7146828187, 38.25683251546],
              [-85.71468445187, 38.25683308598],
              [-85.71470136922, 38.25680322475],
              [-85.71465237858, 38.25678611096],
              [-85.71464302885, 38.25680261441],
              [-85.71464106975, 38.25680193015],
              [-85.71463972346, 38.25680427255],
              [-85.71463702798, 38.2568089624],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Director of Corporate and Foundation Relations',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.714667984765, 38.256809998725004],
            type: 'Point',
          },
        },
      },
      {
        id: '5165e67d-cfa0-48af-9deb-c5de6e472c82',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71408285873, 38.25821363858],
              [-85.71408423021, 38.25821116589],
              [-85.71408588548, 38.25820818152],
              [-85.71408679661, 38.25820653879],
              [-85.71409720098, 38.25818778026],
              [-85.71410277115, 38.25817773753],
              [-85.71413237271, 38.25818786086],
              [-85.71412677597, 38.25819795149],
              [-85.71411638707, 38.25821668212],
              [-85.71411406652, 38.25822090584],
              [-85.71411708345, 38.25822195901],
              [-85.71411534294, 38.2582250959],
              [-85.71411321309, 38.2582289345],
              [-85.71411023119, 38.25822788667],
              [-85.71410830427, 38.25823139394],
              [-85.71407863793, 38.25822124847],
              [-85.71408285873, 38.25821363858],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71410550531999, 38.258204565735],
            type: 'Point',
          },
        },
      },
      {
        id: '5178993a-e485-4c11-856f-ce64e356c8e6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461109673, 38.25685905853],
              [-85.71460947834, 38.25686195039],
              [-85.71460779129, 38.25686496492],
              [-85.71460323179, 38.25687331996],
              [-85.71460478478, 38.25687384603],
              [-85.71460412665, 38.256875044],
              [-85.71463496873, 38.25688549163],
              [-85.71463852805, 38.25688669734],
              [-85.71464243218, 38.25688802175],
              [-85.71465180118, 38.25689118409],
              [-85.71466148827, 38.2568734877],
              [-85.71464740608, 38.25686873449],
              [-85.71461229054, 38.25685683069],
              [-85.71461109673, 38.25685905853],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71463236003, 38.256874007389996],
            type: 'Point',
          },
        },
      },
      {
        id: '518b505f-40f0-4685-ae46-451aa2b85846',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71437651967, 38.25744384933],
              [-85.71434476033, 38.25749892613],
              [-85.71425300351, 38.2574663018],
              [-85.71428289771, 38.25741445948],
              [-85.71428531786, 38.25741531997],
              [-85.714287183, 38.25741208546],
              [-85.71432011019, 38.25742378125],
              [-85.71432458014, 38.25742538219],
              [-85.71432895287, 38.25742694831],
              [-85.71437651967, 38.25744384933],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71431476159, 38.257455505794994],
            type: 'Point',
          },
        },
      },
      {
        id: '526bb2f5-25f8-4d13-b4f3-faa18a0e5e09',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71462437234, 38.25680145248],
              [-85.71463673355, 38.25680571617],
              [-85.714620644, 38.25683447876],
              [-85.71462878193, 38.25683728574],
              [-85.71462734464, 38.25683985511],
              [-85.71462954177, 38.25684061296],
              [-85.71462569986, 38.25684748097],
              [-85.71462190491, 38.25685426505],
              [-85.71461977571, 38.25685353063],
              [-85.71461804324, 38.25685662768],
              [-85.71460993864, 38.25685383221],
              [-85.71460767877, 38.25685787207],
              [-85.71461109673, 38.25685905853],
              [-85.71460947834, 38.25686195039],
              [-85.71460779129, 38.25686496492],
              [-85.71460441851, 38.25686379629],
              [-85.71459747544, 38.25687620812],
              [-85.71459138986, 38.25687410906],
              [-85.71458513722, 38.25687195237],
              [-85.71459868772, 38.25684772873],
              [-85.71458966478, 38.2568446165],
              [-85.71458854975, 38.2568465178],
              [-85.71458442866, 38.25684509571],
              [-85.71458091825, 38.25684388434],
              [-85.7145818856, 38.25684215582],
              [-85.7145694178, 38.25683777125],
              [-85.71457114134, 38.25683469014],
              [-85.7145131889, 38.25681470092],
              [-85.71451453533, 38.25681229395],
              [-85.71451217982, 38.25681148148],
              [-85.71451446373, 38.25680739864],
              [-85.71451608575, 38.25680449902],
              [-85.71451840041, 38.2568052974],
              [-85.71451975371, 38.25680287816],
              [-85.71453489069, 38.25680809928],
              [-85.71453748489, 38.25680346173],
              [-85.71453180771, 38.25680150353],
              [-85.71454519369, 38.25677757399],
              [-85.71454858894, 38.2567787451],
              [-85.71456123262, 38.25675614253],
              [-85.71457324793, 38.25676028691],
              [-85.71457445146, 38.25675813541],
              [-85.71459344285, 38.25676468601],
              [-85.71459208113, 38.25676712031],
              [-85.7146039398, 38.25677121066],
              [-85.71459181405, 38.25679288735],
              [-85.71459848175, 38.25679518721],
              [-85.71459923622, 38.25679383846],
              [-85.71460855331, 38.25679705215],
              [-85.71461740246, 38.25680010444],
              [-85.71461667583, 38.2568014034],
              [-85.71462315051, 38.25680363668],
              [-85.71462437234, 38.25680145248],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'lobby',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Front Lobby',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714576700755, 38.256816175325],
            type: 'Point',
          },
        },
      },
      {
        id: '52a5a68f-3e41-45a8-99d1-0cb174ce9860',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71415953244, 38.2574124251],
              [-85.71417095695, 38.25739181632],
              [-85.7141675761, 38.2573906607],
              [-85.71416273696, 38.25738900663],
              [-85.7141578894, 38.25738734968],
              [-85.71414405645, 38.25738262141],
              [-85.71413263194, 38.25740323019],
              [-85.71415953244, 38.2574124251],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714151794445, 38.257397523255],
            type: 'Point',
          },
        },
      },
      {
        id: '52ed0fef-5aa6-4f76-860e-e7b8e5c5b1af',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71456203337, 38.25733593633],
              [-85.71456794321, 38.25732566002],
              [-85.71457426975, 38.25732790195],
              [-85.71457837941, 38.25732935537],
              [-85.71458229201, 38.25733073638],
              [-85.71461549449, 38.25734252179],
              [-85.71460958465, 38.25735279809],
              [-85.71456203337, 38.25733593633],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71458876393001, 38.257339229055],
            type: 'Point',
          },
        },
      },
      {
        id: '532fd2ac-2ee4-40a7-97e4-c3d14975e1ff',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71443550762, 38.2572897942],
              [-85.71441346222, 38.25728179677],
              [-85.71440920884, 38.2572890262],
              [-85.71438184848, 38.25727910065],
              [-85.71438287017, 38.25727736408],
              [-85.7143778869, 38.25727555629],
              [-85.71437395057, 38.2572741283],
              [-85.71436983181, 38.25727263413],
              [-85.71434341483, 38.25726305081],
              [-85.71435455241, 38.25724412034],
              [-85.71441785518, 38.25726708477],
              [-85.71441613037, 38.25727001642],
              [-85.71444168872, 38.25727928825],
              [-85.71443550762, 38.2572897942],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714392551775, 38.25726695727],
            type: 'Point',
          },
        },
      },
      {
        id: '53b0ff28-42df-47e1-b87d-82ceb9f83b05',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71450244625, 38.25673956256],
              [-85.71448925043, 38.25676307044],
              [-85.71449080688, 38.25676360916],
              [-85.71448800799, 38.25676859527],
              [-85.71450831848, 38.2567756164],
              [-85.7145129057, 38.25677721288],
              [-85.71451763699, 38.25677885949],
              [-85.71453786954, 38.25678585356],
              [-85.71455579643, 38.25675391745],
              [-85.71450986803, 38.25673802052],
              [-85.71450793584, 38.25674146264],
              [-85.71450244625, 38.25673956256],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71452190221001, 38.25676193704],
            type: 'Point',
          },
        },
      },
      {
        id: '560871b5-0b71-42f1-aeeb-34dc79f0c7dc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7145057533, 38.25747628469],
              [-85.71450400751, 38.25747936642],
              [-85.71450350978, 38.25748024504],
              [-85.71452276249, 38.25748697001],
              [-85.71452606668, 38.25748812416],
              [-85.7145298941, 38.25748946108],
              [-85.71453423359, 38.25749097686],
              [-85.71453555141, 38.25748864326],
              [-85.71454013777, 38.25749024527],
              [-85.71454257882, 38.25748602749],
              [-85.71454489709, 38.25748202186],
              [-85.71454019009, 38.25748042904],
              [-85.71454792703, 38.25746677154],
              [-85.71455232709, 38.2574590044],
              [-85.71452161898, 38.25744827806],
              [-85.71451701842, 38.25745639914],
              [-85.71450937748, 38.25746988717],
              [-85.71450766302, 38.2574729136],
              [-85.7145057533, 38.25747628469],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71452791843501, 38.25746962746],
            type: 'Point',
          },
        },
      },
      {
        id: '56844de3-db48-465b-bc05-51ecf586f2b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71410853744, 38.25739003385],
              [-85.71414058111, 38.25740137042],
              [-85.71416227756, 38.25736355661],
              [-85.71413023389, 38.25735222001],
              [-85.71412857642, 38.25735516454],
              [-85.71412696346, 38.25735792266],
              [-85.71412510124, 38.257361107],
              [-85.71410853744, 38.25739003385],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71413540750001, 38.257376795214995],
            type: 'Point',
          },
        },
      },
      {
        id: '568b94b1-566e-49b5-8647-d6bc159b38f3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71430668008, 38.25717588911],
              [-85.7142742991, 38.25716415401],
              [-85.71426108129, 38.25715936378],
              [-85.71424797214, 38.25718178199],
              [-85.71426762606, 38.25718891281],
              [-85.71427209538, 38.2571905244],
              [-85.71427651613, 38.25719211847],
              [-85.71429357093, 38.25719830731],
              [-85.71430668008, 38.25717588911],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'test',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71427732611, 38.257178835545],
            type: 'Point',
          },
        },
      },
      {
        id: '56af869a-34e2-4772-82d4-4be19ca01e92',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71393579416, 38.25847236539],
              [-85.71393416237, 38.25847508636],
              [-85.71392302891, 38.25849504008],
              [-85.71391569607, 38.2585079874],
              [-85.71394370029, 38.25851776662],
              [-85.71395103313, 38.25850481931],
              [-85.71396219166, 38.25848461995],
              [-85.71396970365, 38.25847170502],
              [-85.71394173332, 38.2584619323],
              [-85.71394026392, 38.25846457268],
              [-85.71393805796, 38.25846841862],
              [-85.71393579416, 38.25847236539],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 4',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71394269986, 38.258489849460005],
            type: 'Point',
          },
        },
      },
      {
        id: '57f40a74-df0a-4a81-ada0-7dff6d9525c9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71397580706, 38.25831476176],
              [-85.71396692524, 38.25831176685],
              [-85.71395804726, 38.25830877324],
              [-85.71395225238, 38.25830681924],
              [-85.71396951999, 38.25827524436],
              [-85.71397498012, 38.25827708549],
              [-85.71398585094, 38.25828075108],
              [-85.71399278704, 38.25828308989],
              [-85.71400321239, 38.25828660527],
              [-85.71398594479, 38.25831818015],
              [-85.71397580706, 38.25831476176],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71397773238499, 38.258296712255],
            type: 'Point',
          },
        },
      },
      {
        id: '59cf144d-0a52-4809-8a2b-e3610fba1664',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71438252994, 38.25720366322],
              [-85.71448902389, 38.25724120362],
              [-85.71442116981, 38.25735989119],
              [-85.71435461552, 38.25733643004],
              [-85.71438237035, 38.25728788244],
              [-85.71437943527, 38.25728684779],
              [-85.7143778397, 38.25728963869],
              [-85.71437210341, 38.25728761658],
              [-85.7143663552, 38.25728559027],
              [-85.71436792271, 38.25728284845],
              [-85.71434240263, 38.25727385233],
              [-85.71436220323, 38.25723907295],
              [-85.71436589666, 38.25723275725],
              [-85.7143696092, 38.25722640889],
              [-85.71438252994, 38.25720366322],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71441571326, 38.25727697385],
            type: 'Point',
          },
        },
      },
      {
        id: '5bd61309-4342-497b-8927-caf6bcca8d06',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480863568, 38.25701581471],
              [-85.71481167288, 38.25701042357],
              [-85.71481473211, 38.25701160793],
              [-85.71481777986, 38.25700624383],
              [-85.71482077905, 38.25700096518],
              [-85.71481763779, 38.25699983568],
              [-85.71482147042, 38.25699303263],
              [-85.71478528314, 38.25698040503],
              [-85.71478118323, 38.25697897436],
              [-85.71477707635, 38.25697754126],
              [-85.71477490258, 38.25697678272],
              [-85.71477324981, 38.25697967175],
              [-85.71477173059, 38.25698238639],
              [-85.7147702568, 38.2569849908],
              [-85.71476201924, 38.25699954785],
              [-85.71476784545, 38.25700158091],
              [-85.71479695578, 38.257011739],
              [-85.71480101288, 38.25701315473],
              [-85.71480495042, 38.25701452873],
              [-85.71480863568, 38.25701581471],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71479174483, 38.256996298715],
            type: 'Point',
          },
        },
      },
      {
        id: '5d5873c4-44cf-46e5-aa17-812b01ff68ef',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71418115814, 38.25718307589],
              [-85.71417930063, 38.25718243351],
              [-85.71417834165, 38.25718414336],
              [-85.71413490909, 38.25716912322],
              [-85.71413531035, 38.25716840778],
              [-85.71413344038, 38.25716776109],
              [-85.71415318639, 38.2571325544],
              [-85.71415423534, 38.25713291716],
              [-85.71415574745, 38.2571302211],
              [-85.71419928711, 38.25714527828],
              [-85.71419814838, 38.25714730861],
              [-85.71420071118, 38.25714819489],
              [-85.7141984499, 38.25715222671],
              [-85.71419900829, 38.25715241984],
              [-85.71419701037, 38.25715597323],
              [-85.71419490497, 38.25715971778],
              [-85.71419436375, 38.2571595306],
              [-85.71418674876, 38.25717306364],
              [-85.71418484978, 38.25717649365],
              [-85.7141829339, 38.25717995419],
              [-85.71418115814, 38.25718307589],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71416707578, 38.25715718223],
            type: 'Point',
          },
        },
      },
      {
        id: '5d5a6114-9f3e-4125-befb-a3dab0092dde',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71466303492, 38.25690077498],
              [-85.71467817983, 38.25687410449],
              [-85.71470798611, 38.25688454971],
              [-85.71471126667, 38.2568856893],
              [-85.7147142723, 38.25688673339],
              [-85.71474362726, 38.25689702033],
              [-85.71472846344, 38.25692372412],
              [-85.71467409343, 38.25690468696],
              [-85.71467347705, 38.25690577135],
              [-85.71466915215, 38.25690431656],
              [-85.71466538035, 38.25690304781],
              [-85.7146660667, 38.25690183653],
              [-85.71466303492, 38.25690077498],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Copy Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71470333108999, 38.256898914305],
            type: 'Point',
          },
        },
      },
      {
        id: '5f0a792c-a038-4af7-b0e4-a9866ec8dc47',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71454207056, 38.25731524178],
              [-85.71453225428, 38.25732254813],
              [-85.71453387658, 38.25732385315],
              [-85.71453000859, 38.25732670105],
              [-85.71452615269, 38.25732944151],
              [-85.71452437574, 38.25732824743],
              [-85.71450622931, 38.257341585],
              [-85.71450022752, 38.25733936065],
              [-85.71449891621, 38.25734154512],
              [-85.71448686203, 38.25733736579],
              [-85.7144987064, 38.25731801076],
              [-85.71449453724, 38.2573164516],
              [-85.71449389916, 38.25731753612],
              [-85.71448934883, 38.25731587551],
              [-85.71448479084, 38.2573142121],
              [-85.71448559826, 38.25731310864],
              [-85.71445636953, 38.25730217782],
              [-85.714444343, 38.25732311628],
              [-85.7144196467, 38.25731422578],
              [-85.71441848071, 38.25731611812],
              [-85.71441446493, 38.25731466407],
              [-85.71441079173, 38.2573133318],
              [-85.71441192333, 38.25731144542],
              [-85.7143958242, 38.25730564984],
              [-85.71439481898, 38.25730727189],
              [-85.71438922906, 38.25730526239],
              [-85.71438423105, 38.25730346394],
              [-85.7143852971, 38.25730186016],
              [-85.71433343834, 38.25728319135],
              [-85.71433500428, 38.25728038406],
              [-85.71433894691, 38.25727359644],
              [-85.71434286343, 38.2572668538],
              [-85.71434413573, 38.25726485006],
              [-85.71436925907, 38.25727370309],
              [-85.71436983181, 38.25727263413],
              [-85.71437395057, 38.2572741283],
              [-85.7143778869, 38.25727555629],
              [-85.71437739348, 38.25727656951],
              [-85.71437912304, 38.25727717898],
              [-85.71437784243, 38.25727922021],
              [-85.71441103307, 38.25729109302],
              [-85.71441550168, 38.25728337352],
              [-85.71443706182, 38.25729116119],
              [-85.71444541297, 38.25727698835],
              [-85.71444541297, 38.25727698835],
              [-85.7144519234, 38.25727936239],
              [-85.71445304008, 38.25727762668],
              [-85.71445778135, 38.2572793231],
              [-85.71446224512, 38.25728092023],
              [-85.71446105706, 38.257282693],
              [-85.71446723599, 38.25728494615],
              [-85.71446575275, 38.25728771793],
              [-85.71447711007, 38.25729186036],
              [-85.71447764566, 38.25729086042],
              [-85.71448207126, 38.25729246737],
              [-85.7144864179, 38.25729404564],
              [-85.71448588156, 38.25729505965],
              [-85.71451853239, 38.2573069686],
              [-85.71451656827, 38.25731025631],
              [-85.71452235006, 38.25731240395],
              [-85.71452449224, 38.25730878691],
              [-85.7145259222, 38.257309312],
              [-85.71452674738, 38.25730785657],
              [-85.71453070235, 38.25730929538],
              [-85.71453477691, 38.25731078873],
              [-85.71453389396, 38.25731223928],
              [-85.71454207056, 38.25731524178],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71442696815, 38.257303217529994],
            type: 'Point',
          },
        },
      },
      {
        id: '5fa5de83-9aaf-4bc1-af93-2d04b12c3859',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71412622233, 38.2579875866],
              [-85.71414484924, 38.25799440498],
              [-85.71415055837, 38.25799646102],
              [-85.71415613852, 38.25799847062],
              [-85.71422571316, 38.2580238679],
              [-85.71420359344, 38.2580612681],
              [-85.7141041026, 38.25802498681],
              [-85.71412622233, 38.2579875866],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71416490788, 38.25802442735],
            type: 'Point',
          },
        },
      },
      {
        id: '6108e94f-aa27-4393-a11c-47d3653c303b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71419468432, 38.25766939238],
              [-85.71421349949, 38.25767611038],
              [-85.71421847337, 38.25767787993],
              [-85.71422316842, 38.25767955027],
              [-85.71423634807, 38.2576842575],
              [-85.71425765743, 38.25764743116],
              [-85.71421599368, 38.25763256603],
              [-85.71420977375, 38.25764331517],
              [-85.71420764237, 38.25764699857],
              [-85.71420552716, 38.25765065403],
              [-85.71419468432, 38.25766939238],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71422617087501, 38.257658411765],
            type: 'Point',
          },
        },
      },
      {
        id: '6127ce7b-ae08-4ce5-b85a-824b350feb4a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71445819364, 38.25737838472],
              [-85.71447936248, 38.25738597315],
              [-85.7144835953, 38.2573874905],
              [-85.71448785535, 38.2573890176],
              [-85.71448861213, 38.25738928889],
              [-85.71451011549, 38.25735230155],
              [-85.71449190809, 38.25734577471],
              [-85.71449433588, 38.25734159873],
              [-85.71448212479, 38.2573372214],
              [-85.71445819364, 38.25737838472],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714484154565, 38.257363255145],
            type: 'Point',
          },
        },
      },
      {
        id: '61781969-f149-4990-b6db-5f74979f75d2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71429277768, 38.25753008675],
              [-85.71429788541, 38.25753188733],
              [-85.71430819255, 38.25753557804],
              [-85.71431793653, 38.25753906709],
              [-85.71432222917, 38.25754057766],
              [-85.71433381959, 38.2575205148],
              [-85.71430436811, 38.25751002387],
              [-85.71429277768, 38.25753008675],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Do not use Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714313298635, 38.257525300764996],
            type: 'Point',
          },
        },
      },
      {
        id: '6283443d-6964-48e7-a91b-ed8b499c2c54',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71419897609, 38.25717368387],
              [-85.71421229891, 38.25714998861],
              [-85.71420232036, 38.25714652917],
              [-85.71419900829, 38.25715241984],
              [-85.71419701037, 38.25715597323],
              [-85.71419490497, 38.25715971778],
              [-85.71418899754, 38.25717022442],
              [-85.71419897609, 38.25717368387],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714200648225, 38.257160106520004],
            type: 'Point',
          },
        },
      },
      {
        id: '62942654-bcca-41de-9265-6e8cf4b99f50',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71466161117, 38.2568281047],
              [-85.71466225413, 38.25682697405],
              [-85.71465721994, 38.25682521547],
              [-85.71465231436, 38.25682350181],
              [-85.71465166746, 38.25682463913],
              [-85.71463392444, 38.25681841701],
              [-85.71462503358, 38.25683405001],
              [-85.71462860685, 38.25683530309],
              [-85.71462803732, 38.2568363045],
              [-85.71464252561, 38.25684141037],
              [-85.7146471079, 38.25684299162],
              [-85.71465145246, 38.25684449082],
              [-85.71467384732, 38.2568523691],
              [-85.7146828288, 38.25683657676],
              [-85.7146808938, 38.2568358982],
              [-85.71468034101, 38.25683570435],
              [-85.7146808301, 38.25683484438],
              [-85.71466161117, 38.2568281047],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Director of Grants',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71465393119, 38.256835393055],
            type: 'Point',
          },
        },
      },
      {
        id: '62c9167e-1e88-4a5f-8813-d48465a4c2c0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71482126413, 38.2569217524],
              [-85.714823072, 38.25691868758],
              [-85.71482478424, 38.25691557699],
              [-85.71482645399, 38.25691254357],
              [-85.71484234994, 38.25688476631],
              [-85.71487830016, 38.2568974038],
              [-85.71485721435, 38.25693438988],
              [-85.71482126413, 38.2569217524],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714849782145, 38.256909578095005],
            type: 'Point',
          },
        },
      },
      {
        id: '63d7e688-6eb0-4ccf-a1c7-2a908cae7251',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71408285873, 38.25821363858],
              [-85.71408423021, 38.25821116589],
              [-85.71408588548, 38.25820818152],
              [-85.71408679661, 38.25820653879],
              [-85.71409720098, 38.25818778026],
              [-85.71410277115, 38.25817773753],
              [-85.71413237271, 38.25818786086],
              [-85.71412677597, 38.25819795149],
              [-85.71411638707, 38.25821668212],
              [-85.71411406652, 38.25822090584],
              [-85.71411708345, 38.25822195901],
              [-85.71411534294, 38.2582250959],
              [-85.71411321309, 38.2582289345],
              [-85.71411023119, 38.25822788667],
              [-85.71410830427, 38.25823139394],
              [-85.71407863793, 38.25822124847],
              [-85.71408285873, 38.25821363858],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71410550531999, 38.258204565735],
            type: 'Point',
          },
        },
      },
      {
        id: '64357b2c-7022-4c94-83a9-eba0d1bb8983',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71467228405, 38.25684674671],
              [-85.71468242282, 38.25682882208],
              [-85.714648796, 38.25681709394],
              [-85.71464949453, 38.25681585845],
              [-85.71464457116, 38.25681416949],
              [-85.71464030045, 38.25681270442],
              [-85.71463964273, 38.25681386705],
              [-85.71463251244, 38.25681138019],
              [-85.71463310206, 38.25681033778],
              [-85.71462826611, 38.25680865376],
              [-85.71462474123, 38.25680741974],
              [-85.71462416619, 38.25680843636],
              [-85.71459174057, 38.25679712716],
              [-85.71457991188, 38.25681803801],
              [-85.71461155408, 38.25682920067],
              [-85.71461598625, 38.25683076415],
              [-85.71462067444, 38.25683241794],
              [-85.71462217472, 38.2568329487],
              [-85.71462391417, 38.25682987658],
              [-85.71467228405, 38.25684674671],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Public Affairs',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71463116735, 38.256821936935],
            type: 'Point',
          },
        },
      },
      {
        id: '64410b31-67a6-4196-a089-12058f140aba',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71459483182, 38.25686929099],
              [-85.7145995286, 38.25686080753],
              [-85.71460295725, 38.25686198622],
              [-85.71460714887, 38.25686340891],
              [-85.714611326, 38.25686482668],
              [-85.71464352388, 38.25687582651],
              [-85.71463887175, 38.25688432503],
              [-85.71459483182, 38.25686929099],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71461917785, 38.25687256628],
            type: 'Point',
          },
        },
      },
      {
        id: '6441bbb5-a4ff-443d-8953-82d3eba05ba5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71393579416, 38.25847236539],
              [-85.71393416237, 38.25847508636],
              [-85.71392302891, 38.25849504008],
              [-85.71391569607, 38.2585079874],
              [-85.71394370029, 38.25851776662],
              [-85.71395103313, 38.25850481931],
              [-85.71396219166, 38.25848461995],
              [-85.71396970365, 38.25847170502],
              [-85.71394173332, 38.2584619323],
              [-85.71394026392, 38.25846457268],
              [-85.71393805796, 38.25846841862],
              [-85.71393579416, 38.25847236539],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 4',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71394269986, 38.258489849460005],
            type: 'Point',
          },
        },
      },
      {
        id: '644389f3-2236-453e-b858-eddc29126689',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71407249194, 38.25738253547],
              [-85.71404389521, 38.25737272119],
              [-85.71405545372, 38.25735195476],
              [-85.71407297453, 38.25735796783],
              [-85.71407759774, 38.25735955355],
              [-85.71408234885, 38.25736118505],
              [-85.71408405045, 38.25736176904],
              [-85.71407249194, 38.25738253547],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71406397282999, 38.257367245115],
            type: 'Point',
          },
        },
      },
      {
        id: '644841c5-fce4-4f29-98d1-2b1e03162065',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71433966854, 38.25721569602],
              [-85.71434066724, 38.25721395769],
              [-85.71434317604, 38.2572095909],
              [-85.71434553075, 38.25720549231],
              [-85.71434640463, 38.25720388862],
              [-85.71434907416, 38.25719932376],
              [-85.71435183447, 38.25719460369],
              [-85.71435292272, 38.25719262593],
              [-85.71434394045, 38.25718944398],
              [-85.7143193758, 38.25718067702],
              [-85.7143105379, 38.25717754284],
              [-85.71430715822, 38.25718344467],
              [-85.71429727187, 38.2572006087],
              [-85.71430610978, 38.25720374288],
              [-85.71433068627, 38.25721251407],
              [-85.71433966854, 38.25721569602],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Stair',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71432509729499, 38.25719661943],
            type: 'Point',
          },
        },
      },
      {
        id: '64ef1854-9dde-451f-9d43-d65d9775cce4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71463780762, 38.25680439644],
              [-85.71464348337, 38.25679394699],
              [-85.71464515363, 38.25679099524],
              [-85.71464682567, 38.25678804036],
              [-85.71464752164, 38.25678666925],
              [-85.71463462525, 38.25678231177],
              [-85.71462491123, 38.25680003896],
              [-85.71463780762, 38.25680439644],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714636216435, 38.256793354105],
            type: 'Point',
          },
        },
      },
      {
        id: '650e5f46-f1e8-4013-af09-fa8b1e98e998',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71467863442, 38.25705871586],
              [-85.71469014349, 38.25706277469],
              [-85.71469488953, 38.2570644713],
              [-85.71469970041, 38.25706619109],
              [-85.71472207666, 38.25707409763],
              [-85.71470546909, 38.25710301891],
              [-85.71466202684, 38.25708763714],
              [-85.71467863442, 38.25705871586],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71469205175, 38.257080867385],
            type: 'Point',
          },
        },
      },
      {
        id: '6680ffe0-4546-4c27-ab92-529eaf70c94a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71444641148, 38.25685483296],
              [-85.714463384, 38.25682518745],
              [-85.71449112694, 38.25683503204],
              [-85.7144950063, 38.25683634874],
              [-85.71449847006, 38.25683752439],
              [-85.71450776197, 38.25684085363],
              [-85.71449078945, 38.25687049913],
              [-85.71444641148, 38.25685483296],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714477086725, 38.256847843290004],
            type: 'Point',
          },
        },
      },
      {
        id: '66df0df3-ae93-4f4a-84fa-ade7ef25e15b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71481321017, 38.25690728083],
              [-85.71482122281, 38.2568923212],
              [-85.71475576716, 38.2568707035],
              [-85.71474775452, 38.25688566313],
              [-85.71479695856, 38.25690187674],
              [-85.71480090692, 38.25690321687],
              [-85.71480505679, 38.25690462539],
              [-85.71481321017, 38.25690728083],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714784488665, 38.256888992165],
            type: 'Point',
          },
        },
      },
      {
        id: '672c4fae-7b33-49d9-bfe8-ffb7866f5ae4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71445239952, 38.25682790994],
              [-85.71443748919, 38.25685435681],
              [-85.71448745031, 38.25687172494],
              [-85.71448881393, 38.25686930625],
              [-85.71449012345, 38.25686976148],
              [-85.71450333665, 38.25684632485],
              [-85.71450203921, 38.25684587381],
              [-85.71450371639, 38.25684289895],
              [-85.71448391663, 38.25683601765],
              [-85.71447940135, 38.25683444621],
              [-85.71447508923, 38.25683294548],
              [-85.71445449146, 38.25682578674],
              [-85.71445314778, 38.25682817006],
              [-85.71445239952, 38.25682790994],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71447060279, 38.25684875584],
            type: 'Point',
          },
        },
      },
      {
        id: '67614ab2-c68a-42a8-9f3a-f9ba9633f6ea',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71449552943, 38.25703383942],
              [-85.71447408348, 38.25702615839],
              [-85.71446682595, 38.25702355905],
              [-85.7144595099, 38.25702093875],
              [-85.71445724782, 38.25702012857],
              [-85.71444786488, 38.25703628223],
              [-85.71444293807, 38.25703451766],
              [-85.71444065939, 38.25703844065],
              [-85.71443820832, 38.2570426604],
              [-85.71444242966, 38.25704417231],
              [-85.71434817568, 38.25720643961],
              [-85.71434553075, 38.25720549231],
              [-85.71434317604, 38.2572095909],
              [-85.71434066724, 38.25721395769],
              [-85.71434329858, 38.25721490025],
              [-85.71431830046, 38.25725793686],
              [-85.71431680914, 38.25725740273],
              [-85.71431290339, 38.25726412685],
              [-85.71430896093, 38.25727091416],
              [-85.71431104688, 38.25727166126],
              [-85.71425234418, 38.25737272334],
              [-85.71425026877, 38.25737198002],
              [-85.7142480941, 38.25737572391],
              [-85.71424662269, 38.25737825707],
              [-85.71424869845, 38.25737900051],
              [-85.71423565333, 38.25740145886],
              [-85.71423354513, 38.2574007038],
              [-85.71423168389, 38.25740390809],
              [-85.71422976156, 38.25740721755],
              [-85.71423177969, 38.25740794036],
              [-85.71420666548, 38.25745106452],
              [-85.7142279952, 38.25745870213],
              [-85.71424913675, 38.25746627235],
              [-85.71428278192, 38.25740837568],
              [-85.71432108928, 38.25742209567],
              [-85.71432011019, 38.25742378125],
              [-85.71432458014, 38.25742538219],
              [-85.71432895287, 38.25742694831],
              [-85.7143299386, 38.25742525129],
              [-85.71439877622, 38.25744990586],
              [-85.71439788295, 38.2574514437],
              [-85.71440195737, 38.25745290298],
              [-85.71440595155, 38.25745433351],
              [-85.71440685222, 38.25745278292],
              [-85.71443534153, 38.25746298652],
              [-85.7144344589, 38.25746450604],
              [-85.71443786971, 38.25746572764],
              [-85.71444129221, 38.25746695343],
              [-85.71444218089, 38.25746542348],
              [-85.71448055485, 38.25747916732],
              [-85.71447851861, 38.25748267288],
              [-85.71449367048, 38.2574880996],
              [-85.71449959563, 38.25747778704],
              [-85.71450400751, 38.25747936642],
              [-85.7145057533, 38.25747628469],
              [-85.71450766302, 38.2574729136],
              [-85.71450350042, 38.25747142225],
              [-85.71450406218, 38.25747045128],
              [-85.71446903857, 38.25745790739],
              [-85.71446037402, 38.25745480414],
              [-85.71445316584, 38.25745222249],
              [-85.71440365263, 38.25743448907],
              [-85.71440444237, 38.25743312947],
              [-85.71440048752, 38.25743171301],
              [-85.7143966896, 38.25743035277],
              [-85.71439589576, 38.25743171944],
              [-85.71429118347, 38.25739421615],
              [-85.71435086091, 38.257291476],
              [-85.71432977678, 38.25728392459],
              [-85.7143323794, 38.25727944394],
              [-85.71433500428, 38.25728038406],
              [-85.71433894691, 38.25727359644],
              [-85.71434286343, 38.2572668538],
              [-85.71434021667, 38.25726590584],
              [-85.71437441462, 38.25720703087],
              [-85.71441329578, 38.25714009325],
              [-85.71443027827, 38.25714617564],
              [-85.71449552943, 38.25703383942],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71435716425, 38.257254114085],
            type: 'Point',
          },
        },
      },
      {
        id: '6786fbe0-ea91-4b42-929c-6c0ff930ad71',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71446986092, 38.25680476359],
              [-85.71443324667, 38.25679212483],
              [-85.71441993927, 38.25681589584],
              [-85.71445655352, 38.2568285346],
              [-85.71446525407, 38.25681301513],
              [-85.71446718099, 38.25680954998],
              [-85.71446891643, 38.25680642916],
              [-85.71446986092, 38.25680476359],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714444900095, 38.256810329714995],
            type: 'Point',
          },
        },
      },
      {
        id: '679d58b4-96b9-4978-978f-4c0532b3b607',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457009231, 38.25675313645],
              [-85.71461210657, 38.2567675277],
              [-85.71459414952, 38.25679985297],
              [-85.71459144343, 38.25679892605],
              [-85.71458801709, 38.25679775242],
              [-85.71458420511, 38.25679644669],
              [-85.71455213526, 38.25678546173],
              [-85.71457009231, 38.25675313645],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714582120915, 38.256776494709996],
            type: 'Point',
          },
        },
      },
      {
        id: '67f08e69-9079-49dc-8f40-244b5d2c04f1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71474936952, 38.25699155968],
              [-85.71467601234, 38.25696671899],
              [-85.7147175269, 38.25689112518],
              [-85.71472315265, 38.25689302707],
              [-85.71472716745, 38.25689438974],
              [-85.71473111264, 38.25689572879],
              [-85.71479088408, 38.2569159659],
              [-85.71475992686, 38.25697234361],
              [-85.71475807216, 38.25697571301],
              [-85.71475626464, 38.2569789967],
              [-85.71474936952, 38.25699155968],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71473344821001, 38.25694134243],
            type: 'Point',
          },
        },
      },
      {
        id: '680a97d1-6775-4207-9dcb-92a61a089847',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71437167136, 38.25754660306],
              [-85.71440942701, 38.25748148081],
              [-85.71441166077, 38.25747767042],
              [-85.71441370301, 38.25747418674],
              [-85.71442600854, 38.25745293656],
              [-85.71438454666, 38.25743810583],
              [-85.71437801073, 38.25744937246],
              [-85.7143754803, 38.25744846894],
              [-85.71436698796, 38.25746338812],
              [-85.7143692334, 38.25746419061],
              [-85.71436477489, 38.25747187618],
              [-85.71435577213, 38.25748739515],
              [-85.71435373831, 38.25748666489],
              [-85.71434771487, 38.25749724675],
              [-85.71434946843, 38.2574978774],
              [-85.71434191531, 38.25751089749],
              [-85.71433921429, 38.25751555351],
              [-85.71433761798, 38.25751498478],
              [-85.71433146072, 38.25752580174],
              [-85.71433293655, 38.25752632803],
              [-85.71432985233, 38.2575316446],
              [-85.71437167136, 38.25754660306],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Office',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714377930435, 38.257492354445],
            type: 'Point',
          },
        },
      },
      {
        id: '68f93540-51e2-4706-bd3c-f261bc96e375',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7144206954, 38.25740786641],
              [-85.71441926168, 38.25740733857],
              [-85.7144156098, 38.25740604595],
              [-85.7144118298, 38.25740467545],
              [-85.71438180574, 38.25739382442],
              [-85.71436674547, 38.25741954257],
              [-85.7143966896, 38.25743035277],
              [-85.71440048752, 38.25743171301],
              [-85.71440444237, 38.25743312947],
              [-85.71440563513, 38.25743358456],
              [-85.7144206954, 38.25740786641],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714393720435, 38.25741370449],
            type: 'Point',
          },
        },
      },
      {
        id: '694bee31-b3f7-4b2c-a04e-a105ba0b0ad5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480863568, 38.25701581471],
              [-85.71481167288, 38.25701042357],
              [-85.71481473211, 38.25701160793],
              [-85.71481777986, 38.25700624383],
              [-85.71482077905, 38.25700096518],
              [-85.71481763779, 38.25699983568],
              [-85.71482147042, 38.25699303263],
              [-85.71478528314, 38.25698040503],
              [-85.71478118323, 38.25697897436],
              [-85.71477707635, 38.25697754126],
              [-85.71477490258, 38.25697678272],
              [-85.71477324981, 38.25697967175],
              [-85.71477173059, 38.25698238639],
              [-85.7147702568, 38.2569849908],
              [-85.71476201924, 38.25699954785],
              [-85.71476784545, 38.25700158091],
              [-85.71479695578, 38.257011739],
              [-85.71480101288, 38.25701315473],
              [-85.71480495042, 38.25701452873],
              [-85.71480863568, 38.25701581471],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71479174483, 38.256996298715],
            type: 'Point',
          },
        },
      },
      {
        id: '6a125fc8-da1b-403f-89be-c615f9424b8f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71455315327, 38.25689285769],
              [-85.71454788852, 38.2569018468],
              [-85.71454581823, 38.25690538164],
              [-85.71454351448, 38.25690931511],
              [-85.71453836822, 38.2569181019],
              [-85.71452898238, 38.25691471236],
              [-85.71452683629, 38.25691837662],
              [-85.71450047973, 38.25690885837],
              [-85.71450617068, 38.25689914156],
              [-85.71450395105, 38.25689833998],
              [-85.71450734964, 38.25689253719],
              [-85.71450974339, 38.25689340165],
              [-85.71451165951, 38.25689013003],
              [-85.71452876866, 38.25689630872],
              [-85.71453648524, 38.25688313333],
              [-85.7145472629, 38.25688702551],
              [-85.71454547181, 38.25689008365],
              [-85.71455315327, 38.25689285769],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714535531625, 38.256900754975],
            type: 'Point',
          },
        },
      },
      {
        id: '6aa495d5-e7e4-4415-a0e2-ba40632d319b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461749384, 38.25734201788],
              [-85.71458284289, 38.25732977502],
              [-85.71458229201, 38.25733073638],
              [-85.71457837941, 38.25732935537],
              [-85.71457426975, 38.25732790195],
              [-85.71457482204, 38.25732693813],
              [-85.71457261593, 38.25732615867],
              [-85.71457408593, 38.25732359328],
              [-85.71456187468, 38.25731927881],
              [-85.71456220712, 38.25731869865],
              [-85.71456416025, 38.25731533368],
              [-85.71456606709, 38.25731196239],
              [-85.7145838118, 38.25728099707],
              [-85.71464092177, 38.25730113233],
              [-85.71461749384, 38.25734201788],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714601398225, 38.257311507474995],
            type: 'Point',
          },
        },
      },
      {
        id: '6b68a78a-2ad5-4fcf-845f-6dfac072d4fb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71470812121, 38.25687242451],
              [-85.71471655912, 38.25685724106],
              [-85.7146842654, 38.25684617508],
              [-85.7146758275, 38.25686135853],
              [-85.71468711646, 38.25686524158],
              [-85.71469167049, 38.25686678728],
              [-85.71469610686, 38.25686829304],
              [-85.71470812121, 38.25687242451],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71469619331, 38.256859299794996],
            type: 'Point',
          },
        },
      },
      {
        id: '6b8ff04f-1dbe-4afa-bece-19bb22f0eb55',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71428918254, 38.25750347318],
              [-85.71429272497, 38.25750473168],
              [-85.71429813199, 38.25750665261],
              [-85.71430332259, 38.25750849666],
              [-85.71430487094, 38.25750904673],
              [-85.71432122375, 38.25751485632],
              [-85.71433401656, 38.25751940117],
              [-85.71434515747, 38.25750006514],
              [-85.71433237076, 38.25749552246],
              [-85.71431588206, 38.25748966459],
              [-85.71430032345, 38.25748413714],
              [-85.71429728743, 38.25748940644],
              [-85.71429455211, 38.25749415382],
              [-85.71428918254, 38.25750347318],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Stairs',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714317170005, 38.257501769155],
            type: 'Point',
          },
        },
      },
      {
        id: '6b9fbb15-7fd6-4fd8-9136-22fd724a6459',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71440632045, 38.25691843557],
              [-85.71438159825, 38.25690962455],
              [-85.71438057398, 38.25691139664],
              [-85.71437680216, 38.25691004902],
              [-85.71437249937, 38.25690851884],
              [-85.71437356447, 38.25690667613],
              [-85.71434860957, 38.25689778218],
              [-85.71436600311, 38.25686768979],
              [-85.71442397438, 38.25688835082],
              [-85.71441157849, 38.25690979681],
              [-85.71441444484, 38.25691081838],
              [-85.7144129428, 38.25691339681],
              [-85.71441052939, 38.25691753968],
              [-85.71440746874, 38.25691644891],
              [-85.71440632045, 38.25691843557],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71438629197499, 38.25689306268],
            type: 'Point',
          },
        },
      },
      {
        id: '6bec132d-6dd4-4a18-be9f-c25d13736d17',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461109673, 38.25685905853],
              [-85.71460947834, 38.25686195039],
              [-85.71460779129, 38.25686496492],
              [-85.71460323179, 38.25687331996],
              [-85.71460478478, 38.25687384603],
              [-85.71460412665, 38.256875044],
              [-85.71463496873, 38.25688549163],
              [-85.71463852805, 38.25688669734],
              [-85.71464243218, 38.25688802175],
              [-85.71465180118, 38.25689118409],
              [-85.71466148827, 38.2568734877],
              [-85.71464740608, 38.25686873449],
              [-85.71461229054, 38.25685683069],
              [-85.71461109673, 38.25685905853],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71463236003, 38.256874007389996],
            type: 'Point',
          },
        },
      },
      {
        id: '6c16127e-409f-46c1-96d1-b422f7cce020',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71438502949, 38.25720028541],
              [-85.71441803027, 38.25714221937],
              [-85.71441907521, 38.25714253772],
              [-85.7144232129, 38.25714403588],
              [-85.71442726668, 38.25714550365],
              [-85.71446747461, 38.25715954635],
              [-85.71447386633, 38.25714829989],
              [-85.71468816364, 38.25722339694],
              [-85.71464877114, 38.25729270935],
              [-85.71438502949, 38.25720028541],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71453659656501, 38.25721746436],
            type: 'Point',
          },
        },
      },
      {
        id: '6cc945f1-9d6f-414a-93c2-885640b5a3ce',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71450869067, 38.25739837898],
              [-85.71449862573, 38.2574156979],
              [-85.71447787096, 38.2574082607],
              [-85.71447719847, 38.25740933709],
              [-85.71447318263, 38.25741645042],
              [-85.71446912067, 38.25742354373],
              [-85.71442006015, 38.25740596462],
              [-85.71441926168, 38.25740733857],
              [-85.7144156098, 38.25740604595],
              [-85.7144118298, 38.25740467545],
              [-85.71441270572, 38.25740316825],
              [-85.71437967638, 38.25739133261],
              [-85.7143640106, 38.257418289],
              [-85.7142943459, 38.25739332556],
              [-85.71432884327, 38.25733396528],
              [-85.71444569328, 38.257375837],
              [-85.71444670238, 38.25737410073],
              [-85.71445136889, 38.25737577753],
              [-85.7144560266, 38.25737745118],
              [-85.71445503313, 38.25737916059],
              [-85.71447844594, 38.25738755027],
              [-85.71447936248, 38.25738597315],
              [-85.7144835953, 38.2573874905],
              [-85.71448785535, 38.2573890176],
              [-85.71448694373, 38.25739058625],
              [-85.71450869067, 38.25739837898],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 127F-127O',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714401518285, 38.257378754505],
            type: 'Point',
          },
        },
      },
      {
        id: '6d1d14c0-7542-4503-adf1-d47a9f823026',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71451178525, 38.25732272907],
              [-85.71452743273, 38.25729725524],
              [-85.71454926416, 38.25730556399],
              [-85.71455311955, 38.25730698563],
              [-85.71455730442, 38.25730852877],
              [-85.71455921356, 38.25730929225],
              [-85.71454356607, 38.25733476606],
              [-85.71451178525, 38.25732272907],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Office',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714535499405, 38.25731601065],
            type: 'Point',
          },
        },
      },
      {
        id: '6d44801b-5831-4a93-9ffc-8ed34ae55d07',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71487003819, 38.25690594704],
              [-85.71487499196, 38.25689695686],
              [-85.71487558706, 38.25689715905],
              [-85.71487965022, 38.25688978518],
              [-85.71487881974, 38.25688950301],
              [-85.71488463627, 38.25687894708],
              [-85.71487860878, 38.25687689917],
              [-85.71488265603, 38.25686955417],
              [-85.71486628764, 38.25686399281],
              [-85.71486644889, 38.25686370017],
              [-85.71485636185, 38.25686027297],
              [-85.71485612721, 38.25686069879],
              [-85.71485536759, 38.2568604407],
              [-85.71484276218, 38.25688338196],
              [-85.71484017634, 38.25688800277],
              [-85.71483837237, 38.2568912264],
              [-85.71483656028, 38.25689457251],
              [-85.71487003819, 38.25690594704],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 317A',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714861799235, 38.256883110005006],
            type: 'Point',
          },
        },
      },
      {
        id: '6dfb3e19-e82a-4b99-82e5-096dc24c399e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71468811039, 38.25696609377],
              [-85.71463472797, 38.25694813719],
              [-85.71466026436, 38.25690132691],
              [-85.71466538035, 38.25690304781],
              [-85.71466915215, 38.25690431656],
              [-85.71467347705, 38.25690577135],
              [-85.71471364678, 38.2569192835],
              [-85.71471247154, 38.25692143781],
              [-85.71471113299, 38.25692389149],
              [-85.7147095057, 38.25692687444],
              [-85.71468811039, 38.25696609377],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Assistant Work Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714674187375, 38.25693371033999],
            type: 'Point',
          },
        },
      },
      {
        id: '6e3cb470-702d-44c3-9490-9bdf96806cad',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71401129331, 38.25818545268],
              [-85.71401799716, 38.25817371057],
              [-85.71402505447, 38.25816113049],
              [-85.71397779819, 38.25814462858],
              [-85.71397742863, 38.25814528111],
              [-85.71397543587, 38.25814458523],
              [-85.713968559, 38.25815672774],
              [-85.71396228526, 38.2581678053],
              [-85.71396442484, 38.25816855244],
              [-85.71396417247, 38.25816899806],
              [-85.71401129331, 38.25818545268],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'ramp',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.713993669865, 38.258165018955],
            type: 'Point',
          },
        },
      },
      {
        id: '6feeee39-f05c-4146-949c-ab057920e5cd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71459811622, 38.25706394624],
              [-85.71460976322, 38.25704211038],
              [-85.71464379624, 38.25705330347],
              [-85.71465111645, 38.25705571101],
              [-85.71463933539, 38.25707779819],
              [-85.71463864382, 38.25707909475],
              [-85.71463589871, 38.25707819192],
              [-85.71463507025, 38.25707974513],
              [-85.71462301535, 38.25707578041],
              [-85.7146237747, 38.25707435678],
              [-85.71460974276, 38.25706974183],
              [-85.71460924631, 38.25707067256],
              [-85.71459827477, 38.25706706414],
              [-85.71459900024, 38.25706570401],
              [-85.71459745051, 38.25706519432],
              [-85.71459811622, 38.25706394624],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Director of Resource Services and NIMAC',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71462428347999, 38.257060927755],
            type: 'Point',
          },
        },
      },
      {
        id: '70062c59-611a-4bd2-86d3-6963b664933b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71452086757, 38.25682220122],
              [-85.71451704951, 38.25682079674],
              [-85.71451378166, 38.25681960755],
              [-85.71450915196, 38.25681792278],
              [-85.71441561809, 38.25678369009],
              [-85.71440593662, 38.25680000496],
              [-85.71440948627, 38.25680130379],
              [-85.71440252845, 38.25681302886],
              [-85.71439949313, 38.25681191822],
              [-85.71438958698, 38.25682861171],
              [-85.71439761847, 38.25683155045],
              [-85.71439437533, 38.25683701568],
              [-85.71449067715, 38.2568722528],
              [-85.71449285079, 38.25686858986],
              [-85.71449325264, 38.25686873689],
              [-85.71452086757, 38.25682220122],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'NIMAC',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71445522727501, 38.256827971445006],
            type: 'Point',
          },
        },
      },
      {
        id: '7006d00d-ee18-46df-8993-b467b4ee3d72',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71414482928, 38.25730659392],
              [-85.71415804268, 38.25728445959],
              [-85.7141875392, 38.25729532754],
              [-85.71419118101, 38.25729665774],
              [-85.7141952128, 38.25729813039],
              [-85.71418197456, 38.25732026663],
              [-85.71414482928, 38.25730659392],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Room Gr39D',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71417002104, 38.25730236311],
            type: 'Point',
          },
        },
      },
      {
        id: '72cc4217-e156-4ab8-a52c-66ae91ee9f9f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71397580706, 38.25831476176],
              [-85.71396692524, 38.25831176685],
              [-85.71395804726, 38.25830877324],
              [-85.71395225238, 38.25830681924],
              [-85.71396951999, 38.25827524436],
              [-85.71397498012, 38.25827708549],
              [-85.71398585094, 38.25828075108],
              [-85.71399278704, 38.25828308989],
              [-85.71400321239, 38.25828660527],
              [-85.71398594479, 38.25831818015],
              [-85.71397580706, 38.25831476176],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71397773238499, 38.258296712255],
            type: 'Point',
          },
        },
      },
      {
        id: '7314be54-e528-4e5d-954b-f34bf98611fc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71451852857, 38.25682102525],
              [-85.7144974086, 38.25681371072],
              [-85.71449335191, 38.2568122797],
              [-85.71448981365, 38.25681103155],
              [-85.71446762642, 38.25680334704],
              [-85.7144400756, 38.2568522619],
              [-85.71445474852, 38.25685735777],
              [-85.71445324087, 38.25686003452],
              [-85.71447096548, 38.25686619023],
              [-85.71447242288, 38.25686360271],
              [-85.71448970236, 38.25686960383],
              [-85.7144914353, 38.2568665271],
              [-85.71449266043, 38.25686695258],
              [-85.71451852857, 38.25682102525],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Field Services (North)',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.714479302085, 38.256836475434994],
            type: 'Point',
          },
        },
      },
      {
        id: '74972292-13f2-4d00-abb4-6a5abe135186',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71407632679, 38.25776961531],
              [-85.71407761951, 38.25776737924],
              [-85.7140794335, 38.2577642415],
              [-85.71408196528, 38.25775986216],
              [-85.71408376341, 38.25775675188],
              [-85.71408565773, 38.25775347518],
              [-85.71408682699, 38.25775145265],
              [-85.71412266192, 38.25776422651],
              [-85.7141346728, 38.25776850795],
              [-85.7141241726, 38.2577866706],
              [-85.71411213424, 38.25778237937],
              [-85.71407632679, 38.25776961531],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71410549979501, 38.257769061625],
            type: 'Point',
          },
        },
      },
      {
        id: '74fad0c1-3daf-4c4b-a6bc-a1631dcd84b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7145441342, 38.25685921065],
              [-85.71451796516, 38.25684992334],
              [-85.71450581882, 38.25687102667],
              [-85.71453198786, 38.25688031398],
              [-85.71453549829, 38.25687422522],
              [-85.71453757323, 38.25687063908],
              [-85.71453943605, 38.25686736299],
              [-85.7145441342, 38.25685921065],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.family',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Family Restroom',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71452497651, 38.25686511866],
            type: 'Point',
          },
        },
      },
      {
        id: '75f35c6c-3546-4142-be71-0b01b0de7008',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71474446394, 38.25687600472],
              [-85.71475642873, 38.25685493542],
              [-85.7147583359, 38.25685157699],
              [-85.71476017777, 38.25684833356],
              [-85.71477202662, 38.25682746842],
              [-85.7147676849, 38.25682594813],
              [-85.71476842589, 38.25682464456],
              [-85.71475851684, 38.25682114879],
              [-85.71475776674, 38.25682246823],
              [-85.71473594231, 38.25681482625],
              [-85.71473671973, 38.25681345908],
              [-85.71472686312, 38.25680998181],
              [-85.71472607765, 38.25681136301],
              [-85.71470828815, 38.25680513389],
              [-85.7147074372, 38.25680663236],
              [-85.71470419731, 38.25680549789],
              [-85.71467859152, 38.25685058825],
              [-85.71467998027, 38.25685107452],
              [-85.71467886672, 38.25685303541],
              [-85.71470574134, 38.256862434],
              [-85.71471009797, 38.25686397083],
              [-85.71471492925, 38.25686567509],
              [-85.71474446394, 38.25687600472],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Braille Improvement',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71472530906999, 38.256840569305],
            type: 'Point',
          },
        },
      },
      {
        id: '75feb0e4-824f-435c-b53d-4f777ec54260',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71433966854, 38.25721569602],
              [-85.71434066724, 38.25721395769],
              [-85.71434317604, 38.2572095909],
              [-85.71434553075, 38.25720549231],
              [-85.71434640463, 38.25720388862],
              [-85.71434907416, 38.25719932376],
              [-85.71435183447, 38.25719460369],
              [-85.71435292272, 38.25719262593],
              [-85.71434394045, 38.25718944398],
              [-85.7143193758, 38.25718067702],
              [-85.7143105379, 38.25717754284],
              [-85.71430715822, 38.25718344467],
              [-85.71429727187, 38.2572006087],
              [-85.71430610978, 38.25720374288],
              [-85.71433068627, 38.25721251407],
              [-85.71433966854, 38.25721569602],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Stair',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71432509729499, 38.25719661943],
            type: 'Point',
          },
        },
      },
      {
        id: '78d4ada4-a327-41b6-bafa-d0181903e36a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71429562936, 38.25758989656],
              [-85.71427521798, 38.25762472774],
              [-85.71431860002, 38.25764027463],
              [-85.71432901952, 38.25762232202],
              [-85.71433087885, 38.25761915035],
              [-85.71433258308, 38.25761624326],
              [-85.71433887396, 38.25760539379],
              [-85.71429562936, 38.25758989656],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Copier Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71430704597, 38.257615085595],
            type: 'Point',
          },
        },
      },
      {
        id: '7a6f018d-ebe5-4c0a-baeb-06701d5db1ea',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71408285873, 38.25821363858],
              [-85.71408423021, 38.25821116589],
              [-85.71408588548, 38.25820818152],
              [-85.71408679661, 38.25820653879],
              [-85.71409720098, 38.25818778026],
              [-85.71410277115, 38.25817773753],
              [-85.71413237271, 38.25818786086],
              [-85.71412677597, 38.25819795149],
              [-85.71411638707, 38.25821668212],
              [-85.71411406652, 38.25822090584],
              [-85.71411708345, 38.25822195901],
              [-85.71411534294, 38.2582250959],
              [-85.71411321309, 38.2582289345],
              [-85.71411023119, 38.25822788667],
              [-85.71410830427, 38.25823139394],
              [-85.71407863793, 38.25822124847],
              [-85.71408285873, 38.25821363858],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71410550531999, 38.258204565735],
            type: 'Point',
          },
        },
      },
      {
        id: '7b4f925d-6346-4dbe-814b-ecb8b0210c62',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71411359272, 38.25737199264],
              [-85.71410209975, 38.25739269599],
              [-85.71407345911, 38.25738289261],
              [-85.71408495208, 38.25736218926],
              [-85.71410148134, 38.25736784704],
              [-85.71410615002, 38.25736944508],
              [-85.71411076591, 38.25737102505],
              [-85.71411359272, 38.25737199264],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71409352591499, 38.257377442625],
            type: 'Point',
          },
        },
      },
      {
        id: '7bb3ea74-3966-47a9-8e7f-0c85d9daa3d4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7139669448, 38.2582740474],
              [-85.71394755229, 38.25830855013],
              [-85.71395633208, 38.2583115928],
              [-85.71395804726, 38.25830877324],
              [-85.71396692524, 38.25831176685],
              [-85.71397580706, 38.25831476176],
              [-85.71397418617, 38.2583176476],
              [-85.71401194264, 38.25833073226],
              [-85.7139432006, 38.25845308129],
              [-85.71393727864, 38.25846352763],
              [-85.71394026392, 38.25846457268],
              [-85.71393805796, 38.25846841862],
              [-85.71393579416, 38.25847236539],
              [-85.71393296443, 38.25847134902],
              [-85.71392921666, 38.25847801695],
              [-85.71391318012, 38.25847245943],
              [-85.71398863244, 38.25833821653],
              [-85.7139214497, 38.25831493407],
              [-85.71391884016, 38.25831906598],
              [-85.71391008018, 38.25831601215],
              [-85.71390109557, 38.25831288001],
              [-85.71390339484, 38.25830886952],
              [-85.71389105251, 38.25830459223],
              [-85.71389220849, 38.25830253554],
              [-85.71388772659, 38.25830098191],
              [-85.71389123346, 38.2582948904],
              [-85.71389529023, 38.25828784372],
              [-85.71389957296, 38.25828932732],
              [-85.7139179269, 38.2582566724],
              [-85.71392632251, 38.25825958194],
              [-85.71392878321, 38.2582552089],
              [-85.71394031455, 38.25825931134],
              [-85.71395237912, 38.25826360347],
              [-85.71394984102, 38.25826812],
              [-85.7139669448, 38.2582740474],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.713949834615, 38.258317316925],
            type: 'Point',
          },
        },
      },
      {
        id: '7d778c79-ac63-45ac-84ee-8fdd7c488a70',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71463771756, 38.25684069835],
              [-85.71465017168, 38.25681886057],
              [-85.71468217846, 38.25683011581],
              [-85.71468282164, 38.25682898868],
              [-85.71468700054, 38.25683047544],
              [-85.71469105685, 38.25683191859],
              [-85.71469045765, 38.25683296893],
              [-85.71469277439, 38.25683378362],
              [-85.7146802926, 38.25685566993],
              [-85.71467834851, 38.25685497132],
              [-85.7146746951, 38.25685370221],
              [-85.71467147849, 38.25685258483],
              [-85.71463771756, 38.25684069835],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 307',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714665245975, 38.25683726525],
            type: 'Point',
          },
        },
      },
      {
        id: '7e3aa381-42c2-4df4-9301-b94378c6c8c8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71455529511, 38.25712950128],
              [-85.71456923992, 38.25710613246],
              [-85.71457112138, 38.25710285777],
              [-85.71457289746, 38.25709976651],
              [-85.71457368138, 38.25709855039],
              [-85.71455227019, 38.25709070767],
              [-85.71453388391, 38.25712165857],
              [-85.71455529511, 38.25712950128],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714553782645, 38.257110104475004],
            type: 'Point',
          },
        },
      },
      {
        id: '7ec982ae-ded4-416a-907b-5d93fb01ab19',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7147985825, 38.25689691409],
              [-85.71479376029, 38.25689524494],
              [-85.71478993839, 38.25689391729],
              [-85.7147860025, 38.25689255006],
              [-85.71477791313, 38.25688974824],
              [-85.71477892192, 38.25688795404],
              [-85.71476078986, 38.25688166785],
              [-85.7147862572, 38.2568363728],
              [-85.71478665941, 38.25683651224],
              [-85.71478709067, 38.25683574523],
              [-85.71479668107, 38.25683907012],
              [-85.71479606498, 38.25684016587],
              [-85.71480813942, 38.25684435196],
              [-85.71480884423, 38.25684309841],
              [-85.71481887596, 38.25684657631],
              [-85.71481832479, 38.25684755659],
              [-85.71482502744, 38.25684988033],
              [-85.7147985825, 38.25689691409],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 316',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71479290865, 38.25686632966],
            type: 'Point',
          },
        },
      },
      {
        id: '804d8e52-7c9a-4c6d-922e-dfd737a5a113',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71474798685, 38.25695113124],
              [-85.71474009116, 38.25696552206],
              [-85.71471666893, 38.25695759815],
              [-85.71470840987, 38.25697265125],
              [-85.71469006269, 38.25696644426],
              [-85.71470993163, 38.2569302308],
              [-85.7147244806, 38.25693515283],
              [-85.71472849798, 38.25693651194],
              [-85.71473241409, 38.25693783679],
              [-85.71473552627, 38.25693888966],
              [-85.71473181209, 38.2569456592],
              [-85.71474798685, 38.25695113124],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714726776625, 38.256951441025],
            type: 'Point',
          },
        },
      },
      {
        id: '80fc0a17-8bd2-43cf-85ac-d0995c6f82a5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71430619338, 38.25718313491],
              [-85.71425902346, 38.2571664685],
              [-85.7142377668, 38.25720356407],
              [-85.71425015559, 38.25720794662],
              [-85.71425453003, 38.25720948689],
              [-85.71425868497, 38.25721094986],
              [-85.71427696285, 38.25721741309],
              [-85.71427770923, 38.25721611056],
              [-85.71429202664, 38.25722116929],
              [-85.71429926013, 38.25720854593],
              [-85.7142929166, 38.25720630459],
              [-85.71430619338, 38.25718313491],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71427198008999, 38.257193818895004],
            type: 'Point',
          },
        },
      },
      {
        id: '81d4553d-a1ec-4b05-811c-15603b41022c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480863568, 38.25701581471],
              [-85.71481167288, 38.25701042357],
              [-85.71481473211, 38.25701160793],
              [-85.71481777986, 38.25700624383],
              [-85.71482077905, 38.25700096518],
              [-85.71481763779, 38.25699983568],
              [-85.71482147042, 38.25699303263],
              [-85.71478528314, 38.25698040503],
              [-85.71478118323, 38.25697897436],
              [-85.71477707635, 38.25697754126],
              [-85.71477490258, 38.25697678272],
              [-85.71477324981, 38.25697967175],
              [-85.71477173059, 38.25698238639],
              [-85.7147702568, 38.2569849908],
              [-85.71476201924, 38.25699954785],
              [-85.71476784545, 38.25700158091],
              [-85.71479695578, 38.257011739],
              [-85.71480101288, 38.25701315473],
              [-85.71480495042, 38.25701452873],
              [-85.71480863568, 38.25701581471],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71479174483, 38.256996298715],
            type: 'Point',
          },
        },
      },
      {
        id: '829b64d2-6923-49de-afec-790144e9c33a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71379844725, 38.25775653128],
              [-85.71384119014, 38.25768337574],
              [-85.71402803433, 38.25775068795],
              [-85.71402014472, 38.25776448242],
              [-85.71399422548, 38.25780855311],
              [-85.71396817182, 38.25785285231],
              [-85.71396132494, 38.25786486265],
              [-85.71391477191, 38.25784809156],
              [-85.71393873842, 38.25780707233],
              [-85.71379844725, 38.25775653128],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71389754807956, 38.25775466540677],
            type: 'Point',
          },
        },
      },
      {
        id: '829f7d6b-c25b-4866-8507-0d5f14bc69c6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71391884016, 38.25831906598],
              [-85.71391008018, 38.25831601215],
              [-85.71390109557, 38.25831288001],
              [-85.71389161814, 38.25830957607],
              [-85.71387035649, 38.25834718106],
              [-85.7138800326, 38.25835050065],
              [-85.71388890142, 38.25835364877],
              [-85.71389656422, 38.25835636879],
              [-85.71390630376, 38.25835971269],
              [-85.71392756541, 38.2583221077],
              [-85.71391884016, 38.25831906598],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Freight Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71389896095, 38.25833464438],
            type: 'Point',
          },
        },
      },
      {
        id: '83961642-0ffb-48b2-8426-772032b4a0e9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457777332, 38.25685738432],
              [-85.71458200234, 38.25685882798],
              [-85.71458862645, 38.25686108926],
              [-85.71459540594, 38.25684884371],
              [-85.71458854975, 38.2568465178],
              [-85.71458442866, 38.25684509571],
              [-85.71458091825, 38.25684388434],
              [-85.71457365686, 38.2568414192],
              [-85.71456687737, 38.25685366475],
              [-85.7145733223, 38.25685586487],
              [-85.71457777332, 38.25685738432],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714581141655, 38.25685125423],
            type: 'Point',
          },
        },
      },
      {
        id: '84204b50-8437-4059-8bde-fa59342f7342',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71449424418, 38.25735169879],
              [-85.71447864569, 38.25737739186],
              [-85.71451053029, 38.2573893276],
              [-85.71451112174, 38.25738840354],
              [-85.71451301999, 38.25738522934],
              [-85.71451514656, 38.25738167337],
              [-85.71452612878, 38.25736363455],
              [-85.71449424418, 38.25735169879],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Office',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714502387235, 38.257370513195],
            type: 'Point',
          },
        },
      },
      {
        id: '85f86feb-0d01-4855-b1d2-88275502e593',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461109673, 38.25685905853],
              [-85.71460947834, 38.25686195039],
              [-85.71460779129, 38.25686496492],
              [-85.71460323179, 38.25687331996],
              [-85.71460478478, 38.25687384603],
              [-85.71460412665, 38.256875044],
              [-85.71463496873, 38.25688549163],
              [-85.71463852805, 38.25688669734],
              [-85.71464243218, 38.25688802175],
              [-85.71465180118, 38.25689118409],
              [-85.71466148827, 38.2568734877],
              [-85.71464740608, 38.25686873449],
              [-85.71461229054, 38.25685683069],
              [-85.71461109673, 38.25685905853],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71463236003, 38.256874007389996],
            type: 'Point',
          },
        },
      },
      {
        id: '86762cac-e648-470a-b0e7-8e763288fc0c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71400618047, 38.25829050834],
              [-85.71406107456, 38.25830969576],
              [-85.71397373916, 38.25846375557],
              [-85.7139432006, 38.25845308129],
              [-85.71393727864, 38.25846352763],
              [-85.71394026392, 38.25846457268],
              [-85.71393805796, 38.25846841862],
              [-85.71393579416, 38.25847236539],
              [-85.71393365741, 38.25847161735],
              [-85.71391261255, 38.25850874048],
              [-85.71394152392, 38.258518846],
              [-85.71390976183, 38.25857487426],
              [-85.7136449279, 38.25848230572],
              [-85.71372553506, 38.25834011458],
              [-85.71365101833, 38.25831406836],
              [-85.7136445379, 38.25832549985],
              [-85.71362154523, 38.2583174631],
              [-85.71362074803, 38.25831886935],
              [-85.71361112896, 38.25831550714],
              [-85.71360242367, 38.25831246434],
              [-85.71360329673, 38.25831092425],
              [-85.71358065096, 38.25830300875],
              [-85.71360502355, 38.2582600154],
              [-85.71355422144, 38.25824225826],
              [-85.7135734878, 38.2582082723],
              [-85.7135701148, 38.25820709329],
              [-85.71357207259, 38.25820362701],
              [-85.71357397735, 38.2582002546],
              [-85.71357674138, 38.25820122072],
              [-85.71358122246, 38.25819331607],
              [-85.71357250385, 38.2581902686],
              [-85.71359800385, 38.25814528641],
              [-85.71393060253, 38.25826128473],
              [-85.71394172443, 38.25826517223],
              [-85.71395516678, 38.25826987081],
              [-85.71396429419, 38.25827306116],
              [-85.71394429795, 38.2583083346],
              [-85.71395594873, 38.25831240696],
              [-85.71395804726, 38.25830877324],
              [-85.71396692524, 38.25831176685],
              [-85.71397580706, 38.25831476176],
              [-85.71397360478, 38.25831867729],
              [-85.71398746528, 38.25832352202],
              [-85.71400618047, 38.25829050834],
            ],
            [
              [-85.71387911387, 38.25835190708],
              [-85.7138800326, 38.25835050065],
              [-85.71388890142, 38.25835364877],
              [-85.71389656422, 38.25835636879],
              [-85.71389580686, 38.25835768433],
              [-85.71390658189, 38.25836152976],
              [-85.71392955643, 38.25832161323],
              [-85.71391944073, 38.25831802335],
              [-85.71391884016, 38.25831906598],
              [-85.71391008018, 38.25831601215],
              [-85.71390109557, 38.25831288001],
              [-85.71390169063, 38.25831184531],
              [-85.71389078607, 38.25830797548],
              [-85.71386780959, 38.25834789539],
              [-85.71387911387, 38.25835190708],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Rear Warehouse North',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.713807648, 38.258360080334995],
            type: 'Point',
          },
        },
      },
      {
        id: '869b56d1-5b96-4845-9e5e-ef37e83c436b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480863568, 38.25701581471],
              [-85.71481167288, 38.25701042357],
              [-85.71481473211, 38.25701160793],
              [-85.71481777986, 38.25700624383],
              [-85.71482077905, 38.25700096518],
              [-85.71481763779, 38.25699983568],
              [-85.71482147042, 38.25699303263],
              [-85.71478528314, 38.25698040503],
              [-85.71478118323, 38.25697897436],
              [-85.71477707635, 38.25697754126],
              [-85.71477490258, 38.25697678272],
              [-85.71477324981, 38.25697967175],
              [-85.71477173059, 38.25698238639],
              [-85.7147702568, 38.2569849908],
              [-85.71476201924, 38.25699954785],
              [-85.71476784545, 38.25700158091],
              [-85.71479695578, 38.257011739],
              [-85.71480101288, 38.25701315473],
              [-85.71480495042, 38.25701452873],
              [-85.71480863568, 38.25701581471],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71479174483, 38.256996298715],
            type: 'Point',
          },
        },
      },
      {
        id: '879ee864-ae73-42ec-9da1-fe3314445ef0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7135701148, 38.25820709329],
              [-85.71356848669, 38.25820993147],
              [-85.7135549448, 38.25823367159],
              [-85.71355043471, 38.25824160733],
              [-85.71352611333, 38.25823313169],
              [-85.7135306879, 38.25822518046],
              [-85.71354424436, 38.25820144905],
              [-85.71358141352, 38.25813578306],
              [-85.71358359195, 38.2581365417],
              [-85.71358418316, 38.25813549496],
              [-85.71359025065, 38.25813760798],
              [-85.71359574666, 38.25813952198],
              [-85.71359516399, 38.25814055361],
              [-85.71359678096, 38.25814111672],
              [-85.71356913952, 38.25819005617],
              [-85.71357799557, 38.25819314031],
              [-85.71357397735, 38.2582002546],
              [-85.71357207259, 38.25820362701],
              [-85.7135701148, 38.25820709329],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'East Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.713561447145, 38.258188551145],
            type: 'Point',
          },
        },
      },
      {
        id: '87db6de4-c145-46ef-a2b7-aa5c6a1630b9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461109673, 38.25685905853],
              [-85.71460947834, 38.25686195039],
              [-85.71460779129, 38.25686496492],
              [-85.71460323179, 38.25687331996],
              [-85.71460478478, 38.25687384603],
              [-85.71460412665, 38.256875044],
              [-85.71463496873, 38.25688549163],
              [-85.71463852805, 38.25688669734],
              [-85.71464243218, 38.25688802175],
              [-85.71465180118, 38.25689118409],
              [-85.71466148827, 38.2568734877],
              [-85.71464740608, 38.25686873449],
              [-85.71461229054, 38.25685683069],
              [-85.71461109673, 38.25685905853],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71463236003, 38.256874007389996],
            type: 'Point',
          },
        },
      },
      {
        id: '88c11693-4001-4d05-90cb-169a66472a65',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71414560534, 38.25765143433],
              [-85.71415653673, 38.25765532426],
              [-85.71415535235, 38.25765737647],
              [-85.71416983673, 38.25766253074],
              [-85.71417101608, 38.25766048725],
              [-85.71419219446, 38.25766802357],
              [-85.71420278252, 38.2576496773],
              [-85.71420552716, 38.25765065403],
              [-85.71420764237, 38.25764699857],
              [-85.71420977375, 38.25764331517],
              [-85.71420703004, 38.25764233877],
              [-85.71421323814, 38.2576315818],
              [-85.71416663379, 38.25761499764],
              [-85.71416045396, 38.25762569414],
              [-85.71415845489, 38.25762916947],
              [-85.71415644856, 38.25763265741],
              [-85.71414560534, 38.25765143433],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: '142',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71417942174, 38.257641510605],
            type: 'Point',
          },
        },
      },
      {
        id: '88d39f01-37f9-454a-bc48-8562b533affd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71423671936, 38.25731312714],
              [-85.71424189748, 38.25731494796],
              [-85.71424583091, 38.25731638469],
              [-85.71424955067, 38.25731774337],
              [-85.71424764303, 38.2573211012],
              [-85.71427350962, 38.25733042947],
              [-85.71426239254, 38.25734943723],
              [-85.71422365356, 38.25733546679],
              [-85.71423671936, 38.25731312714],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Room Gr39F',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71424858159, 38.257331282185],
            type: 'Point',
          },
        },
      },
      {
        id: '88f60a06-70d4-471a-b4e8-ed427eec135d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71446354957, 38.25760487147],
              [-85.7144036323, 38.25758336602],
              [-85.71438859744, 38.25760919469],
              [-85.71437229784, 38.25760334445],
              [-85.71437134529, 38.25760500842],
              [-85.71436902909, 38.25760895942],
              [-85.71436682948, 38.25761271154],
              [-85.71436589209, 38.257614349],
              [-85.71439172008, 38.25762363269],
              [-85.7144011842, 38.25762700247],
              [-85.7144420572, 38.25764168609],
              [-85.71444506791, 38.25763651394],
              [-85.71444816503, 38.25763762555],
              [-85.71446446919, 38.25760961633],
              [-85.71446142384, 38.25760852329],
              [-85.71446354957, 38.25760487147],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Wonder Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71441518064, 38.257612526055],
            type: 'Point',
          },
        },
      },
      {
        id: '89425ca3-97dd-4b0d-9cf0-39b35617f197',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71454900146, 38.25688795624],
              [-85.71455869893, 38.25689127447],
              [-85.71456398549, 38.25689307918],
              [-85.71456894812, 38.25689478149],
              [-85.7145834345, 38.25689973836],
              [-85.71458163782, 38.25690278381],
              [-85.71458310171, 38.25690327163],
              [-85.71458141019, 38.25690624372],
              [-85.71458004524, 38.256908642],
              [-85.71457805499, 38.25690798635],
              [-85.7145650495, 38.25693145274],
              [-85.71457448799, 38.25693467819],
              [-85.71457019851, 38.25694306971],
              [-85.71457320298, 38.25694413405],
              [-85.71457158474, 38.25694710319],
              [-85.71457007426, 38.25694987461],
              [-85.71456556034, 38.25694830012],
              [-85.71456441652, 38.25695032238],
              [-85.71454858635, 38.25697888542],
              [-85.71455294811, 38.25698057118],
              [-85.71455040586, 38.25698504124],
              [-85.7145422549, 38.25699937315],
              [-85.71453675951, 38.25699750557],
              [-85.71451567348, 38.25703525748],
              [-85.71443916909, 38.25700821523],
              [-85.71445673814, 38.2569765076],
              [-85.71445240233, 38.25697506433],
              [-85.71445473607, 38.25697083024],
              [-85.7144571609, 38.25696643092],
              [-85.71446064421, 38.25696758259],
              [-85.71448543126, 38.25692285825],
              [-85.71448187027, 38.25692168023],
              [-85.71448417665, 38.25691754386],
              [-85.71448619133, 38.25691393066],
              [-85.71448942491, 38.25691499554],
              [-85.71449177494, 38.25691075527],
              [-85.71450412542, 38.25691497585],
              [-85.71450652116, 38.25691065311],
              [-85.71453184033, 38.25691930553],
              [-85.71453573691, 38.25691227477],
              [-85.71453212868, 38.25691112932],
              [-85.71453385077, 38.25690802804],
              [-85.71453577156, 38.2569045689],
              [-85.71453935231, 38.25690575135],
              [-85.71454900146, 38.25688795624],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'room',
          restriction: null,
          accessibility: null,
          name: {
            en: '1883 Gallery',
            frCA: null,
          },
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714511301795, 38.25696160686],
            type: 'Point',
          },
        },
      },
      {
        id: '89598fb4-97f8-48ff-af43-80d9ee7e1ae5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71465085885, 38.25696370124],
              [-85.71467262733, 38.25692620299],
              [-85.71467469097, 38.25692264818],
              [-85.71467694145, 38.2569187715],
              [-85.71468182851, 38.25691035309],
              [-85.71464945576, 38.25689876521],
              [-85.7146184861, 38.25695211337],
              [-85.71465085885, 38.25696370124],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71465015730499, 38.256931233225],
            type: 'Point',
          },
        },
      },
      {
        id: '896211df-b69f-4686-bd72-e983757230b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71357778286, 38.25830383778],
              [-85.71360242367, 38.25831246434],
              [-85.71361112896, 38.25831550714],
              [-85.71362074803, 38.25831886935],
              [-85.71364642828, 38.25832785979],
              [-85.71365287386, 38.25831650303],
              [-85.71372047988, 38.2583401613],
              [-85.71364078505, 38.25848057916],
              [-85.71342776209, 38.25840603332],
              [-85.71352545578, 38.25823390223],
              [-85.71353165718, 38.25823604862],
              [-85.71354332435, 38.25824017891],
              [-85.71360222731, 38.25826076794],
              [-85.71357778286, 38.25830383778],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'storage',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.713574120985, 38.258357240695005],
            type: 'Point',
          },
        },
      },
      {
        id: '8cb12de8-9fbc-4d37-9f90-453b2c6da8fd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71450033542, 38.25690490449],
              [-85.71448042922, 38.25689801135],
              [-85.71448351671, 38.25689251361],
              [-85.71448082781, 38.2568915825],
              [-85.71448630814, 38.25688182395],
              [-85.71448937446, 38.25688288576],
              [-85.71449116627, 38.25687969516],
              [-85.71449245318, 38.2568801408],
              [-85.71449840885, 38.25686953583],
              [-85.71451665071, 38.25687585264],
              [-85.71450976294, 38.25688811737],
              [-85.71450933617, 38.25688887729],
              [-85.71450734964, 38.25689253719],
              [-85.71450395105, 38.25689833998],
              [-85.71450033542, 38.25690490449],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71449896637999, 38.256887220159996],
            type: 'Point',
          },
        },
      },
      {
        id: '8d1fac6f-8793-4eec-b578-53d92fc48de1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71437505798, 38.25698704326],
              [-85.71441575245, 38.25691416936],
              [-85.71447418366, 38.25693428877],
              [-85.71447160933, 38.25693889878],
              [-85.71446972424, 38.2569422745],
              [-85.71446792746, 38.25694549211],
              [-85.7144334892, 38.25700716265],
              [-85.71437505798, 38.25698704326],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71442462082001, 38.256960666005],
            type: 'Point',
          },
        },
      },
      {
        id: '8d6efb88-345f-488a-9ad8-04eac52df115',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71434128091, 38.25764498881],
              [-85.71437829824, 38.25765799276],
              [-85.71438359132, 38.25765988009],
              [-85.71438887094, 38.25766176261],
              [-85.71439530334, 38.25766400215],
              [-85.71440703883, 38.2576434425],
              [-85.71435301641, 38.25762442915],
              [-85.71434128091, 38.25764498881],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71437415987, 38.257644215650004],
            type: 'Point',
          },
        },
      },
      {
        id: '8d82d311-3070-4219-a9df-d1fc2f0269f8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71404142347, 38.2574258504],
              [-85.71399547882, 38.25740943754],
              [-85.71398013623, 38.25743604865],
              [-85.7139748437, 38.25744522834],
              [-85.71402075018, 38.25746157959],
              [-85.71402398697, 38.25745598552],
              [-85.7140259689, 38.2574525602],
              [-85.71402795248, 38.25744913201],
              [-85.71404142347, 38.2574258504],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714008133585, 38.257435508564996],
            type: 'Point',
          },
        },
      },
      {
        id: '8eb9f88e-cfda-41be-b511-85a5b69fbcfe',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71458048169, 38.25690170422],
              [-85.71463140621, 38.25691966478],
              [-85.71461867563, 38.25694192157],
              [-85.71456775112, 38.25692396103],
              [-85.71457305833, 38.25691491251],
              [-85.714574521, 38.25691213475],
              [-85.71457617111, 38.25690900103],
              [-85.71458048169, 38.25690170422],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714599578665, 38.256921812895],
            type: 'Point',
          },
        },
      },
      {
        id: '8eecce7c-51d0-40a5-a505-8eed7d236912',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71445354235, 38.25693488737],
              [-85.71445644229, 38.2569358844],
              [-85.71446310011, 38.25693817343],
              [-85.71446974578, 38.25694045829],
              [-85.71447113378, 38.25694093549],
              [-85.71448187027, 38.25692168023],
              [-85.71448417665, 38.25691754386],
              [-85.71448619133, 38.25691393066],
              [-85.71448774894, 38.25691113718],
              [-85.71447008143, 38.2569050629],
              [-85.7144302244, 38.2568913596],
              [-85.71441854156, 38.25691216647],
              [-85.71445854062, 38.25692592327],
              [-85.71445354235, 38.25693488737],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'test 2',
            frCA: null,
          },
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71446296099501, 38.256916147545],
            type: 'Point',
          },
        },
      },
      {
        id: '8fd21940-f458-47c1-ab55-f0efef67438a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71464442455, 38.25687427179],
              [-85.71464740608, 38.25686873449],
              [-85.71464821908, 38.25686720627],
              [-85.71462192199, 38.25685849806],
              [-85.71462136485, 38.25685960932],
              [-85.71461975077, 38.25686254159],
              [-85.71461809555, 38.25686554859],
              [-85.71464442455, 38.25687427179],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714633157315, 38.256866384925],
            type: 'Point',
          },
        },
      },
      {
        id: '90f3e35b-f742-4460-ac5a-076350528210',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71446729185, 38.25678302685],
              [-85.71443276681, 38.2567709247],
              [-85.71442107787, 38.25679148621],
              [-85.71442276896, 38.2567921284],
              [-85.71442729618, 38.256793665],
              [-85.71443155183, 38.25679510943],
              [-85.71445560292, 38.25680358837],
              [-85.71446729185, 38.25678302685],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71444418486, 38.256787256535],
            type: 'Point',
          },
        },
      },
      {
        id: '910f1954-c17a-47ef-932e-953f611321e1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71441663454, 38.25726428033],
              [-85.71442171271, 38.25725548175],
              [-85.71442368789, 38.25725205951],
              [-85.71442566333, 38.25724863681],
              [-85.71443686033, 38.25722923659],
              [-85.71437572292, 38.25720747927],
              [-85.71435549713, 38.25724252302],
              [-85.71441663454, 38.25726428033],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71439617873, 38.2572358798],
            type: 'Point',
          },
        },
      },
      {
        id: '916baba0-493d-445b-bfc3-c2e7f0ff9622',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71455652752, 38.2567899829],
              [-85.71457290889, 38.25676112361],
              [-85.71455508972, 38.25675488682],
              [-85.7145558715, 38.25675350953],
              [-85.71453856366, 38.25674745171],
              [-85.71453769786, 38.25674897701],
              [-85.71452698314, 38.25674522681],
              [-85.71452096793, 38.25675582389],
              [-85.71452339564, 38.2567566736],
              [-85.71452286169, 38.25675761427],
              [-85.71451626292, 38.25675530467],
              [-85.71450770767, 38.25677037658],
              [-85.71450952692, 38.25677101333],
              [-85.71450833398, 38.25677311495],
              [-85.71452218996, 38.2567779922],
              [-85.71452608657, 38.25677932829],
              [-85.71452992308, 38.25678064378],
              [-85.71455652752, 38.2567899829],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: "President's Assistant's Office",
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71454030828, 38.256767604855],
            type: 'Point',
          },
        },
      },
      {
        id: '938a9a9c-366e-4bd3-b926-8142bf03cad0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71384252626, 38.25768099642],
              [-85.71405305725, 38.25775760079],
              [-85.71407647285, 38.2577181876],
              [-85.71408066241, 38.25771106406],
              [-85.71408484645, 38.2577039499],
              [-85.71408769121, 38.25769921495],
              [-85.71407926682, 38.25769613367],
              [-85.71409096524, 38.25767641248],
              [-85.71402695204, 38.25765299921],
              [-85.71401477477, 38.25767352765],
              [-85.71387621542, 38.2576228486],
              [-85.71384252626, 38.25768099642],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71396674575, 38.257690224695],
            type: 'Point',
          },
        },
      },
      {
        id: '93f65377-853f-4e9f-9a06-c58b84ec749e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71435481457, 38.25748756072],
              [-85.71431646868, 38.2575538159],
              [-85.71430672735, 38.25757059028],
              [-85.71429688424, 38.25758753992],
              [-85.71428320415, 38.25761116859],
              [-85.71428198839, 38.25761073431],
              [-85.71428005569, 38.25761407037],
              [-85.71427809327, 38.25761745772],
              [-85.7142793041, 38.25761789024],
              [-85.71423990998, 38.25768588895],
              [-85.7142472306, 38.25768851561],
              [-85.71425543814, 38.25769143558],
              [-85.71426311373, 38.25769416629],
              [-85.71439030757, 38.25773961311],
              [-85.71450520722, 38.25754128327],
              [-85.71435481457, 38.25748756072],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Print room',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71437255859999, 38.257613586915],
            type: 'Point',
          },
        },
      },
      {
        id: '94111f33-1d90-4516-b0fa-54c2d5cc3143',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71445240233, 38.25697506433],
              [-85.71445473607, 38.25697083024],
              [-85.7144571609, 38.25696643092],
              [-85.71445962739, 38.256961956],
              [-85.71445392101, 38.25696003598],
              [-85.71445613152, 38.25695598261],
              [-85.71446185893, 38.25695790735],
              [-85.7144702149, 38.25694274724],
              [-85.71446876406, 38.25694226029],
              [-85.71446974578, 38.25694045829],
              [-85.71446310011, 38.25693817343],
              [-85.71445644229, 38.2569358844],
              [-85.71445535754, 38.25693787176],
              [-85.71445314574, 38.25693712801],
              [-85.7144347526, 38.25697085525],
              [-85.71442647826, 38.25698602778],
              [-85.71442478017, 38.25698545677],
              [-85.71442281425, 38.25698906166],
              [-85.71442100441, 38.25699238033],
              [-85.71442309582, 38.2569930836],
              [-85.71442163075, 38.25699577008],
              [-85.714434213, 38.25700000105],
              [-85.71443470306, 38.25699910245],
              [-85.71443845694, 38.25700036523],
              [-85.71445240233, 38.25697506433],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714445609655, 38.256968124815],
            type: 'Point',
          },
        },
      },
      {
        id: '941a3fb4-6bd8-4e05-b1df-0ead7928836d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71483660717, 38.25696477611],
              [-85.71479914525, 38.25695169663],
              [-85.71479039243, 38.25696715466],
              [-85.71477836763, 38.25696295632],
              [-85.71477691525, 38.25696554605],
              [-85.71477553525, 38.25696795823],
              [-85.71477419805, 38.25697029561],
              [-85.7147726922, 38.25697297949],
              [-85.71482221762, 38.25699027083],
              [-85.71482567971, 38.25698415656],
              [-85.71482719957, 38.25698468795],
              [-85.71483197417, 38.25697634281],
              [-85.7148303745, 38.2569757834],
              [-85.71483660717, 38.25696477611],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.714804649685, 38.25697098373],
            type: 'Point',
          },
        },
      },
      {
        id: '952d98a3-ef9b-4d64-8279-5eef1ad3404d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71418473578, 38.25732119738],
              [-85.71422108007, 38.25733446262],
              [-85.71423405254, 38.25731254738],
              [-85.71420572608, 38.25730220956],
              [-85.7142026029, 38.25730106879],
              [-85.71419771276, 38.25729928262],
              [-85.71418473578, 38.25732119738],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Room Gr39E',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71420939416, 38.257316872619995],
            type: 'Point',
          },
        },
      },
      {
        id: '95dd916c-e902-4938-a944-d9e4dda224db',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71419971221, 38.2572136137],
              [-85.71422913321, 38.25722458062],
              [-85.71423320448, 38.25722606769],
              [-85.71423722052, 38.25722753459],
              [-85.71425220886, 38.25720276188],
              [-85.71421467367, 38.25718880451],
              [-85.71419971221, 38.2572136137],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: "Jose's Office",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714225960535, 38.25720816955],
            type: 'Point',
          },
        },
      },
      {
        id: '96ef62e3-2b1b-49f3-a784-65efae23e182',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71442478017, 38.25698545677],
              [-85.71442281425, 38.25698906166],
              [-85.71442100441, 38.25699238033],
              [-85.71441732258, 38.25699113649],
              [-85.71441587903, 38.25699367013],
              [-85.71439671682, 38.25698693824],
              [-85.71439614187, 38.25698794737],
              [-85.71438358109, 38.25698353463],
              [-85.71438409811, 38.25698262719],
              [-85.7143781282, 38.25698052989],
              [-85.71437927125, 38.25697852367],
              [-85.71437823726, 38.25697815569],
              [-85.71438997034, 38.25695606482],
              [-85.71443289324, 38.25697030028],
              [-85.71442478017, 38.25698545677],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71440551072, 38.256974867475],
            type: 'Point',
          },
        },
      },
      {
        id: '97eb85fd-6673-4edf-bb2b-429b7b7fd638',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480863568, 38.25701581471],
              [-85.71481167288, 38.25701042357],
              [-85.71481473211, 38.25701160793],
              [-85.71481777986, 38.25700624383],
              [-85.71482077905, 38.25700096518],
              [-85.71481763779, 38.25699983568],
              [-85.71482147042, 38.25699303263],
              [-85.71478528314, 38.25698040503],
              [-85.71478118323, 38.25697897436],
              [-85.71477707635, 38.25697754126],
              [-85.71477490258, 38.25697678272],
              [-85.71477324981, 38.25697967175],
              [-85.71477173059, 38.25698238639],
              [-85.7147702568, 38.2569849908],
              [-85.71476201924, 38.25699954785],
              [-85.71476784545, 38.25700158091],
              [-85.71479695578, 38.257011739],
              [-85.71480101288, 38.25701315473],
              [-85.71480495042, 38.25701452873],
              [-85.71480863568, 38.25701581471],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71479174483, 38.256996298715],
            type: 'Point',
          },
        },
      },
      {
        id: '9826d113-8a8d-4c32-bb11-881dc8a43033',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71452263868, 38.25750750529],
              [-85.71447997078, 38.25749218538],
              [-85.71443993006, 38.25747780874],
              [-85.71442902017, 38.25749657542],
              [-85.7144261291, 38.25750150706],
              [-85.71442355598, 38.25750589631],
              [-85.7144005466, 38.25754547149],
              [-85.71439771375, 38.25755030381],
              [-85.71439532519, 38.25755437824],
              [-85.71438389775, 38.25757403281],
              [-85.71442013895, 38.2575870452],
              [-85.714467382, 38.2576040078],
              [-85.7144911617, 38.25756317104],
              [-85.71449268896, 38.2575637194],
              [-85.71451267356, 38.25752939995],
              [-85.71451037066, 38.2575285731],
              [-85.71452263868, 38.25750750529],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 2R32 through 2R43',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714453268215, 38.25754090827],
            type: 'Point',
          },
        },
      },
      {
        id: '98462083-e38b-4aa0-8e50-28f8c98f7b2b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71425128201, 38.25731997099],
              [-85.71427502022, 38.2573283495],
              [-85.71428850655, 38.25730478939],
              [-85.71426481881, 38.25729643034],
              [-85.71426302038, 38.25729946629],
              [-85.71426112921, 38.25730265878],
              [-85.71425128201, 38.25731997099],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Room Gr39G',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71426989428, 38.25731238992],
            type: 'Point',
          },
        },
      },
      {
        id: '989205e8-ae30-4963-acfe-7d6246f8ba68',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71436625739, 38.25676969347],
              [-85.71436941849, 38.25676406328],
              [-85.71437232423, 38.2567590085],
              [-85.7144059756, 38.25677079002],
              [-85.71441479362, 38.25675525966],
              [-85.71441647254, 38.25675584746],
              [-85.71441691604, 38.25675506644],
              [-85.71442188529, 38.25675678337],
              [-85.71442630532, 38.25675831054],
              [-85.71442549846, 38.25675973192],
              [-85.71443028985, 38.25676140941],
              [-85.71441254271, 38.25679252302],
              [-85.71440986001, 38.25679767035],
              [-85.71440728968, 38.25680206613],
              [-85.71440081804, 38.25679973445],
              [-85.71440019503, 38.2568008317],
              [-85.71439101828, 38.25679761888],
              [-85.71440013891, 38.25678155555],
              [-85.71436625739, 38.25676969347],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714406790005, 38.256778566285],
            type: 'Point',
          },
        },
      },
      {
        id: '98c3bf35-61a6-4de6-98eb-176e47df9a39',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7146171129, 38.25694738429],
              [-85.7146123757, 38.25694572833],
              [-85.71460761935, 38.25694406567],
              [-85.71457541035, 38.25693280653],
              [-85.71457456388, 38.25693429964],
              [-85.71456391961, 38.25693057877],
              [-85.71456486877, 38.25692890453],
              [-85.71455641794, 38.25692595041],
              [-85.71455511651, 38.25692824603],
              [-85.71454492697, 38.25692468412],
              [-85.71454613753, 38.2569225488],
              [-85.7144846321, 38.25690104863],
              [-85.71449510383, 38.25688257733],
              [-85.71449589325, 38.25688285328],
              [-85.71451497729, 38.25684919053],
              [-85.71458476274, 38.25687358511],
              [-85.7145775443, 38.25688631787],
              [-85.71457956778, 38.25688702521],
              [-85.71458698161, 38.2568739478],
              [-85.71458819957, 38.25687437356],
              [-85.71459097328, 38.25686948094],
              [-85.71459238418, 38.25686999332],
              [-85.71459674359, 38.25687149765],
              [-85.71460083232, 38.25687290858],
              [-85.71460401339, 38.25687403932],
              [-85.71460131536, 38.25687879844],
              [-85.71460405053, 38.25687975456],
              [-85.71460520305, 38.25688015745],
              [-85.71460415241, 38.2568820107],
              [-85.71459478448, 38.25689851525],
              [-85.71459258514, 38.25690241445],
              [-85.71458955832, 38.25690775352],
              [-85.71459916777, 38.25691111265],
              [-85.71459780936, 38.25691350877],
              [-85.71462994842, 38.25692474347],
              [-85.7146171129, 38.25694738429],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Break Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71455729025999, 38.256898287409996],
            type: 'Point',
          },
        },
      },
      {
        id: '9acef95c-797d-4b4a-ae66-4c72ee31d0b4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71445414814, 38.25682277698],
              [-85.71444112587, 38.25681814055],
              [-85.71443670322, 38.25681658422],
              [-85.71443269266, 38.25681517291],
              [-85.71441187061, 38.25680791601],
              [-85.71440124569, 38.25680939077],
              [-85.71439933919, 38.25681274509],
              [-85.71439720501, 38.2568120969],
              [-85.71438908482, 38.2568266527],
              [-85.71439106508, 38.25682730264],
              [-85.71438738718, 38.25683377358],
              [-85.71440137303, 38.25683867509],
              [-85.71440029623, 38.25684081748],
              [-85.71441882197, 38.25684727254],
              [-85.71442002724, 38.25684521269],
              [-85.71443791184, 38.25685148057],
              [-85.71445414814, 38.25682277698],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Field Services (East)',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71442076766, 38.25682969829],
            type: 'Point',
          },
        },
      },
      {
        id: '9b170552-de82-412e-a40f-aa572ea2866b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71443895366, 38.25674470898],
              [-85.71441689156, 38.2567820914],
              [-85.71451024866, 38.25681606452],
              [-85.71450915196, 38.25681792278],
              [-85.71451378166, 38.25681960755],
              [-85.71451704951, 38.25682079674],
              [-85.71451823424, 38.25681878933],
              [-85.71452213832, 38.25682021004],
              [-85.71452319695, 38.25681874736],
              [-85.71452727842, 38.25681145432],
              [-85.71452982934, 38.25680689617],
              [-85.71453246215, 38.25680271717],
              [-85.71452613021, 38.25680041295],
              [-85.71455491492, 38.25675163961],
              [-85.71450986532, 38.25673524582],
              [-85.71449229099, 38.25676502412],
              [-85.71446953301, 38.25675674237],
              [-85.71447052949, 38.25675505391],
              [-85.71446493091, 38.25675301585],
              [-85.71445964326, 38.25675109235],
              [-85.71445908665, 38.25675203548],
              [-85.71443895366, 38.25674470898],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71451213946, 38.25677802128],
            type: 'Point',
          },
        },
      },
      {
        id: '9b5ae2fe-eb79-4a8a-ac76-e5e9e590a8be',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71418866451, 38.25742405503],
              [-85.71418286421, 38.25743452616],
              [-85.71418096326, 38.25743795789],
              [-85.71417901187, 38.25744148067],
              [-85.71417639474, 38.25744620532],
              [-85.71415640031, 38.25743937616],
              [-85.71416867008, 38.25741722587],
              [-85.71418866451, 38.25742405503],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71417253241, 38.257431715595004],
            type: 'Point',
          },
        },
      },
      {
        id: '9cf1f0d6-1896-4d27-84e5-7c30c85cd0b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480482202, 38.25691684296],
              [-85.71480767792, 38.25691193003],
              [-85.71480936975, 38.25690897276],
              [-85.71481114415, 38.25690587117],
              [-85.71481740778, 38.25689506098],
              [-85.71486646452, 38.25691253881],
              [-85.71486161565, 38.25692093067],
              [-85.71486332851, 38.25692154107],
              [-85.71485907026, 38.25692898373],
              [-85.71485729172, 38.25692835005],
              [-85.7148538481, 38.25693430987],
              [-85.71480482202, 38.25691684296],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Break Room',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71483564326999, 38.256914685425],
            type: 'Point',
          },
        },
      },
      {
        id: '9de5605f-e010-4d24-b06b-ece61bf4b400',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71429612139, 38.25779818149],
              [-85.71434647467, 38.25781661827],
              [-85.71437903011, 38.25776179523],
              [-85.71432867682, 38.25774335845],
              [-85.71430564223, 38.25778209854],
              [-85.71430359919, 38.25778557027],
              [-85.7143014209, 38.25778930667],
              [-85.71429612139, 38.25779818149],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71433757575, 38.25777998836],
            type: 'Point',
          },
        },
      },
      {
        id: '9e72e37c-1f71-4b48-8b80-2ed2ed79361e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71439289856, 38.25683588557],
              [-85.71440989531, 38.2568063057],
              [-85.71443625568, 38.25681570461],
              [-85.71444036115, 38.25681709806],
              [-85.71444409538, 38.2568183655],
              [-85.7144603297, 38.25682417489],
              [-85.71444333294, 38.25685375476],
              [-85.71439289856, 38.25683588557],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71442661413, 38.256830030230006],
            type: 'Point',
          },
        },
      },
      {
        id: '9eed3702-98dc-48f2-9318-0268c812faa5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71444914324, 38.25682997523],
              [-85.71443583914, 38.25685364877],
              [-85.71438366031, 38.25683556768],
              [-85.71439696441, 38.25681189413],
              [-85.71440092276, 38.25681325875],
              [-85.71440556479, 38.2568148743],
              [-85.71441031368, 38.25681652704],
              [-85.71444914324, 38.25682997523],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714416401775, 38.25683277145],
            type: 'Point',
          },
        },
      },
      {
        id: '9f6f4d4d-4740-4f19-94cf-d236787b02dc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.714831998, 38.25697294126],
              [-85.7148220598, 38.25699016515],
              [-85.71477223107, 38.25697330605],
              [-85.71477692438, 38.25696475281],
              [-85.71477737826, 38.25696386859],
              [-85.71477878309, 38.25696135835],
              [-85.71478061777, 38.25695808003],
              [-85.71478198166, 38.25695553623],
              [-85.71483223091, 38.25697253761],
              [-85.714831998, 38.25697294126],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Braille Tales Coordinator',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71480223099, 38.256972850690005],
            type: 'Point',
          },
        },
      },
      {
        id: 'a04bf9db-e0a4-4194-b8f7-54e915bd430b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71445140587, 38.25675725496],
              [-85.714435948, 38.25678471825],
              [-85.71443928834, 38.25678587291],
              [-85.71444888758, 38.25678921371],
              [-85.71446618474, 38.25679521225],
              [-85.71448164261, 38.25676774896],
              [-85.71445140587, 38.25675725496],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714458795305, 38.256776233604995],
            type: 'Point',
          },
        },
      },
      {
        id: 'a050a15a-cfef-4b39-9467-c06646694000',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71463933271, 38.2569715223],
              [-85.7146330772, 38.25696949166],
              [-85.71462568885, 38.25696709329],
              [-85.71461921982, 38.25697938114],
              [-85.71462658116, 38.25698177074],
              [-85.71463286368, 38.25698381015],
              [-85.71463933271, 38.2569715223],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71462927626499, 38.256975451719995],
            type: 'Point',
          },
        },
      },
      {
        id: 'a154a228-9953-4d65-9f3d-4dae30333dc1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457878749, 38.25691085192],
              [-85.71458004524, 38.256908642],
              [-85.71458141019, 38.25690624372],
              [-85.71458310171, 38.25690327163],
              [-85.71458486719, 38.25690016959],
              [-85.71459832101, 38.25690489097],
              [-85.71459778544, 38.256905832],
              [-85.71460801688, 38.25690942254],
              [-85.71460865749, 38.25690829695],
              [-85.71462511806, 38.2569140735],
              [-85.71462385027, 38.25691630108],
              [-85.71462780128, 38.25691769098],
              [-85.71461668193, 38.25693762276],
              [-85.71461460807, 38.25693689292],
              [-85.71461268186, 38.25694027736],
              [-85.71458298609, 38.25692985616],
              [-85.71459130248, 38.25691524383],
              [-85.71457878749, 38.25691085192],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.family',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Family Restroom',
            frCA: null,
          },
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714603294385, 38.256920223475],
            type: 'Point',
          },
        },
      },
      {
        id: 'a2f2db51-a011-476f-a34e-6a37dcb8db3d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71426851377, 38.2576389456],
              [-85.71438534114, 38.25768060218],
              [-85.7143882403, 38.25767558879],
              [-85.71439003899, 38.25767623014],
              [-85.7143921786, 38.25767253021],
              [-85.71439411825, 38.25766917606],
              [-85.7143924645, 38.25766858639],
              [-85.71439443269, 38.2576651829],
              [-85.71438818193, 38.2576629541],
              [-85.71438887094, 38.25766176261],
              [-85.71438359132, 38.25765988009],
              [-85.71437829824, 38.25765799276],
              [-85.71437732068, 38.25765968321],
              [-85.71433857002, 38.25764586608],
              [-85.71435148269, 38.25762353673],
              [-85.71434016827, 38.2576195024],
              [-85.71434559632, 38.25761011591],
              [-85.71434810001, 38.25761100863],
              [-85.7143509865, 38.25760601714],
              [-85.71435377591, 38.25760119353],
              [-85.71435098747, 38.25760019927],
              [-85.71438527923, 38.25754089995],
              [-85.71437980766, 38.25753894897],
              [-85.71438164568, 38.25753576991],
              [-85.71436202832, 38.25752885779],
              [-85.7143421835, 38.25752186552],
              [-85.71434044334, 38.25752487372],
              [-85.71433549905, 38.25752311076],
              [-85.71431403826, 38.25756027374],
              [-85.714309792, 38.25756757307],
              [-85.71430315768, 38.25757897749],
              [-85.71426851377, 38.2576389456],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71433147323, 38.25758482498],
            type: 'Point',
          },
        },
      },
      {
        id: 'a38f1727-a39a-44d0-a1bc-0e850157061a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71447696856, 38.25747963671],
              [-85.71444129221, 38.25746695343],
              [-85.71443786971, 38.25746572764],
              [-85.7144344589, 38.25746450604],
              [-85.71443254596, 38.25746383378],
              [-85.71441000667, 38.25750290051],
              [-85.71446942721, 38.25752403881],
              [-85.71447131846, 38.25752076076],
              [-85.71447483682, 38.25752201236],
              [-85.71447702385, 38.25751823624],
              [-85.71447922593, 38.25751443413],
              [-85.71447568499, 38.25751317448],
              [-85.71449008013, 38.25748822378],
              [-85.71447509076, 38.25748289145],
              [-85.71447696856, 38.25747963671],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7144500434, 38.257493936295],
            type: 'Point',
          },
        },
      },
      {
        id: 'a3a63f5a-7168-4067-bc0d-eda856c8f14d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71448148802, 38.25677521389],
              [-85.71450490371, 38.25673383866],
              [-85.71448642659, 38.25672739088],
              [-85.71448747003, 38.25672554679],
              [-85.714469346, 38.25671931241],
              [-85.71446836907, 38.25672103792],
              [-85.71445425426, 38.25671611242],
              [-85.71445043422, 38.25672286239],
              [-85.71444754685, 38.25672185675],
              [-85.71443952925, 38.25673622868],
              [-85.71444226283, 38.25673718113],
              [-85.71443075747, 38.25675751095],
              [-85.71445598617, 38.25676631476],
              [-85.71446032727, 38.25676783274],
              [-85.71446413617, 38.25676915878],
              [-85.71448148802, 38.25677521389],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Field Services (East)',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71446783059, 38.256745663155],
            type: 'Point',
          },
        },
      },
      {
        id: 'a3ebf07e-097c-48f2-ba87-ae0a4a15f201',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7146143244, 38.25697750772],
              [-85.71460849375, 38.256987674],
              [-85.71457325837, 38.25697521253],
              [-85.71456786469, 38.25698466951],
              [-85.71456205989, 38.2569826167],
              [-85.71455629934, 38.25698057954],
              [-85.71456172065, 38.25697115383],
              [-85.71456753045, 38.25696097025],
              [-85.71457906817, 38.25696502895],
              [-85.7146143244, 38.25697750772],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71458531187, 38.256974322125004],
            type: 'Point',
          },
        },
      },
      {
        id: 'a4e8f81e-c257-4df1-9fc0-bbe14989c5f5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71398346908, 38.25743561657],
              [-85.71400526122, 38.25739777626],
              [-85.7140676909, 38.25741994482],
              [-85.71406037326, 38.25743272186],
              [-85.7140576627, 38.25743735686],
              [-85.71405507868, 38.25744177547],
              [-85.71404589876, 38.25745778512],
              [-85.71398346908, 38.25743561657],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71402557999, 38.25742778069],
            type: 'Point',
          },
        },
      },
      {
        id: 'a531d837-70db-4c04-8813-4bcf2427a1f2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71424866348, 38.25798403949],
              [-85.71414976103, 38.25794842142],
              [-85.71414317432, 38.25795961093],
              [-85.71413900714, 38.2579668332],
              [-85.71413483522, 38.25797406367],
              [-85.71412780485, 38.25798601278],
              [-85.71414593693, 38.25799254275],
              [-85.71414484924, 38.25799440498],
              [-85.71415055837, 38.25799646102],
              [-85.71415613852, 38.25799847062],
              [-85.71415722816, 38.25799660504],
              [-85.71422670926, 38.25802162749],
              [-85.71424866348, 38.25798403949],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714188234165, 38.257985024454996],
            type: 'Point',
          },
        },
      },
      {
        id: 'a552838f-14e9-408a-a42a-dbd18ecf7ed3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71429277768, 38.25753008675],
              [-85.71429788541, 38.25753188733],
              [-85.71430819255, 38.25753557804],
              [-85.71431793653, 38.25753906709],
              [-85.71432222917, 38.25754057766],
              [-85.71433381959, 38.2575205148],
              [-85.71430436811, 38.25751002387],
              [-85.71429277768, 38.25753008675],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Do not use Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714313298635, 38.257525300764996],
            type: 'Point',
          },
        },
      },
      {
        id: 'a654bcf7-5121-4909-beac-46cab5098bb8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71421020002, 38.25723800165],
              [-85.714211074, 38.25723642305],
              [-85.71421309332, 38.25723277568],
              [-85.71421503873, 38.2572292618],
              [-85.71422957141, 38.25720301237],
              [-85.71420866203, 38.25719587446],
              [-85.71420931173, 38.25719470096],
              [-85.71420492024, 38.25719320182],
              [-85.71420048501, 38.25719168775],
              [-85.71419983501, 38.25719286181],
              [-85.71419578397, 38.25719147889],
              [-85.71420395514, 38.25717671981],
              [-85.71419105161, 38.25717231489],
              [-85.71419001901, 38.25717418001],
              [-85.71418674876, 38.25717306364],
              [-85.71418484978, 38.25717649365],
              [-85.7141829339, 38.25717995419],
              [-85.71418621318, 38.25718107365],
              [-85.71418203299, 38.25718862405],
              [-85.71417978258, 38.25718785613],
              [-85.71417787247, 38.2571912633],
              [-85.71417589547, 38.25719478979],
              [-85.71417816777, 38.25719556536],
              [-85.71416196465, 38.25722483201],
              [-85.7141909191, 38.25723469544],
              [-85.7141948581, 38.257236057],
              [-85.714199565, 38.2572376888],
              [-85.7142041495, 38.25723923278],
              [-85.71420568451, 38.25723646018],
              [-85.71421020002, 38.25723800165],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71419576803001, 38.257205773835],
            type: 'Point',
          },
        },
      },
      {
        id: 'a68d6c23-0d15-48a9-9a52-16d805324145',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457507738, 38.25698211785],
              [-85.71457640742, 38.25697957974],
              [-85.71457777978, 38.25697696086],
              [-85.71457813801, 38.25697627725],
              [-85.71458421179, 38.2569643991],
              [-85.71459968153, 38.25696965157],
              [-85.71460544635, 38.25697774931],
              [-85.71460015974, 38.25698612158],
              [-85.71459061901, 38.25698785705],
              [-85.71458560473, 38.25698631514],
              [-85.71457465321, 38.25698292729],
              [-85.71457507738, 38.25698211785],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71459004978, 38.256976128074996],
            type: 'Point',
          },
        },
      },
      {
        id: 'a6e7debd-3505-47ec-b5bd-63c01e38e7df',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71409148673, 38.25724820339],
              [-85.7141402079, 38.25726579744],
              [-85.71413849896, 38.25726871969],
              [-85.71413683156, 38.25727157091],
              [-85.71412192127, 38.25729707316],
              [-85.71407328973, 38.25727958483],
              [-85.71409148673, 38.25724820339],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Room Gr39C',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714106748815, 38.257272638275],
            type: 'Point',
          },
        },
      },
      {
        id: 'a714af0c-82a5-48b6-a62c-b0a6fe2e8de5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71415883059, 38.25728294658],
              [-85.7141612692, 38.25727883104],
              [-85.71416336052, 38.25727529955],
              [-85.71419453559, 38.25722263105],
              [-85.71419641855, 38.2572194823],
              [-85.71419880838, 38.25721545965],
              [-85.71422818088, 38.25722618826],
              [-85.71422913321, 38.25722458062],
              [-85.71423320448, 38.25722606769],
              [-85.71423722052, 38.25722753459],
              [-85.71423627271, 38.25722913459],
              [-85.71423902803, 38.257230141],
              [-85.71424008196, 38.25722836186],
              [-85.71424368733, 38.25722967876],
              [-85.71424728326, 38.25723099221],
              [-85.71424622952, 38.25723277104],
              [-85.71428217598, 38.25724590086],
              [-85.71428304151, 38.25724443975],
              [-85.71428712997, 38.2572459331],
              [-85.71429105056, 38.25724736514],
              [-85.71429018571, 38.25724882511],
              [-85.71429123488, 38.25724920833],
              [-85.71428363941, 38.25726203031],
              [-85.71428495742, 38.25726251173],
              [-85.71428322641, 38.25726543387],
              [-85.71428139989, 38.25726851723],
              [-85.71427882041, 38.25726757505],
              [-85.71427780578, 38.25726928785],
              [-85.71428002097, 38.25727009696],
              [-85.7142781208, 38.25727330465],
              [-85.71427610842, 38.25727670178],
              [-85.7142740193, 38.25727593871],
              [-85.71426240317, 38.25729554801],
              [-85.71426481881, 38.25729643034],
              [-85.71426302038, 38.25729946629],
              [-85.71426112921, 38.25730265878],
              [-85.71425895656, 38.2573018652],
              [-85.71424955067, 38.25731774337],
              [-85.71424583091, 38.25731638469],
              [-85.71424189748, 38.25731494796],
              [-85.71424260089, 38.25731376053],
              [-85.71420666012, 38.25730063281],
              [-85.71420572608, 38.25730220956],
              [-85.7142026029, 38.25730106879],
              [-85.71419771276, 38.25729928262],
              [-85.71419859497, 38.25729779335],
              [-85.71419597857, 38.25729683769],
              [-85.7141952128, 38.25729813039],
              [-85.71419118101, 38.25729665774],
              [-85.7141875392, 38.25729532754],
              [-85.714188462, 38.25729376975],
              [-85.71415883059, 38.25728294658],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Technology Product Research',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71422503273499, 38.25726660151],
            type: 'Point',
          },
        },
      },
      {
        id: 'a745029f-2e5f-4a3d-8b71-980c154caab9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7148042459, 38.25693913591],
              [-85.71481240213, 38.25692512852],
              [-85.71480276373, 38.25692166798],
              [-85.71480017634, 38.25692611151],
              [-85.71479594116, 38.25692459092],
              [-85.71479516861, 38.25692591769],
              [-85.71479323523, 38.25692923805],
              [-85.71479129408, 38.25693257175],
              [-85.71479037231, 38.25693415478],
              [-85.7148042459, 38.25693913591],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71480366816499, 38.256930401945],
            type: 'Point',
          },
        },
      },
      {
        id: 'a7de4fcb-0b37-4e07-9742-3a2c6d834966',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71377871878, 38.25817883889],
              [-85.71378289563, 38.25818026124],
              [-85.71378707767, 38.25818168535],
              [-85.71379146861, 38.25818318059],
              [-85.71381610379, 38.25819165565],
              [-85.71383049141, 38.25819659267],
              [-85.71381858101, 38.25821799408],
              [-85.71380437561, 38.25821311959],
              [-85.71377963365, 38.25820460967],
              [-85.7137765621, 38.25820356372],
              [-85.7137720683, 38.25820203345],
              [-85.71376786094, 38.25820060072],
              [-85.71376399224, 38.25819928331],
              [-85.7137758272, 38.25817785422],
              [-85.71377871878, 38.25817883889],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Center Stairwell 6',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.713797241825, 38.25819792415],
            type: 'Point',
          },
        },
      },
      {
        id: 'a838a9cc-e2c8-4319-83b2-88163bfeafcb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71460213093, 38.25677998271],
              [-85.71459521359, 38.25679250931],
              [-85.71459923622, 38.25679383846],
              [-85.71460855331, 38.25679705215],
              [-85.71461740246, 38.25680010444],
              [-85.71462119904, 38.25680135731],
              [-85.71462811639, 38.25678883072],
              [-85.71461519277, 38.25678443025],
              [-85.71460213093, 38.25677998271],
              [-85.71460213093, 38.25677998271],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Front Vestibule',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71461166499, 38.256790670010005],
            type: 'Point',
          },
        },
      },
      {
        id: 'a90e210f-7ef3-43ab-bfa3-b732ac9a47b3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71472067504, 38.25684826804],
              [-85.71469764496, 38.25684012205],
              [-85.71469286233, 38.25683843039],
              [-85.71468792185, 38.25683668289],
              [-85.71468662679, 38.25683622481],
              [-85.71470353107, 38.25680675638],
              [-85.71473757932, 38.25681879961],
              [-85.71472067504, 38.25684826804],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714712103055, 38.25682751221],
            type: 'Point',
          },
        },
      },
      {
        id: 'a9fe523e-aa51-4fe8-8763-e88e48701541',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71414971548, 38.25714617307],
              [-85.71416287999, 38.25712377492],
              [-85.71425851246, 38.25715843282],
              [-85.71424599945, 38.25718106707],
              [-85.71419896631, 38.25716401432],
              [-85.7141947825, 38.25716250568],
              [-85.71419058697, 38.25716099282],
              [-85.71414971548, 38.25714617307],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Break Room 2',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71420411397, 38.257152420995],
            type: 'Point',
          },
        },
      },
      {
        id: 'aa6ca436-f3e9-4538-81fb-8bacd7591194',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71393579416, 38.25847236539],
              [-85.71393416237, 38.25847508636],
              [-85.71392302891, 38.25849504008],
              [-85.71391569607, 38.2585079874],
              [-85.71394370029, 38.25851776662],
              [-85.71395103313, 38.25850481931],
              [-85.71396219166, 38.25848461995],
              [-85.71396970365, 38.25847170502],
              [-85.71394173332, 38.2584619323],
              [-85.71394026392, 38.25846457268],
              [-85.71393805796, 38.25846841862],
              [-85.71393579416, 38.25847236539],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'West Stairwell 4',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71394269986, 38.258489849460005],
            type: 'Point',
          },
        },
      },
      {
        id: 'aaa376ef-b16e-4325-92ed-c916646e7555',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7148670206, 38.2569111],
              [-85.71481809052, 38.25689373713],
              [-85.71482273443, 38.25688567006],
              [-85.71482463977, 38.25688235872],
              [-85.71482649275, 38.25687913459],
              [-85.71484218928, 38.25685186195],
              [-85.71485898276, 38.25685782113],
              [-85.71485962822, 38.25685670074],
              [-85.71487020601, 38.25686043243],
              [-85.71486956972, 38.25686153698],
              [-85.71489112673, 38.2568691865],
              [-85.71488039051, 38.25688784227],
              [-85.71488223432, 38.25688849711],
              [-85.71487770055, 38.25689642133],
              [-85.71487584689, 38.25689576304],
              [-85.7148670206, 38.2569111],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Communications',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71485460862499, 38.256881480975],
            type: 'Point',
          },
        },
      },
      {
        id: 'abb93d9e-da6c-43f5-a23f-fc360b5c19e2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480261645, 38.25695409735],
              [-85.71483880249, 38.25696621204],
              [-85.71482612433, 38.25698956228],
              [-85.71477768269, 38.25697334455],
              [-85.71477916974, 38.25697056346],
              [-85.71478086891, 38.25696747662],
              [-85.71478253135, 38.25696445649],
              [-85.71478336453, 38.2569628799],
              [-85.71479562014, 38.25696698294],
              [-85.71480261645, 38.25695409735],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71480824259, 38.256971829815],
            type: 'Point',
          },
        },
      },
      {
        id: 'abccbae5-fd37-4b92-a70e-b82bdc309489',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71450424109, 38.25677586886],
              [-85.71450752994, 38.25677701347],
              [-85.71450831848, 38.2567756164],
              [-85.7145129057, 38.25677721288],
              [-85.71451763699, 38.25677885949],
              [-85.71451684541, 38.25678026195],
              [-85.71453742781, 38.25678742519],
              [-85.71452864128, 38.25680299249],
              [-85.71451887081, 38.2567995921],
              [-85.71451608575, 38.25680449902],
              [-85.71451446373, 38.25680739864],
              [-85.71451217982, 38.25681148148],
              [-85.71451006299, 38.25681519713],
              [-85.71451983471, 38.25681859795],
              [-85.71451511215, 38.25682696501],
              [-85.71451717331, 38.25682768235],
              [-85.71451526769, 38.25683105859],
              [-85.71451324543, 38.25683464146],
              [-85.71451117853, 38.25683392213],
              [-85.71450653852, 38.25684214293],
              [-85.71448474114, 38.25683455686],
              [-85.71448391663, 38.25683601765],
              [-85.71447940135, 38.25683444621],
              [-85.71447508923, 38.25683294548],
              [-85.71447592149, 38.25683147095],
              [-85.71445363336, 38.25682371408],
              [-85.71446363385, 38.25680599598],
              [-85.71445907545, 38.25680440954],
              [-85.71445849007, 38.25680544667],
              [-85.71445365566, 38.25680376416],
              [-85.71444893009, 38.25680211954],
              [-85.71444951948, 38.25680107532],
              [-85.71442836202, 38.25679371195],
              [-85.71441540311, 38.25681667153],
              [-85.71441108111, 38.25681516736],
              [-85.71441031368, 38.25681652704],
              [-85.71440556479, 38.2568148743],
              [-85.71440092276, 38.25681325875],
              [-85.71440169327, 38.25681189363],
              [-85.71439730508, 38.25681036642],
              [-85.71443127244, 38.25675018569],
              [-85.71443583046, 38.25675177201],
              [-85.71443643572, 38.25675069965],
              [-85.71444148028, 38.25675245609],
              [-85.71444598186, 38.25675402197],
              [-85.7144453757, 38.25675509592],
              [-85.71444975922, 38.25675662151],
              [-85.71443374396, 38.25678499611],
              [-85.71443879168, 38.25678675285],
              [-85.71443928834, 38.25678587291],
              [-85.71444888758, 38.25678921371],
              [-85.71444839576, 38.25679008507],
              [-85.71448836576, 38.25680399572],
              [-85.71448982236, 38.25680141503],
              [-85.71448873001, 38.25680103486],
              [-85.71449064211, 38.25679764716],
              [-85.71449273951, 38.25679393114],
              [-85.71449383177, 38.25679431128],
              [-85.71450424109, 38.25677586886],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71449144919, 38.25679616431],
            type: 'Point',
          },
        },
      },
      {
        id: 'abde762c-42a4-4106-b005-7d46c8670d1a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71448285686, 38.25676839238],
              [-85.71450315424, 38.25677544574],
              [-85.71449273951, 38.25679393114],
              [-85.71449064211, 38.25679764716],
              [-85.71448873001, 38.25680103486],
              [-85.71448783768, 38.25680262352],
              [-85.7144675403, 38.25679557015],
              [-85.71448285686, 38.25676839238],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71448534727, 38.25678550795],
            type: 'Point',
          },
        },
      },
      {
        id: 'ade736d4-64e0-404f-98db-cbdf76984152',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71485351152, 38.25693461368],
              [-85.71485713921, 38.25692798696],
              [-85.71485861055, 38.25692848361],
              [-85.7148627864, 38.25692085557],
              [-85.71486077724, 38.25692017738],
              [-85.71486835956, 38.25690632672],
              [-85.71483635227, 38.25689552263],
              [-85.71482543218, 38.25691555097],
              [-85.71482396101, 38.25691816236],
              [-85.71482222601, 38.25692124202],
              [-85.71482213119, 38.25692150034],
              [-85.71482096641, 38.25692362806],
              [-85.71485351152, 38.25693461368],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 318',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714844662985, 38.256915068155],
            type: 'Point',
          },
        },
      },
      {
        id: 'ae78fb7d-f1cb-4388-bf26-35a7bb3712e3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480491374, 38.2569522351],
              [-85.71481619316, 38.25693151773],
              [-85.7148177944, 38.25692867548],
              [-85.71481964835, 38.25692538466],
              [-85.71482030145, 38.25692409532],
              [-85.71485364123, 38.25693533679],
              [-85.7148448828, 38.25695135348],
              [-85.71484638335, 38.25695185943],
              [-85.71484167359, 38.25696047224],
              [-85.71484058125, 38.25696010393],
              [-85.71483866172, 38.2569636142],
              [-85.71480491374, 38.2569522351],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 319',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714829277485, 38.256943854759996],
            type: 'Point',
          },
        },
      },
      {
        id: 'af0c6b22-1ef1-49ce-b87e-30ba19f41565',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7145674485, 38.25695469233],
              [-85.71457007426, 38.25694987461],
              [-85.71457158474, 38.25694710319],
              [-85.71457320298, 38.25694413405],
              [-85.7145761171, 38.25693878723],
              [-85.71459819431, 38.25694620655],
              [-85.71458952571, 38.25696211164],
              [-85.7145674485, 38.25695469233],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714582821405, 38.256950449434996],
            type: 'Point',
          },
        },
      },
      {
        id: 'af37a854-eccd-4c8a-a346-36e11dbab775',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71475263768, 38.25686003813],
              [-85.71475133025, 38.25685957122],
              [-85.71474653421, 38.25685785849],
              [-85.71474172149, 38.25685613979],
              [-85.71472230383, 38.25684920546],
              [-85.71473901167, 38.25682035713],
              [-85.71474011546, 38.25682075131],
              [-85.71474061001, 38.2568198974],
              [-85.71476984008, 38.2568303359],
              [-85.71475263768, 38.25686003813],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 109B-109D',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714746071955, 38.256839967765],
            type: 'Point',
          },
        },
      },
      {
        id: 'af6f9b00-7118-481d-a52b-63ac0681f9b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7140897934, 38.25744324008],
              [-85.71408862413, 38.2574452865],
              [-85.7140864823, 38.25744896695],
              [-85.7140842846, 38.2574527434],
              [-85.7140696805, 38.25747798403],
              [-85.7140680394, 38.25747739825],
              [-85.71406759719, 38.25747816214],
              [-85.71402302434, 38.25746225228],
              [-85.71402619571, 38.25745677391],
              [-85.71402398697, 38.25745598552],
              [-85.7140259689, 38.2574525602],
              [-85.71402795248, 38.25744913201],
              [-85.71403025567, 38.25744995411],
              [-85.71404367231, 38.25742677757],
              [-85.7140897934, 38.25744324008],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom Vestibule",
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71405640887, 38.257452469855],
            type: 'Point',
          },
        },
      },
      {
        id: 'b2fe075e-a509-4656-b4dc-1bbce64ab179',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71459962507, 38.25694775075],
              [-85.71458852331, 38.25696719031],
              [-85.71461485205, 38.25697646164],
              [-85.7146188, 38.25696954864],
              [-85.7146204223, 38.25697011991],
              [-85.71462184703, 38.25696762516],
              [-85.71462323531, 38.25696519424],
              [-85.71462158611, 38.25696461349],
              [-85.71462600479, 38.25695687622],
              [-85.71461073345, 38.25695149861],
              [-85.71461181079, 38.25694961215],
              [-85.71460849038, 38.25694844291],
              [-85.71460497627, 38.25694720546],
              [-85.71460382105, 38.25694922831],
              [-85.71459962507, 38.25694775075],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71460726405, 38.25696183355],
            type: 'Point',
          },
        },
      },
      {
        id: 'b34205df-ac72-4db4-9f43-c8b1010838fd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457818262, 38.25681742791],
              [-85.7146057948, 38.25676922755],
              [-85.71459390242, 38.25676502678],
              [-85.71459526998, 38.25676262827],
              [-85.71457684072, 38.2567562889],
              [-85.71457550999, 38.25675861938],
              [-85.71455963377, 38.25675301138],
              [-85.71453205489, 38.25680115364],
              [-85.7145499819, 38.25680747868],
              [-85.71455444595, 38.2568090534],
              [-85.71455907899, 38.25681068774],
              [-85.71457818262, 38.25681742791],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'V.P. of Public Affairs',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.714568924845, 38.256785219644996],
            type: 'Point',
          },
        },
      },
      {
        id: 'b34ec29f-91d2-45e2-b95f-aaef9a308f58',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71395515762, 38.25807490213],
              [-85.71398879992, 38.2580871795],
              [-85.71399299704, 38.25808871119],
              [-85.71399722037, 38.25809025244],
              [-85.7139996793, 38.2580911498],
              [-85.71399663815, 38.25809620042],
              [-85.7139946376, 38.25809966762],
              [-85.71399260366, 38.2581031927],
              [-85.71397821197, 38.25812742015],
              [-85.71393369029, 38.25811117249],
              [-85.71393643979, 38.25810652705],
              [-85.71393870735, 38.25810269587],
              [-85.71394092484, 38.25809894929],
              [-85.71395515762, 38.25807490213],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.713966684795, 38.25810116114],
            type: 'Point',
          },
        },
      },
      {
        id: 'b4e14876-301b-44c5-8ead-f070b3279d8c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71449519272, 38.25735022613],
              [-85.71451077434, 38.25732445441],
              [-85.71454264554, 38.2573363359],
              [-85.71454188174, 38.25733756222],
              [-85.71453981844, 38.25734101241],
              [-85.7145378324, 38.25734433339],
              [-85.71452706391, 38.2573621076],
              [-85.71449519272, 38.25735022613],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Office',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71451891913, 38.25734328100501],
            type: 'Point',
          },
        },
      },
      {
        id: 'b5f48aa2-7a75-4102-b624-4b665dbd4738',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71455869893, 38.25689127447],
              [-85.71456379819, 38.25688234935],
              [-85.71455609374, 38.25687966218],
              [-85.71456213649, 38.2568689793],
              [-85.71456635782, 38.25687045163],
              [-85.71457003716, 38.25686394697],
              [-85.71456484347, 38.25686213551],
              [-85.71456750666, 38.25685742731],
              [-85.71457162599, 38.25685886405],
              [-85.7145733223, 38.25685586487],
              [-85.71457777332, 38.25685738432],
              [-85.71458200234, 38.25685882798],
              [-85.71458038597, 38.25686168677],
              [-85.71458330267, 38.25686270407],
              [-85.71458016214, 38.25686825616],
              [-85.71463415738, 38.25688708872],
              [-85.71463496873, 38.25688549163],
              [-85.71463852805, 38.25688669734],
              [-85.71464243218, 38.25688802175],
              [-85.71464186438, 38.25688919338],
              [-85.71464340123, 38.25688972758],
              [-85.714630965, 38.25691201991],
              [-85.71458347508, 38.25689545881],
              [-85.71458643965, 38.2568902178],
              [-85.71457400158, 38.25688587962],
              [-85.71456894812, 38.25689478149],
              [-85.71456398549, 38.25689307918],
              [-85.71455869893, 38.25689127447],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71459974748501, 38.25688394239],
            type: 'Point',
          },
        },
      },
      {
        id: 'b6cb1535-d0a0-4edd-903c-e6e96ed53061',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71434712629, 38.25689947818],
              [-85.71428896391, 38.25700012663],
              [-85.71434870569, 38.25702141388],
              [-85.71435541136, 38.25700980991],
              [-85.71438028118, 38.25701867155],
              [-85.71437326805, 38.25703080757],
              [-85.71443276261, 38.25705200673],
              [-85.71443820832, 38.2570426604],
              [-85.71444065939, 38.25703844065],
              [-85.71444293807, 38.25703451766],
              [-85.71445205655, 38.25701861913],
              [-85.71443128187, 38.25701121667],
              [-85.71447044627, 38.25694344374],
              [-85.71446300361, 38.25694079176],
              [-85.71446387073, 38.25693929112],
              [-85.7144605476, 38.25693809745],
              [-85.71445567434, 38.25693634698],
              [-85.71445480735, 38.25693784727],
              [-85.71438057398, 38.25691139664],
              [-85.71437680216, 38.25691004902],
              [-85.71437249937, 38.25690851884],
              [-85.71434712629, 38.25689947818],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: "Lynn's Cafe",
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714394181995, 38.256975742455],
            type: 'Point',
          },
        },
      },
      {
        id: 'b79ce21c-dc48-4373-ac25-af21477f4977',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71454464434, 38.25704243947],
              [-85.71457277528, 38.25705205478],
              [-85.71458364839, 38.25703244014],
              [-85.7145852575, 38.25702953718],
              [-85.71458690608, 38.25702656341],
              [-85.71459425496, 38.25701330633],
              [-85.71457227693, 38.25700579412],
              [-85.71456806414, 38.25700435401],
              [-85.71456389971, 38.25700293074],
              [-85.71455675507, 38.25700048866],
              [-85.71454197166, 38.25702715734],
              [-85.7145513406, 38.25703035969],
              [-85.71454464434, 38.25704243947],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Computer and Technical Services',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71456811331001, 38.257026271719994],
            type: 'Point',
          },
        },
      },
      {
        id: 'b84aa587-2127-437c-b1a1-c51e8e02f33c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71394274788, 38.25852108066],
              [-85.71390949173, 38.2585788471],
              [-85.71342494545, 38.25840685056],
              [-85.71352400686, 38.25823477884],
              [-85.71355137986, 38.25824449531],
              [-85.71357242343, 38.25820794216],
              [-85.7135701148, 38.25820709329],
              [-85.71357207259, 38.25820362701],
              [-85.71357397735, 38.2582002546],
              [-85.71357674135, 38.25820121143],
              [-85.71358114181, 38.25819318966],
              [-85.71357051235, 38.25818941657],
              [-85.71359820279, 38.25814169565],
              [-85.71391474171, 38.25825405606],
              [-85.71389529023, 38.25828784372],
              [-85.71389123346, 38.2582948904],
              [-85.71388772659, 38.25830098191],
              [-85.71386192, 38.25834580849],
              [-85.71387920221, 38.25835194306],
              [-85.7138800326, 38.25835050065],
              [-85.71388890142, 38.25835364877],
              [-85.71389656422, 38.25835636879],
              [-85.71389580686, 38.25835768433],
              [-85.71391073784, 38.2583629843],
              [-85.71393418214, 38.25832226108],
              [-85.71398370477, 38.25833983987],
              [-85.71390636957, 38.25847417256],
              [-85.71392735168, 38.25848162046],
              [-85.71391110148, 38.25850984732],
              [-85.71394274788, 38.25852108066],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'storage',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71370432511, 38.258360271375],
            type: 'Point',
          },
        },
      },
      {
        id: 'b85685d5-41fb-4f3b-b744-d14e39789827',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7143863907, 38.25674137231],
              [-85.71441360515, 38.25675127414],
              [-85.71442388251, 38.25673385709],
              [-85.71439666805, 38.25672395525],
              [-85.71439571357, 38.25672557282],
              [-85.71439376271, 38.25672889672],
              [-85.71439195563, 38.25673194141],
              [-85.7143863907, 38.25674137231],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714405136605, 38.256737614695],
            type: 'Point',
          },
        },
      },
      {
        id: 'b8f68e36-e122-4679-8634-1e7ec5f57f73',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71459936037, 38.25698920332],
              [-85.71455601253, 38.25697450352],
              [-85.71456664846, 38.25695557216],
              [-85.71459096242, 38.25696400707],
              [-85.71458449232, 38.25697550694],
              [-85.71459600702, 38.25697950158],
              [-85.71459981152, 38.25698082142],
              [-85.71460337976, 38.2569820593],
              [-85.71459936037, 38.25698920332],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714579696145, 38.25697238774001],
            type: 'Point',
          },
        },
      },
      {
        id: 'ba1ee02b-8c17-4240-a1fe-fc7bc0889dc2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71456465151, 38.25730966608],
              [-85.71454112474, 38.25730107476],
              [-85.71454156896, 38.25730032462],
              [-85.71453772212, 38.25729893475],
              [-85.71453339047, 38.25729736972],
              [-85.71453299176, 38.25729804273],
              [-85.71452757382, 38.25729606426],
              [-85.7145229043, 38.25730394883],
              [-85.71452574188, 38.25730498503],
              [-85.71452452244, 38.25730704408],
              [-85.71452674738, 38.25730785657],
              [-85.71453070235, 38.25730929538],
              [-85.71453477691, 38.25731078873],
              [-85.71455875244, 38.25731954392],
              [-85.71455977773, 38.25731781269],
              [-85.71456220712, 38.25731869865],
              [-85.71456416025, 38.25731533368],
              [-85.71456606709, 38.25731196239],
              [-85.71456378512, 38.257311129],
              [-85.71456465151, 38.25730966608],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714544485695, 38.25730780409],
            type: 'Point',
          },
        },
      },
      {
        id: 'bb271af3-0a0b-46a5-b586-0a1a80fb4b7d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457096719, 38.25686692023],
              [-85.71458513722, 38.25687195237],
              [-85.71459138986, 38.25687410906],
              [-85.71459747544, 38.25687620812],
              [-85.71459898747, 38.25687678354],
              [-85.71459024937, 38.25689208999],
              [-85.71459279289, 38.25689298532],
              [-85.71459111055, 38.25689593227],
              [-85.7145892797, 38.25689913935],
              [-85.71458681064, 38.25689827022],
              [-85.71457850769, 38.25691281443],
              [-85.71458082948, 38.25691363171],
              [-85.71457772897, 38.25691906285],
              [-85.71457398548, 38.25692562028],
              [-85.71457167516, 38.25692480703],
              [-85.71456650818, 38.25693385797],
              [-85.71455909574, 38.25693124875],
              [-85.71455790332, 38.2569333375],
              [-85.71455125323, 38.25693099663],
              [-85.71454456572, 38.25692864259],
              [-85.71454574073, 38.25692658434],
              [-85.71453858405, 38.25692406514],
              [-85.71454642037, 38.25691033834],
              [-85.71454351448, 38.25690931511],
              [-85.71454581823, 38.25690538164],
              [-85.71454788852, 38.2569018468],
              [-85.71455060998, 38.25690280427],
              [-85.71456147531, 38.25688377158],
              [-85.71455938423, 38.25688303551],
              [-85.71456136252, 38.25687957017],
              [-85.71456294965, 38.25687678999],
              [-85.71456493401, 38.2568774885],
              [-85.71457096719, 38.25686692023],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71456878576001, 38.2569003891],
            type: 'Point',
          },
        },
      },
      {
        id: 'bb9742c5-f906-470f-850c-6652f15694d8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7141221818, 38.25769320654],
              [-85.71413187395, 38.25767682156],
              [-85.71413390156, 38.25767329663],
              [-85.71413593983, 38.25766975317],
              [-85.71414542408, 38.2576537216],
              [-85.71415535235, 38.25765737647],
              [-85.71416983673, 38.25766253074],
              [-85.71419109491, 38.25767029778],
              [-85.71416785263, 38.25770978271],
              [-85.7141221818, 38.25769320654],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714156638355, 38.257681752155],
            type: 'Point',
          },
        },
      },
      {
        id: 'bbb12845-f689-4694-ba6b-e4eeb5dde3d4',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7139306152, 38.25801378967],
              [-85.71391704441, 38.25800894426],
              [-85.71390077551, 38.25800317409],
              [-85.71388545152, 38.25799773907],
              [-85.71369990385, 38.25793171062],
              [-85.71358243344, 38.25813484608],
              [-85.71358418316, 38.25813549496],
              [-85.71359025065, 38.25813760798],
              [-85.71359574666, 38.25813952198],
              [-85.71376210002, 38.25819890464],
              [-85.71377574155, 38.25817494276],
              [-85.71378007755, 38.25817648535],
              [-85.71377871878, 38.25817883889],
              [-85.71378289563, 38.25818026124],
              [-85.71378707767, 38.25818168535],
              [-85.71378829188, 38.25817953764],
              [-85.71383303629, 38.25819545609],
              [-85.71382073812, 38.25821677037],
              [-85.71392878321, 38.2582552089],
              [-85.71394031455, 38.25825931134],
              [-85.71395237912, 38.25826360347],
              [-85.71397753167, 38.25827258013],
              [-85.71397498012, 38.25827708549],
              [-85.71398585094, 38.25828075108],
              [-85.71399278704, 38.25828308989],
              [-85.71399502025, 38.25827915346],
              [-85.7140634805, 38.25830353009],
              [-85.71410492231, 38.25823215183],
              [-85.71407576542, 38.25822177886],
              [-85.71408065662, 38.25821285629],
              [-85.71408285873, 38.25821363858],
              [-85.71408423021, 38.25821116589],
              [-85.71408588548, 38.25820818152],
              [-85.71408402837, 38.25820753225],
              [-85.71410307452, 38.25817456358],
              [-85.71413227912, 38.25818495352],
              [-85.71420219061, 38.25806378803],
              [-85.71409999883, 38.2580274318],
              [-85.7141314804, 38.25797287015],
              [-85.71413483522, 38.25797406367],
              [-85.71413900714, 38.2579668332],
              [-85.71414317432, 38.25795961093],
              [-85.71414011436, 38.2579585223],
              [-85.71414808513, 38.25794470792],
              [-85.71425056894, 38.25798116809],
              [-85.71438854277, 38.25774204108],
              [-85.71426155725, 38.2576968639],
              [-85.71426311373, 38.25769416629],
              [-85.71425543814, 38.25769143558],
              [-85.7142472306, 38.25768851561],
              [-85.71424588715, 38.25769084398],
              [-85.71422163118, 38.25768221452],
              [-85.71422316842, 38.25767955027],
              [-85.71421847337, 38.25767787993],
              [-85.71421349949, 38.25767611038],
              [-85.7142120315, 38.25767865461],
              [-85.71419196207, 38.25767151458],
              [-85.71412468208, 38.25778783501],
              [-85.71407578481, 38.25777043902],
              [-85.71404730473, 38.25782006147],
              [-85.71404313253, 38.25782731469],
              [-85.71403891078, 38.25783465405],
              [-85.71400627692, 38.25789119046],
              [-85.71401218597, 38.25789330318],
              [-85.71402186658, 38.25789673665],
              [-85.71403132831, 38.25790009249],
              [-85.71406679718, 38.2579120963],
              [-85.71408601207, 38.25787879437],
              [-85.71411888824, 38.25789111576],
              [-85.71409057325, 38.2579401676],
              [-85.71408646795, 38.2579473045],
              [-85.7140824227, 38.25795433701],
              [-85.71401177314, 38.25807676018],
              [-85.71400880299, 38.2580757035],
              [-85.7140065776, 38.25807956039],
              [-85.71400438918, 38.25808335319],
              [-85.7140065486, 38.25808412143],
              [-85.71399907824, 38.25809706852],
              [-85.71399663815, 38.25809620042],
              [-85.7139946376, 38.25809966762],
              [-85.71399260366, 38.2581031927],
              [-85.71399488413, 38.25810400401],
              [-85.71398020669, 38.25812944186],
              [-85.71388338361, 38.25809499565],
              [-85.7138865613, 38.25808948831],
              [-85.71388877552, 38.25809027605],
              [-85.71389086129, 38.25808666113],
              [-85.71389302815, 38.2580829057],
              [-85.71389100414, 38.25808218563],
              [-85.71391681416, 38.25803745356],
              [-85.7139187801, 38.25803815298],
              [-85.71392075995, 38.25803472165],
              [-85.71392262787, 38.25803148431],
              [-85.71392078404, 38.25803082834],
              [-85.7139306152, 38.25801378967],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71414010348929, 38.25807090957509],
            type: 'Point',
          },
        },
      },
      {
        id: 'bbd13c71-37ae-40fd-8540-20e1c3832867',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71419061396, 38.25747870496],
              [-85.71413741434, 38.25745961545],
              [-85.71414015725, 38.25745490213],
              [-85.71413644683, 38.25745357073],
              [-85.71413760084, 38.25745158773],
              [-85.71413354408, 38.25745013205],
              [-85.71412935506, 38.25744862891],
              [-85.71412821356, 38.25745059043],
              [-85.7140993035, 38.2574402167],
              [-85.71409693511, 38.25744428646],
              [-85.71409249489, 38.25744269319],
              [-85.71409057776, 38.25744598751],
              [-85.71408862413, 38.2574452865],
              [-85.7140864823, 38.25744896695],
              [-85.7140842846, 38.2574527434],
              [-85.7140862367, 38.25745344387],
              [-85.71408470253, 38.25745608014],
              [-85.71418322304, 38.25749143199],
              [-85.71418696896, 38.25748498159],
              [-85.71419061396, 38.25747870496],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71413744928, 38.257465824345],
            type: 'Point',
          },
        },
      },
      {
        id: 'bbe4e96d-db1d-49ff-9ae8-233e26f6713e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71455534583, 38.25685950831],
              [-85.71456896115, 38.25683562135],
              [-85.71452206318, 38.25681913863],
              [-85.71451717331, 38.25682768235],
              [-85.71451526769, 38.25683105859],
              [-85.71451324543, 38.25683464146],
              [-85.71450844787, 38.2568430256],
              [-85.71455534583, 38.25685950831],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71453870451, 38.256839323470004],
            type: 'Point',
          },
        },
      },
      {
        id: 'bcab30aa-49d1-4370-be60-4f4a16114e1e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71418046002, 38.25718664774],
              [-85.71413558761, 38.25717113647],
              [-85.71413449646, 38.25717308282],
              [-85.71413172873, 38.25717216543],
              [-85.71411090593, 38.25720748409],
              [-85.71411462422, 38.25720876941],
              [-85.7141143292, 38.25720929565],
              [-85.71415696837, 38.25722403493],
              [-85.7141624872, 38.25721419069],
              [-85.71416460806, 38.25721492381],
              [-85.71417589547, 38.25719478979],
              [-85.71417787247, 38.2571912633],
              [-85.71417978258, 38.25718785613],
              [-85.71418046002, 38.25718664774],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714145682975, 38.2571975857],
            type: 'Point',
          },
        },
      },
      {
        id: 'be370945-2736-4c90-a89e-dd3bd83ffbc5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71475187712, 38.25698473281],
              [-85.71476232307, 38.25696536149],
              [-85.71476428969, 38.25696187067],
              [-85.71476586108, 38.25695908139],
              [-85.71476671165, 38.25695739913],
              [-85.7147488607, 38.25695142543],
              [-85.71474075285, 38.25696636473],
              [-85.71472347554, 38.25696058299],
              [-85.71471553225, 38.25697521908],
              [-85.71474119864, 38.25698380816],
              [-85.71474241525, 38.25698156646],
              [-85.71475187712, 38.25698473281],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71475005796, 38.25696807912],
            type: 'Point',
          },
        },
      },
      {
        id: 'bed852f9-9a8f-45fb-b828-30b779eac9ec',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71442330529, 38.25746065485],
              [-85.71442021163, 38.25746590812],
              [-85.71442495663, 38.2574676311],
              [-85.71440515982, 38.25750124757],
              [-85.71435825318, 38.25748421508],
              [-85.71435989714, 38.25748142351],
              [-85.71435789537, 38.25748069664],
              [-85.71437914188, 38.25744461847],
              [-85.71439788295, 38.2574514437],
              [-85.71440195737, 38.25745290298],
              [-85.71440595155, 38.25745433351],
              [-85.71442330529, 38.25746065485],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71439142599999, 38.25747293302],
            type: 'Point',
          },
        },
      },
      {
        id: 'bf11f39f-0093-49ff-bb0a-b63e26b94e04',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7135701148, 38.25820709329],
              [-85.71356848669, 38.25820993147],
              [-85.7135549448, 38.25823367159],
              [-85.71355043471, 38.25824160733],
              [-85.71352611333, 38.25823313169],
              [-85.7135306879, 38.25822518046],
              [-85.71354424436, 38.25820144905],
              [-85.71358141352, 38.25813578306],
              [-85.71358359195, 38.2581365417],
              [-85.71358418316, 38.25813549496],
              [-85.71359025065, 38.25813760798],
              [-85.71359574666, 38.25813952198],
              [-85.71359516399, 38.25814055361],
              [-85.71359678096, 38.25814111672],
              [-85.71356913952, 38.25819005617],
              [-85.71357799557, 38.25819314031],
              [-85.71357397735, 38.2582002546],
              [-85.71357207259, 38.25820362701],
              [-85.7135701148, 38.25820709329],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'East Stairwell 2',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.713561447145, 38.258188551145],
            type: 'Point',
          },
        },
      },
      {
        id: 'c0b0cce0-585a-4133-a2a7-5c952cf5942a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71448491763, 38.25733670615],
              [-85.71444691069, 38.25732283578],
              [-85.71445783676, 38.25730437539],
              [-85.71448479084, 38.2573142121],
              [-85.71448934883, 38.25731587551],
              [-85.71449389916, 38.25731753612],
              [-85.71449584369, 38.25731824576],
              [-85.71448491763, 38.25733670615],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71447137719, 38.257320540769996],
            type: 'Point',
          },
        },
      },
      {
        id: 'c4283b06-924b-4719-b8a4-9ec9fa2b341b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71446903857, 38.25745790739],
              [-85.71448246175, 38.2574343008],
              [-85.71449142655, 38.2574374438],
              [-85.71449092597, 38.25743832418],
              [-85.71449388899, 38.25743936299],
              [-85.7144967873, 38.25744037912],
              [-85.71449730555, 38.25743946766],
              [-85.71450011869, 38.25744045393],
              [-85.71450159789, 38.25743785243],
              [-85.71450343598, 38.25743849685],
              [-85.71450539997, 38.25743504272],
              [-85.71450736288, 38.25743159051],
              [-85.71450541912, 38.25743090904],
              [-85.71452238982, 38.25740106222],
              [-85.71451759416, 38.2573993809],
              [-85.71451278459, 38.2573976947],
              [-85.71450135027, 38.25741780453],
              [-85.71447719847, 38.25740933709],
              [-85.71447318263, 38.25741645042],
              [-85.71446912067, 38.25742354373],
              [-85.71446303878, 38.2574342401],
              [-85.71445991754, 38.25743314582],
              [-85.71445807251, 38.25743639071],
              [-85.7144560261, 38.25743998978],
              [-85.71445944012, 38.25744118671],
              [-85.71445316584, 38.25745222249],
              [-85.71446037402, 38.25745480414],
              [-85.71446903857, 38.25745790739],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71449228347501, 38.257427801045],
            type: 'Point',
          },
        },
      },
      {
        id: 'c52b7f4b-8e47-4450-bd8a-a639a6bc9b2d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71447479819, 38.25682947028],
              [-85.71445857762, 38.25685819719],
              [-85.71443828319, 38.25685113138],
              [-85.71443725699, 38.2568529488],
              [-85.71442870675, 38.25684997191],
              [-85.71441969916, 38.25684683578],
              [-85.71442050398, 38.25684541043],
              [-85.71440576326, 38.25684027823],
              [-85.71440987273, 38.25683300028],
              [-85.71440824994, 38.25683243528],
              [-85.7144120731, 38.25682566439],
              [-85.71441608159, 38.25681856529],
              [-85.71441772602, 38.25681913782],
              [-85.71441885494, 38.25681713848],
              [-85.71445719337, 38.25683048659],
              [-85.71446056528, 38.25682451488],
              [-85.71446223679, 38.25682509684],
              [-85.71446646366, 38.25682656849],
              [-85.71447048208, 38.25682796756],
              [-85.71447479819, 38.25682947028],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'V.P. of Educational Services & Product Development',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714440280725, 38.256837667835],
            type: 'Point',
          },
        },
      },
      {
        id: 'c5b93454-61a4-4fe6-b8a8-2056f4fa1651',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71478197893, 38.25691549314],
              [-85.71477485346, 38.25691296633],
              [-85.71477772729, 38.25690796932],
              [-85.71468470434, 38.25687497284],
              [-85.7146801894, 38.25687338016],
              [-85.71467610942, 38.25687194093],
              [-85.71466776492, 38.25686897469],
              [-85.71465291233, 38.25689470269],
              [-85.71465476088, 38.25689535849],
              [-85.714653036, 38.25689835771],
              [-85.71465918324, 38.25690053762],
              [-85.71465832524, 38.25690202951],
              [-85.71466220302, 38.25690340692],
              [-85.71466636236, 38.2569048796],
              [-85.71466722116, 38.25690338632],
              [-85.71475801165, 38.25693558207],
              [-85.7147570841, 38.25693719488],
              [-85.71476155007, 38.25693879544],
              [-85.714764835, 38.25693994347],
              [-85.71476589206, 38.25693810545],
              [-85.71476845223, 38.25693901333],
              [-85.71478197893, 38.25691549314],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: '2nd Floor Conference Room',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71471744563, 38.256904459080005],
            type: 'Point',
          },
        },
      },
      {
        id: 'c6008caa-1c6a-4366-8e0e-d51f54955fd5',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71437920745, 38.25683947465],
              [-85.71438318729, 38.25683256253],
              [-85.71439708129, 38.25680843167],
              [-85.71439114168, 38.25680631151],
              [-85.71439455538, 38.25680041462],
              [-85.71438840145, 38.25679821796],
              [-85.71439769488, 38.2567821643],
              [-85.7143656082, 38.25677071087],
              [-85.71436625739, 38.25676969347],
              [-85.71436941849, 38.25676406328],
              [-85.71437232423, 38.2567590085],
              [-85.71437308776, 38.25675779053],
              [-85.71440518673, 38.25676924835],
              [-85.714413591, 38.25675473064],
              [-85.71438381107, 38.2567441006],
              [-85.71439103934, 38.25673161433],
              [-85.71439195563, 38.25673194141],
              [-85.71439376271, 38.25672889672],
              [-85.71439571357, 38.25672557282],
              [-85.71439484435, 38.25672525609],
              [-85.71439601709, 38.25672323029],
              [-85.71438080512, 38.25671780033],
              [-85.71437103304, 38.25673468083],
              [-85.71437472217, 38.25673599768],
              [-85.71436321881, 38.25675586885],
              [-85.71435737363, 38.25675378239],
              [-85.71435127803, 38.25676431206],
              [-85.71435805577, 38.25676673139],
              [-85.71434707514, 38.25678569956],
              [-85.71434222718, 38.25678396906],
              [-85.71432193072, 38.25681902958],
              [-85.71437920745, 38.25683947465],
            ],
            [
              [-85.71435372879, 38.25680312467],
              [-85.71438544433, 38.25681443758],
              [-85.71437559714, 38.2568314599],
              [-85.71434417112, 38.25682025027],
              [-85.71434542451, 38.2568180836],
              [-85.71434635218, 38.2568184145],
              [-85.71434798462, 38.25681558556],
              [-85.71434952244, 38.25681293423],
              [-85.71434830525, 38.25681250006],
              [-85.71435372879, 38.25680312467],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71436776086, 38.25677863749],
            type: 'Point',
          },
        },
      },
      {
        id: 'c67c8db0-2bec-4b92-a6c5-39141eb5b291',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457777332, 38.25685738432],
              [-85.71458200234, 38.25685882798],
              [-85.71458862645, 38.25686108926],
              [-85.71459540594, 38.25684884371],
              [-85.71458854975, 38.2568465178],
              [-85.71458442866, 38.25684509571],
              [-85.71458091825, 38.25684388434],
              [-85.71457365686, 38.2568414192],
              [-85.71456687737, 38.25685366475],
              [-85.7145733223, 38.25685586487],
              [-85.71457777332, 38.25685738432],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714581141655, 38.25685125423],
            type: 'Point',
          },
        },
      },
      {
        id: 'c79e749b-905f-4145-a328-9885fb6e26b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71410939655, 38.2573489926],
              [-85.71411391367, 38.25734098101],
              [-85.71411597108, 38.25733731685],
              [-85.71411795245, 38.25733378811],
              [-85.71413213437, 38.25730861277],
              [-85.71406571881, 38.25728555291],
              [-85.71404298098, 38.25732593275],
              [-85.71410939655, 38.2573489926],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714087557675, 38.257317272755],
            type: 'Point',
          },
        },
      },
      {
        id: 'c85d6f0a-f16c-4bd6-b57c-1ead7fb8cebb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7143955878, 38.25759498857],
              [-85.71438043411, 38.25758949457],
              [-85.71437886414, 38.25759205029],
              [-85.71437677815, 38.2575956086],
              [-85.71437444598, 38.25759958684],
              [-85.71437262626, 38.2576025602],
              [-85.71438773604, 38.25760812767],
              [-85.7143955878, 38.25759498857],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'storage',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Storage',
            frCA: null,
          },
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71438410703, 38.25759881112],
            type: 'Point',
          },
        },
      },
      {
        id: 'c8aa43d2-5c28-47ce-98ba-c8db14e63144',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71418545382, 38.25777789053],
              [-85.71419816974, 38.25775612989],
              [-85.71420066034, 38.25775186774],
              [-85.71420318745, 38.25774754312],
              [-85.71420601617, 38.25774270235],
              [-85.71416042955, 38.25772627716],
              [-85.7141398672, 38.25776146535],
              [-85.71418545382, 38.25777789053],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714172941685, 38.257752083845006],
            type: 'Point',
          },
        },
      },
      {
        id: 'c90ccf70-7dc1-4f60-90a2-f6fbd8a7774d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71427861416, 38.25724284355],
              [-85.71428304151, 38.25724443975],
              [-85.71428712997, 38.2572459331],
              [-85.71429105056, 38.25724736514],
              [-85.71429216954, 38.25724776125],
              [-85.71429492813, 38.25724307269],
              [-85.71431988636, 38.2572521272],
              [-85.71433193331, 38.25723165193],
              [-85.71429341969, 38.25721767972],
              [-85.71427861416, 38.25724284355],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: "Eric's Office",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714305273735, 38.25723490346],
            type: 'Point',
          },
        },
      },
      {
        id: 'c91637e1-8d47-454e-aca4-f57acaac6133',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71475734478, 38.2568826575],
              [-85.71477011171, 38.25685977571],
              [-85.71474137062, 38.25684988773],
              [-85.71474123695, 38.25685017085],
              [-85.71473873507, 38.2568546118],
              [-85.71473615807, 38.25685918606],
              [-85.71472860369, 38.25687276953],
              [-85.71475734478, 38.2568826575],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 311',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.7147493577, 38.256866272615],
            type: 'Point',
          },
        },
      },
      {
        id: 'ca1d116c-293b-49d3-9513-0bb29810c7ee',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71347670302, 38.25831707371],
              [-85.7135155763, 38.25833079212],
              [-85.71351312318, 38.25833507816],
              [-85.71351076195, 38.25833920365],
              [-85.71350741303, 38.25833802182],
              [-85.71349633992, 38.25835736854],
              [-85.71346226653, 38.25834534401],
              [-85.71346449405, 38.25834145213],
              [-85.71346304308, 38.25834094008],
              [-85.71347670302, 38.25831707371],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.713488921415, 38.258337221125004],
            type: 'Point',
          },
        },
      },
      {
        id: 'cb64d2a9-c158-40a6-a0dd-e73047b44b88',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71475808362, 38.2569623819],
              [-85.71476085153, 38.25696335062],
              [-85.71476199732, 38.25696133195],
              [-85.71476574589, 38.25696264388],
              [-85.71476943112, 38.25696393365],
              [-85.71476831436, 38.25696590119],
              [-85.71477041587, 38.25696663667],
              [-85.71476485903, 38.25697631275],
              [-85.71477351242, 38.25697923917],
              [-85.71477173059, 38.25698238639],
              [-85.7147702568, 38.2569849908],
              [-85.71476144576, 38.256982494],
              [-85.71475263992, 38.25699812238],
              [-85.71475066329, 38.25699737657],
              [-85.71474973671, 38.25699900969],
              [-85.71474557744, 38.25699755402],
              [-85.71474122496, 38.25699603074],
              [-85.71474203336, 38.25699460648],
              [-85.71474016438, 38.25699395237],
              [-85.71475808362, 38.2569623819],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7147568384, 38.25697800597],
            type: 'Point',
          },
        },
      },
      {
        id: 'cc3fc769-3257-4d12-bf7c-606d13838aa2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71358243344, 38.25813484608],
              [-85.71358418316, 38.25813549496],
              [-85.71359025065, 38.25813760798],
              [-85.71359574666, 38.25813952198],
              [-85.7136033949, 38.25814225461],
              [-85.71360881949, 38.25814417186],
              [-85.71361404244, 38.25814601784],
              [-85.71372465569, 38.25818510819],
              [-85.71374533952, 38.25819242323],
              [-85.71376228651, 38.25819841671],
              [-85.71377581431, 38.25817479644],
              [-85.7137801547, 38.25817633049],
              [-85.71377871878, 38.25817883889],
              [-85.71378289563, 38.25818026124],
              [-85.71378707767, 38.25818168535],
              [-85.71378824462, 38.25817965167],
              [-85.71379167869, 38.2581808654],
              [-85.71380038969, 38.2581656687],
              [-85.7138025097, 38.2581663505],
              [-85.71380418517, 38.25816344016],
              [-85.71380606008, 38.2581601834],
              [-85.7138039323, 38.25815948845],
              [-85.71382991473, 38.25811416098],
              [-85.71383540793, 38.25811610248],
              [-85.71383519435, 38.25811709523],
              [-85.71383863597, 38.25811830035],
              [-85.71384203868, 38.25811949184],
              [-85.71384262296, 38.25811851696],
              [-85.71386281423, 38.25812578886],
              [-85.71386482206, 38.25812228611],
              [-85.71387516892, 38.25812594307],
              [-85.71387315318, 38.25812945962],
              [-85.71389381011, 38.25813676053],
              [-85.71389249718, 38.25813905099],
              [-85.71390022027, 38.25814178061],
              [-85.71390143196, 38.25813966677],
              [-85.71391010751, 38.25814273303],
              [-85.71391754935, 38.25812975043],
              [-85.71392202635, 38.25813133276],
              [-85.71393846687, 38.25810265156],
              [-85.71399129676, 38.25812132356],
              [-85.71391766086, 38.25824978446],
              [-85.71393084196, 38.25825444313],
              [-85.71392758069, 38.25826013252],
              [-85.71394029994, 38.25826463081],
              [-85.71395132253, 38.25826852905],
              [-85.71395456811, 38.25826286701],
              [-85.7139783158, 38.25827126029],
              [-85.71397498012, 38.25827708549],
              [-85.71398585094, 38.25828075108],
              [-85.71399278704, 38.25828308989],
              [-85.71399572166, 38.2582779755],
              [-85.7140657326, 38.25830271985],
              [-85.71410589787, 38.25823264993],
              [-85.71407508648, 38.25822176009],
              [-85.71408026662, 38.25821272312],
              [-85.71408285873, 38.25821363858],
              [-85.71408423021, 38.25821116589],
              [-85.71408588548, 38.25820818152],
              [-85.71408296709, 38.25820714983],
              [-85.71410168714, 38.25817449193],
              [-85.71413364305, 38.2581857863],
              [-85.71422933481, 38.25801884783],
              [-85.71408006102, 38.25796608897],
              [-85.7140713139, 38.25798134872],
              [-85.71406515357, 38.25797917143],
              [-85.71406145719, 38.25798561994],
              [-85.71405771367, 38.25799215069],
              [-85.71406313743, 38.25799406765],
              [-85.71405851331, 38.25800213465],
              [-85.71407125561, 38.25800663825],
              [-85.71407096414, 38.25800714675],
              [-85.71394764527, 38.25796356131],
              [-85.71393827655, 38.25796025005],
              [-85.7139287974, 38.25795689977],
              [-85.71391850176, 38.25795326091],
              [-85.71394555919, 38.25790605793],
              [-85.71395114859, 38.25789630695],
              [-85.7139568187, 38.25788641517],
              [-85.71396393272, 38.25787400441],
              [-85.71390729631, 38.25785398697],
              [-85.71393391173, 38.25780755503],
              [-85.7137976479, 38.25775939423],
              [-85.71358243344, 38.25813484608],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.713905884125, 38.25803105704],
            type: 'Point',
          },
        },
      },
      {
        id: 'ccbc084c-7217-4d8f-88a0-13a84d3ddb8b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71419075776, 38.25780761212],
              [-85.71419339529, 38.25780855759],
              [-85.71419791485, 38.25781019654],
              [-85.71420241195, 38.25781182734],
              [-85.71421331517, 38.25781575755],
              [-85.71423633467, 38.25777645069],
              [-85.71429413376, 38.25779732179],
              [-85.71429928036, 38.25778853373],
              [-85.7143014209, 38.25778930667],
              [-85.71430359919, 38.25778557027],
              [-85.71430564223, 38.25778209854],
              [-85.71430351329, 38.25778132978],
              [-85.71433485301, 38.25772781572],
              [-85.71433707959, 38.25772861973],
              [-85.71433946417, 38.25772454794],
              [-85.71434182219, 38.25772052152],
              [-85.71433960674, 38.25771972153],
              [-85.71434151438, 38.25771646414],
              [-85.71439359801, 38.25773527141],
              [-85.71441645762, 38.25769623752],
              [-85.71426659231, 38.25764212145],
              [-85.71423536406, 38.25769539914],
              [-85.71423121723, 38.25770252755],
              [-85.71422743898, 38.25770902236],
              [-85.71420456291, 38.25774803967],
              [-85.71420318745, 38.25774754312],
              [-85.71420066034, 38.25775186774],
              [-85.71419816974, 38.25775612989],
              [-85.71419951523, 38.25775661562],
              [-85.71419611414, 38.25776242314],
              [-85.71421353749, 38.25776871467],
              [-85.71419075776, 38.25780761212],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71430360769, 38.2577289395],
            type: 'Point',
          },
        },
      },
      {
        id: 'cd4fc747-7a86-433d-873f-8b6c4645ed4c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71399952263, 38.25803841543],
              [-85.7139751568, 38.25808069806],
              [-85.71398958093, 38.25808582316],
              [-85.71398879992, 38.2580871795],
              [-85.71399299704, 38.25808871119],
              [-85.71399722037, 38.25809025244],
              [-85.71399802965, 38.25808884887],
              [-85.71400068196, 38.25808979128],
              [-85.71400438918, 38.25808335319],
              [-85.7140065776, 38.25807956039],
              [-85.71400880299, 38.2580757035],
              [-85.71402505915, 38.25804748893],
              [-85.71399952263, 38.25803841543],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71400010797501, 38.258064333934996],
            type: 'Point',
          },
        },
      },
      {
        id: 'ce798d77-9749-4b44-a96e-dd05d17151e3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71423097519, 38.25719972937],
              [-85.71422790842, 38.25719866628],
              [-85.71422673629, 38.25720075121],
              [-85.71420931173, 38.25719470096],
              [-85.71420492024, 38.25719320182],
              [-85.71420048501, 38.25719168775],
              [-85.71419846999, 38.25719095274],
              [-85.71420673574, 38.25717625006],
              [-85.71419974013, 38.25717382504],
              [-85.7142129371, 38.25715035094],
              [-85.71425126577, 38.25716363754],
              [-85.71423097519, 38.25719972937],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71422486788, 38.257175551075],
            type: 'Point',
          },
        },
      },
      {
        id: 'ce9fe56c-c483-4a83-a8e9-ca22e4f79f79',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71477109275, 38.25708134393],
              [-85.71479029642, 38.25704820001],
              [-85.71476567389, 38.25703940333],
              [-85.71476399385, 38.25704223576],
              [-85.71476221319, 38.25704537657],
              [-85.71476046497, 38.25704846016],
              [-85.71474647021, 38.25707254726],
              [-85.71477109275, 38.25708134393],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714768383315, 38.25706037363],
            type: 'Point',
          },
        },
      },
      {
        id: 'cf544ca1-ebaf-4a8a-819a-d1fb08fc3ad9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71474437179, 38.25688479632],
              [-85.7147528065, 38.25686961862],
              [-85.71472051278, 38.25685855264],
              [-85.71471207808, 38.25687373034],
              [-85.71472980658, 38.25687981659],
              [-85.71473326769, 38.25688099134],
              [-85.71473674495, 38.25688217156],
              [-85.71474437179, 38.25688479632],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71473244229, 38.256871674479996],
            type: 'Point',
          },
        },
      },
      {
        id: 'cf792762-540e-48ee-a419-371abbe030c8',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71414071034, 38.25733394977],
              [-85.71415901294, 38.25734062382],
              [-85.71414961005, 38.25735652343],
              [-85.71414116539, 38.25735344408],
              [-85.71414040648, 38.25735472735],
              [-85.7141305869, 38.2573511405],
              [-85.71413445615, 38.25734452417],
              [-85.71413817282, 38.25733816874],
              [-85.71414071034, 38.25733394977],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71414479992, 38.2573452366],
            type: 'Point',
          },
        },
      },
      {
        id: 'd034d562-cf29-42cd-863f-8ab674180a4f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71450227199, 38.25744045058],
              [-85.71455083395, 38.25745777008],
              [-85.71457050719, 38.25742375763],
              [-85.71452194524, 38.25740643813],
              [-85.71450736288, 38.25743159051],
              [-85.71450539997, 38.25743504272],
              [-85.71450343598, 38.25743849685],
              [-85.71450227199, 38.25744045058],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71453638959, 38.257432104105],
            type: 'Point',
          },
        },
      },
      {
        id: 'd0900c1b-4eb5-41a8-bd24-495b687c83bb',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71437920745, 38.25683947465],
              [-85.71438318729, 38.25683256253],
              [-85.71439708129, 38.25680843167],
              [-85.71439114168, 38.25680631151],
              [-85.71439455538, 38.25680041462],
              [-85.71438840145, 38.25679821796],
              [-85.71439769488, 38.2567821643],
              [-85.7143656082, 38.25677071087],
              [-85.71436625739, 38.25676969347],
              [-85.71436941849, 38.25676406328],
              [-85.71437232423, 38.2567590085],
              [-85.71437308776, 38.25675779053],
              [-85.71440518673, 38.25676924835],
              [-85.714413591, 38.25675473064],
              [-85.71438381107, 38.2567441006],
              [-85.71439103934, 38.25673161433],
              [-85.71439195563, 38.25673194141],
              [-85.71439376271, 38.25672889672],
              [-85.71439571357, 38.25672557282],
              [-85.71439484435, 38.25672525609],
              [-85.71439601709, 38.25672323029],
              [-85.71438080512, 38.25671780033],
              [-85.71437103304, 38.25673468083],
              [-85.71437472217, 38.25673599768],
              [-85.71436321881, 38.25675586885],
              [-85.71435737363, 38.25675378239],
              [-85.71435127803, 38.25676431206],
              [-85.71435805577, 38.25676673139],
              [-85.71434707514, 38.25678569956],
              [-85.71434222718, 38.25678396906],
              [-85.71432193072, 38.25681902958],
              [-85.71437920745, 38.25683947465],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71436776086, 38.25677863749],
            type: 'Point',
          },
        },
      },
      {
        id: 'd1ed8f24-7ad9-405e-816b-f69ce3840bfa',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71377871878, 38.25817883889],
              [-85.71378289563, 38.25818026124],
              [-85.71378707767, 38.25818168535],
              [-85.71379146861, 38.25818318059],
              [-85.71381610379, 38.25819165565],
              [-85.71383049141, 38.25819659267],
              [-85.71381858101, 38.25821799408],
              [-85.71380437561, 38.25821311959],
              [-85.71377963365, 38.25820460967],
              [-85.7137765621, 38.25820356372],
              [-85.7137720683, 38.25820203345],
              [-85.71376786094, 38.25820060072],
              [-85.71376399224, 38.25819928331],
              [-85.7137758272, 38.25817785422],
              [-85.71377871878, 38.25817883889],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Center Stairwell 6',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.713797241825, 38.25819792415],
            type: 'Point',
          },
        },
      },
      {
        id: 'd258693d-2ce5-4bc8-915f-e46a2af41f10',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71454520206, 38.25731563367],
              [-85.71453387658, 38.25732385315],
              [-85.71453000859, 38.25732670105],
              [-85.71452615269, 38.25732944151],
              [-85.71450153695, 38.25734785058],
              [-85.71454466543, 38.25736312449],
              [-85.71456743114, 38.25732384993],
              [-85.71454520206, 38.25731563367],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714534484045, 38.257339379079994],
            type: 'Point',
          },
        },
      },
      {
        id: 'd2c2146a-ccec-405f-9012-170d90d0bf5d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71460663671, 38.25680371471],
              [-85.71460419037, 38.25680791089],
              [-85.71460177215, 38.25681220333],
              [-85.71459950483, 38.2568162279],
              [-85.71459425395, 38.25682531862],
              [-85.7146363363, 38.25684019145],
              [-85.71464871906, 38.25681858754],
              [-85.71461894684, 38.25680808018],
              [-85.71461386203, 38.25680626812],
              [-85.71460898536, 38.25680453023],
              [-85.71460663671, 38.25680371471],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 305',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714621486505, 38.256821953080006],
            type: 'Point',
          },
        },
      },
      {
        id: 'd3f6fffd-c44f-472e-b2b9-ca9e4064d191',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71408802791, 38.2577503947],
              [-85.71413512319, 38.25776730809],
              [-85.71416105351, 38.25772278843],
              [-85.71415331993, 38.25772001105],
              [-85.71415985175, 38.2577087966],
              [-85.71412049005, 38.25769466057],
              [-85.71411328934, 38.25770702344],
              [-85.71411129309, 38.2577104508],
              [-85.7141093081, 38.25771385882],
              [-85.71408802791, 38.2577503947],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71412472975, 38.25773098433],
            type: 'Point',
          },
        },
      },
      {
        id: 'd407c6e5-ce76-485a-be6f-20d185505e1b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.714753077, 38.25700104447],
              [-85.71480396137, 38.25701894443],
              [-85.71478742915, 38.25704792249],
              [-85.71476117208, 38.25703871278],
              [-85.71475650882, 38.25703704577],
              [-85.71475166599, 38.25703531455],
              [-85.71473654478, 38.25703002253],
              [-85.714753077, 38.25700104447],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71477025307499, 38.25702448348],
            type: 'Point',
          },
        },
      },
      {
        id: 'd42c9050-8c82-4d52-a5e2-4fe9f89bfd7d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71425301961, 38.25725634496],
              [-85.71424227026, 38.25725265057],
              [-85.71423813486, 38.25725119448],
              [-85.71423375982, 38.257249654],
              [-85.71422173313, 38.25724551806],
              [-85.71422018487, 38.25724827672],
              [-85.71421911128, 38.2572479052],
              [-85.71420546211, 38.2572722251],
              [-85.71420461419, 38.25727193167],
              [-85.71420182441, 38.25727690246],
              [-85.71419881781, 38.25728225958],
              [-85.71419971319, 38.25728256943],
              [-85.71419791379, 38.25728577557],
              [-85.71423193279, 38.25729754807],
              [-85.71423268369, 38.25729621012],
              [-85.71423375763, 38.25729658177],
              [-85.71425379213, 38.25726088457],
              [-85.71425101181, 38.25725992242],
              [-85.71425301961, 38.25725634496],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71422777712499, 38.257271533065],
            type: 'Point',
          },
        },
      },
      {
        id: 'd548a622-526a-4fd2-aeab-a54a1ae5e070',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71408457694, 38.25731382097],
              [-85.71410616213, 38.25732163531],
              [-85.71411293046, 38.25731010749],
              [-85.7141148077, 38.25730691018],
              [-85.71411667574, 38.25730372853],
              [-85.71411904044, 38.25729970097],
              [-85.71409745525, 38.25729188662],
              [-85.71408457694, 38.25731382097],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Test Room',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71410180869, 38.257306760965],
            type: 'Point',
          },
        },
      },
      {
        id: 'd60ef518-f40d-4c9b-8f4e-9c0029819e11',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71469891896, 38.25688051213],
              [-85.71469626319, 38.25687955176],
              [-85.7146919043, 38.25687797552],
              [-85.71468739269, 38.25687634405],
              [-85.71466682971, 38.25686890816],
              [-85.71465016943, 38.25689731633],
              [-85.71468225868, 38.25690892029],
              [-85.71469891896, 38.25688051213],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71467454419499, 38.256888914225],
            type: 'Point',
          },
        },
      },
      {
        id: 'd6268573-2911-49c0-b8b0-6a121757300f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7147592486, 38.25699516297],
              [-85.71476572163, 38.25698394723],
              [-85.7147702568, 38.2569849908],
              [-85.71477173059, 38.25698238639],
              [-85.71477324981, 38.25697967175],
              [-85.71476810226, 38.25697789546],
              [-85.71477273968, 38.25696978116],
              [-85.71477419805, 38.25697029561],
              [-85.71477553525, 38.25696795823],
              [-85.71477691525, 38.25696554605],
              [-85.71477546591, 38.25696503478],
              [-85.71478989838, 38.25693980732],
              [-85.71479136625, 38.25694032512],
              [-85.7147930389, 38.25693740137],
              [-85.71479470802, 38.25693448382],
              [-85.71479325266, 38.25693397043],
              [-85.71480616679, 38.25691139697],
              [-85.71480767792, 38.25691193003],
              [-85.71480936975, 38.25690897276],
              [-85.71481114415, 38.25690587117],
              [-85.71480959433, 38.25690532446],
              [-85.71481138972, 38.25690218618],
              [-85.71478082007, 38.25689140256],
              [-85.71478200149, 38.25688933748],
              [-85.7147779837, 38.25688792018],
              [-85.71477357246, 38.25688636409],
              [-85.71477237499, 38.25688845722],
              [-85.71471373071, 38.2568677701],
              [-85.71471492925, 38.25686567509],
              [-85.71471009797, 38.25686397083],
              [-85.71470574134, 38.256862434],
              [-85.71470455486, 38.25686450794],
              [-85.71461948405, 38.2568344987],
              [-85.71462067444, 38.25683241794],
              [-85.71461598625, 38.25683076415],
              [-85.71461155408, 38.25682920067],
              [-85.71461037528, 38.25683126118],
              [-85.71455789923, 38.25681274993],
              [-85.71455907899, 38.25681068774],
              [-85.71455444595, 38.2568090534],
              [-85.7145499819, 38.25680747868],
              [-85.71454881036, 38.2568095265],
              [-85.71450890128, 38.25679544832],
              [-85.71451007418, 38.25679339811],
              [-85.71450601539, 38.25679196635],
              [-85.71450263199, 38.25679077283],
              [-85.71450146475, 38.25679281313],
              [-85.71448000191, 38.25678524198],
              [-85.7144797235, 38.25678572863],
              [-85.71447635586, 38.25678454067],
              [-85.71447415624, 38.25678838555],
              [-85.71447271103, 38.25679091173],
              [-85.71447606598, 38.25679209521],
              [-85.71447046053, 38.25680189337],
              [-85.71449091387, 38.25680910841],
              [-85.71448981365, 38.25681103155],
              [-85.71449335191, 38.2568122797],
              [-85.7144974086, 38.25681371072],
              [-85.71449848618, 38.25681182716],
              [-85.71451968789, 38.2568193062],
              [-85.71458233457, 38.2568414052],
              [-85.71458091825, 38.25684388434],
              [-85.71458442866, 38.25684509571],
              [-85.71458854975, 38.2568465178],
              [-85.71458992496, 38.25684413556],
              [-85.71459912744, 38.25684738179],
              [-85.71458788463, 38.25686703387],
              [-85.7146009464, 38.25687164148],
              [-85.71460527387, 38.25686407721],
              [-85.71460779129, 38.25686496492],
              [-85.71460947834, 38.25686195039],
              [-85.71461109673, 38.25685905853],
              [-85.71460862088, 38.25685818492],
              [-85.7146133982, 38.25684983431],
              [-85.71466088425, 38.2568665853],
              [-85.71466154144, 38.25686543654],
              [-85.71467675941, 38.25687080477],
              [-85.71467610942, 38.25687194093],
              [-85.7146801894, 38.25687338016],
              [-85.71468470434, 38.25687497284],
              [-85.71468531703, 38.25687390188],
              [-85.7147805618, 38.25690750003],
              [-85.71477771249, 38.25691248054],
              [-85.71478447869, 38.25691486736],
              [-85.71475461981, 38.25696705967],
              [-85.71475235021, 38.25696625906],
              [-85.71475114935, 38.25696835813],
              [-85.71475011588, 38.2569701646],
              [-85.71475232076, 38.25697094238],
              [-85.71474195443, 38.25698906236],
              [-85.7147592486, 38.25699516297],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71470607857, 38.25688985182],
            type: 'Point',
          },
        },
      },
      {
        id: 'd72c5c36-a673-43ab-9dbb-0df85732c930',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71462775866, 38.25695730627],
              [-85.71467073636, 38.25697258806],
              [-85.7146376483, 38.25702968949],
              [-85.71463971438, 38.25703040879],
              [-85.71463744437, 38.25703432426],
              [-85.71463523121, 38.25703814165],
              [-85.7146331602, 38.25703743479],
              [-85.71461364362, 38.25707111533],
              [-85.7145341739, 38.25704370295],
              [-85.71453981371, 38.25703425279],
              [-85.71453675462, 38.25703309643],
              [-85.71453761087, 38.25703147062],
              [-85.7145397446, 38.25702785413],
              [-85.71454177631, 38.25702426539],
              [-85.7145616316, 38.25701646911],
              [-85.7145641896, 38.25701847719],
              [-85.7145708081, 38.25701270432],
              [-85.71457768324, 38.25700680654],
              [-85.71458383826, 38.25700152653],
              [-85.71459024846, 38.25699590836],
              [-85.71458790864, 38.25699407157],
              [-85.71458971821, 38.25699171978],
              [-85.71459257393, 38.25699270007],
              [-85.71459277643, 38.25699230612],
              [-85.71459553881, 38.25699329502],
              [-85.71459821208, 38.25699425202],
              [-85.71459803216, 38.25699457373],
              [-85.71460246022, 38.25699614421],
              [-85.71460462025, 38.256992576],
              [-85.71460678972, 38.25699337282],
              [-85.71460987809, 38.25698817721],
              [-85.71461598935, 38.25697777385],
              [-85.7146204223, 38.25697011991],
              [-85.71462184703, 38.25696762516],
              [-85.71462323531, 38.25696519424],
              [-85.71462775866, 38.25695730627],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Training Room',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71461383183001, 38.2570142108],
            type: 'Point',
          },
        },
      },
      {
        id: 'd93f7bfb-53a2-48f4-9b98-64cafade5fd2',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71465032363, 38.25678783058],
              [-85.71466381755, 38.25679253875],
              [-85.71466530699, 38.25678990655],
              [-85.71468420387, 38.25679649986],
              [-85.71468260671, 38.25679932243],
              [-85.71469692783, 38.25680431921],
              [-85.71467098849, 38.25685016019],
              [-85.71466581401, 38.25684835476],
              [-85.71466179231, 38.25684695155],
              [-85.71465684639, 38.25684522587],
              [-85.71462411558, 38.25683380576],
              [-85.71463288012, 38.25681831674],
              [-85.71463493051, 38.25681903215],
              [-85.71463718866, 38.25681504147],
              [-85.71463534736, 38.25681439902],
              [-85.71464635357, 38.25679494844],
              [-85.71464348337, 38.25679394699],
              [-85.71464515363, 38.25679099524],
              [-85.71464682567, 38.25678804036],
              [-85.71464964775, 38.25678902502],
              [-85.71465032363, 38.25678783058],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714660521705, 38.256818995385004],
            type: 'Point',
          },
        },
      },
      {
        id: 'dabebd92-cbad-4896-97ba-6c90214436d6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71427734228, 38.25724209969],
              [-85.71424728326, 38.25723099221],
              [-85.71424368733, 38.25722967876],
              [-85.71424008196, 38.25722836186],
              [-85.71423936369, 38.25722808306],
              [-85.71425421566, 38.25720326985],
              [-85.71429219425, 38.25721728649],
              [-85.71427734228, 38.25724209969],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: "Nick's Office",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71426577897, 38.25722268477],
            type: 'Point',
          },
        },
      },
      {
        id: 'db0cb8d6-caff-4b72-9098-2482c581be31',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7142009458, 38.25740224838],
              [-85.71419515134, 38.25740024698],
              [-85.71419045561, 38.25739862507],
              [-85.71418565287, 38.25739696621],
              [-85.7141723292, 38.25739236422],
              [-85.7141608407, 38.25741287318],
              [-85.7141894573, 38.25742275733],
              [-85.7142009458, 38.25740224838],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71418089325, 38.257407560775],
            type: 'Point',
          },
        },
      },
      {
        id: 'db3a98b9-8433-4f7d-8aa7-a5120391f1d9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71430479427, 38.25727762291],
              [-85.71428449995, 38.25731352297],
              [-85.7142790452, 38.25731162165],
              [-85.71427775932, 38.25731389632],
              [-85.71423447207, 38.25729880796],
              [-85.71425554577, 38.25726152919],
              [-85.71425679524, 38.25726196471],
              [-85.714258398, 38.25725912947],
              [-85.71427648663, 38.25726541948],
              [-85.71428075732, 38.25726692322],
              [-85.7142849505, 38.25726839966],
              [-85.7143009986, 38.2572739785],
              [-85.71429990233, 38.25727591776],
              [-85.71430479427, 38.25727762291],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71426963317, 38.257286512895],
            type: 'Point',
          },
        },
      },
      {
        id: 'ddbc72ae-7239-41f5-8704-c5e7fd64208f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71473457124, 38.25684688533],
              [-85.71474825231, 38.25682252544],
              [-85.71474796444, 38.25682239814],
              [-85.71470904681, 38.2568086652],
              [-85.7146946161, 38.25683304563],
              [-85.71473155725, 38.25684583236],
              [-85.71473457124, 38.25684688533],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 310',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714721434205, 38.256827775264995],
            type: 'Point',
          },
        },
      },
      {
        id: 'ddd944a0-e87a-4e4e-932d-89e635fa7565',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71406998276, 38.25791160178],
              [-85.71407484759, 38.25791345795],
              [-85.71408366713, 38.25791658602],
              [-85.71409208847, 38.25791957286],
              [-85.71409733423, 38.25792156646],
              [-85.71411527612, 38.25789120092],
              [-85.71408792465, 38.25788123624],
              [-85.71406998276, 38.25791160178],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71409262943999, 38.25790140135],
            type: 'Point',
          },
        },
      },
      {
        id: 'de85e1f5-dddf-4f4a-98c4-189446a8c9b0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7146322948, 38.2570426753],
              [-85.71465556906, 38.25705089979],
              [-85.71466047316, 38.2570526529],
              [-85.71466504789, 38.25705428827],
              [-85.71467573704, 38.25705805707],
              [-85.71465918317, 38.25708688485],
              [-85.71461574092, 38.25707150308],
              [-85.7146322948, 38.2570426753],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71464573898, 38.257064780075],
            type: 'Point',
          },
        },
      },
      {
        id: 'deada6e8-23c9-4c6c-84ba-0874de8838dd',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71477116204, 38.25683078846],
              [-85.71483826321, 38.25685466905],
              [-85.71482720864, 38.25687367439],
              [-85.71482866684, 38.25687419759],
              [-85.71482653615, 38.25687781863],
              [-85.71482468409, 38.25688098045],
              [-85.71482326518, 38.25688047144],
              [-85.71480744269, 38.25690767329],
              [-85.7148087555, 38.25690814418],
              [-85.71480701735, 38.25691110778],
              [-85.7148049003, 38.25691469829],
              [-85.71480362941, 38.25691424257],
              [-85.71480017026, 38.25692018949],
              [-85.71480153079, 38.25692067746],
              [-85.71479911589, 38.25692482913],
              [-85.714795399, 38.25692349602],
              [-85.71479419376, 38.25692556805],
              [-85.71479516861, 38.25692591769],
              [-85.71479323523, 38.25692923805],
              [-85.71479129408, 38.25693257175],
              [-85.71479032208, 38.25693222313],
              [-85.71478782683, 38.25693651292],
              [-85.71478891236, 38.25693690231],
              [-85.71478704232, 38.25694013911],
              [-85.71478510403, 38.25694349418],
              [-85.71478400705, 38.25694310073],
              [-85.71477145176, 38.2569646856],
              [-85.71476943112, 38.25696393365],
              [-85.71476574589, 38.25696264388],
              [-85.71476199732, 38.25696133195],
              [-85.71475729039, 38.25695960647],
              [-85.71475583496, 38.25696210863],
              [-85.71475446512, 38.25696161732],
              [-85.71475414692, 38.25696216399],
              [-85.71474930512, 38.25696044616],
              [-85.71474458693, 38.2569587722],
              [-85.71474492287, 38.25695819471],
              [-85.71469342551, 38.25693972463],
              [-85.71469296632, 38.25694051395],
              [-85.71468798893, 38.25693873569],
              [-85.71468321935, 38.25693703168],
              [-85.71468367215, 38.2569362533],
              [-85.7146723023, 38.25693217538],
              [-85.71467523272, 38.25692713745],
              [-85.71467262733, 38.25692620299],
              [-85.71467469097, 38.25692264818],
              [-85.71467694145, 38.2569187715],
              [-85.71467954478, 38.25691970522],
              [-85.7146801276, 38.25691870323],
              [-85.71468912119, 38.25692192887],
              [-85.71469233013, 38.2569164121],
              [-85.71469570389, 38.25691762213],
              [-85.71469739978, 38.25691470657],
              [-85.71468370354, 38.25690979427],
              [-85.71470128287, 38.25687957211],
              [-85.71469711992, 38.25687807902],
              [-85.71469626319, 38.25687955176],
              [-85.7146919043, 38.25687797552],
              [-85.71468739269, 38.25687634405],
              [-85.71468823015, 38.25687490428],
              [-85.71466821229, 38.25686772109],
              [-85.71467153402, 38.25686187754],
              [-85.71467489775, 38.25685596012],
              [-85.71468597725, 38.25683706297],
              [-85.71468740248, 38.25683757414],
              [-85.71468792185, 38.25683668289],
              [-85.71469286233, 38.25683843039],
              [-85.71469764496, 38.25684012205],
              [-85.71469710859, 38.25684104404],
              [-85.71474128575, 38.25685688868],
              [-85.71474172149, 38.25685613979],
              [-85.71474653421, 38.25685785849],
              [-85.71475133025, 38.25685957122],
              [-85.71475088989, 38.25686032877],
              [-85.71475344572, 38.25686124545],
              [-85.71477116204, 38.25683078846],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 109E-109M',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71475323774999, 38.25689773703],
            type: 'Point',
          },
        },
      },
      {
        id: 'dee8766a-88c0-49e1-b7a1-d2e46ca21211',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71480113434, 38.25689731047],
              [-85.71482738461, 38.25685040223],
              [-85.71485425733, 38.25685967488],
              [-85.71484127735, 38.25688286961],
              [-85.71484276218, 38.25688338196],
              [-85.71484017634, 38.25688800277],
              [-85.71483837237, 38.2568912264],
              [-85.71483689691, 38.25689071729],
              [-85.71482801644, 38.25690658636],
              [-85.71482686702, 38.25690620626],
              [-85.71482300663, 38.25690486525],
              [-85.71481748381, 38.25690294675],
              [-85.71480113434, 38.25689731047],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 317',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714827695835, 38.256878494295],
            type: 'Point',
          },
        },
      },
      {
        id: 'df508559-586a-4535-9201-58e46a132fff',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71457777332, 38.25685738432],
              [-85.71458200234, 38.25685882798],
              [-85.71458862645, 38.25686108926],
              [-85.71459540594, 38.25684884371],
              [-85.71458854975, 38.2568465178],
              [-85.71458442866, 38.25684509571],
              [-85.71458091825, 38.25684388434],
              [-85.71457365686, 38.2568414192],
              [-85.71456687737, 38.25685366475],
              [-85.7145733223, 38.25685586487],
              [-85.71457777332, 38.25685738432],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'elevator',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Elevator',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.714581141655, 38.25685125423],
            type: 'Point',
          },
        },
      },
      {
        id: 'e01f0f5e-c905-4afc-b0f4-dd4cadef52cc',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71432952797, 38.2573326115],
              [-85.7143530667, 38.2572922557],
              [-85.71438423105, 38.25730346394],
              [-85.71438922906, 38.25730526239],
              [-85.71439481898, 38.25730727189],
              [-85.71440106061, 38.25730951668],
              [-85.71439669642, 38.25731699883],
              [-85.71439475641, 38.25732032484],
              [-85.71439283257, 38.25732362319],
              [-85.71437752189, 38.25734987248],
              [-85.71432952797, 38.2573326115],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71436529428999, 38.25732106409],
            type: 'Point',
          },
        },
      },
      {
        id: 'e1d355d0-3ece-4ac8-8a66-79ac38cbee87',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71420052434, 38.25723931942],
              [-85.71419895458, 38.25723877594],
              [-85.714199565, 38.2572376888],
              [-85.7141948581, 38.257236057],
              [-85.7141909191, 38.25723469544],
              [-85.71419030907, 38.25723578189],
              [-85.71418322034, 38.25723332765],
              [-85.71417345048, 38.25725072742],
              [-85.71417234463, 38.25725034455],
              [-85.71417071162, 38.25725325289],
              [-85.71416910963, 38.25725610598],
              [-85.71417023765, 38.25725649651],
              [-85.71415241141, 38.25728824439],
              [-85.71414480804, 38.25728561196],
              [-85.71414335285, 38.2572882105],
              [-85.71414185092, 38.25729089252],
              [-85.71414376562, 38.25729155543],
              [-85.71411971046, 38.25733439676],
              [-85.71411795245, 38.25733378811],
              [-85.71411597108, 38.25733731685],
              [-85.71411391367, 38.25734098101],
              [-85.71411569349, 38.25734159722],
              [-85.71411123141, 38.25734954402],
              [-85.71412871465, 38.25735559701],
              [-85.71413340723, 38.25735722166],
              [-85.71413790376, 38.25735877843],
              [-85.71416569498, 38.2573684002],
              [-85.71417357102, 38.25735437327],
              [-85.71416957564, 38.25735299],
              [-85.71416977903, 38.25735256098],
              [-85.71417191437, 38.25734882948],
              [-85.71417378235, 38.25734556494],
              [-85.7141826814, 38.25732964914],
              [-85.71421433116, 38.25734060681],
              [-85.71421694449, 38.25733595257],
              [-85.71426240666, 38.25735169231],
              [-85.71428172614, 38.25731728506],
              [-85.71419496656, 38.25728724745],
              [-85.7141959071, 38.25728557239],
              [-85.71418402128, 38.25728145732],
              [-85.71419322706, 38.25726506215],
              [-85.71418724795, 38.25726299208],
              [-85.71419190683, 38.25725469479],
              [-85.71419300346, 38.25725507446],
              [-85.714195183, 38.25725119278],
              [-85.71419758579, 38.25724691349],
              [-85.71419647605, 38.25724652928],
              [-85.71420052434, 38.25723931942],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 136C-136I',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714196478775, 38.257300863924996],
            type: 'Point',
          },
        },
      },
      {
        id: 'e38ec9ed-0ad5-4d0e-8f74-11ec36acc454',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71426143466, 38.25764604828],
              [-85.71427809327, 38.25761745772],
              [-85.71428005569, 38.25761407037],
              [-85.71428198839, 38.25761073431],
              [-85.71428417988, 38.25760698799],
              [-85.71419026566, 38.25757326818],
              [-85.71418823632, 38.25757670968],
              [-85.71418623003, 38.25758019756],
              [-85.7141840862, 38.25758392454],
              [-85.71416752043, 38.2576123285],
              [-85.71426143466, 38.25764604828],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'conferenceroom',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714225850155, 38.25760965823],
            type: 'Point',
          },
        },
      },
      {
        id: 'e6d77cb2-bdd8-4a68-9a4d-7f8d800522d9',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71474599919, 38.25707407775],
              [-85.71477013311, 38.25708248809],
              [-85.71476192656, 38.25709700857],
              [-85.71473779263, 38.25708859824],
              [-85.71474029264, 38.25708416563],
              [-85.7147419272, 38.25708128251],
              [-85.71474362181, 38.25707829349],
              [-85.71474599919, 38.25707407775],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71475396286999, 38.25708554316],
            type: 'Point',
          },
        },
      },
      {
        id: 'e6f2b03e-fd54-46a9-b08a-9a9f19db492e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71426602542, 38.25729419217],
              [-85.71427449209, 38.25729721503],
              [-85.71427657034, 38.25729362586],
              [-85.71429216002, 38.25729919185],
              [-85.71430404098, 38.25727867319],
              [-85.71428002097, 38.25727009696],
              [-85.7142781208, 38.25727330465],
              [-85.71427610842, 38.25727670178],
              [-85.71426602542, 38.25729419217],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Room Gr39H',
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7142850332, 38.257284644405004],
            type: 'Point',
          },
        },
      },
      {
        id: 'e6ff4062-4c67-43ca-879b-394f38090ef0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71460516189, 38.25680174064],
              [-85.71461931046, 38.25677707041],
              [-85.71460656165, 38.25677256206],
              [-85.71460738712, 38.25677112273],
              [-85.7145922494, 38.25676576959],
              [-85.71459138354, 38.25676727937],
              [-85.71457843647, 38.2567627009],
              [-85.7145643283, 38.25678730069],
              [-85.71459362071, 38.25679768972],
              [-85.71459848611, 38.25679937985],
              [-85.7146033354, 38.25680106438],
              [-85.71460516189, 38.25680174064],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 304',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71459181937999, 38.25678222077],
            type: 'Point',
          },
        },
      },
      {
        id: 'e78c5480-cb5c-40a1-a0a3-8a48813e41b0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71454177631, 38.25702426539],
              [-85.7145397446, 38.25702785413],
              [-85.71453761087, 38.25703147062],
              [-85.71453319297, 38.25703222571],
              [-85.71452853114, 38.25703063149],
              [-85.71453526172, 38.25701887383],
              [-85.71453969699, 38.25702055438],
              [-85.71454177631, 38.25702426539],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714535153725, 38.25702554977],
            type: 'Point',
          },
        },
      },
      {
        id: 'e7b2cc13-2580-4266-abc6-01b2bad09a08',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71435392066, 38.25680524103],
              [-85.71438305319, 38.25681554258],
              [-85.71437479019, 38.25682995119],
              [-85.71434565766, 38.25681964964],
              [-85.71434635218, 38.2568184145],
              [-85.71434798462, 38.25681558556],
              [-85.71434952244, 38.25681293423],
              [-85.71435392066, 38.25680524103],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714364355425, 38.25681759611],
            type: 'Point',
          },
        },
      },
      {
        id: 'e7d597ae-7064-4340-b91d-b91bbbae2ec0',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71461109673, 38.25685905853],
              [-85.71460947834, 38.25686195039],
              [-85.71460779129, 38.25686496492],
              [-85.71460323179, 38.25687331996],
              [-85.71460478478, 38.25687384603],
              [-85.71460412665, 38.256875044],
              [-85.71463496873, 38.25688549163],
              [-85.71463852805, 38.25688669734],
              [-85.71464243218, 38.25688802175],
              [-85.71465180118, 38.25689118409],
              [-85.71466148827, 38.2568734877],
              [-85.71464740608, 38.25686873449],
              [-85.71461229054, 38.25685683069],
              [-85.71461109673, 38.25685905853],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Center Stairwell 1',
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71463236003, 38.256874007389996],
            type: 'Point',
          },
        },
      },
      {
        id: 'e8072ee8-27df-4093-9fea-1605b6ae8b9a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71420265173, 38.25745517449],
              [-85.71422976156, 38.25740721755],
              [-85.71423168389, 38.25740390809],
              [-85.71423354513, 38.2574007038],
              [-85.71423650615, 38.25739539165],
              [-85.71413687132, 38.25736060188],
              [-85.71413790376, 38.25735877843],
              [-85.71413340723, 38.25735722166],
              [-85.71412871465, 38.25735559701],
              [-85.71412761316, 38.25735754221],
              [-85.71404187223, 38.25732759581],
              [-85.71402049277, 38.2573651696],
              [-85.71404261746, 38.25737289644],
              [-85.71405522504, 38.25735063295],
              [-85.71407351195, 38.25735701824],
              [-85.71407297453, 38.25735796783],
              [-85.71407759774, 38.25735955355],
              [-85.71408234885, 38.25736118505],
              [-85.71408291835, 38.2573601796],
              [-85.71410204171, 38.25736685695],
              [-85.71410148134, 38.25736784704],
              [-85.71410615002, 38.25736944508],
              [-85.71411076591, 38.25737102505],
              [-85.71411135667, 38.25736998234],
              [-85.71413171981, 38.25737709259],
              [-85.71413114653, 38.25737810599],
              [-85.71413587954, 38.25737971312],
              [-85.71414055586, 38.25738130101],
              [-85.71414114585, 38.25738026019],
              [-85.71415847684, 38.2573863117],
              [-85.7141578894, 38.25738734968],
              [-85.71416273696, 38.25738900663],
              [-85.7141675761, 38.2573906607],
              [-85.7141682137, 38.2573895354],
              [-85.714186287, 38.2573958461],
              [-85.71418565287, 38.25739696621],
              [-85.71419045561, 38.25739862507],
              [-85.71419515134, 38.25740024698],
              [-85.71419583719, 38.25739903586],
              [-85.71420271007, 38.25740143568],
              [-85.71418378867, 38.25743484861],
              [-85.71418286421, 38.25743452616],
              [-85.71418096326, 38.25743795789],
              [-85.71417901187, 38.25744148067],
              [-85.7141800105, 38.25744182957],
              [-85.71417739757, 38.2574464437],
              [-85.71418649678, 38.2574496209],
              [-85.71418592511, 38.25745063042],
              [-85.71418961089, 38.25745192128],
              [-85.71419416928, 38.25745352005],
              [-85.71419478742, 38.2574524285],
              [-85.71420265173, 38.25745517449],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71417271681, 38.25739138515],
            type: 'Point',
          },
        },
      },
      {
        id: 'e8a12e2f-76d6-4bdd-98cb-72caee307b23',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71463095712, 38.25683823201],
              [-85.71462954177, 38.25684061296],
              [-85.71462569986, 38.25684748097],
              [-85.71462190491, 38.25685426505],
              [-85.71462012832, 38.2568572819],
              [-85.71465249093, 38.2568686252],
              [-85.71465383332, 38.25686626368],
              [-85.71465981172, 38.25686835915],
              [-85.71466150984, 38.25686537184],
              [-85.71466821229, 38.25686772109],
              [-85.71467153402, 38.25686187754],
              [-85.71467489775, 38.25685596012],
              [-85.71466553901, 38.25685267983],
              [-85.7146666419, 38.25685073964],
              [-85.71466482113, 38.25685010145],
              [-85.71466581401, 38.25684835476],
              [-85.71466179231, 38.25684695155],
              [-85.71465684639, 38.25684522587],
              [-85.71465586022, 38.25684696071],
              [-85.71463095712, 38.25683823201],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714647513035, 38.256853428605],
            type: 'Point',
          },
        },
      },
      {
        id: 'e963e028-ce56-411d-af9c-e5b7a77583ac',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71380606008, 38.2581601834],
              [-85.7138126412, 38.25814856183],
              [-85.71382791592, 38.25815398385],
              [-85.71381547889, 38.25817577727],
              [-85.71380020417, 38.25817035525],
              [-85.7138025097, 38.2581663505],
              [-85.71380418517, 38.25816344016],
              [-85.71380606008, 38.2581601834],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.713814060045, 38.25816216955],
            type: 'Point',
          },
        },
      },
      {
        id: 'ecd301ed-0f81-44ac-8596-d0ef6b2f4682',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71350008024, 38.25835798379],
              [-85.71354898561, 38.25837515458],
              [-85.7135471435, 38.25837838985],
              [-85.71355641586, 38.25838166911],
              [-85.7135656652, 38.25838494022],
              [-85.71356782011, 38.25838115602],
              [-85.71359907524, 38.25839212976],
              [-85.7137166475, 38.25818584335],
              [-85.71361223904, 38.25814918519],
              [-85.71361404244, 38.25814601784],
              [-85.71360881949, 38.25814417186],
              [-85.7136033949, 38.25814225461],
              [-85.7136015791, 38.25814544353],
              [-85.7135969888, 38.25814383186],
              [-85.71357071782, 38.25818945941],
              [-85.71358098125, 38.25819306293],
              [-85.7135765581, 38.25820115258],
              [-85.71357397735, 38.2582002546],
              [-85.71357207259, 38.25820362701],
              [-85.7135701148, 38.25820709329],
              [-85.71357238872, 38.25820791116],
              [-85.71355144823, 38.25824461319],
              [-85.71352418766, 38.25823499216],
              [-85.71347797478, 38.25831509856],
              [-85.71351681497, 38.25832859545],
              [-85.7135155763, 38.25833079212],
              [-85.71351312318, 38.25833507816],
              [-85.71351076195, 38.25833920365],
              [-85.71350008024, 38.25835798379],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71359731114, 38.258267192185],
            type: 'Point',
          },
        },
      },
      {
        id: 'ecea6bc2-68e9-48d3-9959-26228656e75c',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7146776684, 38.25696240412],
              [-85.71466045508, 38.25695659185],
              [-85.7146697769, 38.25693956923],
              [-85.71467389596, 38.25693204739],
              [-85.71467840073, 38.25693355875],
              [-85.71468151445, 38.25693463322],
              [-85.714709889, 38.25694420085],
              [-85.71470269742, 38.25695733343],
              [-85.7146839177, 38.25695099225],
              [-85.7146776684, 38.25696240412],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.714694710635, 38.256947225755],
            type: 'Point',
          },
        },
      },
      {
        id: 'eeabef0e-1821-487a-b0e4-7795b6a2095e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71460742703, 38.25676979255],
              [-85.71459249868, 38.25679616663],
              [-85.71462474123, 38.25680741974],
              [-85.71462826611, 38.25680865376],
              [-85.71463310206, 38.25681033778],
              [-85.71463704808, 38.256811715],
              [-85.71465195492, 38.25678537891],
              [-85.71463872812, 38.25678076257],
              [-85.71464024021, 38.25677809731],
              [-85.71462187598, 38.25677178031],
              [-85.71462042853, 38.25677433026],
              [-85.71460742703, 38.25676979255],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Public Affairs',
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.7146222268, 38.256790753775],
            type: 'Point',
          },
        },
      },
      {
        id: 'f0006594-2259-4a5f-bfdb-1280512dd51d',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71443818435, 38.25701194203],
              [-85.71447358232, 38.25702475879],
              [-85.71447687655, 38.25701945944],
              [-85.71448549047, 38.25702266068],
              [-85.71449449222, 38.25702600606],
              [-85.7144911758, 38.257031345],
              [-85.71450114265, 38.25703495376],
              [-85.71445048971, 38.25712121397],
              [-85.71445245109, 38.25712192464],
              [-85.71444893643, 38.25712804191],
              [-85.71444536915, 38.25713425076],
              [-85.71444215505, 38.25713308753],
              [-85.71443498408, 38.25714529943],
              [-85.71443096708, 38.25714384497],
              [-85.71443033856, 38.25714491531],
              [-85.7144280902, 38.25714410123],
              [-85.71442726668, 38.25714550365],
              [-85.7144232129, 38.25714403588],
              [-85.71441907521, 38.25714253772],
              [-85.71441992046, 38.25714109829],
              [-85.71441388795, 38.25713891406],
              [-85.714412451, 38.25714135616],
              [-85.71440385138, 38.25713825523],
              [-85.71439533261, 38.25713518346],
              [-85.71439657544, 38.25713306693],
              [-85.71439312668, 38.25713181822],
              [-85.71440656714, 38.25710892959],
              [-85.71440471626, 38.25710825944],
              [-85.71440873494, 38.25710141577],
              [-85.71441251164, 38.25709498418],
              [-85.71441477982, 38.25709580544],
              [-85.71443689153, 38.25705814996],
              [-85.71441878429, 38.25705159376],
              [-85.7144202579, 38.25704908425],
              [-85.71441705502, 38.25704792457],
              [-85.71443818435, 38.25701194203],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714447134665, 38.25707872284],
            type: 'Point',
          },
        },
      },
      {
        id: 'f05c5432-2165-4222-8807-b23eb40477b6',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71476095892, 38.25697108871],
              [-85.71476388065, 38.25696590255],
              [-85.71476232307, 38.25696536149],
              [-85.71476428969, 38.25696187067],
              [-85.71476586108, 38.25695908139],
              [-85.71476709597, 38.25695951036],
              [-85.71476788885, 38.25695810297],
              [-85.71478320922, 38.25696342491],
              [-85.71480734226, 38.25692058795],
              [-85.71480142198, 38.25691853139],
              [-85.71480278794, 38.25691610676],
              [-85.71475686247, 38.25690015333],
              [-85.71475388502, 38.25690543839],
              [-85.71475525808, 38.25690591536],
              [-85.71475365233, 38.25690876562],
              [-85.71475199076, 38.25691171496],
              [-85.71475032403, 38.25691113598],
              [-85.71473540997, 38.25693760895],
              [-85.71473301215, 38.25693677601],
              [-85.71473241409, 38.25693783679],
              [-85.71472849798, 38.25693651194],
              [-85.7147244806, 38.25693515283],
              [-85.71472540994, 38.25693350244],
              [-85.71471019803, 38.25692821818],
              [-85.71471071752, 38.25692729606],
              [-85.7147095057, 38.25692687444],
              [-85.71471113299, 38.25692389149],
              [-85.71471247154, 38.25692143781],
              [-85.71471372217, 38.25692187202],
              [-85.71471453178, 38.25692043493],
              [-85.71472909347, 38.25692549332],
              [-85.71474563352, 38.25689613415],
              [-85.71471497593, 38.25688548441],
              [-85.7147142723, 38.25688673339],
              [-85.71471126667, 38.2568856893],
              [-85.71470798611, 38.25688454971],
              [-85.71470888287, 38.25688295794],
              [-85.71467926942, 38.25687267092],
              [-85.71467312201, 38.25687053545],
              [-85.71467225279, 38.25687207836],
              [-85.71466836137, 38.25687072657],
              [-85.71466758628, 38.25687210239],
              [-85.71461286794, 38.2568530945],
              [-85.71460976933, 38.25685859465],
              [-85.71461109673, 38.25685905853],
              [-85.71460947834, 38.25686195039],
              [-85.71460779129, 38.25686496492],
              [-85.71460672933, 38.25686459398],
              [-85.71460213224, 38.25687275398],
              [-85.71459233286, 38.2568693499],
              [-85.71460248061, 38.25685133729],
              [-85.71460340638, 38.25684969402],
              [-85.71458949069, 38.25684486003],
              [-85.71458854975, 38.2568465178],
              [-85.71458442866, 38.25684509571],
              [-85.71458091825, 38.25684388434],
              [-85.71458173566, 38.25684243012],
              [-85.71457480898, 38.25684002395],
              [-85.7145386269, 38.25682745513],
              [-85.71454362251, 38.25681858773],
              [-85.71454129951, 38.2568177807],
              [-85.71454291975, 38.25681498016],
              [-85.71454463458, 38.25681178328],
              [-85.71454651387, 38.25681243601],
              [-85.71454745767, 38.25681076072],
              [-85.71458017247, 38.25682212509],
              [-85.71458665313, 38.25681062168],
              [-85.71458449908, 38.2568098735],
              [-85.71458618257, 38.25680692782],
              [-85.71459127014, 38.25679802582],
              [-85.7145930258, 38.25679874571],
              [-85.71459362071, 38.25679768972],
              [-85.71459848611, 38.25679937985],
              [-85.7146033354, 38.25680106438],
              [-85.71460269028, 38.25680220948],
              [-85.71460431155, 38.25680277267],
              [-85.71460187078, 38.25680710512],
              [-85.71460419037, 38.25680791089],
              [-85.71460177215, 38.25681220333],
              [-85.71459950483, 38.2568162279],
              [-85.71459639479, 38.25681514754],
              [-85.71459024446, 38.2568260646],
              [-85.71467067818, 38.25685400542],
              [-85.71467147849, 38.25685258483],
              [-85.7146746951, 38.25685370221],
              [-85.71467834851, 38.25685497132],
              [-85.71467757822, 38.25685633861],
              [-85.71471603683, 38.25686969824],
              [-85.71472431963, 38.25685499597],
              [-85.71472133341, 38.25685395863],
              [-85.714723884, 38.25684943125],
              [-85.71472616651, 38.2568453797],
              [-85.71472860694, 38.25684622745],
              [-85.71472927597, 38.2568450399],
              [-85.71473155725, 38.25684583236],
              [-85.71473410019, 38.25684671572],
              [-85.71473358379, 38.25684763235],
              [-85.71473614989, 38.25684852375],
              [-85.71473679423, 38.25684738004],
              [-85.71473904386, 38.25684816151],
              [-85.71474140516, 38.25684898177],
              [-85.71474081738, 38.2568500251],
              [-85.71474123695, 38.25685017085],
              [-85.71473873507, 38.2568546118],
              [-85.71473615807, 38.25685918606],
              [-85.71473356761, 38.2568582862],
              [-85.71472530753, 38.25687294814],
              [-85.7147853042, 38.25689378958],
              [-85.7147860025, 38.25689255006],
              [-85.71478993839, 38.25689391729],
              [-85.71479376029, 38.25689524494],
              [-85.71479325273, 38.25689614587],
              [-85.71481672221, 38.25690429862],
              [-85.71481748381, 38.25690294675],
              [-85.71482300663, 38.25690486525],
              [-85.71482686702, 38.25690620626],
              [-85.71482633166, 38.25690715654],
              [-85.71482785907, 38.25690768713],
              [-85.71482375671, 38.25691496895],
              [-85.71482543218, 38.25691555097],
              [-85.71482396101, 38.25691816236],
              [-85.71482222601, 38.25692124202],
              [-85.71482076083, 38.25692073305],
              [-85.71481838709, 38.25692494653],
              [-85.71481964835, 38.25692538466],
              [-85.7148177944, 38.25692867548],
              [-85.71481619316, 38.25693151773],
              [-85.71481515677, 38.25693115771],
              [-85.7148025629, 38.25695351225],
              [-85.71480408862, 38.25695404225],
              [-85.71480281132, 38.25695630949],
              [-85.71480178528, 38.25695813075],
              [-85.71480050268, 38.25695768521],
              [-85.71478933337, 38.25697751111],
              [-85.71478693486, 38.25697667792],
              [-85.71478528314, 38.25698040503],
              [-85.71478118323, 38.25697897436],
              [-85.71477707635, 38.25697754126],
              [-85.71477830751, 38.2569745606],
              [-85.71477187513, 38.25697232614],
              [-85.71477016878, 38.25697535497],
              [-85.71476596815, 38.25697389577],
              [-85.71476182021, 38.25697245488],
              [-85.71476232293, 38.25697156253],
              [-85.71476095892, 38.25697108871],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714736501415, 38.256889047375],
            type: 'Point',
          },
        },
      },
      {
        id: 'f0c7cf48-99c1-43c3-b0ff-6aa2dce61522',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71433401656, 38.25751940117],
              [-85.71432122375, 38.25751485632],
              [-85.71430487094, 38.25750904673],
              [-85.7143098811, 38.25750033569],
              [-85.7143102373, 38.25749971639],
              [-85.71429455211, 38.25749415382],
              [-85.71429479076, 38.25749375304],
              [-85.71429728743, 38.25748940644],
              [-85.71429985655, 38.25748494535],
              [-85.71430032345, 38.25748413714],
              [-85.71431588206, 38.25748966459],
              [-85.71433237076, 38.25749552246],
              [-85.71434515747, 38.25750006514],
              [-85.71433401656, 38.25751940117],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'stairs',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71431985479, 38.257501769155],
            type: 'Point',
          },
        },
      },
      {
        id: 'f1009b8c-499c-488e-978f-cb89455df024',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71409808848, 38.25743755261],
              [-85.71412935506, 38.25744862891],
              [-85.71413354408, 38.25745013205],
              [-85.71413760084, 38.25745158773],
              [-85.71414421458, 38.25745391765],
              [-85.71416469937, 38.25741831666],
              [-85.71411864369, 38.25740197659],
              [-85.71411816014, 38.25740281696],
              [-85.71411427511, 38.25740143859],
              [-85.71411220774, 38.25740503153],
              [-85.71411015669, 38.25740859609],
              [-85.71411397131, 38.25740994947],
              [-85.71409808848, 38.25743755261],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom Vestibule",
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714131393925, 38.257427678119996],
            type: 'Point',
          },
        },
      },
      {
        id: 'f1598ae5-83e0-411c-b62c-6d1b003d8e63',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71440262942, 38.25731037396],
              [-85.71439837787, 38.25731760812],
              [-85.71439669642, 38.25731699883],
              [-85.71439475641, 38.25732032484],
              [-85.71439283257, 38.25732362319],
              [-85.71439448151, 38.25732422077],
              [-85.71437924722, 38.25735014244],
              [-85.71442379689, 38.25736628624],
              [-85.7144447158, 38.25733069199],
              [-85.71444185926, 38.25732965684],
              [-85.71444431423, 38.25732547961],
              [-85.71441848071, 38.25731611812],
              [-85.71441446493, 38.25731466407],
              [-85.71441079173, 38.2573133318],
              [-85.71440262942, 38.25731037396],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71441198151, 38.2573383301],
            type: 'Point',
          },
        },
      },
      {
        id: 'f2602946-9666-4195-9ae1-2e1b5bceca2e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71456786469, 38.25698466951],
              [-85.71458364821, 38.25699022292],
              [-85.71458064204, 38.25699524138],
              [-85.7145830952, 38.25699716715],
              [-85.71458049057, 38.25699947851],
              [-85.71458383826, 38.25700152653],
              [-85.71457768324, 38.25700680654],
              [-85.7145708081, 38.25701270432],
              [-85.71456792138, 38.25701063247],
              [-85.71456577531, 38.2570125369],
              [-85.71456236557, 38.25701097032],
              [-85.71455754443, 38.25701358789],
              [-85.71455873905, 38.2570155156],
              [-85.71454208071, 38.25702187556],
              [-85.71454071108, 38.25702008368],
              [-85.71455012632, 38.25700315675],
              [-85.71454229845, 38.25700042177],
              [-85.71454341765, 38.25699825975],
              [-85.71454726071, 38.25699173928],
              [-85.7145508994, 38.25698515055],
              [-85.71455379667, 38.25697974212],
              [-85.71455629934, 38.25698057954],
              [-85.71456205989, 38.2569826167],
              [-85.71456786469, 38.25698466951],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71456277154, 38.257000808840004],
            type: 'Point',
          },
        },
      },
      {
        id: 'f32e7b5f-d54b-49a5-bb82-0949ebaaf30e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71470579643, 38.25695802447],
              [-85.71471305205, 38.25694482592],
              [-85.7147330354, 38.25695158097],
              [-85.71473618549, 38.256952668],
              [-85.71473901346, 38.25695364386],
              [-85.71474586897, 38.2569559497],
              [-85.71473162317, 38.25698186396],
              [-85.71471481553, 38.25697616676],
              [-85.71472150321, 38.25696400135],
              [-85.71470549392, 38.25695857476],
              [-85.71470579643, 38.25695802447],
              [-85.71470579643, 38.25695802447],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.male',
          restriction: null,
          accessibility: null,
          name: {
            en: "Men's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71472734995, 38.256963344940004],
            type: 'Point',
          },
        },
      },
      {
        id: 'f3ea4f73-f1eb-456c-af5a-4372b2076a8a',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71426221359, 38.25795469384],
              [-85.71419686543, 38.25807145794],
              [-85.71412249992, 38.25804580103],
              [-85.71415145189, 38.25799399878],
              [-85.71415546565, 38.25798691165],
              [-85.7141591601, 38.25798038833],
              [-85.71418784809, 38.25792903689],
              [-85.71426221359, 38.25795469384],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'storage',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714192356755, 38.258000247415],
            type: 'Point',
          },
        },
      },
      {
        id: 'f3f1e630-2276-4313-8d6e-26c6f588d5a1',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71415954101, 38.25727319023],
              [-85.7141519336, 38.25728677483],
              [-85.71414541694, 38.25728452464],
              [-85.71414480804, 38.25728561196],
              [-85.71414335285, 38.2572882105],
              [-85.71414185092, 38.25729089252],
              [-85.71413308078, 38.25730655341],
              [-85.7140671195, 38.25728377718],
              [-85.71408765576, 38.25724853613],
              [-85.71415954101, 38.25727319023],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Cubicles 136J-136O',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714113330255, 38.25727754477],
            type: 'Point',
          },
        },
      },
      {
        id: 'f416a52c-ce76-4cb7-8742-e570190297b7',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71419705941, 38.25728522365],
              [-85.71419881781, 38.25728225958],
              [-85.71420182441, 38.25727690246],
              [-85.71420461419, 38.25727193167],
              [-85.71420609533, 38.25726944905],
              [-85.71419427383, 38.25726527373],
              [-85.7141852379, 38.25728104834],
              [-85.71419705941, 38.25728522365],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714195666615, 38.25727524869],
            type: 'Point',
          },
        },
      },
      {
        id: 'f45c6edc-9cf4-45bb-ae09-0bc57d1c8213',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71484798049, 38.25694119521],
              [-85.71484373189, 38.25693968172],
              [-85.71484513493, 38.2569372532],
              [-85.71481355369, 38.25692600295],
              [-85.71480515452, 38.25694054114],
              [-85.71478994111, 38.25693512164],
              [-85.71478891236, 38.25693690231],
              [-85.71478704232, 38.25694013911],
              [-85.71478510403, 38.25694349418],
              [-85.71478409243, 38.25694524516],
              [-85.71478757133, 38.25694648446],
              [-85.71478547361, 38.25695011543],
              [-85.71478648676, 38.25695047634],
              [-85.71478575291, 38.25695174963],
              [-85.71479012861, 38.25695323993],
              [-85.71479438471, 38.2569546895],
              [-85.71479506215, 38.25695351882],
              [-85.71483180617, 38.25696660822],
              [-85.71483389372, 38.25696299485],
              [-85.71483513142, 38.25696343576],
              [-85.71483569722, 38.25696245641],
              [-85.71484798049, 38.25694119521],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71482767785501, 38.256946305585004],
            type: 'Point',
          },
        },
      },
      {
        id: 'f528cfee-0505-42d9-a16f-941ecbdab8de',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71464252561, 38.25684141037],
              [-85.7146471079, 38.25684299162],
              [-85.71465145246, 38.25684449082],
              [-85.71465065531, 38.25684591522],
              [-85.7146820017, 38.25685673215],
              [-85.71468290593, 38.2568551164],
              [-85.71468739458, 38.25685666533],
              [-85.71469251046, 38.25685843071],
              [-85.71469157152, 38.25686010847],
              [-85.71470187297, 38.25686366326],
              [-85.71470054673, 38.25686603308],
              [-85.71470271861, 38.25686678254],
              [-85.7147002839, 38.25687113305],
              [-85.71469760347, 38.2568759226],
              [-85.71469553982, 38.25687521048],
              [-85.71467163023, 38.25691793365],
              [-85.71476570356, 38.25695039618],
              [-85.71476670275, 38.25694861077],
              [-85.71477159842, 38.25695030015],
              [-85.71477570172, 38.2569517161],
              [-85.71477477753, 38.25695336749],
              [-85.7147801848, 38.25695523342],
              [-85.71477891966, 38.25695749405],
              [-85.71478061777, 38.25695808003],
              [-85.71477878309, 38.25696135835],
              [-85.71477737826, 38.25696386859],
              [-85.71477618307, 38.25696345616],
              [-85.71476810226, 38.25697789546],
              [-85.71477324981, 38.25697967175],
              [-85.71477173059, 38.25698238639],
              [-85.7147702568, 38.2569849908],
              [-85.71476651488, 38.25698369961],
              [-85.71475820274, 38.25699855226],
              [-85.7147564235, 38.25699793828],
              [-85.71475575463, 38.25699913347],
              [-85.71475066329, 38.25699737657],
              [-85.71474582911, 38.25699570841],
              [-85.71474649773, 38.25699451368],
              [-85.71474482186, 38.25699393537],
              [-85.71475827816, 38.25696989083],
              [-85.71475383065, 38.25696835609],
              [-85.71475292775, 38.25696996945],
              [-85.71474948534, 38.25696878155],
              [-85.71474569785, 38.25696747458],
              [-85.71474650492, 38.25696603245],
              [-85.71474545287, 38.25696566941],
              [-85.71475086342, 38.2569560015],
              [-85.71473982564, 38.25695219262],
              [-85.71473901346, 38.25695364386],
              [-85.71473618549, 38.256952668],
              [-85.7147330354, 38.25695158097],
              [-85.71473372188, 38.25695035433],
              [-85.7146857548, 38.256933802],
              [-85.71468467857, 38.25693572509],
              [-85.71468151445, 38.25693463322],
              [-85.71467840073, 38.25693355875],
              [-85.71467936459, 38.25693183646],
              [-85.71466979633, 38.25692853468],
              [-85.71465305385, 38.25695845119],
              [-85.71464848121, 38.25695687328],
              [-85.71464568038, 38.25696187798],
              [-85.7146384269, 38.25695937498],
              [-85.71463114584, 38.25695686245],
              [-85.71463392087, 38.25695190384],
              [-85.71462841975, 38.25695000554],
              [-85.71467285259, 38.25687061012],
              [-85.7146591532, 38.25686588277],
              [-85.71465760883, 38.25686864235],
              [-85.71461278628, 38.25685317511],
              [-85.71460975316, 38.2568585949],
              [-85.71461109673, 38.25685905853],
              [-85.71460947834, 38.25686195039],
              [-85.71460779129, 38.25686496492],
              [-85.71460667842, 38.2568645809],
              [-85.71460289298, 38.25687134498],
              [-85.71460189927, 38.25687100207],
              [-85.71460083232, 38.25687290858],
              [-85.71459674359, 38.25687149765],
              [-85.71459238418, 38.25686999332],
              [-85.71459334098, 38.25686828364],
              [-85.71459078361, 38.25686740115],
              [-85.71460100622, 38.25684913473],
              [-85.71458933847, 38.25684510845],
              [-85.71458854975, 38.2568465178],
              [-85.71458442866, 38.25684509571],
              [-85.71458091825, 38.25684388434],
              [-85.7145818856, 38.25684215582],
              [-85.71452303972, 38.25682184945],
              [-85.71452452022, 38.25681920399],
              [-85.71452319695, 38.25681874736],
              [-85.71452727842, 38.25681145432],
              [-85.71452982934, 38.25680689617],
              [-85.71453145595, 38.25680745747],
              [-85.71453263829, 38.25680534479],
              [-85.71458961643, 38.25682500665],
              [-85.71459013739, 38.25682407577],
              [-85.71459418052, 38.25682547097],
              [-85.7145985962, 38.25682699472],
              [-85.71459811354, 38.25682785717],
              [-85.71464169426, 38.25684289588],
              [-85.71464252561, 38.25684141037],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71468372343, 38.25690223913],
            type: 'Point',
          },
        },
      },
      {
        id: 'f75b6577-1ee9-423f-bb9f-5d57b172018e',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71467559597, 38.25685249314],
              [-85.71470371708, 38.2568034376],
              [-85.71473090538, 38.25681304786],
              [-85.71472839702, 38.25681742354],
              [-85.71473008871, 38.25681802219],
              [-85.71472823306, 38.2568213289],
              [-85.71472627649, 38.25682481545],
              [-85.71472450675, 38.25682418972],
              [-85.71470277466, 38.25686209999],
              [-85.71469251046, 38.25685843071],
              [-85.71468739458, 38.25685666533],
              [-85.71468290593, 38.2568551164],
              [-85.71467559597, 38.25685249314],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'storage',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.714703250675, 38.256832768795],
            type: 'Point',
          },
        },
      },
      {
        id: 'f7af3645-c6b0-45e9-893c-f97fdbdd5f92',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71484904401, 38.25693655084],
              [-85.71486856132, 38.25690337005],
              [-85.71482158554, 38.2568863322],
              [-85.7148087555, 38.25690814418],
              [-85.71480701735, 38.25691110778],
              [-85.7148049003, 38.25691469829],
              [-85.71480206823, 38.256919513],
              [-85.71484904401, 38.25693655084],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714835314775, 38.25691144152],
            type: 'Point',
          },
        },
      },
      {
        id: 'f7b5b6a2-5bb1-429d-af40-9433e6e17bbf',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71483861234, 38.2569610667],
              [-85.71484122334, 38.25695651943],
              [-85.71484296352, 38.25695713542],
              [-85.71484696726, 38.2569501376],
              [-85.71484526471, 38.25694953492],
              [-85.71485284878, 38.25693632664],
              [-85.71480366863, 38.25691891447],
              [-85.71479470802, 38.25693448382],
              [-85.7147930389, 38.25693740137],
              [-85.71479136625, 38.25694032512],
              [-85.7147894579, 38.25694366365],
              [-85.71483861234, 38.2569610667],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'restroom.female',
          restriction: null,
          accessibility: null,
          name: {
            en: "Women's Restroom",
            frCA: null,
          },
          alt_name: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71482115334, 38.256939990584996],
            type: 'Point',
          },
        },
      },
      {
        id: 'f9312a48-d66f-4048-8f95-e9a05fa0f31f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71478967502, 38.25697998926],
              [-85.71480178528, 38.25695813075],
              [-85.71480281132, 38.25695630949],
              [-85.71480408862, 38.25695404225],
              [-85.71480470512, 38.25695289831],
              [-85.71483791976, 38.25696426081],
              [-85.7148332814, 38.2569726212],
              [-85.71483469784, 38.25697310575],
              [-85.71482770556, 38.25698570896],
              [-85.7148267854, 38.25698539418],
              [-85.71482338593, 38.25699152153],
              [-85.71478967502, 38.25697998926],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Educational Product Research Office 320',
            frCA: null,
          },
          alt_name: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71481379739001, 38.256972209919994],
            type: 'Point',
          },
        },
      },
      {
        id: 'fa991bfd-1db3-4589-8725-178300eb5647',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.7144089147, 38.25764396336],
              [-85.71444006415, 38.25765521403],
              [-85.71441816938, 38.25769259161],
              [-85.71438701993, 38.25768134095],
              [-85.71439003899, 38.25767623014],
              [-85.7143921786, 38.25767253021],
              [-85.71439411825, 38.25766917606],
              [-85.7144089147, 38.25764396336],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71441354204, 38.257668277485],
            type: 'Point',
          },
        },
      },
      {
        id: 'fb9f5ee0-d519-4c67-ad5c-d216a32d7425',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71454237119, 38.2572710931],
              [-85.7145280952, 38.25729545653],
              [-85.71453339047, 38.25729736972],
              [-85.71453772212, 38.25729893475],
              [-85.71454156896, 38.25730032462],
              [-85.71455726869, 38.25730599697],
              [-85.71456833131, 38.25728711749],
              [-85.71457020016, 38.25728779271],
              [-85.71457185056, 38.25728497612],
              [-85.71457351412, 38.2572821371],
              [-85.71457175878, 38.25728150289],
              [-85.71457401282, 38.25727765613],
              [-85.7145479074, 38.25726822418],
              [-85.71454555277, 38.25727224261],
              [-85.71454237119, 38.2572710931],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71455105401, 38.257287110575],
            type: 'Point',
          },
        },
      },
      {
        id: 'fbf1aabe-023e-4e92-a6b6-81712e66602f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71406726248, 38.25748044749],
              [-85.7139751882, 38.25744779085],
              [-85.71369889148, 38.25792812343],
              [-85.71388709858, 38.25799487571],
              [-85.71388545152, 38.25799773907],
              [-85.71390077551, 38.25800317409],
              [-85.71391704441, 38.25800894426],
              [-85.71391842837, 38.2580065383],
              [-85.71402582141, 38.25804462785],
              [-85.71407854899, 38.25795296311],
              [-85.7140824227, 38.25795433701],
              [-85.71408646795, 38.2579473045],
              [-85.71409057325, 38.2579401676],
              [-85.7140869636, 38.25793888734],
              [-85.71409484507, 38.25792518572],
              [-85.71408991648, 38.25792334879],
              [-85.71409208847, 38.25791957286],
              [-85.71408366713, 38.25791658602],
              [-85.71407484759, 38.25791345795],
              [-85.71407273328, 38.25791713359],
              [-85.71403016035, 38.25790212293],
              [-85.71403132831, 38.25790009249],
              [-85.71402186658, 38.25789673665],
              [-85.71401218597, 38.25789330318],
              [-85.71401108314, 38.25789522041],
              [-85.71400179218, 38.25789192514],
              [-85.71403544316, 38.25783342417],
              [-85.71403891078, 38.25783465405],
              [-85.71404313253, 38.25782731469],
              [-85.71404730473, 38.25782006147],
              [-85.71404447119, 38.25781905649],
              [-85.71407908574, 38.25775889421],
              [-85.71408196528, 38.25775986216],
              [-85.71408376341, 38.25775675188],
              [-85.71408565773, 38.25775347518],
              [-85.7140829426, 38.25775251641],
              [-85.71410587189, 38.25771264076],
              [-85.7141093081, 38.25771385882],
              [-85.71411129309, 38.2577104508],
              [-85.71411328934, 38.25770702344],
              [-85.71410972564, 38.25770575975],
              [-85.71412730383, 38.25767520065],
              [-85.71413187395, 38.25767682156],
              [-85.71413390156, 38.25767329663],
              [-85.71413593983, 38.25766975317],
              [-85.7141315382, 38.25766819202],
              [-85.71415273585, 38.2576313406],
              [-85.71415644856, 38.25763265741],
              [-85.71415845489, 38.25762916947],
              [-85.71416045396, 38.25762569414],
              [-85.71415697258, 38.25762445938],
              [-85.71418093241, 38.25758280596],
              [-85.7141840862, 38.25758392454],
              [-85.71418623003, 38.25758019756],
              [-85.71418823632, 38.25757670968],
              [-85.71418520879, 38.25757563589],
              [-85.71418860538, 38.25756973018],
              [-85.71416753023, 38.25756218374],
              [-85.71414634428, 38.25755459763],
              [-85.71414527248, 38.25755643754],
              [-85.71404417681, 38.25752058126],
              [-85.71406726248, 38.25748044749],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: {
            en: 'Braille Production Area',
            frCA: null,
          },
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71394374843001, 38.25774620935],
            type: 'Point',
          },
        },
      },
      {
        id: 'fdad949f-f1a9-4df7-8cfa-c747d58c7c9b',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71482929617, 38.25687905572],
              [-85.71483210536, 38.25687995568],
              [-85.71483569177, 38.25688117295],
              [-85.71483934612, 38.25688241329],
              [-85.71488061333, 38.25689604553],
              [-85.71488472596, 38.25688838603],
              [-85.714880877, 38.25688711173],
              [-85.71489097689, 38.25686830131],
              [-85.71487247497, 38.25686217579],
              [-85.71487343175, 38.25686039384],
              [-85.71484446548, 38.25685080384],
              [-85.71482929617, 38.25687905572],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'office',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71486013653, 38.256873424685],
            type: 'Point',
          },
        },
      },
      {
        id: 'fe584745-4fa0-4347-b511-fa063ea80e2f',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71432924583, 38.2577417967],
              [-85.71437931591, 38.25776008623],
              [-85.71439318672, 38.25773667227],
              [-85.71434311664, 38.25771838273],
              [-85.71434182219, 38.25772052152],
              [-85.71433946417, 38.25772454794],
              [-85.71433707959, 38.25772861973],
              [-85.71432924583, 38.2577417967],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714361216275, 38.25773923448],
            type: 'Point',
          },
        },
      },
      {
        id: 'fe6576f6-0e56-4359-a0e1-a9427519d967',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71418058777, 38.25749243025],
              [-85.71408339482, 38.25745741808],
              [-85.71404736661, 38.25751908594],
              [-85.71414455957, 38.25755409808],
              [-85.71415228204, 38.25754091826],
              [-85.71415602013, 38.25753448134],
              [-85.71415968396, 38.25752817229],
              [-85.71418058777, 38.25749243025],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'unspecified',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71411397719001, 38.25750575808],
            type: 'Point',
          },
        },
      },
      {
        id: 'fe6fa762-3ff2-46eb-9caa-3f0bd494dbb3',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [-85.71440728968, 38.25680206613],
              [-85.71440986001, 38.25679767035],
              [-85.71441254271, 38.25679252302],
              [-85.71441823447, 38.25679445488],
              [-85.71441934744, 38.25679243296],
              [-85.71442208909, 38.25679336352],
              [-85.71442276896, 38.2567921284],
              [-85.71442729618, 38.256793665],
              [-85.71443155183, 38.25679510943],
              [-85.71443045595, 38.25679710031],
              [-85.71455449175, 38.25683919972],
              [-85.71456445333, 38.25682110267],
              [-85.7145699441, 38.25682296631],
              [-85.71457095307, 38.25682113334],
              [-85.7145749735, 38.25682249793],
              [-85.71457881563, 38.256823802],
              [-85.71457774791, 38.25682574169],
              [-85.71458697676, 38.25682887408],
              [-85.71458789806, 38.25682720038],
              [-85.71459250116, 38.25682876273],
              [-85.7145970458, 38.25683030525],
              [-85.71459607554, 38.25683206789],
              [-85.71464241432, 38.25684779589],
              [-85.71464623644, 38.25684085229],
              [-85.7146785988, 38.2568518365],
              [-85.71467298389, 38.25686203703],
              [-85.71468637798, 38.25686658316],
              [-85.71468711646, 38.25686524158],
              [-85.71469167049, 38.25686678728],
              [-85.71469610686, 38.25686829304],
              [-85.71469544661, 38.25686949251],
              [-85.71472918594, 38.25688094408],
              [-85.71472980658, 38.25687981659],
              [-85.71473326769, 38.25688099134],
              [-85.71473674495, 38.25688217156],
              [-85.71473605611, 38.25688342296],
              [-85.71479593017, 38.25690374499],
              [-85.71479695856, 38.25690187674],
              [-85.71480090692, 38.25690321687],
              [-85.71480505679, 38.25690462539],
              [-85.71480432921, 38.25690594716],
              [-85.7148145648, 38.25690942125],
              [-85.71483012544, 38.2568811525],
              [-85.7148312386, 38.25688153032],
              [-85.71483210536, 38.25687995568],
              [-85.71483569177, 38.25688117295],
              [-85.71483934612, 38.25688241329],
              [-85.71483850158, 38.25688394757],
              [-85.71483997755, 38.25688444853],
              [-85.71482481815, 38.25691198835],
              [-85.71482645399, 38.25691254357],
              [-85.71482478424, 38.25691557699],
              [-85.714823072, 38.25691868758],
              [-85.71482106083, 38.25691800496],
              [-85.71481913925, 38.25692149586],
              [-85.71480657729, 38.25691723217],
              [-85.71480477922, 38.2569204987],
              [-85.71480652513, 38.25692109128],
              [-85.71480467112, 38.25692445943],
              [-85.71480279064, 38.25692787565],
              [-85.71480127377, 38.2569273608],
              [-85.71479063249, 38.25694669261],
              [-85.71479433441, 38.25694794909],
              [-85.71479179367, 38.25695256482],
              [-85.71478805338, 38.25695129532],
              [-85.71478637161, 38.25695435055],
              [-85.71479585501, 38.25695756934],
              [-85.71479201387, 38.25696454747],
              [-85.71478190098, 38.25696111503],
              [-85.71478045044, 38.25696375021],
              [-85.71478253135, 38.25696445649],
              [-85.71478086891, 38.25696747662],
              [-85.71477916974, 38.25697056346],
              [-85.71477696072, 38.25696981369],
              [-85.71477207483, 38.25697868979],
              [-85.71477351242, 38.25697923917],
              [-85.71477173059, 38.25698238639],
              [-85.7147702568, 38.2569849908],
              [-85.7147687591, 38.25698496793],
              [-85.71476243363, 38.25699645927],
              [-85.7147597961, 38.25699559921],
              [-85.71475634345, 38.25699439224],
              [-85.714752927, 38.25699319792],
              [-85.71475082652, 38.25699251967],
              [-85.71475795457, 38.25697957028],
              [-85.71475626464, 38.2569789967],
              [-85.71475807216, 38.25697571301],
              [-85.71475992686, 38.25697234361],
              [-85.71476166544, 38.2569729337],
              [-85.71479366675, 38.25691479753],
              [-85.71473211582, 38.25689390634],
              [-85.71473111264, 38.25689572879],
              [-85.71472716745, 38.25689438974],
              [-85.71472315265, 38.25689302707],
              [-85.71472399607, 38.25689149484],
              [-85.71467254699, 38.25687403235],
              [-85.71467369106, 38.25687195393],
              [-85.71466523054, 38.25686908232],
              [-85.71466408801, 38.25687115794],
              [-85.71461974227, 38.25685610641],
              [-85.71461837304, 38.25685859386],
              [-85.71462136485, 38.25685960932],
              [-85.71461975077, 38.25686254159],
              [-85.71461809555, 38.25686554859],
              [-85.71461205684, 38.25686349898],
              [-85.714611326, 38.25686482668],
              [-85.71460714887, 38.25686340891],
              [-85.71460295725, 38.25686198622],
              [-85.71460357841, 38.25686085776],
              [-85.71460123494, 38.25686006236],
              [-85.71460623715, 38.25685097491],
              [-85.71458927513, 38.25684521778],
              [-85.71458854975, 38.2568465178],
              [-85.71458442866, 38.25684509571],
              [-85.71458091825, 38.25684388434],
              [-85.71458145316, 38.25684292029],
              [-85.7145728162, 38.25683998879],
              [-85.71455923744, 38.25686465708],
              [-85.71456672147, 38.25686719726],
              [-85.71455388909, 38.25689050962],
              [-85.71454542159, 38.25688763564],
              [-85.71452527251, 38.25692424011],
              [-85.71451529785, 38.25692085459],
              [-85.71450428436, 38.25691711647],
              [-85.71454082757, 38.25685072906],
              [-85.71449901364, 38.25683653686],
              [-85.71449847006, 38.25683752439],
              [-85.7144950063, 38.25683634874],
              [-85.71449112694, 38.25683503204],
              [-85.71449202222, 38.2568334056],
              [-85.71444466443, 38.25681733174],
              [-85.71444409538, 38.2568183655],
              [-85.71444036115, 38.25681709806],
              [-85.71443625568, 38.25681570461],
              [-85.71443704004, 38.25681427968],
              [-85.7144119399, 38.25680576036],
              [-85.71441292125, 38.25680397756],
              [-85.71440728968, 38.25680206613],
            ],
          ],
          type: 'Polygon',
        },
        feature_type: 'unit',
        properties: {
          category: 'walkway',
          restriction: null,
          accessibility: null,
          name: null,
          alt_name: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714533481245, 38.256894293835],
            type: 'Point',
          },
        },
      },
    ],
    venue: {
      id: '0e905ba3-123b-4589-9ef3-034bb6154e0c',
      buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
      type: 'Feature',
      geometry: {
        coordinates: [
          [
            [-85.71492947204, 38.25666738267],
            [-85.71492947204, 38.25861682688],
            [-85.71338197298, 38.25861682688],
            [-85.71338197298, 38.25666738267],
            [-85.71492947204, 38.25666738267],
          ],
        ],
        type: 'Polygon',
      },
      feature_type: 'venue',
      properties: {
        category: 'businesscampus',
        restriction: null,
        name: {
          en: 'American Printing House for the Blind',
          frCA: null,
        },
        alt_name: null,
        hours: null,
        phone: null,
        website: null,
        address_id: 'edc2af67-1074-4715-a287-9b94dbdc0335',
        display_point: {
          coordinates: [-85.71416037630499, 38.25763342359],
          type: 'Point',
        },
      },
    },
    cps_points: [
      {
        id: 'a6957c28-0bd0-4164-b539-b8fcebc7da14',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.71450994705, 38.25673814514],
          type: 'Point',
        },
        feature_type: 'cps_points',
        properties: {
          indoorMark: 'cps',
          cps: 'point_cloud',
          x_pointcloud: -86.822,
          y_pointcloud: 34.862,
          z_pointcloud: null,
          level: null,
        },
      },
      {
        id: 'becc4ea5-9205-49da-82f2-7c509dbc5033',
        buildingId: '3dd050d8-3528-4587-9414-c0b895ec54f7',
        type: 'Feature',
        geometry: {
          coordinates: [-85.714187811, 38.25754024937],
          type: 'Point',
        },
        feature_type: 'cps_points',
        properties: {
          indoorMark: 'cps',
          cps: 'point_cloud',
          x_pointcloud: 0,
          y_pointcloud: 0,
          z_pointcloud: null,
          level: null,
        },
      },
    ],
    route: [
      {
        id: '003f93bd-0b2b-4560-9dd9-650826147479',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71460577387, 38.25688581624],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71463852805, 38.25688669734],
            [-85.71463305666, 38.2568967343],
            [-85.71457088666, 38.25687489818],
          ],
          type: 'LineString',
        },
      },
      {
        id: '00491667-5624-4706-a4ae-8306e5d96d68',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71468798893, 38.25693873569],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71468798893, 38.25693873569],
            [-85.71469348019, 38.25692942598],
          ],
          type: 'LineString',
        },
      },
      {
        id: '00c7c810-d19f-4b97-b10c-3df92ab8e144',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71398585094, 38.25828075108],
          ],
          type: 'LineString',
        },
      },
      {
        id: '00e007b1-f492-41bf-a8b2-1494311d6d61',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71434907416, 38.25719932376],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71434907416, 38.25719932376],
            [-85.71434317604, 38.2572095909],
          ],
          type: 'LineString',
        },
      },
      {
        id: '00e95c6b-e883-422c-b428-3f1531495471',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71480101288, 38.25701315473],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0111a305-09c4-4bc4-a7e8-d44c4430c096',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71461443065, 38.256835302075],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71462826611, 38.25680865376],
            [-85.71462587791, 38.25681295383],
            [-85.71461598625, 38.25683076415],
            [-85.71461222829, 38.25683753059],
            [-85.71460059519, 38.256858814],
            [-85.71460947834, 38.25686195039],
          ],
          type: 'LineString',
        },
      },
      {
        id: '01b3d1ba-b882-413e-b22a-fa51c2329952',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71396692524, 38.25831176685],
          ],
          type: 'LineString',
        },
      },
      {
        id: '01c6670e-7a64-44b9-af6a-a582cc6e44fb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71418821832, 38.25787119468],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71418821832, 38.25787119468],
            [-85.71420042335, 38.25787593129],
          ],
          type: 'LineString',
        },
      },
      {
        id: '01ee7f1c-3941-488f-9a48-a9e7ae645f0a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71475710769, 38.25697739823],
          ],
          type: 'LineString',
        },
      },
      {
        id: '02ab79bd-6e6a-4498-825e-776f422fbb56',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.713822866445, 38.258107898815],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71383863597, 38.25811830035],
            [-85.71384229276, 38.25811097191],
            [-85.71380344013, 38.25809749728],
          ],
          type: 'LineString',
        },
      },
      {
        id: '03178944-631b-4152-8749-15f5fbd67e77',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71471126667, 38.2568856893],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71471126667, 38.2568856893],
            [-85.71471818653, 38.25687576546],
          ],
          type: 'LineString',
        },
      },
      {
        id: '034a5e28-f5ad-4432-b027-50e547f3658c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71424583091, 38.25731638469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71424583091, 38.25731638469],
            [-85.71424780828, 38.25731079987],
          ],
          type: 'LineString',
        },
      },
      {
        id: '03fdb721-fbdb-4730-be45-8a11a7fe7584',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71426302038, 38.25729946629],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71426302038, 38.25729946629],
            [-85.71425596715, 38.25729718171],
          ],
          type: 'LineString',
        },
      },
      {
        id: '048c90fc-f581-4800-9247-5ee20826bf10',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.7148177944, 38.25692867548],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7148177944, 38.25692867548],
            [-85.71481086279, 38.25692662022],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0511ae0e-c490-4e1f-bd1d-c2501e2c2f5c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '05614ad5-8943-4000-ad1c-612bcd45b845',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71458442866, 38.25684509571],
          ],
          type: 'LineString',
        },
      },
      {
        id: '059ad57b-422d-4456-9439-6cdcaed9c77a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71456205989, 38.2569826167],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71456205989, 38.2569826167],
            [-85.7145551396, 38.25699564143],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0749033f-13df-4ef4-b4da-f9486abbc1ed',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71451870337, 38.256788462595],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71461519277, 38.25678443025],
            [-85.71460855331, 38.25679705215],
            [-85.71460491195, 38.25680343454],
            [-85.71457998788, 38.2567946653],
            [-85.71457165235, 38.25680927357],
            [-85.71456298173, 38.2568244691],
            [-85.71451446373, 38.25680739864],
            [-85.71449943151, 38.25680210971],
            [-85.71449407157, 38.25681150316],
            [-85.71445699773, 38.25679845916],
            [-85.71444109184, 38.25679286286],
            [-85.71442221397, 38.25678622091],
            [-85.71444148028, 38.25675245609],
          ],
          type: 'LineString',
        },
      },
      {
        id: '07a6c007-1423-4905-b9fb-3d9c4ddf480b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714596090155, 38.25682081145],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460481866, 38.25680324998],
            [-85.71459250116, 38.25682876273],
            [-85.71458736165, 38.25683837292],
          ],
          type: 'LineString',
        },
      },
      {
        id: '08729ad1-8648-4eef-b573-cf1ff415b9bb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71457158474, 38.25694710319],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71457158474, 38.25694710319],
            [-85.71454250625, 38.25694057488],
          ],
          type: 'LineString',
        },
      },
      {
        id: '08aca47d-bda8-40bf-abf4-354904909df3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71419045561, 38.25739862507],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71419045561, 38.25739862507],
            [-85.71419550106, 38.25739024816],
          ],
          type: 'LineString',
        },
      },
      {
        id: '08d5efa6-ecbf-4423-9c8a-3a4aa4329d67',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71357207259, 38.25820362701],
            [-85.71359025065, 38.25813760798],
          ],
          type: 'LineString',
        },
      },
      {
        id: '08ddb161-38ef-4a15-b9aa-31864f6af519',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71408366713, 38.25791658602],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408366713, 38.25791658602],
            [-85.71406906175, 38.25794096725],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0954391b-b1d6-4a21-8e55-6116830e1e2c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71468700054, 38.25683047544],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71468700054, 38.25683047544],
            [-85.7146746951, 38.25685370221],
            [-85.71467145452, 38.25685982356],
          ],
          type: 'LineString',
        },
      },
      {
        id: '09db368c-990c-4b27-8aa0-ffb29d6281ab',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71424368733, 38.25722967876],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71424368733, 38.25722967876],
            [-85.71424023541, 38.25723566892],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0aeffb6d-db44-4337-9e61-06dba34abd81',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71449968364999, 38.256802123025],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71450601539, 38.25679196635],
            [-85.7145020556, 38.25679831815],
            [-85.71449335191, 38.2568122797],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0b24fa3b-4c47-4b6d-85f1-f70d6aca15e1',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71469488953, 38.2570644713],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71469488953, 38.2570644713],
            [-85.71469872931, 38.25705804801],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0c876ffd-c5fc-4378-b6a5-fa8f905b8453',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460947834, 38.25686195039],
            [-85.71460503067, 38.25686028449],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0c8f852d-7947-45cf-897f-a2dc9282bad0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71357207259, 38.25820362701],
            [-85.71357207259, 38.25820362701],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0d14833f-423d-4a40-a9fe-f5fbc56381c2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71391008018, 38.25831601215],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0d4355d7-c97f-4576-ae83-4ebe682bdf18',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71425548631, 38.25771107825],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71425548631, 38.25771107825],
            [-85.71433946417, 38.25772454794],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0ddfc501-8bcf-4d15-a621-7c736fe8c779',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71408066241, 38.25771106406],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408066241, 38.25771106406],
            [-85.71408833591, 38.2577137872],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0ea471c8-8490-4bd6-8f60-b0aee8582776',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714496523275, 38.25697235864],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71450218524, 38.25692341891],
            [-85.71448086675, 38.25696230349],
            [-85.71447852107, 38.25696658196],
            [-85.71446573216, 38.25697378082],
            [-85.71446186976, 38.25699063204],
            [-85.71445797497, 38.25700461272],
            [-85.71450710635, 38.25702129837],
            [-85.71453507158, 38.25697975074],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0f477e28-6f8c-423e-802d-902b87613d38',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71396692524, 38.25831176685],
          ],
          type: 'LineString',
        },
      },
      {
        id: '0fd142d4-b3a2-4e76-9031-ffb681da4545',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.714690877875, 38.256875109565],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71475603321, 38.25697667975],
            [-85.71475907161, 38.25697138341],
            [-85.71476349787, 38.25696371974],
            [-85.7147810882, 38.25693326368],
            [-85.7147981547, 38.2569037146],
            [-85.71477379645, 38.25689504318],
            [-85.71470652037, 38.25687109708],
            [-85.71468594404, 38.25686377319],
            [-85.71461222829, 38.25683753059],
            [-85.71459331591, 38.25683095132],
            [-85.71455057493, 38.25681558723],
            [-85.7145020556, 38.25679831815],
            [-85.71447415624, 38.25678838555],
            [-85.71445284226, 38.25678079872],
            [-85.71446032727, 38.25676783274],
          ],
          type: 'LineString',
        },
      },
      {
        id: '10590c59-1cb4-4c2a-8d9e-7fa98b8455b8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71415546565, 38.25798691165],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71415546565, 38.25798691165],
            [-85.71414061549, 38.25798201852],
          ],
          type: 'LineString',
        },
      },
      {
        id: '10710f5e-26ab-4780-b94b-1dec838af81e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71388890142, 38.25835364877],
          ],
          type: 'LineString',
        },
      },
      {
        id: '10e016ad-cd30-4771-833f-c7c7c20c8c6a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71398585094, 38.25828075108],
          ],
          type: 'LineString',
        },
      },
      {
        id: '117d70a5-de8d-4ef4-8818-5a1bd201c604',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71413390156, 38.25767329663],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71413390156, 38.25767329663],
            [-85.7141075866, 38.25766313167],
          ],
          type: 'LineString',
        },
      },
      {
        id: '11a40300-ebdd-42f2-bb10-0a5ec99aa1bc',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71436588219001, 38.256770646815],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71439376271, 38.25672889672],
            [-85.71438403323, 38.25672570807],
            [-85.71436450335, 38.25676245244],
            [-85.71433800167, 38.25681231384],
            [-85.71434798462, 38.25681558556],
          ],
          type: 'LineString',
        },
      },
      {
        id: '126c654b-c34c-4aae-95e9-2e25edfc5d58',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71378289563, 38.25818026124],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71378289563, 38.25818026124],
            [-85.71378289563, 38.25818026124],
          ],
          type: 'LineString',
        },
      },
      {
        id: '12b5f5c4-10ad-4df1-8923-9b52aaaf7260',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71464169632, 38.25681582867],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71464457116, 38.25681416949],
            [-85.71464219568, 38.25681870351],
            [-85.71462587791, 38.25681295383],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1346000b-4ced-41e7-bc9f-709dddaaa2a5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71413587954, 38.25737971312],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71413587954, 38.25737971312],
            [-85.71414061888, 38.25737088278],
          ],
          type: 'LineString',
        },
      },
      {
        id: '134ca762-e68b-4780-9a06-bdb611e9c837',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71454257882, 38.25748602749],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71454257882, 38.25748602749],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1365e728-0ddc-4022-b4e8-7f560a2624d8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '137c42a6-2a82-4298-8651-36f65e09b14a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71428322641, 38.25726543387],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71428322641, 38.25726543387],
            [-85.71427636603, 38.25726313348],
          ],
          type: 'LineString',
        },
      },
      {
        id: '148a33c9-8d28-4dbc-b14f-2b73fad0b840',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71404533649, 38.2582368204],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71404533649, 38.2582368204],
            [-85.71399435407, 38.25821901226],
          ],
          type: 'LineString',
        },
      },
      {
        id: '14a41c2f-7ab0-4c5d-8a64-167da0252d33',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71453000859, 38.25732670105],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71453000859, 38.25732670105],
            [-85.71452491745, 38.25731909358],
          ],
          type: 'LineString',
        },
      },
      {
        id: '156c01e3-1ce0-4c49-acf8-97e7c0f925ef',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71477173059, 38.25698238639],
          ],
          type: 'LineString',
        },
      },
      {
        id: '15e1dd0e-6257-4a79-8ad5-668cc8b528fe',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7140922845, 38.25737785522],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7140922845, 38.25737785522],
            [-85.71410239222, 38.25738132895],
          ],
          type: 'LineString',
        },
      },
      {
        id: '15f9b3ca-0659-48cf-9b1d-ae307304e5b3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71391008018, 38.25831601215],
          ],
          type: 'LineString',
        },
      },
      {
        id: '16441c57-9e40-4760-a3f8-3e8ea1039445',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71428075732, 38.25726692322],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71428075732, 38.25726692322],
            [-85.71428724125, 38.25725606616],
          ],
          type: 'LineString',
        },
      },
      {
        id: '16500cac-10b4-44dc-ab58-c06019aedcf5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71378289563, 38.25818026124],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71378289563, 38.25818026124],
            [-85.71378289563, 38.25818026124],
          ],
          type: 'LineString',
        },
      },
      {
        id: '17402689-821e-4536-95d6-bda49485c090',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71393805796, 38.25846841862],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71393805796, 38.25846841862],
            [-85.71393805796, 38.25846841862],
          ],
          type: 'LineString',
        },
      },
      {
        id: '179f5ef5-bbde-4af8-971f-cad2fbccfe8c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7144232129, 38.25714403588],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7144232129, 38.25714403588],
            [-85.71442300924, 38.25711761548],
          ],
          type: 'LineString',
        },
      },
      {
        id: '17c983d2-4db3-4b0c-9eb6-44b7e12c8a19',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71457158474, 38.25694710319],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71457158474, 38.25694710319],
            [-85.71457158474, 38.25694710319],
          ],
          type: 'LineString',
        },
      },
      {
        id: '17d4c353-6a7a-41e4-ad27-e6030b645fd9',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '192021c1-d98e-4506-9632-c708114b9aa7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71393805796, 38.25846841862],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71393805796, 38.25846841862],
            [-85.71393805796, 38.25846841862],
          ],
          type: 'LineString',
        },
      },
      {
        id: '19649dc0-915f-4a49-ad2e-6602d8fc6a0d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71393805796, 38.25846841862],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71393805796, 38.25846841862],
            [-85.71393805796, 38.25846841862],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1ad78e2b-5ec0-48e8-81d6-8860cc453dd1',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71421524775499, 38.257690416715],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71483569177, 38.25688117295],
            [-85.71481807691, 38.25691350283],
            [-85.71480307647, 38.25691101472],
            [-85.71479787958, 38.25690926467],
            [-85.71473030301, 38.25688650831],
            [-85.71468870467, 38.25687250011],
            [-85.71461577063, 38.25684793963],
            [-85.71458736165, 38.25683837292],
            [-85.71457028257, 38.25683262156],
            [-85.71456079751, 38.25684762626],
            [-85.71451529785, 38.25692085459],
            [-85.71451259803, 38.2569263378],
            [-85.71450990928, 38.25693179858],
            [-85.71449977713, 38.25695237664],
            [-85.71448370297, 38.25698502275],
            [-85.71448549047, 38.25702266068],
            [-85.71442300924, 38.25711761548],
            [-85.71440385138, 38.25713825523],
            [-85.71437920703, 38.25718029861],
            [-85.71436478018, 38.25720491086],
            [-85.71435123732, 38.25722801504],
            [-85.71427013451, 38.25736422493],
            [-85.71425911574, 38.25738517455],
            [-85.71425314291, 38.25739536427],
            [-85.71418736458, 38.25752513769],
            [-85.71411834828, 38.2576615106],
            [-85.7141106179, 38.25767966278],
            [-85.71408833591, 38.2577137872],
            [-85.71406126457, 38.25775824997],
            [-85.71402374492, 38.25781987329],
            [-85.71397266333, 38.25790377104],
            [-85.7139472164, 38.25794556572],
            [-85.71393827655, 38.25796025005],
            [-85.71387921253, 38.25807011734],
            [-85.71380344013, 38.25809749728],
            [-85.71377022671, 38.25815185268],
            [-85.71376636152, 38.25815817826],
            [-85.71375504378, 38.2581766866],
            [-85.71374533952, 38.25819242323],
            [-85.71374104701, 38.25820052987],
            [-85.71361575926, 38.25841193623],
            [-85.71359480374, 38.25844733588],
            [-85.71382721285, 38.25852805547],
            [-85.7138852475, 38.25854821187],
            [-85.71392983899, 38.2584655154],
            [-85.71397110789, 38.2583876749],
            [-85.7139985946, 38.25833414842],
            [-85.71396148598, 38.25832134021],
            [-85.71391684491, 38.25830556325],
            [-85.71394029994, 38.25826463081],
            [-85.71395260726, 38.25824315628],
            [-85.71399642388, 38.25816549047],
            [-85.71402213316, 38.2581198728],
            [-85.7140912724, 38.25799719434],
            [-85.71406145719, 38.25798561994],
            [-85.71401466409, 38.25796902206],
            [-85.71408104876, 38.25784423238],
            [-85.71416141972, 38.25787411734],
            [-85.71419791485, 38.25781019654],
            [-85.71422687349, 38.25776045367],
            [-85.71425548631, 38.25771107825],
            [-85.71423121723, 38.25770252755],
            [-85.71411834828, 38.2576615106],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1b45bc6b-2bac-4139-a68d-6e4dfbe533ac',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.71452606668, 38.25748812416],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1b8bab7c-227e-4587-8d9c-9d11e66af3c6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71477159842, 38.25695030015],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477159842, 38.25695030015],
            [-85.71476856936, 38.25695604972],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1bdb2e94-7ab1-48df-9966-8c9b70390d16',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71458442866, 38.25684509571],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1c160603-b58e-4dc2-be9e-d24f6dd9bd97',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71433087885, 38.25761915035],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71433087885, 38.25761915035],
            [-85.71434268407, 38.25762355046],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1d4a118b-1fe9-4ad6-a967-0a1188c6b8b2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71414185406, 38.25747099877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7140259689, 38.2574525602],
            [-85.7140864823, 38.25744896695],
            [-85.71412607771, 38.25746315804],
            [-85.71418696896, 38.25748498159],
            [-85.71420794952, 38.25749303059],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1e8c8ea2-0f63-4f7b-beec-5a8335079bd4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.7147930389, 38.25693740137],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7147930389, 38.25693740137],
            [-85.7147810882, 38.25693326368],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1eaf3ebd-5783-4ba3-8ac4-532d09433a7e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71378289563, 38.25818026124],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71378289563, 38.25818026124],
            [-85.71378289563, 38.25818026124],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1ef1421d-7f51-4ddd-86fb-477d3ded451b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.7144556679, 38.256970768105],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71446573216, 38.25697378082],
            [-85.71445473607, 38.25697083024],
            [-85.71444560364, 38.25696775539],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1f9f765c-146e-46c6-a8ff-218e6ea28a6b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71393805796, 38.25846841862],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71393805796, 38.25846841862],
            [-85.71392983899, 38.2584655154],
          ],
          type: 'LineString',
        },
      },
      {
        id: '1fc21765-342c-4ade-bf53-9a0f0173cdeb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71459111055, 38.25689593227],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71459111055, 38.25689593227],
            [-85.71458044388, 38.25688988916],
          ],
          type: 'LineString',
        },
      },
      {
        id: '20860ba1-c713-4da7-88e0-9cbf8f291c5e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71393275324, 38.25809532197],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7139946376, 38.25809966762],
            [-85.71398774288, 38.25809734463],
            [-85.7139771428, 38.25811595009],
            [-85.71393870735, 38.25810269587],
            [-85.71389086129, 38.25808666113],
            [-85.71385886567, 38.25807469385],
          ],
          type: 'LineString',
        },
      },
      {
        id: '23071e52-1b3b-4008-bf1a-8f01c368ed54',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71398585094, 38.25828075108],
          ],
          type: 'LineString',
        },
      },
      {
        id: '2324186e-b82e-4c58-9fcf-d009aa0b6cc4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71436398827, 38.25717478532],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71436398827, 38.25717478532],
            [-85.71437920703, 38.25718029861],
          ],
          type: 'LineString',
        },
      },
      {
        id: '240fd0f7-d388-4e47-b7ed-eeed386eef1a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71449388899, 38.25743936299],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71449388899, 38.25743936299],
            [-85.71448536292, 38.25742140436],
          ],
          type: 'LineString',
        },
      },
      {
        id: '24aefa6b-79ea-4a99-9280-626aada49ab1',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71396692524, 38.25831176685],
          ],
          type: 'LineString',
        },
      },
      {
        id: '267bbeff-0370-4078-97ed-34b426a33606',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714454250985, 38.257299032365],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71457837941, 38.25732935537],
            [-85.71458342574, 38.25732154169],
            [-85.71456416025, 38.25731533368],
            [-85.71453343908, 38.25730375207],
            [-85.71453070235, 38.25730929538],
            [-85.71452491745, 38.25731909358],
            [-85.71449447816, 38.25730789715],
            [-85.71447730266, 38.25730157952],
            [-85.7144512365, 38.25729395961],
            [-85.71442588688, 38.25729489809],
            [-85.71439626246, 38.2572930909],
            [-85.71436826151, 38.25728356469],
            [-85.71433894691, 38.25727359644],
            [-85.71432507623, 38.25726870936],
          ],
          type: 'LineString',
        },
      },
      {
        id: '26841f7c-38a2-4e76-8c8b-04a2b1cc736c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '2779b52d-ed88-468c-8be4-7372a6246bf6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71387921253, 38.25807011734],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71387921253, 38.25807011734],
            [-85.71402213316, 38.2581198728],
          ],
          type: 'LineString',
        },
      },
      {
        id: '2a5e910b-a22c-4c36-a6db-26d03ce9b3ae',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71359025065, 38.25813760798],
            [-85.71357207259, 38.25820362701],
          ],
          type: 'LineString',
        },
      },
      {
        id: '2b96f373-1b72-4257-af2f-78afd9c7f770',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71369219337501, 38.25815543425],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71378774055, 38.25817044387],
            [-85.71376992183, 38.2581632799],
            [-85.71375786683, 38.25818449663],
            [-85.7135966462, 38.25812637187],
          ],
          type: 'LineString',
        },
      },
      {
        id: '2ba492f2-3646-4f95-8cbf-33aa49910d98',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71464142815, 38.25695709],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71464142815, 38.25695709],
            [-85.71463214594, 38.25697472229],
          ],
          type: 'LineString',
        },
      },
      {
        id: '2c0def12-c7dc-4b51-b920-8382346f4590',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714667076985, 38.257029245145006],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71468437352, 38.25699441297],
            [-85.71466752155, 38.25702834987],
            [-85.71464978045, 38.25706407732],
          ],
          type: 'LineString',
        },
      },
      {
        id: '2c3b2863-d505-472d-83fd-02948818ba12',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714252129765, 38.257768719005],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71430359919, 38.25778557027],
            [-85.71422687349, 38.25776045367],
            [-85.71420066034, 38.25775186774],
          ],
          type: 'LineString',
        },
      },
      {
        id: '2c936a12-ad52-47fc-b4a1-c5783fb9bce2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71422848649, 38.25736198333],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71422848649, 38.25736198333],
            [-85.71422545496, 38.25736773141],
          ],
          type: 'LineString',
        },
      },
      {
        id: '305a5496-86b4-4d61-a721-f86c172dbcf4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71449064211, 38.25679764716],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71449064211, 38.25679764716],
            [-85.71449943151, 38.25680210971],
          ],
          type: 'LineString',
        },
      },
      {
        id: '306c2b9e-4104-44ad-b60b-815d3dc2862b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71439535203, 38.256962685765004],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71441757238, 38.25701532251],
            [-85.7143350766, 38.2569870067],
            [-85.71436565586, 38.2569324324],
            [-85.71444812806, 38.25696044417],
            [-85.71445562746, 38.25694691014],
            [-85.71437203291, 38.25691834839],
            [-85.71437680216, 38.25691004902],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3070e849-01bd-44de-95af-f1d173e75f0b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71458442866, 38.25684509571],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3074e95e-a17a-42ad-a317-5b10a6ee09cb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71477553525, 38.25696795823],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477553525, 38.25696795823],
            [-85.71476349787, 38.25696371974],
          ],
          type: 'LineString',
        },
      },
      {
        id: '30df72c9-f00f-4872-9470-b294a9d0c763',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71443596202, 38.25688835627],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71443596202, 38.25688835627],
            [-85.71444361192, 38.25687650167],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3183c1ad-8f5d-4a04-9514-d2ab4ef90b51',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71396692524, 38.25831176685],
          ],
          type: 'LineString',
        },
      },
      {
        id: '31e62c46-fd99-4699-b61b-be379c11dec2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71458442866, 38.25684509571],
          ],
          type: 'LineString',
        },
      },
      {
        id: '334b6bcc-caa6-49f4-891b-0107a7056c77',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71421693394501, 38.257263022809994],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71415057663, 38.25727488104],
            [-85.7141612692, 38.25727883104],
            [-85.71419448332, 38.25729110083],
            [-85.71420560248, 38.25729520842],
            [-85.71424780828, 38.25731079987],
            [-85.71425596715, 38.25729718171],
            [-85.71427170308, 38.25727091651],
            [-85.71427636603, 38.25726313348],
            [-85.71428329126, 38.25725157441],
            [-85.71424023541, 38.25723566892],
            [-85.71422984237, 38.25723182958],
            [-85.71419641855, 38.2572194823],
            [-85.71418495029, 38.25721524575],
          ],
          type: 'LineString',
        },
      },
      {
        id: '33d2f1cb-e359-4e13-9a59-5040fe0840cb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71411534294, 38.2582250959],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71411534294, 38.2582250959],
            [-85.71408423021, 38.25821116589],
          ],
          type: 'LineString',
        },
      },
      {
        id: '33ed2651-624b-4209-9421-9b237732b0aa',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7142781208, 38.25727330465],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7142781208, 38.25727330465],
            [-85.71427170308, 38.25727091651],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3413f7ac-8ae7-4497-a144-acaff6fa8e85',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7137677619, 38.25820483627],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71374104701, 38.25820052987],
            [-85.7137684444, 38.25820914267],
            [-85.7137720683, 38.25820203345],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3434d00b-4cf1-4ea0-95ab-1ee279315643',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '345f8791-f753-44b5-9a54-f77fd3f285a3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71465085234, 38.256875196210004],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71475066329, 38.25699737657],
            [-85.71476131779, 38.25697857865],
            [-85.71477218709, 38.25695910102],
            [-85.7147730327, 38.25695759498],
            [-85.71476856936, 38.25695604972],
            [-85.71475832535, 38.25695250312],
            [-85.71473991815, 38.25694613033],
            [-85.71468558214, 38.25692731855],
            [-85.71466149612, 38.25691897967],
            [-85.71465882739, 38.25691805572],
            [-85.71468753038, 38.25686693557],
            [-85.71468282115, 38.25686530517],
            [-85.71464237971, 38.25685130384],
            [-85.71461358886, 38.25684133609],
            [-85.71459143391, 38.25683366577],
            [-85.71452727842, 38.25681145432],
            [-85.71451939224, 38.25680883742],
            [-85.71444760644, 38.25678387086],
            [-85.71446493091, 38.25675301585],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3528e7ff-9cc7-47a5-a1ef-78a2e6f47605',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71413445615, 38.25734452417],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71413445615, 38.25734452417],
            [-85.71412664598, 38.25733954253],
          ],
          type: 'LineString',
        },
      },
      {
        id: '354c9e18-fa69-4ea4-8741-ec969396cd8b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71437677815, 38.2575956086],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71437677815, 38.2575956086],
            [-85.71436246174, 38.25759029678],
          ],
          type: 'LineString',
        },
      },
      {
        id: '35e265be-c0a8-4139-8021-b825171aebaa',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714570309405, 38.257024299245],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460087421, 38.2570174851],
            [-85.71455266482, 38.25703111339],
            [-85.7145397446, 38.25702785413],
          ],
          type: 'LineString',
        },
      },
      {
        id: '36569f0b-daeb-4b63-a3c9-ca1c851f3f51',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460947834, 38.25686195039],
            [-85.71460947834, 38.25686195039],
          ],
          type: 'LineString',
        },
      },
      {
        id: '36b37d96-379f-4297-baab-58e235b6329f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7141148077, 38.25730691018],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7141148077, 38.25730691018],
            [-85.71412899291, 38.25731268884],
          ],
          type: 'LineString',
        },
      },
      {
        id: '36c909f7-881f-4bb2-82ea-7bc373459fb2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71462912677, 38.25702706128],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71459981152, 38.25698082142],
            [-85.71460711219, 38.25696668109],
            [-85.71465109058, 38.25698418423],
            [-85.71468437352, 38.25699441297],
            [-85.7147335061, 38.2570091775],
            [-85.71477738679, 38.25702424868],
            [-85.7147734018, 38.2570427432],
            [-85.7147506493, 38.25706202593],
            [-85.71474900553, 38.25708440546],
            [-85.71471623375, 38.25709181907],
            [-85.71470192223, 38.25706389042],
            [-85.71467641326, 38.25707220073],
            [-85.71464978045, 38.25706407732],
            [-85.7146126263, 38.25705101518],
            [-85.71460460805, 38.25703928604],
            [-85.71458011933, 38.25702715145],
            [-85.71456053141, 38.25698832747],
            [-85.71455040586, 38.25698504124],
            [-85.71453507158, 38.25697975074],
            [-85.71452109428, 38.25697530855],
            [-85.71448086675, 38.25696230349],
          ],
          type: 'LineString',
        },
      },
      {
        id: '36dcaa0a-3405-479e-919a-25d80b22e8c7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71477173059, 38.25698238639],
          ],
          type: 'LineString',
        },
      },
      {
        id: '36eec405-38d2-44bd-a87b-15527b43c48a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71476596815, 38.25697389577],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71476596815, 38.25697389577],
            [-85.71477381695, 38.25696531605],
          ],
          type: 'LineString',
        },
      },
      {
        id: '36ffed60-b075-47c8-b519-0b9722da1ad2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714195183, 38.25725119278],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.714195183, 38.25725119278],
            [-85.71418768051, 38.25724859298],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3b6e8864-e629-4d3e-85f0-0824623cd9fb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71408376341, 38.25775675188],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408376341, 38.25775675188],
            [-85.71408376341, 38.25775675188],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3b7cd71d-bd9c-4e14-890b-88176ce75bbe',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71415602013, 38.25753448134],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71415602013, 38.25753448134],
            [-85.71417897724, 38.25754259894],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3b841e3a-6b74-48d6-a199-d1bf8e0f1f93',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71388890142, 38.25835364877],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3ba497d1-94c2-4665-b668-db44aa35fbe8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3d607b77-17b1-48bc-bab3-69bb9aace67a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71422966471, 38.25759596002],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7142968654, 38.25762120515],
            [-85.71428005569, 38.25761407037],
            [-85.71418623003, 38.25758019756],
            [-85.71416246402, 38.25757071489],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3d8ea636-e601-42ee-bb54-6141e99ff8f5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71411129309, 38.2577104508],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71411129309, 38.2577104508],
            [-85.71408526856, 38.25770071651],
          ],
          type: 'LineString',
        },
      },
      {
        id: '3e9a615d-a358-47c6-b004-3d2a4c7da3bf',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71445349545, 38.256952942675],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71442281425, 38.25698906166],
            [-85.71442972508, 38.25699132115],
            [-85.71444560364, 38.25696775539],
            [-85.71446310011, 38.25693817343],
            [-85.71447522209, 38.2569145642],
            [-85.71448417665, 38.25691754386],
          ],
          type: 'LineString',
        },
      },
      {
        id: '40085989-ae45-4622-94b8-9bbf84106036',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71480101288, 38.25701315473],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71480101288, 38.25701315473],
            [-85.71478118323, 38.25697897436],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4018f28e-da63-4ce5-98c0-e9293abfbaec',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71476221319, 38.25704537657],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71476221319, 38.25704537657],
            [-85.71475332988, 38.25704243812],
          ],
          type: 'LineString',
        },
      },
      {
        id: '404fe732-a35b-4e4e-87b5-2640ba967a6d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71468739458, 38.25685666533],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71468739458, 38.25685666533],
            [-85.71468282115, 38.25686530517],
          ],
          type: 'LineString',
        },
      },
      {
        id: '40af98ae-bfe8-4597-8bea-7af3a484902a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71393805796, 38.25846841862],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71393805796, 38.25846841862],
            [-85.71393805796, 38.25846841862],
          ],
          type: 'LineString',
        },
      },
      {
        id: '40d22935-d92f-4958-9d45-21ad4161b32c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71466564445, 38.25689766173],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71466564445, 38.25689766173],
            [-85.71476498367, 38.25693286941],
          ],
          type: 'LineString',
        },
      },
      {
        id: '41c690f8-5452-45d1-b1df-723affb89c0d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.714605166225, 38.25685641687],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71459674359, 38.25687149765],
            [-85.71460335731, 38.25685965577],
            [-85.71461358886, 38.25684133609],
          ],
          type: 'LineString',
        },
      },
      {
        id: '41ed8ae0-bded-481f-b85f-8991f5976382',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71410615002, 38.25736944508],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71410615002, 38.25736944508],
            [-85.71411107258, 38.25736045726],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4276fe4e-331c-4a64-acfd-66e1dc91db04',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7143509865, 38.25760601714],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7143509865, 38.25760601714],
            [-85.71432112734, 38.25759670518],
          ],
          type: 'LineString',
        },
      },
      {
        id: '453ae181-f86c-47ac-aef0-91309a4fc0b2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71451493747, 38.25681188783],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71451493747, 38.25681188783],
            [-85.71452070427, 38.25680037542],
          ],
          type: 'LineString',
        },
      },
      {
        id: '45544059-f353-4439-9804-b0c3573c1a78',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71444453554, 38.25742903558],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71444453554, 38.25742903558],
            [-85.71445600749, 38.25743358662],
          ],
          type: 'LineString',
        },
      },
      {
        id: '45c06808-6481-42b7-a0e5-d92b05daafa0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460947834, 38.25686195039],
            [-85.71463852805, 38.25688669734],
          ],
          type: 'LineString',
        },
      },
      {
        id: '46f466e5-f13b-4b44-ad39-d40ecad319cd',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71437395057, 38.2572741283],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71437395057, 38.2572741283],
            [-85.71436826151, 38.25728356469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4760a66b-73e6-4655-b28d-0bf4d730e3ad',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71418987199, 38.25775878959001],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71421380629, 38.25768698669],
            [-85.71416593769, 38.2577703802],
            [-85.7142058228, 38.25778449669],
          ],
          type: 'LineString',
        },
      },
      {
        id: '48085791-00d5-473a-a0ae-5c7f564e1119',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71441166077, 38.25747767042],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71441166077, 38.25747767042],
            [-85.71442629531, 38.25748296848],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4873b5fa-39f4-4179-9738-bcd1ed640c77',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71451301999, 38.25738522934],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71451301999, 38.25738522934],
            [-85.71453007396, 38.25739148223],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4959f614-4189-4159-936f-61bc502ece48',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71391008018, 38.25831601215],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4bf22499-fe34-4408-bc55-431d8dce3c93',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714607865495, 38.25685523287],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460008752, 38.25686963169],
            [-85.71460503067, 38.25686028449],
            [-85.71461564347, 38.25684083405],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4c2d1f14-2b70-477e-b9a7-da2620788394',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.7147284608, 38.25686233387],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71471818653, 38.25687576546],
            [-85.71473136065, 38.2568520596],
            [-85.71473617239, 38.25685370811],
            [-85.71473873507, 38.2568546118],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4c7b02a3-0831-4989-9b20-85f9f42e207b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145129057, 38.25677721288],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145129057, 38.25677721288],
            [-85.71449943151, 38.25680210971],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4ca81479-6081-42ed-8c3c-be90f762cdb0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71438475988, 38.25758224729],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71438475988, 38.25758224729],
            [-85.71437050253, 38.25757677717],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4d1d4e0a-a0dd-4eff-b013-488a3b08cd0e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71481279811, 38.256893123889995],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71482653615, 38.25687781863],
            [-85.71481956291, 38.25687514],
            [-85.71479906007, 38.25690805115],
            [-85.71480701735, 38.25691110778],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4d4a856d-c8e3-4190-902b-4ca66345465e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71454257882, 38.25748602749],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71454257882, 38.25748602749],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4e23da61-b7ae-456a-a513-b48398c309e4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71357207259, 38.25820362701],
            [-85.71357207259, 38.25820362701],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4e9e5fed-0f8f-4f35-9a68-e3b1fcc2a3df',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71408376341, 38.25775675188],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408376341, 38.25775675188],
            [-85.71405793094, 38.2577467546],
          ],
          type: 'LineString',
        },
      },
      {
        id: '4f0dde80-7745-4e1f-9b39-5a809db7558b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71387604361, 38.25839051528],
          ],
          type: 'LineString',
        },
      },
      {
        id: '51701ecc-6d87-4813-afaa-062db4cd5216',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71453703185, 38.25690406546],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71450568109, 38.25689522053],
            [-85.71454581823, 38.25690538164],
            [-85.71456356664, 38.25691291039],
          ],
          type: 'LineString',
        },
      },
      {
        id: '51b4223c-01f8-4b80-9901-c31f9293d85b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714221379185, 38.257424483815],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7143921786, 38.25767253021],
            [-85.71437930311, 38.25766806737],
            [-85.71432301648, 38.25764679775],
            [-85.71432112734, 38.25759670518],
            [-85.71433298488, 38.25757561172],
            [-85.714309792, 38.25756757307],
            [-85.71429364911, 38.25756197771],
            [-85.71427035309, 38.25755390291],
            [-85.71418736458, 38.25752513769],
            [-85.71406682796, 38.2574843336],
            [-85.71404718554, 38.25747644355],
            [-85.71406785801, 38.25744082733],
            [-85.71408188666, 38.25741665763],
            [-85.71410239222, 38.25738132895],
            [-85.71411774704, 38.25735487437],
            [-85.71412664598, 38.25733954253],
            [-85.71412899291, 38.25731268884],
            [-85.71415057663, 38.25727488104],
            [-85.71415156403, 38.25727313641],
            [-85.71416531668, 38.25724929104],
            [-85.71418495029, 38.25721524575],
            [-85.71418785956, 38.25721020451],
            [-85.71420733447, 38.25717643742],
            [-85.71426776121, 38.25719809555],
            [-85.71435123732, 38.25722801504],
            [-85.71436589666, 38.25723275725],
            [-85.71439557283, 38.2572449448],
            [-85.71437210341, 38.25728761658],
          ],
          type: 'LineString',
        },
      },
      {
        id: '51d26f96-e22f-43e5-9980-c02649f44b23',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '526404e5-e342-4374-8c04-9cb43a66d3ed',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71395114859, 38.25789630695],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71395114859, 38.25789630695],
            [-85.71397266333, 38.25790377104],
          ],
          type: 'LineString',
        },
      },
      {
        id: '528be0d7-846d-479c-8dd4-62ea486d94b5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71446718099, 38.25680954998],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71446718099, 38.25680954998],
            [-85.7144744111, 38.25681221848],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5323dcdc-a1ea-438a-882f-e5ff8b64b43f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71408423021, 38.25821116589],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408423021, 38.25821116589],
            [-85.71408423021, 38.25821116589],
          ],
          type: 'LineString',
        },
      },
      {
        id: '534e2d52-b3d1-43d3-ba3d-61c6710b1faa',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71477173059, 38.25698238639],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5373b4fb-1c77-4b4f-93e4-727b856ad3d6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71469286233, 38.25683843039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71469286233, 38.25683843039],
            [-85.71469241213, 38.25686940777],
          ],
          type: 'LineString',
        },
      },
      {
        id: '53dec905-bb69-4b8f-af95-8ae2ca71fbb2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71434907416, 38.25719932376],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71434907416, 38.25719932376],
            [-85.71434317604, 38.2572095909],
          ],
          type: 'LineString',
        },
      },
      {
        id: '54e03025-38bc-4ff7-8d5e-e189223b8aec',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71363408348, 38.25823993737],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71351312318, 38.25833507816],
            [-85.71356466839, 38.25835253095],
            [-85.71362867444, 38.2582214384],
            [-85.71360881949, 38.25814417186],
            [-85.71361843763, 38.25812734379],
            [-85.71375504378, 38.2581766866],
          ],
          type: 'LineString',
        },
      },
      {
        id: '55c4474d-d715-4406-b1f1-de124e37fdc0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71413849896, 38.25726871969],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71413849896, 38.25726871969],
            [-85.71415156403, 38.25727313641],
          ],
          type: 'LineString',
        },
      },
      {
        id: '56f023a2-a9f0-407d-82d3-bb8f23f1d737',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71467407353, 38.256883590055],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71466220302, 38.25690340692],
            [-85.71466564445, 38.25689766173],
            [-85.71467733537, 38.25687814468],
            [-85.7146801894, 38.25687338016],
            [-85.71468594404, 38.25686377319],
          ],
          type: 'LineString',
        },
      },
      {
        id: '59c3a485-c488-4612-a68d-c26fc2504dc3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71359025065, 38.25813760798],
            [-85.71357207259, 38.25820362701],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5a5bb4dd-5e86-47c6-819b-88edbe6fef72',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460947834, 38.25686195039],
            [-85.71463852805, 38.25688669734],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5a732f12-809e-4622-b8f3-a034606646ff',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71448207126, 38.25729246737],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71448207126, 38.25729246737],
            [-85.71447730266, 38.25730157952],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5b261493-1ed5-43fc-89dd-4b75edcdebe4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.713999350745, 38.258167351455],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.713968559, 38.25815672774],
            [-85.71401799716, 38.25817371057],
            [-85.71403014249, 38.25817797517],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5b60117c-1799-4660-bb93-47205f654ee2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714500565185, 38.25713247383],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71457112138, 38.25710285777],
            [-85.71457812113, 38.25710588202],
            [-85.71454614125, 38.25716208989],
            [-85.71444893643, 38.25712804191],
            [-85.71442300924, 38.25711761548],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5b712318-9716-40dc-ba0c-9eda114c229d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7140576627, 38.25743735686],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7140576627, 38.25743735686],
            [-85.71406785801, 38.25744082733],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5b8f22a4-3975-4eca-9bd2-074323d6702a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71456356664, 38.25691291039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71456356664, 38.25691291039],
            [-85.71457772897, 38.25691906285],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5baf2155-1ca6-437d-9116-a0fbfd6d93c8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71454257882, 38.25748602749],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71454257882, 38.25748602749],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5bc17d81-14f1-4bac-92d2-062257d430b4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71452608657, 38.25677932829],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71452608657, 38.25677932829],
            [-85.71452070427, 38.25680037542],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5bd5f8be-f25e-4859-ad2f-c20992cd8e45',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714717714165, 38.257036533960004],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71470192223, 38.25706389042],
            [-85.71470215959, 38.25704303114],
            [-85.71471877148, 38.2570382663],
            [-85.7147335061, 38.2570091775],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5c1559df-2f25-4d95-96f8-0d19dac67647',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71478086891, 38.25696747662],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71478086891, 38.25696747662],
            [-85.71477300942, 38.25696465809],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5c39f164-ac8d-4525-9d3c-521d25be1cfc',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71466047316, 38.2570526529],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71466047316, 38.2570526529],
            [-85.71466500729, 38.25704499403],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5c4c44e7-d94e-410e-83a4-48c38cf5ef8f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71458442866, 38.25684509571],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5d5fdf56-7d6e-481a-ae2b-4d7815ffde86',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71388890142, 38.25835364877],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5d9b7aa6-5fc1-428a-85c0-07de0838a052',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71422562932, 38.25736198727],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71422562932, 38.25736198727],
            [-85.7142327617, 38.25735057871],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5dcffb4a-8063-4691-a005-24c8cbb4f59e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71361052243304, 38.25823417468537],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71387604361, 38.25839051528],
            [-85.71375405574, 38.25834612703],
            [-85.71368082583, 38.25831674096],
            [-85.7136210884, 38.25829605237],
            [-85.71359821195, 38.25821241673],
            [-85.71360800503, 38.25819420082],
            [-85.71373198619, 38.25823828805],
            [-85.71368082583, 38.25831674096],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5e0d9965-62fc-4fc6-862e-005c2f5081dc',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71463852805, 38.25688669734],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71463852805, 38.25688669734],
            [-85.71460947834, 38.25686195039],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5ebf44a3-e545-4ec1-855f-ba8fd92f318f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71473021757, 38.25688769054],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71473326769, 38.25688099134],
            [-85.71473030301, 38.25688650831],
            [-85.71472716745, 38.25689438974],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5eede141-158e-4a3d-9684-e9db101930a6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71448111685498, 38.257481874735],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71452606668, 38.25748812416],
            [-85.71452130324, 38.25749685801],
            [-85.71443585836, 38.25746689146],
          ],
          type: 'LineString',
        },
      },
      {
        id: '5fab8a86-a2d3-486c-a6cb-626a24ae5a5e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71442063224, 38.256916783445],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71446446232, 38.25693115643],
            [-85.71441996763, 38.2569158183],
            [-85.7144129428, 38.25691339681],
            [-85.71438107255, 38.25690241046],
            [-85.71437680216, 38.25691004902],
          ],
          type: 'LineString',
        },
      },
      {
        id: '601b8d47-0487-4fbd-944f-ada452df587a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460947834, 38.25686195039],
            [-85.71460947834, 38.25686195039],
          ],
          type: 'LineString',
        },
      },
      {
        id: '605a1784-092e-48d7-9a9e-853e55554635',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71480101288, 38.25701315473],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71480101288, 38.25701315473],
            [-85.71477738679, 38.25702424868],
          ],
          type: 'LineString',
        },
      },
      {
        id: '608c7c96-9c01-4e49-8da8-ffa9fe5dc2ea',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460947834, 38.25686195039],
            [-85.71460947834, 38.25686195039],
          ],
          type: 'LineString',
        },
      },
      {
        id: '61272d50-fb1b-49de-9523-2f077c897680',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71482478424, 38.25691557699],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71482478424, 38.25691557699],
            [-85.71481807691, 38.25691350283],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6184ee72-0998-4485-8124-1ddb0867dbea',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71468151445, 38.25693463322],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71468151445, 38.25693463322],
            [-85.71468558214, 38.25692731855],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6319fa94-325a-4ef2-b321-4c6c16a966d6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71432458014, 38.25742538219],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71432458014, 38.25742538219],
            [-85.71432997848, 38.25741688783],
          ],
          type: 'LineString',
        },
      },
      {
        id: '642e8a13-254f-4596-b09b-60bdb287221c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71414335285, 38.2572882105],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71414335285, 38.2572882105],
            [-85.71416139907, 38.25729449465],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6499813c-f9d2-4ad0-a01a-5b8830790176',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71421091845, 38.25788699785],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71421091845, 38.25788699785],
            [-85.7141969159, 38.2578821528],
          ],
          type: 'LineString',
        },
      },
      {
        id: '655b3a80-139d-4d24-ab16-a10eecaf6f52',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714554582125, 38.256997789485],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460087421, 38.2570174851],
            [-85.71457768324, 38.25700680654],
            [-85.7145551396, 38.25699564143],
            [-85.71454726071, 38.25699173928],
            [-85.71450829004, 38.25697809387],
          ],
          type: 'LineString',
        },
      },
      {
        id: '65e1b53d-e7ae-4d65-905a-9b6a0b2862c1',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71391008018, 38.25831601215],
          ],
          type: 'LineString',
        },
      },
      {
        id: '67456501-f2cf-491d-8d68-efc07ae3ad85',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.714221458115, 38.257883012505],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71393805796, 38.25846841862],
            [-85.71386195431, 38.25844203743],
            [-85.71387604361, 38.25839051528],
            [-85.71386622339, 38.25835601807],
            [-85.71387661302, 38.25830898466],
            [-85.71392768585, 38.25828907157],
            [-85.71394172443, 38.25826517223],
            [-85.7139506392, 38.25825901775],
            [-85.71399435407, 38.25821901226],
            [-85.71402043385, 38.25819519628],
            [-85.71403014249, 38.25817797517],
            [-85.71406310088, 38.25811951374],
            [-85.71411721011, 38.25802353494],
            [-85.71414061549, 38.25798201852],
            [-85.71415936196, 38.25794876604],
            [-85.7141969159, 38.2578821528],
            [-85.71420042335, 38.25787593129],
            [-85.71421716242, 38.25784623974],
            [-85.71432969589, 38.25764539058],
            [-85.71433864227, 38.25763034623],
            [-85.71434268407, 38.25762355046],
            [-85.71435461264, 38.25760349405],
            [-85.71436246174, 38.25759029678],
            [-85.71436634257, 38.25758377163],
            [-85.71437050253, 38.25757677717],
            [-85.71437355868, 38.25757163863],
            [-85.71438815127, 38.257547103],
            [-85.71441699626, 38.25749860371],
            [-85.71442629531, 38.25748296848],
            [-85.71443585836, 38.25746689146],
            [-85.71444066904, 38.25745880105],
            [-85.71445600749, 38.25743358662],
            [-85.71448079458, 38.25742752469],
            [-85.71451238911, 38.25742123666],
            [-85.71453007396, 38.25739148223],
            [-85.71455662328, 38.25734681347],
            [-85.71458096192, 38.25730596019],
            [-85.71455888724, 38.25729760639],
            [-85.71455311955, 38.25730698563],
          ],
          type: 'LineString',
        },
      },
      {
        id: '67621b72-493f-478f-bf47-5d4b2b79578d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71478603446, 38.25696944064],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71478603446, 38.25696944064],
            [-85.71478118323, 38.25697897436],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6829ed88-9a5a-4b85-b4d4-83f60f023153',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71478993839, 38.25689391729],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71478993839, 38.25689391729],
            [-85.71478683866, 38.25689922657],
          ],
          type: 'LineString',
        },
      },
      {
        id: '685874c3-b0cd-426d-b4d2-9a0dbdcde13c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71473155725, 38.25684583236],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71473155725, 38.25684583236],
            [-85.71472890745, 38.25685123944],
          ],
          type: 'LineString',
        },
      },
      {
        id: '68610436-2aa5-4581-b3c8-7755d8c22108',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71461145975, 38.25685567427],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460714887, 38.25686340891],
            [-85.7146096973, 38.25685883647],
            [-85.71461577063, 38.25684793963],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6904c833-2472-4210-88c7-102291b1382f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71420492024, 38.25719320182],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71420492024, 38.25719320182],
            [-85.71420126745, 38.2571992748],
          ],
          type: 'LineString',
        },
      },
      {
        id: '697d403a-0db2-4195-875c-e02538d5293d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714692165575, 38.256904790395],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71471126667, 38.2568856893],
            [-85.71466915215, 38.25690431656],
            [-85.71466678292, 38.25690892522],
            [-85.71471113299, 38.25692389149],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6a3b4381-464f-4878-9824-6e234ff17de1',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71381672652, 38.25854652913],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71381672652, 38.25854652913],
            [-85.71382721285, 38.25852805547],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6b11a304-3d02-45ab-b9a8-1ae977de3282',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71445365566, 38.25680376416],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71445365566, 38.25680376416],
            [-85.71445699773, 38.25679845916],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6bb6af87-a427-49b5-8908-174070c8c7d7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71444389813, 38.25678743729],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71444389813, 38.25678743729],
            [-85.71444109184, 38.25679286286],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6c8fb05a-752a-480d-9fef-2671f17ba4fa',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71359025065, 38.25813760798],
            [-85.71359025065, 38.25813760798],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6cdb8797-2f98-40b5-81cc-2f0ce2e41565',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71479323523, 38.25692923805],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71479323523, 38.25692923805],
            [-85.71478746782, 38.25692708402],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6d247482-0e38-4ce9-a5e3-5301ce1379f6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71474930512, 38.25696044616],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71474930512, 38.25696044616],
            [-85.71475490187, 38.25695158248],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6e04943d-636a-4f88-886a-a6d8caa4a3e4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71480101288, 38.25701315473],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6e1a8f81-3e08-4f97-a043-5c9635598cbc',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71404032704501, 38.25818832818],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408423021, 38.25821116589],
            [-85.71407206471, 38.25819748873],
            [-85.71399642388, 38.25816549047],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6e31ee7c-8c55-4512-9d03-51c7c795145a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71391008018, 38.25831601215],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6e32029d-436a-4fc3-a2e5-508f32557e24',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71391908932115, 38.258051967114085],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71406906175, 38.25794096725],
            [-85.71401963874, 38.25803293802],
            [-85.71390714906, 38.25799229517],
            [-85.71390077551, 38.25800317409],
            [-85.71388895598, 38.25802334283],
            [-85.71385886567, 38.25807469385],
            [-85.7138466267, 38.2580934385],
            [-85.71400008175, 38.25814888218],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6ef7919e-a22a-4a89-82c5-41a8a1aba96d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71396148598, 38.25832134021],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6f21f5b4-c0a0-4ff1-b72e-1cc4136d8f4a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71460963390501, 38.256810496245],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71461386203, 38.25680626812],
            [-85.71460922151, 38.25681472437],
            [-85.71460177215, 38.25681220333],
            [-85.71459306723, 38.25680925726],
            [-85.71458618257, 38.25680692782],
          ],
          type: 'LineString',
        },
      },
      {
        id: '6f869c33-7599-4e3e-bc3b-66f0a3113c06',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71443596202, 38.25688835627],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71443596202, 38.25688835627],
            [-85.71441996763, 38.2569158183],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7140edbd-378b-4a2b-9356-78de98795e75',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71444036115, 38.25681709806],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71444036115, 38.25681709806],
            [-85.71444470509, 38.25680957917],
          ],
          type: 'LineString',
        },
      },
      {
        id: '718ee5cb-332e-4ce9-9fc7-bd8b2063f4c4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71396692524, 38.25831176685],
          ],
          type: 'LineString',
        },
      },
      {
        id: '72ba92e1-44d9-4d10-82af-867bad6ce981',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71480936975, 38.25690897276],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71480936975, 38.25690897276],
            [-85.7147981547, 38.2569037146],
          ],
          type: 'LineString',
        },
      },
      {
        id: '72e0555c-e080-4bbb-bbcc-d12b03cd370c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71464379624, 38.25705330347],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71464379624, 38.25705330347],
            [-85.71465962838, 38.25702495463],
          ],
          type: 'LineString',
        },
      },
      {
        id: '731b363c-9199-4d76-99ff-3ea858d38d83',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71434999722, 38.25763462068],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71434999722, 38.25763462068],
            [-85.71433864227, 38.25763034623],
          ],
          type: 'LineString',
        },
      },
      {
        id: '73fb8bd9-1166-42a8-97e5-85621ab06c1c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71445284226, 38.25678079872],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71445284226, 38.25678079872],
            [-85.71443670322, 38.25681658422],
          ],
          type: 'LineString',
        },
      },
      {
        id: '74475dd4-d3ba-400c-a390-d8cd657c69fa',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71463852805, 38.25688669734],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71463852805, 38.25688669734],
            [-85.71460947834, 38.25686195039],
          ],
          type: 'LineString',
        },
      },
      {
        id: '75086ae2-d7c3-4570-9c2a-f121627f1aee',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '752b47cb-164e-4248-9221-d6b7175d5811',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71457158474, 38.25694710319],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71457158474, 38.25694710319],
            [-85.71454126024, 38.25693664716],
          ],
          type: 'LineString',
        },
      },
      {
        id: '76237396-1ab9-4a4e-a632-e8a41be74116',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71477173059, 38.25698238639],
          ],
          type: 'LineString',
        },
      },
      {
        id: '768e5f07-6011-4a6a-a1d4-57d1676800d3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71407189634, 38.25741320098],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71407189634, 38.25741320098],
            [-85.71408188666, 38.25741665763],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7705cd03-c908-4508-912e-6cc0d0900e26',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714543559515, 38.256923798784996],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.714574521, 38.25691213475],
            [-85.71456642379, 38.25690963669],
            [-85.71455231631, 38.25693796088],
            [-85.7145260641, 38.25693032099],
            [-85.71451259803, 38.2569263378],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7765e189-5f89-462c-b6b3-9afe59fe5ba1',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71398585094, 38.25828075108],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7773f1fa-bd77-4177-ba0d-c7ce309c7ef7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71451526769, 38.25683105859],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71451526769, 38.25683105859],
            [-85.71449407157, 38.25681150316],
          ],
          type: 'LineString',
        },
      },
      {
        id: '77e7c5e5-d1bc-4df9-83fd-bbb396e4693b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71434907416, 38.25719932376],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71434907416, 38.25719932376],
            [-85.71436478018, 38.25720491086],
          ],
          type: 'LineString',
        },
      },
      {
        id: '77ed10ed-cfbf-47b8-b78e-b30161f47dc3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7144828981, 38.25692140901],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7144828981, 38.25692140901],
            [-85.71450990928, 38.25693179858],
          ],
          type: 'LineString',
        },
      },
      {
        id: '79784a32-85bf-4539-b35e-df6ef1a833cf',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71451378166, 38.25681960755],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71451378166, 38.25681960755],
            [-85.71451939224, 38.25680883742],
          ],
          type: 'LineString',
        },
      },
      {
        id: '79e1b4ae-abd7-4126-a3ef-46e250f296bc',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7140794335, 38.2577642415],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7140794335, 38.2577642415],
            [-85.71406126457, 38.25775824997],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7a704ec5-9ab0-4f2e-bec6-a0ae97f8e7fd',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71417071162, 38.25725325289],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71417071162, 38.25725325289],
            [-85.7141827511, 38.2572572024],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7a874e52-74be-4aa7-a3af-2e9e65b1fd71',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71453385077, 38.25690802804],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71453385077, 38.25690802804],
            [-85.71455311862, 38.25691391273],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7b1ce273-35ec-455d-b657-f972b67a5de4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7eb8c5e9-d1af-409c-a848-c9c72a670487',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71480101288, 38.25701315473],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71480101288, 38.25701315473],
            [-85.71478118323, 38.25697897436],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7ed33cdc-cd2a-47f6-a251-6100a2879093',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71397941322999, 38.258261953680005],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71399821063, 38.25825953301],
            [-85.71395260726, 38.25824315628],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7f416b84-8c8f-4ca1-8742-d09bb7e1b189',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71427186661, 38.257635926579994],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71393805796, 38.25846841862],
            [-85.71392746791, 38.25846455673],
            [-85.71400187632, 38.25833415584],
            [-85.7139624032, 38.25832025351],
            [-85.71391707568, 38.25830428928],
            [-85.71394031455, 38.25825931134],
            [-85.71394508583, 38.2582509936],
            [-85.71400008175, 38.25814888218],
            [-85.71403741282, 38.25809005597],
            [-85.71411430465, 38.25795602351],
            [-85.71408646795, 38.2579473045],
            [-85.71406906175, 38.25794096725],
            [-85.71400803854, 38.25791902028],
            [-85.71396488059, 38.25790345638],
            [-85.71401611771, 38.25781717037],
            [-85.71405793094, 38.2577467546],
            [-85.71408526856, 38.25770071651],
            [-85.7141075866, 38.25766313167],
            [-85.71413353428, 38.25761943427],
            [-85.71416246402, 38.25757071489],
            [-85.71416753023, 38.25756218374],
            [-85.71417897724, 38.25754259894],
            [-85.71418882255, 38.25752575471],
            [-85.71420794952, 38.25749303059],
            [-85.71422480619, 38.25746416335],
            [-85.7142279952, 38.25745870213],
            [-85.71425378361, 38.25741169119],
            [-85.71426392822, 38.25739320998],
            [-85.71426940096, 38.25738324608],
            [-85.71432507623, 38.25726870936],
            [-85.71435683979, 38.25721279224],
            [-85.71445578398, 38.2570436907],
            [-85.71446682595, 38.25702355905],
            [-85.71450829004, 38.25697809387],
            [-85.71453094233, 38.25695323777],
            [-85.71454250625, 38.25694057488],
            [-85.71455125323, 38.25693099663],
            [-85.71456356664, 38.25691291039],
            [-85.71458044388, 38.25688988916],
            [-85.71459138986, 38.25687410906],
            [-85.71459994843, 38.25685868333],
            [-85.71460947026, 38.25684152142],
            [-85.71462665754, 38.2568106188],
            [-85.71460491195, 38.25680343454],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7f6ef3eb-7186-437a-9121-2042f1c07a27',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7144596799, 38.25748231352],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71447702385, 38.25751823624],
            [-85.71446952678, 38.25751563956],
            [-85.71448473801, 38.25748938495],
            [-85.71443462179, 38.2574715549],
            [-85.71443786971, 38.25746572764],
            [-85.71444258539, 38.25745725541],
          ],
          type: 'LineString',
        },
      },
      {
        id: '7fbe5b61-ad11-4204-a8a0-b0a2491867d4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71411534294, 38.2582250959],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71411534294, 38.2582250959],
            [-85.71408423021, 38.25821116589],
          ],
          type: 'LineString',
        },
      },
      {
        id: '80226140-ba29-4d16-9839-26c941685b16',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7140794335, 38.2577642415],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7140794335, 38.2577642415],
            [-85.71408376341, 38.25775675188],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8024f1df-cdcd-471d-9b1b-f27ee3f797bf',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71377948884, 38.25816921975],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71378289563, 38.25818026124],
            [-85.71379053033, 38.25816636684],
            [-85.71376636152, 38.25815817826],
          ],
          type: 'LineString',
        },
      },
      {
        id: '80ba5e27-2d5b-4094-ab59-2d5e0bcee74e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71475650882, 38.25703704577],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71475650882, 38.25703704577],
            [-85.71475281929, 38.25704319079],
          ],
          type: 'LineString',
        },
      },
      {
        id: '80dbf80f-8f48-4536-b053-d97168f807bd',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71391008018, 38.25831601215],
          ],
          type: 'LineString',
        },
      },
      {
        id: '81c40e98-2468-4e33-9b86-eb70e8dab148',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71445807251, 38.25743639071],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71445807251, 38.25743639071],
            [-85.71447085719, 38.25744079296],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8230ec04-b1ba-4281-8cf9-ae333661daa4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7140794335, 38.2577642415],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7140794335, 38.2577642415],
            [-85.71408376341, 38.25775675188],
          ],
          type: 'LineString',
        },
      },
      {
        id: '84d3c2da-d0c8-4660-9e67-f1a9c1d69c32',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.713745473025, 38.258129002355],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71392075995, 38.25803472165],
            [-85.71388895598, 38.25802334283],
            [-85.71369456288, 38.25795371543],
            [-85.7135966462, 38.25812637187],
            [-85.71359025065, 38.25813760798],
            [-85.71355500112, 38.25819803305],
            [-85.71357207259, 38.25820362701],
            [-85.71389123346, 38.2582948904],
            [-85.71391707568, 38.25830428928],
          ],
          type: 'LineString',
        },
      },
      {
        id: '85023b01-07fb-4ffe-84aa-0d5337fb26ec',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714443749495, 38.257272121115],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71442368789, 38.25725205951],
            [-85.7144638111, 38.25726654073],
            [-85.71445778135, 38.2572793231],
            [-85.7144512365, 38.25729395961],
          ],
          type: 'LineString',
        },
      },
      {
        id: '86076d9e-cb24-4f4d-a1aa-9c47e71bb710',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71359025065, 38.25813760798],
            [-85.71359025065, 38.25813760798],
          ],
          type: 'LineString',
        },
      },
      {
        id: '86407c9e-9bea-4e44-879c-f6f0954a70c3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71450218524, 38.25692341891],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71450218524, 38.25692341891],
            [-85.71448417665, 38.25691754386],
          ],
          type: 'LineString',
        },
      },
      {
        id: '86a91008-74ba-4dbc-a4a6-d3fae468d01e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71460480227, 38.25700281178],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460480227, 38.25700281178],
            [-85.71459311451, 38.25699910008],
          ],
          type: 'LineString',
        },
      },
      {
        id: '870259f0-20fa-4bb9-b949-f78f646073ca',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71401466409, 38.25796902206],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71401466409, 38.25796902206],
            [-85.7139472164, 38.25794556572],
          ],
          type: 'LineString',
        },
      },
      {
        id: '88f5ff3e-15a2-4d23-ad88-f33ed264463d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71427013451, 38.25736422493],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71427013451, 38.25736422493],
            [-85.7142327617, 38.25735057871],
            [-85.71412899291, 38.25731268884],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8930b1cb-09d0-4587-bd61-e27e7e63996c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71412696346, 38.25735792266],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71412696346, 38.25735792266],
            [-85.71411774704, 38.25735487437],
          ],
          type: 'LineString',
        },
      },
      {
        id: '89480ece-9104-47c0-888e-d83af1e4bc4c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.7144261291, 38.25750150706],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7144261291, 38.25750150706],
            [-85.71441699626, 38.25749860371],
          ],
          type: 'LineString',
        },
      },
      {
        id: '89e64673-7023-4a50-b3fe-ecfea53eb696',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71455444595, 38.2568090534],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71455444595, 38.2568090534],
            [-85.71455057493, 38.25681558723],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8a221a13-a70c-4fbe-92a2-21a037e5787d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71391707568, 38.25830428928],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8ab0ea45-bac9-4b2c-8c3a-0dc53bb4780a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71453981844, 38.25734101241],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71453981844, 38.25734101241],
            [-85.71455662328, 38.25734681347],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8b210347-e56f-4fb2-9e1a-f3464e4cc3fc',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71388890142, 38.25835364877],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8b79ba11-9069-4101-8b80-4f081a6dd0f1',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71435971011, 38.25756705057],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71435971011, 38.25756705057],
            [-85.71437355868, 38.25757163863],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8bbce3ab-8c86-4632-828a-af078ac6643f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71392874951499, 38.258370661835],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71388639114, 38.25835875115],
            [-85.71397110789, 38.2583876749],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8bef2ca8-fba4-4ad4-9947-8868476efdde',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714788400745, 38.256945436335],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71479012861, 38.25695323993],
            [-85.71479594093, 38.25694336785],
            [-85.71478704232, 38.25694013911],
            [-85.71478086056, 38.25693789615],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8c64727a-d1f2-49c3-b819-fc7f4d4c9c66',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71457914983, 38.256815186989996],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458801709, 38.25679775242],
            [-85.7145749735, 38.25682249793],
            [-85.71457028257, 38.25683262156],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8caa5291-6f2f-4256-be20-fbb9fa1e98ad',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71430819255, 38.25753557804],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71430819255, 38.25753557804],
            [-85.71430819255, 38.25753557804],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8db5d625-a5b4-4e48-b9a7-5f357cda4352',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71448789774, 38.25741476677],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71448789774, 38.25741476677],
            [-85.71448079458, 38.25742752469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8e4ccf29-d552-4c6e-b6a6-912ad43a4f7b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71448108542, 38.256802204815],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71442188529, 38.25675678337],
            [-85.71441080768, 38.25677762781],
            [-85.71440137333, 38.256795378],
            [-85.71440986001, 38.25679767035],
            [-85.71442233045, 38.2568022463],
            [-85.71444470509, 38.25680957917],
            [-85.71449965128, 38.25682758674],
            [-85.71456079751, 38.25684762626],
          ],
          type: 'LineString',
        },
      },
      {
        id: '8e9635a3-fc89-4da7-8c3e-3847357aede0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714572297735, 38.257011188350006],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145852575, 38.25702953718],
            [-85.71455933797, 38.2570213143],
            [-85.71456806414, 38.25700435401],
            [-85.71457398842, 38.25699283952],
          ],
          type: 'LineString',
        },
      },
      {
        id: '90b188b4-7611-4976-90c8-6c7e802ca7c0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71457158474, 38.25694710319],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71457158474, 38.25694710319],
            [-85.71457158474, 38.25694710319],
          ],
          type: 'LineString',
        },
      },
      {
        id: '90d20e90-b5ad-4e5f-b974-ac96637b370e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.7139624032, 38.25832025351],
          ],
          type: 'LineString',
        },
      },
      {
        id: '924095c4-a289-4d57-a23b-613a730ca771',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.71445739633, 38.25745927033],
          ],
          type: 'LineString',
        },
      },
      {
        id: '926caea9-d54d-4ca9-a8a6-baddff7cf65c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71438359132, 38.25765988009],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71438359132, 38.25765988009],
            [-85.71437930311, 38.25766806737],
          ],
          type: 'LineString',
        },
      },
      {
        id: '92864e1e-bec9-4e8d-9456-b2e8ef0b5a32',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71408423021, 38.25821116589],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408423021, 38.25821116589],
            [-85.71408423021, 38.25821116589],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9432f136-e568-4fa7-a753-cd9de3727f01',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71479921811, 38.256880657785004],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71482463977, 38.25688235872],
            [-85.71478983296, 38.25686627239],
            [-85.7147779837, 38.25688792018],
            [-85.71477379645, 38.25689504318],
          ],
          type: 'LineString',
        },
      },
      {
        id: '94ee6bfe-dd48-4242-b76a-2eb64e252da0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71460300733, 38.25701428639],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460300733, 38.25701428639],
            [-85.71460460805, 38.25703928604],
          ],
          type: 'LineString',
        },
      },
      {
        id: '95a41040-35b5-4455-ad98-0f7c686071af',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71476490137499, 38.2569790497],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71476505356, 38.25697910851],
            [-85.71475807216, 38.25697571301],
          ],
          type: 'LineString',
        },
      },
      {
        id: '95bb8701-8658-485e-b3cb-b97df25cca5c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '95d949de-19e5-4567-ac21-00b66f77a420',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71413566489, 38.257352584995004],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71412816273, 38.25736648758],
            [-85.71413340723, 38.25735722166],
            [-85.71414316705, 38.25733868241],
          ],
          type: 'LineString',
        },
      },
      {
        id: '965b82fb-b431-4303-a91c-e55981f1fc6d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714221132045, 38.25726240505],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71420182441, 38.25727690246],
            [-85.71422144418, 38.2572834447],
            [-85.71423813486, 38.25725119448],
            [-85.71424043968, 38.2572413654],
          ],
          type: 'LineString',
        },
      },
      {
        id: '967220e4-21cd-473f-9e39-0359f206d78e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71481777986, 38.25700624383],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71481777986, 38.25700624383],
            [-85.71477173059, 38.25698238639],
          ],
          type: 'LineString',
        },
      },
      {
        id: '970f267c-287d-4733-b2f1-655f1a2d0b16',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71438243452, 38.25743072962],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.71441644994, 38.25744393056],
            [-85.71438679389, 38.257431119],
            [-85.71425911574, 38.25738517455],
          ],
          type: 'LineString',
        },
      },
      {
        id: '975db712-7b17-4414-b987-364c3e0a3d79',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71423687392, 38.257405285635],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71421996423, 38.25739888008],
            [-85.71423168389, 38.25740390809],
            [-85.71425378361, 38.25741169119],
          ],
          type: 'LineString',
        },
      },
      {
        id: '977c6dc1-187a-4561-83a3-508a890a11da',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71354643958, 38.25819498046],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71354643958, 38.25819498046],
            [-85.71354130567, 38.25819495549],
          ],
          type: 'LineString',
        },
      },
      {
        id: '97b8b168-807f-48ae-a016-c5a171f895d1',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460947834, 38.25686195039],
            [-85.71460335731, 38.25685965577],
          ],
          type: 'LineString',
        },
      },
      {
        id: '986d08c5-585a-4875-a033-2b257e3ab4fb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71458442866, 38.25684509571],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9952b4ff-472b-4911-9de4-1e382b56440a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.714727055585, 38.256924764044996],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71467733537, 38.25687814468],
            [-85.71476861462, 38.25691025244],
            [-85.71477367495, 38.25691786923],
            [-85.71476498367, 38.25693286941],
            [-85.71476155007, 38.25693879544],
            [-85.71474573503, 38.25696647744],
            [-85.71475114935, 38.25696835813],
            [-85.71475907161, 38.25697138341],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9962851c-62ec-4ddf-8270-5adbc0ffeb04',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71388890142, 38.25835364877],
          ],
          type: 'LineString',
        },
      },
      {
        id: '99c24777-6de8-4dc9-909a-c75f594f88f8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71429728743, 38.25748940644],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71429728743, 38.25748940644],
            [-85.71422480619, 38.25746416335],
          ],
          type: 'LineString',
        },
      },
      {
        id: '99e10133-7f22-4837-b078-a39d3b8b3942',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71454257882, 38.25748602749],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71454257882, 38.25748602749],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9a836806-d6bc-4832-bb3d-324ab19cc641',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71440195737, 38.25745290298],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71440195737, 38.25745290298],
            [-85.71440689109, 38.25744445964],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9aafd8db-9594-4fef-96e1-28340ced11e2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71430819255, 38.25753557804],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71430819255, 38.25753557804],
            [-85.71429075001, 38.25756451459],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9b304f45-8088-421d-86e2-46f2b222ce05',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71477173059, 38.25698238639],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477173059, 38.25698238639],
            [-85.71476131779, 38.25697857865],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9d03c308-4bbe-4c82-a179-5fc88474af2a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71446972424, 38.2569422745],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71446972424, 38.2569422745],
            [-85.71449977713, 38.25695237664],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9d4bd872-da68-44e9-9608-62d1c2496b34',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.714630256395, 38.256817318905],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71459241569, 38.25678333397],
            [-85.71461183125, 38.2567924609],
            [-85.71463972346, 38.25680427255],
            [-85.71466424133, 38.2568128724],
            [-85.71465721994, 38.25682521547],
            [-85.7146471079, 38.25684299162],
            [-85.71464237971, 38.25685130384],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9e21b9c7-f207-43fb-bbce-76507f3e8cc7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71473904386, 38.25684816151],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71473904386, 38.25684816151],
            [-85.71473617239, 38.25685370811],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9e5dd64c-f000-4ef5-86f7-3fd0d0faf9a0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: '9ea53ffa-53fe-4fce-b07b-a3613aa0a1f2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71458442866, 38.25684509571],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a0e6614c-c2b6-4351-9a33-ef4d4b35e0a9',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71434543665, 38.25757534857],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71434543665, 38.25757534857],
            [-85.71436634257, 38.25758377163],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a109d383-70fe-4dae-b188-75ccfbfdb692',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71357207259, 38.25820362701],
            [-85.71357207259, 38.25820362701],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a116e5d6-f1a0-4fbb-9bf5-016b88687db4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71357207259, 38.25820362701],
            [-85.71359025065, 38.25813760798],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a1479cfc-3472-47ca-b149-0f2ffeeb807e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71429813199, 38.25750665261],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71429813199, 38.25750665261],
            [-85.71427035309, 38.25755390291],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a3e49c0c-5884-43b1-9314-444b666d1c41',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71437471741001, 38.25750977114],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71433298488, 38.25757561172],
            [-85.71436202832, 38.25752885779],
            [-85.71441644994, 38.25744393056],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a47be08d-b51e-4a2c-acf8-6845862e2031',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714570879405, 38.25687523175],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71457777332, 38.25685738432],
            [-85.71457088666, 38.25687489818],
            [-85.71456398549, 38.25689307918],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a52268a4-3f5b-41bb-a6c6-b1d3ab4e9279',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71477878309, 38.25696135835],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71477878309, 38.25696135835],
            [-85.71477218709, 38.25695910102],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a54c57ff-05fe-4168-b4f4-a964b7c2a452',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71388890142, 38.25835364877],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a58ef304-6f78-4c38-9935-0188dfeb5487',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71461519277, 38.25678443025],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71461519277, 38.25678443025],
            [-85.71462211325, 38.25677077411],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a5eb403f-13f3-48a7-b86b-bc7361b1b2fe',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71414569166, 38.25794369422],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71414569166, 38.25794369422],
            [-85.71415936196, 38.25794876604],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a62c6e58-1f30-436a-936b-e58a0eac4698',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71480467112, 38.25692445943],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71480467112, 38.25692445943],
            [-85.71479702373, 38.25692181357],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a68049b9-7623-4371-8e00-311ecf610aa5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71471009797, 38.25686397083],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71471009797, 38.25686397083],
            [-85.71470652037, 38.25687109708],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a6abebe3-2e2a-4279-9ea0-771d3ceced6a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71459059325, 38.25683460401],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a72d64a2-80bc-4240-906b-26af62ecd38d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71398585094, 38.25828075108],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a7ed9c85-fed0-45f6-9347-19714353950b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71469167049, 38.25686678728],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71469167049, 38.25686678728],
            [-85.71468870467, 38.25687250011],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a8e22d96-4a96-41f0-9188-919538db1f99',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71396692524, 38.25831176685],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a9614a96-52d0-49f1-84ed-14864cf6af06',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71442729618, 38.256793665],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71442729618, 38.256793665],
            [-85.71442233045, 38.2568022463],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'a992563b-a802-4503-b786-e5318a48ace4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71448934883, 38.25731587551],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71448934883, 38.25731587551],
            [-85.71449447816, 38.25730789715],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'aab75ef0-f765-4126-bd70-97b9fcfcc67b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.71452606668, 38.25748812416],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'abd7c1bd-a54d-4809-85b4-84dadec0024f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71440434504999, 38.25741305109],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71454300277, 38.25736928682],
            [-85.71453645294, 38.25736683185],
            [-85.71451759416, 38.2573993809],
            [-85.71450631545, 38.25741971104],
            [-85.71448536292, 38.25742140436],
            [-85.71447085719, 38.25744079296],
            [-85.71446037402, 38.25745480414],
            [-85.71445739633, 38.25745927033],
            [-85.71444258539, 38.25745725541],
            [-85.71440689109, 38.25744445964],
            [-85.71439553805, 38.25744038978],
            [-85.71432997848, 38.25741688783],
            [-85.71426392822, 38.25739320998],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'acc952ef-5830-429b-818b-62da43e9bba4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71481777986, 38.25700624383],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71481777986, 38.25700624383],
            [-85.71477173059, 38.25698238639],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'accca138-f517-48df-be69-826b9e488aa8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714802233015, 38.25692760196],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71476428969, 38.25696187067],
            [-85.71477381695, 38.25696531605],
            [-85.71478603446, 38.25696944064],
            [-85.71479565375, 38.2569538663],
            [-85.71481086279, 38.25692662022],
            [-85.71481690387, 38.25691579802],
            [-85.71481985933, 38.25691050347],
            [-85.71482300663, 38.25690486525],
            [-85.71483366956, 38.25688576328],
            [-85.71484017634, 38.25688800277],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'adf92deb-c42f-4f7c-ada5-55e4726b233c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71357207259, 38.25820362701],
            [-85.71359821195, 38.25821241673],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ae4903dd-9001-44cf-9b90-240c2c78f90b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.7145920086, 38.25683256298],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'af0a1893-30fd-41fb-a877-428ef7faa7c8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71404718554, 38.25747644355],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71404718554, 38.25747644355],
            [-85.71397370073, 38.2574471895],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'af73b226-51f2-4b5a-9025-1dc634eade31',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71460163258, 38.256813063335],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71461183125, 38.2567924609],
            [-85.71459418052, 38.25682547097],
            [-85.71459143391, 38.25683366577],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'afc16811-bd7e-4f87-8057-298af2fc2a12',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7144835953, 38.2573874905],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7144835953, 38.2573874905],
            [-85.71446842792, 38.257414755],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b217d288-1242-46e1-aec6-0b60adde8007',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71408856643501, 38.257842812075],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71401611771, 38.25781717037],
            [-85.71404313253, 38.25782731469],
            [-85.71405901703, 38.25783285819],
            [-85.71416101516, 38.25786845378],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b2309d69-4d9d-4be0-b84c-9532d768ced0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b28af9b7-8770-4ad0-b307-0633c8b41dd3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714637058755, 38.256849569945004],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71481985933, 38.25691050347],
            [-85.71478683866, 38.25689922657],
            [-85.71475530355, 38.256888457],
            [-85.71471818653, 38.25687576546],
            [-85.71467145452, 38.25685982356],
            [-85.71461564347, 38.25684083405],
            [-85.7145920086, 38.25683256298],
            [-85.71458207304, 38.25682930021],
            [-85.71454291975, 38.25681498016],
            [-85.71452070427, 38.25680037542],
            [-85.71448747154, 38.25678863642],
            [-85.71448044985, 38.25680131485],
            [-85.7144744111, 38.25681221848],
            [-85.71446646366, 38.25682656849],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b51d1321-b690-4b98-8e36-027f68912fce',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71400681172501, 38.258087144265005],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398774288, 38.25809734463],
            [-85.71399299704, 38.25808871119],
            [-85.71400021357, 38.2580769439],
            [-85.7140065776, 38.25807956039],
            [-85.71403741282, 38.25809005597],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b5ae72a5-4ddc-47b7-923e-5566042ccf51',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71439771375, 38.25755030381],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71439771375, 38.25755030381],
            [-85.71438815127, 38.257547103],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b604b2d1-1047-453b-91d3-a4986b01bebc',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71388771428, 38.258225597475004],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71399253293, 38.25826901478],
            [-85.71394508583, 38.2582509936],
            [-85.71383565226, 38.25821083519],
            [-85.7138479976, 38.25819167816],
            [-85.71378774055, 38.25817044387],
            [-85.71378289563, 38.25818026124],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b6b9aaa9-202d-4e7f-842d-5b4e7758b9b2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71478118323, 38.25697897436],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71478118323, 38.25697897436],
            [-85.71477173059, 38.25698238639],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b73d06a2-86c5-48e0-b9d7-e88427904ae8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71472417816, 38.25685314143],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71478983296, 38.25686627239],
            [-85.7147583359, 38.25685157699],
            [-85.71472446296, 38.25684001047],
            [-85.71471009797, 38.25686397083],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b8d585ff-1904-40a5-a61c-2e3a4fc33318',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71359210656, 38.25817104014],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71362867444, 38.2582214384],
            [-85.71357207259, 38.25820362701],
            [-85.71355553868, 38.25819793168],
            [-85.71359025065, 38.25813760798],
            [-85.71359994546, 38.25812064188],
            [-85.71361843763, 38.25812734379],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b96b1789-0b05-40b8-a207-e0deccf33076',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71463429395, 38.256940027565],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71463505743, 38.25696574856],
            [-85.71469693634, 38.25698683262],
            [-85.71468493134, 38.25700855748],
            [-85.71462348608, 38.25698762118],
            [-85.71460255691, 38.25696422602],
            [-85.7146123757, 38.25694572833],
            [-85.71461895726, 38.25693392765],
            [-85.71457165156, 38.25691746685],
            [-85.71459674359, 38.25687149765],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'b97a0c2c-13da-4b72-a062-75923264d8c2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71391684491, 38.25830556325],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ba148d9f-5066-43dc-b4a3-ca80a330ff25',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71416273696, 38.25738900663],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71416273696, 38.25738900663],
            [-85.71416760632, 38.2573804054],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'bbadd004-c68f-4f56-9784-affe0ed9686a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.713927551025, 38.258351141065],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71395440604, 38.25833349466],
            [-85.71392112091, 38.25836859546],
            [-85.71387604361, 38.25839051528],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'bdbaa618-9015-4431-8ac3-00de751c33f4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71445136889, 38.25737577753],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71445136889, 38.25737577753],
            [-85.71443497356, 38.25740282592],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'be0db1d4-61bb-45bd-9bbc-13a0562ce37d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'bed00937-feb3-4be7-8d0e-8ae7e1650391',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71357207259, 38.25820362701],
            [-85.71357207259, 38.25820362701],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'bff62854-b914-4a3c-b6d2-b3bf3f18b27a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71475365233, 38.25690876562],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71475365233, 38.25690876562],
            [-85.71474525709, 38.2569061349],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c00c1d9e-4fc3-48da-87a0-deecfeb374da',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.71452606668, 38.25748812416],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c042ee59-5d6a-4c07-af4c-9660e0345608',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71417543472, 38.25720583548],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71417543472, 38.25720583548],
            [-85.71418785956, 38.25721020451],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c2ce4e80-9442-4d29-ae86-365000152ffb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71466149612, 38.25691897967],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71466149612, 38.25691897967],
            [-85.7146384269, 38.25695937498],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c2f71f18-7835-4c03-9b0b-c3e0226a2cf5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71459024889501, 38.25699011506],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71457640742, 38.25697957974],
            [-85.71456996862, 38.25697747742],
            [-85.71456501606, 38.25698693376],
            [-85.71461548173, 38.2570027527],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c35d1fb8-3a62-459c-ac1e-1938b14b31e2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714559008555, 38.25688026412],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71453757323, 38.25687063908],
            [-85.71456136252, 38.25687957017],
            [-85.71458044388, 38.25688988916],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c442142d-48cc-4a43-8726-5721e0ac1acf',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71398585094, 38.25828075108],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c466f778-bb3c-475b-8012-5e425d2f1a38',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714206284005, 38.257305724105],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71419935548, 38.25722820213],
            [-85.7141948581, 38.257236057],
            [-85.71418768051, 38.25724859298],
            [-85.7141827511, 38.2572572024],
            [-85.71416139907, 38.25729449465],
            [-85.71414316705, 38.25733868241],
            [-85.71417191437, 38.25734882948],
            [-85.71422545496, 38.25736773141],
            [-85.7142480941, 38.25737572391],
            [-85.71426940096, 38.25738324608],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c49dfb03-dc48-47bc-9171-25f5049b13c2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71388890142, 38.25835364877],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c5d917e4-221f-4302-8842-fce2ef1186d1',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714169242635, 38.257401199685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71407759774, 38.25735955355],
            [-85.71408279126, 38.25735047809],
            [-85.71411107258, 38.25736045726],
            [-85.71412816273, 38.25736648758],
            [-85.71414061888, 38.25737088278],
            [-85.71416760632, 38.2573804054],
            [-85.71419550106, 38.25739024816],
            [-85.71421996423, 38.25739888008],
            [-85.71419483549, 38.25744279151],
            [-85.71418961089, 38.25745192128],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'c6d57177-1140-4b89-a5b3-cbdcae90eeb6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71427209538, 38.2571905244],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71427209538, 38.2571905244],
            [-85.71426776121, 38.25719809555],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ca4053a7-3d74-45d5-b988-5ee676dd1cb6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714404420915, 38.257309136255],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71439626246, 38.2572930909],
            [-85.71438922906, 38.25730526239],
            [-85.71438295495, 38.25731611989],
            [-85.71439475641, 38.25732032484],
            [-85.71440838727, 38.25732518161],
            [-85.71441446493, 38.25731466407],
            [-85.71442588688, 38.25729489809],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ca48453a-9b54-4065-905d-09d8802e75d6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71430819255, 38.25753557804],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71430819255, 38.25753557804],
            [-85.71430819255, 38.25753557804],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ccf8a660-079f-42f5-8a78-84cd35b36b8e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71425453003, 38.25720948689],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71425453003, 38.25720948689],
            [-85.71424043968, 38.2572413654],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'cd4298df-b018-48bb-bf80-d454336a8412',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71418096326, 38.25743795789],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71418096326, 38.25743795789],
            [-85.71419483549, 38.25744279151],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'cea92198-7ab3-4449-9292-72e52b05a622',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71482396101, 38.25691816236],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71482396101, 38.25691816236],
            [-85.71481690387, 38.25691579802],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ceb9e1b4-27f5-4f9c-afd3-ffabf1281adf',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71473407471001, 38.25690968584],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71471113299, 38.25692389149],
            [-85.71473117457, 38.25693091468],
            [-85.71474525709, 38.2569061349],
            [-85.71475530355, 38.256888457],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ced77a17-d715-4ccb-a654-130ba5ffd3ac',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71396692524, 38.25831176685],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ced852a3-2349-47a6-9a86-6f58f833f187',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71474937439501, 38.256964716795],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71480307647, 38.25691101472],
            [-85.71479702373, 38.25692181357],
            [-85.71477300942, 38.25696465809],
            [-85.71476505356, 38.25697910851],
            [-85.71475634345, 38.25699439224],
            [-85.71475169053, 38.25701841887],
            [-85.71464152225, 38.25697789839],
            [-85.71463214594, 38.25697472229],
            [-85.71461245353, 38.25696805234],
            [-85.71459311451, 38.25699910008],
            [-85.71457398842, 38.25699283952],
            [-85.7145543819, 38.2569864217],
            [-85.71448370297, 38.25698502275],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'cefb66d8-9863-40dc-8203-32e141c12a21',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71408376341, 38.25775675188],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408376341, 38.25775675188],
            [-85.71408376341, 38.25775675188],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'cfd18bf6-a0d1-4f36-97ac-dcc4d32b43f3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71472200758001, 38.256939716595],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71467469097, 38.25692264818],
            [-85.71469348019, 38.25692942598],
            [-85.71475490187, 38.25695158248],
            [-85.71476932419, 38.25695678501],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd008743a-35db-453c-b7d9-5e6d4bbe2089',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71408423021, 38.25821116589],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408423021, 38.25821116589],
            [-85.71408423021, 38.25821116589],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd00e03e9-bc8a-4aca-a607-22e20b0c3a7e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71436450335, 38.25676245244],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71436450335, 38.25676245244],
            [-85.71436941849, 38.25676406328],
            [-85.71441080768, 38.25677762781],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd0b3b9ed-d96f-44d7-8c4b-6c7e065e61d8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71419118101, 38.25729665774],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71419118101, 38.25729665774],
            [-85.71419448332, 38.25729110083],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd16ddea8-6bc7-40d1-b352-ce9749da8552',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714766570075, 38.257049850689995],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71480003033, 38.25703715194],
            [-85.71475169053, 38.25701841887],
            [-85.71475332988, 38.25704243812],
            [-85.71473310982, 38.25707890085],
            [-85.7147419272, 38.25708128251],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd19cea46-ff2a-491a-825b-0d9b14d04a6a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71460581099501, 38.256999235310005],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71465109058, 38.25698418423],
            [-85.71460300733, 38.25701428639],
            [-85.71456053141, 38.25698832747],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd1e7ca29-9319-4b26-9923-d2934d2b871e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71453772212, 38.25729893475],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71453772212, 38.25729893475],
            [-85.71453343908, 38.25730375207],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd23cf4a4-3398-4a39-a049-a81b3c7cd315',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71455478634, 38.257291346985],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71457185056, 38.25728497612],
            [-85.7145692924, 38.25728375922],
            [-85.71453772212, 38.25729893475],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd27d27f5-3334-4276-89f3-6f4d62375139',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71392768585, 38.25828907157],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71392768585, 38.25828907157],
            [-85.71395440604, 38.25833349466],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd2bd9e47-ef9b-4118-8ba2-65a2b856828d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71434317604, 38.2572095909],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71434317604, 38.2572095909],
            [-85.71435683979, 38.25721279224],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd2d3613c-7497-4b9f-9b0c-8d3c7372d01c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'fe056d0a-f272-4503-9d50-46becdc49564',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71459331591, 38.25683095132],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd3e54e21-0698-4f52-aec7-72f761864e5d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7137720683, 38.25820203345],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7137720683, 38.25820203345],
            [-85.71378289563, 38.25818026124],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd493d073-f168-4548-a9f6-b15db5aca717',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.71452606668, 38.25748812416],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd4b11808-2a97-46fb-971c-e215131ddedf',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71440873494, 38.25710141577],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71440873494, 38.25710141577],
            [-85.71442300924, 38.25711761548],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd50f6c90-a912-4993-bc0c-272b36bf1bf4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71448747154, 38.25678863642],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71448747154, 38.25678863642],
            [-85.71449187927, 38.25678058231],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd5dfc424-8e31-4fac-8540-cfa6083dce69',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71359025065, 38.25813760798],
            [-85.71359025065, 38.25813760798],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd624c3d9-d5f1-4e4e-a255-8a7387de784d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71447327643, 38.25679878887],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71447327643, 38.25679878887],
            [-85.71448044985, 38.25680131485],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd63f6268-7be3-455b-82a6-1290f6547801',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71391008018, 38.25831601215],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd66b8e93-95bb-4d20-b0a2-479b3feba2d7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7144950063, 38.25683634874],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7144950063, 38.25683634874],
            [-85.71449965128, 38.25682758674],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd71d69ec-d2f1-4114-85c2-a91b64fb30c6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71378289563, 38.25818026124],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71378289563, 38.25818026124],
            [-85.71378289563, 38.25818026124],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd73bc71c-a3f6-4f6c-9910-3bd141cdc2b9',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71359025065, 38.25813760798],
            [-85.71357207259, 38.25820362701],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd789b012-d904-43ea-934f-2c3856b00929',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71452888218, 38.25742663427],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71452888218, 38.25742663427],
            [-85.71451238911, 38.25742123666],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd7daaf79-b46f-4be8-a2b4-4d24479f9da5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71357207259, 38.25820362701],
            [-85.71359025065, 38.25813760798],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd8784b02-2ac3-405d-98b2-abed28634e67',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71398585094, 38.25828075108],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd8bba10c-155f-483b-817d-8203c26769f4',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd93ab4d5-b838-4fa2-8950-13dab6e36485',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71388890142, 38.25835364877],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71388890142, 38.25835364877],
            [-85.71388890142, 38.25835364877],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'd9be05b4-f062-418a-a937-13399bcdbdc5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71474653421, 38.25685785849],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71474653421, 38.25685785849],
            [-85.71473138038, 38.25688346266],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'da0641c0-ed06-40d6-91cf-4139d7c0a881',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71458141019, 38.25690624372],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458141019, 38.25690624372],
            [-85.71456068343, 38.25689940976],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'da884cb8-cf5b-4f26-85fe-958f69734bda',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71459143391, 38.25683366577],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'daa409b1-d3bf-4d78-88f5-d3412fa3df90',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'db6e3d28-ba51-4436-a87d-07976b4b897b',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71421875455499, 38.257630204615005],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71411430465, 38.25795602351],
            [-85.71416101516, 38.25786845378],
            [-85.7142058228, 38.25778449669],
            [-85.71425055254, 38.25770059478],
            [-85.71425543814, 38.25769143558],
            [-85.7142968654, 38.25762120515],
            [-85.71432320446, 38.25757655326],
            [-85.71430672735, 38.25757059028],
            [-85.71429075001, 38.25756451459],
            [-85.71418882255, 38.25752575471],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'dc538a06-2c7f-4bc2-b09b-fa81b2c6a70c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71474948534, 38.25696878155],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71474948534, 38.25696878155],
            [-85.71475832535, 38.25695250312],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'dd589c13-4104-42a7-beef-f6b84959d996',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71447940135, 38.25683444621],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71447940135, 38.25683444621],
            [-85.71449407157, 38.25681150316],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ddb326ee-1867-49fa-a229-32fa92148b41',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460947834, 38.25686195039],
            [-85.71459994843, 38.25685868333],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ddf5e9d7-8232-4894-a34c-e39d2a748984',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714665558215, 38.256811399825004],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71464515363, 38.25679099524],
            [-85.7146859628, 38.25680576073],
            [-85.71466179231, 38.25684695155],
            [-85.71465591162, 38.25685697352],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'de0adc9b-1caa-4c17-bb8e-84723e73245d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71359025065, 38.25813760798],
            [-85.71359025065, 38.25813760798],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'de142571-4685-4c91-9458-de795b21273e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71444135895499, 38.257419042775],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71439553805, 38.25744038978],
            [-85.71440048752, 38.25743171301],
            [-85.7144156098, 38.25740604595],
            [-85.71442058638, 38.25739769577],
            [-85.71443497356, 38.25740282592],
            [-85.71446842792, 38.257414755],
            [-85.71447318263, 38.25741645042],
            [-85.71448536292, 38.25742140436],
            [-85.71450539997, 38.25743504272],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'de3c13e5-74b3-423b-8a63-c620c4499ceb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71359025065, 38.25813760798],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71359025065, 38.25813760798],
            [-85.71357207259, 38.25820362701],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'df85d91f-346e-4d6c-8d51-3450ce7535d0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71467510518, 38.257004987555],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71473824948, 38.25705060013],
            [-85.71470390306, 38.25703970927],
            [-85.71465962838, 38.25702495463],
            [-85.71461196088, 38.25700940672],
            [-85.71461548173, 38.2570027527],
            [-85.71461875117, 38.25699657027],
            [-85.71462348608, 38.25698762118],
            [-85.71462658116, 38.25698177074],
            [-85.7146330772, 38.25696949166],
            [-85.71463505743, 38.25696574856],
            [-85.7146384269, 38.25695937498],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e04c6679-1d27-4561-af22-dc6635e8e841',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71411597108, 38.25733731685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71411597108, 38.25733731685],
            [-85.71414316705, 38.25733868241],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e07c05b6-bc0b-4c0b-89aa-2db79f15e31d',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71436902909, 38.25760895942],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71436902909, 38.25760895942],
            [-85.71435461264, 38.25760349405],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e0924f6c-e0af-445c-9d0d-462041b8a537',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.714727283175, 38.25701707008],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71475066329, 38.25699737657],
            [-85.71474877201, 38.25700153421],
            [-85.71472792002, 38.25699443089],
            [-85.71470390306, 38.25703970927],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e239f31c-d41b-4854-9e00-c5026c4f87e7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7146121374, 38.25698422375],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71462184703, 38.25696762516],
            [-85.71462873599, 38.25696974709],
            [-85.71461959798, 38.25698540405],
            [-85.71460087421, 38.2570174851],
            [-85.71459553881, 38.25699329502],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e274a7e4-8b2f-41b4-b833-fabbcb8bdacb',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71458736165, 38.25683837292],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e2c529bd-160d-4793-95a0-5c17167c04e7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71430819255, 38.25753557804],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71430819255, 38.25753557804],
            [-85.71429364911, 38.25756197771],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e3bc9466-9c20-414f-96bb-5d56aed2c522',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71478118323, 38.25697897436],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71478118323, 38.25697897436],
            [-85.71477173059, 38.25698238639],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e3dbc641-ebc3-4639-b03b-432acdd646dc',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71357207259, 38.25820362701],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71357207259, 38.25820362701],
            [-85.71359025065, 38.25813760798],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e57fcadd-c771-4deb-b53b-6408bbc2bac2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71480090692, 38.25690321687],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71480090692, 38.25690321687],
            [-85.71479787958, 38.25690926467],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e5c52f59-637e-43e6-a8df-8aa9540a663a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71468753038, 38.25686693557],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71468753038, 38.25686693557],
            [-85.7147002839, 38.25687113305],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e64b7d2c-6a78-4067-a166-fc7a05eb17c7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7145057533, 38.25747628469],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7145057533, 38.25747628469],
            [-85.7145057533, 38.25747628469],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e6a4af61-537f-428a-b7dc-6f72b950bae3',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71361112896, 38.25831550714],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71361112896, 38.25831550714],
            [-85.7136210884, 38.25829605237],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e6d4f82a-c913-46b4-8c6f-b53fea5d1a1e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71473117457, 38.25693091468],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71473117457, 38.25693091468],
            [-85.71472849798, 38.25693651194],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e72ba77b-2e01-4295-a7a2-08ee451ba4f5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '44536ed5-4347-4682-9b89-07ad91856b6c',
          display_point: {
            coordinates: [-85.71473618549, 38.256952668],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71473618549, 38.256952668],
            [-85.71473991815, 38.25694613033],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e7d8ea1f-dd10-4a91-81dd-d2029883bb98',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71391008018, 38.25831601215],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e80749a6-d3a4-4ddf-bbc2-fc374d267a7c',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71460947834, 38.25686195039],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71460947834, 38.25686195039],
            [-85.71460947834, 38.25686195039],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e849651e-26e1-43c9-9ef0-58b179c9e6b0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71420997228499, 38.257660014525],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71425055254, 38.25770059478],
            [-85.71421380629, 38.25768698669],
            [-85.71421847337, 38.25767787993],
            [-85.71420764237, 38.25764699857],
            [-85.71415845489, 38.25762916947],
            [-85.71413353428, 38.25761943427],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e956b0d0-b660-4514-8ff4-3b30b5d1c88a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71403763711999, 38.257826392745],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71399422548, 38.25780855311],
            [-85.71402374492, 38.25781987329],
            [-85.71408104876, 38.25784423238],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'e9dd9caa-d075-4969-a671-67d18624f5a9',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.714198841455, 38.25716947155],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71420733447, 38.25717643742],
            [-85.71419034844, 38.25716999554],
            [-85.7141947825, 38.25716250568],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ea0d81e1-0d00-49a4-af1f-6af0bf6fc97e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71396692524, 38.25831176685],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71396692524, 38.25831176685],
            [-85.71398585094, 38.25828075108],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'eaaa1b0c-f35d-45c2-ae2e-aca2fc5d4c59',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714474257355, 38.256987423565],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71445578398, 38.2570436907],
            [-85.71444065939, 38.25703844065],
            [-85.71441757238, 38.25701532251],
            [-85.71444812806, 38.25696044417],
            [-85.71445562746, 38.25694691014],
            [-85.7144605476, 38.25693809745],
            [-85.71446446232, 38.25693115643],
            [-85.7144756525, 38.25693404546],
            [-85.71453094233, 38.25695323777],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'eae1cedb-0787-4e78-93a2-9d89e9d2fbf6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71419034844, 38.25716999554],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71419034844, 38.25716999554],
            [-85.7141413218, 38.25715254381],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'eba8bb99-7df7-4908-8237-54c69d5bb4e5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71415217689, 38.25724521023],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71415217689, 38.25724521023],
            [-85.71416531668, 38.25724929104],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ebedb0bd-3f5e-44be-9f4b-9a0faef48648',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71408423021, 38.25821116589],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408423021, 38.25821116589],
            [-85.71402043385, 38.25819519628],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ebedb783-9dfe-48b6-b47d-3ca83176fcaf',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71398585094, 38.25828075108],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71398585094, 38.25828075108],
            [-85.71396692524, 38.25831176685],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ec2c5f12-b75d-4b08-912f-bdd9fd881846',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71410529202, 38.258019239],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71410529202, 38.258019239],
            [-85.71411721011, 38.25802353494],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ec5b67b0-0340-4771-84dd-a245c0fee727',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714130807575, 38.257423631365],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71411220774, 38.25740503153],
            [-85.71414940741, 38.25741799376],
            [-85.71413354408, 38.25745013205],
            [-85.71412607771, 38.25746315804],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ed9e2c9f-7e29-42be-9e3b-920fc62ad725',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71461975077, 38.25686254159],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71461975077, 38.25686254159],
            [-85.7146096973, 38.25685883647],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'edb31cd5-42b8-4889-891a-eb0bf5e117d6',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7137720683, 38.25820203345],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7137720683, 38.25820203345],
            [-85.71378289563, 38.25818026124],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'ee0eb78c-efdb-431e-b87b-823ae27649ac',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71408423021, 38.25821116589],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408423021, 38.25821116589],
            [-85.71400008175, 38.25814888218],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'eec99a8d-3cbc-4d0a-86bf-0ff61d4031e7',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714253317105, 38.25721172638],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71419701037, 38.25715597323],
            [-85.71419298944, 38.2571547434],
            [-85.71418155798, 38.2571753076],
            [-85.71418484978, 38.25717649365],
            [-85.71419410806, 38.25717992615],
            [-85.71418617176, 38.25719412101],
            [-85.71420126745, 38.2571992748],
            [-85.71419935548, 38.25722820213],
            [-85.71421309332, 38.25723277568],
            [-85.71424043968, 38.2572413654],
            [-85.71428724125, 38.25725606616],
            [-85.71431290339, 38.25726412685],
            [-85.71432507623, 38.25726870936],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f08be9b7-ea06-4f36-a1e7-ca2e589c1425',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71423320448, 38.25722606769],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71423320448, 38.25722606769],
            [-85.71422984237, 38.25723182958],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f0907af3-87e9-4340-a0d8-db8f42585c04',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71459848611, 38.25679937985],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71459848611, 38.25679937985],
            [-85.71459306723, 38.25680925726],
            [-85.71458207304, 38.25682930021],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f13e7616-69b0-4396-9918-2fae64a79817',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71391008018, 38.25831601215],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71391008018, 38.25831601215],
            [-85.71392768585, 38.25828907157],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f1666b48-805e-433b-8686-5418f1be7318',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7146810209, 38.25694250827001],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71456298173, 38.2568244691],
            [-85.71459059325, 38.25683460401],
            [-85.71460947026, 38.25684152142],
            [-85.71462569986, 38.25684748097],
            [-85.71465591162, 38.25685697352],
            [-85.71467153402, 38.25686187754],
            [-85.71469241213, 38.25686940777],
            [-85.71473138038, 38.25688346266],
            [-85.71479906007, 38.25690805115],
            [-85.71478746782, 38.25692708402],
            [-85.71478086056, 38.25693789615],
            [-85.71476932419, 38.25695678501],
            [-85.71476574589, 38.25696264388],
            [-85.71475710769, 38.25697739823],
            [-85.71474557744, 38.25699755402],
            [-85.71474332166, 38.25700146319],
            [-85.71472517594, 38.25703290868],
            [-85.71475281929, 38.25704319079],
            [-85.71473598387, 38.25707077297],
            [-85.71469872931, 38.25705804801],
            [-85.71466500729, 38.25704499403],
            [-85.71464225205, 38.25703618534],
            [-85.71463744437, 38.25703432426],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f1c617fd-092e-48e9-a321-eb72b5faa52f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71440556479, 38.2568148743],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71440556479, 38.2568148743],
            [-85.71442221397, 38.25678622091],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f2d1e09e-31c2-4261-b9a5-7b05b782a76f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71417787247, 38.2571912633],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71417787247, 38.2571912633],
            [-85.71418617176, 38.25719412101],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f2e263bd-9d18-4465-8f0b-68380afbcd40',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71408423021, 38.25821116589],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71408423021, 38.25821116589],
            [-85.71408423021, 38.25821116589],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f36db270-d299-415f-8063-ff484ef5bed2',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.714727622325, 38.256850745425],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.714723884, 38.25684943125],
            [-85.71472890745, 38.25685123944],
            [-85.71473136065, 38.2568520596],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f583e157-cb21-45d4-a501-91f4ea62eb15',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71377703058, 38.258429326395],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71386195431, 38.25844203743],
            [-85.71382444726, 38.25851252576],
            [-85.71369210685, 38.25846396194],
            [-85.71375405574, 38.25834612703],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f5d1ba46-b3db-445f-978c-a031d26a3009',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71413243151, 38.257974150369996],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71415055837, 38.25799646102],
            [-85.71413900714, 38.2579668332],
            [-85.71411430465, 38.25795602351],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f67f7fd2-2c6c-4715-bac8-b9fca2ed512a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.714542539885, 38.256934193865],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71452109428, 38.25697530855],
            [-85.71454126024, 38.25693664716],
            [-85.71455311862, 38.25691391273],
            [-85.71456068343, 38.25689940976],
            [-85.71456398549, 38.25689307918],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f6feb478-6f73-4eb1-8c56-afdf4639f3a0',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71358385946999, 38.25838223359],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71356466839, 38.25835253095],
            [-85.71355641586, 38.25838166911],
            [-85.71355195968, 38.25839016382],
            [-85.71361575926, 38.25841193623],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f82a57ae-295e-46ff-8a23-9312763b314e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714692786855, 38.257006839940004],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71474332166, 38.25700146319],
            [-85.7146773346, 38.25697749454],
            [-85.71464225205, 38.25703618534],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f884a08a-f245-47f7-b359-5f11905f4ab9',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71428712997, 38.2572459331],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71428712997, 38.2572459331],
            [-85.71428329126, 38.25725157441],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f92585bf-b4d0-4867-974c-25293c0302c8',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.7140478194, 38.25811458885],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7140478194, 38.25811458885],
            [-85.71406310088, 38.25811951374],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'f971befd-fb4f-45c5-8cd8-0fa2a13f090f',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7142026029, 38.25730106879],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7142026029, 38.25730106879],
            [-85.71420560248, 38.25729520842],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'fa64d88d-dfb4-464a-8c7a-af08a90229cc',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.71458442866, 38.25684509571],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71458442866, 38.25684509571],
            [-85.71458442866, 38.25684509571],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'fac179b8-c330-4e11-8a68-cc0481feecb9',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '1b9019de-8d83-4d45-9863-39b93f3d60b3',
          display_point: {
            coordinates: [-85.71397755615499, 38.258269884415],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7139506392, 38.25825901775],
            [-85.71398842282, 38.25827207489],
            [-85.71398585094, 38.25828075108],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'fae95fe1-bde9-422b-8e18-97cdb3060c59',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20300d84-3c88-444f-a50c-e7748982c9b2',
          display_point: {
            coordinates: [-85.71470215959, 38.25704303114],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71470215959, 38.25704303114],
            [-85.71466752155, 38.25702834987],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'fbd65b1b-c3ab-448b-9ab7-ab7d258e788e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.7141413218, 38.25715254381],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7141413218, 38.25715254381],
            [-85.71412818251, 38.25714790618],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'fcd38ec2-2074-4946-8f51-59009bdc427e',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.7146919043, 38.25687797552],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.7146919043, 38.25687797552],
            [-85.71469241213, 38.25686940777],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'fcddb879-0ffe-4517-9544-8badebb751c5',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '945a8d37-b2d7-4917-a138-cc90b5f4990a',
          display_point: {
            coordinates: [-85.71480281132, 38.25695630949],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71480281132, 38.25695630949],
            [-85.71479565375, 38.2569538663],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'fd14a510-7aa0-43d6-96be-53a768e9c700',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: '20be8f6d-915d-4837-af52-afb5f5b8f7cd',
          display_point: {
            coordinates: [-85.71380418517, 38.25816344016],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71380418517, 38.25816344016],
            [-85.71377022671, 38.25815185268],
          ],
          type: 'LineString',
        },
      },
      {
        id: 'fd1a1111-573f-4fbd-93f4-e2c5dc59268a',
        type: 'Feature',
        feature_type: 'route',
        properties: {
          category: 'standard',
          restriction: 'none',
          accessibility: null,
          level_id: 'c7549865-d626-4ff6-965f-fd313605a7e6',
          display_point: {
            coordinates: [-85.714033527785, 38.257875939235],
            type: 'Point',
          },
        },
        geometry: {
          coordinates: [
            [-85.71405901703, 38.25783285819],
            [-85.71402186658, 38.25789673665],
            [-85.71400803854, 38.25791902028],
          ],
          type: 'LineString',
        },
      },
    ],
  },
};
