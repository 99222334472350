import { aphIMDF } from 'constants/APH-IMDF';
import * as gm from 'goodmaps-sdk';
import ExploreIMDFBuilding from '_sdks/cavu-sdk/src/ExploreIMDFBuilding';
import config from '../global/config';
import { ExploreEntity, ExploreBuilding, ExploreCampus } from '../_sdks/cavu-sdk/src';
import {
  BuildingInfo,
  convertToBuildingInfo,
  getAllAzureBuildings,
  getAzureBuilding,
  getAllAWSBuildings,
  getAWSBuilding,
} from './ApolloHelper';

let buildingManager: gm.BuildingManager;
let gmBuildingMetaData: BuildingInfo[];
let gmCampusMetaData: gm.BuildingMetaData[];
const exploreEntities: { [id: string]: ExploreEntity } = {};

let currentInsideBuilding: ExploreBuilding | ExploreIMDFBuilding;
let currentInsideCampus: ExploreCampus;
let currentExploreEntity: ExploreEntity;

export const initializeBuildingManager = (jwt: string) => {
  buildingManager = gm.createBuildingManager({
    userType: 'cavu',
    userId: 'cavu',
    jwt,
    environment: config.ENV_ID,
    language: 'en',
  });

  return buildingManager;
};

export const getBuildingManager = () => buildingManager;

export const updateBuildingManagerWithJWT = (idToken: string) =>
  (buildingManager.bmc.jwt = idToken);

export const updateLanguageForBuildingManager = (language: 'enUS' | 'frCA' | 'esMX') => {
  // TODO: Update GoodMaps SDK to use the full locale code
  const gmLocale = language.slice(0, 2);
  buildingManager.bmc.language = gmLocale;
};

export const refreshBuildingsAndCampusMetaData = async () => {
  const { buildings } = await buildingManager.getBuildings();
  const { campuses } = await buildingManager.getCampuses();

  const azureBuildings = await getAllAzureBuildings();
  azureBuildings.forEach(b => (b.name = `${b.name} (Azure 2.0)`));

  const awsBuildings = await getAllAWSBuildings();
  awsBuildings.forEach(b => (b.name = `${b.name} (AWS 2.0)`));

  const convertedBuildings = convertToBuildingInfo(buildings);
  const combinedBuildings = [...convertedBuildings, ...azureBuildings, ...awsBuildings];

  gmBuildingMetaData = combinedBuildings;
  gmCampusMetaData = campuses;

  return { buildings: combinedBuildings, campuses };
};

export const getBuildingMetaData = () => ({
  buildings: gmBuildingMetaData,
  campuses: gmCampusMetaData,
});

export const loadExploreBuilding = async (
  id: string
): Promise<ExploreBuilding | ExploreIMDFBuilding> => {
  if (id == null) return;
  if (exploreEntities[id]) {
    return exploreEntities[id] as ExploreBuilding;
  }

  console.log('load building ID', id);
  const buildingInfo = gmBuildingMetaData.find(data => data.id === id);
  if (buildingInfo.source === 'aws' && buildingInfo.version === '1.0') {
    const gmBuilding = await buildingManager.createBuilding(buildingInfo.buildingMetaData);
    const exploreBuilding: ExploreBuilding = new ExploreBuilding(
      gmBuilding,
      buildingInfo.buildingMetaData
    );
    exploreEntities[exploreBuilding.gmBuilding.uuid] = exploreBuilding;
    exploreBuilding.source = 'aws';
    return exploreBuilding;
  }
  if (buildingInfo.source === 'aws') {
    const response = await getAWSBuilding(id);
    const exploreBuilding: ExploreIMDFBuilding = new ExploreIMDFBuilding(response);
    exploreBuilding.cpsRegion = 'us';
    exploreBuilding.source = 'aws';
    exploreEntities[exploreBuilding.id] = exploreBuilding;
    return exploreBuilding;
  }
  const response = await getAzureBuilding(id);
  const exploreBuilding: ExploreIMDFBuilding = new ExploreIMDFBuilding(response);
  exploreBuilding.cpsRegion = 'us';
  exploreBuilding.source = 'az';
  exploreEntities[exploreBuilding.id] = exploreBuilding;
  return exploreBuilding;
};

export const loadExploreCampus = async (id: string): Promise<ExploreCampus> => {
  if (exploreEntities[id]) {
    return exploreEntities[id] as ExploreCampus;
  }
  const metaData = gmCampusMetaData.find(data => data.id === id);
  const gmCampus = await buildingManager.createCampus(metaData);
  const exploreCampus: ExploreCampus = new ExploreCampus(gmCampus, metaData);
  exploreEntities[exploreCampus.gmCampus.uuid] = exploreCampus;
  return exploreCampus;
};

export const getCurrentExploreEntity = () => currentExploreEntity;
export const clearCurrentExploreEntity = () => {
  currentExploreEntity = null;
  currentInsideCampus = null;
  currentInsideBuilding = null;
};

export const setCurrentInsideBuilding = async (id: string) => {
  clearCurrentExploreEntity();
  const building = await loadExploreBuilding(id);
  building.debug = true;
  currentInsideBuilding = building;
  currentExploreEntity = building;
  return building;
};

export const getCurrentInsideBuilding = () => currentInsideBuilding;

export const setCurrentVirtualBuilding = async (id: string) => {
  const building = await loadExploreBuilding(id);
  building.debug = true;
  currentExploreEntity = building;
  return building;
};

export const setCurrentVirtualCampus = async (id: string) => {
  clearCurrentExploreEntity();
  const campus = await loadExploreCampus(id);

  return campus;
};

export const setCurrentInsideCampus = async (id: string) => {
  const campus = await loadExploreCampus(id);
  campus.debug = true;
  currentInsideCampus = campus;
  currentExploreEntity = campus;
  return campus;
};

export const getCurrentInsideCampus = () => currentInsideCampus;

export const getExploreBuilding = (id: string): ExploreBuilding =>
  exploreEntities[id] as ExploreBuilding;

export const getExploreCampus = (id: string): ExploreCampus => exploreEntities[id] as ExploreCampus;

export const loadHardcodedIMDFBuilding = () => {
  const exploreBuilding: ExploreIMDFBuilding = new ExploreIMDFBuilding(aphIMDF);
  exploreEntities[exploreBuilding.imdfBuilding.id] = exploreBuilding;
  exploreBuilding.debug = true;
  currentExploreEntity = exploreBuilding;
  return exploreBuilding;
};
