import * as THREE from 'three';
import { POI, Room, Route, ExploreEntity } from '_sdks/cavu-sdk/src';
import { getInstruction } from '_sdks/explore-directions/src/ActionPoint';
import * as BuildingManagerHelper from '../helpers/BuildingManagerHelper';
import {
  BUILDING_SELECTED,
  DIRECTIONS_CREATED,
  DIRECTIONS_UPDATED,
  TEST_PERCENTAGE_UPDATED,
  MAP_OVERLAY_EXTRA_INFO_UPDATED,
  MAP_OVERLAY_LIVE_DIRECTION_UPDATED,
} from './types';

export type SelectableRouteDestination = {
  name: string;
  entity: POI | Room;
  type: 'poi' | 'room';
};
let currentRoute: Route;
let entity: ExploreEntity;

export const selectBuilding = id => async dispatch => {
  const building = await BuildingManagerHelper.setCurrentInsideBuilding(id);

  entity = building;

  const pois = building.pois
    .filter(poi => !!poi.name)
    .map(poi => ({
      name: poi.name,
      entity: poi,
      type: 'poi',
    }));

  const rooms: SelectableRouteDestination[] = building.rooms
    .filter(room => !!room.name)
    .map(room => ({
      name: room.name,
      entity: room,
      type: 'room',
    }));

  const all = [...pois, ...rooms].sort((a, b) => a.name.localeCompare(b.name));

  dispatch({
    type: MAP_OVERLAY_EXTRA_INFO_UPDATED,
    payload: { buildingName: building.name },
  });
  dispatch({ type: BUILDING_SELECTED, payload: { selectedBuilding: building, pois: all } });
};

export const selectCampus = (id: string) => async dispatch => {
  const campus = await BuildingManagerHelper.setCurrentInsideCampus(id);
  entity = campus;

  const pois = campus.pois
    .filter(poi => !!poi.name)
    .map(poi => ({
      name: poi.name,
      entity: poi,
      type: 'poi',
    }));
  const doors = campus.doors
    .filter(door => !!door.name)
    .map(door => ({ name: door.name, entity: door, type: 'door' }));
  dispatch({
    type: BUILDING_SELECTED,
    payload: { selectedBuilding: campus, pois: [...doors, ...pois] },
  });
};

export const selectIMDFBuilding = () => async dispatch => {
  const imdfBuilding = await BuildingManagerHelper.loadHardcodedIMDFBuilding();
  entity = imdfBuilding;

  const pois = imdfBuilding.pois
    .filter(poi => !!poi.name)
    .map(poi => ({
      name: poi.name,
      entity: poi,
      type: 'poi',
    }));

  const rooms: SelectableRouteDestination[] = imdfBuilding.rooms
    .filter(room => !!room.name)
    .map(room => ({
      name: room.name,
      entity: room,
      type: 'room',
    }));

  const all = [...pois, ...rooms].sort((a, b) => a.name.localeCompare(b.name));

  dispatch({ type: BUILDING_SELECTED, payload: { selectedBuilding: imdfBuilding, pois: all } });
};

export const selectStartingPOI = (poi: SelectableRouteDestination) => {
  if (!poi) return;
  if (poi.type === 'room') {
    const { center } = poi.entity as Room;
    entity.virtualPositioning.setPosition({ x: center.x, y: center.y, level: poi.entity.level });
  } else {
    const { position } = poi.entity as POI;
    entity.virtualPositioning.setPosition({
      x: position.x,
      y: position.y,
      level: poi.entity.level,
    });
  }
};

export const selectStartingPosition = (x: number, y: number) => {
  entity.virtualPositioning.setPosition({
    x,
    y,
    level: entity.virtualPositioning.getPosition().level,
  });
};

// let currentTarget;

export const startDirections = (
  startingPOI: SelectableRouteDestination | undefined,
  endingPOI: SelectableRouteDestination,
  // eslint-disable-next-line no-unused-vars
  building: boolean = true,
  stepFree: boolean = false
) => {
  try {
    if (startingPOI) {
      selectStartingPOI(startingPOI);
    }

    let rawInstructions;

    entity.routeNetwork.setConfiguration({ STEP_FREE: stepFree });

    if (endingPOI.type === 'poi') {
      currentRoute = entity.routeNetwork.generateRouteToPOI(
        entity.virtualPositioning.getPosition(),
        endingPOI.entity as POI
      );
      rawInstructions = currentRoute.routeInstructions;
    } else {
      currentRoute = entity.routeNetwork.generateRouteToRoom(
        entity.virtualPositioning.getPosition(),
        endingPOI.entity as Room
      );
      rawInstructions = currentRoute.routeInstructions;
    }

    const instruction = getInstruction(rawInstructions[0], value => `${Math.round(value)}m`);
    return {
      type: DIRECTIONS_CREATED,
      payload: { rawInstructions, tripInstructions: [instruction] },
    };
  } catch (e) {
    console.log(e);
    return { type: '' };
  }
};

export const updateDirections = (/* isBuilding: boolean = true */) => dispatch => {
  try {
    const route = currentRoute.routeInstructions;
    const target = route[0].distanceToNextPoint > 0.005 ? route[1] : route[2];
    if (route.length > 1) {
      if (
        target.point.connectionType &&
        target.nextInstruction &&
        target.nextInstruction.point.connectionType &&
        route[0].distanceToNextPoint < 1.5
      ) {
        const { position: positionA, level: levelA } = target.point;
        const { position: positionB, level: levelB } = target.nextInstruction.point;
        entity.virtualPositioning.moveThroughConnection(
          { x: positionA.x, y: positionA.y, level: levelA },
          { x: positionB.x, y: positionB.y, level: levelB }
        );
        const { destination } = currentRoute;
        if ((destination as Room).walls) {
          currentRoute = entity.routeNetwork.generateRouteToRoom(
            entity.virtualPositioning.getPosition(),
            destination as Room
          );
        } else {
          currentRoute = entity.routeNetwork.generateRouteToPOI(
            entity.virtualPositioning.getPosition(),
            destination as POI
          );
        }
      } else {
        entity.virtualPositioning.walkTowardPoint(
          entity.virtualPositioning.getPosition(),
          {
            x: target.point.position.x,
            y: target.point.position.y,
            level: target.point.level,
          },
          1.4,
          target.angleToNextPoint
        );
      }
    }
    const update = currentRoute.update(entity.virtualPositioning.getPosition());
    const currentPoint = update[0];
    const currentInstruction = getInstruction(currentPoint, value => `${Math.round(value)}m`);
    dispatch({
      type: MAP_OVERLAY_LIVE_DIRECTION_UPDATED,
      payload: currentInstruction.instruction.replace('\n', ' '),
    });
    dispatch({
      type: DIRECTIONS_UPDATED,
      payload: { tripInstructions: [], rawInstructions: update, currentInstruction },
    });
  } catch (e) {
    console.log(e);
    return { type: '' };
  }
};

let canceled = false;
let roomTestResults = {};
export const testRoutes = () => dispatch => {
  try {
    roomTestResults = {};
    canceled = false;
    dispatch({ type: TEST_PERCENTAGE_UPDATED, payload: 0 });
    const { rooms } = entity;
    console.log('Starting test...');

    let startingPoint = entity.pois.find(poi => poi.startingPoint);

    if (startingPoint) {
      entity.virtualPositioning.setPosition({
        x: startingPoint.position.x,
        y: startingPoint.position.y,
        level: startingPoint.level,
      });
    } else {
      startingPoint = {
        routePointID: 'starting',
        id: 'starting',
        name: 'starting',
        position: new THREE.Vector2(
          entity.virtualPositioning.getPosition().x,
          entity.virtualPositioning.getPosition().y
        ),
        shortName: '',
        attachedRooms: [],
        type: 'poi',
        level: entity.virtualPositioning.getPosition().level,
        startingPoint: true,
      };
    }

    dispatch(testRoomFromStartingPoint(rooms, startingPoint, 0));
  } catch (e) {
    console.log(e);
  }
};

export const cancelTest = () => {
  canceled = true;
  printRoomTestResults();
};

const addRoomTestResult = (
  id: string,
  name: string,
  startError: number = 0,
  endError: number = 0
) => {
  if (!roomTestResults[id]) {
    roomTestResults[id] = {
      id,
      name,
      startError: 0,
      endError: 0,
    };
  }

  roomTestResults[id].startError += startError;
  roomTestResults[id].endError += endError;
};

const printRoomTestResults = () => {
  console.log('ID\t\t\tStart\tEnd\tName');
  Object.keys(roomTestResults).forEach(r => {
    const result = roomTestResults[r];
    console.log(`${r}\t\t${result.startError}\t\t${result.endError}\t${result.name}`);
  });
};

export const testRoomFromStartingPoint = (
  rooms: Room[],
  startingPoint: POI,
  i: number
) => dispatch => {
  if (canceled) return;
  if (i >= rooms.length) {
    console.log('Test finished');
    printRoomTestResults();
    return;
  }

  dispatch({ type: TEST_PERCENTAGE_UPDATED, payload: (i / rooms.length) * 100 });

  const r = rooms[i];

  // Test starting point to room
  try {
    entity.virtualPositioning.setPosition({
      x: startingPoint.position.x,
      y: startingPoint.position.y,
      level: startingPoint.level,
    });
    const route = entity.routeNetwork.generateRouteToRoom(
      {
        x: startingPoint.position.x,
        y: startingPoint.position.y,
        level: startingPoint.level,
        orientation: 0,
      },
      r
    );
    const instructions = route.routeInstructions;
    if (!instructions || !instructions.length) {
      console.log('--');
      console.log('Error getting destination');
      addRoomTestResult(r.id, r.name, 0, 1);
    }
  } catch (e) {
    addRoomTestResult(r.id, r.name, 0, 1);
  }

  setTimeout(() => {
    dispatch(testRoomFromStartingPoint(rooms, startingPoint, i + 1));
  }, 1);
};

export const testRoom = (rooms: Room[], oi: number, di: number) => dispatch => {
  if (canceled) return;
  const noi = di >= rooms.length ? oi + 1 : oi;
  const ndi = di >= rooms.length ? 0 : di;
  console.log(noi);
  if (noi > rooms.length) {
    console.log('Test finished');
    printRoomTestResults();
    return;
  }

  dispatch({ type: TEST_PERCENTAGE_UPDATED, payload: (oi / rooms.length) * 100 });

  const o = rooms[noi];
  const d = rooms[ndi];

  if (d.name) {
    const { center } = o;
    entity.virtualPositioning.setPosition({ x: center.x, y: center.y, level: o.level });

    if (o.id !== d.id) {
      try {
        const route = entity.routeNetwork.generateRouteToRoom(
          entity.virtualPositioning.getPosition(),
          entity.getRoom(d.id)
        );
        if (!route || !route.routeInstructions.length) {
          console.log('--');
          console.log('Error getting destination');
          console.log(o.id, '\t', d.id);
          console.log(o.name, '\t', d.name);
          addRoomTestResult(o.id, o.name, 1, 0);
          addRoomTestResult(d.id, d.name, 0, 1);
        }
      } catch (e) {
        console.log('Error getting destination from', o.id, 'to', d.id);
        addRoomTestResult(o.id, o.name, 1, 0);
        addRoomTestResult(d.id, d.name, 0, 1);
      }
    }
  }

  setTimeout(() => {
    dispatch(testRoom(rooms, noi, ndi + 1));
  }, 1);
};
