import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'App';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ModalComponent from 'components/ModalComponent';
import DataLoggerList from 'screens/datalogger/DataLoggerList';
import DataLoggerSelected from 'screens/datalogger/DataLoggerSelected';
import './DataLogger.scss';
import BuildingCampusSelect from 'components/BuildingCampusSelect';
import { selectBuilding } from 'actions/DirectionsActions';
import { DataLoggerDataset } from 'goodmaps-utils';
import DeleteModal from '../route-logs/DeleteModal';
import {
  selectDataLoggerCPSData,
  selectDataLoggerFile,
  clearCPSDataList,
  stop,
  runCPSTest,
  runCPSHealthAnalysis,
  getDataLoggerData,
} from '../../actions/DataLoggerActions';
import { setExploreBuilding, loadBuildings } from '../../actions/RouteLogsActions';

type Mode = 'Delete' | 'Edit' | 'Default';

const DataLoggerOptions = () => {
  const dispatch = useDispatch();
  const { listOfBuildings } = useSelector((state: RootState) => state.global);
  const { buildingSelected } = useSelector((state: RootState) => state.datalogger);
  const { selectedDataLoggerFile, dataLoggerFiles, cpsTestMessage } = useSelector(
    (state: RootState) => state.datalogger
  );
  const [dropdownSelection, setSelection] = useState([]);
  const [selectedForDelete, setSelectedForDelete] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<Mode>('Default');
  const [selectedData, setSelectedData] = useState<DataLoggerDataset>(undefined);
  const [cpsOverride, setCpsOverride] = useState<'None' | 'Immersal' | 'Fantasmo'>('None');
  const [cpsOverrideMap, setCpsOverrideMap] = useState('');
  const [positionsToAnalyze, setPositionsToAnalyze] = useState('');

  useEffect(() => {
    dispatch(stop());
    dispatch(clearCPSDataList());
    dispatch(loadBuildings());
    dispatch(stop());
    dispatch(getDataLoggerData());
    setSelection([]);
  }, [dispatch]);

  const openModal = (datasetID: any, mode: Mode) => {
    setModalMode(mode);
    switch (mode) {
      case 'Delete':
        setSelectedForDelete(datasetID);
        break;
      default:
        break;
    }
    setIsModalOpen(true);
  };

  const renderModal = () => {
    switch (modalMode) {
      case 'Delete':
        return <DeleteModal datasetID={selectedForDelete} onClose={() => setIsModalOpen(false)} />;
      default:
    }
  };

  const fillBuildingsDropdown = () =>
    listOfBuildings.map(building => ({ id: building.id, name: building.name }));

  const onBuildingSelect = e => {
    dispatch(stop());
    setSelection(e);

    if (e[0]) {
      const { id } = e[0];
      dispatch(setExploreBuilding(id, true));
      dispatch(selectBuilding(id));
    }
  };

  const onDeleteButtonClick = (id: string) => {
    openModal(id, 'Delete');
  };

  const onHealthButtonClick = () => {
    // Parse user's comma-separated list of position IDs to analyze
    const positionIDs = positionsToAnalyze.trim().length === 0 ? [] : positionsToAnalyze.split(',').map(x => parseInt(x, 10) )
    dispatch(runCPSHealthAnalysis(selectedDataLoggerFile.dataset, positionIDs))
  }

  const handleBackButtonClick = () => {
    dispatch(clearCPSDataList());
    dispatch(stop());
  };

  useEffect(() => {
    if (selectedData && buildingSelected) {
      console.log('select');
      dispatch(selectDataLoggerCPSData(selectedData, cpsOverride, cpsOverrideMap));
    }
  }, [dispatch, selectedData, buildingSelected]);

  const renderList = () => {
    if (selectedDataLoggerFile) {
      return (
        <DataLoggerSelected
          dataLoggerFile={selectedDataLoggerFile}
          onDatasetSelect={data => setSelectedData(data)}
        />
      );
    }
    if (dataLoggerFiles.length) {
      return (
        <DataLoggerList
          datasets={dataLoggerFiles}
          onDatasetSelect={data => dispatch(selectDataLoggerFile(data))}
          onDeleteButtonClick={onDeleteButtonClick}
        />
      );
    }
    return null;
  };

  return (
    <div className="flex flex-column">
      <BuildingCampusSelect
        buildings={fillBuildingsDropdown()}
        onBuildingChange={onBuildingSelect}
        selectedBuilding={dropdownSelection}
      />

      {renderList()}

      {selectedDataLoggerFile ? (
        <div className="extra-options" style={{ marginBottom: 10 }}>
          <>
          <form className="form-inline">
            <Button className="back-button" variant="light" onClick={handleBackButtonClick}>
              Back
            </Button>
            <Button
              className="test-button"
              variant="primary"
              disabled={!buildingSelected}
              onClick={() => dispatch(runCPSTest(selectedDataLoggerFile.dataset))}
            >
              Run CPS test
            </Button>
            <Button
              className="test-button"
              variant="primary"
              disabled={!buildingSelected}
              onClick={() => {
                onHealthButtonClick()
              }}
            >
              Health
            </Button>
            <Form.Control
                value={positionsToAnalyze}
                onChange={e => setPositionsToAnalyze(e.target.value)}
                type="text"
                style={{width:50}}
              />
              </form>
            <div>{cpsTestMessage}</div>
          </>
        </div>
      ) : null}
      <div className="extra-options">
        <b>CPS Override</b>
        <br />
        Use a comma with no spaces to poll multiple map IDs (Immersal only).
        <div className="flex flex-row justify-between  align-items-center">
          <Form>
            <div key="inline-radio" className="mb-3">
              <Form.Check
                onChange={() => setCpsOverride('None')}
                checked={cpsOverride === 'None'}
                inline
                label="None"
                name="group1"
                type="radio"
                id="inline-radio-1"
              />
              <Form.Check
                onChange={() => setCpsOverride('Fantasmo')}
                checked={cpsOverride === 'Fantasmo'}
                inline
                label="Fantasmo"
                name="group1"
                type="radio"
                id="inline-radio-1"
              />
              <Form.Check
                onChange={() => setCpsOverride('Immersal')}
                checked={cpsOverride === 'Immersal'}
                inline
                label="Immersal"
                name="group1"
                type="radio"
                id="inline-radio-1"
              />
            </div>
            {cpsOverride !== 'None' ? (
              <Form.Control
                value={cpsOverrideMap}
                onChange={e => setCpsOverrideMap(e.target.value)}
                type="text"
              />
            ) : null}
          </Form>
          {/* <span>Follow User</span> */}
        </div>
      </div>

      <ModalComponent
        show={isModalOpen}
        size="sm"
        title={modalMode !== 'Edit' ? `${modalMode} Dataset` : `${modalMode} Dataset`}
        onClose={() => setIsModalOpen(false)}
      >
        {renderModal()}
      </ModalComponent>
    </div>
  );
};

export default DataLoggerOptions;
