import { IFootprintFeature, IGeometry, IVenueFeature } from 'goodmaps-utils/index';
import { Position } from 'goodmaps-utils/index';

const RADIUS_OF_EARTH_IN_M: number = 6371 * 1000;

/**
 * Local functions to convert deg to radians
 * @param angle in degrees
 * @returns angle in radians
 */
const degToRadian = (angle: number) => (Math.PI / 180) * angle;

/**
 * Local functions to convert radians to deg
 * @param angle in radians
 * @returns angle in degrees
 */
const radianToDeg = (angle: number) => (angle * 180.0) / Math.PI;

// longitude and latitude in that order
export function calcProjection(geoLoc: IGeometry | number[], box: IVenueFeature): Position {
  const r = RADIUS_OF_EARTH_IN_M;
  const dtor = degToRadian;
  const lat = (geoLoc as IGeometry).coordinates
    ? ((geoLoc as IGeometry).coordinates[1] as number)
    : geoLoc[1];
  const lon = (geoLoc as IGeometry).coordinates
    ? ((geoLoc as IGeometry).coordinates[0] as number)
    : geoLoc[0];

  const boxCalc = calcBoundingBox(box);
  const pos: Position = {
    x: r * (dtor(lon) - dtor(boxCalc.centerLon)) * Math.cos(dtor(boxCalc.centerLat)),
    y: r * (dtor(lat) - dtor(boxCalc.centerLat)),
  };

  return pos;
}

/**
 * Helper function to reverse the projection: take x/y position in meters and determine
 * the lat/lon, using the center of the bounding box as the central location of the projection.
 *
 * Equirectangular projection (reverse)
 * https://en.wikipedia.org/wiki/Equirectangular_projection
 * using center of building (lat) for both standard parallel and central parallel
 * using center of building (lon) for central meridian
 *
 * @param position - a Position object
 * @param box - a Bounding box
 *
 * @returns A GeoLocation object
 */
// export function reverseProjection(position: Position, box: BoundingBox): GeoLocation {
//   const r = RADIUS_OF_EARTH_IN_M;
//   const dtor = degToRadian;
//   const rtod = radianToDeg;

//   const geo: IGeometry = {
//     lat: rtod(position.y / r + dtor(box.centerLat)),
//     lon: rtod(position.x / (r * Math.cos(dtor(box.centerLat))) + dtor(box.centerLon)),
//   };

//   return geo;
// }

/**
 * Helper function to find the min/max lat/lon values as well as the center
 * point of a building based on it's nodes.
 *
 * @param geofence - IMDF GeofenceFeature
 *
 * @returns A BoundingBox that has the min/max latitude and longitude values for
 * the nodes in the array, as well as the center location of the bounding box.
 */
export function calcBoundingBox(
  geofence: IVenueFeature
): {
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
  centerLat: number;
  centerLon: number;
} {
  const mappedGeometry = (geofence.geometry.coordinates as number[][][])[0].map(coords => ({
    lat: coords[1],
    lon: coords[0],
  }));

  const result = {
    minLat: mappedGeometry.reduce((min, p) => (p.lat < min ? p.lat : min), mappedGeometry[0].lat),
    maxLat: mappedGeometry.reduce((max, p) => (p.lat > max ? p.lat : max), mappedGeometry[0].lat),
    minLon: mappedGeometry.reduce((min, p) => (p.lon < min ? p.lon : min), mappedGeometry[0].lon),
    maxLon: mappedGeometry.reduce((max, p) => (p.lon > max ? p.lon : max), mappedGeometry[0].lon),
    centerLat: 0,
    centerLon: 0,
  };
  result.centerLat = (result.maxLat + result.minLat) / 2;
  result.centerLon = (result.maxLon + result.minLon) / 2;
  return result;
}
