import { Vector2, Vector3, Line3, Line, Raycaster, Geometry } from 'three';
import RoutePoint from './RoutePoint';
import { POI } from '../entities/POI';
import Room from '../entities/Room';
import { DjikstraGraph, DjikstraSolution } from '../helpers/DjikstraHelper';
import { getDistanceBetweenPoints, convertDegToRad } from '../helpers/MathHelpers';
import { Route } from './Route';
import ExploreEntity from '../ExploreEntity';
import { ExplorePosition } from '../types';

interface RouteNetworkConfiguration {
  STEP_FREE?: boolean;
  // Radius with which to search for potential starting points
  NEARBY_ROUTEPOINT_SEARCH_DISTANCE?: number;
  // How far away from the point the user must be to reroute
  REROUTE_THRESHOLD?: number;
  // How close we have to be to a point to update to the next target point
  POINT_UPDATE_THRESHOLD_METERS?: number;
  POINT_UPDATE_THRESHOLD_POI_METERS?: number;
  // How close the orientation must be to update
  POINT_UPDATE_THRESHOLD_RADIANS?: number;
  // How much we bump the assumed position of the user forward in the route
  BUMP_AHEAD_AMOUNT?: number;
  // Whether this is a route network for a cmpus
  IS_CAMPUS?: boolean;
  // How long we are allowed to reroute
  ALLOW_REROUTE_TIME?: number;
}

const DEFAULT_CONFIGURATION: RouteNetworkConfiguration = {
  STEP_FREE: false,
  NEARBY_ROUTEPOINT_SEARCH_DISTANCE: 40,
  REROUTE_THRESHOLD: 5,
  POINT_UPDATE_THRESHOLD_METERS: 3,
  POINT_UPDATE_THRESHOLD_POI_METERS: 0.5,
  POINT_UPDATE_THRESHOLD_RADIANS: convertDegToRad(20),
  BUMP_AHEAD_AMOUNT: 1,
  IS_CAMPUS: false,
  ALLOW_REROUTE_TIME: 3000,
};

type PointInfo = {
  id: string;
  level: number;
  routeType: number;
  connectionType: number;
  nodeConnectionType: number;
  oneWay?: boolean;
  x: number;
  y: number;
  weight: number;
};

type RouteInfo = PointInfo[];

type RoutesDictionary = {
  [routeID: string]: {
    route: RouteInfo;
    indexInRoute: number;
  }
};

export class RouteNetwork {
  building: ExploreEntity;

  pointsTable: { [pointId: string]: RoutePoint } = {};

  djikstraGraph: DjikstraGraph = new DjikstraGraph();

  configuration: RouteNetworkConfiguration = { ...DEFAULT_CONFIGURATION };

  rerouteAllowed: boolean = false;

  allowRerouteTimeout: any;

  constructor(
    building: ExploreEntity,
    routes: RouteInfo[],
    pois: { [id: string]: POI },
    configuration: RouteNetworkConfiguration = {}
  ) {
    this.building = building;
    this.setConfiguration(configuration);
    this.pointsTable = {};

    // For each point, what routes is it in, and what is its position in each route?
    const pointToRoutes: { [pointID: string]: RoutesDictionary} = {};

    const time1 = Date.now();

    // Loop through the routes and create a hash table and array filled with all of the points
    // NOTE: level should never be NaN or undefined
    routes.forEach((points, routeIndex) => {
      if (!points) return;
      points.forEach((point, pointIndex) => {
        const {
          id,
          level,
          routeType,
          connectionType,
          nodeConnectionType,
          x,
          y,
          weight,
          oneWay,
        } = point;

        // Initialize dictionary for this point if it hasn't been yet
        let pointRoutes: RoutesDictionary = {};
        if (pointToRoutes[id]) {
          pointRoutes = pointToRoutes[id];
        } else {
          pointToRoutes[id] = pointRoutes;
        }
        // Use the route's position in the array as a key/id and add it to this point's dictionary
        pointRoutes[routeIndex.toString()] = {route: points, indexInRoute: pointIndex};

        if (!this.pointsTable[id]) {
          const poi = pois[id];

          const p = new RoutePoint(
            id,
            level,
            routeType,
            connectionType,
            nodeConnectionType,
            x,
            y,
            weight,
            poi || null,
            oneWay
          );

          this.pointsTable[id] = p;

          this.djikstraGraph.addNode(p);
        } else if (
          this.pointsTable[id] &&
          !this.pointsTable[id].connectionType &&
          point.connectionType
        ) {
          this.pointsTable[id].connectionType = point.connectionType;
        }
        if (this.pointsTable[id] && oneWay) {
          this.pointsTable[id].oneWay = true;
        }
      });
    });
    console.log(`RouteNetwork, time 1: `, Date.now() - time1);
    
    const time2 = Date.now();
    // Next, find all of the points' neighbors by finding routes that contain it and finding
    // the its siblings (prevPoint and nextPoint). The siblings will be added to the route's
    // neighbors
    for (const pointID in pointToRoutes) {
      const point = this.pointsTable[pointID];
      const routesDict = pointToRoutes[pointID];
  
      for (const routeID in routesDict) {
        const routeEntry = routesDict[routeID];
        const {route} = routeEntry;
        const i = routeEntry.indexInRoute;
        if (i !== 0 && !point.oneWay) {
          const prevPoint = this.pointsTable[route[i - 1].id];
          point.addNeighbor(prevPoint);
          this.djikstraGraph.addNeighbors(point, prevPoint);
        }
        if (i !== route.length - 1) {
          const nextPoint = this.pointsTable[route[i + 1].id];
          point.addNeighbor(nextPoint);
          this.djikstraGraph.addNeighbors(point, nextPoint);
        }
      }
    }
    console.log(`RouteNetwork, time 2: `, Date.now() - time2);
    console.log(`graph nodes: ${this.djikstraGraph.getNodes().length.toString()}`);

    // routePoints = allRoutePoints;
  }

  allowReroute() {
    console.log('allow reroute');
    this.rerouteAllowed = true;
    this.allowRerouteTimeout = setTimeout(() => {
      this.rerouteAllowed = false;
    }, this.configuration.ALLOW_REROUTE_TIME);
  }

  setConfiguration(configuration: RouteNetworkConfiguration) {
    this.configuration = { ...this.configuration, ...configuration };
  }

  getRoutePoint(id: string): RoutePoint {
    try {
      return this.pointsTable[id];
    } catch (e) {
      console.log(e);
    }
  }

  getAllRoutePoints(): RoutePoint[] {
    return Object.keys(this.pointsTable).map(k => this.pointsTable[k]);
  }

  getRoutePointsForLevel(level: number) {
    return this.getAllRoutePoints().filter(rp => rp.level === level);
  }

  generateRouteToPOI(
    startingPosition: ExplorePosition,
    poi: POI,
    room?: Room,
    destinationRoom?: Room
  ) {
    // console.log('~~ Generating New Route To POI ~~');
    try {
      console.log(`GENERATE ROUTE TO SHELF? ${poi.shelf}`);
      let startingNodes: RoutePoint[];
      if (this.configuration.IS_CAMPUS) {
        startingNodes = this.findNearestLineToPoint(
          new Vector2(startingPosition.x, startingPosition.y)
        );
        if (!startingNodes.length) startingNodes = [this.findNearestNode(startingPosition)];
      } else {
        startingNodes = this.findStartingNodesForPosition(startingPosition, room);
      }

      let endingNodes: RoutePoint[] = [];
      let hasRoutePoint = false;
      if (this.getRoutePoint(poi.routePointID)) {
        hasRoutePoint = true;
        const destination = this.getRoutePoint(poi.routePointID);
        destination.poi = poi;
        endingNodes = [destination];
      } else {
        endingNodes = this.findStartingNodesForPosition(
          { x: poi.position.x, y: poi.position.y, level: poi.level },
          destinationRoom
        );
      }

      const path = this.generateShortestPathForStartingAndEndingNodes(
        startingNodes,
        endingNodes,
        startingPosition,
        !hasRoutePoint ? { x: poi.position.x, y: poi.position.y, level: poi.level } : undefined
      );
      path.path[path.path.length - 1].poi = poi;
      path.path[path.path.length - 2].poi = poi;
      return new Route(this, poi, path.path, startingPosition.orientation);
    } catch (e) {
      console.log(e);
    }
  }

  generateRouteToRoom(startingPosition: ExplorePosition, room: Room) {
    // console.log('~~ Generating New Route To Room ~~');
    try {
      const startingNodes: RoutePoint[] = this.findStartingNodesForPosition(startingPosition);
      let endingNodes: RoutePoint[];
      let useCenter = false;

      // console.log('Generating route to', room.name);
      // console.log('Type:', room.type);

      if (room.routePoints.length) {
        // If navigating to a room with doors, use the doors as the destination.
        // console.log('Finding ending node for room with doors...');
        endingNodes = room.routePoints;
      } else if (room.type === 'area' || room.type === 'fixture') {
        // Otherwise, use the center point, by finding nodes in line of sight (using the parent's walls if it is an area or fixture)
        // console.log('Finding ending node for', room.type, '...');
        // console.log('Room parent: ', room.parent.name);
        endingNodes = this.findNodesInLineOfSight(
          { x: room.center.x, y: room.center.y, level: room.level },
          [...room.parent?.walls]
        );
        useCenter = true;
      } else {
        // console.log('Finding ending node for room without doors...');
        endingNodes = this.findNodesInLineOfSight(
          { x: room.center.x, y: room.center.y, level: room.level },
          [...room.walls]
        );
        useCenter = true;
      }

      // console.log('Found', endingNodes.length, 'potential ending nodes...');

      const path = this.generateShortestPathForStartingAndEndingNodes(
        startingNodes,
        endingNodes,
        startingPosition,
        useCenter ? { x: room.center.x, y: room.center.y, level: room.level } : undefined
      );
      return new Route(this, room, path.path, startingPosition.orientation);
    } catch (e) {
      console.log(e);
    }
  }

  generateRouteToPosition(
    startingPosition: ExplorePosition,
    endingPosition: ExplorePosition,
    startingRoom?: Room,
    endingRoom?: Room
  ): Route {
    const tempPOI: POI = {
      id: 'temp',
      routePointID: 'temp',
      name: 'Temp',
      type: 'poi',
      position: new Vector2(endingPosition.x, endingPosition.y),
      shortName: 'Temp',
      level: endingPosition.level,
      startingPoint: false,
      attachedRooms: [],
      shelf: false,
    };

    return this.generateRouteToPOI(startingPosition, tempPOI, startingRoom, endingRoom);
  }

  findNearestLineToPoint(point: Vector2 | Vector3, threshold: number = 50): RoutePoint[] {
    const p = new Vector3(point.x, point.y, 0);
    const filteredRoutePoints = this.getAllRoutePoints().filter(
      rp => rp.position.distanceTo(new Vector2(p.x, p.y)) < threshold
    );
    let set = [];
    let smallestDistance = Infinity;
    filteredRoutePoints.forEach(rp => {
      Object.keys(rp.neighbors).forEach(n => {
        const neighbor = rp.neighbors[n].point;
        const line = new Line3(
          new Vector3(rp.position.x, rp.position.y, 0),
          new Vector3(neighbor.position.x, neighbor.position.y, 0)
        );
        const snap = new Vector3();
        line.closestPointToPoint(p, true, snap);
        if (this.building.debug) {
          this.building.renderer?.renderCircleMarker(`${Math.random()}`, snap, 0x00ff00, 0.2);
        }
        const distance = snap.distanceTo(p);
        if (distance < smallestDistance) {
          smallestDistance = distance;
          set = [rp, neighbor];
          if (this.building.debug) {
            this.building.renderer?.renderCircleMarker('closestSnap', snap, 0xff0000, 0.4);
          }
        }
      });
    });
    return set;
  }

  // TODO: Remove dependency on getCurrentRoom
  findStartingNodesForPosition(startingPosition: ExplorePosition, room?: Room): RoutePoint[] {
    // let walls: THREE.Line[];
    try {
      let currentRoom: Room;
      if (room) currentRoom = room;
      else {
        currentRoom = this.building.roomQueries.getRoomAtPosition(startingPosition);
      }

      // Find the topmost parent of the current room
      let parent = currentRoom;
      while (parent?.parent) {
        parent = parent.parent;
      }

      let walls: Line[] = [];

      let itr: Room = currentRoom;
      do {
        if ((itr.type !== 'area' && itr.type !== 'fixture') || itr.id !== currentRoom.id) {
          walls.push(...itr.walls);
        }
        // eslint-disable-next-line no-loop-func
        walls = [...walls, ...itr.innerFixtures.filter(f => f.id !== itr.id).map(f => f.walls[0])];
        itr = itr.parent;
      } while (itr);

      walls = walls.filter(w => w && (w.geometry as Geometry).vertices);

      if (this.building.debug) {
        this.building.renderer?.renderLines(
          `walls`,
          walls.map(w => (w.geometry as Geometry).vertices),
          0.4,
          0x00ff00
        );

        this.building.renderer?.renderLine(
          'currentArea',
          (currentRoom.walls[0].geometry as Geometry).vertices,
          0.2,
          0xffff00
        );
      }

      let nodes = this.findNodesInLineOfSight(startingPosition, walls, false);
      if (!nodes.length && currentRoom.doors.length && currentRoom.type !== 'connection') {
        // console.log('No nodes in LOS found, falling back to room doors...');
        nodes = [...parent.doors, ...currentRoom.doors].map(d => this.getRoutePoint(d.id));
      }

      if (!nodes.length) {
        // console.log('No nodes in LOS found and no room doors, falling back to nearest point...');
        console.warn('No good route nodes were found for the current position!');
        nodes = [this.findNearestNode(startingPosition)];
      }

      // console.info('Found', nodes.length, 'potential starting nodes...');
      return nodes;
    } catch (e) {
      console.log(e);
      // console.log('falling back to nearest node');
      return [this.findNearestNode(startingPosition)];
    }
  }

  findEndingNodeForPosition() {}

  findNearestNode = (position: ExplorePosition): RoutePoint => {
    try {
      const positionVector = new Vector2(position.x, position.y);
      const routePointsForLevel = this.getAllRoutePoints().filter(
        rp => rp.level === position.level
      );

      let nearestPoint;
      let nearestDistance = Infinity;

      routePointsForLevel.forEach(p => {
        const d = getDistanceBetweenPoints(p.position, positionVector);
        if (d < nearestDistance) {
          nearestDistance = d;
          nearestPoint = p;
        }
      });

      return nearestPoint;
    } catch (e) {
      console.log('Find nearest node error');
    }
  };

  findNodesInLineOfSight(
    startingPosition: ExplorePosition,
    walls: Line[],
    nearestPointFallback = true,
    near = 0.1
  ) {
    try {
      const { x, y, level } = startingPosition;
      const positionVector = new Vector2(x, y);
      // let nearestRoutePoints: RoutePoint[];

      // const routePointsForLevel = this.getRoutePointsForLevel(level).filter(
      //   (rp) =>
      //     Math.abs(coords.x - rp.position.x) <
      //       this.configuration.NEARBY_ROUTEPOINT_SEARCH_DISTANCE &&
      //     Math.abs(coords.y - rp.position.y) < this.configuration.NEARBY_ROUTEPOINT_SEARCH_DISTANCE
      // );
      const nearestRoutePoints = this.getRoutePointsForLevel(level)
        .sort(
          (a, b) =>
            getDistanceBetweenPoints(a.position, positionVector) -
            getDistanceBetweenPoints(b.position, positionVector)
        )
        .slice(0, 30);
      // nearestRoutePoints = nearestFiveRoutePoints;

      // end

      const raycaster = new Raycaster(new Vector3(0, 0, 0), new Vector3(0, 0, 0), near);
      raycaster.linePrecision = 0.05;
      raycaster.params.Line.threshold = 0.1;

      let nearestPoints: RoutePoint[] = [];
      // Go through each point, checking to see if there are any walls between the point and the provided position
      nearestRoutePoints.forEach(p => {
        const to = new Vector3(p.position.x, p.position.y, 0);
        const from = new Vector3(x, y, 0);
        const difference = to.sub(from);
        const distanceFromPointToUser = difference.length();
        const normal = difference.normalize();

        // Shoot a ray to see if it intersects with any walls
        raycaster.set(new Vector3(from.x, from.y, 0), normal);
        const intersections = raycaster.intersectObjects(walls);

        if (intersections.length) {
          const firstIntersection = intersections[0];

          // If there is an intersection between the point and the user, we cannot use
          // that point (for some reason the distance it reports is very very small rather than zero)
          const betweenPointAndUser =
            firstIntersection.distance - distanceFromPointToUser < -0.0005;

          // Check to see if the intersection is very near to the point -- if so,
          // it's likely the point is just slightly off of the wall
          const isCloseToPoint =
            new Vector2(
              firstIntersection.point.x - p.position.x,
              firstIntersection.point.y - p.position.y
            ).length() < 0.005;

          if (betweenPointAndUser && !isCloseToPoint) return;
        }

        // If we made it this far, we're good to use the point
        nearestPoints.push(p);
      });

      // If somehow there were no usable points, find the nearest point and use it
      if (!nearestPoints.length && nearestPointFallback) {
        const nearestPoint = this.findNearestNode(startingPosition);
        nearestPoints = [nearestPoint];
      }

      // console.log(nearestPoints.map(p => p.id));
      return nearestPoints;
    } catch (e) {
      console.log(e);
    }
  }

  generateShortestPathForStartingAndEndingNodes(
    startingNodes: RoutePoint[],
    endingNodes: RoutePoint[],
    startingPosition: ExplorePosition,
    endingPosition?: ExplorePosition
  ) {
    try {
      let shortestSolution: DjikstraSolution = { path: [], distance: Infinity };

      if (!startingNodes.length || !endingNodes.length) {
        console.warn('Tried to generate a path with no starting/ending nodes.');
        return shortestSolution;
      }

      const startPoint = new RoutePoint(
        'initial',
        startingPosition.level,
        0,
        0,
        0,
        startingPosition.x,
        startingPosition.y,
        1
      );

      let endPoint: RoutePoint;
      if (endingPosition) {
        endPoint = new RoutePoint(
          'lastPoint',
          endingPosition.level,
          0,
          0,
          0,
          endingPosition.x,
          endingPosition.y,
          1
        );
      }

      startingNodes
        .filter(s => !!s)
        .forEach(s => {
          endingNodes
            .filter(n => !!n)
            .forEach(n => {
              const sol = this.djikstraGraph.calcDjikstraAlgorithm(
                s,
                n,
                this.configuration.STEP_FREE
              );
              try {
                sol.path.unshift(startPoint);
                sol.distance += startPoint.position.distanceTo(sol.path[1].position);
                if (endPoint) {
                  sol.path.push(endPoint);
                  sol.distance += endPoint.position.distanceTo(
                    sol.path[sol.path.length - 1].position
                  );
                }
                // Always add an extra, identical point at the end marked as "destination" in order to
                // maintain at least 2 points

                const lastPoint = sol.path[sol.path.length - 1];
                sol.path.push(
                  new RoutePoint(
                    'destination',
                    lastPoint.level,
                    0,
                    0,
                    0,
                    lastPoint.position.x,
                    lastPoint.position.y,
                    1
                  )
                );

                // sol.path.push(
                //   new RoutePoint('destination', n.level, 0, 0, n.position.x, n.position.y)
                // );
                if (sol.distance < shortestSolution.distance) {
                  shortestSolution = sol;
                }
              } catch (e) {
                console.log(e);
              }
            });
        });

      if (!shortestSolution.path.length) {
        console.warn('Generated an empty route.');
      }

      return shortestSolution;
    } catch (e) {
      console.log(e);
    }
  }

  // generatePath(
  //   startingNode: RoutePoint,
  //   endingNode: RoutePoint[],
  //   startingPosition: Vector2,
  //   endingPosition: Vector2 = undefined
  // ): RoutePoint[] {
  //   // const path = DjikstraGraph.
  // }
}
