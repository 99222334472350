import { combineReducers } from 'redux';
import DirectionsReducer from './DirectionsReducer';
import GlobalReducer from './GlobalReducer';
import RouteLogsReducer from './RouteLogsReducer';
import DataLoggerReducer from './DataLoggerReducer';
import MapOverlayReducer from './MapOverlayReducer';
import AuthenticationReducer from './AuthenticationReducer';

export default combineReducers({
  directions: DirectionsReducer,
  global: GlobalReducer,
  routeLogs: RouteLogsReducer,
  authenticator: AuthenticationReducer,
  datalogger: DataLoggerReducer,
  mapOverlay: MapOverlayReducer,
});
