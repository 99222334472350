import {
  Scene,
  WebGLRenderer,
  Mesh,
  ArrowHelper,
  Vector2,
  Vector3,
  CircleGeometry,
  BufferGeometry,
  MeshBasicMaterial,
  Shape,
  ShapeGeometry,
  PlaneGeometry,
  Geometry,
  Line,
  LineBasicMaterial,
  Material,
  Object3D,
  Euler,
  Math as TMath,
  PerspectiveCamera,
  RepeatWrapping,
  Raycaster,
  Path,
} from 'three';
import { ConnectionType, DoorType, RoomType } from 'goodmaps-sdk';
import { Floor } from '../entities/Floor';
import RoutePoint from '../routing/RoutePoint';
import ExploreBuilding from '../ExploreBuilding';
import {
  convertDegToRad,
  maxPointX,
  maxPointY,
  minPointX,
  minPointY,
} from '../helpers/MathHelpers';
import {
  BEDROOM_COLOR,
  CONFERENCE_COLOR,
  KITCHEN_COLOR,
  LOBBY_COLOR,
  PUBLIC_ROOM_COLOR,
  RESTAURANT_COLOR,
  RESTROOM_COLOR,
  SHOP_COLOR,
} from '../globals/constants';
import ExploreEntity from '../ExploreEntity';
// import Explore from '..';
// import { POI } from '../entities/POI';

export default class Renderer {
  building: ExploreEntity;

  initialized: boolean = false;

  snapToUser = false;

  scene: Scene;

  camera: PerspectiveCamera;

  glRenderer: WebGLRenderer;

  raycaster: Raycaster;

  raycastingPlane: Mesh;

  timeout = null;

  isCompassUserOriented: boolean = false;

  currentRoute: Object3D;

  currentCameraTarget: Vector3 = new Vector3(0, 0, 0);

  currentRenderedLevel: number;

  private elements = {};

  private textures = {};

  constructor(
    building: ExploreEntity,
    renderer: WebGLRenderer,
    width: number,
    height: number,
    onInit?: (renderer: Renderer) => void,
    onRender?: (renderer: Renderer) => void
  ) {
    this.building = building;
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(50, width / 2 / (height / 2), 1, 1000);
    this.raycaster = new Raycaster();
    this.camera.position.set(0, 0, 50);
    this.camera.up.set(0, 0, 1);
    this.camera.updateProjectionMatrix();

    this.glRenderer = renderer;
    this.glRenderer.setSize(width, height);
    this.glRenderer.setClearColor(0xbcbfc2, 1);

    const planeGeometry = new PlaneGeometry(1000, 1000);
    const material = new MeshBasicMaterial({ color: 0xffff00, opacity: 0, transparent: true });
    this.raycastingPlane = new Mesh(planeGeometry, material);
    this.raycastingPlane.position.z = 0;
    this.scene.add(this.raycastingPlane);

    this.renderFloor(this.building.floors[0]);

    const render = () => {
      // @ts-ignore
      requestAnimationFrame(render);
      this.glRenderer.render(this.scene, this.camera);
      if (onRender) {
        onRender(this);
      }
    };
    render();
    this.initialized = true;

    if (onInit) {
      onInit(this);
    }
  }

  cleanup() {
    if (this.scene) {
      this.scene.dispose();
    }
    if (this.glRenderer) {
      this.glRenderer.dispose();
    }
  }

  setSnapToUser(snap: boolean) {
    this.snapToUser = snap;
  }

  getCamera() {
    return this.camera;
  }

  getCoordinateFromRaycaster(x: number, y: number): Vector3 {
    try {
      this.raycaster.setFromCamera({ x, y }, this.camera);
      const intersections = this.raycaster.intersectObjects([this.raycastingPlane]);
      return intersections[0].point;
    } catch (e) {
      return new Vector3(0, 0, 0);
    }
  }

  renderCircleMarker(id: string, position?: Vector3, color: number = 0xff0000, size = 0.5) {
    if (!this.building.debug) return;
    try {
      if (!this.scene) {
        return;
      }
      const currentMarker: Mesh = this.elements[`${id}-circle`];

      if (!currentMarker) {
        const geometry = new CircleGeometry(size, 20);
        const material = new MeshBasicMaterial({ color });
        const mesh = new Mesh(geometry, material);
        mesh.position.copy(position);
        this.elements[`${id}-circle`] = mesh;
        this.scene.add(mesh);
      } else {
        (currentMarker.material as MeshBasicMaterial).color.setHex(color);
        if (position) currentMarker.position.copy(position);
      }

      if (id === 'user' && this.snapToUser) {
        this.camera.position.x = position.x;
        this.camera.position.y = position.y;
        this.currentCameraTarget.set(position.x, position.y, 0);
        if (this.isCompassUserOriented) {
          // this.camera.setRotationFromEuler(
          //   new Euler(0, 0, convertDegToRad(getStatus().indoorPosition.orientation) - Math.PI / 2)
          // );
        } else {
          this.camera.setRotationFromEuler(new Euler(0, 0, 0));
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  renderCrosshairs(center?: Vector3, color: number = 0xff0000) {
    if (!this.building.debug) return;
    try {
      if (!this.scene) {
        return;
      }
      let lineHorz: Line = this.elements['crosshairs-horz'];
      let lineVert: Line = this.elements['crosshairs-vert'];

      if (!lineHorz) {
        const material = new LineBasicMaterial({ color });
        const geometry = new BufferGeometry();
        lineHorz = new Line( geometry, material );
        this.elements['crosshairs-horz'] = lineHorz;
        this.scene.add(lineHorz);
      } else {
        (lineHorz.material as LineBasicMaterial).color.setHex(color);
      }
      if (center) {
        lineHorz.geometry.setFromPoints( [new Vector3( -50, center.y, center.z ), new Vector3( 50, center.y, center.z )] );
      }
      if (!lineVert) {
        const material = new LineBasicMaterial({ color });
        const geometry = new BufferGeometry();
        lineVert = new Line( geometry, material );
        this.elements['crosshairs-vert'] = lineVert;
        this.scene.add(lineVert);
      } else {
        (lineVert.material as LineBasicMaterial).color.setHex(color);
      }
      if (center) {
        lineVert.geometry.setFromPoints( [new Vector3( center.x, -50, center.z ), new Vector3( center.x, 50, center.z )] );
      }
    } catch (e) {
      console.log(e);
    }
  }

  clearCrosshairs() {
    try {
      const h: Line = this.elements['crosshairs-horz'];
      const v: Line = this.elements['crosshairs-vert'];

      this.scene.remove(h);
      this.scene.remove(v);

      if (h.geometry) {
        h.geometry.dispose();
      }
      if (v.geometry) {
        v.geometry.dispose();
      }
      if (h.material) {
        (h.material as Material).dispose();
      }
      if (v.material) {
        (v.material as Material).dispose();
      }
      delete this.elements['crosshairs-horz'];
      delete this.elements['crosshairs-vert'];
    } catch (e) {
      // console.log(e);
    }
  }

  jumpToPosition(position: Vector3, lookAt = false) {
    this.camera.position.x = position.x;
    this.camera.position.y = position.y;
    if (lookAt) {
      this.camera.lookAt(position.x, position.y, 0);
    }
  }

  panCamera(x: number, y: number) {
    const adjusted = new Vector2(-y, x).rotateAround(new Vector2(0, 0), this.camera.rotation.z);
    this.camera.position.x += adjusted.x;
    this.camera.position.y += adjusted.y;
    this.camera.updateProjectionMatrix();
  }

  zoomCamera(zoomAmount: number, min: number = 40, max: number = 450) {
    this.camera.position.z = Math.min(Math.max(this.camera.position.z + zoomAmount, min), max);
    this.camera.updateProjectionMatrix();
  }

  // toggleCompassOrientation(currentFloor) {
  //   this.isCompassUserOriented = !this.isCompassUserOriented;

  //   if (this.isCompassUserOriented) {
  //     this.camera.setRotationFromEuler(
  //       new Euler(0, 0, convertDegToRad(getStatus().indoorPosition.orientation) - Math.PI / 2)
  //     );
  //   } else {
  //     this.camera.setRotationFromEuler(new Euler(0, 0, 0));
  //   }
  //   this.renderFloor(currentFloor);
  // }

  renderArrowMarker(
    id: string,
    position: Vector3,
    direction: Vector3 | Vector2,
    color: number = 0xff0000,
    size = 4,
    headLength = 0.5,
    headWidth = 0.8
  ) {
    if (!this.scene || !this.building.debug) {
      return;
    }
    const currentMarker: ArrowHelper = this.elements[`${id}-arrow`];

    if (!currentMarker) {
      const mesh = new ArrowHelper(
        new Vector3(direction.x, direction.y, 0),
        position,
        size,
        color,
        headLength,
        headWidth
      );
      this.elements[`${id}-arrow`] = mesh;
      this.scene.add(mesh);
    } else {
      currentMarker.setDirection(new Vector3(direction.x, direction.y, 0));
      currentMarker.position.copy(position);
    }
  }

  renderBuildingOutline(outline: Vector2[]) {
    if (outline.length <= 2 || !this.scene) {
      return;
    }

    const material = new MeshBasicMaterial({ color: 0x999999 });
    const buildingShape = new Shape(outline);
    const buildingGeometry = new ShapeGeometry(buildingShape);
    const buildingMesh = new Mesh(buildingGeometry, material);
    buildingMesh.position.z = -0.25;

    const wallMaterial = new LineBasicMaterial({ color: 0xffffff, linewidth: 10 });
    const wallGeometry = new Geometry();
    outline.forEach(o => wallGeometry.vertices.push(new Vector3(o.x, o.y, -0.25)));
    const wallMesh = new Line(wallGeometry, wallMaterial);

    this.elements['building-floor'] = buildingMesh;
    // this.scene.add(buildingMesh);

    this.elements['building-walls'] = wallMesh;
    this.scene.add(wallMesh);
  }

  renderArea(
    id: string,
    type: string,
    points: Vector2[],
    holes: Vector2[][],
    color = 0xefefef,
    outlineColor = 0xffffff,
    zPosition?: number
  ) {
    if (!this.building.debug || !this.scene) return;
    if (points.length <= 2) {
      return;
    }
    const floorMaterial = this.textures[type]
      ? new MeshBasicMaterial({ color, map: this.textures[type] })
      : new MeshBasicMaterial({ color });

    if (this.textures[type]) {
      // const { orientation } = getStatus().indoorPosition;

      floorMaterial.map.center.set(0.5, 0.5);
      floorMaterial.map.wrapS = RepeatWrapping;
      floorMaterial.map.wrapT = RepeatWrapping;
      floorMaterial.map.repeat.set(2, 2);
      floorMaterial.map.rotation = TMath.degToRad(180);
      // : TMath.degToRad(orientation);
    }

    const floorShape = new Shape(points);
    floorShape.holes = holes.map(p => new Path(p));
    const floorGeometry = new ShapeGeometry(floorShape);
    const floorMesh = new Mesh(floorGeometry, floorMaterial);
    floorMesh.position.z = zPosition ? zPosition - 0.02 : -0.12;

    const wallMaterial = new LineBasicMaterial({ color: outlineColor, linewidth: 4 });
    const wallGeometry = new Geometry();
    points.forEach(p => wallGeometry.vertices.push(new Vector3(p.x, p.y, zPosition || -0.1)));
    const wallMesh = new Line(wallGeometry, wallMaterial);

    this.elements[`${id}-floor`] = floorMesh;
    this.scene.add(floorMesh);
    this.elements[`${id}-walls`] = wallMesh;
    this.scene.add(wallMesh);
  }

  renderElevator = (id: string, points: Vector2[]) => {
    if (
      this.elements[`${id}-elevator-border`] ||
      this.elements[`${id}-elevator-diag1`] ||
      this.elements[`${id}-elevator-diag2`]
    ) {
      return;
    }
    const [A, B, C, D] = [
      minPointX(points),
      maxPointY(points),
      maxPointX(points),
      minPointY(points),
    ];
    const horizUnit = A.clone().sub(B).normalize().multiplyScalar(0.25);
    const vertUnit = B.clone().sub(C).normalize().multiplyScalar(0.25);

    A.sub(horizUnit);
    A.sub(vertUnit);

    B.add(horizUnit);
    B.sub(vertUnit);

    C.add(horizUnit);
    C.add(vertUnit);

    D.sub(horizUnit);
    D.add(vertUnit);

    const border: Vector3[] = [A, B, C, D, A];
    const diag1: Vector3[] = [A, C];
    const diag2: Vector3[] = [B, D];

    const borderMaterial = new LineBasicMaterial({ color: 0xdec7b0, linewidth: 4 });
    const borderGeometry = new Geometry();
    border.forEach(b => borderGeometry.vertices.push(new Vector3(b.x, b.y, -0.1)));
    const borderMesh = new Line(borderGeometry, borderMaterial);
    this.elements[`${id}-elevator-border`] = borderMesh;
    this.scene.add(borderMesh);

    const diag1Material = new LineBasicMaterial({ color: 0xdec7b0, linewidth: 4 });
    const diag1Geometry = new Geometry();
    diag1.forEach(d => diag1Geometry.vertices.push(new Vector3(d.x, d.y, -0.1)));
    const diag1Mesh = new Line(diag1Geometry, diag1Material);
    this.elements[`${id}-elevator-diag1`] = diag1Mesh;
    this.scene.add(diag1Mesh);

    const diag2Material = new LineBasicMaterial({ color: 0xdec7b0, linewidth: 4 });
    const diag2Geometry = new Geometry();
    diag2.forEach(d => diag2Geometry.vertices.push(new Vector3(d.x, d.y, -0.1)));
    const diag2Mesh = new Line(diag2Geometry, diag2Material);
    this.elements[`${id}-elevator-diag2`] = diag2Mesh;
    this.scene.add(diag2Mesh);
  };

  renderFloor(floor: Floor) {
    if (!this.scene || !floor || floor.level === this.currentRenderedLevel) {
      return;
    }

    this.clearCanvas();

    this.currentRenderedLevel = floor.level;

    floor.rooms.forEach(room => {
      let color = 0xefefef;
      let z = -0.1;
      let outlineColor = 0x999999;
      if (room.connectionType) {
        if (
          room.connectionType.toLowerCase() ===
          ConnectionType[ConnectionType.Elevator].toLowerCase()
        ) {
          this.renderElevator(room.id, room.points);
        }
        color = 0xffedce;
        z = -0.05;
      }
      if (room.roomType) {
        switch (room.roomType) {
          case RoomType.Shop:
            color = SHOP_COLOR;
            break;
          case RoomType.Restaurant:
          case RoomType.Food_Service:
            color = RESTAURANT_COLOR;
            break;
          case RoomType.Kitchen:
            color = KITCHEN_COLOR;
            break;
          case RoomType.Conference:
            color = CONFERENCE_COLOR;
            break;
          case RoomType.Lobby:
          case RoomType.Lounge:
          case RoomType.Waiting:
          case RoomType.Entrance:
            color = LOBBY_COLOR;
            break;
          case RoomType.Restroom:
          case RoomType.Restroom_Family:
          case RoomType.Restroom_Female:
          case RoomType.Restroom_Male:
          case RoomType.Restroom_Unisex:
          case RoomType.Bathroom:
            color = RESTROOM_COLOR;
            break;
          case RoomType.Bedroom:
            color = BEDROOM_COLOR;
            break;
          default:
            color = PUBLIC_ROOM_COLOR;
        }
      }
      switch (room.type) {
        case 'area':
          z = -0.05;
          color = 0xeff7fa;
          outlineColor = 0x666666;
          break;
        case 'fixture':
          z = -0.04;
          color = 0xaaaaaa;
          outlineColor = 0x222222;
          break;
        default:
          break;
      }

      this.renderArea(
        room.id,
        `${room.type}-${room.connectionType}`,
        room.points,
        room.holes,
        color,
        outlineColor,
        z
      );
    });

    // floor.beacons.forEach((beacon) => {
    //   renderCircleMarker(
    //     beacon.id,
    //     new Vector3(beacon.position.x, beacon.position.y, 0.5),
    //     0x41bcff,
    //     0.5
    //   );
    // });
    this.renderBuildingOutline(floor.outline);

    if (this.building.debug) {
      floor.pois.forEach(poi => {
        switch (poi.type) {
          case 'door':
            this.renderCircleMarker(
              poi.routePointID,
              new Vector3(poi.position.x, poi.position.y, 0.5),
              0xd2691e,
              0.25
            );
            break;
          default:
            this.renderCircleMarker(
              poi.routePointID,
              new Vector3(poi.position.x, poi.position.y, 0.5),
              0xff8c00,
              0.25
            );
            break;
        }
      });
    }

    if (this.building.debug) {
      floor.testPoints.forEach(point => {
        this.renderCircleMarker(
          point.id,
          new Vector3(point.position.x, point.position.y, 0.5),
          0xff0000,
          0.3
        );
      });
    }

    this.renderRoutes(floor.level);
  }

  renderRoutes(level: number) {
    if (this.building.debug && this.scene) {
      const points = this.building.routeNetwork.getRoutePointsForLevel(level);
      const renderedRoutes = {};
      points.forEach(p => {
        const color = !p.poi?.poiType || p.poi?.poiType === DoorType.No ? 0x8b0000 : 0xcc8899;
        this.renderCircleMarker(p.id, new Vector3(p.position.x, p.position.y, 0.2), color, 0.1);
        Object.keys(p.neighbors).forEach(nid => {
          const n = p.neighbors[nid].point;
          const jointId = [p.id, n.id].sort((a, b) => a.localeCompare(b)).join('');
          if (!renderedRoutes[jointId]) {
            renderedRoutes[jointId] = true;

            let c = 0xff00000;
            if (n.oneWay && p.oneWay) c = 0x0000ff;
            const line = this.createLine(p.position, n.position, 0.05, c, 0.2);
            this.scene.add(line);
          }
        });
      });
    }
  }

  createLine = (
    a: Vector2 | Vector3,
    b: Vector2 | Vector3,
    width: number = 0.2,
    color: number = 0xff0000,
    z: number = 0.3
  ) => {
    const point1 = new Vector3(a.x, a.y, z);
    const point2 = new Vector3(b.x, b.y, z);

    const vector12 = new Vector3().copy(point2).sub(point1);

    if (vector12.length() === 0) return new Object3D();
    const point3 = new Vector3().copy(vector12).multiplyScalar(0.5).add(point1);

    // const sphere = new SphereGeometry( 2, 10, 10 );

    // const point1mesh = new Mesh( sphere, new MeshBasicMaterial( { color: 0xff0000 } ) );
    // point1mesh.position.copy( point1 );
    // this.scene.addObject( point1mesh );

    // const point2mesh = new Mesh( sphere, new MeshBasicMaterial( { color: 0x0000ff } ) );
    // point2mesh.position.copy( point2 );
    // this.scene.addObject( point2mesh );

    const plane = new PlaneGeometry(width, 1, 1);

    const wall = new Mesh(plane, new MeshBasicMaterial({ color }));
    wall.position.copy(point3);
    wall.position.z = z;
    wall.scale.y = vector12.length();
    // wall.scale.y = wall.position.y * 2;
    wall.rotation.z = -Math.atan2(vector12.x, vector12.y);
    // wall. = true;
    return wall;
  };

  renderLine(
    id: string,
    points: Vector2[] | Vector3[],
    width: number = 0.2,
    color: number = 0xff0000,
    z: number = 0.3
  ) {
    if (!this.building.debug || !this.scene) return;
    const currentLine = this.elements[id];

    if (currentLine) {
      this.scene.remove(currentLine);
    }

    const container = new Object3D();
    for (let i = 0; i < points.length - 1; i++) {
      const line = this.createLine(points[i], points[i + 1], width, color, z);
      container.add(line);
    }

    this.scene.add(container);
    this.elements[id] = container;
  }

  renderLines(
    id: string,
    points: Vector2[][] | Vector3[][],
    width: number = 0.2,
    color: number = 0xff0000,
    z: number = 0.3
  ) {
    if (!this.building.debug || !this.scene) return;
    const currentLine = this.elements[id];

    if (currentLine) {
      this.scene.remove(currentLine);
    }

    const container = new Object3D();
    for (let i = 0; i < points.length; i++) {
      const innerContainer = new Object3D();
      for (let j = 0; j < points[i].length - 1; j++) {
        const line = this.createLine(points[i][j], points[i][j + 1], width, color, z);
        innerContainer.add(line);
      }
      container.add(innerContainer);
    }

    this.scene.add(container);
    this.elements[id] = container;
  }

  renderRoute(
    id: string,
    points: RoutePoint[],
    level: number,
    width: number = 0.2,
    color: number = 0x0096ff,
    z = 0.3
  ) {
    if (!this.scene) {
      return;
    }

    const currentRoute = this.elements[id];

    if (currentRoute) {
      this.scene.remove(currentRoute);
    }

    if (!points.length) return;

    // const ROUTE_POINT_MATERIAL = new MeshBasicMaterial({ color: 0x0075fe });

    const filteredPoints = [];
    points = points.filter(p => p !== undefined);
    for (let i = 0; i < points.length; i++) {
      if (points[i] && points[i].level === level) {
        filteredPoints.push(points[i]);
      } else {
        break;
      }
    }

    // const filteredPoints = points.filter((p) => p && p.level === indoorPosition.level);

    const container = new Object3D();
    for (let i = 0; i < filteredPoints.length - 1; i++) {
      const a = filteredPoints[i];
      const b = filteredPoints[i + 1];

      const line = this.createLine(a.position, b.position, width, color, z);
      container.add(line);
    }
    this.elements[id] = container;
    this.scene.add(container);
  }

  clearMarker(marker: string) {
    try {
      const mesh: Mesh = this.elements[marker];

      this.scene.remove(mesh);

      if (mesh.geometry) {
        mesh.geometry.dispose();
      }
      if (mesh.material) {
        (mesh.material as Material).dispose();
      }
      delete this.elements[marker];
    } catch (e) {
      // console.log(e);
    }
  }

  clearCanvas() {
    if (!this.scene) {
      return;
    }
    Object.keys(this.elements).forEach(id => {
      this.clearMarker(id);
    });
    while (this.scene.children.length > 0) {
      this.scene.remove(this.scene.children[0]);
    }
  }

  project(x: number, y: number) {
    const v = new Vector3(x, y, 0);
    v.project(this.camera);
    return v;
  }

  setTexture(id: string, texture: any) {
    this.textures[id] = texture;
  }
}
